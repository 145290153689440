import { mediaarchiver } from '../../Protos/protos';

export enum ReviewsActionTypes {
    CREATE = '@@reviews/CREATE',
    DELETE = '@@reviews/DELETE',
    EDIT = '@@reviews/EDIT',
    GET = '@@reviews/GET',
    GET_BY_ID = '@@reviews/GET_BY_ID',
    OP_SUCCESS = '@@reviews/OP_SUCCESS',
    SET_ERRORS = '@@reviews/SET_ERRORS',
    SET_OP_PROGRESS = '@@reviews/SET_OP_PROGRESS',
    SET_REVIEW = '@@archives/SET_REVIEW',
    SET_SEARCH_RESULT = '@@reviews/SET_SEARCH_RESULT',
}

export interface IReviewFormErrors {
    general: string;
    name: string;
}

export interface IReviewsState {
    readonly opSuccessEventElement: HTMLElement;
    readonly operationInProgress: boolean;
    readonly reviews: mediaarchiver.IReview[];
    readonly selected: mediaarchiver.IReview;

    readonly total: number;
}

export interface ICreateAction {
    payload: mediaarchiver.IReview;
    type: ReviewsActionTypes.CREATE;
}

export interface IEditAction {
    payload: mediaarchiver.IReview;
    type: ReviewsActionTypes.EDIT;
}

export interface IEditArchiveAction {
    payload: mediaarchiver.IReview;
    type: ReviewsActionTypes.EDIT;
}

export interface IGetAction {
    payload: mediaarchiver.IArgumentsGetReviews;
    type: ReviewsActionTypes.GET;
}

export interface IGetByIdAction {
    payload: string;
    type: ReviewsActionTypes.GET_BY_ID;
}

export interface IRemoveAction {
    payload: string;
    type: ReviewsActionTypes.DELETE;
}

export interface ISetErrorsAction {
    payload: IReviewFormErrors;
    type: ReviewsActionTypes.SET_ERRORS;
}

export interface ISetOpSuccessAction {
    type: ReviewsActionTypes.OP_SUCCESS;
}

export interface ISetProgressAction {
    payload: boolean;
    type: ReviewsActionTypes.SET_OP_PROGRESS;
}
