import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class Archive extends AMessage implements IFromCommand {
    private parsedArchive: Proto.mediaarchiver.IArchive | null = null;

    public constructor() {
        super('archive');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.ArchiveArg)) {
                return reject(new Error('Not a archive message'));
            }
            this.parsedArchive = msg.ArchiveArg;
            resolve();
        });
    }

    public get archive(): Proto.mediaarchiver.IArchive | undefined {
        if (isNull(this.parsedArchive)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedArchive;
    }
}
