import { ILocaleInfos } from './Types';

export enum Locales {
    English = 'en_GB',
    French = 'fr_FR',
    German = 'de_DE',
}

export interface ILocaleInfosList {
    [l: string]: ILocaleInfos;
}

export const LocaleInfos: ILocaleInfosList = {
    [Locales.English]: {
        daysInitials: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dow: 0,
        formatLongDate: 'LL',
        formatLongDateTime: 'LLLL',
        formatLongDateTimeWithMilliSeconds: 'LL LTS.SSS',
        formatLongDateTimeWithSeconds: 'LL LTS',
        formatShortDate: 'DD/MM/YYYY',
        formatShortDateTime: 'DD/MM/YYYY HH:mm',
        formatShortDateTimeWithMilliseconds: 'DD/MM/YYYY HH:mm:ss.SSS',
        formatShortDateTimeWithSeconds: 'DD/MM/YYYY HH:mm:ss',
        formatShortTime: 'HH:mm',
        formatShortTimeWithMilliseconds: 'HH:mm:ss.SSS',
        formatShortTimeWithSeconds: 'HH:mm:ss',
        iconName: 'gb',
        momentLocale: 'en-gb',
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        name: Locales.English,
    },
    [Locales.French]: {
        daysInitials: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dow: 1,
        formatLongDate: 'LL',
        formatLongDateTime: 'LLLL',
        formatLongDateTimeWithMilliSeconds: 'LL LTS.SSS',
        formatLongDateTimeWithSeconds: 'LL LTS',
        formatShortDate: 'DD/MM/YYYY',
        formatShortDateTime: 'DD/MM/YYYY HH:mm',
        formatShortDateTimeWithMilliseconds: 'DD/MM/YYYY HH:mm:ss.SSS',
        formatShortDateTimeWithSeconds: 'DD/MM/YYYY HH:mm:ss',
        formatShortTime: 'HH:mm',
        formatShortTimeWithMilliseconds: 'HH:mm:ss.SSS',
        formatShortTimeWithSeconds: 'HH:mm:ss',
        iconName: 'fr',
        momentLocale: 'fr',
        months: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre',
        ],
        name: Locales.French,
    },
    [Locales.German]: {
        daysInitials: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
        dow: 0,
        formatLongDate: 'LL',
        formatLongDateTime: 'LLLL',
        formatLongDateTimeWithMilliSeconds: 'LL LTS.SSS',
        formatLongDateTimeWithSeconds: 'LL LTS',
        formatShortDate: 'DD/MM/YYYY',
        formatShortDateTime: 'DD/MM/YYYY HH:mm',
        formatShortDateTimeWithMilliseconds: 'DD/MM/YYYY HH:mm:ss.SSS',
        formatShortDateTimeWithSeconds: 'DD/MM/YYYY HH:mm:ss',
        formatShortTime: 'HH:mm',
        formatShortTimeWithMilliseconds: 'HH:mm:ss.SSS',
        formatShortTimeWithSeconds: 'HH:mm:ss',
        iconName: 'de',
        momentLocale: 'de',
        months: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
        ],
        name: Locales.German,
    },
};
