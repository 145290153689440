import { effects } from 'redux-saga';
import { IIncidentInfos, IncidentActionTypes } from './Types';

import incidentFile from '../../IncidentOngoing/incident.json';
import { updateIncidentContent } from './Actions';

function* handleIncident() {
    const incidentInfos: IIncidentInfos = {
        incidentMessages: [],
    };
    incidentInfos.incidentMessages = incidentFile.incident;
    yield effects.put(updateIncidentContent(incidentInfos));
}

function* watchIncident() {
    yield effects.takeEvery(IncidentActionTypes.CHECK_INCIDENT_CONTENT, handleIncident);
}

export function* IncidentSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchIncident)]);
}
