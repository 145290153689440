import { action } from 'typesafe-actions';

import { Locales } from './Constants';
import {
    I18N,
    I18nActionTypes,
    ILocaleInfos,
    ILoadErrorAction,
    ILoadSuccessAction,
    ISetLocaleAction,
} from './Types';

export const loadError = (message: string): ILoadErrorAction => action(I18nActionTypes.SET_LOCALE_ERROR, message);
export const loadSuccess = (i18n: I18N, localeInfos: ILocaleInfos): ILoadSuccessAction =>
    action(I18nActionTypes.SET_LOCALE_SUCCESS, { i18n, localeInfos });
export const setLocale = (locale: Locales): ISetLocaleAction => action(I18nActionTypes.SET_LOCALE, locale);
