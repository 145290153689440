import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class TalkGetPerson extends AMessage implements IToCommand {
    private id: string;

    public constructor(id: string) {
        super('talkGetPerson');
        this.id = id;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                TalkGetPersonArg: this.id,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeTalkGetPerson,
            }),
        );
    }
}
