import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import Layout from '../../Components/Layout';
import { SearchDrawer } from '../../Components/Search/Drawer';
import { SearchResults } from '../../Components/Search/Results';
import { IConnectedReduxProps } from '../../Store';
import { I18NState } from '../../Store/I18n';
import { setDrawerContent, setDrawerTitle, setHasAnnotations, setPageContent, setPageTitle } from '../../Store/Layout';

interface IPropsFromState {
    i18n: I18NState;
}

interface IPropsFromDispatch {
    setDrawerContent: typeof setDrawerContent;
    setDrawerTitle: typeof setDrawerTitle;
    setHasAnnotations: typeof setHasAnnotations;
    setPageContent: typeof setPageContent;
    setPageTitle: typeof setPageTitle;
}

type AllProps = IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}> & IConnectedReduxProps;

export class SearchPage extends React.Component<AllProps> {
    public render(): React.ReactNode {
        this.props.setDrawerContent(this.renderDrawerContent());
        this.props.setPageContent(this.renderPageContent());
        this.props.setDrawerTitle('');
        this.props.setPageTitle(this.props.i18n.i18n._('EPG Search'));
        this.props.setHasAnnotations(false);

        return <Route component={Layout} />;
    }

    private renderDrawerContent(): React.ReactNode {
        return <Route component={SearchDrawer} />;
    }

    private renderPageContent(): React.ReactNode {
        return <Route component={SearchResults} />;
    }
}
