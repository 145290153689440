import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class SearchPrograms extends AMessage implements IToCommand {
    private start: number;
    private end: number;
    private medias: number[];
    private kinds: string[];
    private text: string;
    private pageSize: number;
    private page: number;

    public constructor(
        start: Date,
        end: Date,
        medias: number[],
        kinds: string[],
        text: string,
        pageSize: number,
        page: number,
    ) {
        super('searchPrograms');

        if (pageSize < 1) {
            throw new Error('pageSize must be greater than 0');
        }
        this.pageSize = Math.round(pageSize);
        if (page < 1) {
            throw new Error('page must be greater than 0');
        }
        this.page = Math.round(page);
        this.pageSize = Math.round(pageSize);
        this.start = start.getTime();
        this.end = end.getTime();
        this.medias = medias;
        this.kinds = kinds;
        this.text = text;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                SearchProgramArgs: {
                    End: this.end,
                    Kinds: this.kinds,
                    Medias: this.medias,
                    Page: this.page,
                    PageSize: this.pageSize,
                    Start: this.start,
                    Text: this.text,
                },
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeSearchPrograms,
            }),
        );
    }
}
