import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class DeleteMultipleTalks extends AMessage implements IToCommand {
    private talksIDs: string[] = [];

    public constructor(talksIDs: string[]) {
        super('deleteMultipleTalks');
        this.talksIDs = talksIDs;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                DeleteMultipleTalksArg: {
                    IDs: this.talksIDs,
                },
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeDeleteMultipleTalks,
            }),
        );
    }
}
