import { Reducer } from 'redux';

import { IUser, IUserState, UserActionTypes } from './Types';

const defaultUser: IUser = {
    allowedEPGs: [],
    email: '',
    firstName: '',
    functionalities: [],
    getFullName: () => '',
    getShortName: () => '',
    group: 0,
    groupName: '',
    id: 0,
    lastName: '',
    loggedIn: false,
    login: '',
    options: [],
    phone: '',
    session: '',
    share: false,
    shareCount: 0,
    shareParams: null,
};

const initialState: IUserState = {
    loading: true,
    loggedIn: false,
    opSuccessEventElement: document.createElement('div'),
    user: defaultUser,
};

const reducer: Reducer<IUserState> = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                error: undefined,
                loading: false,
                loggedIn: true,
                user: action.payload,
            };
        }

        case UserActionTypes.LOGIN_ERROR: {
            return {
                ...state,
                error: action.payload,
                loggedIn: false,
                user: defaultUser,
            };
        }

        case UserActionTypes.LOGGED_OUT: {
            return {
                ...state,
                error: undefined,
                loading: false,
                loggedIn: false,
                user: defaultUser,
            };
        }

        case UserActionTypes.STORE_USER_OPTIONS: {
            return {
                ...state,
                user: { ...state.user, options: action.payload },
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as UserReducer };
