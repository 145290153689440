import * as Icons from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { edit } from '../../../Store/Archives/Actions';
import { IArchiveFormErrors, IArchiveState } from '../../../Store/Archives/Types';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import { IMediasState } from '../../../Store/Medias';
import { getTheme } from '../../../Themes';
import { ensureString } from '../../../Utils/String';
import { isNullOrUndefined, isNullOrUndefinedOrZero } from '../../../Utils/Various';

import missingVignette from '../../../Images/missingVignette.jpg';

interface IOwnProps {
    archive: mediaarchiver.IArchive;
    onClose: () => void;
    open: boolean;
}

interface IState {
    errors: IArchiveFormErrors;
    values: mediaarchiver.IArchive;
}

interface IPropsFromState {
    archives: IArchiveState;
    i18n: I18N;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
}

interface IPropsFromDispatch {
    edit: typeof edit;
}

const theme = getTheme();

const styles = MD.createStyles({
    container: {},
    date: {
        color: '#e1f995',
        fontWeight: 300,
    },
    dialogTags: {
        marginTop: 5,
    },
    duration: {
        backgroundColor: '#000',
        bottom: 0,
        color: '#ff7c72',
        fontWeight: 300,
        paddingRight: 3,
        position: 'absolute',
        right: 0,
        zIndex: 2,
    },
    image: {
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 1,
    },
    imageContainer: {
        height: 70,
        maxWidth: 130,
        minWidth: 130,
        position: 'relative',
    },
    item: {
        border: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(3),
        padding: theme.spacing(1),
    },
    itemContainer: {
        padding: theme.spacing(1),
    },
    media: {
        color: '#7cbff9',
        fontWeight: 300,
    },
    name: {
        fontWeight: 400,
    },
    text: {
        '&>span': {
            display: 'block',
            fontSize: '1.1em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
        },

        maxWidth: 'calc(100% - 130px)',
        paddingLeft: theme.spacing(1),
    },
});

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    IOwnProps &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class EditItemComponent extends React.Component<AllProps, IState> {
    private errorCallback: (ev: CustomEvent<IArchiveFormErrors>) => void;
    private successCallback: (ev: CustomEvent<{}>) => void;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
        this.errorCallback = (ev: CustomEvent<IArchiveFormErrors>) => {
            this.setState({ errors: ev.detail });
        };
        this.successCallback = () => {
            this.onClose();
        };
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public componentDidMount(): void {
        this.props.archives.opSuccessEventElement.addEventListener<any>('opError', this.errorCallback);
        this.props.archives.opSuccessEventElement.addEventListener<any>('opSuccess', this.successCallback);
    }

    public componentWillUnmount(): void {
        this.props.archives.opSuccessEventElement.removeEventListener<any>('opError', this.errorCallback);
        this.props.archives.opSuccessEventElement.removeEventListener<any>('opSuccess', this.successCallback);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    public render(): React.ReactNode {
        const classes = this.props.classes;
        const tags = Array.isArray(this.state.values.Tags) ? this.state.values.Tags : [];

        return (
            <MD.Dialog
                className={classes.container}
                fullWidth={true}
                maxWidth='md'
                onClose={this.onClose.bind(this)}
                open={this.props.open}
            >
                <MD.DialogTitle>{this.props.i18n._('Edit archive')}</MD.DialogTitle>
                <MD.DialogContent>
                    <MD.Grid container>
                        <MD.Grid item xs={12}>
                            <MD.Box className={classes.item}>
                                <MD.Box className={classes.imageContainer}>
                                    <img
                                        className={classes.image}
                                        onError={this.onVignetteError.bind(this)}
                                        src={this.getVignetteURL()}
                                    />
                                    <div className={classes.duration}>{this.getDuration()}</div>
                                </MD.Box>
                                <MD.Box className={classes.text}>
                                    <MD.Typography className={classes.name} variant='inherit'>
                                        {ensureString(this.props.archive.Name)}
                                    </MD.Typography>
                                    <MD.Typography className={classes.media} variant='inherit'>
                                        {ensureString(this.getMediaName())}
                                    </MD.Typography>
                                    <MD.Typography className={classes.date} variant='inherit'>
                                        {ensureString(this.getStart())}
                                    </MD.Typography>
                                </MD.Box>
                            </MD.Box>
                        </MD.Grid>
                        <MD.Grid item xs={12}>
                            <MD.TextField
                                disabled={this.props.archives.operationInProgress}
                                error={this.state.errors.name !== ''}
                                fullWidth
                                helperText={this.state.errors.name}
                                label={this.props.i18n._('Name')}
                                onChange={this.onNameChange.bind(this)}
                                value={this.state.values.Name}
                            />
                            <MD.FormControl
                                className={this.props.classes.dialogTags}
                                disabled={this.props.archives.operationInProgress}
                                fullWidth
                            >
                                <ChipInput
                                    label={this.props.i18n._('Archive tags (press enter to add another tag)')}
                                    onAdd={this.handleTagAdd.bind(this)}
                                    onDelete={this.handleTagDelete.bind(this)}
                                    defaultValue={tags}
                                    required={true}
                                    value={tags}
                                />
                            </MD.FormControl>
                            <MD.TextField
                                disabled={this.props.archives.operationInProgress}
                                label={this.props.i18n._('Comment')}
                                fullWidth
                                multiline
                                onChange={this.onCommentChange.bind(this)}
                                rows={4}
                                value={this.state.values.Comment}
                            />
                            {this.state.errors.general !== '' ? (
                                <MD.FormHelperText>{this.state.errors.general}</MD.FormHelperText>
                            ) : (
                                ''
                            )}
                        </MD.Grid>
                    </MD.Grid>
                </MD.DialogContent>
                <MD.DialogActions>
                    <MD.Button
                        color='primary'
                        disabled={this.props.archives.operationInProgress}
                        onClick={this.onClose.bind(this)}
                        title={this.props.i18n._('Cancel')}
                    >
                        {this.props.i18n._('Cancel')}
                    </MD.Button>
                    <MD.Button
                        color='secondary'
                        disabled={this.props.archives.operationInProgress}
                        onClick={() => {
                            this.props.edit(this.state.values);
                        }}
                        title={this.props.i18n._('Save your changes and edit review')}
                    >
                        {this.props.archives.operationInProgress ? (
                            <FontAwesomeIcon icon={Icons.faSpinner} spin />
                        ) : (
                            this.props.i18n._('Save')
                        )}
                    </MD.Button>
                </MD.DialogActions>
            </MD.Dialog>
        );
    }

    private getDuration(): string {
        const durationMS = this.props.archive.DurationMS || 0;
        const duration = moment.duration(durationMS);

        return (
            ('0' + duration.hours().toString()).slice(-2) +
            ':' +
            ('0' + duration.minutes().toString()).slice(-2) +
            ':' +
            ('0' + duration.seconds().toString()).slice(-2)
        );
    }

    private getMediaName(): string {
        const mediaID = this.props.archive.MediaID || 0;
        let mediaName = 'N/A';

        this.props.medias.data.some((media) => {
            if (media.id === mediaID) {
                mediaName = media.name;
                return true;
            }
            return false;
        });
        return mediaName;
    }

    private getStart(): string {
        if (isNullOrUndefinedOrZero(this.props.archive.Start)) {
            return '';
        }
        return moment(new Date(this.props.archive.Start as number)).format(
            this.props.localeInfos.formatShortDateTimeWithSeconds,
        );
    }

    private onClose() {
        this.setState(this.getDefaultState());
        this.props.onClose();
    }

    private getVignetteURL(): string {
        const url = ensureString(this.props.archive.VignetteURL);

        return url === '' ? missingVignette : url;
    }

    private onVignetteError(ev: React.SyntheticEvent<HTMLImageElement>): void {
        if (isNullOrUndefined(ev.target)) {
            return;
        }
        ev.currentTarget.src = missingVignette;
    }

    private onNameChange(ev: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            ...this.state,

            values: {
                ...this.state.values,

                Name: ev.target.value,
            },
        });
    }

    private onCommentChange(ev: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            ...this.state,

            values: {
                ...this.state.values,

                Comment: ev.target.value,
            },
        });
    }

    private handleTagAdd(tag: string) {
        const tags = Array.isArray(this.state.values.Tags) ? this.state.values.Tags : [];

        this.setState({
            ...this.state,

            values: {
                ...this.state.values,

                Tags: tags.concat(tag),
            },
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleTagDelete(tagToRemove: any) {
        const tags = Array.isArray(this.state.values.Tags) ? this.state.values.Tags : [];

        this.setState({
            ...this.state,
            values: {
                ...this.state.values,

                Tags: tags.filter((tag) => tag !== tagToRemove),
            },
        });
    }

    private getDefaultState(): IState {
        return {
            errors: {
                comment: '',
                general: '',
                name: '',
                toContacts: '',
                // toFTP: '',
                toReview: '',
                toSFTP: '',
            },
            values: { ...this.props.archive },
        };
    }
}

const mapStateToProps = ({ archives, i18n, medias }: IApplicationState) => ({
    archives,
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    medias,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    edit: (archive: mediaarchiver.IArchive) => dispatch(edit(archive)),
});

export const EditItem = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(EditItemComponent));
