import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class EditFTPAccount extends AMessage implements IToCommand {
    private account: Proto.mediaarchiver.IFTPAccount;

    public constructor(account: Proto.mediaarchiver.IFTPAccount) {
        super('editFTPAccount');
        this.account = account;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                EditFTPAccountArgs : this.account,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeEditFTPAccount,
            }),
            10000,
        );
    }
}
