import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';

import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class SearchSpeechesDateResult extends AMessage implements IFromCommand {
    private parsedTotal: number | null = null;
    private parsedResults: Proto.mediaarchiver.ISearchSpeechesWord[] | null = null;

    public constructor() {
        super('searchSpeechesDateResult');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg)) {
                return reject(new Error('Empty message'));
            }
            if (isNullOrUndefined(msg.SearchSpeechesDateResultArgs)) {
                return reject(new Error('Not a SearchSpeechesDateResult message'));
            }
            this.parsedTotal = !isNullOrUndefined(msg.SearchSpeechesDateResultArgs.Count)
                ? msg.SearchSpeechesDateResultArgs.Count
                : 0;
            this.parsedResults = !isNullOrUndefined(msg.SearchSpeechesDateResultArgs.Result)
                ? msg.SearchSpeechesDateResultArgs.Result
                : [];
            resolve();
        });
    }

    public get total(): number {
        if (isNull(this.parsedTotal)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedTotal;
    }

    public get results(): Proto.mediaarchiver.ISearchSpeechesWord[] {
        if (isNull(this.parsedResults)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedResults;
    }
}
