import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos } from '../../Store/I18n';
import { getPanels, update as getMedias } from '../../Store/Medias/Actions';
import { IMediasState } from '../../Store/Medias/Types';
import { isNullOrUndefined } from '../../Utils/Various';
import { MediaList } from '../Medias/List';

const styles = MD.createStyles({
    listButton: {
        '& span': {
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
});

interface IState {
    dialogOpen: boolean;
    medias: number[];
}

interface IOwnProps {
    initialMedias?: number[];
    onChange?: (medias: number[]) => void;
    variant?: 'text' | 'outlined' | 'contained';
}

interface IPropsFromState {
    id?: string;
    i18n: I18N;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
}

interface IPropsFromDispatch {
    getMedias: typeof getMedias;
    getPanels: typeof getPanels;
}

type AllProps = MD.WithStyles<typeof styles> &
    IOwnProps &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class MediaSelectorComponent extends React.Component<AllProps, IState> {
    private initialized = false;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState(props);
    }

    public componentDidMount(): void {
        // this.props.getMedias();
        // this.props.getPanels();
    }

    public render(): React.ReactNode {
        if (!this.initialized) {
            if (this.state.medias.length > 0) {
                this.initialized = true;
            } else {
                this.props.medias.panels.tvs.forEach((panel) => {
                    if (panel.ID === 101 && Array.isArray(panel.Medias)) {
                        const medias = panel.Medias.filter((id) =>
                            this.props.medias.data.some((media) => {
                                return media.id === id;
                            }),
                        );
                        // const medias = panel.Medias;
                        window.setTimeout(() => {
                            this.setMedias(medias);
                        }, 500);
                        this.initialized = true;
                    }
                });
            }
        }

        let selected = '';

        // Only show 3 names
        if (this.state.medias.length === 0) {
            selected = this.props.i18n._('No medias selected');
        } else if (this.state.medias.length < 4) {
            const medias: string[] = [];
            this.props.medias.data.forEach((media) => {
                if (this.state.medias.indexOf(media.id) !== -1) {
                    medias.push(media.name);
                }
            });
            selected = medias.join(', ');
            if (medias.length > 1) {
                selected = `(${medias.length}) ${selected}`;
            }
        } else {
            selected = this.props.i18n.sprintf(
                this.props.i18n.ngettext('%1$d selected', '%1$d selected', this.state.medias.length),
                this.state.medias.length,
            );
        }

        return (
            <>
                <MD.Button
                    className={this.props.classes.listButton}
                    id={this.props.id}
                    onClick={this.openDialog.bind(this)}
                    variant={this.props.variant}
                >
                    {selected}
                </MD.Button>
                <MD.Dialog onClose={this.closeDialog.bind(this)} open={this.state.dialogOpen}>
                    <MD.DialogTitle>{this.props.i18n._('Select medias')}</MD.DialogTitle>
                    <MD.DialogContent>
                        <Route
                            render={(props) => (
                                <MediaList
                                    {...props}
                                    dispatch={this.props.dispatch}
                                    initialMedias={this.state.medias}
                                    onChange={this.setMedias.bind(this)}
                                />
                            )}
                        />
                    </MD.DialogContent>
                    <MD.DialogActions>
                        <MD.Button onClick={this.closeDialog.bind(this)} variant='text'>
                            {this.props.i18n._('Close')}
                        </MD.Button>
                    </MD.DialogActions>
                </MD.Dialog>
            </>
        );
    }

    private openDialog(): void {
        this.setState({ dialogOpen: true });
    }

    private closeDialog(): void {
        this.setState({ dialogOpen: false });
    }

    private setMedias(medias: number[]) {
        this.setState({ medias });
        if (!isNullOrUndefined(this.props.onChange)) {
            this.props.onChange(medias);
        }
    }

    private getDefaultState(props: AllProps): IState {
        let medias: number[] = [];

        if (!isNullOrUndefined(props.initialMedias)) {
            medias = props.initialMedias;
        }
        return {
            dialogOpen: false,
            medias,
        };
    }
}

const mapStateToProps = ({ i18n, medias }: IApplicationState) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    medias,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getMedias: () => dispatch(getMedias()),
    getPanels: () => dispatch(getPanels()),
});

export const MediaSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(MediaSelectorComponent));
