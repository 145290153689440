import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetContactsCount extends AMessage implements IToCommand {
    public constructor() {
        super('getContactsCount');
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetContactsCount,
        }));
    }
}
