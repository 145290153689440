import { Store } from 'redux';
import { effects } from 'redux-saga';

import { IToCommand } from '../../Backend/Commands';
import { CreateAnnotationType } from '../../Backend/Commands/CreateAnnotationType';
import { DeleteAnnotationType } from '../../Backend/Commands/DeleteAnnotationType';
import { EditAnnotationType } from '../../Backend/Commands/EditAnnotationType';
import { GetAnnotationTypes } from '../../Backend/Commands/GetAnnotationTypes';
import { GetAnnotationTypesCount } from '../../Backend/Commands/GetAnnotationTypesCount';
import { GetAnnotationTypesOwned } from '../../Backend/Commands/GetAnnotationTypesOwned';
import { GetAnnotationTypesOwnedCount } from '../../Backend/Commands/GetAnnotationTypesOwnedCount';

import { IApplicationState } from '..';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import { create, get, getOwned, setErrors, setOpSuccess, setProgress, typesUpdate } from './Actions';
import { AnnotationTypesActionTypes } from './Types';

function* handleGet(action: ReturnType<typeof get>) {
    const cmd = new GetAnnotationTypes(action.payload.offset, action.payload.limit);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get annotation types');
    }
}

function* handleGetOwned(action: ReturnType<typeof getOwned>) {
    const cmd = new GetAnnotationTypesOwned(action.payload.offset, action.payload.limit);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get annotation types (owned)');
    }
}

function* handleGetCount() {
    const cmd = new GetAnnotationTypesCount();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get annotation types count');
    }
}

function* handleGetOwnedCount() {
    const cmd = new GetAnnotationTypesOwnedCount();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get annotation types count (owned)');
    }
}

function* handleOps(actionType: string, action: ReturnType<typeof create>) {
    const str = store as Store<IApplicationState>;
    let command: IToCommand;

    switch (actionType) {
        case 'create':
            command = new CreateAnnotationType(action.payload);
            break;
        case 'edit':
            command = new EditAnnotationType(action.payload);
            break;
        case 'delete':
            command = new DeleteAnnotationType(action.payload);
            break;
        default:
            throw new Error('Unknown ops action type');
    }

    str.dispatch(setProgress(true));
    try {
        yield effects.call(command.Send.bind(command));
        yield effects.call(str.dispatch.bind(str, typesUpdate(action.payload, actionType)));
        yield effects.call(str.dispatch.bind(str, setOpSuccess(action.payload)));
        yield effects.call(str.dispatch.bind(str, get(0, 1000)));
    } catch (err) {
        try {
            const data = JSON.parse((err as Error).message);

            yield effects.call(
                str.dispatch.bind(
                    str,
                    setErrors({
                        color: data.color ? str.getState().i18n.i18n._(data.color) : '',
                        general: data.general ? str.getState().i18n.i18n._(data.general) : '',
                        name: data.name ? str.getState().i18n.i18n._(data.name) : '',
                    }),
                ),
            );
        } catch (err2) {
            Logger.warn({ original: err, parsing: err2 }, 'Unable to parse error message');
            yield effects.call(
                str.dispatch.bind(
                    str,
                    setErrors({
                        color: '',
                        general: 'N/A',
                        name: '',
                    }),
                ),
            );
        }
        Logger.warn(err as Error, 'Failed to create annotation type');
    }
    yield effects.call(str.dispatch.bind(str, setProgress(false)));
}

function* watchGet() {
    yield effects.takeEvery(AnnotationTypesActionTypes.GET, handleGet);
    yield effects.takeEvery(AnnotationTypesActionTypes.GET_OWNED, handleGetOwned);
}

function* watchGetCount() {
    yield effects.takeEvery(AnnotationTypesActionTypes.GET_COUNT, handleGetCount);
    yield effects.takeEvery(AnnotationTypesActionTypes.GET_OWNED_COUNT, handleGetOwnedCount);
}

function* watchCreate() {
    yield effects.takeEvery(AnnotationTypesActionTypes.CREATE, handleOps.bind(null, 'create'));
}
function* watchEdit() {
    yield effects.takeEvery(AnnotationTypesActionTypes.EDIT, handleOps.bind(null, 'edit'));
}
function* watchDelete() {
    yield effects.takeEvery(AnnotationTypesActionTypes.DELETE, handleOps.bind(null, 'delete'));
}

export function* AnnotationTypesSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchGet)]);
    yield effects.all([effects.fork(watchGetCount)]);
    yield effects.all([effects.fork(watchCreate)]);
    yield effects.all([effects.fork(watchEdit)]);
    yield effects.all([effects.fork(watchDelete)]);
}
