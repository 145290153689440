import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { ITimelinePopoversState } from '../../../Store/TimelinePopovers/Types';
import { limitText } from '../../../Utils/String';
import { isNullOrUndefined } from '../../../Utils/Various';

interface IPropsFromState {
    timelinePopovers: ITimelinePopoversState;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

const styles = MD.createStyles({
    popover: {
        display: 'none',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 2000,
    },
    popoverContent: {
        '& h5': {
            fontSize: '1.5em', //'1em',
        },
        '& h6': {
            fontSize: '1.2em', //'0.7em',
            fontStyle: 'italic',
            marginTop: 5,
            textTransform: 'uppercase',
        },
        '& p': {
            '& span': {
                fontStyle: 'italic',
            },
            fontSize: '0.7em',
        },
        '& p:first-of-type': {
            marginTop: 10,
        },
        flex: '1 0 auto',
        wordWrap: 'break-word',
    },
    popoverDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 340,
    },
});

export class PopoverBrutPhonosenseComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        return (
            <MD.Card className={this.props.classes.popover} id='rawPopover'>
                <div className={this.props.classes.popoverDetails}>
                    <MD.CardContent className={this.props.classes.popoverContent}>
                        <MD.Typography component='h5' variant='h5'>
                            {limitText(
                                this.props.timelinePopovers.popoverBrutPhonosense
                                    ? this.props.timelinePopovers.popoverBrutPhonosense.title
                                    : '',
                                150,
                            )}
                        </MD.Typography>
                        {this.props.timelinePopovers.popoverBrutPhonosense &&
                        this.props.timelinePopovers.popoverBrutPhonosense.categoryText ? (
                            <MD.Typography
                                component='h6'
                                style={{
                                    color: '#dddddd',
                                }}
                                variant='h6'
                            >
                                {limitText(
                                    this.props.timelinePopovers.popoverBrutPhonosense
                                        ? this.props.timelinePopovers.popoverBrutPhonosense.categoryText
                                        : '',
                                    150,
                                )}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverBrutPhonosense &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas['start_date'] ? (
                            <MD.Typography component='p'>
                                <span>start_date</span> : &nbsp;
                                {this.props.timelinePopovers.popoverBrutPhonosense.metas['start_date']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}

                        {this.props.timelinePopovers.popoverBrutPhonosense &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas['end_date'] ? (
                            <MD.Typography component='p'>
                                <span>end_date</span> : &nbsp;
                                {this.props.timelinePopovers.popoverBrutPhonosense.metas['end_date']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}

                        {this.props.timelinePopovers.popoverBrutPhonosense &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas['duration'] ? (
                            <MD.Typography component='p'>
                                <span>duration</span> : &nbsp;
                                {this.props.timelinePopovers.popoverBrutPhonosense.metas['duration']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}

                        {this.props.timelinePopovers.popoverBrutPhonosense &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas['title'] ? (
                            <MD.Typography component='p'>
                                <span>title</span> : &nbsp;
                                {this.props.timelinePopovers.popoverBrutPhonosense.metas['title']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}

                        {this.props.timelinePopovers.popoverBrutPhonosense &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas['description'] ? (
                            <MD.Typography component='p'>
                                <span>description</span> : &nbsp;
                                {this.props.timelinePopovers.popoverBrutPhonosense.metas['description']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}

                        {this.props.timelinePopovers.popoverBrutPhonosense &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas &&
                        this.props.timelinePopovers.popoverBrutPhonosense.metas['type'] ? (
                            <MD.Typography component='p'>
                                <span>type</span> : &nbsp;
                                {this.props.timelinePopovers.popoverBrutPhonosense.metas['type']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                    </MD.CardContent>
                </div>
            </MD.Card>
        );
    }
}

const mapStateToProps = ({ timelinePopovers }: IApplicationState) => ({
    timelinePopovers,
});

export const PopoverBrutPhonosense = connect(mapStateToProps)(MD.withStyles(styles)(PopoverBrutPhonosenseComponent));
