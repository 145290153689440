import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class Archives extends AMessage implements IFromCommand {
    private parsedCount: number | null = null;
    private parsedOffset: number | null = null;
    private parsedArchives: Proto.mediaarchiver.IArchive[] | null = null;

    public constructor() {
        super('archives');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.ArchivesArg)) {
                return reject(new Error('Not a Archives message'));
            }
            if (isNullOrUndefined(msg.ArchivesArg.Count)) {
                return reject(new Error('Invalid count'));
            }
            if (isNullOrUndefined(msg.ArchivesArg.Offset)) {
                return reject(new Error('Invalid offset'));
            }
            if (isNullOrUndefined(msg.ArchivesArg.List)) {
                return reject(new Error('No archive list'));
            }
            this.parsedCount = msg.ArchivesArg.Count;
            this.parsedOffset = msg.ArchivesArg.Offset;
            this.parsedArchives = msg.ArchivesArg.List;
            resolve();
        });
    }

    public get count(): number {
        if (isNull(this.parsedCount)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedCount;
    }

    public get offset(): number {
        if (isNull(this.parsedOffset)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedOffset;
    }

    public get archives(): Proto.mediaarchiver.IAnnotation[] {
        if (isNull(this.parsedArchives)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedArchives;
    }
}
