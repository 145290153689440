import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import * as Proto from '../../../Protos/protos';
import { IApplicationState } from '../../../Store';
import {
    IAnnotationTypesErrors,
} from '../../../Store/AnnotationTypes';
import {
    create,
    deleteType,
    edit,
    getOwned,
    getOwnedCount,
    setErrors,
} from '../../../Store/AnnotationTypes/Actions';
import { setPageTitle } from '../../../Store/Layout';
import AccountAnnotationTypesComponent, {
    IRouterMatchParams,
} from './AnnotationTypes';
import styles from './styles';

const mapStateToProps = (
    { annotationTypes, i18n, user }: IApplicationState,
    ownProps: RouteComponentProps<IRouterMatchParams>,
) => ({
    annotationTypes,
    i18n: i18n.i18n,
    router: ownProps,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    create: (type: Proto.mediaarchiver.AnnotationType) =>
        dispatch(create(type)),
    delete: (type: Proto.mediaarchiver.AnnotationType) =>
        dispatch(deleteType(type)),
    edit: (type: Proto.mediaarchiver.AnnotationType) => dispatch(edit(type)),
    getOwned: (offset = 0, limit = 100) =>
        dispatch(getOwned(offset, limit)),
    getOwnedCount: () => dispatch(getOwnedCount()),
    setErrors: (errors: IAnnotationTypesErrors) => dispatch(setErrors(errors)),
    setPageTitle: (title: string) => dispatch(setPageTitle(title)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(AccountAnnotationTypesComponent));
