import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import DefaultImage from '../../../Images/icon-no-image.svg';
import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { I18N } from '../../../Store/I18n/Types';
import { ITimelinePopoversState } from '../../../Store/TimelinePopovers/Types';
import { limitText } from '../../../Utils/String';

interface IPropsFromState {
    i18n: I18N;
    timelinePopovers: ITimelinePopoversState;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

const styles = MD.createStyles({
    popover: {
        display: 'none',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 2000,
    },
    popoverContent: {
        '& h5': {
            fontSize: '1em',
        },
        '& h6': {
            fontSize: '1em',
            fontStyle: 'italic',
            marginTop: 3,
        },
        '& p': {
            '& span': {
                fontStyle: 'italic',
            },
            fontSize: '0.7em',
        },
        '& p:first-of-type': {
            marginTop: 10,
        },
        flex: '1 0 auto',
        wordWrap: 'break-word',
    },
    popoverCover: {
        '& img': {
            width: '100%',
        },
        width: 100,
    },
    popoverDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 340,
    },
});

export class PopoverMusicComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        return (
            <MD.Card className={this.props.classes.popover} id='musicPopover'>
                <div className={this.props.classes.popoverCover}>
                    <img
                        id='musicPopoverImage'
                        src={DefaultImage}
                        title={
                            this.props.timelinePopovers.popoverMusic
                                ? this.props.timelinePopovers.popoverMusic.title
                                : ''
                        }
                    />
                </div>
                <div className={this.props.classes.popoverDetails}>
                    <MD.CardContent className={this.props.classes.popoverContent}>
                        {this.props.timelinePopovers.popoverMusic &&
                        this.props.timelinePopovers.popoverMusic.metas &&
                        this.props.timelinePopovers.popoverMusic.metas.artist ? (
                            <MD.Typography component='h5' variant='h5'>
                                {limitText(this.props.timelinePopovers.popoverMusic.metas.artist, 150)}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        <MD.Typography component='h6' variant='h6'>
                            {limitText(
                                this.props.timelinePopovers.popoverMusic
                                    ? this.props.timelinePopovers.popoverMusic.title
                                    : '',
                                150,
                            )}
                        </MD.Typography>
                        {this.props.timelinePopovers.popoverMusic &&
                        this.props.timelinePopovers.popoverMusic.metas &&
                        this.props.timelinePopovers.popoverMusic.metas.genre ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Musical genre')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverMusic.metas.genre}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverMusic &&
                        this.props.timelinePopovers.popoverMusic.metas &&
                        this.props.timelinePopovers.popoverMusic.metas['release date'] ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Release date')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverMusic.metas['release date']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverMusic &&
                        this.props.timelinePopovers.popoverMusic.metas &&
                        this.props.timelinePopovers.popoverMusic.metas.language ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Lyrics language')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverMusic.metas.language}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                    </MD.CardContent>
                </div>
            </MD.Card>
        );
    }
}

const mapStateToProps = ({ i18n, timelinePopovers }: IApplicationState) => ({
    i18n: i18n.i18n,
    timelinePopovers,
});

export const PopoverMusic = connect(mapStateToProps)(MD.withStyles(styles)(PopoverMusicComponent));
