export enum IncidentActionTypes {
    CHECK_INCIDENT_CONTENT = '@@incident/CHECK_INCIDENT_CONTENT',
    HIDE_INCIDENT = '@@timeline/HIDE_INCIDENT',
    SHOW_INCIDENT = '@@incident/SHOW_INCIDENT',
    UPDATE_INCIDENT_CONTENT = '@@incident/UPDATE_INCIDENT_CONTENT',
}

export interface IIncidentState {
    readonly showIncident: boolean;
    readonly incidentContent: IIncidentInfos;
}

export interface IIncidentInfos {
    incidentMessages: IIncidentInfo[];
}

export interface IIncidentInfo {
    title: string;
    message: string;
}

export interface ICheckIncidentContentAction {
    type: IncidentActionTypes.CHECK_INCIDENT_CONTENT;
}

export interface IHideIncidentAction {
    type: IncidentActionTypes.HIDE_INCIDENT;
}

export interface IShowIncidentAction {
    type: IncidentActionTypes.SHOW_INCIDENT;
}

export interface IUpdateIncidentContentAction {
    type: IncidentActionTypes.UPDATE_INCIDENT_CONTENT;
}
