import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNull, isNullOrUndefinedOrEmptyString } from '../../Utils/Various';

export class AnnotationDirect extends AMessage implements IFromCommand {
    private parsedLink: string | null = null;

    public constructor() {
        super('annotationDirect');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefinedOrEmptyString(msg.AnnotationDirectArg)) {
                return reject(new Error('Not a AnnotationDirect message'));
            }
            this.parsedLink = msg.AnnotationDirectArg;
            resolve();
        });
    }

    public get link(): string {
        if (isNull(this.parsedLink)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedLink;
    }
}
