import * as Proto from '../../Protos/protos';

export enum AnnotationTypesActionTypes {
    CREATE = '@@annotationTypes/CREATE',
    DELETE = '@@annotationTypes/DELETE',
    EDIT = '@@annotationTypes/EDIT',
    GET = '@@annotationTypes/GET',
    GET_COUNT = '@@annotationTypes/GET_COUNT',
    GET_OWNED = '@@annotationTypes/GET_OWNED',
    GET_OWNED_COUNT = '@@annotationTypes/GET_OWNED_COUNT',
    OP_SUCCESS = '@@annotationTypes/OP_SUCCESS',
    SET_COUNT = '@@annotationTypes/SET_COUNT',
    SET_ERRORS = '@@annotationTypes/SET_ERRORS',
    SET_OP_PROGRESS = '@@annotationTypes/SET_OP_PROGRESS',
    SET_OWNED_COUNT = '@@annotationTypes/SET_OWNED_COUNT',
    UPDATE = '@@annotationTypes/UPDATE',
    UPDATE_LIST = '@@annotationTypes/UPDATE_LIST',
    UPDATE_OWNED_LIST = '@@annotationTypes/UPDATE_OWNED_LIST'
}

export type NullableAnnotationType = Proto.mediaarchiver.AnnotationType | null;
export type NullableAnnotationTypeList = NullableAnnotationType[];

export interface IAnnotationTypesErrors {
    color: string;
    general: string;
    name: string;
}

export interface IAnnotationTypesState {
    readonly annotationTypes: NullableAnnotationTypeList;
    readonly annotationTypesOwned: NullableAnnotationTypeList;
    readonly formErrors: IAnnotationTypesErrors;
    readonly opSuccessEventElement: HTMLElement;
    readonly operationInProgress: boolean;
    readonly total: number;
    readonly totalOwned: number;
}

export interface ICreateAction {
    payload: Proto.mediaarchiver.AnnotationType;
    type: AnnotationTypesActionTypes.CREATE;
}

export interface IDeleteTypeAction {
    payload: Proto.mediaarchiver.AnnotationType;
    type: AnnotationTypesActionTypes.DELETE;
}

export interface IEditAction {
    payload: Proto.mediaarchiver.AnnotationType;
    type: AnnotationTypesActionTypes.EDIT;
}

export interface IGetActionPayload {
    limit: number;
    offset: number;
}

export interface IGetAction {
    payload: IGetActionPayload;
    type: AnnotationTypesActionTypes.GET;
}

export interface IGetCountAction {
    type: AnnotationTypesActionTypes.GET_COUNT;
}

export interface IGetOwnedActionPayload {
    limit: number;
    offset: number;
}

export interface IGetOwnedAction {
    payload: IGetOwnedActionPayload;
    type: AnnotationTypesActionTypes.GET_OWNED;
}

export interface IGetOwnedCountAction {
    type: AnnotationTypesActionTypes.GET_OWNED_COUNT;
}

export interface ISetErrorsAction {
    payload: IAnnotationTypesErrors;
    type: AnnotationTypesActionTypes.SET_ERRORS;
}

export interface ISetOpSuccessAction {
    payload: Proto.mediaarchiver.AnnotationType;
    type: AnnotationTypesActionTypes.OP_SUCCESS;
}

export interface ISetProgressAction {
    payload: boolean;
    type: AnnotationTypesActionTypes.SET_OP_PROGRESS;
}

export interface ITypesUpdateAction {
    payload: [Proto.mediaarchiver.AnnotationType, string];
    type: AnnotationTypesActionTypes.UPDATE;
}
