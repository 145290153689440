import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class SFTPAccounts extends AMessage implements IFromCommand {
    private parsedCount: number | null = null;
    private parsedOffset: number | null = null;
    private parsedAccounts: Proto.mediaarchiver.ISFTPAccount[] | null = null;

    public constructor() {
        super('sftpAccounts');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.SFTPAccountsArgs)) {
                return reject(new Error('Not a SFTPAccounts message'));
            }
            if (isNullOrUndefined(msg.SFTPAccountsArgs.Count)) {
                return reject(new Error('Invalid count'));
            }
            if (isNullOrUndefined(msg.SFTPAccountsArgs.Offset)) {
                return reject(new Error('Invalid offset'));
            }
            if (isNullOrUndefined(msg.SFTPAccountsArgs.Accounts)) {
                return reject(new Error('No account list'));
            }
            this.parsedCount = msg.SFTPAccountsArgs.Count;
            this.parsedOffset = msg.SFTPAccountsArgs.Offset;
            this.parsedAccounts = msg.SFTPAccountsArgs.Accounts;
            resolve();
        });
    }

    public get count(): number {
        if (isNull(this.parsedCount)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedCount;
    }

    public get offset(): number {
        if (isNull(this.parsedOffset)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedOffset;
    }

    public get acounts(): Proto.mediaarchiver.ISFTPAccount[] {
        if (isNull(this.parsedAccounts)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedAccounts;
    }
}
