import * as Proto from '../../Protos/protos';
import { AMessage, IFromCommand } from './';

export class MessageAck extends AMessage implements IFromCommand {

    private messageID = '';
    private success = true;
    private reason = '';

    public constructor() {
        super('messageAck');
    }

    public async Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        if ((msg.MessageAckArgs === null) || (msg.MessageAckArgs === undefined)) {
            return Promise.reject(new Error('Not a MessageAck message'));
        }
        if ((msg.MessageAckArgs.MessageID === '') || (msg.MessageAckArgs.MessageID === undefined)) {
            return Promise.reject(new Error('No message ID'));
        }
        this.messageID = msg.MessageAckArgs.MessageID as string;
        this.success = !!msg.MessageAckArgs.Success;
        if (
            (msg.MessageAckArgs.Error !== '') &&
            (msg.MessageAckArgs.Error !== null) &&
            (msg.MessageAckArgs.Error !== undefined)
        ) {
            this.reason = msg.MessageAckArgs.Error;
        }
        return Promise.resolve();
    }

    public GetMessageID(): string {
        return this.messageID;
    }

    public IsSuccess(): boolean {
        return this.success;
    }

    public GetReason(): string {
        return this.reason;
    }
}
