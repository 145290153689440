import Hls, * as HLS from 'hls.js';

import { Config } from '../config';
import { mediaarchiver } from '../Protos/protos';
import { MediaTypes } from '../Store/Medias/Types';
import { PlayerStatus } from '../Store/Player/Constant';
import { Logger } from '../Utils/Logger';
import { getDateInTz } from '../Utils/Time';
import { TypedStorage } from '../Utils/TypedStorage';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyArray, isUndefined } from '../Utils/Various';
import { IPlayer } from './Player';

export class PlayerStreamer implements IPlayer {
    private container: HTMLElement;
    private fragments: HLS.Fragment[] = [];
    private hls: Hls;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private hlsBindings: Map<string, any>;
    private manifestParsed = false;
    private mediaID: number;
    private pauseAt: Date | undefined;
    private playerElem: HTMLVideoElement;
    private playerType: MediaTypes;
    private readyForCapture = false;
    private startAt: Date | undefined;
    private startStatus = false;
    private status: PlayerStatus;
    private statusCB: (status: PlayerStatus) => void;
    private timeUpdateCB: (time: Date) => void;

    private tlEnd: Date;
    private tlStart: Date;
    private tz: string;
    private token: string;

    public constructor(
        container: HTMLElement,
        media: number,
        type: MediaTypes,
        start: Date,
        end: Date,
        token: string,
        tz: string,
    ) {
        this.container = container;
        this.hlsBindings = this.getHLSBindings();
        this.mediaID = media;
        this.playerElem = this.createPlayer();
        this.playerType = type;
        this.status = PlayerStatus.LOADING;
        this.statusCB = () => {
            /* */
        };
        this.timeUpdateCB = () => {
            /* */
        };
        this.tlStart = start;
        this.tz = tz ? tz : 'Europe/Paris';
        this.tlEnd = end;
        this.token = token;

        this.hls = this.createHLS();
        this.setStatus(PlayerStatus.LOADING);

        this.container.appendChild(this.playerElem);
        return;
    }

    public askFullscreen(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.playerElem as any)
            .requestFullscreen({
                navigationUI: 'hide',
            })
            .catch((err: Error) => {
                Logger.warn({ err }, 'Failed requesting fullscreen');
            });
    }
    public cancelPauseAt(): void {
        this.pauseAt = undefined;
    }

    public destroy(): void {
        this.playerElem.pause();
        this.destroyHLS();
        this.readyForCapture = false;
        this.pauseAt = undefined;
        this.statusCB = () => {
            /* */
        };
        this.timeUpdateCB = () => {
            /* */
        };
        if (this.playerElem.parentElement === this.container) {
            this.container.removeChild(this.playerElem);
        }
        this.playerElem = this.createPlayer();
    }

    public getCurrentTime(): Date | null {
        return new Date(this.tlStart.getTime() + this.playerElem.currentTime * 1000);
    }

    public getElem(): HTMLVideoElement {
        return this.playerElem;
    }

    public setVolume(v: number): void {
        const volume = v < 0 ? 0 : v > 1 ? 1 : v;

        this.playerElem.volume = volume;
        TypedStorage.set('volume', volume);
    }

    public getVolume(): number {
        return TypedStorage.get('volume', 0.5);
    }

    public onStatus(cb: (status: PlayerStatus) => void): void {
        this.statusCB = cb;
        cb(this.status);
    }

    public onTimeUpdate(cb: (time: Date) => void): void {
        this.timeUpdateCB = cb;
    }

    public pause(): void {
        this.playerElem.pause();
        this.setStatus(PlayerStatus.PAUSED);
    }

    public replay(): void {
        this.playerElem.pause();
        Logger.trace('Replay content');
        if (isNullOrUndefined(this.startAt)) {
            return;
        }
        return this.start(this.startAt, this.pauseAt, this.startStatus);
    }

    public resume(): void {
        this.playerElem
            .play()
            .then(() => {
                this.setStatus(PlayerStatus.PLAYING);
            })
            .catch((err) => {
                this.setStatus(PlayerStatus.NULL);
                Logger.debug({ err }, 'HLS.js error');
            });
    }

    public seek(delta: number): void {
        if (this.playerElem.currentTime !== 0) {
            this.playerElem.currentTime = this.playerElem.currentTime + delta;
        }
    }

    public setPlaybackSpeed(speed: number): void {
        this.playerElem.playbackRate = speed;
    }

    public start(start: Date, end?: Date, startStatus?: boolean): void {
        Logger.trace({ end, start, startStatus }, 'Start playing content');
        if (isUndefined(startStatus)) {
            startStatus = true;
            if (!isNullOrUndefined(this.playerElem) && this.playerElem.paused) {
                Logger.trace('Player was paused before starting, keeping paused state');
                startStatus = false;
            }
        }
        this.startStatus = startStatus;
        this.pauseAt = end;
        this.startAt = start;

        const dest = (start.getTime() - getDateInTz(this.tlStart, this.tz).getTime()) / 1000;
        this.playerElem.currentTime = dest;
    }

    public onNewStats(
        cb: (manifests: mediaarchiver.IPlayerStatsManifest, chunks: mediaarchiver.IPlayerStatsChunks) => void,
    ): void {
        //
    }

    public getSnapshot(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!this.readyForCapture) {
                return reject(new Error('Player is not ready for a snapshot'));
            }
            const canvas = document.createElement('canvas') as HTMLCanvasElement;

            canvas.width = this.playerElem.videoWidth;
            canvas.height = this.playerElem.videoHeight;

            const ctx = canvas.getContext('2d');

            if (isNullOrUndefined(ctx)) {
                return reject(new Error('Could not create a canvas context'));
            }
            ctx.drawImage(this.playerElem, 0, 0, this.playerElem.videoWidth, this.playerElem.videoHeight);
            resolve(canvas.toDataURL('image/png'));
        });
    }

    private createPlayer(): HTMLVideoElement {
        const player = document.createElement('video');

        player.onclick = () => {
            if (player.paused) {
                this.resume();
            } else {
                this.pause();
            }
        };
        player.crossOrigin = 'use-credentials';
        player.setAttribute('playsinline', '');
        player.setAttribute('webkit-playsinline', '');
        player.controls = false;
        player.volume = this.getVolume();
        player.addEventListener('timeupdate', () => this.onTimeUpdateInternal());
        return player;
    }

    private onTimeUpdateInternal() {
        const now = this.getCurrentTime();
        if (isNullOrUndefined(now)) {
            return;
        }
        this.readyForCapture = true;
        if (!isNullOrUndefined(this.pauseAt) && now.getTime() >= this.pauseAt.getTime()) {
            this.playerElem.pause();
        }
        this.timeUpdateCB(now);
    }

    private createHLS(): Hls {
        const hls = new Hls({
            autoStartLoad: true,
            debug: false,
            fetchSetup: this.hlsFetchSetup.bind(this),
            maxBufferHole: 0,
            maxFragLookUpTolerance: 0.02,
            xhrSetup: this.hlsXHRSetup.bind(this),
        });
        hls.attachMedia(this.playerElem);
        /* eslint-disable @typescript-eslint/no-explicit-any */
        this.hlsBindings.forEach((callback: any, event: string) => {
            hls.on(event as any, callback);
        });
        this.setStatus(PlayerStatus.LOADING);
        /* eslint-enable @typescript-eslint/no-explicit-any */
        hls.loadSource(
            `${Config.streamerURL}/instant?media=${this.mediaID}&start=${Math.round(
                getDateInTz(this.tlStart, this.tz).getTime() / 1000,
            )}&end=${Math.round(getDateInTz(this.tlEnd, this.tz).getTime() / 1000)}`,
        );
        // hls.config.debug = true;
        return hls;
    }

    private destroyHLS(): void {
        this.hls.stopLoad();
        /* eslint-disable @typescript-eslint/no-explicit-any */
        this.hlsBindings.forEach((callback: any, event: string) => {
            this.hls.off(event as any, callback);
        });
        /* eslint-enable @typescript-eslint/no-explicit-any */
        this.hls.destroy();
    }

    private hlsFetchSetup(context: HLS.LoaderContext, initParams: RequestInit): Request {
        const headers = new Headers();

        headers.set('authorization', `Bearer ${this.token}`);
        headers.set('x-yacast-streamer-client', 'mediaarchiver');
        initParams.headers = headers;
        return new Request(context.url, initParams);
    }

    private hlsXHRSetup(xhr: XMLHttpRequest, url: string): void {
        xhr.setRequestHeader('authorization', `Bearer ${this.token}`);
        xhr.setRequestHeader('x-yacast-streamer-client', 'mediaarchiver');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getHLSBindings(): Map<string, any> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const bindings = new Map<string, any>();
        /*
        bindings.set(Hls.Events.AUDIO_TRACK_LOADED, this.log.bind(this));
        bindings.set(Hls.Events.AUDIO_TRACK_LOADING, this.log.bind(this));
        bindings.set(Hls.Events.AUDIO_TRACK_SWITCHED, this.log.bind(this));
        bindings.set(Hls.Events.AUDIO_TRACK_SWITCHING, this.log.bind(this));
        bindings.set(Hls.Events.AUDIO_TRACKS_UPDATED, this.log.bind(this));
        bindings.set(Hls.Events.BACK_BUFFER_REACHED, this.log.bind(this));
        bindings.set(Hls.Events.BUFFER_APPENDED, this.log.bind(this));
        bindings.set(Hls.Events.BUFFER_APPENDING, this.log.bind(this));
        bindings.set(Hls.Events.BUFFER_CODECS, this.log.bind(this));
        bindings.set(Hls.Events.BUFFER_CREATED, this.log.bind(this));
        bindings.set(Hls.Events.BUFFER_EOS, this.log.bind(this));
        bindings.set(Hls.Events.BUFFER_FLUSHED, this.log.bind(this));
        bindings.set(Hls.Events.BUFFER_FLUSHING, this.log.bind(this));
        bindings.set(Hls.Events.BUFFER_RESET, this.log.bind(this));
        bindings.set(Hls.Events.CUES_PARSED, this.log.bind(this));
        bindings.set(Hls.Events.DESTROYING, this.log.bind(this));
        */
        bindings.set(Hls.Events.ERROR, this.onHLSError.bind(this));
        // bindings.set(Hls.Events.FPS_DROP, this.log.bind(this));
        // bindings.set(Hls.Events.FPS_DROP_LEVEL_CAPPING, this.log.bind(this));
        bindings.set(Hls.Events.FRAG_BUFFERED, this.onFragBuffered.bind(this));
        bindings.set(Hls.Events.FRAG_CHANGED, this.onFragChange.bind(this));
        bindings.set(Hls.Events.FRAG_LOADING, this.onFragLoading.bind(this));
        bindings.set(Hls.Events.MANIFEST_PARSED, this.onParsedManifest.bind(this));
        return bindings;
    }
    /*
    private log(event: string, data: unknown) {
        Logger.debug({ event, data }, 'logged');
    }
*/
    private onParsedManifest(event: string, data: HLS.ManifestParsedData) {
        if (
            isNullOrUndefinedOrEmptyArray(data.levels) ||
            isNullOrUndefined(data.levels[0]) ||
            isNullOrUndefined(data.levels[0].details) ||
            isNullOrUndefinedOrEmptyArray(data.levels[0].details.fragments)
        ) {
            Logger.warn(data, 'Invalid manifest');
            this.setStatus(PlayerStatus.NULL);
            return;
        }
        this.manifestParsed = true;
        this.fragments = data.levels[0].details.fragments;
        this.setStatus(PlayerStatus.PAUSED);
    }

    private onHLSError(event: string, data: HLS.ErrorData) {
        switch (data.details) {
            case Hls.ErrorDetails.FRAG_LOAD_ERROR:
                Logger.warn({ data }, 'Failed loading fragment');
                break;
            case Hls.ErrorDetails.FRAG_LOAD_TIMEOUT:
                Logger.warn({ data }, 'Failed loading fragment');
                break;
            case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
                Logger.warn({ data }, 'Failed loading fragment');
                break;
            case Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT:
                Logger.warn({ data }, 'Failed loading manifest');
                break;
            case Hls.ErrorDetails.BUFFER_APPENDING_ERROR:
                Logger.warn({ data }, 'Failed appending buffer');
                break;
            case Hls.ErrorDetails.BUFFER_APPEND_ERROR:
                Logger.warn({ data }, 'Failed to append buffer');
                break;
            default:
                Logger.debug({ data }, 'HLS.js error');
                break;
        }
    }

    private onFragLoading(event: string, data: HLS.FragBufferedData) {
        // Logger.debug(`Loading fragment ${new Date(data.frag.programDateTime || 0)}`);
    }

    private onFragBuffered(event: string, data: HLS.FragBufferedData) {
        // Logger.debug(event, data);
    }

    private onFragChange(event: string, data: HLS.FragChangedData) {
        // Logger.debug(`Changing fragment ${new Date(data.frag.programDateTime || 0)}`);
    }

    private setStatus(status: PlayerStatus): void {
        this.status = status;
        this.statusCB(status);
    }
}
