import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos } from '../../Store/I18n';
import { setSnackMessage } from '../../Store/Layout/Actions';
import { getTalkMedias, IMediasState } from '../../Store/Medias';
import { update as updateMedias } from '../../Store/Medias/Actions';
import { ITalkState } from '../../Store/Talk';
import {
    clearResults,
    deleteMultipleTalks,
    //download,
    exportTalks,
    getAllValues,
    search as doSearch,
    setTimelineTalk,
} from '../../Store/Talk/Actions';
import { IUserState } from '../../Store/User/Types';
import { getTheme } from '../../Themes';
import { formatDuration } from '../../Utils/Time';
import {
    getDate,
    isNullOrUndefined,
    isNullOrUndefinedOrZero,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrEmptyArray,
} from '../../Utils/Various';
import { MediaSelector } from '../Medias/Selector';

const theme = getTheme();

const styles = MD.createStyles({
    datePickers: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        flex: '1 1 0px',
        padding: theme.spacing(1),
    },
    formControlClosable: {
        flexGrow: 0.00001,
        overflow: 'hidden',
        paddingLeft: 0,
        paddingRight: 0,
        transition: 'flex-grow 150ms linear',
    },
    formControlClosableActive: {
        '& button span': {
            whiteSpace: 'nowrap',
        },

        flexGrow: 1,
        paddingRight: theme.spacing(1),
    },
    formControlLabel: {
        paddingLeft: theme.spacing(1),
    },
    formControlNoLabel: {
        marginTop: theme.spacing(2),
    },
    headerCheckbox: {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    },
    headerCheckboxActive: {
        '& svg': {
            color: 'rgb(0, 176, 255)',
        },
    },
    resultLoading: {
        fontSize: '3em',
        textAlign: 'center',
    },
    resultTitle: {
        '& span': {
            flex: 1,
        },

        display: 'flex',
        padding: theme.spacing(1),
    },
    resultTitleRoot: {
        height: 60,
        marginTop: theme.spacing(1),
    },
    root: {
        height: '100%',
        overflow: 'hidden',
        padding: theme.spacing(1),
        width: '100%',
    },
    rowCheckbox: {
        '& td:first-child': {
            cursor: 'pointer',
        },

        backgroundColor: theme.palette.background.default,
    },
    rowCheckboxChecked: {
        '& td:first-child svg': {
            color: 'rgb(0, 176, 255)',
        },

        backgroundColor: theme.palette.background.paper,
    },
    searchBox: {
        '&>form': {
            display: 'flex',
            height: '100%',
            width: '100%',
        },

        height: '100%',
        width: '100%',
    },
    searchBoxRoot: {
        height: 80,
    },
    searchButton: {},
    table: {
        minWidth: '100%',
        userSelect: 'text',
    },
    tableRoot: {
        maxHeight: 'calc(100% - 80px - 60px - 8px)',
        minHeight: 'calc(100% - 80px - 60px - 8px)',
        overflow: 'auto',
    },
});

interface IState {
    end: Date | null;
    endError: string;
    exporting: boolean;
    filterByDate: boolean;
    filterByMedia: boolean;
    launchedSearch: boolean;
    medias: number[];
    selected: string[];
    sortDirection: 'asc' | 'desc';
    sortedRow: string;
    start: Date | null;
    type: string;
}

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
    router: RouteComponentProps<{}>;
    talk: ITalkState;
    user: IUserState;
}

interface IPropsFromDispatch {
    clearResults: typeof clearResults;
    deleteMultipleTalks: typeof deleteMultipleTalks;
    doSearch: typeof doSearch;
    //download: typeof download;
    exportTalks: typeof exportTalks;
    getAllValues: typeof getAllValues;
    getTalkMedias: typeof getTalkMedias;
    setSnackMessage: typeof setSnackMessage;
    setTimelineTalk: typeof setTimelineTalk;
    updateMedias: typeof updateMedias;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class AccountTalksComponent extends React.Component<AllProps, IState> {
    private currentSearch: mediaarchiver.IArgumentsGetTalks = {};
    private triggeredLastElement: HTMLTableRowElement = document.createElement('tr');

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
    }

    public componentDidMount(): void {
        this.props.updateMedias();
        this.props.clearResults();
        this.props.getAllValues();
        this.props.getTalkMedias();
        this.triggeredLastElement = document.createElement('tr');
    }

    public render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid container className={classes.root}>
                <MD.Grid className={classes.searchBoxRoot} item xs={12}>
                    <MD.Paper className={classes.searchBox} elevation={2}>
                        <form>
                            {this.buildTypeSelect()}
                            {this.buildMediaSelect()}
                            {this.buildDatesInput()}
                            {this.buildSearchButton()}
                        </form>
                    </MD.Paper>
                </MD.Grid>
                {this.props.talk.searchLoading &&
                this.props.talk.searchResults.length === 0 &&
                this.state.launchedSearch ? (
                    <MD.Grid className={classes.resultLoading} item xs={12}>
                        <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                    </MD.Grid>
                ) : (
                    ''
                )}
                {this.props.talk.searchResults.length !== 0 && this.state.launchedSearch ? (
                    <>
                        <MD.Grid className={classes.resultTitleRoot} item xs={12}>
                            <MD.Paper className={classes.resultTitle} elevation={1}>
                                <MD.Typography component='span' variant='h5'>
                                    {this.props.i18n.sprintf(
                                        this.props.i18n.ngettext(
                                            '%1$d result',
                                            '%1$d results',
                                            this.props.talk.searchResultsCount,
                                        ),
                                        this.props.talk.searchResultsCount,
                                    )}
                                    {this.state.selected.length > 0
                                        ? ', ' +
                                          this.props.i18n.sprintf(
                                              this.props.i18n.ngettext(
                                                  '%1$d selected',
                                                  '%1$d selected',
                                                  this.state.selected.length,
                                              ),
                                              this.state.selected.length,
                                          )
                                        : ''}
                                </MD.Typography>
                                <MD.Button
                                    disabled={this.state.exporting}
                                    onClick={() => {
                                        let Type: mediaarchiver.TalkStudyType;

                                        switch (this.state.type) {
                                            case mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION.toString(10):
                                                Type = mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION;
                                                break;
                                            case mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM.toString(10):
                                                Type = mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM;
                                                break;
                                            default:
                                                Type = mediaarchiver.TalkStudyType.TALK_TYPE_UNKNOWN;
                                        }

                                        const args: mediaarchiver.IArgumentsExportTalks = { Type };

                                        if (this.state.filterByDate) {
                                            if (!isNullOrUndefined(this.state.start)) {
                                                args.DateStart = this.state.start.getTime();
                                            }
                                            if (!isNullOrUndefined(this.state.end)) {
                                                args.DateEnd = this.state.end.getTime();
                                            }
                                        }
                                        if (this.state.filterByMedia) {
                                            args.Medias = this.state.medias;
                                        }

                                        this.setState({ exporting: true });
                                        this.props.exportTalks(args);
                                        window.setTimeout(() => {
                                            this.setState({ exporting: false });
                                        }, 2000);
                                    }}
                                    startIcon={<FontAwesomeIcon icon={FA.faFileExcel} />}
                                    variant='outlined'
                                >
                                    {this.state.exporting
                                        ? this.props.i18n._('Export is in progress ...')
                                        : this.props.i18n._('Export results')}
                                </MD.Button>
                                {this.state.selected.length > 0 ? (
                                    <MD.Button
                                        onClick={this.deleteSelected.bind(this)}
                                        startIcon={<FontAwesomeIcon icon={FA.faTrash} />}
                                        variant='outlined'
                                    >
                                        {this.props.i18n._('Delete selected')}
                                    </MD.Button>
                                ) : (
                                    ''
                                )}
                            </MD.Paper>
                        </MD.Grid>

                        <MD.TableContainer
                            className={classes.tableRoot}
                            component={MD.Grid}
                            item
                            xs={12}
                            onScroll={this.checkScroll.bind(this)}
                        >
                            <MD.Table className={classes.table} size='small' stickyHeader>
                                {this.buildTableHead()}
                                {this.buildTableRows()}
                            </MD.Table>
                        </MD.TableContainer>
                    </>
                ) : (
                    ''
                )}
                {this.buildTalkDialog()}
            </MD.Grid>
        );
    }

    private buildTypeSelect() {
        return (
            <MD.FormControl className={this.props.classes.formControl}>
                <MD.InputLabel className={this.props.classes.formControlLabel} id='searchTalkTypeLabel' required>
                    {this.props.i18n._('Talk type')}
                </MD.InputLabel>
                <MD.Select
                    labelId='searchTalkTypeLabel'
                    onChange={this.onTypeChange.bind(this)}
                    value={this.state.type}
                >
                    <MD.MenuItem value='none'>{this.props.i18n._('All types')}</MD.MenuItem>
                    <MD.MenuItem value={mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM.toString()}>
                        {this.props.i18n._('Pluralism')}
                    </MD.MenuItem>
                    <MD.MenuItem value={mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION.toString()}>
                        {this.props.i18n._('Election')}
                    </MD.MenuItem>
                </MD.Select>
            </MD.FormControl>
        );
    }

    private onTypeChange(ev: React.ChangeEvent<{ name?: string | undefined }>) {
        let value = (ev.target as HTMLSelectElement).value;

        if (value === '') {
            value = 'none';
        }
        this.props.clearResults();
        this.triggeredLastElement = document.createElement('tr');
        this.setState({ launchedSearch: false, sortDirection: 'asc', sortedRow: 'date', type: value });
    }

    private buildMediaSelect() {
        const labelClassName = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlNoLabel]: true,
        });
        const inputClassName = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlNoLabel]: true,
            [this.props.classes.formControlClosable]: true,
            [this.props.classes.formControlClosableActive]: this.state.filterByMedia,
        });

        return (
            <>
                <MD.FormControl className={labelClassName} required={this.state.filterByMedia}>
                    <MD.FormLabel htmlFor='searchMedias'>
                        <MD.Checkbox
                            checked={this.state.filterByMedia}
                            onClick={() => {
                                this.setState({ filterByMedia: !this.state.filterByMedia, launchedSearch: false });
                            }}
                        />
                        {this.props.i18n._('Filter by medias')}
                    </MD.FormLabel>
                </MD.FormControl>
                <MD.FormControl className={inputClassName}>
                    <Route
                        render={(props) => (
                            <MediaSelector
                                {...props}
                                dispatch={this.props.dispatch}
                                id={'searchMedias'}
                                initialMedias={this.state.medias}
                                onChange={(medias: number[]) => {
                                    this.setState({ launchedSearch: false, medias });
                                }}
                                variant='outlined'
                            />
                        )}
                    />
                </MD.FormControl>
            </>
        );
    }

    private buildDatesInput() {
        const className = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlNoLabel]: true,
        });

        return (
            <>
                <MD.FormControl className={className}>
                    <MD.FormLabel>
                        <MD.Checkbox
                            checked={this.state.filterByDate}
                            onClick={() => {
                                this.setState({ filterByDate: !this.state.filterByDate, launchedSearch: false });
                            }}
                        />
                        {this.props.i18n._('Filter by date')}
                    </MD.FormLabel>
                </MD.FormControl>
                {this.buildStartInput()}
                {this.buildEndInput()}
            </>
        );
    }

    private buildStartInput() {
        const { classes } = this.props;
        const className = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlClosable]: true,
            [this.props.classes.formControlClosableActive]: this.state.filterByDate,
        });
        const today = new Date();
        const startCapta = new Date(today.getFullYear() - 6, today.getMonth(), 1, 0, 0, 0, 0);
        const startMinDate = startCapta;
        const startMaxDate = !isNullOrUndefined(this.state.end) ? this.state.end : moment().endOf('day');

        return (
            <MD.FormControl className={className}>
                <DatePicker
                    autoOk={true}
                    className={classes.datePickers}
                    disableToolbar
                    format={this.props.localeInfos.formatLongDate}
                    // keyboardIcon={<FontAwesomeIcon icon={FA.faCalendar} />}
                    InputProps={{
                        endAdornment: (
                            <MD.InputAdornment position='end'>
                                <MD.IconButton
                                    onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        this.setState({ endError: '', launchedSearch: false, start: null });
                                    }}
                                >
                                    <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                </MD.IconButton>
                            </MD.InputAdornment>
                        ),
                    }}
                    label={this.props.i18n._('Start date')}
                    leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                    // locale={this.props.localeInfos.momentLocale}
                    maxDate={startMaxDate}
                    minDate={startMinDate}
                    onChange={(val: Date | moment.Moment | null): void => {
                        let start = getDate(val);
                        let endError = '';

                        if (!isNullOrUndefined(start)) {
                            start = moment(start).startOf('day').toDate();
                            if (!isNullOrUndefined(this.state.end) && this.state.end.getTime() < start.getTime()) {
                                endError = this.props.i18n._('End date cannot be before start date');
                            }
                        }
                        this.setState({ endError, launchedSearch: false, start });
                    }}
                    openTo='year'
                    rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                    value={this.state.start}
                    variant='inline'
                    views={['year', 'month', 'date']}
                />
            </MD.FormControl>
        );
    }

    private buildEndInput() {
        const { classes } = this.props;
        const className = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlClosable]: true,
            [this.props.classes.formControlClosableActive]: this.state.filterByDate,
        });
        const today = new Date();
        const startCapta = new Date(today.getFullYear() - 6, today.getMonth(), 1, 0, 0, 0, 0);
        const endMinDate = !isNullOrUndefined(this.state.start) ? this.state.start : startCapta;
        const endMaxDate = today;

        return (
            <MD.FormControl className={className}>
                <DatePicker
                    autoOk={true}
                    className={classes.datePickers}
                    disableToolbar
                    error={this.state.endError !== ''}
                    format={this.props.localeInfos.formatLongDate}
                    helperText={this.state.endError}
                    // keyboardIcon={<FontAwesomeIcon icon={FA.faCalendar} />}
                    InputProps={{
                        endAdornment: (
                            <MD.InputAdornment position='end'>
                                <MD.IconButton
                                    onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        this.setState({ end: null, endError: '', launchedSearch: false });
                                    }}
                                >
                                    <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                </MD.IconButton>
                            </MD.InputAdornment>
                        ),
                    }}
                    label={this.props.i18n._('End date')}
                    leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                    // locale={this.props.localeInfos.momentLocale}
                    maxDate={endMaxDate}
                    minDate={endMinDate}
                    onChange={(val: Date | moment.Moment | null): void => {
                        let end = getDate(val);
                        let endError = '';

                        if (!isNullOrUndefined(end)) {
                            end = moment(end).endOf('day').toDate();
                            if (!isNullOrUndefined(this.state.start) && this.state.start.getTime() > end.getTime()) {
                                endError = this.props.i18n._('End date cannot be before start date');
                            }
                        }
                        this.setState({ end, endError, launchedSearch: false });
                    }}
                    openTo='year'
                    rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                    value={this.state.end}
                    variant='inline'
                    views={['year', 'month', 'date']}
                />
            </MD.FormControl>
        );
    }

    private buildSearchButton() {
        const className = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlNoLabel]: true,
        });

        return (
            <MD.FormControl className={className}>
                <MD.Button
                    className={this.props.classes.searchButton}
                    color={this.isSearchReady() ? 'secondary' : 'primary'}
                    disabled={!this.isSearchReady()}
                    onClick={this.onSearch.bind(this, true)}
                    variant='outlined'
                >
                    {this.props.i18n._('Search')}
                </MD.Button>
            </MD.FormControl>
        );
    }

    private isSearchReady() {
        return true;
        return (
            (!this.state.filterByMedia || this.state.medias.length > 0) &&
            this.state.type !== 'none' &&
            (!this.state.filterByDate || this.state.endError === '')
        );
    }

    private onSearch() {
        if (!this.isSearchReady()) {
            return;
        }
        let t: mediaarchiver.TalkStudyType;

        switch (this.state.type) {
            case mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION.toString():
                t = mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION;
                break;
            case mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM.toString():
                t = mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM;
                break;
            default:
                t = mediaarchiver.TalkStudyType.TALK_TYPE_UNKNOWN;
        }

        const search: mediaarchiver.IArgumentsGetTalks = {
            Limit: 100,
            Offset: 0,
            Sort: 'date',
            SortDesc: false,
            Type: t,
        };

        if (this.state.filterByDate) {
            if (!isNullOrUndefined(this.state.start)) {
                search.DateStart = this.state.start.getTime();
            }
            if (!isNullOrUndefined(this.state.end)) {
                search.DateEnd = this.state.end.getTime();
            }
        }
        if (this.state.filterByMedia) {
            search.Medias = this.state.medias;
        }
        this.currentSearch = search;
        this.triggeredLastElement = document.createElement('tr');
        this.props.clearResults();
        this.props.doSearch(this.currentSearch);
        this.setState({ launchedSearch: true, sortDirection: 'asc', sortedRow: 'date' });
    }

    private buildTableHead(): React.ReactNode {
        return (
            <MD.TableHead>
                <MD.TableRow>
                    <MD.TableCell />
                    <MD.TableCell sortDirection={this.state.sortedRow === 'media' ? this.state.sortDirection : false}>
                        <MD.TableSortLabel
                            active={this.state.sortedRow === 'media'}
                            direction={this.state.sortDirection}
                            onClick={this.changeSort.bind(this, 'media')}
                        >
                            {this.props.i18n._('Media')}
                        </MD.TableSortLabel>
                    </MD.TableCell>
                    <MD.TableCell sortDirection={this.state.sortedRow === 'date' ? this.state.sortDirection : false}>
                        <MD.TableSortLabel
                            active={this.state.sortedRow === 'date'}
                            direction={this.state.sortDirection}
                            onClick={this.changeSort.bind(this, 'date')}
                        >
                            {this.props.i18n._('Date')}
                        </MD.TableSortLabel>
                    </MD.TableCell>
                    <MD.TableCell>{this.props.i18n._('Hour')}</MD.TableCell>
                    <MD.TableCell
                        sortDirection={this.state.sortedRow === 'duration' ? this.state.sortDirection : false}
                    >
                        <MD.TableSortLabel
                            active={this.state.sortedRow === 'duration'}
                            direction={this.state.sortDirection}
                            onClick={this.changeSort.bind(this, 'duration')}
                        >
                            {this.props.i18n._('Duration')}
                        </MD.TableSortLabel>
                    </MD.TableCell>
                    <MD.TableCell sortDirection={this.state.sortedRow === 'name' ? this.state.sortDirection : false}>
                        <MD.TableSortLabel
                            active={this.state.sortedRow === 'name'}
                            direction={this.state.sortDirection}
                            onClick={this.changeSort.bind(this, 'name')}
                        >
                            {this.props.i18n.pgettext('Politic', 'Name')}
                        </MD.TableSortLabel>
                    </MD.TableCell>
                    <MD.TableCell sortDirection={this.state.sortedRow === 'group' ? this.state.sortDirection : false}>
                        <MD.TableSortLabel
                            active={this.state.sortedRow === 'group'}
                            direction={this.state.sortDirection}
                            onClick={this.changeSort.bind(this, 'group')}
                        >
                            {this.props.i18n.pgettext('Politic', 'Group')}
                        </MD.TableSortLabel>
                    </MD.TableCell>
                    <MD.TableCell>{this.props.i18n.pgettext('Politic', 'Title')}</MD.TableCell>
                    <MD.TableCell sortDirection={this.state.sortedRow === 'regim' ? this.state.sortDirection : false}>
                        <MD.TableSortLabel
                            active={this.state.sortedRow === 'regim'}
                            direction={this.state.sortDirection}
                            onClick={this.changeSort.bind(this, 'regim')}
                        >
                            {this.props.i18n.pgettext('Politic', 'Regim')}
                        </MD.TableSortLabel>
                    </MD.TableCell>
                </MD.TableRow>
            </MD.TableHead>
        );
    }

    private changeSort(sortedRow: string) {
        let sortDirection: 'asc' | 'desc' = 'desc';

        if (this.state.sortedRow === sortedRow) {
            sortDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc';
        }
        this.setState({ sortDirection, sortedRow }, () => {
            this.props.clearResults();
            this.triggeredLastElement = document.createElement('tr');
            this.currentSearch.Offset = 0;
            this.currentSearch.Sort = sortedRow;
            this.currentSearch.SortDesc = sortDirection === 'desc';
            this.props.doSearch(this.currentSearch);
        });
    }

    private buildTableRows(): React.ReactNode {
        const build = this.buildTableRow.bind(this);

        return <MD.TableBody>{this.props.talk.searchResults.map(build)}</MD.TableBody>;
    }

    private buildTableRow(talk: mediaarchiver.ITalk): React.ReactNode {
        if (isNullOrUndefined(talk.ID) || isNullOrUndefinedOrEmptyArray(talk.Speakers)) {
            return <MD.TableRow key={Math.random().toString()} style={{ display: 'none' }} />;
        }
        const speaker = talk.Speakers[0];

        const rowClasses = classNames({
            [this.props.classes.rowCheckbox]: true,
            [this.props.classes.rowCheckboxChecked]: this.state.selected.indexOf(talk.ID) !== -1,
        });
        const icon = this.state.selected.indexOf(talk.ID) !== -1 ? FA.faCheckSquare : FA.faSquare;
        const checkLabel =
            this.state.selected.indexOf(talk.ID) === -1
                ? this.props.i18n._('Select talk')
                : this.props.i18n._('Unselect talk');
        const canDelete =
            talk.Owner === this.props.user.user.id ||
            this.props.user.user.functionalities.indexOf(
                mediaarchiver.UserFunctionalities.FUNCTIONALITY_POLITICAL_SUPERVISOR_DELETE,
            ) !== -1;

        return (
            <MD.TableRow className={rowClasses} key={`table_talk_${talk.ID}`} id={`table_talk_${talk.ID}`}>
                <MD.TableCell padding='checkbox'>
                    <MD.Tooltip title={this.props.i18n._('See talk in timeline')}>
                        <MD.Button
                            onClick={() => {
                                this.props.setTimelineTalk(talk);
                                this.props.router.history.push('/timeline');
                            }}
                        >
                            <FontAwesomeIcon icon={FA.faPlay} />
                        </MD.Button>
                    </MD.Tooltip>
                </MD.TableCell>
                <MD.TableCell>{this.getMediaName(talk)}</MD.TableCell>
                <MD.TableCell>{this.getDate(speaker)}</MD.TableCell>
                <MD.TableCell>{this.getHour(speaker)}</MD.TableCell>
                <MD.TableCell>{this.getDuration(speaker)}</MD.TableCell>
                <MD.TableCell>{speaker.Name}</MD.TableCell>
                <MD.TableCell>{this.getGroupName(speaker.Group as number)}</MD.TableCell>
                <MD.TableCell>{speaker.Title}</MD.TableCell>
                <MD.TableCell>{this.getRegimName(speaker.Regim as number)}</MD.TableCell>
            </MD.TableRow>
        );
    }

    private getMediaName(talk: mediaarchiver.ITalk): string {
        const mediaID = talk.Media || 0;
        let mediaName = 'N/A';

        this.props.medias.data.some((media) => {
            if (media.id === mediaID) {
                mediaName = media.name;
                return true;
            }
            return false;
        });
        return mediaName;
    }

    private getDate(speaker: mediaarchiver.ITalkSpeaker): string {
        if (isNullOrUndefinedOrZero(speaker.Start)) {
            return '';
        }
        return moment(new Date(speaker.Start as number)).format(this.props.localeInfos.formatShortDate);
    }

    private getHour(speaker: mediaarchiver.ITalkSpeaker): string {
        if (isNullOrUndefinedOrZero(speaker.Start)) {
            return '';
        }
        return moment(new Date(speaker.Start as number)).format(this.props.localeInfos.formatShortTime);
    }

    private getDuration(speaker: mediaarchiver.ITalkSpeaker): string {
        if (isNullOrUndefinedOrZero(speaker.DurationMS)) {
            return '';
        }
        return formatDuration(speaker.DurationMS);
    }

    private buildTalkDialog() {
        return (
            <MD.Dialog open={false}>
                <MD.DialogTitle>Titre</MD.DialogTitle>
                <MD.DialogContent />
            </MD.Dialog>
        );
    }

    private deleteSelected(): void {
        if (this.state.selected.length === 0) {
            return;
        }
        this.props.deleteMultipleTalks(this.state.selected);
        window.setTimeout(() => {
            this.setState({ selected: [] });
            this.onSearch();
        }, 500);
    }

    private getGroupName(group: number): string {
        let name = 'N/A';

        this.props.talk.allGroups.some((g: mediaarchiver.ITalkGroup) => {
            if (
                !isNullOrUndefined(g) &&
                !isNullOrUndefinedOrZero(g.ID) &&
                !isNullOrUndefinedOrEmptyString(g.Name) &&
                g.ID == group
            ) {
                name = g.Name;
            }
        });
        return name;
    }

    private getRegimName(regim: number): string {
        let name = 'N/A';

        this.props.talk.allRegims.some((r: mediaarchiver.ITalkRegim) => {
            if (
                !isNullOrUndefined(r) &&
                !isNullOrUndefinedOrZero(r.ID) &&
                !isNullOrUndefinedOrEmptyString(r.Name) &&
                r.ID == regim
            ) {
                name = r.Name;
            }
        });
        return name;
    }

    private getStudyName(study: number): string {
        let name = 'N/A';

        this.props.talk.studies.some((s: mediaarchiver.ITalkStudy) => {
            if (
                !isNullOrUndefined(s) &&
                !isNullOrUndefinedOrZero(s.ID) &&
                !isNullOrUndefinedOrEmptyString(s.Name) &&
                s.ID == study
            ) {
                name = s.Name;
            }
        });
        return name;
    }

    private checkScroll(ev: React.UIEvent<HTMLDivElement, UIEvent>) {
        const container = ev.target as HTMLDivElement;
        if (isNullOrUndefined(container)) {
            return;
        }
        if (container.scrollTop + container.clientHeight < container.scrollHeight - 200) {
            return;
        }
        const tables = container.getElementsByTagName('table');
        if (tables.length === 0) {
            return;
        }
        const trs = tables[0].getElementsByTagName('tr');
        if (trs.length === 0) {
            return;
        }
        if (this.triggeredLastElement === trs[trs.length - 1]) {
            return;
        }
        this.triggeredLastElement = trs[trs.length - 1] as HTMLTableRowElement;
        if (isNullOrUndefined(this.currentSearch.Offset)) {
            this.currentSearch.Offset = 0;
        }
        if (this.currentSearch.Offset + 100 < this.props.talk.searchResultsCount) {
            this.currentSearch.Offset = this.currentSearch.Offset + 100;
            this.props.doSearch(this.currentSearch);
        }
    }

    private getDefaultState(): IState {
        if (!isNullOrUndefined(this.props.talk.lastSearch)) {
            return {
                end: !isNullOrUndefined(this.props.talk.lastSearch.DateEnd)
                    ? new Date(this.props.talk.lastSearch.DateEnd as number)
                    : null,
                endError: '',
                exporting: false,
                filterByDate:
                    !isNullOrUndefined(this.props.talk.lastSearch.DateEnd) &&
                    !isNullOrUndefined(this.props.talk.lastSearch.DateStart),
                filterByMedia:
                    !isNullOrUndefined(this.props.talk.lastSearch.Medias) &&
                    this.props.talk.lastSearch.Medias.length > 0,
                launchedSearch: false,
                medias: !isNullOrUndefined(this.props.talk.lastSearch.Medias) ? this.props.talk.lastSearch.Medias : [],
                selected: [],
                sortDirection: !isNullOrUndefined(this.props.talk.lastSearch.SortDesc)
                    ? this.props.talk.lastSearch.SortDesc
                        ? 'desc'
                        : 'asc'
                    : 'desc',
                sortedRow: !isNullOrUndefined(this.props.talk.lastSearch.Sort)
                    ? this.props.talk.lastSearch.Sort
                    : 'date',
                start: !isNullOrUndefined(this.props.talk.lastSearch.DateStart)
                    ? new Date(this.props.talk.lastSearch.DateStart as number)
                    : null,
                type: !isNullOrUndefined(this.props.talk.lastSearch.Type)
                    ? this.props.talk.lastSearch.Type.toString()
                    : 'none',
            };
        }
        return {
            end: null,
            endError: '',
            exporting: false,
            filterByDate: false,
            filterByMedia: false,
            launchedSearch: false,
            medias: [],
            selected: [],
            sortDirection: 'desc',
            sortedRow: 'date',
            start: null,
            type: 'none',
        };
    }
}

const mapStateToProps = ({ talk, i18n, medias, user }: IApplicationState, ownProps: RouteComponentProps<{}>) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    medias,
    router: ownProps,
    talk,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearResults: () => dispatch(clearResults()),
    deleteMultipleTalks: (ids: string[]) => dispatch(deleteMultipleTalks(ids)),
    doSearch: (query: mediaarchiver.IArgumentsGetTalks) => dispatch(doSearch(query)),
    // download: (aType: mediaarchiver.ITalkType) => dispatch(download(aType)),
    exportTalks: (a: mediaarchiver.IArgumentsExportTalks) => dispatch(exportTalks(a)),
    getAllValues: () => dispatch(getAllValues()),
    getTalkMedias: () => dispatch(getTalkMedias()),
    setSnackMessage: (msg: string) => dispatch(setSnackMessage(msg)),
    setTimelineTalk: (talk: mediaarchiver.ITalk | null) => dispatch(setTimelineTalk(talk)),
    updateMedias: () => dispatch(updateMedias()),
});

export const AccountTalks = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(AccountTalksComponent));
