import { createStyles } from '@material-ui/core';
import { getTheme } from '../../Themes';

const theme = getTheme();

export default createStyles({
    cursorCurrent: {
        background: theme.mediaarchiver.colors.cursorCurrent,
        display: 'none',
        height: 'calc(100% - 28px)',
        left: 0,
        position: 'absolute',
        top: 20,
        width: 2,
        zIndex: 2002,
    },
    cursorSelectionEnd: {
        '&>div': {
            background: theme.mediaarchiver.colors.cursorSelectionEnd,
            height: '100%',
            marginLeft: 4,
            width: 2,
        },
        cursor: 'ew-resize',
        height: 'calc(100% - 20px)',
        left: 120,
        position: 'absolute',
        top: 20,
        width: 10,
        zIndex: 9999,
    },
    cursorSelectionStart: {
        '&>div': {
            background: theme.mediaarchiver.colors.cursorSelectionStart,
            height: '100%',
            marginLeft: 4,
            width: 2,
        },
        cursor: 'ew-resize',
        display: 'none',
        height: 'calc(100% - 20px)',
        left: 40,
        position: 'absolute',
        top: 20,
        width: 10,
        zIndex: 9999,
    },
    cursorsContainer: {
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        zIndex: 2,
    },
    item20: {
        maxHeight: '20px',
        minHeight: '20px',
    },
    item40: {
        maxHeight: '34px',
        minHeight: '34px',
    },
    item45: {
        maxHeight: '45px',
        minHeight: '45px',
    },
    item70: {
        maxHeight: '62px',
        minHeight: '62px',
    },
    item100: {
        maxHeight: '100px',
        minHeight: '100px',
    },
    legend: {},
    legend20: {
        height: '20px',
    },
    legend40: {
        height: '34px',
    },
    legend45: {
        maxHeight: '45px',
        minHeight: '45px',
    },
    legend70: {
        height: '62px',
    },
    legendItem: {
        '&:first-child': {
            borderTopWidth: '1px',
        },
        '&:last-child': {
            borderBottomWidth: '0px',
        },

        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        borderBottomWidth: '1px',
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        borderWidth: '0px',
        color: theme.palette.primary.main,
        display: 'flex',
        fontSize: '0.9em',
        overflow: 'hidden',
        paddingLeft: '10px',
        whiteSpace: 'nowrap',
        width: '120px',
    },
    legendRuler: {
        height: '30px',
        paddingLeft: '0px',
        position: 'relative',
    },
    legendRulerText: {
        '& p': {
            margin: '0 auto',
        },
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyItems: 'center',
        left: 0,
        opacity: 0,
        position: 'absolute',
        top: 0,
        transition: theme.transitions.create('opacity'),
        width: '100%',
        zIndex: 1,
    },
    legendRulerZoom: {
        '& a': {
            minHeight: '29px',
            minWidth: '40px',
        },
        left: 0,
        opacity: 1,
        position: 'absolute',
        top: 0,
        transition: theme.transitions.create('opacity'),
        zIndex: 2,
    },
    legendXML: {
        '& a': {
            '& span': {
                display: 'block',
                fontSize: '0.7em',
                marginTop: 5,
            },
            textDecoration: 'underline',
        },
        maxHeight: '40px',
        minHeight: '40px',
    },
    root: {
        backgroundColor: theme.palette.background.default,
        display: 'inline-flex',
        height: `calc(100% - ${theme.mediaarchiver.dimensions.footerHeight}px)`,
        outline: 'none',
        overflowX: 'auto',
        width: '100%',
    },
    ruler: {
        '&>div:first-child': {
            background: 'rgba(255, 255, 255, .1)',
            height: '100%',
            width: '100%',
        },
        maxHeight: '30px',
        minHeight: '30px',
        zIndex: 3,
    },
    scrollThumb: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: 3,
        height: 10,
    },
    scrollTrack: {
        backgroundColor: theme.palette.primary.dark,
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: 3,
        bottom: 3,
        height: '20px !important',
        left: 3,
        right: 3,
    },
    scrollable: {
        display: 'flex',
        flexDirection: 'column',
        left: 0,
        position: 'relative',
        top: 0,
    },
    scrollableItem: {
        '&:last-child': {
            borderBottomWidth: '0px',
        },
        borderBottomWidth: '1px',
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        borderWidth: '0px',
        flexBasis: 0,
        flexGrow: 1,
    },
    selectionHighlight: {
        background: theme.mediaarchiver.colors.selectionHighlight,
        height: 'calc(100% - 20px)',
        left: 40,
        position: 'absolute',
        top: 20,
        width: 80,
        zIndex: 2000,
    },
});
