import * as Proto from '../../Protos/protos';

export enum ArchivesActionTypes {
    CREATE = '@@archives/CREATE',
    DELETE = '@@archives/DELETE',
    DELETED_ARCHIVE = '@@archives/DELETED_ARCHIVE',
    DOWNLOAD_BY_ID = '@@archives/DOWNLOAD_BY_ID',
    EDIT = '@@archives/EDIT',
    GET = '@@archives/GET',
    GET_BY_ID = '@@archives/GET_BY_ID',
    GET_COUNT = '@@archives/GET_COUNT',
    HIDE_DIALOG = '@@archives/HIDE_DIALOG',
    INC_COUNTERS = '@@archives/INC_COUNTERS',
    RETRY = '@@archives/RETRY',
    SET_ARCHIVE = '@@archives/SET_ARCHIVE',
    SET_ARCHIVES = '@@archives/SET_ARCHIVES',
    SET_COUNT = '@@archives/SET_COUNT',
    SET_OP_ERRORS = '@@archives/SET_OP_ERRORS',
    SET_OP_PROGRESS = '@@archives/SET_OP_PROGRESS',
    SET_OP_SUCCESS = '@@archives/OP_SUCCESS',
    SHARE = '@@archives/SHARE',
    SHOW_DIALOG = '@@archives/SHOW_DIALOG',
    UNWATCH_ARCHIVE = '@@archives/UNWATCH_ARCHIVE',
    WATCH_ARCHIVE = '@@archives/WATCH_ARCHIVE',
    WATCH_TICK = '@@archives/WATCH_TICK',
}

export interface IArchiveFormErrors {
    comment: string;
    general: string;
    name: string;
    toContacts: string;
    // toFTP: string;
    toReview: string;
    toSFTP: string;
}

export interface IArchiveState {
    readonly archives: Proto.mediaarchiver.IArchive[];
    readonly opSuccessEventElement: HTMLElement;
    readonly operationInProgress: boolean;
    readonly showDialog: boolean;
    readonly totalRadio: number;
    readonly totalTV: number;
    readonly watchedArchives: string[];
}

export interface ICreateAction {
    payload: Proto.mediaarchiver.IArchive;
    type: ArchivesActionTypes.CREATE;
}

export interface IDeleteArchiveAction {
    payload: string;
    type: ArchivesActionTypes.DELETE;
}

export interface IDeletedArchiveAction {
    payload: string;
    type: ArchivesActionTypes.DELETED_ARCHIVE;
}

export interface IEditAction {
    payload: Proto.mediaarchiver.IArchive;
    type: ArchivesActionTypes.EDIT;
}

export interface IGetAction {
    payload: Proto.mediaarchiver.IArgumentsGetArchives;
    type: ArchivesActionTypes.GET;
}

export interface IGetByIdAction {
    payload: string;
    type: ArchivesActionTypes.GET_BY_ID;
}

export interface IGetCountAction {
    type: ArchivesActionTypes.GET_COUNT;
}

export interface IHideDialogAction {
    type: ArchivesActionTypes.HIDE_DIALOG;
}

export interface IIncCountersAction {
    payload: [string, number, number];
    type: ArchivesActionTypes.INC_COUNTERS;
}

export interface IRetryAction {
    payload: string;
    type: ArchivesActionTypes.RETRY;
}

export interface ISetArchiveAction {
    payload: Proto.mediaarchiver.IArchive;
    type: ArchivesActionTypes.SET_ARCHIVE;
}

export interface ISetOpErrorsAction {
    payload: IArchiveFormErrors;
    type: ArchivesActionTypes.SET_OP_ERRORS;
}

export interface ISetOpSuccessAction {
    type: ArchivesActionTypes.SET_OP_SUCCESS;
}

export interface ISetProgressAction {
    payload: boolean;
    type: ArchivesActionTypes.SET_OP_PROGRESS;
}

export interface IShareAction {
    payload: Proto.mediaarchiver.IArgumentsShareArchive;
    type: ArchivesActionTypes.SHARE;
}

export interface IShowDialogAction {
    type: ArchivesActionTypes.SHOW_DIALOG;
}

export interface IUnwatchArchiveAction {
    payload: string;
    type: ArchivesActionTypes.UNWATCH_ARCHIVE;
}

export interface IWatchArchiveAction {
    payload: string;
    type: ArchivesActionTypes.WATCH_ARCHIVE;
}

export interface IWatchTickAction {
    type: ArchivesActionTypes.WATCH_TICK;
}
