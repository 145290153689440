import { AMessage, IToCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';

export class ExportTalks extends AMessage implements IToCommand {
    private readonly specs: mediaarchiver.IArgumentsExportTalks;

    public constructor(specs: mediaarchiver.IArgumentsExportTalks) {
        super('exportTalks');
        this.specs = specs;
    }

    public Send(): Promise<void> {
        return this.send(
            new mediaarchiver.WSMessage({
                ExportTalksArg: this.specs,
                Type: mediaarchiver.WSMessageType.MessageTypeExportTalks,
            }),
        );
    }
}
