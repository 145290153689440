import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { IApplicationState } from '../../../Store';
import { getCount } from '../../../Store/Archives';
import { showReportBug } from '../../../Store/Timeline';
import { showIncident } from '../../../Store/Incident';
import ArchivesDrawerComponent, { IRouterMatchParams } from './Drawer';

import styles from './styles';

const mapStateToProps = (state: IApplicationState, ownProps: RouteComponentProps<IRouterMatchParams>) => ({
    archives: state.archives,
    i18n: state.i18n.i18n,
    incident: state.incident,
    router: ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getCount: () => dispatch(getCount()),
    showIncident: () => dispatch(showIncident()),
    showReportBug: () => dispatch(showReportBug()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ArchivesDrawerComponent));
