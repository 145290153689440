import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class CreateArchive extends AMessage implements IToCommand {
    private specs: Proto.mediaarchiver.IArchive;

    public constructor(specs: Proto.mediaarchiver.IArchive) {
        super('createArchive');
        this.specs = specs;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                CreateArchiveArg : this.specs,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeCreateArchive,
            }),
            30000,
        );
    }
}
