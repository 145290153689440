import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class DeleteMultipleAnnotations extends AMessage implements IToCommand {
    private annotationIDs: string[] = [];

    public constructor(annotationIDs: string[]) {
        super('deleteMultipleAnnotations');
        this.annotationIDs = annotationIDs;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            DeleteMultipleAnnotationsArg: {
                IDs: this.annotationIDs,
            },
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeDeleteMultipleAnnotations,
        }));
    }
}
