import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';

import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class SearchSpeechesResult extends AMessage implements IFromCommand {
    private parsedTotal: number | null = null;
    private parsedResults: Proto.mediaarchiver.ISearchSpeechResult[] | null = null;

    public constructor() {
        super('searchSpeechesResult');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg)) {
                return reject(new Error('Empty message'));
            }
            if (isNullOrUndefined(msg.SearchSpeechesResultArgs)) {
                return reject(new Error('Not a SearchSpeechesResult message'));
            }
            this.parsedTotal = !isNullOrUndefined(msg.SearchSpeechesResultArgs.Count)
                ? msg.SearchSpeechesResultArgs.Count
                : 0;
            this.parsedResults = !isNullOrUndefined(msg.SearchSpeechesResultArgs.Result)
                ? msg.SearchSpeechesResultArgs.Result
                : [];
            resolve();
        });
    }

    public get total(): number {
        if (isNull(this.parsedTotal)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedTotal;
    }

    public get results(): Proto.mediaarchiver.ISearchSpeechResult[] {
        if (isNull(this.parsedResults)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedResults;
    }
}
