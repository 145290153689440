import { Store } from 'redux';
import { action } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { SFTPAccount } from '../../Backend/Commands/SFTPAccount';
import { SFTPAccounts } from '../../Backend/Commands/SFTPAccounts';
import { SFTPAccountsCount } from '../../Backend/Commands/SFTPAccountsCount';
import * as Proto from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import {
    ICreateAction,
    IDeleteAccountAction,
    IDeletedAccountAction,
    IEditAction,
    IGetAction,
    IGetCountAction,
    ISFTPAccountFormErrors,
    ISetErrorsAction,
    ISetOpSuccessAction,
    ISetProgressAction,
    SFTPAccountActionTypes,
} from './Types';

export const create = (account: Proto.mediaarchiver.ISFTPAccount): ICreateAction =>
    action(SFTPAccountActionTypes.CREATE, account);
export const deleteAccount = (id: string): IDeleteAccountAction => action(SFTPAccountActionTypes.DELETE, id);
export const deletedAccount = (id: string): IDeletedAccountAction => action(SFTPAccountActionTypes.DELETED_ACCOUNT, id);
export const edit = (account: Proto.mediaarchiver.ISFTPAccount): IEditAction =>
    action(SFTPAccountActionTypes.EDIT, account);
export const get = (offset = 0, limit = 100): IGetAction => action(SFTPAccountActionTypes.GET, { limit, offset });
export const getCount = (): IGetCountAction => action(SFTPAccountActionTypes.GET_COUNT);
export const setErrors = (errors: ISFTPAccountFormErrors): ISetErrorsAction =>
    action(SFTPAccountActionTypes.SET_ERRORS, errors);
export const setOpSuccess = (): ISetOpSuccessAction => action(SFTPAccountActionTypes.OP_SUCCESS);
export const setProgress = (toggle: boolean): ISetProgressAction =>
    action(SFTPAccountActionTypes.SET_OP_PROGRESS, toggle);

Backend.getInstance()
    .Bind('sftpAccountsCount', (incoming: IFromCommand) => {
        const cmd = incoming as SFTPAccountsCount;

        Logger.trace({ count: cmd.count }, 'Received sftp accounts count');
        (store as Store).dispatch(action(SFTPAccountActionTypes.SET_ACCOUNTS_COUNT, cmd.count));
    })
    .Bind('sftpAccounts', (incoming: IFromCommand) => {
        const cmd = incoming as SFTPAccounts;

        Logger.trace({ cmd }, 'Received sftp accounts');
        (store as Store).dispatch(action(SFTPAccountActionTypes.SET_ACCOUNTS, cmd));
    })
    .Bind('sftpAccount', (incoming: IFromCommand) => {
        const cmd = incoming as SFTPAccount;

        Logger.trace({ cmd }, 'Received sftp account');
        (store as Store).dispatch(action(SFTPAccountActionTypes.SET_ACCOUNT, cmd.account));
    });
