import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class BlockMail extends AMessage implements IToCommand {
    private mail: string;
    private emailReason: string;

    public constructor(mail: string, emailReason: string) {
        super('blockMail');
        if (mail === '') {
            throw new Error('Missing mail');
        }
        if (emailReason === '') {
            throw new Error('Missing mail reason');
        }
        this.mail = mail;
        this.emailReason = emailReason;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            BlockMailArg : {
                Email: this.mail,
                MailReason: this.emailReason,
            },
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeBlockMail,
        }));
    }
}
