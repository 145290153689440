import { Reducer } from 'redux';
import { ISearchSTTState, SearchSTTActionTypes } from './Types';

const initialState: ISearchSTTState = {
    currentBegin: 0,
    currentEnd: 0,
    currentMediaID: 0,
    currentMediaName: '',
    currentSentence: [],
    currentStart: 0,
    currentTimestampedWords: new Map<number, string>(),
    currentWords: [],
    currentWordsUpdated: false,
    currentWordUID: '',
    initialState: true,
    lastSearchSpeeches: null,
    lastSearchSpeechesDate: null,
    newWords: [],
    page: 0,
    pageSize: 100,
    playerLoading: true,
    requestedCurrent: new Date(),
    requestedEnd: new Date(),
    requestedMedia: 0,
    requestedStart: new Date(),
    searchLoading: false,
    searchSpeechesResults: [],
    searchSpeechesResultsTotal: -1,
    searchWordsResults: [],
    selectedStart: 0,
    timelineProgram: null,
};
const reducer: Reducer<ISearchSTTState> = (state = initialState, action) => {
    switch (action.type) {
        case SearchSTTActionTypes.CLEAR: {
            return {
                ...state,
                currentBegin: 0,
                currentEnd: 0,
                currentMediaID: 0,
                currentMediaName: '',
                currentSentence: [],
                currentStart: 0,
                currentTimestampedWords: new Map<number, string>(),
                currentWords: [],
                currentWordsUpdated: false,
                currentWordUID: '',
                initialState: true,
                lastSearchSpeeches: null,
                lastSearchSpeechesDate: null,
                newWords: [],
                page: 0,
                pageSize: 100,
                playerLoading: true,
                requestedCurrent: new Date(),
                requestedEnd: new Date(),
                requestedMedia: 0,
                requestedStart: new Date(),
                searchLoading: false,
                searchSpeechesResults: [],
                searchSpeechesResultsTotal: -1,
                searchWordsResults: [],
                selectedStart: 0,
                timelineProgram: null,
            };
        }

        case SearchSTTActionTypes.CLEAR_REQUESTED_PARAMS: {
            return {
                ...state,
                requestedCurrent: new Date(),
                requestedEnd: new Date(),
                requestedMedia: 0,
                requestedStart: new Date(),
            };
        }

        case SearchSTTActionTypes.SET_CURRENTBEGIN: {
            return {
                ...state,
                currentBegin: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_CURRENTEND: {
            return {
                ...state,
                currentEnd: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_CURRENTMEDIAID: {
            return {
                ...state,
                currentMediaID: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_CURRENTMEDIANAME: {
            return {
                ...state,
                currentMediaName: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_CURRENTSENTENCE: {
            return {
                ...state,
                currentSentence: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_CURRENTSTART: {
            return {
                ...state,
                currentStart: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_CURRENTTIMESTAMPEDWORDS: {
            return {
                ...state,
                currentTimestampedWords: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_CURRENTWORDS: {
            return {
                ...state,
                currentWords: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_CURRENTWORDUID: {
            return {
                ...state,
                currentWordUID: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_LAST_SEARCH_SPEECHES: {
            return {
                ...state,
                lastSearchSpeeches: action.payload,
            };
        }

        case SearchSTTActionTypes.SET_NEWWORDS: {
            return {
                ...state,
                newWords: action.payload,
            };
        }

        case SearchSTTActionTypes.SET_REQUESTED_CURRENTTIME: {
            return {
                ...state,
                requestedCurrent: action.payload,
            };
            
        }
        case SearchSTTActionTypes.SET_REQUESTED_END: {
            return {
                ...state,
                requestedEnd: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_REQUESTED_MEDIA: {
            return {
                ...state,
                requestedMedia: action.payload,
            }
        }
        case SearchSTTActionTypes.SET_REQUESTED_START: {
            return {
                ...state,
                requestedStart: action.payload,
            };
            
        }

        case SearchSTTActionTypes.SET_SEARCH_SPEECHES_LOADING: {
            return {
                ...state,
                initialState: false,
                searchLoading: action.payload,
            };
        }
        case SearchSTTActionTypes.SET_SEARCH_SPEECHES_PARAMS: {
            return {
                ...state,
                currentSearch: action.payload,
                initialState: false,
            };
        }
        case SearchSTTActionTypes.SET_SEARCH_SPEECHES_RESULTS: {
            return {
                ...state,
                initialState: false,
                searchLoading: false,
                searchSpeechesResults: action.payload[0],
                searchSpeechesResultsTotal: action.payload[1],
            };
        }
        case SearchSTTActionTypes.SET_TIMELINE_PROGRAM: {
            return {
                ...state,
                timelineProgram: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};
export { reducer as SearchSTTReducer };
