import { ITimelineCriteriasState } from '../Store/Timeline';

export function openTimeline(crit: ITimelineCriteriasState): void {
    gtag('event', 'openTimeline', {
        event_category: 'timeline',
        event_label: 'Ouverture de timeline',
        value: Math.round((crit.end.getTime() - crit.start.getTime()) / 3600000),
    });
    gtag('event', 'openTimelineType', {
        event_category: 'timeline',
        event_label: 'Type de timeline',
        value: crit.mediaType,
    });
}

export function createAnnotation(): void {
    gtag('event', 'createAnnotation', {
        event_category: 'functions',
        event_label: "Création d'une annotation",
    });
}

export function editAnnotation(): void {
    gtag('event', 'editAnnotation', {
        event_category: 'functions',
        event_label: "Modification d'une annotation",
    });
}

export function createArchive(): void {
    gtag('event', 'createArchive', {
        event_category: 'functions',
        event_label: "Création d'une archive",
    });
}

export function editArchive(): void {
    gtag('event', 'editArchive', {
        event_category: 'functions',
        event_label: "Modification d'une archive",
    });
}

export function createTalk(): void {
    gtag('event', 'createTalk', {
        event_category: 'functions',
        event_label: "Création d'un temps de parole politique",
    });
}

export function editTalk(): void {
    gtag('event', 'editTalk', {
        event_category: 'functions',
        event_label: "Modification d'un temps de parole politique",
    });
}
