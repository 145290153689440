import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class IncArchiveCounter extends AMessage implements IToCommand {
    private archiveID: string;
    private downloads: number;
    private views: number;

    public constructor(archiveID: string, views: number, downloads: number) {
        super('incArchiveCounter');
        this.archiveID = archiveID;
        this.downloads = downloads;
        this.views = views;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            IncArchiveCounterArg : {
                ArchiveID: this.archiveID,
                Downloads: this.downloads,
                Views: this.views,
            },
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeIncArchiveCounter,
        }));
    }
}
