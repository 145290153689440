import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefined } from '../../Utils/Various';

export class TalkAllTitles extends AMessage implements IFromCommand {
    private parsedTitles: Proto.mediaarchiver.ITalkTitle[] | null = null;

    public constructor() {
        super('talkAllTitles');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.TalkAllTitlesArg) || isNullOrUndefined(msg.TalkAllTitlesArg.Titles)) {
                return reject(new Error('Not a TalkAllTitles message'));
            }
            this.parsedTitles = msg.TalkAllTitlesArg.Titles;
            resolve();
        });
    }

    public get titles(): Proto.mediaarchiver.ITalkTitle[] {
        if (isNullOrUndefined(this.parsedTitles)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedTitles;
    }
}
