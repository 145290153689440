import { Store } from 'redux';
import { action } from 'typesafe-actions';

import { IApplicationState } from '..';
import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { SearchResults } from '../../Backend/Commands/SearchResults';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import {
    IClearAction,
    ISearchAction,
    ISearchParams,
    ISetCurrentSearchAction,
    ISetLastSearchAction,
    ISetResultsAction,
    ISetResultsCountAction,
    ISetResultsTotalAction,
    ISetSearchLoadingAction,
    ISetStructuredResultsAction,
    ISetTimelineProgramAction,
    NullableResult,
    NullableStructuredResult,
    SearchActionTypes,
    hashedSearchParams,
} from './Types';

export const clear = (): IClearAction => action(SearchActionTypes.CLEAR);
export const search = (params: ISearchParams): ISearchAction => action(SearchActionTypes.SEARCH, params);
export const setCurrentSearch = (params: hashedSearchParams): ISetCurrentSearchAction =>
    action(SearchActionTypes.SET_SEARCH_PARAMS, params);
export const setLastSearch = (lastSearch: ISearchParams | null): ISetLastSearchAction =>
    action(SearchActionTypes.SET_LAST_SEARCH, lastSearch);
export const setResults = (results: NullableResult[], page: number): ISetResultsAction =>
    action(SearchActionTypes.SET_SEARCH_RESULTS, [results, page]);
export const setResultsCount = (count: number): ISetResultsCountAction =>
    action(SearchActionTypes.SET_SEARCH_RESULTS_COUNT, count);
export const setResultsTotal = (total: number): ISetResultsTotalAction =>
    action(SearchActionTypes.SET_SEARCH_RESULTS_TOTAL, total);
export const setSearchLoading = (loading: boolean): ISetSearchLoadingAction =>
    action(SearchActionTypes.SET_SEARCH_LOADING, loading);
export const setStructuredResults = (results: NullableStructuredResult[], page: number): ISetStructuredResultsAction =>
    action(SearchActionTypes.SET_STRUCTURED_SEARCH_RESULTS, [results, page]);
export const setTimelineProgram = (prg: NullableResult): ISetTimelineProgramAction =>
    action(SearchActionTypes.SET_TIMELINE_PROGRAM, prg);

Backend.getInstance().Bind('searchResults', (incoming: IFromCommand) => {
    const cmd = incoming as SearchResults;
    const str = store as Store;
    const state = str.getState() as IApplicationState;

    try {
        Logger.debug(
            {
                results: cmd.results,
                structuredResults: cmd.structuredResults,
                total: cmd.total,
            },
            'Received search results',
        );
        let dest = state.search.pageSize * cmd.page;

        if (dest > cmd.total) {
            dest = cmd.total;
        }
        str.dispatch(setResultsCount(dest));
        str.dispatch(setResultsTotal(cmd.total));
        if (cmd.structuredResults.length !== 0) {
            str.dispatch(setStructuredResults(cmd.structuredResults, cmd.page));
        } else {
            str.dispatch(setResults(cmd.results, cmd.page));
        }
    } catch (err) {
        Logger.warn(err as Error, 'Unable to parse search result');
    }
});
