import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefined } from '../../Utils/Various';

export class TalkDistricts extends AMessage implements IFromCommand {
    private parsedDistricts: Proto.mediaarchiver.ITalkDistrict[] | null = null;

    public constructor() {
        super('talkDistricts');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.TalkDistrictsArgs) || !Array.isArray(msg.TalkDistrictsArgs.Districts)) {
                return reject(new Error('Not a TalkDistricts message'));
            }

            this.parsedDistricts = msg.TalkDistrictsArgs.Districts;
            resolve();
        });
    }

    public get districts(): Proto.mediaarchiver.ITalkDistrict[] {
        if (isNullOrUndefined(this.parsedDistricts)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedDistricts;
    }
}
