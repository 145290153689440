import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetTalks extends AMessage implements IToCommand {
    private query: Proto.mediaarchiver.IArgumentsGetTalks;

    public constructor(query: Proto.mediaarchiver.IArgumentsGetTalks) {
        super('getTalks');
        this.query = query;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                GetTalksArg: this.query,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetTalks,
            }),
        );
    }
}
