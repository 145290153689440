import { mediaarchiver } from '../Protos/protos';
import { Logger } from '../Utils/Logger';

import { IFromCommand } from './Commands';

import { AnnotationDirect } from './Commands/AnnotationDirect';
import { Annotations } from './Commands/Annotations';
import { AnnotationTypes } from './Commands/AnnotationTypes';
import { AnnotationTypesCount } from './Commands/AnnotationTypesCount';
import { AnnotationTypesOwned } from './Commands/AnnotationTypesOwned';
import { AnnotationTypesOwnedCount } from './Commands/AnnotationTypesOwnedCount';
import { Archive } from './Commands/Archive';
import { Archives } from './Commands/Archives';
import { ArchivesCount } from './Commands/ArchivesCount';
import { Chunk } from './Commands/Chunk';
import { Contact } from './Commands/Contact';
import { Contacts } from './Commands/Contacts';
import { ContactsCount } from './Commands/ContactsCount';
import { EPG } from './Commands/EPG';
import { ExpiringToken } from './Commands/ExpiringToken';
import { FTPAccount } from './Commands/FTPAccount';
import { FTPAccounts } from './Commands/FTPAccounts';
import { FTPAccountsCount } from './Commands/FTPAccountsCount';
import { Mail } from './Commands/Mail';
import { Medias } from './Commands/Medias';
import { MessageAck } from './Commands/MessageAck';
import { Panels } from './Commands/Panels';
import { Review } from './Commands/Review';
import { Reviews } from './Commands/Reviews';
import { SearchKindsResult } from './Commands/SearchKindsResult';
import { SearchResults } from './Commands/SearchResults';
import { SFTPAccount } from './Commands/SFTPAccount';
import { SFTPAccounts } from './Commands/SFTPAccounts';
import { SFTPAccountsCount } from './Commands/SFTPAccountsCount';
import { SearchSpeechesResult } from './Commands/SearchSpeechResult';
import { SearchSpeechesDateResult } from './Commands/SearchSpeechDateResult';
import { SearchWordsResult } from './Commands/SearchWordsResult';
import { Stream } from './Commands/Stream';
import { TalkAutocompleteNameSuggs } from './Commands/TalkAutocompleteNameSuggs';
import { TalkPerson } from './Commands/TalkPerson';
import { Talks } from './Commands/Talks';
import { TalkAllGroups } from './Commands/TalkAllGroups';
import { TalkAllRegims } from './Commands/TalkAllRegims';
import { TalkAllTitles } from './Commands/TalkAllTitles';
import { TalkDistricts } from './Commands/TalkDistricts';
import { TalkExportedTalks } from './Commands/TalkExportedTalks';
import { TalkMedias } from './Commands/TalkMedias';
import { TalkProgramTypes } from './Commands/TalkProgramTypes';
import { TalkStudies } from './Commands/TalkStudies';
import { WhoYouAre } from './Commands/WhoYouAre';
import { SttMedias } from './Commands/SttMedias';
// import { GetSttMedias } from './Commands/GetSttMedias';
import { BackgroundMedias } from './Commands/BackgroundMedias';
import { CrossBucketMedias } from './Commands/CrossBucketMedias';

/**
 * Parses an incoming message to the bounded command.
 *
 * @param msg Incoming message.
 */
export async function parseIncomingMessage(msg: mediaarchiver.WSMessage): Promise<IFromCommand> {
    let cmd: IFromCommand;
    switch (msg.Type) {
        case mediaarchiver.WSMessageType.MessageTypeAck:
            cmd = new MessageAck();
            break;
        case mediaarchiver.WSMessageType.MessageTypeAnnotations:
            cmd = new Annotations();
            break;
        case mediaarchiver.WSMessageType.MessageTypeAnnotationTypes:
            cmd = new AnnotationTypes();
            break;
        case mediaarchiver.WSMessageType.MessageTypeAnnotationTypesCount:
            cmd = new AnnotationTypesCount();
            break;
        case mediaarchiver.WSMessageType.MessageTypeArchives:
            cmd = new Archives();
            break;
        case mediaarchiver.WSMessageType.MessageTypeArchive:
            cmd = new Archive();
            break;
        case mediaarchiver.WSMessageType.MessageTypeArchivesCount:
            cmd = new ArchivesCount();
            break;
        case mediaarchiver.WSMessageType.MessageTypeContacts:
            cmd = new Contacts();
            break;
        case mediaarchiver.WSMessageType.MessageTypeContactsCount:
            cmd = new ContactsCount();
            break;
        case mediaarchiver.WSMessageType.MessageTypeEPG:
            cmd = new EPG();
            break;
        case mediaarchiver.WSMessageType.MessageTypeFTPAccounts:
            cmd = new FTPAccounts();
            break;
        case mediaarchiver.WSMessageType.MessageTypeFTPAccountsCount:
            cmd = new FTPAccountsCount();
            break;
        case mediaarchiver.WSMessageType.MessageTypeChunk:
            cmd = new Chunk();
            break;
        case mediaarchiver.WSMessageType.MessageTypeMedias:
            cmd = new Medias();
            break;
        case mediaarchiver.WSMessageType.MessageTypeSearchResults:
            cmd = new SearchResults();
            break;
        case mediaarchiver.WSMessageType.MessageTypeSFTPAccounts:
            cmd = new SFTPAccounts();
            break;
        case mediaarchiver.WSMessageType.MessageTypeSFTPAccountsCount:
            cmd = new SFTPAccountsCount();
            break;
        case mediaarchiver.WSMessageType.MessageTypeStream:
            cmd = new Stream();
            break;
        case mediaarchiver.WSMessageType.MessageTypeWhoYouAre:
            cmd = new WhoYouAre();
            break;
        case mediaarchiver.WSMessageType.MessageTypeReview:
            cmd = new Review();
            break;
        case mediaarchiver.WSMessageType.MessageTypeReviews:
            cmd = new Reviews();
            break;
        case mediaarchiver.WSMessageType.MessageTypeContact:
            cmd = new Contact();
            break;
        case mediaarchiver.WSMessageType.MessageTypeFTPAccount:
            cmd = new FTPAccount();
            break;
        case mediaarchiver.WSMessageType.MessageTypeSFTPAccount:
            cmd = new SFTPAccount();
            break;
        case mediaarchiver.WSMessageType.MessageTypeMail:
            cmd = new Mail();
            break;
        case mediaarchiver.WSMessageType.MessageTypePanels:
            cmd = new Panels();
            break;
        case mediaarchiver.WSMessageType.MessageTypeSearchKindsResult:
            cmd = new SearchKindsResult();
            break;
        case mediaarchiver.WSMessageType.MessageTypeAnnotationTypesOwned:
            cmd = new AnnotationTypesOwned();
            break;
        case mediaarchiver.WSMessageType.MessageTypeAnnotationTypesOwnedCount:
            cmd = new AnnotationTypesOwnedCount();
            break;
        case mediaarchiver.WSMessageType.MessageTypeSearchSpeechesResult:
            cmd = new SearchSpeechesResult();
            break;
        case mediaarchiver.WSMessageType.MessageTypeSearchWordsResult:
            cmd = new SearchWordsResult();
            break;
        case mediaarchiver.WSMessageType.MessageTypeSearchSpeechesDateResult:
            cmd = new SearchSpeechesDateResult();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalkAutocompleteNameSuggs:
            cmd = new TalkAutocompleteNameSuggs();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalkPerson:
            cmd = new TalkPerson();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalks:
            cmd = new Talks();
            break;
        case mediaarchiver.WSMessageType.MessageTypeAnnotationDirect:
            cmd = new AnnotationDirect();
            break;
        case mediaarchiver.WSMessageType.MessageTypeExpiringToken:
            cmd = new ExpiringToken();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalkStudies:
            cmd = new TalkStudies();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalkDistricts:
            cmd = new TalkDistricts();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalkProgramTypes:
            cmd = new TalkProgramTypes();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalkAllGroups:
            cmd = new TalkAllGroups();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalkAllRegims:
            cmd = new TalkAllRegims();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalkAllTitles:
            cmd = new TalkAllTitles();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalkMedias:
            cmd = new TalkMedias();
            break;
        case mediaarchiver.WSMessageType.MessageTypeTalkExportedTalks:
            cmd = new TalkExportedTalks();
            break;
        case mediaarchiver.WSMessageType.MessageTypeMediasSTTid:
            cmd = new SttMedias();
            break;
        case mediaarchiver.WSMessageType.MessageTypeMediasBackgroundid:
            cmd = new BackgroundMedias();
            break;
        case mediaarchiver.WSMessageType.MessageTypeMediasCrossBucketid:
            cmd = new CrossBucketMedias();
            break;
        default:
            Logger.debug('Ignoring unknown command type message', {
                type: msg.Type,
            });
            return Promise.reject(new Error('Unknown message type'));
    }
    return cmd.Parse(msg).then(() => cmd);
}
