import { effects } from 'redux-saga';

import { GetMedias } from '../../Backend/Commands/GetMedias';
import { GetMediasSTT } from '../../Backend/Commands/GetMediasSTT';
import { GetPanels } from '../../Backend/Commands/GetPanels';
import { GetSttMedias } from '../../Backend/Commands/GetSttMedias';
import { GetTalkMedias } from '../../Backend/Commands/GetTalkMedias';
import { SearchKinds } from '../../Backend/Commands/SearchKinds';
import { Logger } from '../../Utils/Logger';

import { searchKinds } from './Actions';
import { MediasActionTypes } from './Types';
import { GetBackgroundMedias } from '../../Backend/Commands/GetBackgroundMedias';
import { GetCrossBucketMedias } from '../../Backend/Commands/GetCrossBucketMedias';

function* handleFetch() {
    const cmd = new GetMedias(true, true);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to fetch media list');
    }
}

function* handleFetchSTT() {
    const cmd = new GetMediasSTT(true, true);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to fetch media list');
    }
}

function* handleGetPanels() {
    const cmd = new GetPanels();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to fetch media panels list');
    }
}

function* handleGetTalkMedias() {
    const cmd = new GetTalkMedias();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to fetch talk medias list');
    }
}

function* handleGetBackgroundMedias() {
    const cmd = new GetBackgroundMedias();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to fetch background media list');
    }
}
function* handleGetCrossBucketMedias() {
    const cmd = new GetCrossBucketMedias();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to fetch crossbucket media list');
    }
}

function* handleGetSttMedias() {
    const cmd = new GetSttMedias();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to fetch stt media list');
    }
}

function* handleSearchKinds(action: ReturnType<typeof searchKinds>) {
    const cmd = new SearchKinds(action.payload);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to search program kinds');
    }
}

function* watchFetchRequest() {
    yield effects.takeEvery(MediasActionTypes.UPDATE, handleFetch);
}

function* watchFetchSTTRequest() {
    yield effects.takeEvery(MediasActionTypes.UPDATE_STT, handleFetchSTT);
}

function* watchGetPanelsRequest() {
    yield effects.takeEvery(MediasActionTypes.GET_PANELS, handleGetPanels);
}

function* watchGetTalkMediasRequest() {
    yield effects.takeLatest(MediasActionTypes.GET_TALK_MEDIAS, handleGetTalkMedias);
}
function* watchGetSttMediasRequest() {
    yield effects.takeLatest(MediasActionTypes.GET_STT_MEDIAS, handleGetSttMedias);
}
function* watchGetBackgroundMediasRequest() {
    yield effects.takeLatest(MediasActionTypes.GET_BACKGROUND_MEDIAS, handleGetBackgroundMedias);
}
function* watchGetCrossBucketMediasRequest() {
    yield effects.takeLatest(MediasActionTypes.GET_CROSSBUCKET_MEDIAS, handleGetCrossBucketMedias);
}

function* watchSearchKindsRequest() {
    yield effects.takeEvery(MediasActionTypes.SEARCH_KINDS, handleSearchKinds);
}

export function* MediasSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchFetchRequest)]);
    yield effects.all([effects.fork(watchFetchSTTRequest)]);
    yield effects.all([effects.fork(watchGetBackgroundMediasRequest)]);
    yield effects.all([effects.fork(watchGetCrossBucketMediasRequest)]);
    yield effects.all([effects.fork(watchGetPanelsRequest)]);
    yield effects.all([effects.fork(watchGetSttMediasRequest)]);
    yield effects.all([effects.fork(watchGetTalkMediasRequest)]);
    yield effects.all([effects.fork(watchSearchKindsRequest)]);
}
