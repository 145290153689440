import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetArchives extends AMessage implements IToCommand {
    private specs: Proto.mediaarchiver.IArgumentsGetArchives;

    public constructor(specs: Proto.mediaarchiver.IArgumentsGetArchives) {
        super('getArchives');
        this.specs = specs;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            GetArchivesArg: this.specs,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetArchives,
        }));
    }
}
