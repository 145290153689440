import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class TalkAutocompleteName extends AMessage implements IToCommand {
    private typed: string;
    private study: number;

    public constructor(typed: string, study: number) {
        super('talkAutocompleteName');
        if (typed.length < 3) {
            throw new Error('Must be at least 3 characters');
        }
        this.typed = typed;
        this.study = study;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                TalkAutocompleteNameArg: {
                    Study: this.study,
                    Typed: this.typed,
                },
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeTalkAutocompleteName,
            }),
        );
    }
}
