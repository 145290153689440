/* eslint-disable react/prop-types */
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../Store';
import { I18N } from '../Store/I18n';

interface IPropsFromState {
    i18n: I18N;
}

const AppLoader: React.FC<IPropsFromState> = ({ i18n }) => {
    return (
        <Card>
            <CardContent>
                <Typography>
                    <FontAwesomeIcon icon={faSpinner} spin />
                    &nbsp;&nbsp;
                    {i18n._('Application is loading')}
                </Typography>
            </CardContent>
        </Card>
    );
};

const mapStateToProps = ({ i18n }: IApplicationState) => ({
    i18n: i18n.i18n,
});

export default connect(mapStateToProps)(AppLoader);
