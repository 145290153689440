import { AMessage, IToCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';

export class EditAnnotation extends AMessage implements IToCommand {
    private readonly specs: mediaarchiver.IAnnotation;

    public constructor(specs: mediaarchiver.IAnnotation) {
        super('editAnnotation');
        this.specs = specs;
    }

    public Send(): Promise<void> {
        return this.send(
            new mediaarchiver.WSMessage({
                EditAnnotationArg: this.specs,
                Type: mediaarchiver.WSMessageType.MessageTypeEditAnnotation,
            }),
        );
    }
}
