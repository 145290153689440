import { Reducer } from 'redux';

import { IMailState, MailsActionTypes } from './Types';

const initialState: IMailState = {
    mail: '',
    opSuccessEventElement: document.createElement('div'),
    operationInProgress: false,
};

const reducer: Reducer<IMailState> = (state = initialState, action) => {
    switch (action.type) {
        case MailsActionTypes.SET_ERROR: {
            state.opSuccessEventElement.dispatchEvent(
                new CustomEvent('opError', { detail: action.payload }),
            );
            return { ...state, operationInProgress: false };
        }

        case MailsActionTypes.SET_MAIL: {
            if (action.payload === null) {
                return state;
            }
            return { ...state, mail: action.payload, operationInProgress: false };
        }

        case MailsActionTypes.SET_OP_PROGRESS: {
            return { ...state, operationInProgress: action.payload };
        }

        case MailsActionTypes.SET_OP_SUCCESS: {
            state.opSuccessEventElement.dispatchEvent(
                new CustomEvent('opSuccess'),
            );
            return { ...state, operationInProgress: false };
        }

        default: {
            return state;
        }
    }
};

export { reducer as MailsReducer };
