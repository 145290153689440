import { Reducer, Store } from 'redux';
import { action as newAction } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { AnnotationDirect } from '../../Backend/Commands/AnnotationDirect';
import { Annotations } from '../../Backend/Commands/Annotations';
import { isNullOrUndefined } from '../../Utils/Various';
import { store } from '../store';
import { AnnotationActionTypes, IAnnotationsState } from './Types';

const initialState: IAnnotationsState = {
    createAnnotationError: '',
    createAnnotationLoading: false,
    createAnnotationSuccessEventElement: document.createElement('div'),
    editedAnnotation: null,
    lastSearch: null,
    opSuccessEventElement: document.createElement('div'),
    searchLoading: false,
    searchResults: [],
    searchResultsCount: 0,
    showDialog: false,
    timelineAnnotation: null,
};

const reducer: Reducer<IAnnotationsState> = (state = initialState, action) => {
    switch (action.type) {
        case AnnotationActionTypes.CREATE_ANNOTATION_ERROR: {
            const ev = new CustomEvent('opError', { detail: action.payload });

            state.createAnnotationSuccessEventElement.dispatchEvent(ev);
            return { ...state, createAnnotationLoading: false };
        }

        case AnnotationActionTypes.CREATE_ANNOTATION_PROGRESS: {
            return { ...state, createAnnotationLoading: action.payload };
        }

        case AnnotationActionTypes.CREATE_ANNOTATION_SUCCESS: {
            state.createAnnotationSuccessEventElement.dispatchEvent(new CustomEvent('opSuccess'));
            return { ...state, createAnnotationLoading: false };
        }

        case AnnotationActionTypes.HIDE_DIALOG: {
            if (!isNullOrUndefined(document.activeElement) && 'blur' in document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
            return {
                ...state,
                editedAnnotation: null,
                showDialog: false,
            };
        }

        case AnnotationActionTypes.OP_SUCCESS: {
            state.opSuccessEventElement.dispatchEvent(new CustomEvent('opSuccess', { detail: action.payload }));
            return state;
        }

        case AnnotationActionTypes.OPEN_EDIT_DIALOG: {
            return { ...state, editedAnnotation: action.payload, showDialog: true };
        }

        case AnnotationActionTypes.SET_LAST_SEARCH: {
            return {
                ...state,
                lastSearch: action.payload,
            };
        }

        case AnnotationActionTypes.SET_SEARCH_LOADING: {
            return {
                ...state,
                searchLoading: action.payload,
            };
        }

        case AnnotationActionTypes.SET_SEARCH_RESULTS: {
            const cmd: Annotations = action.payload;
            const annotations = cmd.annotations;
            const searchResults = state.searchResults;

            if (cmd.offset >= cmd.count) {
                return state;
            }
            if (isNullOrUndefined(annotations) || cmd.count === 0) {
                return { ...state, searchResults: [], searchResultsCount: 0 };
            }
            if (cmd.offset > annotations.length) {
                for (let i = annotations.length; i < cmd.offset; i++) {
                    searchResults[i] = {};
                }
            }
            for (let i = 0; i < annotations.length; i++) {
                searchResults[i + cmd.offset] = annotations[i];
            }
            return { ...state, searchResults, searchResultsCount: cmd.count };
        }

        case AnnotationActionTypes.SET_TIMELINE_ANNOTATION: {
            return {
                ...state,
                timelineAnnotation: action.payload,
            };
        }

        case AnnotationActionTypes.SHOW_DIALOG: {
            if (!isNullOrUndefined(document.activeElement) && 'blur' in document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
            return {
                ...state,
                showDialog: true,
            };
        }

        default: {
            return state;
        }
    }
};

Backend.getInstance()
    .Bind('annotations', (incoming: IFromCommand) => {
        const cmd = incoming as Annotations;

        (store as Store).dispatch(newAction(AnnotationActionTypes.SET_SEARCH_RESULTS, cmd));
    })
    .Bind('annotationDirect', (incoming: IFromCommand) => {
        const cmd = incoming as AnnotationDirect;

        window.setTimeout(() => {
            window.open(cmd.link);
        }, 2000);
    });

export { reducer as AnnotationsReducer };
