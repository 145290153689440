import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetMediasSTT extends AMessage implements IToCommand {
    private tv: boolean;
    private radio: boolean;

    public constructor(tv: boolean, radio: boolean) {
        super('getMediasSTT');
        if (!radio && !tv) {
            throw new Error('GetMediasSTT needs at least one type');
        }
        this.tv = tv;
        this.radio = radio;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            GetMediasArgs : new Proto.mediaarchiver.ArgumentsGetMediasSTT({
                Radio: this.radio,
                TV: this.tv,
            }),
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetMediasSTT,
        }));
    }
}
