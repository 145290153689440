import { action } from 'typesafe-actions';

import { Talks } from '../../Backend/Commands/Talks';
import { mediaarchiver } from '../../Protos/protos';
import {
    IAddTalkAction,
    IClearDistrictsAction,
    IClearResultsAction,
    IClearSuggsAction,
    IDeleteMultipleAction,
    IDeleteTalkAction,
    IEditTalkAction,
    IExportAnnotationsAction,
    IGetAllValuesAction,
    IGetDistrictsAction,
    IGetPersonAction,
    IGetProgramTypesAction,
    IGetStudiesAction,
    IGetSuggAction,
    IHideCopyDialogAction,
    IHideDialogAction,
    ITalkLastPerson,
    ISearchAction,
    ISetCopiedTalksAction,
    ISetDistrictsAction,
    ISetLastPersonAction,
    ISetLastSearchAction,
    ISetLoadingAction,
    ISetPersonLoadingAction,
    ISetProgramTypesAction,
    ISetSearchLoadingAction,
    ISetSearchresultsAction,
    ISetStudiesAction,
    ISetSuggLoadingAction,
    ISetSuggsAction,
    ISetTimelineTalkAction,
    IShowAggregatedDialogAction,
    IShowCopyDialogAction,
    IShowDialogAction,
    IShowEditDialogAction,
    TalkActionTypes,
} from './Types';

export const addTalk = (talk: mediaarchiver.ITalk): IAddTalkAction => action(TalkActionTypes.ADD_TALK, talk);
export const clearDistricts = (): IClearDistrictsAction => action(TalkActionTypes.CLEAR_DISTRICTS);
export const clearResults = (): IClearResultsAction => action(TalkActionTypes.CLEAR_RESULTS);
export const clearSuggs = (): IClearSuggsAction => action(TalkActionTypes.CLEAR_SUGGS);
export const deleteMultipleTalks = (ids: string[]): IDeleteMultipleAction =>
    action(TalkActionTypes.DELETE_MULTIPLE, ids);
export const deleteTalk = (id: string): IDeleteTalkAction => action(TalkActionTypes.DELETE_TALK, id);
export const editTalk = (talk: mediaarchiver.ITalk): IEditTalkAction => action(TalkActionTypes.EDIT_TALK, talk);
export const exportTalks = (q: mediaarchiver.IArgumentsExportTalks): IExportAnnotationsAction =>
    action(TalkActionTypes.EXPORT, q);
export const getAllValues = (): IGetAllValuesAction => action(TalkActionTypes.GET_ALL_VALUES);
export const getDistricts = (q: mediaarchiver.ITalkGetDistricts): IGetDistrictsAction =>
    action(TalkActionTypes.GET_DISTRICTS, q);
export const getPerson = (id: string): IGetPersonAction => action(TalkActionTypes.GET_PERSON, id);
export const getProgramTypes = (): IGetProgramTypesAction => action(TalkActionTypes.GET_PROGRAM_TYPES);
export const getStudies = (): IGetStudiesAction => action(TalkActionTypes.GET_STUDIES);
export const getSugg = (typed: string, study: number): IGetSuggAction =>
    action(TalkActionTypes.GET_SUGG, [typed, study]);
export const hideCopyDialog = (): IHideCopyDialogAction => action(TalkActionTypes.HIDE_COPY_DIALOG);
export const hideDialog = (): IHideDialogAction => action(TalkActionTypes.HIDE_DIALOG);
export const search = (specs: mediaarchiver.IArgumentsGetTalks): ISearchAction => action(TalkActionTypes.SEARCH, specs);
export const setLastPerson = (p: ITalkLastPerson): ISetLastPersonAction => action(TalkActionTypes.SET_LAST_PERSON, p);
export const setLastSearch = (specs: mediaarchiver.IArgumentsGetTalks): ISetLastSearchAction =>
    action(TalkActionTypes.SET_LAST_SEARCH, specs);
export const setCopiedTalks = (d: mediaarchiver.ITalk[]): ISetCopiedTalksAction =>
    action(TalkActionTypes.SET_COPIED_TALKS, d);
export const setDistricts = (d: mediaarchiver.ITalkDistrict[]): ISetDistrictsAction =>
    action(TalkActionTypes.SET_DISTRICTS, d);
export const setLoading = (loading: boolean): ISetLoadingAction => action(TalkActionTypes.SET_LOADING, loading);
export const setProgramTypes = (t: mediaarchiver.ITalkProgramType[]): ISetProgramTypesAction =>
    action(TalkActionTypes.SET_PROGRAM_TYPES, t);
export const setSearchLoading = (loading: boolean): ISetSearchLoadingAction =>
    action(TalkActionTypes.SET_SEARCH_LOADING, loading);
export const setSearchResults = (cmd: Talks): ISetSearchresultsAction =>
    action(TalkActionTypes.SET_SEARCH_RESULTS, cmd);
export const setStudies = (s: mediaarchiver.ITalkStudy[]): ISetStudiesAction => action(TalkActionTypes.SET_STUDIES, s);
export const setSuggs = (suggs: mediaarchiver.ITalkPersonSugg[]): ISetSuggsAction =>
    action(TalkActionTypes.SET_SUGGS, suggs);
export const setPersonLoading = (loading: boolean): ISetPersonLoadingAction =>
    action(TalkActionTypes.SET_PERSON_LOADING, loading);
export const setSuggLoading = (loading: boolean): ISetSuggLoadingAction =>
    action(TalkActionTypes.SET_SUGG_LOADING, loading);
export const setTimelineTalk = (talk: mediaarchiver.ITalk | null): ISetTimelineTalkAction =>
    action(TalkActionTypes.SET_TIMELINE_TALK, talk);
export const showAggregatedDialog = (talk: mediaarchiver.ITalk): IShowAggregatedDialogAction =>
    action(TalkActionTypes.SHOW_AGGREGATED_DIALOG, talk);
export const showCopyDialog = (): IShowCopyDialogAction => action(TalkActionTypes.SHOW_COPY_DIALOG);
export const showDialog = (): IShowDialogAction => action(TalkActionTypes.SHOW_DIALOG);
export const showEditDialog = (talk: mediaarchiver.ITalk): IShowEditDialogAction =>
    action(TalkActionTypes.SHOW_EDIT_DIALOG, talk);
