import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefinedOrEmptyString } from '../../Utils/Various';

export class SearchSpeech extends AMessage implements IToCommand {
    private search: string;
    private logic: Proto.mediaarchiver.SearchSpeechesLogic;
    private medias: number[];
    private start: number;
    private end: number;
    private rows: number;
    private page: number;

    public constructor(
        search: string,
        logic: Proto.mediaarchiver.SearchSpeechesLogic,
        medias: number[],
        start: Date,
        end: Date,
        rows: number,
        page: number,
    ) {
        super('searchSpeech');

        if (isNullOrUndefinedOrEmptyString(search)) {
            throw new Error('search must be a non empty string');
        }
        switch (logic) {
            case Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_AND:
            case Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_OR:
            case Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_PHRASE:
                break;
            default:
                throw new Error('invalid search logic');
        }
        if (medias.length < 1) {
            throw new Error('no media provided');
        }
        if (end.getTime() <= start.getTime()) {
            throw new Error('start is equal of after end');
        }

        this.search = search;
        this.logic = logic;
        this.medias = medias;
        this.start = Math.round(start.getTime() / 1000);
        this.end = Math.round(end.getTime() / 1000);
        this.rows = rows;
        this.page = page;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                SearchSpeechesArgs: {
                    Search: this.search,
                    Logic: this.logic,
                    Medias: this.medias,
                    Start: this.start,
                    End: this.end,
                    Rows: this.rows,
                    Page: this.page,
                },
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeSearchSpeeches,
            }),
            20000,
        );
    }
}
