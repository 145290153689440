import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class SearchKinds extends AMessage implements IToCommand {
    private search: string;

    public constructor(search: string) {
        super('searchKinds');
        this.search = search;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            SearchKindArg: this.search,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeSearchKinds,
        }));
    }
}
