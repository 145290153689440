import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import moment from 'moment';
import hash from 'object-hash';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Constants } from '../../../constants';
import { getPlayer, IPlayer } from '../../../Player/Player';
import * as Proto from '../../../Protos/protos';
import { IConnectedReduxProps } from '../../../Store';
import { IArchiveState } from '../../../Store/Archives';
import { create as createArchive } from '../../../Store/Archives/Actions';
import { IContactsState } from '../../../Store/Contacts';
import {
    create as createContact,
    get as getContacts,
    setErrors as setContactsError,
} from '../../../Store/Contacts/Actions';
import { IFTPAccountsState } from '../../../Store/FTPAccounts';
import { get as getFtpAccount } from '../../../Store/FTPAccounts/Actions';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import {
    IPlayerState,
    PlayerStatus,
    sendStats,
    setEndSelection,
    setPlayer,
    setPosition,
    setSnapshot,
    setStartSelection,
    setStatus,
} from '../../../Store/Player';
import { get, IReviewsState } from '../../../Store/Reviews';
import { get as getSFtpAccount, ISFTPAccountsState } from '../../../Store/SFTPAccounts';
import { ITimelineState } from '../../../Store/Timeline';
import { IUserState } from '../../../Store/User';
import { Logger } from '../../../Utils/Logger';
import { TypedStorage } from '../../../Utils/TypedStorage';
import { isNull, isNullOrUndefined } from '../../../Utils/Various';

import styles from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unused-vars
const Jed = require('jed');

import Loading0 from '../../../Images/loadingY0.png';
import Loading1 from '../../../Images/loadingY1.png';
import Loading2 from '../../../Images/loadingY2.png';
import Loading3 from '../../../Images/loadingY3.png';
import Loading4 from '../../../Images/loadingY4.png';

export interface IUIProps {
    expandedPanels: number[];
    seeArchive: boolean;
    volume: number;
}

interface IPropsFromState {
    archives: IArchiveState;
    contacts: IContactsState;
    ftpAccounts: IFTPAccountsState;
    i18n: I18N;
    localeInfos: ILocaleInfos;
    player: IPlayerState;
    reviews: IReviewsState;
    sftpAccounts: ISFTPAccountsState;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    createArchive: typeof createArchive;
    createContact: typeof createContact;
    getContacts: typeof getContacts;
    getFtpAccount: typeof getFtpAccount;
    getSFtpAccount: typeof getSFtpAccount;
    sendStats: typeof sendStats;
    setContactsError: typeof setContactsError;
    setEndSelection: typeof setEndSelection;
    setPlayer: typeof setPlayer;
    setPosition: typeof setPosition;
    setSnapshot: typeof setSnapshot;
    setStartSelection: typeof setStartSelection;
    setStatus: typeof setStatus;
    getReviews: typeof get;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export default class TimelinePlayerComponent extends React.Component<AllProps> {
    // TODO: persist
    public state: IUIProps = {
        expandedPanels: [],
        seeArchive: false,
        volume: TypedStorage.get('volume', 0.5),
    };

    private loadingStep = 0;
    private keyboardHandler: (ev: KeyboardEvent) => void;
    private isSafari = false;
    private lastDataHash = '';
    private lastPlaybackSpeed = 1;
    private player: IPlayer | null = null;
    private timerMicroplay = -1;
    private timeText: HTMLParagraphElement | null = null;
    private volumeBar: HTMLDivElement | null = null;
    private volumeBarActive: HTMLDivElement | null = null;
    private volumeContainer: HTMLDivElement | null = null;
    private volumeCursor: HTMLDivElement | null = null;
    private volumeMoving = false;

    public constructor(props: AllProps) {
        super(props);
        this.keyboardHandler = this.handleKeyboardEvent.bind(this);

        const elem = document.createElement('video');

        if (elem.canPlayType('application/vnd.apple.mpegurl')) {
            this.isSafari = true;
        }
    }

    public render(): React.ReactNode {
        const newHash = hash({
            criterias: {
                ...this.props.timeline.criterias,
                calendar: null,
            },
        });
        if (this.lastDataHash !== newHash) {
            this.lastDataHash = newHash;
            this.refreshPlayer();
        }
        if (this.props.timeline.presentation.playbackSpeed !== this.lastPlaybackSpeed) {
            this.lastPlaybackSpeed = this.props.timeline.presentation.playbackSpeed;
            if (!isNullOrUndefined(this.player)) {
                this.player.setPlaybackSpeed(this.lastPlaybackSpeed);
            }
        }
        const canAnnotate =
            this.props.user.user.functionalities.indexOf(
                Proto.mediaarchiver.UserFunctionalities.FUNCTIONALITY_ANNOTATIONS,
            ) !== -1;
        const canExtract =
            this.props.user.user.functionalities.indexOf(
                Proto.mediaarchiver.UserFunctionalities.FUNCTIONALITY_EXTRACTIONS,
            ) !== -1;

        const showFuncButtons = canAnnotate && canExtract;
        if (
            this.props.user.user.share &&
            !isNullOrUndefined(this.props.user.user.shareParams) &&
            !this.state.seeArchive
        ) {
            return (
                <div className={this.props.classes.root}>
                    <MD.Paper className={this.props.classes.sharePopup} elevation={3}>
                        <MD.Typography variant='h6'>
                            {this.props.i18n.sprintf(
                                this.props.user.user.shareParams.type === 'annotation'
                                    ? '%1$s shared an annotation with you'
                                    : '%1$s shared an archive with you',
                                this.props.user.user.shareParams.sender,
                            )}
                        </MD.Typography>
                        {this.props.user.user.shareParams.type === 'archive' ? (
                            <MD.Typography>
                                {this.props.i18n._('Archive title')} : {this.props.user.user.shareParams.archiveTitle}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        <MD.Typography>
                            {this.props.i18n._('Media')} : {this.props.user.user.shareParams.mediaName}
                        </MD.Typography>
                        <MD.Typography>
                            {this.props.i18n._('Date')} :{' '}
                            {moment(this.props.user.user.shareParams.start).format(
                                this.props.localeInfos.formatLongDateTimeWithMilliSeconds,
                            )}
                        </MD.Typography>
                        <MD.Typography>
                            {this.props.i18n._('Duration')} :{' '}
                            {this.renderDuration(
                                this.props.user.user.shareParams.end.getTime() -
                                    this.props.user.user.shareParams.start.getTime(),
                            )}
                        </MD.Typography>
                        <div className={this.props.classes.sharePopup}>
                            <MD.Button
                                onClick={() => {
                                    this.setState({ seeArchive: true });
                                    window.setTimeout(() => {
                                        this.refreshPlayer();
                                    }, 1000);
                                }}
                                variant='contained'
                            >
                                {this.props.i18n._('View archive')}
                            </MD.Button>
                        </div>
                    </MD.Paper>
                </div>
            );
        }
        return (
            <div className={this.props.classes.root}>
                <MD.Typography className={this.props.classes.titleText} variant='subtitle1' gutterBottom={true}>
                    <span>{this.props.timeline.criterias.mediaName}</span>
                    {this.props.i18n
                        ._('from [date_start] to [date_end]')
                        .replace(
                            '[date_start]',
                            moment(this.props.timeline.criterias.start).format(
                                this.props.localeInfos.formatShortDateTime,
                            ),
                        )
                        .replace(
                            '[date_end]',
                            moment(this.props.timeline.criterias.end).format(
                                this.props.localeInfos.formatShortDateTime,
                            ),
                        )}
                </MD.Typography>

                <div className={this.props.classes.playerContainer}>
                    <div className={this.props.classes.player} id='player' />
                    {!isNull(this.props.player.startSelection) && !isNull(this.props.player.endSelection) ? (
                        <div className={this.props.classes.selectionInfos}>
                            <div className={this.props.classes.selectionSnapshotContainer}>
                                <figure
                                    style={{
                                        backgroundImage: `url(${this.props.player.snapshot})`,
                                    }}
                                />
                            </div>
                            <div className={this.props.classes.selectionInfosDetailsDefinitions}>
                                <MD.Typography component='p'>{this.props.i18n._('Start')}</MD.Typography>
                                <MD.Typography component='p'>{this.props.i18n._('End')}</MD.Typography>
                                <MD.Typography component='p'>{this.props.i18n._('Length')}</MD.Typography>
                            </div>
                            <div className={this.props.classes.selectionInfosDetailsValules}>
                                <MD.Typography component='p'>
                                    {moment(this.props.player.startSelection).format('HH:mm:ss.SS')}
                                </MD.Typography>
                                <MD.Typography component='p'>
                                    {moment(this.props.player.endSelection).format('HH:mm:ss.SS')}
                                </MD.Typography>
                                <MD.Typography component='p'>
                                    {moment
                                        .utc(
                                            moment(this.props.player.endSelection).diff(
                                                moment(this.props.player.startSelection),
                                            ),
                                        )
                                        .format('HH:mm:ss.SS')}
                                </MD.Typography>
                            </div>
                            <div className={this.props.classes.selectionInfosDetailsIcons}>
                                <MD.Button
                                    component='a'
                                    disabled={
                                        this.props.player.startSelection.getTime() <=
                                        this.props.timeline.criterias.start.getTime()
                                    }
                                    title={this.props.i18n._('Previous image')}
                                    onClick={this.frameStart.bind(this, -1)}
                                    variant='text'
                                >
                                    <FontAwesomeIcon icon={FA.faStepBackward} />
                                </MD.Button>
                                <MD.Button
                                    component='a'
                                    disabled={
                                        this.props.player.startSelection.getTime() >=
                                        this.props.player.endSelection.getTime() - 1000
                                    }
                                    title={this.props.i18n._('Next image')}
                                    onClick={this.frameStart.bind(this, 1)}
                                    variant='text'
                                >
                                    <FontAwesomeIcon icon={FA.faStepForward} />
                                </MD.Button>
                                <br />
                                <MD.Button
                                    component='a'
                                    disabled={
                                        this.props.player.startSelection.getTime() >=
                                        this.props.player.endSelection.getTime() - 1000
                                    }
                                    title={this.props.i18n._('Previous image')}
                                    onClick={this.frameEnd.bind(this, -1)}
                                    variant='text'
                                >
                                    <FontAwesomeIcon icon={FA.faStepBackward} />
                                </MD.Button>
                                <MD.Button
                                    component='a'
                                    disabled={
                                        this.props.player.endSelection.getTime() >=
                                        this.props.timeline.criterias.end.getTime()
                                    }
                                    title={this.props.i18n._('Next image')}
                                    onClick={this.frameEnd.bind(this, 1)}
                                    variant='text'
                                >
                                    <FontAwesomeIcon icon={FA.faStepForward} />
                                </MD.Button>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div
                        className={this.props.classes.volume}
                        ref={(c) => {
                            this.volumeContainer = c;
                        }}
                    >
                        <div
                            className={this.props.classes.volumeBarClickZone}
                            onMouseDown={this.onVolumeMouseDown.bind(this)}
                            onMouseLeave={this.onVolumeMouseUp.bind(this)}
                            onMouseMove={this.onVolumeMove.bind(this)}
                            onMouseUp={this.onVolumeMouseUp.bind(this)}
                        />
                        <div
                            className={this.props.classes.volumeBar}
                            ref={(c) => {
                                this.volumeBar = c;
                            }}
                        />
                        <div
                            className={this.props.classes.volumeBarActive}
                            ref={(c) => {
                                this.volumeBarActive = c;
                            }}
                        />
                        <div
                            className={this.props.classes.volumeCursor}
                            ref={(c) => {
                                this.volumeCursor = c;
                            }}
                        />
                    </div>
                </div>
                <div className={this.props.classes.controls}>
                    <div className={this.props.classes.controlsLeft}>
                        {(() => {
                            switch (this.props.player.status) {
                                case PlayerStatus.END:
                                    return (
                                        <MD.Button
                                            component='a'
                                            onKeyDown={(ev: React.KeyboardEvent<HTMLAnchorElement>) => {
                                                ev.preventDefault();
                                            }}
                                            onKeyUp={(ev: React.KeyboardEvent<HTMLAnchorElement>) => {
                                                ev.preventDefault();
                                            }}
                                            onClick={() => {
                                                if (isNullOrUndefined(this.props.player.player)) {
                                                    return;
                                                }
                                                this.props.player.player.replay();
                                            }}
                                            title={this.props.i18n._('Replay content')}
                                            variant='text'
                                        >
                                            <FontAwesomeIcon icon={FA.faRedo} />
                                        </MD.Button>
                                    );
                                case PlayerStatus.LOADING:
                                    return (
                                        <MD.Button
                                            component='a'
                                            onKeyDown={(ev: React.KeyboardEvent<HTMLAnchorElement>) => {
                                                ev.preventDefault();
                                            }}
                                            onKeyUp={(ev: React.KeyboardEvent<HTMLAnchorElement>) => {
                                                ev.preventDefault();
                                            }}
                                            title={this.props.i18n._('Media is loading')}
                                            variant='text'
                                        >
                                            <img
                                                alt='Loading'
                                                onLoad={this.animateLoading.bind(this)}
                                                src={Loading0}
                                                style={{
                                                    width: 20,
                                                }}
                                            />
                                        </MD.Button>
                                    );
                                case PlayerStatus.PAUSED:
                                    return (
                                        <MD.Button
                                            component='a'
                                            onKeyDown={(ev: React.KeyboardEvent<HTMLAnchorElement>) => {
                                                ev.preventDefault();
                                            }}
                                            onKeyUp={(ev: React.KeyboardEvent<HTMLAnchorElement>) => {
                                                ev.preventDefault();
                                            }}
                                            onClick={() => {
                                                if (isNullOrUndefined(this.props.player.player)) {
                                                    return;
                                                }
                                                this.props.player.player.resume();
                                            }}
                                            title={this.props.i18n._('Resume playing')}
                                            variant='text'
                                        >
                                            <FontAwesomeIcon icon={FA.faPlay} />
                                        </MD.Button>
                                    );
                                case PlayerStatus.PLAYING:
                                    return (
                                        <MD.Button
                                            component='a'
                                            onKeyDown={(ev: React.KeyboardEvent<HTMLAnchorElement>) => {
                                                ev.preventDefault();
                                            }}
                                            onKeyUp={(ev: React.KeyboardEvent<HTMLAnchorElement>) => {
                                                ev.preventDefault();
                                            }}
                                            onClick={
                                                isNullOrUndefined(this.props.player.player)
                                                    ? () => {
                                                          /* */
                                                      }
                                                    : this.props.player.player.pause.bind(this.props.player.player)
                                            }
                                            title={this.props.i18n._('Pause playing')}
                                            variant='text'
                                        >
                                            <FontAwesomeIcon icon={FA.faPause} />
                                        </MD.Button>
                                    );
                                case PlayerStatus.NULL:
                                default:
                                    return (
                                        <MD.Button
                                            component='a'
                                            onKeyDown={(ev: React.KeyboardEvent<HTMLAnchorElement>) => {
                                                ev.preventDefault();
                                            }}
                                            onKeyUp={(ev: React.KeyboardEvent<HTMLAnchorElement>) => {
                                                ev.preventDefault();
                                            }}
                                            title={this.props.i18n._('Player is not ready yet')}
                                            variant='text'
                                        >
                                            <FontAwesomeIcon icon={FA.faTimesCircle} />
                                        </MD.Button>
                                    );
                            }
                        })()}
                        {showFuncButtons ? (
                            <MD.Button
                                component='a'
                                disabled={this.props.player.status === PlayerStatus.NULL}
                                onClick={this.startSelection.bind(this)}
                                title={this.props.i18n._('Start selection')}
                                variant='text'
                            >
                                <FontAwesomeIcon icon={FA.faArrowFromLeft} />
                            </MD.Button>
                        ) : (
                            ''
                        )}
                        {showFuncButtons ? (
                            <MD.Button
                                component='a'
                                disabled={
                                    this.props.player.status === PlayerStatus.NULL ||
                                    this.props.player.startSelection === null ||
                                    isNullOrUndefined(this.player)
                                }
                                onClick={this.endSelection.bind(this)}
                                title={this.props.i18n._('End selection')}
                                variant='text'
                            >
                                <FontAwesomeIcon icon={FA.faArrowFromRight} />
                            </MD.Button>
                        ) : (
                            ''
                        )}
                        {showFuncButtons ? (
                            <MD.Button
                                component='a'
                                disabled={
                                    this.props.player.status === PlayerStatus.NULL ||
                                    this.props.player.startSelection === null ||
                                    this.props.player.endSelection === null
                                }
                                onClick={this.replaySelection.bind(this)}
                                title={this.props.i18n._('Replay selection')}
                                variant='text'
                            >
                                <FontAwesomeIcon icon={FA.faPlayCircle} />
                            </MD.Button>
                        ) : (
                            ''
                        )}
                        &nbsp;&nbsp;&nbsp;
                        {showFuncButtons
                            ? this.wrapForSafariSnapshot(
                                  <MD.Button
                                      component='a'
                                      disabled={
                                          this.props.player.status === PlayerStatus.NULL ||
                                          this.props.player.startSelection === null
                                      }
                                      onClick={this.takeSnapshot.bind(this)}
                                      title={this.props.i18n._('Select current image as selection snapshot')}
                                      variant='text'
                                  >
                                      <FontAwesomeIcon icon={FA.faCameraRetro} />
                                  </MD.Button>,
                              )
                            : ''}
                        &nbsp;&nbsp;&nbsp;
                        {showFuncButtons ? (
                            <MD.Button
                                component='a'
                                disabled={
                                    this.props.player.status === PlayerStatus.NULL ||
                                    this.props.player.startSelection === null ||
                                    this.props.player.endSelection === null
                                }
                                onClick={this.deleteSelection.bind(this)}
                                title={this.props.i18n._('Delete selection')}
                                variant='text'
                            >
                                <FontAwesomeIcon icon={FA.faTrash} />
                            </MD.Button>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={this.props.classes.controlsRight}>
                        <p
                            ref={(c) => {
                                this.timeText = c;
                            }}
                        >
                            --:--:--.--
                        </p>
                        <MD.Button
                            component='a'
                            onClick={this.toggleVolume.bind(this)}
                            onContextMenu={this.toggleMute.bind(this)}
                            variant='text'
                        >
                            {((volume) => {
                                if (volume < 0.05) {
                                    return <FontAwesomeIcon icon={FA.faVolumeSlash} />;
                                } else if (volume < 0.2) {
                                    return <FontAwesomeIcon icon={FA.faVolumeOff} />;
                                } else if (volume < 0.5) {
                                    return <FontAwesomeIcon icon={FA.faVolumeDown} />;
                                } else if (volume < 0.7) {
                                    return <FontAwesomeIcon icon={FA.faVolume} />;
                                } else {
                                    return <FontAwesomeIcon icon={FA.faVolumeUp} />;
                                }
                            })(this.state.volume)}
                        </MD.Button>
                        <MD.Button
                            component='a'
                            disabled={
                                [PlayerStatus.PLAYING, PlayerStatus.PAUSED].indexOf(this.props.player.status) === -1
                            }
                            onClick={this.fullScreen.bind(this)}
                            variant='text'
                        >
                            <FontAwesomeIcon icon={FA.faExpand} />
                        </MD.Button>
                    </div>
                </div>
            </div>
        );
    }

    public componentWillUnmount(): void {
        document.removeEventListener('keydown', this.keyboardHandler);
    }

    public componentDidMount(): void {
        this.refreshPlayer();
        document.addEventListener('keydown', this.keyboardHandler);
    }

    private animateLoading(ev: React.SyntheticEvent<HTMLImageElement, Event>): void {
        let imgSrc = Loading0;
        const img = ev.target as HTMLImageElement;

        switch (this.loadingStep) {
            case 1:
                imgSrc = Loading2;
                this.loadingStep = 2;
                break;
            case 2:
                imgSrc = Loading3;
                this.loadingStep = 3;
                break;
            case 3:
                imgSrc = Loading4;
                this.loadingStep = 4;
                break;
            case 4:
                imgSrc = Loading0;
                this.loadingStep = 0;
                break;
            case 0:
            default:
                imgSrc = Loading1;
                this.loadingStep = 1;
        }
        window.setTimeout(() => {
            img.src = imgSrc;
        }, 200);
    }

    private refreshPlayer() {
        if (this.player) {
            this.player.destroy();
        }
        const container = document.getElementById('player') || document.createElement('div');
        const player = getPlayer(
            container as HTMLElement,
            this.props.timeline.criterias.media,
            this.props.timeline.criterias.mediaType,
            this.props.timeline.criterias.start,
            this.props.timeline.criterias.end,
            TypedStorage.get('expiringToken', ''),
            this.props.timeline.criterias.timezone,
        );

        Logger.debug({ type: this.props.timeline.criterias.mediaType }, 'New Player');
        this.props.setPlayer(player);

        player.onNewStats(
            (manifests: Proto.mediaarchiver.IPlayerStatsManifest, chunks: Proto.mediaarchiver.IPlayerStatsChunks) => {
                this.props.sendStats({ chunks, manifests });
            },
        );

        this.player = player;
        player.onStatus((s: PlayerStatus) => {
            this.props.setStatus(s);
        });
        player.onTimeUpdate((t: Date) => {
            this.props.setPosition(t);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.props.player.positionUpdateEventElem.addEventListener<any>('timeUpdate', (ev: CustomEvent) => {
            if (this.timeText) {
                this.timeText.innerText = moment(ev.detail).format('HH:mm:ss.SS');
            }
        });

        this.props.setPosition(new Date(0));
        this.player.start(this.props.timeline.criterias.playerStart, this.props.timeline.criterias.end, false);
    }

    private toggleVolume() {
        if (isNullOrUndefined(this.volumeContainer)) {
            return;
        }
        if (this.volumeContainer.style.display === 'block') {
            this.volumeContainer.style.opacity = '0';
            window.setTimeout(() => {
                (this.volumeContainer as HTMLDivElement).style.display = 'none';
            }, 500);
            return;
        }
        this.volumeContainer.style.display = 'block';
        this.volumeContainer.style.opacity = '1';
        if (!isNull(this.player)) {
            this.setVolume(this.player.getVolume());
        }
    }

    private toggleMute(ev: React.MouseEvent) {
        if (isNull(this.player)) {
            return;
        }
        ev.preventDefault();
        ev.stopPropagation();
        if (this.player.getVolume() === 0) {
            this.setVolume(TypedStorage.get('volumeBeforeMute', 0.5));
        } else {
            this.setVolume(0);
        }
    }

    private setVolume(v: number) {
        const volume = v < 0 ? 0 : v > 1 ? 1 : v;

        if (volume !== 0) {
            TypedStorage.set('volumeBeforeMute', volume);
        }
        if (
            isNullOrUndefined(this.volumeBar) ||
            isNullOrUndefined(this.volumeBarActive) ||
            isNullOrUndefined(this.volumeCursor)
        ) {
            return;
        }

        const box = this.volumeBar.getBoundingClientRect();

        this.volumeBarActive.style.width = `${box.width * volume}px`;
        this.volumeCursor.style.left = `${box.width * volume + 5}px`;
        if (!isNullOrUndefined(this.props.player.player)) {
            this.props.player.player.setVolume(volume);
        }
        this.setState({ volume });
    }

    private onVolumeMove(ev: React.MouseEvent) {
        if (!this.volumeMoving || isNullOrUndefined(this.volumeBar)) {
            return;
        }
        const box = this.volumeBar.getBoundingClientRect();

        this.setVolume((ev.clientX - box.left) / box.width);
    }

    private onVolumeMouseDown(ev: React.MouseEvent) {
        if (isNullOrUndefined(this.volumeBar)) {
            return;
        }

        const box = this.volumeBar.getBoundingClientRect();

        this.setVolume((ev.clientX - box.left) / box.width);
        this.volumeMoving = true;
    }

    private onVolumeMouseUp() {
        this.volumeMoving = false;
    }

    private startSelection() {
        if (!isNullOrUndefined(this.player)) {
            const currentTime = this.player.getCurrentTime();

            if (
                isNull(currentTime) ||
                (!isNull(this.props.player.endSelection) &&
                    this.props.player.endSelection.getTime() <= currentTime.getTime())
            ) {
                this.props.setEndSelection(null);
                this.player.cancelPauseAt();
            }
            if (
                this.props.user.user.options.indexOf(Proto.mediaarchiver.UserOptions.USER_OPTION_PAUSE_AFTER_IN_OUT) !==
                -1
            ) {
                this.player.pause();
            }
            this.props.setStartSelection(currentTime);
            if (this.props.timeline.criterias.mediaType === 2 && !isNull(currentTime)) {
                this.takeSnapshot();
            }
        }
    }

    private endSelection() {
        if (!isNullOrUndefined(this.player)) {
            const start = this.props.player.startSelection;
            let time = this.player.getCurrentTime();

            if (isNull(time) || isNull(start)) {
                return;
            }
            if (time.getTime() - start.getTime() < 1000) {
                time = new Date(start.getTime() + 1000);
            }
            this.props.setEndSelection(time);
            this.player.pause();
        }
    }

    private fullScreen() {
        if (!isNull(this.player)) {
            this.player.askFullscreen();
        }
    }

    private takeSnapshot() {
        if (!isNullOrUndefined(this.player)) {
            this.player
                .getSnapshot()
                .then((data: string) => this.props.setSnapshot(data))
                .catch((err) => Logger.warn(err as Error, 'Could not generate snapshot'));
            return;
        }
        Logger.warn({}, 'Could not generate snapshot, player is not ready');
    }

    private deleteSelection() {
        this.props.setStartSelection(null);
        this.props.setEndSelection(null);
        if (!isNullOrUndefined(this.player)) {
            this.player.cancelPauseAt();
        }
    }

    private replaySelection() {
        if (isNull(this.player) || isNull(this.props.player.startSelection) || isNull(this.props.player.endSelection)) {
            return;
        }
        this.player.start(this.props.player.startSelection, this.props.player.endSelection, true);
    }

    private frameStart(delta: number) {
        delta = delta === -1 ? -1 : 1;
        if (isNull(this.player) || isNull(this.props.player.startSelection)) {
            return;
        }
        let newTime = new Date(
            this.props.player.startSelection.getTime() + delta * ((1 / Constants.chunksFramerate) * 1000),
        );
        if (newTime.getTime() <= this.props.timeline.criterias.start.getTime()) {
            newTime = this.props.timeline.criterias.start;
        }
        this.props.setStartSelection(newTime);
        this.player.start(this.props.player.startSelection, this.props.player.startSelection);
        window.setTimeout(() => {
            this.props.setPosition(newTime);
        }, 500);
    }

    private frameEnd(delta: number) {
        delta = delta === -1 ? -1 : 1;
        if (isNull(this.player) || isNull(this.props.player.endSelection)) {
            return;
        }
        let newTime = new Date(
            this.props.player.endSelection.getTime() + delta * ((1 / Constants.chunksFramerate) * 1000),
        );
        if (newTime.getTime() >= this.props.timeline.criterias.end.getTime()) {
            newTime = this.props.timeline.criterias.end;
        }
        this.props.setEndSelection(newTime);
        this.player.start(this.props.player.endSelection, this.props.player.endSelection);
        window.setTimeout(() => {
            this.props.setPosition(newTime);
        }, 500);
    }
    private onLaunchVideo() {
        if (!isNullOrUndefined(this.player)) {
            this.player.resume();
        }
    }

    private goToAnnotationTypes() {
        this.props.history.push('/account/annotationTypes');
    }

    private handleKeyboardEvent(ev: KeyboardEvent) {
        const steps = this.props.player.steps;

        if (
            this.player === null ||
            document.activeElement === null ||
            document.activeElement.tagName === 'INPUT' ||
            document.activeElement.tagName === 'TEXTAREA'
        ) {
            return;
        }
        let stop = true;

        switch (ev.code) {
            // Spacebar
            case 'Space':
                ev.preventDefault();
                ev.stopPropagation();
                switch (this.props.player.status) {
                    case PlayerStatus.PAUSED:
                        this.player.resume();
                        break;
                    case PlayerStatus.PLAYING:
                        this.player.pause();
                        break;
                    case PlayerStatus.LOADING:
                    case PlayerStatus.NULL:
                    default:
                }
                break;
            // Left
            case 'ArrowLeft':
                switch (this.props.player.status) {
                    case PlayerStatus.PAUSED:
                        // this.triggerMicroPlay();
                        this.player.seek(-(ev.shiftKey ? steps.fastBackward / 1000 : steps.backward / 1000));
                        break;
                    case PlayerStatus.PLAYING:
                        this.player.seek(-(ev.shiftKey ? steps.fastBackward / 1000 : steps.backward / 1000));
                        break;
                    case PlayerStatus.LOADING:
                    case PlayerStatus.NULL:
                    default:
                        stop = false;
                        break;
                }
                break;
            // Right
            case 'ArrowRight':
                switch (this.props.player.status) {
                    case PlayerStatus.PAUSED:
                        // this.triggerMicroPlay();
                        this.player.seek(ev.shiftKey ? steps.fastForward / 1000 : steps.forward / 1000);
                        break;
                    case PlayerStatus.PLAYING:
                        this.player.seek(ev.shiftKey ? steps.fastForward / 1000 : steps.forward / 1000);
                        break;
                    case PlayerStatus.LOADING:
                    case PlayerStatus.NULL:
                    default:
                        stop = false;
                        break;
                }
                break;
            case 'KeyD':
                this.startSelection();
                break;
            case 'KeyF':
                this.endSelection();
                break;
            case 'KeyR':
                if (!isNullOrUndefined(this.props.player.player)) {
                    this.props.player.player.replay();
                }
                break;
            case 'Home':
                if (!isNullOrUndefined(this.props.player.startSelection)) {
                    if (isNull(this.player) || isNull(this.props.player.startSelection)) {
                        return;
                    }
                    this.player.start(
                        this.props.player.startSelection,
                        this.props.player.endSelection || undefined,
                        false,
                    );
                }
                break;
            case 'End':
                if (!isNullOrUndefined(this.props.player.endSelection)) {
                    if (isNull(this.player) || isNull(this.props.player.endSelection)) {
                        return;
                    }
                    this.player.start(this.props.player.endSelection, undefined, false);
                }
                break;
            default:
                stop = false;
        }
        if (stop) {
            ev.stopPropagation();
            ev.preventDefault();
        }
    }

    private wrapForSafariSnapshot(elem: React.ReactElement): React.ReactNode {
        if (this.isSafari) {
            return (
                <MD.Tooltip
                    title={this.props.i18n._(
                        'Grabbing video data from a video in Safari is not possible, snapshots may not work on some medias and cannot be frame-accurate, please condiser using another browser if you want a better experience.',
                    )}
                >
                    {elem}
                </MD.Tooltip>
            );
        }
        return elem;
    }

    private renderDuration(ms: number): string {
        const duration = moment.duration(ms);

        let v =
            ('0' + duration.hours().toString()).slice(-2) +
            ':' +
            ('0' + duration.minutes().toString()).slice(-2) +
            ':' +
            ('0' + duration.seconds().toString()).slice(-2);

        if (duration.milliseconds() > 0) {
            v += '.' + ('0' + duration.milliseconds().toString()).slice(-2);
        }
        return v;
    }
}
