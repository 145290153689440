import moment from 'moment';
import { Store } from 'redux';
import { effects } from 'redux-saga';

import { IApplicationState } from '..';
import { Logger } from '../../Utils/Logger';
import { isNull, isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../Utils/Various';
import { store } from '../store';
import { IEPGProgramState, IEPGState } from '../Timeline/Types';
import { addFile, loadFile, setCurrentFile, setError } from './Actions';
import { XMLActionTypes } from './Types';

async function readFileContent(f: File) {
    return new Promise<string>((resolve: (v: string) => void, reject: (err: Error) => void) => {
        const reader = new FileReader();

        reader.onload = (e: ProgressEvent) => {
            if (isNull(e.target)) {
                reject(new Error('Error while reading file'));
                return;
            }
            resolve((e.target as FileReader).result as string);
        };
        reader.onerror = () => {
            reject(reader.error as Error);
        };
        reader.readAsText(f);
    });
}

function parseFile(content: string): IEPGState[] {
    content = content.replace(/&/g, '&amp;');
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'application/xml');

    if (doc.children.length < 1 || doc.children[0].tagName.toLocaleUpperCase() !== 'SEGMENTCOLLECTION') {
        Logger.warn({ doc }, 'Invalid XML document');
        throw new Error('Invalid XML document');
    }

    if (doc.children[0].children.length < 1 || doc.children[0].children[0].tagName.toLocaleUpperCase() !== 'SEGMENTS') {
        Logger.warn({ doc }, 'Empty segments list');
    }

    const epgs = Array.from(doc.children[0].children[0].children)
        .map((e: Element): IEPGProgramState | null => {
            const startTXT = e.getAttribute('startDate');
            const endTXT = e.getAttribute('endDate');
            const label = e.getAttribute('label');

            if (
                e.tagName.toLocaleUpperCase() !== 'SEGMENT' ||
                isNull(startTXT) ||
                isNull(endTXT) ||
                isNullOrUndefinedOrEmptyString(label)
            ) {
                return null;
            }
            const start = moment(startTXT, 'YYYY-MM-DDTHH:mm:ssZZ');
            const end = moment(endTXT, 'YYYY-MM-DDTHH:mm:ssZZ');
            return {
                category: '',
                categoryText: '',
                duration: end.toDate().getTime() - start.toDate().getTime(),
                metas: {},
                realDuration: end.toDate().getTime() - start.toDate().getTime(),
                realStart: start.toDate(),
                start: start.toDate(),
                subtitle: '',
                synopsis: '',
                title: label,
                vignette: '',
            };
        })
        .filter((i: IEPGProgramState | null) => !isNull(i)) as IEPGProgramState[];

    const res: IEPGState[] = [[], []];

    epgs.forEach((i) => {
        if (
            !isNullOrUndefined(res[0]) &&
            Object.keys(res[0]).some((key: string) => {
                const m = parseInt(key, 10);
                return (
                    !isNaN(m) &&
                    i.start.getTime() >= res[0][m].start.getTime() &&
                    i.start.getTime() < res[0][m].start.getTime() + res[0][m].duration
                );
            })
        ) {
            res[1][i.start.getTime()] = i;
        } else {
            res[0][i.start.getTime()] = i;
        }
    });
    return res;
}

function* handleLoadFile(action: ReturnType<typeof loadFile>) {
    const state = (store as Store<IApplicationState>).getState();

    try {
        if (!state.timeline.ready) {
            throw new Error(state.i18n.i18n._('Timeline is not ready'));
        }
        if (action.payload.size === 0) {
            throw new Error(state.i18n.i18n._('File is empty'));
        }
        if (action.payload.size > 5242880) {
            throw new Error(state.i18n.i18n._('File must not be larger than 5Mb'));
        }
    } catch (err) {
        yield effects.put(setError((err as Error).message));
        return;
    }

    try {
        const data: string = yield readFileContent(action.payload);
        const epgs = parseFile(data);
        const min = state.timeline.criterias.start.getTime();
        const max = state.timeline.criterias.end.getTime();

        if (
            !epgs.some((epg) =>
                Object.keys(epg).some((minuteStr) => {
                    const minute = parseInt(minuteStr, 10);

                    if (!isNaN(minute) && minute >= min && minute <= max) {
                        return true;
                    }
                    return false;
                }),
            )
        ) {
            throw new Error(state.i18n.i18n._('No annotation found in file is in the current timeline'));
        }
        yield effects.put(addFile(action.payload.name, epgs));
        yield effects.put(setCurrentFile(action.payload.name));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to load file');
        yield effects.put(
            setError(state.i18n.i18n.sprintf(state.i18n.i18n._('Failed loading file: %1$s'), (err as Error).message)),
        );
    }
}

function* watchLoadRequests() {
    yield effects.takeEvery(XMLActionTypes.LOAD_FILE, handleLoadFile);
}

export function* XMLSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchLoadRequests)]);
}
