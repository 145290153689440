import { Action, AnyAction, combineReducers, Dispatch } from 'redux';
import { all, AllEffect, fork } from 'redux-saga/effects';

import { AnalyticsReducer, AnalyticsSaga, IAnalyticsState } from './Analytics';
import { AnnotationsReducer, AnnotationsSaga, IAnnotationsState } from './Annotations';
import { AnnotationTypesReducer, AnnotationTypesSaga, IAnnotationTypesState } from './AnnotationTypes';
import { ArchivesReducer, ArchivesSaga, IArchiveState } from './Archives';
import { ContactsReducer, ContactsSaga, IContactsState } from './Contacts';
import { FTPAccountsReducer, FTPAccountsSaga, IFTPAccountsState } from './FTPAccounts';
import { I18NReducer, I18NSaga, I18NState } from './I18n';
import { IncidentReducer, IncidentSaga, IIncidentState } from './Incident';
import { ILayoutState, LayoutReducer, LayoutSaga } from './Layout';
import { IMailState, MailsReducer, MailsSaga } from './Mails';
import { IMediasState, MediasReducer, MediasSaga } from './Medias';
import { IPlayerState, PlayerReducer, PlayerSaga } from './Player';
import { IReviewsState, ReviewsReducer, ReviewsSaga } from './Reviews';
import { ISearchState, SearchReducer, SearchSaga } from './Search';
import { ISearchSTTState, SearchSTTReducer, SearchSTTSaga } from './SearchSTT';
import { ISFTPAccountsState, SFTPAccountsReducer, SFTPAccountsSaga } from './SFTPAccounts';
import { ITalkState, TalkReducer, TalkSaga } from './Talk';
import { ITimelineState, TimelineReducer, TimelineSaga } from './Timeline';
import { ITimelinePopoversState, TimelinePopoversReducer } from './TimelinePopovers';
import { IUserState, UserReducer, UserSaga } from './User';
import { IXMLState, XMLReducer, XMLSaga } from './XML';

export interface IApplicationState {
    analytics: IAnalyticsState;
    annotationTypes: IAnnotationTypesState;
    annotations: IAnnotationsState;
    archives: IArchiveState;
    contacts: IContactsState;
    ftpAccounts: IFTPAccountsState;
    i18n: I18NState;
    incident: IIncidentState;
    layout: ILayoutState;
    mails: IMailState;
    medias: IMediasState;
    player: IPlayerState;
    reviews: IReviewsState;
    search: ISearchState;
    searchSTT: ISearchSTTState;
    sftpAccounts: ISFTPAccountsState;
    talk: ITalkState;
    timeline: ITimelineState;
    timelinePopovers: ITimelinePopoversState;
    user: IUserState;
    xml: IXMLState;
}

export interface IConnectedReduxProps<A extends Action = AnyAction> {
    dispatch: Dispatch<A>;
}

export const RootReducer = combineReducers<IApplicationState>({
    analytics: AnalyticsReducer,
    annotationTypes: AnnotationTypesReducer,
    annotations: AnnotationsReducer,
    archives: ArchivesReducer,
    contacts: ContactsReducer,
    ftpAccounts: FTPAccountsReducer,
    i18n: I18NReducer,
    incident: IncidentReducer,
    layout: LayoutReducer,
    mails: MailsReducer,
    medias: MediasReducer,
    player: PlayerReducer,
    reviews: ReviewsReducer,
    search: SearchReducer,
    searchSTT: SearchSTTReducer,
    sftpAccounts: SFTPAccountsReducer,
    talk: TalkReducer,
    timeline: TimelineReducer,
    timelinePopovers: TimelinePopoversReducer,
    user: UserReducer,
    xml: XMLReducer,
});

export function* RootSaga(): Generator<AllEffect, void, unknown> {
    yield all([
        fork(AnalyticsSaga),
        fork(AnnotationTypesSaga),
        fork(AnnotationsSaga),
        fork(ArchivesSaga),
        fork(ContactsSaga),
        fork(FTPAccountsSaga),
        fork(I18NSaga),
        fork(IncidentSaga),
        fork(MailsSaga),
        fork(MediasSaga),
        fork(PlayerSaga),
        fork(ReviewsSaga),
        fork(LayoutSaga),
        fork(SearchSaga),
        fork(SearchSTTSaga),
        fork(SFTPAccountsSaga),
        fork(TalkSaga),
        fork(TimelineSaga),
        fork(UserSaga),
        fork(XMLSaga),
    ]);
}
