import moment from 'moment';
import numeral from 'numeral';
import { effects } from 'redux-saga';

import { Logger } from '../../Utils/Logger';
import { TypedStorage } from '../../Utils/TypedStorage';

import { loadError, loadSuccess, setLocale } from './Actions';
import { LocaleInfos, Locales } from './Constants';
import { I18N, I18nActionTypes } from './Types';

/*
  When https://github.com/Microsoft/TypeScript/pull/28207 is released ...
  import en_US from '../../I18n/en_US.json';
  import de_DE from '../../I18n/de_DE.json';
  import fr_FR from '../../I18n/fr_FR.json';
 */
/* eslint-disable @typescript-eslint/no-var-requires */
const en_US = require('../../I18n/en_GB.json');
const de_DE = require('../../I18n/de_DE.json');
const fr_FR = require('../../I18n/fr_FR.json');
/* eslint-enable @typescript-eslint/no-var-requires */

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Jed = require('jed');

import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'numeral/locales/de';
import 'numeral/locales/en-gb';
import 'numeral/locales/fr';

function* handleSetLocale(action: ReturnType<typeof setLocale>) {
    try {
        const res: I18N = yield effects.call<string>(loadGettext, action.payload);

        yield effects.put(loadSuccess(res as I18N, LocaleInfos[action.payload]));
    } catch (err) {
        if (err instanceof Error) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            yield effects.put(loadError(err.stack!));
        } else {
            yield effects.put(loadError('An unknown error occured.'));
        }
    }
}

async function loadGettext(locale: string): Promise<I18N> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: any;

    switch (locale) {
        case Locales.French:
            data = fr_FR;
            break;
        case Locales.English:
            data = en_US;
            break;
        case Locales.German:
            data = de_DE;
            break;
        default:
            return Promise.reject('Unknown locale');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let gettext: any;

    try {
        gettext = new Jed({
            domain: 'messages',
            locale_data: data.locale_data,
            missing_key_callback: (/*key: string*/) => {
                // Logger.warn(key);
            },
        });
        moment.locale(LocaleInfos[locale].momentLocale);
        numeral.locale(LocaleInfos[locale].momentLocale);
        TypedStorage.set('locale', locale);
    } catch (err) {
        Logger.warn('Could not load translations', { err });
        return Promise.reject(err);
    }

    return Promise.resolve({
        _: gettext.gettext.bind(gettext),
        gettext: gettext.gettext.bind(gettext),
        ngettext: (msgId: string, plural: string, n: number) => Jed.sprintf(gettext.ngettext(msgId, plural, n), n),
        npgettext: (ctx: string, msgID: string, plural: string, n: number) =>
            Jed.sprintf(gettext.npgettext(ctx, msgID, plural, n), n),
        pgettext: gettext.pgettext.bind(gettext),
        sprintf: Jed.sprintf,
    });
}

function* watchLoad() {
    yield effects.takeEvery(I18nActionTypes.SET_LOCALE, handleSetLocale);
}

export function* I18NSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchLoad)]);
}
