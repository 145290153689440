import { mediaarchiver } from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';
import { AMessage, IFromCommand } from './';

export class Review extends AMessage implements IFromCommand {
    private parsedReview: mediaarchiver.IReview | undefined | null;

    public constructor() {
        super('review');
    }

    public Parse({ ReviewArg }: mediaarchiver.WSMessage): Promise<void> {
        return new Promise(
            (resolve: () => void, reject: (err: Error) => void): void => {
                if (isNullOrUndefined(ReviewArg)) {
                    return reject(new Error('Not a review message'));
                }
                this.parsedReview = ReviewArg;
                resolve();
            },
        );
    }

    public get review(): mediaarchiver.IAnnotation | undefined {
        if (isNull(this.parsedReview)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedReview;
    }
}
