import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    annotationItem: {
        '&:last-child': {
            borderBottomWidth: 0,
        },
        alignItems: 'stretch',
        borderBottom: '1px solid ' + theme.palette.divider,
        fontSize: theme.mediaarchiver.fonts.infosTableFontSize,
        paddingBottom: 0,
        paddingLeft: 5,
        paddingTop: 0,
    },
    annotationItemContainer: {
        border: '1px solid ' + theme.palette.divider,
        marginTop: 0,
    },
    annotationItemHeader: {
        alignItems: 'stretch',
        borderRight: '1px solid ' + theme.palette.divider,
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: 130,
        overflow: 'hidden',
    },
    annotationItemHeaderLevel: {
        flex: '1 1 auto',
        marginRight: 4,
        maxWidth: 5,
        minWidth: 5,
    },
    annotationItemHeaderText: {
        alignItems: 'center',
        display: 'flex',
        fontFamily: theme.mediaarchiver.fonts.time,
        paddingLeft: 5,
    },
    annotationItemHeaderPicture: {
        backgroundSize: 'cover',
        maxHeight: 45,
        maxWidth: 45,
        minWidth: 45,
    },
    contextualMenu: {
        backgroundColor: 'transparent',
        height: '100vh',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100vw',
        zIndex: 9999,
    },
    contextualMenuList: {
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        width: 230,
    },
    exportButton: {},
    filterBox: {
        '& .MuiInputLabel-formControl': {
            fontSize: 11,
            top: -12,
        },
        '& .MuiInputLabel-formControl.MuiFormLabel-filled': {
            fontSize: 11,
            top: -5,
        },
        '& .MuiSelect-select .MuiInput-input': {
            paddingBottom: 0,
            paddingTop: 0,
        },
        '& .MuiSelect-selectMenu.MuiInputBase-input': {
            fontSize: 11,
        },

        alignItems: 'center',
        border: '0px',
        display: 'flex',
        flexDirection: 'row',
        padding: `0 ${theme.spacing(1)}`,
    },
    filterBoxCategory: {
        marginRight: theme.spacing(1),
        maxWidth: '30%',
        minWidth: '30%',
    },
    filterBoxInput: {
        flex: 1,
        marginRight: theme.spacing(1),
    },
    formControl: {
        '&>div': {
            marginTop: 0,
        },
        '&>label': {
            top: -13,
        },

        marginTop: 0,
    },
    highlightedWordText: {
        color: theme.palette.secondary.light,
        fontDecoration: 'underline',
        fontWeight: 'bold',
    },
    listItemTexts: {
        '& .MuiListItemText-secondary': {
            fontSize: '1em',
        },
        '& > span': {
            fontSize: '1em',
        },

        fontSize: 11,
        margin: '2px 0',
        padding: '0 6px',
        wordWrap: 'break-word',
    },
    readjustedItemHeader: {
        alignItems: 'stretch',
        borderRight: '1px solid ' + theme.palette.divider,
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: 85,
        overflow: 'hidden',
    },
    readjustedItemHeaderLevel1: {
        flex: '1 1 auto',
        marginRight: 4,
        maxWidth: 5,
        minWidth: 5,
    },
    readjustedItemHeaderLevel2: {
        flex: '1 1 auto',
        marginLeft: 4,
        maxWidth: 5,
        minWidth: 5,
    },
    root: {
        alignItems: 'center',
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'start',
    },
    sttItemMatch: {
        color: theme.palette.secondary.light,
        fontDecoration: 'underline',
        fontWeight: 'bold',
    },
    tabsContainer: {
        '&>div': {
            height: '100%',
        },
        height: 'calc(100% - 50px)',
        overflowY: 'auto',
        width: '100%',
    },
    tabsContent: {
        '& nav.MuiList-padding': {
            paddingBottom: 0,
            paddingTop: 0,
        },
        height: '100%',
        padding: '5px 10px 0 10px',
        width: '100%',
    },
    tabsSwitcher: {
        marginTop: 4,
        maxHeight: 23,
        minHeight: 23,
    },
    tabsSwitcherContainer: {
        '&>div>div': {
            overflow: 'hidden',
        },

        backgroundColor: 'transparent',
        height: 27,
    },
    tabsSwitcherTab: {
        '&>span>span': {
            padding: 0,
        },
        fontSize: 12,
        maxHeight: 23,
        minHeight: 23,
        minWidth: 80,
    },
    xmlHeader: {
        '& [role=button]': {
            '&>span': {
                whiteSpace: 'nowrap',
            },

            marginRight: theme.spacing(1),
            minWidth: 180,
        },
        '&>div': {
            flexGrow: 1,
        },

        display: 'flex',
        padding: theme.spacing(1),
    },
});
