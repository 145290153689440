import { push } from 'connected-react-router';
import { Store } from 'redux';
import { action } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { ExpiringToken } from '../../Backend/Commands/ExpiringToken';
import { WhoYouAre } from '../../Backend/Commands/WhoYouAre';
import { isPublicPath } from '../../constants';
import * as Proto from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { TypedStorage } from '../../Utils/TypedStorage';
import { isNull } from '../../Utils/Various';
import { store } from '../store';
import { updateCriterias } from '../Timeline/Actions';
import {
    ILoggedOutAction,
    ILoginAction,
    ILoginData,
    ILoginErrorAction,
    ILoginSuccessAction,
    ILogoutAction,
    ISetUserOptionsAction,
    IStoreUserOptionsAction,
    IUser,
    UserActionTypes,
} from './Types';

export const loggedOut = (): ILoggedOutAction => action(UserActionTypes.LOGGED_OUT);
export const login = (data: ILoginData): ILoginAction => action(UserActionTypes.LOGIN, data);
export const loginError = (message: Error): ILoginErrorAction => action(UserActionTypes.LOGIN_ERROR, message);
export const loginSuccess = (data: IUser): ILoginSuccessAction => action(UserActionTypes.LOGIN_SUCCESS, data);
export const logout = (): ILogoutAction => action(UserActionTypes.LOGOUT);
export const setUserOptions = (options: Proto.mediaarchiver.UserOptions[]): ISetUserOptionsAction =>
    action(UserActionTypes.SET_USER_OPTIONS, options);
export const storeUserOptions = (options: Proto.mediaarchiver.UserOptions[]): IStoreUserOptionsAction =>
    action(UserActionTypes.STORE_USER_OPTIONS, options);

Backend.getInstance()
    .Bind('whoYouAre', (msg: IFromCommand): void => {
        const w = msg as WhoYouAre;
        const user = (msg as WhoYouAre).User();
        if (!isPublicPath() && !w.LoggedIn()) {
            Logger.debug(user as object, 'Received a NOT logged in WhoYouAre');
            (store as Store).dispatch(loggedOut());
            (store as Store).dispatch(push('/'));
            return;
        }
        if (!w.LoggedIn() && !isNull(user) && user.share) {
            Logger.debug(user, 'Received a shared WhoYouAre');
            window.setTimeout(() => {
                if (!isNull(user.shareParams)) {
                    (store as Store).dispatch(updateCriterias(user.shareParams));
                }
            }, 1500);
        } else {
            Logger.debug(user as object, 'Received a logged in WhoYouAre');
        }
        window.setTimeout(() => {
            (store as Store).dispatch(loginSuccess(user as IUser));
        }, 1000);
    })
    .Bind('expiringToken', (msg: IFromCommand): void => {
        const cmd = msg as ExpiringToken;

        Logger.debug('Storing expiring token');
        TypedStorage.set('expiringToken', cmd.token);
        TypedStorage.set('expiringTokenExpires', cmd.expires);
        TypedStorage.set('incidentFirstTimeDisplayed', false);
    });
