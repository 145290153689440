import { Store } from 'redux';
import { action } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { Contact } from '../../Backend/Commands/Contact';
import { Contacts } from '../../Backend/Commands/Contacts';
import { ContactsCount } from '../../Backend/Commands/ContactsCount';
import { mediaarchiver } from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import {
    ContactsActionTypes,
    IContactFormErrors,
    ICreateAction,
    IDeleteContactAction,
    IDeletedContactAction,
    IEditAction,
    IGetAction,
    IGetCountAction,
    ISetErrorsAction,
    ISetOpSuccessAction,
    ISetProgressAction,
} from './Types';

export const create = (contact: mediaarchiver.IContact): ICreateAction => action(ContactsActionTypes.CREATE, contact);
export const deleteContact = (id: string): IDeleteContactAction => action(ContactsActionTypes.DELETE, id);
export const deletedContact = (id: string): IDeletedContactAction => action(ContactsActionTypes.DELETED_CONTACT, id);
export const edit = (contact: mediaarchiver.IContact): IEditAction => action(ContactsActionTypes.EDIT, contact);
export const get = (offset = 0, limit = 100): IGetAction => action(ContactsActionTypes.GET, { limit, offset });
export const getCount = (): IGetCountAction => action(ContactsActionTypes.GET_COUNT);
export const setErrors = (errors: IContactFormErrors): ISetErrorsAction =>
    action(ContactsActionTypes.SET_ERRORS, errors);
export const setOpSuccess = (): ISetOpSuccessAction => action(ContactsActionTypes.OP_SUCCESS);
export const setProgress = (toggle: boolean): ISetProgressAction => action(ContactsActionTypes.SET_OP_PROGRESS, toggle);

Backend.getInstance()
    .Bind('contact', (incoming: IFromCommand) => {
        const cmd = incoming as Contact;

        Logger.trace({ cmd }, 'Received contact');
        (store as Store).dispatch(action(ContactsActionTypes.SET_CONTACT, cmd.contact));
    })
    .Bind('contacts', (incoming: IFromCommand) => {
        const cmd = incoming as Contacts;

        Logger.trace({ cmd }, 'Received contacts');
        (store as Store).dispatch(action(ContactsActionTypes.SET_CONTACTS, cmd));
    })
    .Bind('contactsCount', (incoming: IFromCommand) => {
        const cmd = incoming as ContactsCount;

        Logger.trace({ count: cmd.count }, 'Received contacts count');
        (store as Store).dispatch(action(ContactsActionTypes.SET_CONTACTS_COUNT, cmd.count));
    });
