import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class DeleteArchive extends AMessage implements IToCommand {
    private id: string;

    public constructor(id: string) {
        super('deleteArchive');
        this.id = id;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            DeleteArchiveArg: this.id,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeDeleteArchive,
        }));
    }
}
