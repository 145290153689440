import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class CreateSFTPAccount extends AMessage implements IToCommand {
    private account: Proto.mediaarchiver.ISFTPAccount;

    public constructor(account: Proto.mediaarchiver.ISFTPAccount) {
        super('createSFTPAccount');
        this.account = account;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            CreateSFTPAccountArgs : this.account,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeCreateSFTPAccount,
        }));
    }
}
