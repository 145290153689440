import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    createButton: {
        '& svg': {
            marginRight: 5,
        },
        margin: theme.spacing(1),
    },
    createContainer: {
        height: '100%',
        overflow: 'hidden',
        padding: `0 ${theme.spacing(1)}`,
    },
    createContainerContent: {
        height: '100%',
        overflow: 'hidden auto',
        padding: theme.spacing(1),
    },
    createContainerInput: {
        height: '100%',
        overflow: 'scroll-y',
        padding: `0 ${theme.spacing(1)}px`,
    },
    fieldsAddButton: {},
    fieldsAddButtonContainer: {
        textAlign: 'right',
        width: '100%',
    },
    fieldsContainer: {
        borderTop: '1 px solid',
        height: '100%',
        paddingTop: 60,
    },
    fieldsForm: {},
    fieldsFormControl: {
        margin: theme.spacing(1),
        minWidth: 400,
    },
    fieldsFormListContainer: {
        border: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(1),
        maxHeight: 200,
        minHeight: 20,
        overflowY: 'auto',
    },
    fieldsFormListItem: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    fieldsFormListItemIcon: {
        cursor: 'pointer',
    },
    fieldsList: {
        border: '1px solid ' + theme.palette.divider,
        maxHeight: 300,
        minHeight: 50,
        overflow: 'hidden auto',
    },
    fieldsListItem: {
        '&:last-child': {
            borderBottomWidth: 0,
        },
        borderBottom: '1px solid ' + theme.palette.divider,
    },
    fieldsListItemIcon: {
        cursor: 'pointer',
    },
    form: {
        display: 'flex',
        flexFlow: 'column',
        flexWrap: 'wrap',
        marginTop: 15,
    },
    formControl: {
        marginTop: theme.spacing(1) * 2,
        textAlign: 'center',
    },
    formControlColor: {
        marginTop: 22,
    },
    listContainer: {
        height: '100%',
        overflow: 'hidden',
        padding: `0 ${theme.spacing(1)}px`,
    },
    listContainerContent: {
        '&>h5': {
            marginBottom: 20,
        },
        height: '100%',
        padding: theme.spacing(1),
    },
    listListContainer: {
        maxHeight: '90%',
        overflow: 'auto',
    },
    listListItem: {
        '& span, & div, & p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderWidth: 0,
        cursor: 'pointer',
        flex: 1,
        maxHeight: 75,
        minHeight: 75,
        minWidth: 0,
        padding: '10px 8px',
    },
    root: {
        flexGrow: 1,
        height: '100%',
        padding: '20px 10px',
    },
    sketchPicker: {
        marginTop: 10,
    },
    sketchPickerColorBlock: {
        background: '#fff',
        borderRadius: 1,
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: theme.spacing(1),
        padding: 5,
    },
    sketchPickerColorBlockInside: {
        borderRadius: '2px',
        height: '14px',
        width: '36px',
    },
    sketchPickerCover: {
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
    },
    sketchPickerPopOver: {
        position: 'absolute',
        top: -100,
        zIndex: 2,
    },
});
