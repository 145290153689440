import { Reducer } from 'redux';

import { Contacts } from '../../Backend/Commands/Contacts';
import { isNull, isNullOrUndefined } from '../../Utils/Various';
import { ContactsActionTypes, IContactsState } from './Types';

const initialState: IContactsState = {
    contacts: [],
    opSuccessEventElement: document.createElement('div'),
    operationInProgress: false,
    total: 0,
};

const reducer: Reducer<IContactsState> = (state = initialState, action) => {
    switch (action.type) {
        case ContactsActionTypes.DELETED_CONTACT: {
            let total = state.total;
            const contacts = [...state.contacts].filter((i) => {
                if (!isNull(i) && i.ID === action.payload) {
                    total = total - 1;
                    return false;
                }
                return true;
            });
            return { ...state, contacts, total };
        }

        case ContactsActionTypes.OP_SUCCESS: {
            state.opSuccessEventElement.dispatchEvent(
                new CustomEvent('opSuccess'),
            );
            return state;
        }

        case ContactsActionTypes.SET_CONTACT: {
            if (action.payload === null) {
                return state;
            }
            let total = state.total;

            const contacts = state.contacts.map((contact) => {
                if (
                    !isNullOrUndefined(contact) &&
                    (action.payload.ID === contact.ID)
                ) {
                    return action.payload;
                }
                return contact;
            });
            if (!contacts.some((contact) => !isNullOrUndefined(contact) && (contact.ID === action.payload.ID))) {
                total = total + 1;
                contacts.push(action.payload);
            }
            return { ...state, contacts, total };
        }

        case ContactsActionTypes.SET_CONTACTS: {
            const cmd: Contacts = action.payload;
            const contacts = state.contacts;

            if (cmd.count === 0) {
                return state;
            }
            if (cmd.offset > contacts.length) {
                for (let i = contacts.length; i < cmd.offset; i++) {
                    contacts[i] = null;
                }
            }
            for (let i = 0; i < cmd.count; i++) {
                contacts[i + cmd.offset] = cmd.contacts[i];
            }
            return { ...state, contacts };
        }

        case ContactsActionTypes.SET_CONTACTS_COUNT: {
            return { ...state, total: action.payload };
        }

        case ContactsActionTypes.SET_ERRORS: {
            state.opSuccessEventElement.dispatchEvent(
                new CustomEvent('opError', { detail: action.payload }),
            );
            return state;
        }

        case ContactsActionTypes.SET_OP_PROGRESS: {
            return { ...state, operationInProgress: action.payload };
        }

        default: {
            return state;
        }
    }
};

export { reducer as ContactsReducer };
