import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../Store';
import {
    setDrawerContent,
    setDrawerTitle,
    setHasAnnotations,
    setPageContent,
    setPageTitle,
} from '../../Store/Layout';
import { SearchPage } from './SearchPage';
import styles from './styles';

const mapStateToProps = ({ i18n }: IApplicationState) => ({
    i18n,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setDrawerContent: (content: React.ReactNode) =>
        dispatch(setDrawerContent(content)),
    setDrawerTitle: (title: string) => dispatch(setDrawerTitle(title)),
    setHasAnnotations: (has: boolean) => dispatch(setHasAnnotations(has)),
    setPageContent: (content: React.ReactNode) =>
        dispatch(setPageContent(content)),
    setPageTitle: (title: string) => dispatch(setPageTitle(title)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(SearchPage));
