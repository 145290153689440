import { withStyles, withWidth } from '@material-ui/core';
import { connect } from 'react-redux';
import { IApplicationState } from '../../Store';
import DrawerComponent from './Drawer';
import styles from './styles';

const mapStateToProps = ({ layout }: IApplicationState) => ({
    layout,
});

const component = connect(mapStateToProps)(
    withWidth()((DrawerComponent)),
);

export default withStyles(styles)(component);
