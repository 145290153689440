import { Reducer } from 'redux';

import { FTPAccounts } from '../../Backend/Commands/FTPAccounts';
import { isNull, isNullOrUndefined } from '../../Utils/Various';
import { FTPAccountActionTypes, IFTPAccountsState } from './Types';

const initialState: IFTPAccountsState = {
    accounts: [],
    opSuccessEventElement: document.createElement('div'),
    operationInProgress: false,
    total: 0,
};

const reducer: Reducer<IFTPAccountsState> = (state = initialState, action) => {
    switch (action.type) {
        case FTPAccountActionTypes.DELETED_ACCOUNT: {
            let total = state.total;
            const accounts = [...state.accounts].filter((i) => {
                if (!isNull(i) && i.ID === action.payload) {
                    total = total - 1;
                    return false;
                }
                return true;
            });
            return { ...state, accounts, total };
        }

        case FTPAccountActionTypes.OP_SUCCESS: {
            state.opSuccessEventElement.dispatchEvent(
                new CustomEvent('opSuccess'),
            );
            return state;
        }

        case FTPAccountActionTypes.SET_ACCOUNT: {
            if (action.payload === null) {
                return state;
            }
            let total = state.total;

            const accounts = state.accounts.map((account) => {
                if (
                    !isNullOrUndefined(account) &&
                    (action.payload.ID === account.ID)
                ) {
                    return action.payload;
                }
                return account;
            });
            if (!accounts.some((account) => !isNullOrUndefined(account) && (account.ID === action.payload.ID))) {
                total = total + 1;
                accounts.push(action.payload);
            }
            return { ...state, accounts, total };
        }

        case FTPAccountActionTypes.SET_ACCOUNTS: {
            const cmd: FTPAccounts = action.payload;
            const accounts = Array.from(state.accounts);

            if (cmd.count === 0) {
                return state;
            }
            if (cmd.offset > accounts.length) {
                for (let i = accounts.length; i < cmd.offset; i++) {
                    accounts[i] = null;
                }
            }
            for (let i = 0; i < cmd.count; i++) {
                accounts[i + cmd.offset] = cmd.acounts[i];
            }
            return { ...state, accounts };
        }

        case FTPAccountActionTypes.SET_ACCOUNTS_COUNT: {
            return { ...state, total: action.payload };
        }

        case FTPAccountActionTypes.SET_ERRORS: {
            state.opSuccessEventElement.dispatchEvent(
                new CustomEvent('opError', { detail: action.payload }),
            );
            return state;
        }

        case FTPAccountActionTypes.SET_OP_PROGRESS: {
            return { ...state, operationInProgress: action.payload };
        }

        default: {
            return state;
        }
    }
};

export { reducer as FTPAccountsReducer };
