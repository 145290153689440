import * as MD from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { IAnalyticsState, setConsent } from '../../Store/Analytics';
import { I18N } from '../../Store/I18n';
import { setSnackMessage } from '../../Store/Layout';
import { IUserState, setUserOptions } from '../../Store/User';
import { getTheme } from '../../Themes';
import { Logger } from '../../Utils/Logger';
import { TypedStorage } from '../../Utils/TypedStorage';

const theme = getTheme();

const styles = MD.createStyles({
    form: {
        display: 'flex',
        flexFlow: 'column',
        flexWrap: 'wrap',
        margin: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
    },
    root: {
        flexGrow: 1,
        height: '100%',
        padding: 10,
    },
});

interface IPropsFromState {
    analytics: IAnalyticsState;
    user: IUserState;
    i18n: I18N;
}

interface IPropsFromDispatch {
    setConsent: typeof setConsent;
    setOptions: typeof setUserOptions;
    setSnack: typeof setSnackMessage;
}

interface IUIOption {
    option: mediaarchiver.UserOptions;
    text: string;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export default class AccountOptionsComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        const classes = this.props.classes;

        return (
            <MD.Grid container className={classes.root} spacing={0}>
                <MD.Grid item sm={12}>
                    <MD.Paper className={classes.paper}>
                        <MD.Typography variant='h5'>{this.props.i18n._('My settings')}</MD.Typography>
                        <form autoComplete='off' className={classes.form}>
                            {this.getAvailableOptions().map((option) => this.renderOption(option))}
                            {this.renderAnalyticsOption()}
                        </form>
                    </MD.Paper>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private getAvailableOptions(): IUIOption[] {
        return [
            {
                option: mediaarchiver.UserOptions.USER_OPTION_MAIL_NOTIFICATIONS,
                text: this.props.i18n._('Send me an email everytime a new extraction is available'),
            },
            {
                option: mediaarchiver.UserOptions.USER_OPTION_BROWSER_NOTIFICATIONS,
                text: this.props.i18n._('Send me a browser notification everytime a new extraction is available'),
            },
            {
                option: mediaarchiver.UserOptions.USER_OPTION_AUTOPLAY_ARCHIVES,
                text: this.props.i18n._('Auto play content when viewing archives'),
            },
            {
                option: mediaarchiver.UserOptions.USER_OPTION_PAUSE_AFTER_IN_OUT,
                text: this.props.i18n._('Pause player when setting start point in timeline'),
            },
            {
                option: mediaarchiver.UserOptions.USER_OPTION_NO_AUTOPLAY_WHEN_PLAY_CONTENT,
                text: this.props.i18n._('Do not auto play content when clicking on EPG and annotations'),
            },
        ];
    }

    private renderOption(option: IUIOption): React.ReactNode {
        let disabled = false;
        let helperText = '';

        if (option.option === mediaarchiver.UserOptions.USER_OPTION_BROWSER_NOTIFICATIONS) {
            if (!('Notification' in window)) {
                disabled = true;
                helperText = this.props.i18n._('Your browser does not support notifications');
            } else if (Notification.permission === 'denied') {
                disabled = true;
                helperText = this.props.i18n._(
                    'You blocked notifications for mediaarchiver, please allow it in your browser settings.',
                );
            }
        }

        return (
            <div key={`option_${option.option}`}>
                <MD.FormControlLabel
                    control={
                        <MD.Switch
                            checked={this.props.user.user.options.indexOf(option.option) !== -1}
                            key={`switch_option_${option.option}`}
                            onChange={this.handleOptionChange.bind(this, option.option)}
                            value='1'
                        />
                    }
                    disabled={disabled}
                    key={`control_option_${option.option}`}
                    label={option.text}
                />
                {helperText !== '' ? (
                    <MD.Typography key={`helper_option_${option.option}`} variant='caption'>
                        {helperText}
                    </MD.Typography>
                ) : (
                    ''
                )}
            </div>
        );
    }

    private renderAnalyticsOption(): React.ReactNode {
        const allowed = TypedStorage.get('analyticsAsked', false) && TypedStorage.get('analyticsAllowed', false);

        return (
            <div key={'option_analytics'}>
                <MD.FormControlLabel
                    control={
                        <MD.Switch
                            checked={allowed}
                            key={'switch_analytics'}
                            onChange={this.handleAnalyticsOptionChange.bind(this)}
                            value='1'
                        />
                    }
                    key={'control_option_analytics'}
                    label={this.props.i18n._('Allow sending anonymous statistics about MediaArchiver usage')}
                />
            </div>
        );
    }

    private handleOptionChange(
        option: mediaarchiver.UserOptions,
        ev: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ): void {
        if (checked && option === mediaarchiver.UserOptions.USER_OPTION_BROWSER_NOTIFICATIONS) {
            if (Notification.permission !== 'granted') {
                const callback = (permission: NotificationPermission) => {
                    if (permission !== 'granted') {
                        return;
                    }
                    let newOptions = this.props.user.user.options;

                    if (newOptions.indexOf(mediaarchiver.UserOptions.USER_OPTION_BROWSER_NOTIFICATIONS) === -1) {
                        newOptions = newOptions.concat(mediaarchiver.UserOptions.USER_OPTION_BROWSER_NOTIFICATIONS);
                        this.props.setOptions(newOptions);
                    }
                };
                const result = Notification.requestPermission(callback);
                if (result && typeof result.then === 'function') {
                    result.then(callback).catch((err) => {
                        Logger.warn(err as string);
                    });
                }
                return;
            }
        }

        let options = this.props.user.user.options;

        options =
            options.indexOf(option) !== -1 ? options.filter((o) => o !== option) : (options = options.concat(option));

        this.props.setOptions(options);
    }

    private handleAnalyticsOptionChange(ev: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
        this.props.setConsent(checked);
    }
}

const mapStateToProps = ({ analytics, i18n, user }: IApplicationState) => ({
    analytics,
    i18n: i18n.i18n,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setConsent: (consent: boolean) => dispatch(setConsent(consent)),
    setOptions: (options: mediaarchiver.UserOptions[]) => dispatch(setUserOptions(options)),
    setSnack: (msg: string) => dispatch(setSnackMessage(msg)),
});

export const AccountOptions = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(AccountOptionsComponent));
