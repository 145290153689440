import { buildBackendURL, Config } from '../config';
import * as Proto from '../Protos/protos';
import { Logger } from '../Utils/Logger';
import { TypedStorage } from '../Utils/TypedStorage';
import { isNull, isNullOrUndefinedOrEmptyArray } from '../Utils/Various';
import { errorMessage } from './errorMessages';
import { parseIncomingMessage } from './parseIncomingMessage';

import { IFromCommand } from './Commands';
import { MessageAck } from './Commands/MessageAck';

import { exportedI18n as i18n } from '../Store/I18n/Reducer';

/**
 * Interface for sent commands callback (while waiting for ack).
 */
interface ISentCommandsCallback {
    callback: (time: number, success: boolean, error: string) => void;
    name: string;
    timeout: number;
    timestamp: number;
}

/**
 * Interface for incoming commands bounded callbacks.
 */
interface IBoundedCommandCallback {
    /**
     * Callback when command incomes.
     */
    callback: (cmd: IFromCommand) => void;
    /**
     * Bind just for once ?.
     */
    justOnce: boolean;
}

type BoundedCommandList = Map<string, IBoundedCommandCallback[]>;

/**
 * WS Backend handler.
 */
export class Backend {
    public static getInstance(): Backend {
        if (Backend.instance === null) {
            Backend.instance = new Backend();
        }
        return Backend.instance;
    }

    private static instance: Backend | null = null;

    /**
     * All sent commands waiting for a MessageAck
     */
    private sentCommands: Map<string, ISentCommandsCallback>;

    private boundCommands: BoundedCommandList;
    private retries: number;
    private retryDelay: number;
    private retryTimer: number;
    private ws: WebSocket | null;

    public constructor() {
        if (Backend.instance !== null) {
            throw new Error('Backend is a singleton');
        }
        this.boundCommands = new Map();
        this.sentCommands = new Map();
        Backend.instance = this;
        this.retries = 0;
        this.retryDelay = Config.backendRetriesStart;
        this.retryTimer = 0;
        this.ws = null;
        window.setInterval(() => {
            this.sentCallbacksGC();
        }, Config.commandsTTL / 2);
        this.Bind('messageAck', this.onMessageAck.bind(this));
        this.connect();
    }

    public async Send(msg: Proto.mediaarchiver.WSMessage, commandName: string, timeout: number): Promise<void> {
        const parseError: string | null = Proto.mediaarchiver.WSMessage.verify(msg);

        if (this.ws === null) {
            return Promise.reject(new Error(i18n._('Backend is not connected')));
        }
        if (parseError !== null) {
            return Promise.reject(parseError);
        }
        if (msg.MessageID === '') {
            return Promise.reject(new Error('Message has no id'));
        }
        return new Promise<void>((resolve: () => void, reject: (err: Error) => void) => {
            if (this.ws === null) {
                return reject(new Error(i18n._('Backend is not connected')));
            }
            this.sentCommands.set(msg.MessageID, {
                callback: (time: number, success: boolean, reason: string) => {
                    if (success) {
                        resolve();
                        return;
                    }
                    reject(new Error(reason));
                },
                name: commandName,
                timeout,
                timestamp: Date.now(),
            });
            this.ws.send(Proto.mediaarchiver.WSMessage.encode(msg).finish());
        });
    }

    /**
     * Bind all (or just the next) incoming specified commands to a callback.
     *
     * @param commandName The command name (like 'whoYouAre')
     * @param callback    Callback to bind command to
     * @param justOnce    Just bind the next command.
     *
     * @returns This, for chaining.
     */
    public Bind(commandName: string, callback: (cmd: IFromCommand) => void, justOnce = false): Backend {
        const list: IBoundedCommandCallback[] = this.boundCommands.has(commandName)
            ? (this.boundCommands.get(commandName) as IBoundedCommandCallback[])
            : [];

        list.push({ callback, justOnce });
        this.boundCommands.set(commandName, list);
        return this;
    }

    /**
     * Unbind incoming specified commands to a callback.
     *
     * @param commandName The command name (like 'whoYouAre')
     * @param callback    Callback to bind command to
     *
     * @returns This, for chaining.
     */
    public Unbind(commandName: string, callback: (cmd: IFromCommand) => void): Backend {
        const list = this.boundCommands.get(commandName);

        if (!isNullOrUndefinedOrEmptyArray(list)) {
            this.boundCommands.set(
                commandName,
                list.filter((i) => i.callback !== callback),
            );
        }
        return this;
    }

    private async connect(): Promise<void> {
        if (this.ws) {
            this.ws.close(1000, 'Reconnecting');
        }

        let url = '';

        let shareMatch: RegExpMatchArray | null = null;

        if (!isNull(document.location)) {
            shareMatch = document.location.href.match(
                /\/share\/([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i,
            );
        }
        if (!isNull(shareMatch)) {
            url = buildBackendURL(`/ws-share/${shareMatch[1]}`, true);
        } else {
            const token = await this.getExpiringToken();

            url = buildBackendURL(`/ws/${token}`, true);
        }
        Logger.debug('Trying to connect to backend');
        this.ws = new WebSocket(url);
        this.ws.binaryType = 'arraybuffer';
        this.ws.onclose = this.onWsClose.bind(this);
        this.ws.onmessage = this.onWsMessage.bind(this);
        this.ws.onopen = this.onWsOpen.bind(this);
    }

    private onWsClose(e: CloseEvent): void {
        let reason = 'Unknown reason';

        this.ws = null;
        if (typeof e.code === 'number' && typeof errorMessage[e.code] === 'string') {
            reason = errorMessage[e.code];
        }
        Logger.info('Connection to backend closed', reason);
        if (Config.backendRetries >= 0 && this.retries >= Config.backendRetries) {
            Logger.fatal('Backend still not reachable after max retries');
            return;
        }
        this.retries = this.retries + 1;
        if (this.retryTimer !== 0) {
            window.clearTimeout(this.retryTimer);
        }
        Logger.info(
            {
                delay: this.retryDelay,
                max: Config.backendRetries,
                try: this.retries,
            },
            'Will try to connect after backoff delay',
        );
        this.retryTimer = window.setTimeout(() => {
            this.connect();
        }, this.retryDelay);
        this.retryDelay = this.retryDelay * 2;
        if (this.retryDelay > Config.backendRetriesCapping) {
            this.retryDelay = Config.backendRetriesCapping;
        }
    }

    private onWsMessage(e: MessageEvent): void {
        let incoming: Proto.mediaarchiver.WSMessage;

        try {
            incoming = Proto.mediaarchiver.WSMessage.decode(new Uint8Array(e.data));
        } catch (err) {
            Logger.warn(err as Error, 'Invalid incoming message');
            return;
        }
        parseIncomingMessage(incoming)
            .then((cmd: IFromCommand): void => {
                this.dispatchCommand(cmd);
            })
            .catch((error: Error): void => {
                Logger.warn({ error }, 'Failed to parse incoming command');
            });
    }

    private onWsOpen(): void {
        Logger.debug('Connected to backend');
        if (this.retryTimer !== 0) {
            window.clearTimeout(this.retryTimer);
        }
        this.retries = 0;
        this.retryDelay = Config.backendRetriesStart;
    }

    private onMessageAck(cmd: IFromCommand): void {
        const ack = cmd as MessageAck;

        if (ack.GetName() !== 'messageAck') {
            return;
        }
        const callback: ISentCommandsCallback | undefined = this.sentCommands.get(ack.GetMessageID());
        if (callback !== undefined) {
            callback.callback.apply(null, [Date.now() - callback.timestamp, ack.IsSuccess(), ack.GetReason()]);
            this.sentCommands.delete(ack.GetMessageID());
            return;
        }
        Logger.trace({ messageID: ack.GetMessageID() }, 'Received a no more awaited) message ack');
    }

    /**
     * Garbage collector for sent commands callbacks. If timeout milliseconds is reached,
     * command promise is rejected with a timeout error and command will not more be awaited.
     */
    private sentCallbacksGC() {
        this.sentCommands.forEach((cb: ISentCommandsCallback, messageID: string) => {
            if (cb.timestamp < Date.now() - cb.timeout) {
                cb.callback.apply(null, [cb.timeout, false, 'Command timeout']);
                this.sentCommands.delete(messageID);
            }
        });
    }

    /**
     * Dispatch commands to bounded callbacks accordingly.
     */
    private dispatchCommand(cmd: IFromCommand): void {
        const list = this.boundCommands.get(cmd.GetName());

        if (list === undefined) {
            return;
        }

        const newList: IBoundedCommandCallback[] = [];

        for (const cb of list) {
            try {
                cb.callback.apply(null, [cmd]);
            } catch (err) {
                Logger.warn({ cmd, err }, 'A bounded command callback execution failed');
            }
            if (!cb.justOnce) {
                newList.push(cb);
            }
        }
        this.boundCommands.set(cmd.GetName(), newList);
    }

    private async getExpiringToken(): Promise<string> {
        const expires = TypedStorage.get('expiringTokenExpires', 0);
        const token = TypedStorage.get('expiringToken', '');

        if (expires === 0 || token == '') {
            return Promise.resolve('');
        }

        return fetch('https://time.akamai.com/', {
            cache: 'no-store',
            method: 'GET',
            mode: 'cors',
        })
            .then((response: Response): Promise<string> => response.text())
            .then((nowStr: string): string => {
                const now = parseInt(nowStr, 10);

                if (now < expires && token !== '') {
                    return token;
                }
                throw new Error('expired token');
            })
            .catch((err) => {
                TypedStorage.del('expiringTokenExpires');
                TypedStorage.del('expiringToken');
                Logger.warn(err as string, 'Failed validating token');
                return '';
            });
    }
}
