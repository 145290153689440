import * as Icons from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18NState } from '../../Store/I18n';
import { block as blockMail } from '../../Store/Mails/Actions';
import { IMailFormErrors, IMailState } from '../../Store/Mails/Types';
import { getTheme } from '../../Themes';

interface IRouterMatchParams {
    id: string;
}

interface IState {
    error: IMailFormErrors;
    mail: string;
    thanks: boolean;
}

interface IPropsFromState {
    i18n: I18NState;
    mails: IMailState;
    router: RouteComponentProps<IRouterMatchParams>;
}

interface IPropsFromDispatch {
    blockMail: typeof blockMail;
}
const theme = getTheme();

const styles = MD.createStyles({
    button: {
        marginTop: theme.spacing(1),
    },
    container: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
    },
    error: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(1),
    },
    input: {
        padding: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
});

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class MailsPreventComponent extends React.Component<AllProps, IState> {
    private errorHandler: (ev: CustomEvent<IMailFormErrors>) => void;
    private successHandler: (ev: CustomEvent<{}>) => void;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
        this.errorHandler = (ev: CustomEvent<IMailFormErrors>) => {
            this.setState({ error: ev.detail });
        };
        this.successHandler = () => {
            this.setState({ thanks: true });
        };
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public componentDidMount(): void {
        this.props.mails.opSuccessEventElement.addEventListener<any>('opError', this.errorHandler);
        this.props.mails.opSuccessEventElement.addEventListener<any>('opSuccess', this.successHandler);
    }

    public componentWillUnmount(): void {
        this.props.mails.opSuccessEventElement.removeEventListener<any>('opError', this.errorHandler);
        this.props.mails.opSuccessEventElement.removeEventListener<any>('opSuccess', this.successHandler);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    public render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid className={classes.container} container>
                <MD.Grid item lg={4} md={6} xs={12}>
                    <MD.Paper className={classes.paper} elevation={3}>
                        {this.state.thanks ? this.renderThanks() : this.renderForm()}
                    </MD.Paper>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private renderForm() {
        const { classes } = this.props;

        return (
            <div>
                <MD.Typography className={classes.title} variant='h4'>
                    {this.props.i18n.i18n._('Unsubscribe me')}
                </MD.Typography>
                <MD.Typography variant='body2'>
                    {this.props.i18n.i18n._(
                        "You received an email from us and don't want to receive any more emails ?",
                    )}
                </MD.Typography>
                <MD.Typography variant='body2'>
                    {this.props.i18n.i18n._("Just fill out this form and we'll stop sending.")}
                </MD.Typography>
                <form className={classes.title}>
                    <MD.TextField
                        className={classes.input}
                        fullWidth
                        error={this.state.error.mail !== ''}
                        helperText={this.state.error.mail}
                        label={this.props.i18n.i18n._('Type here your email address')}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                            this.setState({ mail: ev.target.value });
                        }}
                        type='email'
                        value={this.state.mail}
                    />
                    <MD.Button className={classes.button} onClick={this.onSubmit.bind(this)} variant='outlined'>
                        <FontAwesomeIcon icon={Icons.faHandPaper} />
                        &nbsp;
                        {this.props.i18n.i18n._('Stop sending me mails')}
                    </MD.Button>
                    {this.state.error.general !== '' ? (
                        <MD.Typography className={classes.error} variant='subtitle1'>
                            {this.state.error.general}
                        </MD.Typography>
                    ) : (
                        ''
                    )}
                </form>
            </div>
        );
    }

    private renderThanks() {
        const { classes } = this.props;

        return (
            <div>
                <MD.Typography className={classes.title} variant='h4'>
                    {this.props.i18n.i18n._('Unsubscribed !')}
                </MD.Typography>
                <MD.Typography variant='body2'>
                    {this.props.i18n.i18n._(
                        "Yacast respects user's privacy, from now on, we won't send you any emails.",
                    )}
                </MD.Typography>
            </div>
        );
    }

    private onSubmit() {
        this.props.blockMail(this.state.mail, this.props.router.match.params.id);
        this.setState({ error: this.getDefaultState().error });
    }

    private getDefaultState(): IState {
        return {
            error: {
                general: '',
                mail: '',
            },
            mail: '',
            thanks: false,
        };
    }
}

const mapStateToProps = ({ i18n, mails }: IApplicationState, ownProps: RouteComponentProps<IRouterMatchParams>) => ({
    i18n,
    mails,
    router: ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    blockMail: (mail: string, mailReason: string) => dispatch(blockMail(mail, mailReason)),
});

export const MailsPrevent = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(MailsPreventComponent));
