import { Store } from 'redux';
import { all, AllEffect, call, fork, takeEvery } from 'redux-saga/effects';

import { IApplicationState } from '..';
import { IToCommand } from '../../Backend/Commands';
import { CreateFTPAccount } from '../../Backend/Commands/CreateFTPAccount';
import { DeleteFTPAccount } from '../../Backend/Commands/DeleteFTPAccount';
import { EditFTPAccount } from '../../Backend/Commands/EditFTPAccount';
import { GetFTPAccounts } from '../../Backend/Commands/GetFTPAccounts';
import { GetFTPAccountsCount } from '../../Backend/Commands/GetFTPAccountsCount';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import { create, deleteAccount, deletedAccount, get, setErrors, setOpSuccess, setProgress } from './Actions';
import { FTPAccountActionTypes } from './Types';

function* handleGet(action: ReturnType<typeof get>) {
    const cmd = new GetFTPAccounts(action.payload.offset, action.payload.limit);

    try {
        yield call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get ftp accounts');
    }
}

function* handleGetCount() {
    const cmd = new GetFTPAccountsCount();

    try {
        yield call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get ftp accounts count');
    }
}

function* handleDelete(action: ReturnType<typeof deleteAccount>) {
    const str = store as Store<IApplicationState>;
    const cmd = new DeleteFTPAccount(action.payload);

    yield call(str.dispatch.bind(str, setProgress(true)));
    try {
        yield call(cmd.Send.bind(cmd));
        yield call(str.dispatch.bind(str, deletedAccount(action.payload)));
        yield call(str.dispatch.bind(str, setOpSuccess()));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to delete account');
    } finally {
        yield call(str.dispatch.bind(str, setProgress(false)));
    }
}

function* handleOps(actionType: string, action: ReturnType<typeof create>) {
    const str = store as Store<IApplicationState>;
    let command: IToCommand;

    switch (actionType) {
        case 'create':
            command = new CreateFTPAccount(action.payload);
            break;
        case 'edit':
            command = new EditFTPAccount(action.payload);
            break;
        default:
            throw new Error('Unknown ops action type');
    }

    yield call(str.dispatch.bind(str, setProgress(true)));
    try {
        yield call(command.Send.bind(command));
        yield call(str.dispatch.bind(str, setOpSuccess()));
    } catch (err) {
        try {
            const data = JSON.parse((err as Error).message);

            yield call(str.dispatch.bind(str, setErrors(data)));
        } catch (err2) {
            Logger.warn({ original: err, parsing: err2 }, 'Unable to parse error message');
            yield call(
                str.dispatch.bind(
                    str,
                    setErrors({
                        general: 'N/A',
                        hostname: '',
                        name: '',
                        port: '',
                    }),
                ),
            );
        }
        Logger.warn(err as Error, 'Failed to create/edit/delete ftp account');
    }
    yield call(str.dispatch.bind(str, setProgress(false)));
}

function* watchGet() {
    yield takeEvery(FTPAccountActionTypes.GET, handleGet);
}

function* watchGetCount() {
    yield takeEvery(FTPAccountActionTypes.GET_COUNT, handleGetCount);
}

function* watchCreate() {
    yield takeEvery(FTPAccountActionTypes.CREATE, handleOps.bind(null, 'create'));
}

function* watchEdit() {
    yield takeEvery(FTPAccountActionTypes.EDIT, handleOps.bind(null, 'edit'));
}

function* watchDelete() {
    yield takeEvery(FTPAccountActionTypes.DELETE, handleDelete);
}

export function* FTPAccountsSaga(): Generator<AllEffect, void, unknown> {
    yield all([fork(watchGet)]);
    yield all([fork(watchGetCount)]);
    yield all([fork(watchCreate)]);
    yield all([fork(watchEdit)]);
    yield all([fork(watchDelete)]);
}
