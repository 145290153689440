import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState } from '../../../Store';
import { deleteAnnotation, exportAnnotations, openEditDialog } from '../../../Store/Annotations/Actions';
import { get as getAnnotationTypes } from '../../../Store/AnnotationTypes';
import { showDialog as showExtractionDialog } from '../../../Store/Archives/Actions';
import {
    setEndSelection,
    setPosition as setPlayerPosition,
    setSnapshot,
    setStartSelection,
    setPlayer,
} from '../../../Store/Player';
import { setCopiedTalks, showCopyDialog } from '../../../Store/Talk';
import {
    loadFileStorage as loadXMLFileStorage,
    loadFile as loadXMLFile,
    setCurrentFile as setCurrentXMLFile,
    setError as setXMLError,
    clearList,
} from '../../../Store/XML';
import TimelineAnnotationsComponent from './Annotations';
import {
    clear as clearSTTSearch,
    searchSpeeches as doSTTSearchSpeeches,
    searchSpeechesDate as doSearchByDate,
    setCurrentbegin,
    setCurrentend,
    setCurrentmediaid,
    setCurrentmedianame,
    setCurrentsentence,
    setCurrentstart,
    setCurrenttimestampedwords,
    setCurrentwords,
    setCurrentworduid,
    setNewwords,
    setTimelineProgram,
    setRequestedCurrent,
    setRequestedEnd,
    setRequestedMedia,
    setRequestedStart,
} from '../../../Store/SearchSTT/Actions';
import styles from './style';
import { ISearchSTTDateParams, ISearchSTTParams, NullableSpeechResult } from '../../../Store/SearchSTT/Types';
import * as Proto from '../../../Protos/protos';
import { IPlayer } from '../../../Player/Player';

const mapStateToProps = ({
    annotationTypes,
    i18n,
    player,
    searchSTT,
    talk,
    timeline,
    user,
    xml,
}: IApplicationState) => ({
    annotationTypes,
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    player,
    searchSTT,
    talk,
    timeline,
    user,
    xml,
});

// window.setTimeout(()=>{str.dispatch(setNewwords(cmd.results));},50);

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearList: () => dispatch(clearList()),
    clearSTTSearch: () => dispatch(clearSTTSearch()),
    deleteAnnotation: (a: mediaarchiver.IAnnotation) => dispatch(deleteAnnotation(a)),
    doSearchByDate: (params: ISearchSTTDateParams) => dispatch(doSearchByDate(params)),
    doSTTSearchSpeeches: (params: ISearchSTTParams) => dispatch(doSTTSearchSpeeches(params)),
    exportAnnotations: (a: mediaarchiver.IArgumentsExportAnnotations) => dispatch(exportAnnotations(a)),
    getAnnotationTypes: (o?: number, l?: number) => dispatch(getAnnotationTypes(o, l)),
    loadXMLFile: (f: File) => dispatch(loadXMLFile(f)),
    loadXMLFileStorage: () => dispatch(loadXMLFileStorage()),
    openEditDialog: (a: mediaarchiver.IAnnotation) => dispatch(openEditDialog(a)),
    setCopiedTalks: (l: mediaarchiver.ITalk[]) => dispatch(setCopiedTalks(l)),
    setCurrentbegin: (currentBegin: number) => dispatch(setCurrentbegin(currentBegin)),
    setCurrentend: (currentEnd: number) => dispatch(setCurrentend(currentEnd)),
    setCurrentmediaid: (currentMediaID: number) => dispatch(setCurrentmediaid(currentMediaID)),
    setCurrentmedianame: (currentMediaName: string) => dispatch(setCurrentmedianame(currentMediaName)),
    setCurrentsentence: (currentSentence: React.ReactNode[]) => dispatch(setCurrentsentence(currentSentence)),
    setCurrentstart: (currentStart: number) => dispatch(setCurrentstart(currentStart)),
    setCurrenttimestampedwords: (currentTimestampedWords: Map<number, string>) =>
        dispatch(setCurrenttimestampedwords(currentTimestampedWords)),
    setCurrentwords: (currentWords: Proto.mediaarchiver.ISearchSpeechesWord[]) =>
        dispatch(setCurrentwords(currentWords)),
    setCurrentworduid: (currentWordUID: string) => dispatch(setCurrentworduid(currentWordUID)),
    setCurrentXMLFile: (f: string) => dispatch(setCurrentXMLFile(f)),
    setEndSelection: (time: Date | null) => dispatch(setEndSelection(time)),
    setNewwords: (currentWords: Proto.mediaarchiver.ISearchSpeechesWord[]) => dispatch(setNewwords(currentWords)),
    setRequestedCurrent: (params: Date) => dispatch(setRequestedCurrent(params)),
    setRequestedEnd: (params: Date) => dispatch(setRequestedEnd(params)),
    setRequestedMedia: (params: number) => dispatch(setRequestedMedia(params)),
    setRequestedStart: (params: Date) => dispatch(setRequestedStart(params)),
    setPlayer: (elem: IPlayer) => dispatch(setPlayer(elem)),
    setPlayerPosition: (t: Date) => dispatch(setPlayerPosition(t)),
    setSnapshot: (d: string | null) => dispatch(setSnapshot(d)),
    setStartSelection: (time: Date | null) => dispatch(setStartSelection(time)),
    setTimelineProgram: (res: NullableSpeechResult) => dispatch(setTimelineProgram(res)),
    setXMLError: (err: string) => dispatch(setXMLError(err)),
    showCopyDialog: () => dispatch(showCopyDialog()),
    showExtractionDialog: () => dispatch(showExtractionDialog()),
});

export const TimelineAnnotations = connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(TimelineAnnotationsComponent));
