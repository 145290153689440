export enum MailsActionTypes {
    BLOCK = '@@mails/BLOCK',
    GET = '@@mails/GET',
    SET_ERROR = '@@mails/SET_ERROR',
    SET_MAIL = '@@mails/SET_CONTACT',
    SET_OP_PROGRESS = '@@mails/SET_OP_PROGRESS',
    SET_OP_SUCCESS = '@@mails/SET_OP_SUCCESS'
}

export interface IMailFormErrors {
    general: string;
    mail: string;
}

export interface IMailState {
    readonly mail: string;
    readonly opSuccessEventElement: HTMLElement;
    readonly operationInProgress: boolean;
}

export interface IBlockAction {
    payload: [string, string];
    type: MailsActionTypes.BLOCK;
}

export interface IGetAction {
    payload: string;
    type: MailsActionTypes.GET;
}

export interface ISetErrorAction {
    payload: IMailFormErrors;
    type: MailsActionTypes.SET_ERROR;
}

export interface ISetOpSuccessAction {
    type: MailsActionTypes.SET_OP_SUCCESS;
}

export interface ISetProgressAction {
    payload: boolean;
    type: MailsActionTypes.SET_OP_PROGRESS;
}
