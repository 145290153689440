import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class DeleteAnnotation extends AMessage implements IToCommand {
    private annotation: Proto.mediaarchiver.IAnnotation;

    public constructor(annotation: Proto.mediaarchiver.IAnnotation) {
        super('deleteAnnotation');
        this.annotation = annotation;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            DeleteAnnotationArg: {
                ID: this.annotation.ID,
            },
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeDeleteAnnotation,
        }));
    }
}
