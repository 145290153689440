import { mediaarchiver } from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';
import { AMessage, IFromCommand } from './';

export class Reviews extends AMessage implements IFromCommand {
    private parsedCount: number | null = null;
    private parsedOffset: number | null = null;
    private parsedReviews: mediaarchiver.IReview[] | null = null;

    public constructor() {
        super('reviews');
    }

    public Parse({ ReviewsArg }: mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(ReviewsArg)) {
                return reject(new Error('Not a Archives message'));
            }
            if (isNullOrUndefined(ReviewsArg.Count)) {
                return reject(new Error('Invalid count'));
            }
            if (isNullOrUndefined(ReviewsArg.Offset)) {
                return reject(new Error('Invalid offset'));
            }
            if (isNullOrUndefined(ReviewsArg.Reviews)) {
                return reject(new Error('No archive list'));
            }
            this.parsedCount = ReviewsArg.Count;
            this.parsedOffset = ReviewsArg.Offset;
            this.parsedReviews = ReviewsArg.Reviews;
            resolve();
        });
    }

    public get count(): number {
        if (isNull(this.parsedCount)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedCount;
    }

    public get offset(): number {
        if (isNull(this.parsedOffset)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedOffset;
    }

    public get reviews(): mediaarchiver.IReview[] {
        if (isNull(this.parsedReviews)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedReviews;
    }
}
