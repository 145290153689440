import * as Proto from '../../Protos/protos';
import { IUser } from '../../Store/User/Types';
import { capitalize, initials } from '../../Utils/String';
import { isNullOrUndefined, isNullOrUndefinedOrZero } from '../../Utils/Various';
import { AMessage, IFromCommand } from './';

export class WhoYouAre extends AMessage implements IFromCommand {
    private user: IUser | null;

    public constructor() {
        super('whoYouAre');
        this.user = null;
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        if (msg.WhoYouAreArgs === null || msg.WhoYouAreArgs === undefined) {
            return Promise.reject(new Error('Not a WhoYouAre message'));
        }
        if (
            !msg.WhoYouAreArgs.LoggedIn &&
            !isNullOrUndefined(msg.WhoYouAreArgs.Identitiy) &&
            !!msg.WhoYouAreArgs.Identitiy.Share
        ) {
            this.user = this.protoUserToStoreUser(msg.WhoYouAreArgs.Identitiy as Proto.mediaarchiver.IUser);
            if (
                !isNullOrUndefined(msg.WhoYouAreArgs.Identitiy.ShareParams) &&
                !isNullOrUndefinedOrZero(msg.WhoYouAreArgs.Identitiy.ShareParams.Start) &&
                !isNullOrUndefinedOrZero(msg.WhoYouAreArgs.Identitiy.ShareParams.End) &&
                !isNullOrUndefinedOrZero(msg.WhoYouAreArgs.Identitiy.ShareParams.Media)
            ) {
                this.user.shareParams = {
                    active: true,
                    archiveTitle: msg.WhoYouAreArgs.Identitiy.ShareParams.ArchiveTitle || 'N/A',
                    calendar: {
                        duration: 2,
                        start: new Date(msg.WhoYouAreArgs.Identitiy.ShareParams.Start as number),
                        startWarning: '',
                    },
                    contentTypes: msg.WhoYouAreArgs.Identitiy.ShareParams.Types as Proto.mediaarchiver.EPGType[],
                    dataToken: msg.WhoYouAreArgs.Identitiy.ShareParams.DataToken || '',
                    end: new Date(msg.WhoYouAreArgs.Identitiy.ShareParams.End as number),
                    media: msg.WhoYouAreArgs.Identitiy.ShareParams.Media,
                    mediaName: msg.WhoYouAreArgs.Identitiy.ShareParams.MediaName || 'N/A',
                    mediaType: msg.WhoYouAreArgs.Identitiy.ShareParams.MediaType || 2,
                    playerStart: new Date(msg.WhoYouAreArgs.Identitiy.ShareParams.Start as number),
                    sender: msg.WhoYouAreArgs.Identitiy.ShareParams.Sender || 'N/A',
                    show: true,
                    start: new Date(msg.WhoYouAreArgs.Identitiy.ShareParams.Start as number),
                    streamerToken: msg.WhoYouAreArgs.Identitiy.ShareParams.StreamerToken || '',
                    timezone: 'Europe/Paris',
                    type: msg.WhoYouAreArgs.Identitiy.ShareParams.Type || 'archive',
                    incrementGenerate: 0,
                };
            }
            return Promise.resolve();
        }
        this.user =
            !msg.WhoYouAreArgs.LoggedIn ||
            msg.WhoYouAreArgs.LoggedIn === null ||
            (msg.WhoYouAreArgs.Identitiy as Proto.mediaarchiver.IUser).ID === 0
                ? null
                : this.protoUserToStoreUser(msg.WhoYouAreArgs.Identitiy as Proto.mediaarchiver.IUser);
        return Promise.resolve();
    }

    /**
     * Tells if user is logged in.
     */
    public LoggedIn(): boolean {
        return this.user !== null && this.user.loggedIn;
    }

    /**
     * Returns current is user is logged in, null elsewhere.
     */
    public User(): IUser | null {
        return this.user;
    }

    private protoUserToStoreUser(proto: Proto.mediaarchiver.IUser): IUser {
        return {
            allowedEPGs: proto.AllowedEPGTypes ? proto.AllowedEPGTypes : [],
            email: proto.Email ? proto.Email : '',
            firstName: proto.FirstName ? proto.FirstName : '',
            functionalities: proto.Functionalities ? proto.Functionalities : [],
            getFullName: () => this.getFullName(),
            getShortName: () => this.getShortName(),
            group: proto.Group ? proto.Group : 0,
            groupName: proto.GroupName ? proto.GroupName : '',
            id: proto.ID ? proto.ID : 0,
            lastName: proto.LastName ? proto.LastName : '',
            loggedIn: !!proto.LoggedIn,
            login: proto.Login ? proto.Login : '',
            options: proto.Options ? proto.Options : [],
            phone: proto.Phone ? proto.Phone : '',
            session: proto.SessionID ? proto.SessionID : '',
            share: proto.Share ? true : false,
            shareCount: proto.ShareParams && proto.ShareParams.ViewCount ? proto.ShareParams.ViewCount : 0,
            shareParams: null,
        };
    }

    private getFullName() {
        if (this.user === null) {
            return '';
        }
        return capitalize(this.user.firstName) + ' ' + this.user.lastName.toLocaleUpperCase();
    }

    private getShortName() {
        if (this.user === null) {
            return '';
        }
        return initials(this.user.firstName) + ' ' + this.user.lastName.toLocaleUpperCase();
    }
}
