import { Constants } from '../../constants';
import { ITimelineCriteriasState, IZoom } from './Types';

// Available zooms, from widest to closest, for the given number or seconds
export const GetZooms = (state: ITimelineCriteriasState): IZoom[] => {
    const seconds = (state.end.getTime() - state.start.getTime()) / 1000;
    const container = document.getElementById('scrollerContainer');
    let width = 0;

    if (container !== null) {
        width = container.getBoundingClientRect().width;
    }

    let zooms = GetAllZooms().filter((zoom) => {
        const spans = Math.ceil(seconds / zoom.spanLength);
        const neededWidth = spans * zoom.spanWidth;

        return (
            (
                (width === 0) ||
                (neededWidth >= width)
            ) &&
            (neededWidth <= Constants.timelineMaxWidth)
        );
    });

    zooms = (zooms.length > 0) ? zooms : GetAllZooms().slice(0, 1);
    zooms[0] = {
        ...zooms[0],
        isMin: true,
    };
    zooms[zooms.length - 1] = {
        ...zooms[zooms.length - 1],
        isMax: true,
    };
    return zooms;
};

// Get initial zoom
export const GetInitialZoom = (zooms: IZoom[]): IZoom => {
    const zoom: IZoom = {
        isDefault: false,
        isMax: false,
        isMin: false,
        spanLength: 1800,
        spanSubsections: 6,
        spanWidth: 180,
    };
    if (
        (!Array.isArray(zooms)) ||
        (zooms.length === 0)
    ) {
        return zoom;
    }
    return zooms.reduce(
        (prev: IZoom, curr: IZoom) => {
            if (!prev.isDefault && curr.isDefault) {
                return curr;
            }
            return prev;
        },
        zoom,
    );
};

// Available zooms, from widest to closest
export const GetAllZooms = (): IZoom[] => {
    return [
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 10800,
            spanSubsections: 3,
            spanWidth: 180,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 7200,
            spanSubsections: 4,
            spanWidth: 180,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 3600,
            spanSubsections: 6,
            spanWidth: 180,
        },
        {
            isDefault: true,
            isMax: false,
            isMin: false,
            spanLength: 1800,
            spanSubsections: 6,
            spanWidth: 180,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 900,
            spanSubsections: 3,
            spanWidth: 180,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 300,
            spanSubsections: 5,
            spanWidth: 300,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 60,
            spanSubsections: 2,
            spanWidth: 120,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 30,
            spanSubsections: 30,
            spanWidth: 240,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 10,
            spanSubsections: 10,
            spanWidth: 240,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 5,
            spanSubsections: 5,
            spanWidth: 240,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 1,
            spanSubsections: 10,
            spanWidth: 200,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 5,
            spanSubsections: 5,
            spanWidth: 300,
        },
        {
            isDefault: false,
            isMax: false,
            isMin: false,
            spanLength: 1,
            spanSubsections: 10,
            spanWidth: 100,
        },
    ];
};
