import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class PlayerManifestStats extends AMessage implements IToCommand {
    private stats: Proto.mediaarchiver.IPlayerStatsManifest;

    public constructor(stats: Proto.mediaarchiver.IPlayerStatsManifest) {
        super('playerManifestStats');
        this.stats = stats;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                PlayerManifestStatsArg: this.stats,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypePlayerManifestStats,
            }),
        );
    }
}
