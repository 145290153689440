import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../../Store';
import { hideAnnotations } from '../../../Store/Layout/Actions';
import { setHeight as setTimelineHeight } from '../../../Store/Timeline';
import TimelineContentComponent from './Content';
import styles from './style';

const mapStateToProps = ({
    i18n,
    layout,
    timeline,
    user,
    xml,
}: IApplicationState) => ({
    i18n: i18n.i18n,
    layout,
    localeInfos: i18n.localeInfos,
    timeline,
    user,
    xml,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hideAnnotations: () => dispatch(hideAnnotations()),
    setTimelineHeight: (height: number) => dispatch(setTimelineHeight(height)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(TimelineContentComponent));
