import { MediaTypes } from '../Store/Medias/Types';
import { PlayerStatus } from '../Store/Player/Constant';
import { mediaarchiver } from '../Protos/protos';
// import { PlayerClassic } from './Player.classic';
import { PlayerHLSJS } from './Player.hlsjs';
import { PlayerStreamer } from './Player.streamer';

export type IPlayerConstructor = new () => IPlayer;

export interface IPlayer {
    askFullscreen(): void;
    cancelPauseAt(): void;
    destroy(): void;
    getCurrentTime(): Date | null;
    getElem(): HTMLVideoElement;
    getSnapshot(): Promise<string>;
    getVolume(): number;
    onNewStats(
        cb: (manifests: mediaarchiver.IPlayerStatsManifest, chunks: mediaarchiver.IPlayerStatsChunks) => void,
    ): void;
    onStatus(cb: (status: PlayerStatus) => void): void;
    onTimeUpdate(cb: (time: Date) => void): void;
    pause(): void;
    replay(): void;
    resume(): void;
    seek(delta: number): void;
    setPlaybackSpeed(speed: number): void;
    setVolume(vol: number): void;
    start(start: Date, end?: Date, startStatus?: boolean): void;
}

export function getPlayer(
    container: HTMLElement,
    media: number,
    type: MediaTypes,
    start: Date,
    end: Date,
    token: string,
    tz: string,
): IPlayer {
    return new PlayerStreamer(container, media, type, start, end, token, tz);
}
