/* eslint-disable react/prop-types */
import * as MD from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Config } from '../config';
import { IConnectedReduxProps } from '../Store';
import { IAnalyticsState, init as initAnalytics, setConsent, setOKBug20220524 } from '../Store/Analytics';
import { I18N } from '../Store/I18n';
import { ILayoutState } from '../Store/Layout';
import { onCloseSnack, setSize } from '../Store/Layout/Actions';
import { isNullOrUndefined } from '../Utils/Various';
import { IApplicationState } from '../Store';
import { getTheme } from '../Themes';
import AppBar from './AppBar';
import Drawer from './Drawer';

const theme = getTheme();

const styles = MD.createStyles({
    container: {
        height: '100%',
    },
    content: {
        [theme.breakpoints.up('lg')]: {
            '&.noDrawer': {
                paddingLeft: '0px',
            },
            backgroundColor: 'red !importent',
            paddingLeft: theme.mediaarchiver.dimensions.drawerWidth,
            transition: 'padding-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
        },
        flexGrow: 1,
        height: `calc(100% - ${theme.mediaarchiver.dimensions.appBarHeight})`,
        overflow: 'hidden',
    },
    toolbar: theme.mixins.toolbar,
});

interface IPropsFromState {
    analytics: IAnalyticsState;
    i18n: I18N;
    layout: ILayoutState;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    ui: any;
    updateUI: (name: string, value: any) => void;
    /* eslint-enable @typescript-eslint/no-explicit-any */
}

interface IPropsFromDispatch {
    closeSnack: typeof onCloseSnack;
    initAnalytics: typeof initAnalytics;
    setConsent: typeof setConsent;
    setOKBug20220524: typeof setOKBug20220524;
    setSize: typeof setSize;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class LayoutComponent extends React.Component<AllProps> {
    private lastTitle = '';
    private lastURL = '';
    private resizeHandler: () => void;

    public constructor(props: AllProps) {
        super(props);
        this.resizeHandler = this.handlePageResize.bind(this);
    }

    public componentDidMount(): void {
        window.onresize = this.resizeHandler;
        this.props.initAnalytics();
    }

    public componentWillUnmount(): void {
        window.onresize = () => {
            /* */
        };
    }

    public render(): React.ReactNode {
        const { classes, layout } = this.props;

        this.handlePageTitle();
        this.handlePageView();
        return (
            <MD.Grid className={classes.container} container>
                {layout.showAppBar ? <Route component={AppBar} /> : ''}
                <Route component={Drawer} />
                <MD.Grid
                    item
                    className={
                        classes.content +
                        (!isNullOrUndefined(layout.drawerContent) && layout.showDrawer ? '' : ' noDrawer')
                    }
                    xs={12}
                >
                    {layout.pageContent}
                </MD.Grid>
                <MD.Snackbar
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                    autoHideDuration={2000}
                    message={<span>{layout.snackMessage}</span>}
                    onClose={() => this.props.closeSnack()}
                    open={layout.snackMessage !== ''}
                />
                <MD.Dialog open={this.props.analytics.shouldAskConsent}>
                    <MD.DialogTitle>
                        {this.props.i18n._('Informed Consent for anonymous statistics usage')}
                    </MD.DialogTitle>
                    <MD.DialogContent>
                        <MD.Typography>
                            {this.props.i18n._(
                                'Do you agree to send anonymous statistics about your usage of MediaArchiver ?',
                            )}
                        </MD.Typography>
                        <MD.Typography>
                            {this.props.i18n._('This will help us to understand usage and detect problems earlier.')}
                        </MD.Typography>
                        <MD.Typography>
                            {this.props.i18n._('You can opt-in or opt-out later in My Account > My Settings.')}
                        </MD.Typography>
                    </MD.DialogContent>
                    <MD.DialogActions>
                        <MD.Button
                            color='primary'
                            onClick={() => {
                                this.props.setConsent(false);
                            }}
                        >
                            {this.props.i18n._('I disagree')}
                        </MD.Button>
                        <MD.Button
                            color='secondary'
                            onClick={() => {
                                this.props.setConsent(true);
                            }}
                        >
                            {this.props.i18n._('I agree')}
                        </MD.Button>
                    </MD.DialogActions>
                </MD.Dialog>
                <MD.Dialog open={!this.props.analytics.shouldAskConsent && !this.props.analytics.okBug20220524}>
                    <MD.DialogTitle>{'Message important'}</MD.DialogTitle>
                    <MD.DialogContent>
                        <MD.Typography>
                            {
                                'Nous rencontrons actuellement un problème technique, Nos équipes sont mobilisées pour résoudre le problème.'
                            }
                        </MD.Typography>
                        <MD.Typography>{'Nous nous excusons pour la gène occasionnée.'}</MD.Typography>
                    </MD.DialogContent>
                    <MD.DialogActions>
                        <MD.Button
                            color='primary'
                            onClick={() => {
                                this.props.setOKBug20220524();
                            }}
                        >
                            {this.props.i18n._('OK')}
                        </MD.Button>
                    </MD.DialogActions>
                </MD.Dialog>
            </MD.Grid>
        );
    }

    private handlePageTitle() {
        if (this.props.layout.pageTitle === this.lastTitle) {
            return;
        }
        this.lastTitle = this.props.layout.pageTitle;
        document.title = this.props.layout.pageTitle;
    }

    private handlePageView() {
        if (document.location.pathname === this.lastURL) {
            return;
        }
        this.lastURL = document.location.pathname;
        gtag('config', Config.gtagID, { page_path: this.lastURL });
    }

    private handlePageResize(): void {
        let currentSize: Breakpoint = 'md';

        if (window.innerWidth >= 1280) {
            currentSize = 'lg';
        }
        if (window.innerWidth >= 1920) {
            currentSize = 'xl';
        }
        if (currentSize != this.props.layout.size) {
            this.props.setSize(currentSize);
        }
    }
}

const mapStateToProps = ({ analytics, i18n, layout }: IApplicationState) => ({
    analytics,
    i18n: i18n.i18n,
    layout,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeSnack: () => dispatch(onCloseSnack()),
    initAnalytics: () => dispatch(initAnalytics()),
    setConsent: (consent: boolean) => dispatch(setConsent(consent)),
    setOKBug20220524: () => dispatch(setOKBug20220524()),
    setSize: (size: Breakpoint) => dispatch(setSize(size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LayoutComponent));
