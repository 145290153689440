export function invertColor(hex: string, bw: boolean): string {
    const padZero = (str: string): string => {
        const len = 2;
        return (new Array(len).join('0') + str).slice(-len);
    };

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    if (bw) {
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    const rs = (255 - r).toString(16);
    const gs = (255 - g).toString(16);
    const bs = (255 - b).toString(16);
    return '#' + padZero(rs) + padZero(gs) + padZero(bs);
}

export function hexToRgb(hex: string): string {
    if (hex.length !== 7 && hex[0] !== '#') {
        return 'rgb(255, 0, 0)';
    }
    const bigint = parseInt(hex.substr(1), 16);

    if (isNaN(bigint)) {
        return 'rgb(255, 0, 0)';
    }

    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return 'rgb(' + r + ',' + g + ',' + b + ')';
}

export function hexToRgba(hex: string, alpha: string): string {
    if (hex.length !== 7 && hex[0] !== '#') {
        return 'rgba(255, 0, 0, 1)';
    }
    const bigint = parseInt(hex.substr(1), 16);

    if (isNaN(bigint)) {
        return 'rgba(255, 0, 0, 1)';
    }
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';
}
