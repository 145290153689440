import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../Store';
import { Locales, setLocale } from '../../Store/I18n';
import {
    setDrawerContent,
    setDrawerTitle,
    setHasAnnotations,
    setPageContent,
    setPageTitle,
} from '../../Store/Layout';
import { ArchivesPage } from './ArchivesPage';
import styles from './styles';

const mapStateToProps = ({ medias, i18n }: IApplicationState) => ({
    errors: medias.errors,
    i18n: i18n.i18n,
    loading: medias.loading,
    medias: medias.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadI18n: (locale: Locales) => dispatch(setLocale(locale)),
    setDrawerContent: (content: React.ReactNode) =>
        dispatch(setDrawerContent(content)),
    setDrawerTitle: (title: string) => dispatch(setDrawerTitle(title)),
    setHasAnnotations: (has: boolean) => dispatch(setHasAnnotations(has)),
    setPageContent: (content: React.ReactNode) =>
        dispatch(setPageContent(content)),
    setPageTitle: (title: string) => dispatch(setPageTitle(title)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(ArchivesPage));
