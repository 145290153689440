import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { ReactNode } from 'react';
import { action } from 'typesafe-actions';

import {
    IHideAnnotationsAction,
    IHideAppBarAction,
    IHideDrawerAction,
    IHideSnackAction,
    IOnCloseSnackAction,
    ISetDrawerContentAction,
    ISetDrawerTitleAction,
    ISetHasAnnotationsAction,
    ISetPageContentAction,
    ISetPageTitleAction,
    ISetSizeAction,
    ISetSnackMessageAction,
    ISetSnackMessageNowAction,
    IShowAnnotationsAction,
    IShowAppBarAction,
    IShowDrawerAction,
    IUpdateStackAction,
    LayoutActionTypes,
} from './Types';

export const hideAnnotations = (): IHideAnnotationsAction => action(LayoutActionTypes.ANNOTATIONS_HIDE);
export const hideAppBar = (): IHideAppBarAction => action(LayoutActionTypes.APPBAR_HIDE);
export const hideDrawer = (): IHideDrawerAction => action(LayoutActionTypes.DRAWER_HIDE);
export const hideSnack = (): IHideSnackAction => action(LayoutActionTypes.HIDE_SNACK);
export const onCloseSnack = (): IOnCloseSnackAction => action(LayoutActionTypes.ON_CLOSE_SNACK);
export const setDrawerContent = (content: ReactNode | null): ISetDrawerContentAction =>
    action(LayoutActionTypes.SET_DRAWER_CONTENT, content);
export const setDrawerTitle = (title: string): ISetDrawerTitleAction =>
    action(LayoutActionTypes.SET_DRAWER_TITLE, title);
export const setHasAnnotations = (set: boolean): ISetHasAnnotationsAction =>
    action(LayoutActionTypes.SET_HAS_ANNOTATIONS, set);
export const setPageContent = (content: ReactNode): ISetPageContentAction =>
    action(LayoutActionTypes.SET_PAGE_CONTENT, content);
export const setPageTitle = (title: string): ISetPageTitleAction => action(LayoutActionTypes.SET_PAGE_TITLE, title);
export const setSize = (size: Breakpoint): ISetSizeAction => action(LayoutActionTypes.SET_SIZE, size);
export const setSnackMessage = (message: string): ISetSnackMessageAction =>
    action(LayoutActionTypes.SET_SNACK_MESSAGE, message);
export const setSnackMessageNow = (message: string): ISetSnackMessageNowAction =>
    action(LayoutActionTypes.SET_SNACK_MESSAGE_NOW, message);
export const showAnnotations = (): IShowAnnotationsAction => action(LayoutActionTypes.ANNOTATIONS_SHOW);
export const showAppBar = (): IShowAppBarAction => action(LayoutActionTypes.APPBAR_SHOW);
export const showDrawer = (): IShowDrawerAction => action(LayoutActionTypes.DRAWER_SHOW);
export const updateStack = (stack: string[]): IUpdateStackAction => action(LayoutActionTypes.UPDATE_STACK, stack);
