import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../../Themes';

const theme = getTheme();

export default createStyles({
    audienceOptions: {
        cursor: 'pointer',
        position: 'absolute',
        right: 5,
        top: 3,
    },
    legendAudience: {
        flexDirection: 'column',
        height: '100px',
        justifyContent: 'center',
        paddingLeft: '0px !important',
        position: 'relative',
    },
    legendAudienceData: {
        '&>dd': {
            color: theme.palette.primary.light,
            margin: '0 0 0 42px',
            maxWidth: '70px',
            minWidth: '70px',
            padding: '0 0 0.5em 0',
        },
        '&>dt': {
            '&:first-child': {
                textDecorationLine: 'none',
            },
            clear: 'left',
            cursor: 'pointer',
            float: 'left',
            textAlign: 'right',
            textDecorationLine: 'underline',
            textDecorationStyle: 'dashed',
        },
        '&>dt::after': {
            content: ':',
        },

        color: theme.palette.primary.main,
        lineHeight: '0.6em',
        marginBottom: '0px',
    },
    legendItem: {
        '&:first-child': {
            borderTopWidth: '1px',
        },
        '&:last-child': {
            borderBottomWidth: '0px',
        },

        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        borderBottomWidth: '1px',
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        borderWidth: '0px',
        color: theme.palette.primary.main,
        display: 'flex',
        fontSize: '0.9em',
        overflow: 'hidden',
        paddingLeft: '10px',
        whiteSpace: 'nowrap',
        width: '120px',
    },
    radios: {
        margin: theme.spacing(1),
    },
});
