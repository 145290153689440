import { createStyles } from '@material-ui/core';
import { getTheme } from '../Themes';

const theme = getTheme();

export default createStyles({
    container: {
        fontFamily: theme.mediaarchiver.fonts.global,
        fontSize: theme.mediaarchiver.fonts.globalSize,
        userSelect: 'none',
    },
});
