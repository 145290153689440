import { Store } from 'redux';
import { effects } from 'redux-saga';

import { IToCommand } from '../../Backend/Commands';
import { CreateContact } from '../../Backend/Commands/CreateContact';
import { DeleteContact } from '../../Backend/Commands/DeleteContact';
import { EditContact } from '../../Backend/Commands/EditContact';
import { GetContacts } from '../../Backend/Commands/GetContacts';
import { GetContactsCount } from '../../Backend/Commands/GetContactsCount';

import { IApplicationState } from '..';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import { create, deleteContact, deletedContact, get, setErrors, setOpSuccess, setProgress } from './Actions';
import { ContactsActionTypes } from './Types';

function* handleGet(action: ReturnType<typeof get>) {
    const cmd = new GetContacts(action.payload.offset, action.payload.limit);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get contacts');
    }
}

function* handleGetCount() {
    const cmd = new GetContactsCount();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get contacts count');
    }
}

function* handleDelete(action: ReturnType<typeof deleteContact>) {
    const str = store as Store<IApplicationState>;
    const cmd = new DeleteContact(action.payload);

    yield effects.call(str.dispatch.bind(str, setProgress(true)));
    try {
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.call(str.dispatch.bind(str, deletedContact(action.payload)));
        yield effects.call(str.dispatch.bind(str, setOpSuccess()));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to delete contact');
    } finally {
        yield effects.call(str.dispatch.bind(str, setProgress(false)));
    }
}

function* handleOps(actionType: string, action: ReturnType<typeof create>) {
    const str = store as Store<IApplicationState>;
    let command: IToCommand;

    switch (actionType) {
        case 'create':
            command = new CreateContact(action.payload);
            break;
        case 'edit':
            command = new EditContact(action.payload);
            break;
        default:
            throw new Error('Unknown ops action type');
    }
    yield effects.call(str.dispatch.bind(str, setProgress(true)));
    try {
        yield effects.call(command.Send.bind(command));
        yield effects.call(str.dispatch.bind(str, setOpSuccess()));
    } catch (err) {
        try {
            const data = JSON.parse((err as Error).message);

            yield effects.call(
                str.dispatch.bind(
                    str,
                    setErrors({
                        email: data.email || '',
                        firstName: data.firstName || '',
                        general: data.general || '',
                        lastName: data.lastName || '',
                    }),
                ),
            );
        } catch (err2) {
            Logger.warn({ original: err, parsing: err2 }, 'Unable to parse error message');
            yield effects.call(
                str.dispatch.bind(
                    str,
                    setErrors({
                        email: '',
                        firstName: '',
                        general: 'N/A',
                        lastName: '',
                    }),
                ),
            );
        }
        Logger.warn(err as Error, 'Failed to create contact');
    }
    yield effects.call(str.dispatch.bind(str, setProgress(false)));
}

function* watchGet() {
    yield effects.takeLatest(ContactsActionTypes.GET, handleGet);
}

function* watchGetCount() {
    yield effects.takeLatest(ContactsActionTypes.GET_COUNT, handleGetCount);
}

function* watchCreate() {
    yield effects.takeLatest(ContactsActionTypes.CREATE, handleOps.bind(null, 'create'));
}

function* watchEdit() {
    yield effects.takeLatest(ContactsActionTypes.EDIT, handleOps.bind(null, 'edit'));
}

function* watchDelete() {
    yield effects.takeLatest(ContactsActionTypes.DELETE, handleDelete);
}

export function* ContactsSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchGet)]);
    yield effects.all([effects.fork(watchGetCount)]);
    yield effects.all([effects.fork(watchCreate)]);
    yield effects.all([effects.fork(watchEdit)]);
    yield effects.all([effects.fork(watchDelete)]);
}
