import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { RouteComponentProps } from 'react-router-dom';
import { Color, HEXColor, RGBColor, SketchPicker } from 'react-color';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { createTalk as trackCreate, editTalk as trackEdit } from '../../../Analytics/Events';
import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import { setSnackMessage } from '../../../Store/Layout';
import { IPlayerState, setEndSelection, setStartSelection } from '../../../Store/Player';
import { addTalk, getPerson, getProgramTypes, getStudies, hideCopyDialog } from '../../../Store/Talk/Actions';
import { ITalkState } from '../../../Store/Talk/Types';
import { center, ITimelineState } from '../../../Store/Timeline';
import { IUserState } from '../../../Store/User';
import { getTheme } from '../../../Themes';
import { formatDuration } from '../../../Utils/Time';
import { TypedStorage } from '../../../Utils/TypedStorage';
import {
    getDate,
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyArray,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../../Utils/Various';

const theme = getTheme();

const styles = MD.createStyles({
    blueLabels: {
        '& .MuiInputLabel-formControl': {
            color: '#00b0ff',
        },
    },
    customLink: {
        color: theme.palette.text.secondary,
        cursor: 'pointer',
    },
    datePickers: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dialogHeaderInfos: {
        '& strong': {
            fontWeight: 'bold',
        },

        fontSize: '0.8em',
        marginBottom: theme.spacing(3),
        paddingLeft: theme.spacing(1),
    },
    dialogImage: {
        height: 'auto',
        width: '100%',
    },
    fieldDescription: {
        fontSize: '2em',
    },
    personPicture: {
        maxHeight: 75,
        position: 'absolute',
        right: 20,
    },
    sketchPicker: {
        marginTop: 10,
    },
    sketchPickerColorBlock: {
        background: '#fff',
        borderRadius: 1,
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: theme.spacing(1),
        padding: 5,
    },
    sketchPickerColorBlockInside: {
        borderRadius: '2px',
        height: '14px',
        width: '36px',
    },
    sketchPickerCover: {
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
    },
    sketchPickerPopOver: {
        position: 'absolute',
        top: -100,
        zIndex: 2,
    },
    speakersList: {
        marginBottom: theme.spacing(2),
    },
    talkDialogAddLink: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1) * 3,
        marginTop: theme.spacing(1),
        textAlign: 'right',
    },
    talkDialogContent: {
        minWidth: 600,
    },
    talkDialogFormElement: {
        marginTop: theme.spacing(1),
    },
    talkDialogLoading: {
        height: '20px !important',
        width: '20px !important',
    },
    timeInputs: {
        width: 80,
    },
});

interface IErrorsState {
    programEnd: string;
    programName: string;
    programStart: string;
    programType: string;
}

interface IState {
    errors: IErrorsState;
    programEnd: Date | null;
    programEndString: string;
    programName: string;
    programStart: Date | null;
    programStartString: string;
    programType: number;
    programTypeCustom: boolean;
    programTypeCustomName: string;
}

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    player: IPlayerState;
    talk: ITalkState;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    addTalk: typeof addTalk;
    center: typeof center;
    getPerson: typeof getPerson;
    getProgramTypes: typeof getProgramTypes;
    getStudies: typeof getStudies;
    hideCopyDialog: typeof hideCopyDialog;
    setEndSelection: typeof setEndSelection;
    setSnackMessage: typeof setSnackMessage;
    setStartSelection: typeof setStartSelection;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

class CopyTalkDialogComponent extends React.Component<AllProps, IState> {
    private parsedStart: Date | null = null;
    private parsedEnd: Date | null = null;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getInitialState();
    }

    public render(): React.ReactNode {
        return <div>{this.renderTalkDialog()}</div>;
    }

    public componentDidMount() {
        this.props.getProgramTypes();
        this.props.getStudies();
    }

    private renderTalkDialog() {
        return (
            <MD.Dialog className={this.props.classes.blueLabels} maxWidth='sm' open={this.props.talk.showCopyDialog}>
                <MD.DialogTitle>
                    {this.props.i18n.pgettext('Politic', 'Copy talk times to another program')}
                </MD.DialogTitle>
                <MD.DialogContent className={this.props.classes.talkDialogContent}>
                    <MD.Box>
                        <MD.Grid container>{this.generateCopyTalkProgram()}</MD.Grid>
                    </MD.Box>
                </MD.DialogContent>
                <MD.DialogActions>
                    <MD.Button
                        color='primary'
                        onClick={() => {
                            this.props.hideCopyDialog();
                        }}
                    >
                        {this.props.i18n._('Cancel')}
                    </MD.Button>
                    {this.props.talk.loading ? (
                        <MD.CircularProgress className={this.props.classes.talkDialogLoading} />
                    ) : (
                        <MD.Button
                            color='secondary'
                            onClick={() => {
                                this.handleSubmitCopyTalkDialog();
                            }}
                        >
                            {this.props.i18n.pgettext('Politic', 'Copy times')}
                        </MD.Button>
                    )}
                </MD.DialogActions>
            </MD.Dialog>
        );
    }

    private generateCopyTalkProgram() {
        return (
            <div>
                <MD.FormControl fullWidth={true}>
                    <MD.TextField
                        error={this.state.errors.programName !== ''}
                        helperText={this.state.errors.programName}
                        label={this.props.i18n.pgettext('Politic', 'Program name')}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                            this.setState({
                                ...this.state,
                                programName: ev.target.value,
                            });
                        }}
                        value={this.state.programName}
                    />
                </MD.FormControl>
                <MD.FormControl fullWidth={true}>
                    {this.state.programTypeCustom ? (
                        <MD.TextField
                            error={this.state.errors.programType !== ''}
                            fullWidth={true}
                            helperText={this.state.errors.programType}
                            label={this.props.i18n.pgettext('Politic', 'New program type name')}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    ...this.state,
                                    programTypeCustomName: ev.target.value,
                                });
                            }}
                            value={this.state.programTypeCustomName}
                        />
                    ) : (
                        <>
                            <MD.InputLabel htmlFor='chooseProgramType'>
                                {this.props.i18n.pgettext('Politic', 'Choose a program type')}
                            </MD.InputLabel>
                            <MD.Select
                                error={this.state.errors.programType !== ''}
                                inputProps={{
                                    id: 'chooseProgramType',
                                    name: 'chooseProgramType',
                                }}
                                onChange={this.handleProgramTypeChange.bind(this)}
                                value={
                                    this.props.talk.programTypes.some(
                                        (t: mediaarchiver.ITalkProgramType) => t.ID === this.state.programType,
                                    )
                                        ? this.state.programType
                                        : this.props.talk.programTypes.length > 0
                                        ? (this.props.talk.programTypes[0].ID as number)
                                        : 0
                                }
                            >
                                {this.props.talk.programTypes.length === 0 ? (
                                    <MD.MenuItem disabled={true} key={'programType_none'} value={0}>
                                        {this.props.i18n.pgettext('Politic', 'No program type, please create one')}
                                    </MD.MenuItem>
                                ) : (
                                    this.props.talk.programTypes
                                        .map((t: mediaarchiver.ITalkProgramType, i: number) => {
                                            if (
                                                isNullOrUndefinedOrZero(t.ID) ||
                                                isNullOrUndefinedOrEmptyString(t.Name)
                                            ) {
                                                return null;
                                            }
                                            return (
                                                <MD.MenuItem key={`programType_${i}`} value={t.ID}>
                                                    {t.Name}
                                                </MD.MenuItem>
                                            );
                                        })
                                        .filter((i) => !isNullOrUndefined(i))
                                )}
                            </MD.Select>
                        </>
                    )}
                </MD.FormControl>
                <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                    <MD.Link
                        className={this.props.classes.customLink}
                        onClick={() => {
                            if (this.state.programTypeCustom) {
                                this.setState({
                                    ...this.state,
                                    programType: TypedStorage.get('lastTalkProgramType', 0),
                                    programTypeCustom: false,
                                    programTypeCustomName: '',
                                });
                                return;
                            }
                            this.setState({
                                ...this.state,
                                programType: 0,
                                programTypeCustom: true,
                                programTypeCustomName: '',
                            });
                        }}
                    >
                        {this.state.programTypeCustom
                            ? this.props.i18n._('Cancel')
                            : this.props.i18n.pgettext('Politic', 'Add a new program type')}
                    </MD.Link>
                </MD.FormControl>
                <>
                    <MD.FormControl>
                        <MD.TextField
                            className={this.props.classes.datePickers}
                            error={this.state.errors.programStart !== ''}
                            helperText={this.state.errors.programStart}
                            label={this.props.i18n.pgettext('Politic', 'Program start')}
                            onBlur={() => {
                                let val = '';
                                if (!isNull(this.parsedStart)) {
                                    val = moment(this.parsedStart).format(this.props.localeInfos.formatShortDateTime);
                                }
                                this.setState({
                                    programStart: this.parsedStart,
                                    programStartString: val,
                                });
                            }}
                            InputProps={{
                                inputComponent: (props) => {
                                    const { inputRef, ...other } = props;

                                    return (
                                        <MaskedInput
                                            {...other}
                                            ref={(ref) => {
                                                inputRef(ref ? ref.inputElement : null);
                                            }}
                                            mask={[
                                                /[0-3]/,
                                                /\d/,
                                                '/',
                                                /[0-1]/,
                                                /\d/,
                                                '/',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                ' ',
                                                /[0-2]/,
                                                /[0-9]/,
                                                ':',
                                                /[0-5]/,
                                                /[0-9]/,
                                            ]}
                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                if (ev.target.value.indexOf('_') === -1) {
                                                    this.parsedStart = moment(
                                                        ev.target.value,
                                                        this.props.localeInfos.formatShortDateTime,
                                                    ).toDate();
                                                }
                                            }}
                                            value={this.state.programStartString}
                                        />
                                    );
                                },
                            }}
                        />
                    </MD.FormControl>
                    <MD.FormControl>
                        <MD.TextField
                            className={this.props.classes.datePickers}
                            error={this.state.errors.programEnd !== ''}
                            helperText={this.state.errors.programEnd}
                            label={this.props.i18n.pgettext('Politic', 'Program end')}
                            onBlur={() => {
                                let val = '';
                                if (!isNull(this.parsedEnd)) {
                                    val = moment(this.parsedEnd).format(this.props.localeInfos.formatShortDateTime);
                                }
                                this.setState({
                                    programEnd: this.parsedEnd,
                                    programEndString: val,
                                });
                            }}
                            InputProps={{
                                inputComponent: (props) => {
                                    const { inputRef, ...other } = props;

                                    return (
                                        <MaskedInput
                                            {...other}
                                            ref={(ref) => {
                                                inputRef(ref ? ref.inputElement : null);
                                            }}
                                            mask={[
                                                /[0-3]/,
                                                /\d/,
                                                '/',
                                                /[0-1]/,
                                                /\d/,
                                                '/',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                ' ',
                                                /[0-2]/,
                                                /[0-9]/,
                                                ':',
                                                /[0-5]/,
                                                /[0-9]/,
                                            ]}
                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                if (ev.target.value.indexOf('_') === -1) {
                                                    this.parsedEnd = moment(
                                                        ev.target.value,
                                                        this.props.localeInfos.formatShortDateTime,
                                                    ).toDate();
                                                }
                                            }}
                                            value={this.state.programEndString}
                                        />
                                    );
                                },
                            }}
                        />
                    </MD.FormControl>
                </>
            </div>
        );
    }

    private handleProgramTypeChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        let programType = parseInt(ev.target.value as string, 10);

        if (!this.props.talk.programTypes.some((i: mediaarchiver.ITalkProgramType) => i.ID === programType)) {
            programType =
                this.props.talk.programTypes.length === 0 ? 0 : (this.props.talk.programTypes[0].ID as number);
        }
        TypedStorage.set('lastTalkProgramType', programType), this.setState({ programType });
    }

    private handleSubmitCopyTalkDialog() {
        const state = { ...this.state };
        const firstTalk = this.props.talk.copiedTalks[0];

        const talk: mediaarchiver.ITalk = {
            Company: this.props.user.user.group,
            District: firstTalk.District,
            Media: firstTalk.Media,
            Owner: this.props.user.user.id,
            OwnerName: this.props.user.user.getShortName(),
            Study: firstTalk.Study,
            TalkType: mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED,
            TimeType: mediaarchiver.TalkTimeType.TALK_TIME_TYPE_BROADCAST,
        };
        let ok = true;

        state.errors = this.getInitialState().errors;

        if (this.state.programName === '') {
            state.errors.programName = this.props.i18n.pgettext('Politic', 'Please set the program name');
            ok = false;
        } else {
            talk.ProgramName = this.state.programName;
        }

        if (this.state.programTypeCustom) {
            if (this.state.programTypeCustomName.trim() === '') {
                state.errors.programType = this.props.i18n.pgettext('Politic', 'Please set the program type name');
                ok = false;
            } else {
                talk.ProgramType = 0;
                talk.ProgramTypeCustom = this.state.programTypeCustomName.trim();
            }
        } else {
            let code = '';
            if (
                this.props.talk.programTypes.some((t: mediaarchiver.ITalkProgramType) => {
                    if (
                        !isNullOrUndefinedOrZero(this.state.programType) &&
                        !isNullOrUndefinedOrZero(t.ID) &&
                        t.ID === this.state.programType
                    ) {
                        code = t.Code || '';
                        return true;
                    }
                })
            ) {
                talk.ProgramType = this.state.programType;
                talk.ProgramTypeCode = code;
            } else if (this.props.talk.programTypes.length > 0) {
                talk.ProgramType = this.props.talk.programTypes[0].ID as number;
                talk.ProgramTypeCode = this.props.talk.programTypes[0].Code || '';
            } else {
                state.errors.programType = this.props.i18n.pgettext('Politic', 'Please set the program type');
                ok = false;
            }
        }

        if (isNull(this.parsedStart) || isNull(this.parsedEnd)) {
            if (isNull(this.parsedStart)) {
                state.errors.programStart = this.props.i18n.pgettext('Politic', 'Program start must be set');
                ok = false;
            }
            if (isNull(this.parsedEnd)) {
                state.errors.programEnd = this.props.i18n.pgettext('Politic', 'Program end must be set');
                ok = false;
            }
        } else if (this.parsedStart.getTime() >= this.parsedEnd.getTime()) {
            state.errors.programEnd = this.props.i18n.pgettext('Politic', 'End date cannot be before start date');
            ok = false;
        } else {
            talk.ProgramStart = this.parsedStart.getTime();
            talk.ProgramEnd = this.parsedEnd.getTime();
        }

        this.setState(state);
        if (!ok) {
            return;
        }

        const speakers: mediaarchiver.ITalkSpeaker[] = [];

        this.props.talk.copiedTalks.forEach((t) => {
            if (!isNullOrUndefined(t.Speakers)) {
                t.Speakers.forEach((s) => {
                    if (
                        !speakers.some((ss) => {
                            if (ss.Name === s.Name && !isNullOrUndefinedOrZero(s.DurationMS)) {
                                ss.DurationMS = (ss.DurationMS || 0) + (s.DurationMS as number);
                                return true;
                            }
                            return false;
                        })
                    ) {
                        s.Start = talk.ProgramStart;
                        speakers.push(s);
                    }
                });
            }
        });
        talk.Speakers = speakers;
        trackCreate();
        this.props.addTalk(talk);
    }

    private getInitialState(): IState {
        return {
            errors: {
                programEnd: '',
                programName: '',
                programStart: '',
                programType: '',
            },
            programEnd: null,
            programEndString: '',
            programName: '',
            programStart: null,
            programStartString: '',
            programType: TypedStorage.get('lastTalkProgramType', 0),
            programTypeCustom: false,
            programTypeCustomName: '',
        };
    }
}

const mapStateToProps = ({ i18n, player, talk, timeline, user }: IApplicationState) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    player,
    talk,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addTalk: (talk: mediaarchiver.ITalk) => dispatch(addTalk(talk)),
    center: () => dispatch(center()),
    getPerson: (id: string) => dispatch(getPerson(id)),
    getProgramTypes: () => dispatch(getProgramTypes()),
    getStudies: () => dispatch(getStudies()),
    hideCopyDialog: () => dispatch(hideCopyDialog()),
    setEndSelection: (time: Date | null) => dispatch(setEndSelection(time)),
    setSnackMessage: (msg: string) => dispatch(setSnackMessage(msg)),
    setStartSelection: (time: Date | null) => dispatch(setStartSelection(time)),
});

export const CopyTalkDialog = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(CopyTalkDialogComponent));
