import { AMessage, IFromCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class Mail extends AMessage implements IFromCommand {
    private parsedHTML: string | null = null;

    public constructor() {
        super('mail');
    }

    public Parse({ MailArg }: mediaarchiver.WSMessage): Promise<void> {
        return new Promise(
            (resolve: () => void, reject: (err: Error) => void): void => {
                if (isNullOrUndefined(MailArg)) {
                    return reject(new Error('Not a mail message'));
                }
                this.parsedHTML = MailArg;
                resolve();
            },
        );
    }

    public get html(): string {
        if (isNull(this.parsedHTML)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedHTML;
    }
}
