import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { ITimelinePopoversState } from '../../../Store/TimelinePopovers/Types';
import { getResultProcessedCategoryColor } from '../../../Themes';
import { limitText } from '../../../Utils/String';
import { isNullOrUndefined } from '../../../Utils/Various';

interface IPropsFromState {
    timelinePopovers: ITimelinePopoversState;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

const styles = MD.createStyles({
    popover: {
        display: 'none',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 2000,
    },
    popoverContent: {
        '& h5': {
            fontSize: '1.5em', //'1em',
        },
        '& h6': {
            fontSize: '1.2em', //'0.7em',
            fontStyle: 'italic',
            marginTop: 5,
            textTransform: 'uppercase',
        },
        '& p': {
            '& span': {
                fontStyle: 'italic',
            },
            fontSize: '0.7em',
        },
        '& p:first-of-type': {
            marginTop: 10,
        },
        flex: '1 0 auto',
        wordWrap: 'break-word',
    },
    popoverDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 340,
    },
});

export class PopoverResultProcessedComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        return (
            <MD.Card className={this.props.classes.popover} id='processedPopover'>
                <div className={this.props.classes.popoverDetails}>
                    <MD.CardContent className={this.props.classes.popoverContent}>
                        <MD.Typography component='h5' variant='h5'>
                            {limitText(
                                this.props.timelinePopovers.popoverResultProcessed
                                    ? this.props.timelinePopovers.popoverResultProcessed.title
                                    : '',
                                150,
                            )}
                        </MD.Typography>
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.categoryText ? (
                            <MD.Typography
                                component='h6'
                                style={{
                                    color: getResultProcessedCategoryColor(this.props.timelinePopovers.popoverResultProcessed.category),
                                }}
                                variant='h6'
                            >
                                {limitText(
                                    this.props.timelinePopovers.popoverResultProcessed
                                        ? this.props.timelinePopovers.popoverResultProcessed.categoryText
                                        : '',
                                    150,
                                )}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['end'] ? (
                            <MD.Typography component='p'>
                                <span>end</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['end']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['duration'] ? (
                            <MD.Typography component='p'>
                                <span>duration</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['duration']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['fingerprintid'] ? (
                            <MD.Typography component='p'>
                                <span>fingerprintid</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['fingerprintid']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['fingerprintlink'] ? (
                            <MD.Typography component='p'>
                                <span>fingerprintlink</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['fingerprintlink']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['id'] ? (
                            <MD.Typography component='p'>
                                <span>id</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['id']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['idrequestreco'] ? (
                            <MD.Typography component='p'>
                                <span>idrequestreco</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['idrequestreco']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['media_id'] ? (
                            <MD.Typography component='p'>
                                <span>media_id</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['media_id']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['media_link'] ? (
                            <MD.Typography component='p'>
                                <span>media_link</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['media_link']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['start'] ? (
                            <MD.Typography component='p'>
                                <span>start</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['start']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['stateid'] ? (
                            <MD.Typography component='p'>
                                <span>stateid</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['stateid']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['statelink'] ? (
                            <MD.Typography component='p'>
                                <span>statelink</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['statelink']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverResultProcessed &&
                        this.props.timelinePopovers.popoverResultProcessed.metas &&
                        this.props.timelinePopovers.popoverResultProcessed.metas['url'] ? (
                            <MD.Typography component='p'>
                                <span>url</span> : &nbsp;
                                {this.props.timelinePopovers.popoverResultProcessed.metas['url']}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                    </MD.CardContent>
                </div>
            </MD.Card>
        );
    }
}

const mapStateToProps = ({ timelinePopovers }: IApplicationState) => ({
    timelinePopovers,
});

export const PopoverResultProcessed = connect(mapStateToProps)(MD.withStyles(styles)(PopoverResultProcessedComponent));
