export interface IConstants {
    // RFC6381 codec string used by chunks
    chunksCodec: string;
    // Chunks framerate
    chunksFramerate: number;
    // Chunks length in ms
    chunksLength: number;
    // Chunks download part size
    chunksPartsSize: number;
    // Hyperion start date timestamp
    hyperionStartDate: number;
    // Minimum date
    minDate: Date;
    // Minimum date for WeTV
    minDateWeTV: Date;
    // List of public paths (/word matches /word /word/ /word/foo etc.)
    publicPaths: string[];
    // Timeline max width
    timelineMaxWidth: number;
}

export const Constants: IConstants = {
    chunksCodec: 'video/mp4; codecs="avc1.42C015,mp4a.40.2"',
    chunksFramerate: 25,
    chunksLength: (5 * 60 * 1000),
    chunksPartsSize: 500000,
    hyperionStartDate: 1576774800000,
    minDate: new Date(2013, 5, 1, 0, 0, 0),
    minDateWeTV: new Date(2014, 3, 16, 0, 0, 0),
    publicPaths: [
        '/mail',
        '/preventMail',
        '/share',
    ],
    timelineMaxWidth: 1000000,
};

export function isPublicPath(): boolean {
    return Constants.publicPaths.some((path) =>
        (window.location.pathname.length >= path.length) &&
        (window.location.pathname.substr(0, path.length) === path),
    );
}
