import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IPlayer } from '../../../Player/Player';
import { IApplicationState } from '../../../Store';
import {
    PlayerStatus,
    IPlayerStats,
    sendStats,
    setEndSelection,
    setPlayer,
    setPosition,
    setSnapshot,
    setStartSelection,
    setStatus,
} from '../../../Store/Player';
import TimelinePlayerComponent from './Player';
import styles from './styles';

const mapStateToProps = ({
    annotationTypes,
    archives,
    contacts,
    ftpAccounts,
    i18n,
    player,
    reviews,
    sftpAccounts,
    timeline,
    user,
}: IApplicationState) => ({
    annotationTypes,
    archives,
    contacts,
    ftpAccounts,
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    player,
    reviews,
    sftpAccounts,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    sendStats: (stats: IPlayerStats) => dispatch(sendStats(stats)),
    setEndSelection: (time: Date | null) => dispatch(setEndSelection(time)),
    setPlayer: (player: IPlayer) => dispatch(setPlayer(player)),
    setPosition: (time: Date) => dispatch(setPosition(time)),
    setSnapshot: (data: string | null) => dispatch(setSnapshot(data)),
    setStartSelection: (time: Date | null) => dispatch(setStartSelection(time)),
    setStatus: (status: PlayerStatus) => dispatch(setStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TimelinePlayerComponent));
