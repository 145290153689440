import { Store } from 'redux';
import { action } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { Mail } from '../../Backend/Commands/Mail';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import {
    IMailFormErrors,
    MailsActionTypes,
    IBlockAction,
    IGetAction,
    ISetErrorAction,
    ISetOpSuccessAction,
    ISetProgressAction,
} from './Types';

export const block = (mail: string, emailReason: string): IBlockAction =>
    action(MailsActionTypes.BLOCK, [mail, emailReason]);
export const get = (id: string): IGetAction => action(MailsActionTypes.GET, id);
export const setError = (error: IMailFormErrors): ISetErrorAction => action(MailsActionTypes.SET_ERROR, error);
export const setOpSuccess = (): ISetOpSuccessAction => action(MailsActionTypes.SET_OP_SUCCESS);
export const setProgress = (toggle: boolean): ISetProgressAction => action(MailsActionTypes.SET_OP_PROGRESS, toggle);

Backend.getInstance().Bind('mail', (incoming: IFromCommand) => {
    const cmd = incoming as Mail;

    Logger.trace({ cmd }, 'Received mail');
    (store as Store).dispatch(action(MailsActionTypes.SET_MAIL, cmd.html));
});
