import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class Talks extends AMessage implements IFromCommand {
    private parsedCount: number | null = null;
    private parsedOffset: number | null = null;
    private parsedTalks: Proto.mediaarchiver.ITalk[] | null = null;

    public constructor() {
        super('talks');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.TalksArg)) {
                return reject(new Error('Not a Talks message'));
            }
            if (isNullOrUndefined(msg.TalksArg.Count)) {
                return reject(new Error('Invalid count'));
            }
            if (isNullOrUndefined(msg.TalksArg.Offset)) {
                return reject(new Error('Invalid offset'));
            }
            if (isNullOrUndefined(msg.TalksArg.Talks)) {
                return reject(new Error('No talk list'));
            }
            this.parsedCount = msg.TalksArg.Count;
            this.parsedOffset = msg.TalksArg.Offset;
            this.parsedTalks = msg.TalksArg.Talks;
            resolve();
        });
    }

    public get count(): number {
        if (isNull(this.parsedCount)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedCount;
    }

    public get offset(): number {
        if (isNull(this.parsedOffset)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedOffset;
    }

    public get talks(): Proto.mediaarchiver.ITalk[] {
        if (isNull(this.parsedTalks)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedTalks;
    }
}
