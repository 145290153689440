import * as FA from '@fortawesome/pro-solid-svg-icons';
import * as Icons from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Constants } from '../../constants';
import * as Proto from '../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos } from '../../Store/I18n';
import {
    getPanels,
    getTalkMedias,
    searchKinds,
    searchKindsReset,
    update as getMedias,
} from '../../Store/Medias/Actions';
import { IMediasState } from '../../Store/Medias/Types';
import { clear as clearSearch, search as doSearch } from '../../Store/Search/Actions';
import { ISearchParams, ISearchState } from '../../Store/Search/Types';
import { IUserState } from '../../Store/User/Types';
import { showReportBug } from '../../Store/Timeline';
import { IIncidentState, showIncident } from '../../Store/Incident';
import { getTheme } from '../../Themes';
import {
    getDate,
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyArray,
    isNullOrUndefinedOrEmptyString,
} from '../../Utils/Various';
import { MediaList } from '../Medias/List';

const theme = getTheme();

export const styles = MD.createStyles({
    datePickers: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    datePickersError: {
        marginLeft: theme.spacing(1),
    },
    formControl: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '100%',
    },
    kindChips: {
        '& .MuiChip-root': {
            marginBottom: theme.spacing(0.5),
            marginLeft: theme.spacing(1),
            maxWidth: '90%',
        },

        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
    },
    kindWarning: {
        textAlign: 'center',
    },
    root: {},
    searchButton: {
        marginTop: theme.spacing(1) * 3,
    },
    searchButtonContainer: {
        textAlign: 'center',
    },
    searchButtonDisabled: {
        '& a': {
            cursor: 'not-allowed',
            opacity: 0.5,
        },
    },
    searchIcon: {
        marginRight: theme.spacing(1),
    },
    title: {
        marginBottom: 0,
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
    updateIcon: {
        marginRight: theme.spacing(1),
    },
});

interface IStateErrors {
    date: string;
}

interface IStateKindsProposition {
    code: string;
    label: string;
}

interface IState {
    end: Date;
    errors: IStateErrors;
    kinds: [string, string, string];
    selectedKinds: IStateKindsProposition[];
    selectedMedias: number[];
    selectedText: string;
    start: Date;
}

interface IPropsFromState {
    i18n: I18N;
    incident: IIncidentState;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
    search: ISearchState;
    user: IUserState;
}

interface IPropsFromDispatch {
    clearSearch: typeof clearSearch;
    doSearch: typeof doSearch;
    getMedias: typeof getMedias;
    getPanels: typeof getPanels;
    getTalkMedias: typeof getTalkMedias;
    searchKinds: typeof searchKinds;
    searchKindsReset: typeof searchKindsReset;
    showIncident: typeof showIncident;
    showReportBug: typeof showReportBug;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class SearchDrawerComponent extends React.Component<AllProps, IState> {
    private searchTimer = -1;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
    }

    public componentDidMount(): void {
        this.props.searchKinds('');
        this.props.getMedias();
        this.props.getPanels();
        this.props.getTalkMedias();
        if (!isNull(this.props.search.lastSearch)) {
            window.setTimeout(() => {
                if (!isNull(this.props.search.lastSearch)) {
                    this.props.doSearch(this.props.search.lastSearch);
                }
            }, 1000);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearSearch();
    }

    public render(): React.ReactNode {
        const { classes } = this.props;
        const searchClasses = classNames({
            [classes.searchButtonContainer]: true,
            [classes.searchButtonDisabled]: !this.isSearchReady(),
        });
        const minDate = Constants.minDateWeTV;

        return (
            <MD.Grid className={classes.root} container>
                <MD.Grid item xs={12}>
                    <MD.Typography className={classes.title} variant='h6'>
                        {this.props.i18n._('Date range')}
                    </MD.Typography>
                </MD.Grid>
                <MD.Grid item xs={6}>
                    <DatePicker
                        autoOk={true}
                        className={classes.datePickers}
                        disableToolbar
                        error={this.state.errors.date !== ''}
                        format={this.props.localeInfos.formatShortDate}
                        // keyboardIcon={<FontAwesomeIcon icon={FA.faKeyboard} />}
                        label={this.props.i18n._('Start date')}
                        leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                        // locale={this.props.localeInfos.momentLocale}
                        maxDate={moment().endOf('day')}
                        minDate={minDate}
                        onChange={this.onStartChange.bind(this)}
                        rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                        value={this.state.start}
                        variant='inline'
                    />
                </MD.Grid>
                <MD.Grid item xs={6}>
                    <DatePicker
                        autoOk={true}
                        className={classes.datePickers}
                        disableToolbar
                        error={this.state.errors.date !== ''}
                        format={this.props.localeInfos.formatShortDate}
                        // keyboardIcon={<FontAwesomeIcon icon={FA.faKeyboard} />}
                        label={this.props.i18n._('End date')}
                        leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                        // locale={this.props.localeInfos.momentLocale}
                        maxDate={moment().endOf('day')}
                        onChange={this.onEndChange.bind(this)}
                        rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                        value={this.state.end}
                        variant='inline'
                    />
                </MD.Grid>
                {this.state.errors.date !== '' ? (
                    <MD.Grid item xs={12}>
                        <MD.Typography className={classes.datePickersError} color='error' variant='body2'>
                            {this.state.errors.date}
                        </MD.Typography>
                    </MD.Grid>
                ) : (
                    ''
                )}
                <MD.Grid item xs={12}>
                    <MD.Typography className={classes.title} variant='h6'>
                        {this.props.i18n._('Media')}
                    </MD.Typography>
                </MD.Grid>
                <MD.Grid item xs={12}>
                    <Route
                        render={(props) => (
                            <MediaList
                                {...props}
                                dispatch={this.props.dispatch}
                                initialMedias={this.state.selectedMedias}
                                mediaTypes={[2]}
                                onChange={(selectedMedias: number[]) => {
                                    this.setState({ selectedMedias });
                                }}
                                single={true}
                                startEmpty={true}
                            />
                        )}
                    />
                </MD.Grid>
                {this.buildSearchKind()}
                <MD.Grid className={classes.kindChips} item xs={12}>
                    {this.state.selectedKinds.map((kind: IStateKindsProposition) => {
                        return (
                            <MD.Chip
                                key={`sleectedKind_${kind.code}`}
                                label={kind.label}
                                onDelete={() => {
                                    const kinds = this.state.selectedKinds.filter(
                                        (k: IStateKindsProposition) => k.code !== kind.code,
                                    );

                                    this.setState({ selectedKinds: kinds });
                                }}
                            />
                        );
                    })}
                </MD.Grid>
                <MD.Grid item xs={12}>
                    <MD.Typography className={classes.title} variant='h6'>
                        {this.props.i18n._('Title / Description')}
                    </MD.Typography>
                </MD.Grid>
                <MD.Grid item xs={12}>
                    <MD.FormControl className={this.props.classes.formControl}>
                        <MD.TextField
                            fullWidth
                            helperText={this.props.i18n._('Enter word(s) to find in title or description')}
                            margin='none'
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({ selectedText: ev.target.value });
                            }}
                            value={this.state.selectedText}
                        />
                    </MD.FormControl>
                </MD.Grid>
                <MD.Grid item xs={12}>
                    <div className={searchClasses}>
                        <MD.Fab
                            className={classes.searchButton}
                            component='a'
                            onClick={this.onSearch.bind(this)}
                            title={this.props.i18n._('Search')}
                            variant='extended'
                        >
                            <FontAwesomeIcon className={classes.searchIcon} icon={FA.faSearch} />
                            {this.props.i18n._('Search')}
                        </MD.Fab>
                        <MD.Button
                            component='p'
                            onClick={() => this.props.showReportBug()}
                            style={{
                                cursor: 'pointer !important',
                                opacity: '1 !important',
                            }}
                            variant='text'
                        >
                            <FontAwesomeIcon icon={Icons.faExclamationTriangle} className={classes.updateIcon} />
                            Signaler un problème
                        </MD.Button>
                        {!isNullOrUndefinedOrEmptyArray(this.props.incident.incidentContent.incidentMessages) ? (
                            <MD.Button
                                // color='error'
                                component='p'
                                onClick={() => this.props.showIncident()}
                                style={{
                                    color: 'red', // à cause de color = 'error' qui ne marche pas laaaaa
                                    cursor: 'pointer !important',
                                    opacity: '1 !important',
                                }}
                                variant='text'
                            >
                                <FontAwesomeIcon icon={Icons.faExclamationTriangle} className={classes.updateIcon} />
                                Incident en cours
                            </MD.Button>
                        ) : (
                            <MD.Button
                                color='secondary'
                                component='p'
                                onClick={() => this.props.showIncident()}
                                style={{
                                    cursor: 'pointer !important',
                                    opacity: '1 !important',
                                }}
                                variant='text'
                            >
                                <FontAwesomeIcon icon={Icons.faCheck} className={classes.updateIcon} />
                                Service normal
                            </MD.Button>
                        )}
                    </div>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private buildSearchKind(): React.ReactNode {
        const list = this.props.medias.kindsResults;
        const enabled = this.isKindEnabled();

        if (enabled === 0) {
            return '';
        }
        return (
            <>
                <MD.Grid item xs={12}>
                    <MD.Typography className={this.props.classes.title} variant='h6'>
                        {this.props.i18n._('Show kind')}
                    </MD.Typography>
                    {enabled === 1 ? (
                        <MD.Typography
                            className={this.props.classes.kindWarning}
                            color='error'
                            component='p'
                            variant='body2'
                        >
                            {this.props.i18n._('Kind program filter will only work on "TNT Recalé" medias')}
                        </MD.Typography>
                    ) : (
                        ''
                    )}
                </MD.Grid>
                <MD.Grid item xs={12}>
                    {this.buildSearchLevel(0, list)}
                    {this.buildSearchLevel(1, list)}
                    {this.buildSearchLevel(2, list)}
                </MD.Grid>
            </>
        );
    }

    private buildSearchLevel(level: number, list: Proto.mediaarchiver.ISearchKind[]): React.ReactNode {
        let selectList: Proto.mediaarchiver.ISearchKind[] = [];

        if (level === 2) {
            list.some((item1) => {
                if (item1.Code === this.state.kinds[0]) {
                    if (!Array.isArray(item1.Children)) {
                        return false;
                    }
                    return item1.Children.some((item2) => {
                        if (!Array.isArray(item2.Children)) {
                            return false;
                        }
                        if (item2.Code === this.state.kinds[1]) {
                            selectList = item2.Children;
                            return true;
                        }
                        return false;
                    });
                }
                return false;
            });
        } else if (level === 1) {
            list.some((item) => {
                if (item.Code === this.state.kinds[0]) {
                    if (!Array.isArray(item.Children)) {
                        return false;
                    }
                    selectList = item.Children;
                }
                return false;
            });
        } else {
            selectList = list;
        }

        selectList = selectList.filter(
            (item) =>
                !isNull(item) &&
                !isNullOrUndefinedOrEmptyString(item.Name) &&
                !isNullOrUndefinedOrEmptyString(item.Code),
        );

        if (selectList.length === 0) {
            return '';
        }

        return (
            <MD.FormControl className={this.props.classes.formControl}>
                <MD.Select
                    native
                    onChange={(ev: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                        const kinds = this.state.kinds;

                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (kinds as any)[level] = ev.target.value;
                        this.setState({ kinds });
                    }}
                >
                    <option value={''}>{this.props.i18n._('All')}</option>
                    {selectList.map((item) => {
                        return (
                            <option key={`kind_select${level}_${item.Name}`} value={item.Code || ''}>
                                {item.Name}
                            </option>
                        );
                    })}
                </MD.Select>
            </MD.FormControl>
        );
    }

    private onStartChange(val: Date | moment.Moment | null): void {
        let start = getDate(val);
        let end = this.state.end;

        if (isNullOrUndefined(start)) {
            return;
        }

        start = moment(start).startOf('day').toDate();

        if (start.getTime() > end.getTime()) {
            end = moment(start).endOf('day').toDate();
        }

        this.setState({ end, start });

        if (this.state.end.getTime() - start.getTime() > 365 * 24 * 60 * 60 * 1000) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    date: this.props.i18n._('Dates gap cannot exceed 1 year'),
                },
            });
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    date: '',
                },
            });
        }
    }

    private isKindEnabled(): number {
        let panelIDs: number[] = [];

        if (this.state.selectedMedias.length === 0) {
            return 0;
        }
        this.props.medias.panels.tvs.some((panel) => {
            if (panel.ID === 101 && Array.isArray(panel.Medias)) {
                panelIDs = panel.Medias;
                return true;
            }
            return false;
        });
        if (panelIDs.length === 0) {
            return 0;
        }

        if (this.state.selectedMedias.length === 1) {
            return panelIDs.indexOf(this.state.selectedMedias[0]) === -1 ? 0 : 2;
        }
        return this.state.selectedMedias.some((m) => panelIDs.indexOf(m) === -1) ? 1 : 2;
    }

    private onEndChange(val: Date | moment.Moment | null): void {
        let end = getDate(val);
        let start = this.state.start;

        if (isNullOrUndefined(end)) {
            return;
        }

        end = moment(end).endOf('day').toDate();

        if (end.getTime() < start.getTime()) {
            start = moment(end).startOf('day').toDate();
        }

        this.setState({ end, start });

        if (end.getTime() - start.getTime() > 365 * 24 * 60 * 60 * 1000) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    date: this.props.i18n._('Dates gap cannot exceed 1 year'),
                },
            });
        } else {
            this.setState({
                errors: {
                    ...this.state.errors,
                    date: '',
                },
            });
        }
    }

    private getKindsPropositions(): IStateKindsProposition[] {
        return this.searchKindToKindsProposition(this.props.medias.kindsResults, 0);
    }

    private searchKindToKindsProposition(
        kinds: Proto.mediaarchiver.ISearchKind[],
        level: number,
    ): IStateKindsProposition[] {
        let res: IStateKindsProposition[] = [];

        kinds.forEach((kind: Proto.mediaarchiver.ISearchKind) => {
            if (!isNullOrUndefinedOrEmptyString(kind.Code) && !isNullOrUndefinedOrEmptyString(kind.Name)) {
                res.push({
                    code: kind.Code,
                    label: `${kind.Code} - ${kind.Name}`,
                });
            }
            if (Array.isArray(kind.Children)) {
                res = res.concat(this.searchKindToKindsProposition(kind.Children, level + 1));
            }
        });
        return res;
    }

    private isSearchReady(): boolean {
        return (
            !this.props.search.searchLoading && this.state.errors.date === '' && this.state.selectedMedias.length !== 0
        );
    }

    private buildKindList(): string[] {
        if (this.state.kinds[0] !== '' && this.state.kinds[1] !== '' && this.state.kinds[2] !== '') {
            return [this.state.kinds[2]];
        } else if (this.state.kinds[0] !== '' && this.state.kinds[1] !== '') {
            return [this.state.kinds[1]];
        } else if (this.state.kinds[0] !== '') {
            return [this.state.kinds[0]];
        }
        return [];
    }

    private onSearch() {
        if (!this.isSearchReady()) {
            return;
        }
        this.props.clearSearch();
        this.props.doSearch({
            end: this.state.end,
            kinds: this.buildKindList(),
            medias: this.state.selectedMedias,
            page: 0,
            pageSize: this.props.search.pageSize,
            start: this.state.start,
            text: this.state.selectedText,
        });
    }

    private getDefaultState(): IState {
        if (!isNull(this.props.search.lastSearch)) {
            return {
                end: this.props.search.lastSearch.end,
                errors: {
                    date: '',
                },
                kinds: ['', '', ''],
                selectedKinds: [],
                selectedMedias: this.props.search.lastSearch.medias,
                selectedText: this.props.search.lastSearch.text,
                start: this.props.search.lastSearch.start,
            };
        }
        return {
            end: moment().startOf('day').toDate(),
            errors: {
                date: '',
            },
            kinds: ['', '', ''],
            selectedKinds: [],
            selectedMedias: [],
            selectedText: '',
            start: moment().startOf('day').subtract(1, 'month').toDate(),
        };
    }
}

const mapStateToProps = (
    { i18n, incident, medias, search, user }: IApplicationState,
    ownProps: RouteComponentProps<{}>,
) => ({
    i18n: i18n.i18n,
    incident,
    localeInfos: i18n.localeInfos,
    medias,
    router: ownProps,
    search,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearSearch: () => dispatch(clearSearch()),
    doSearch: (params: ISearchParams) => dispatch(doSearch(params)),
    getMedias: () => dispatch(getMedias()),
    getPanels: () => dispatch(getPanels()),
    getTalkMedias: () => dispatch(getTalkMedias()),
    searchKinds: (search: string) => dispatch(searchKinds(search)),
    searchKindsReset: () => dispatch(searchKindsReset()),
    showIncident: () => dispatch(showIncident()),
    showReportBug: () => dispatch(showReportBug()),
});

export const SearchDrawer = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(SearchDrawerComponent));
