import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class TalkExportedTalks extends AMessage implements IFromCommand {
    private parsedContent: string | null = null;

    public constructor() {
        super('talkExportedTalks');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.TalkExportedTalksArg)) {
                return reject(new Error('Not a TalkExportedTalks message'));
            }
            this.parsedContent = msg.TalkExportedTalksArg;
            resolve();
        });
    }

    public get content(): string {
        if (isNull(this.parsedContent)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedContent;
    }
}
