import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class PlayerChunkStats extends AMessage implements IToCommand {
    private stats: Proto.mediaarchiver.IPlayerStatsChunks;

    public constructor(stats: Proto.mediaarchiver.IPlayerStatsChunks) {
        super('playerChunkStats');
        this.stats = stats;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                PlayerChunksStatsArg: this.stats,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypePlayerChunkStats,
            }),
        );
    }
}
