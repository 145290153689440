import { getLastChar } from './Utils/String';
import { isNull, isNullOrUndefined, isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrZero } from './Utils/Various';

export enum Environment {
    DEV = 1,
    STAGING,
    PRODUCTION,
}

export interface IConfig {
    // Delay between two archive polls
    archivesPollingDelay: number;
    // Force to use backend on non secure HTTP even if page is https
    backendForceNonSecure?: boolean;
    // Backend Host
    backendHost: string;
    // Backend path if different from default (/)
    backendPath?: string;
    // Backend Port if different from default (80/443)
    backendPort?: number;
    // How many retries we should do for WS reconnection backoff, -1 for unlimited
    // The delay starts at 5 seconds and is doubled every try until capping reached.
    backendRetries: number;
    // Backend retry delay capping, in milliseconds
    backendRetriesCapping: number;
    // Backend retry starting delay
    backendRetriesStart: number;

    // How many milliseconds before considering a client command as timeouted.
    commandsTTL: number;

    // What is the active environment
    environment: Environment;

    // GTag id
    gtagID: string;

    // Loglevel
    logLevel: 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'fatal';

    streamerURL: string;

    // verison
    version: string;
}

export let Config: IConfig;

const version = !isNullOrUndefinedOrEmptyString(process.env.REACT_APP_VERSION) ? process.env.REACT_APP_VERSION : 'N/A';
const backendHost = !isNullOrUndefinedOrEmptyString(process.env.REACT_APP_BACKEND_HOST)
    ? process.env.REACT_APP_BACKEND_HOST
    : '';
const backendPath = !isNullOrUndefinedOrEmptyString(process.env.MA_BACKEND_PATH)
    ? process.env.REACT_APP_BACKEND_PATH
    : '/';
const backendForceNonSecure = process.env.MREACT_APP_BACKEND_FORCE_NON_SECURE === 'true' ? true : false;

let backendPort: number | undefined = undefined;
if (!isNullOrUndefined(process.env.REACT_APP_BACKEND_PORT)) {
    const port = parseInt(process.env.REACT_APP_BACKEND_PORT, 10);

    if (isNaN(port) || port < 1 || port > 65535) {
        console.log(`Invalid MA_BACKEND_PORT env: "${process.env.REACT_APP_BACKEND_PORT}"`);
        backendPort = undefined;
    } else {
        backendPort = port;
    }
}

switch (process.env.REACT_APP_STAGE) {
    case 'production':
        Config = {
            archivesPollingDelay: 10000,
            backendForceNonSecure,
            backendHost: backendHost !== '' ? backendHost : 'mediaarchiver-ws.yacast.fr',
            backendPath,
            backendPort,
            backendRetries: -1,
            backendRetriesCapping: 30000,
            backendRetriesStart: 5000,
            commandsTTL: 5000,
            environment: Environment.PRODUCTION,
            gtagID: 'G-BMNJC7MWHJ',
            logLevel: 'warn',
            streamerURL: 'https://streamer.yacast.fr/v0/public',
            version,
        };
        break;
    case 'staging':
        Config = {
            archivesPollingDelay: 5000,
            backendForceNonSecure,
            backendHost: backendHost !== '' ? backendHost : 'mediaarchiver-dev-ws.yacast.fr',
            backendPath,
            backendPort,
            backendRetries: -1,
            backendRetriesCapping: 30000,
            backendRetriesStart: 5000,
            commandsTTL: 5000,
            environment: Environment.STAGING,
            gtagID: 'G-BMNJC7MWHJ',
            logLevel: 'debug',
            streamerURL: 'https://streamer-stg.yacast.fr/v0/public',
            version: `${version} (staging)`,
        };
        break;
    case 'dev':
    default:
        Config = {
            archivesPollingDelay: 5000,
            backendForceNonSecure,
            backendHost: backendHost !== '' ? backendHost : '127.0.0.1',
            backendPath,
            backendPort,
            backendRetries: -1,
            backendRetriesCapping: 10000,
            backendRetriesStart: 1000,
            commandsTTL: 5000,
            environment: Environment.DEV,
            gtagID: 'G-BMNJC7MWHJ',
            logLevel: 'trace',
            streamerURL: 'https://streamer-stg.yacast.fr/v0/public',
            version: `${version} (dev)`,
        };
}

export function buildBackendURL(path: string, wsURL = false): string {
    const isHttps =
        !Config.backendForceNonSecure &&
        !isNull(document) &&
        !isNull(document.location) &&
        !isNull(document.location.protocol) &&
        document.location.protocol.toLowerCase().startsWith('https');
    let url = wsURL ? (isHttps ? 'wss://' : 'ws://') : isHttps ? 'https://' : 'http://';

    url += Config.backendHost;
    if (!isNullOrUndefinedOrZero(Config.backendPort)) {
        url += `:${Config.backendPort}`;
    }
    if (!isNullOrUndefinedOrEmptyString(Config.backendPath)) {
        if (Config.backendPath[0] !== '/') {
            url += '/';
        }
        url += Config.backendPath;
    }
    if (getLastChar(url) === '/') {
        url = url.slice(0, url.length - 1);
    }
    url += path[0] === '/' ? '' : '/';
    url += path;
    return url;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).cfg = Config;
