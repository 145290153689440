import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefinedOrEmptyString } from '../../Utils/Various';

export class ShareArchive extends AMessage implements IToCommand {
    private params: Proto.mediaarchiver.IArgumentsShareArchive;

    public constructor(params: Proto.mediaarchiver.IArgumentsShareArchive) {
        super('shareArchive');

        if (isNullOrUndefinedOrEmptyString(params.ArchiveID)) {
            throw new Error('Missing archive id');
        }
        if (isNullOrUndefinedOrEmptyString(params.ToMail)) {
            throw new Error('Missing recipient email');
        }

        this.params = params;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                ShareArchiveArg: this.params,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeShareArchive,
            }),
        );
    }
}
