import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import classNames from 'classnames';
import { validate as validateEmail } from 'email-validator';
import ChipInput from 'material-ui-chip-input';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { createArchive as trackCreate } from '../../../Analytics/Events';
import { mediaarchiver } from '../../../Protos/protos';
import { IConnectedReduxProps } from '../../../Store';
import {
    create as createArchive,
    get as getArchives,
    hideDialog,
    IArchiveFormErrors,
    IArchiveState,
} from '../../../Store/Archives';
import {
    create as createContact,
    get as getContacts,
    IContactFormErrors,
    IContactsState,
} from '../../../Store/Contacts';
// import { get as getFTPAccounts, IFTPAccountsState } from '../../../Store/FTPAccounts';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import { setSnackMessage } from '../../../Store/Layout';
import { IPlayerState, setEndSelection, setStartSelection } from '../../../Store/Player';
import { create as createReview, get as getReviews, IReviewFormErrors, IReviewsState } from '../../../Store/Reviews';
import { get as getSFTPAccounts, ISFTPAccountsState } from '../../../Store/SFTPAccounts';
import { ITimelineState } from '../../../Store/Timeline';
import { IUserState } from '../../../Store/User';
import { capitalize, ensureString } from '../../../Utils/String';
import { getDateInTz } from '../../../Utils/Time';
import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../../Utils/Various';

import styles from './styles';

export interface IStateContact {
    email: string;
    firstName: string;
    general: string;
    lastName: string;
}

export interface IState {
    enabled: number[];
    errors: IArchiveFormErrors;
    gif: boolean;
    newContactErrors: IContactFormErrors;
    newContactForm: IStateContact;
    newReviewErrors: IReviewFormErrors;
    newReviewName: string;
    values: mediaarchiver.IArchive;
}

interface IPropsFromState {
    archives: IArchiveState;
    contacts: IContactsState;
    // ftpAccounts: IFTPAccountsState;
    i18n: I18N;
    localeInfos: ILocaleInfos;
    player: IPlayerState;
    reviews: IReviewsState;
    sftpAccounts: ISFTPAccountsState;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    createArchive: typeof createArchive;
    createContact: typeof createContact;
    createReview: typeof createReview;
    getArchives: typeof getArchives;
    getContacts: typeof getContacts;
    // getFTPAccounts: typeof getFTPAccounts;
    getReviews: typeof getReviews;
    getSFTPAccounts: typeof getSFTPAccounts;
    hideDialog: typeof hideDialog;
    setEndSelection: typeof setEndSelection;
    setSnackMessage: typeof setSnackMessage;
    setStartSelection: typeof setStartSelection;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export default class ExtractionDialogComponent extends React.Component<AllProps, IState> {
    private contactErrorHandler: (ev: CustomEvent) => void;
    private contactSuccessHandler: (ev: CustomEvent) => void;
    private extractionErrorHandler: (ev: CustomEvent) => void;
    private extractionSuccessHandler: (ev: CustomEvent) => void;
    private reviewErrorHandler: (ev: CustomEvent) => void;
    private reviewSuccessHandler: (ev: CustomEvent) => void;
    private waitingContactID = '';
    private waitingReviewID = '';

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();

        this.extractionSuccessHandler = () => {
            this.props.setEndSelection(null);
            this.props.setStartSelection(null);
            this.handleCloseExtractionDialog();
            this.props.setSnackMessage(this.props.i18n._('Extraction in progress'));
        };
        this.extractionErrorHandler = (ev: CustomEvent<IArchiveFormErrors>) => {
            this.setState({ errors: ev.detail });
            window.setTimeout(() => {
                const errors = document.getElementsByClassName('Mui-error');

                if (errors.length !== 0) {
                    errors[0].scrollIntoView();
                }
            }, 300);
        };

        this.contactSuccessHandler = () => {
            if (this.waitingContactID === '') {
                return;
            }

            const defaultState = this.getDefaultState();
            const values = Array.isArray(this.state.values.ToContacts) ? this.state.values.ToContacts : [];
            this.setState({
                ...this.state,

                newContactErrors: defaultState.newContactErrors,
                newContactForm: defaultState.newContactForm,
                values: {
                    ...this.state.values,
                    ToContacts: values.concat(this.waitingContactID),
                },
            });
            this.waitingContactID = '';
        };
        this.contactErrorHandler = (ev: CustomEvent<IContactFormErrors>) => {
            this.setState({ newContactErrors: ev.detail });
            this.waitingContactID = '';
        };

        this.reviewSuccessHandler = () => {
            if (this.waitingReviewID === '') {
                return;
            }
            this.setState({
                ...this.state,

                newReviewErrors: this.getDefaultState().newReviewErrors,
                newReviewName: '',
                values: {
                    ...this.state.values,

                    ToPressReview: this.waitingReviewID,
                },
            });
            this.waitingReviewID = '';
        };
        this.reviewErrorHandler = (ev: CustomEvent<IReviewFormErrors>) => {
            this.setState({ newReviewErrors: ev.detail as IReviewFormErrors });
            this.waitingReviewID = '';
        };
    }

    public render(): React.ReactNode {
        if (
            isNull(this.props.player.startSelection) ||
            isNull(this.props.player.endSelection) ||
            isNullOrUndefinedOrZero(this.props.timeline.criterias.media) ||
            isNullOrUndefinedOrEmptyString(this.props.timeline.criterias.mediaName)
        ) {
            return <div />;
        }
        return <div>{this.renderExtractionDialog()}</div>;
    }

    public componentDidMount(): void {
        this.props.getContacts();
        this.props.getReviews({ Limit: 1000 });
        // this.props.getFTPAccounts();
        this.props.getSFTPAccounts();

        /* eslint-disable @typescript-eslint/no-explicit-any */
        this.props.archives.opSuccessEventElement.addEventListener<any>('opSuccess', this.extractionSuccessHandler);
        this.props.archives.opSuccessEventElement.addEventListener<any>('opError', this.extractionErrorHandler);
        this.props.contacts.opSuccessEventElement.addEventListener<any>('opSuccess', this.contactSuccessHandler);
        this.props.contacts.opSuccessEventElement.addEventListener<any>('opError', this.contactErrorHandler);
        this.props.reviews.opSuccessEventElement.addEventListener<any>('opSuccess', this.reviewSuccessHandler);
        this.props.reviews.opSuccessEventElement.addEventListener<any>('opError', this.reviewErrorHandler);
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    public componentWillUnmount(): void {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        this.props.archives.opSuccessEventElement.removeEventListener<any>('opSuccess', this.extractionSuccessHandler);
        this.props.archives.opSuccessEventElement.removeEventListener<any>('opError', this.extractionErrorHandler);
        this.props.contacts.opSuccessEventElement.removeEventListener<any>('opSuccess', this.contactSuccessHandler);
        this.props.contacts.opSuccessEventElement.removeEventListener<any>('opError', this.contactErrorHandler);
        this.props.reviews.opSuccessEventElement.removeEventListener<any>('opSuccess', this.reviewSuccessHandler);
        this.props.reviews.opSuccessEventElement.removeEventListener<any>('opError', this.reviewErrorHandler);
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    private renderExtractionDialog() {
        if (
            isNull(this.props.player.startSelection) ||
            isNull(this.props.player.endSelection) ||
            isNullOrUndefinedOrZero(this.props.timeline.criterias.media) ||
            isNullOrUndefinedOrEmptyString(this.props.timeline.criterias.mediaName)
        ) {
            return <div />;
        }

        const from = moment(this.props.player.startSelection).format(
            this.props.localeInfos.formatShortDateTimeWithMilliseconds,
        );
        const to = moment(this.props.player.endSelection).format(
            this.props.localeInfos.formatShortDateTimeWithMilliseconds,
        );
        const durationMS = this.props.player.endSelection.getTime() - this.props.player.startSelection.getTime();
        const duration = moment.duration(durationMS);
        const durationStr =
            ('0' + duration.hours().toString()).slice(-2) +
            ':' +
            ('0' + duration.minutes().toString()).slice(-2) +
            ':' +
            ('0' + duration.seconds().toString()).slice(-2) +
            '.' +
            duration.milliseconds().toString();

        return (
            <MD.Dialog
                maxWidth='sm'
                open={this.props.archives.showDialog}
                onClose={this.handleCloseExtractionDialog.bind(this)}
                onEntered={this.handleOpenExtractionDialog.bind(this)}
            >
                <MD.DialogTitle>{this.props.i18n._('Create an archive')}</MD.DialogTitle>
                <MD.DialogContent className={this.props.classes.dialogContent}>
                    <MD.Box>
                        <MD.Grid container>
                            {this.props.timeline.criterias.mediaType === 2 ? (
                                <MD.Grid item xs={4}>
                                    <img className={this.props.classes.dialogImage} src={this.props.player.snapshot} />
                                </MD.Grid>
                            ) : (
                                ''
                            )}
                            <MD.Grid className={this.props.classes.dialogHeaderInfos} item xs={8}>
                                <strong>{this.props.timeline.criterias.mediaName}</strong>
                                {this.props.i18n.sprintf(this.props.i18n._('From %1$s to %2$s'), from, to)}
                                <br />
                                {this.props.i18n.sprintf(this.props.i18n._('Duration: %1$s'), durationStr)}
                                {this.props.timeline.criterias.mediaType === 2 ? (
                                    <MD.FormControl className={this.props.classes.dialogHeaderFormat}>
                                        <MD.InputLabel htmlFor='previewFormat'>
                                            {this.props.i18n._('Image format')}
                                        </MD.InputLabel>
                                        <MD.Select
                                            onChange={this.handleChangeThumbFormat.bind(this)}
                                            inputProps={{
                                                id: 'previewFormat',
                                            }}
                                            value={this.state.values.ThumbFormat}
                                        >
                                            <MD.MenuItem
                                                value={mediaarchiver.ArchiveThumbFormats.ARCHIVE_THUMB_FORMAT_PNG}
                                            >
                                                PNG
                                            </MD.MenuItem>
                                            <MD.MenuItem
                                                value={mediaarchiver.ArchiveThumbFormats.ARCHIVE_THUMB_FORMAT_JPEG}
                                            >
                                                JPEG
                                            </MD.MenuItem>
                                        </MD.Select>
                                    </MD.FormControl>
                                ) : (
                                    ''
                                )}
                            </MD.Grid>
                        </MD.Grid>
                    </MD.Box>
                    {this.generateAddExtractionForm()}
                </MD.DialogContent>
                <MD.DialogActions>
                    <MD.Button onClick={this.handleCloseExtractionDialog.bind(this)} color='primary'>
                        {this.props.i18n._('Cancel')}
                    </MD.Button>
                    {this.props.archives.operationInProgress ? (
                        <MD.CircularProgress className={this.props.classes.dialogLoading} />
                    ) : (
                        <MD.Button onClick={this.handleSubmitExtractionDialog.bind(this)} color='secondary'>
                            {this.props.i18n._('Create')}
                        </MD.Button>
                    )}
                </MD.DialogActions>
            </MD.Dialog>
        );
    }

    private generateAddExtractionForm() {
        const tags = Array.isArray(this.state.values.Tags) ? this.state.values.Tags : [];

        return (
            <div>
                <MD.FormControl error={this.state.errors.name !== ''} fullWidth={true}>
                    <MD.TextField
                        error={this.state.errors.name !== ''}
                        helperText={this.state.errors.name}
                        label={this.props.i18n._('Archive name')}
                        onChange={this.handleNameChange.bind(this)}
                        required={true}
                        value={this.state.values.Name}
                    />
                </MD.FormControl>
                <MD.FormControl className={this.props.classes.dialogTags} fullWidth={true}>
                    <ChipInput
                        label={this.props.i18n._('Archive tags (press enter to add another tag)')}
                        onChange={this.handleTagChange.bind(this)}
                        onDelete={this.handleTagDelete.bind(this)}
                        required={true}
                        value={tags}
                    />
                </MD.FormControl>
                <MD.Box className={this.props.classes.panelsContainer}>
                    {this.renderExtractionGIF()}
                    {this.renderExtractionDialogShareMail()}
                    {this.renderExtractionDialogPressReview()}
                    {/*this.renderExtractionDialogFTP()*/}
                    {this.renderExtractionDialogSFTP()}
                    {this.renderExtractionDialogComment()}
                </MD.Box>
            </div>
        );
    }

    private renderExtractionGIF() {
        if (
            this.props.timeline.criterias.mediaType !== 2 ||
            isNull(this.props.player) ||
            isNull(this.props.player.startSelection) ||
            isNull(this.props.player.endSelection)
        ) {
            return '';
        }
        const classes = this.props.classes;
        const duration = this.props.player.endSelection.getTime() - this.props.player.startSelection.getTime();

        if (duration > 30000) {
            return '';
        }

        return (
            <div className={classes.expansionPanel}>
                <div className={classes.expansionPanelHeader}>
                    <MD.Checkbox
                        checked={this.state.enabled.includes(0)}
                        className={classes.expansionPanelCheckbox}
                        color='primary'
                        onChange={() => {
                            let enabled = this.state.enabled;

                            if (enabled.indexOf(0) === -1) {
                                enabled.push(0);
                            } else {
                                enabled = enabled.filter((i) => i !== 0);
                            }
                            this.setState({ enabled });
                        }}
                        value='0'
                    />
                    <MD.Typography>{this.props.i18n._('Create a GIF')}</MD.Typography>
                    &nbsp;
                </div>
            </div>
        );
    }

    private renderExtractionDialogShareMail() {
        const classes = this.props.classes;
        const mails = Array.isArray(this.state.values.ToContacts) ? this.state.values.ToContacts : [];
        const classesPanel = classNames({
            [classes.formBorderError]: this.state.errors.toContacts !== '',
            [classes.expansionPanel]: true,
        });
        const classesPanelDetails = classNames({
            [classes.expansionPanelDetails]: true,
            [classes.expansionPanelDetailsOpened]: this.state.enabled.indexOf(1) !== -1,
        });

        return (
            <div className={classesPanel}>
                <div className={classes.expansionPanelHeader}>
                    <MD.Checkbox
                        checked={this.state.enabled.includes(1)}
                        className={classes.expansionPanelCheckbox}
                        color='primary'
                        onChange={() => {
                            let enabled = this.state.enabled;

                            if (enabled.indexOf(1) === -1) {
                                enabled.push(1);
                            } else {
                                enabled = enabled.filter((i) => i !== 1);
                            }
                            this.setState({ enabled });
                        }}
                        value='1'
                    />
                    <MD.Typography>{this.props.i18n._('Share by mail')}</MD.Typography>
                </div>
                <div className={classesPanelDetails}>
                    {this.state.errors.toContacts !== '' ? (
                        <MD.Typography color='error' component='div'>
                            {this.state.errors.toContacts}
                        </MD.Typography>
                    ) : (
                        ''
                    )}
                    <MD.Grid container>
                        <MD.Grid item xs={5}>
                            <MD.FormControl fullWidth>
                                <MD.InputLabel htmlFor='contactSelect'>
                                    {this.props.i18n._('Choose an existing contact')}
                                </MD.InputLabel>
                                <MD.Select
                                    fullWidth
                                    inputProps={{
                                        id: 'contactSelect',
                                        name: 'contactSelect',
                                    }}
                                    native
                                >
                                    <option value='' />
                                    {this.props.contacts.contacts
                                        .map((c) => {
                                            if (isNullOrUndefined(c) || isNullOrUndefined(c.ID)) {
                                                return '';
                                            }
                                            if (mails.includes(c.ID)) {
                                                return '';
                                            }
                                            return (
                                                <option key={`contact_${c.ID}`} value={c.ID}>
                                                    {c.FirstName} {c.LastName}
                                                </option>
                                            );
                                        })
                                        .filter((c) => c !== '')}
                                </MD.Select>
                                <MD.Button onClick={this.handleAddExistingContact.bind(this)}>
                                    {this.props.i18n._('Add to list')} -&sup;
                                </MD.Button>
                            </MD.FormControl>
                            <MD.Typography>{this.props.i18n._('Or create a new contact :')}</MD.Typography>
                            <MD.TextField
                                disabled={this.props.contacts.operationInProgress}
                                error={this.state.newContactErrors.lastName !== ''}
                                fullWidth
                                helperText={this.state.newContactErrors.lastName}
                                label={this.props.i18n._('Last name')}
                                margin='normal'
                                name='lastName'
                                onChange={this.handleNewContactLastName.bind(this)}
                                value={this.state.newContactForm.lastName}
                            />
                            <MD.TextField
                                disabled={this.props.contacts.operationInProgress}
                                error={this.state.newContactErrors.firstName !== ''}
                                fullWidth
                                helperText={this.state.newContactErrors.firstName}
                                label={this.props.i18n._('First name')}
                                margin='normal'
                                name='firstName'
                                onChange={this.handleNewContactFirstName.bind(this)}
                                value={this.state.newContactForm.firstName}
                            />
                            <MD.TextField
                                disabled={this.props.contacts.operationInProgress}
                                error={this.state.newContactErrors.email !== ''}
                                fullWidth
                                helperText={this.state.newContactErrors.email}
                                label={this.props.i18n._('Email')}
                                margin='normal'
                                name='email'
                                onChange={this.handleNewContactEmail.bind(this)}
                                value={this.state.newContactForm.email}
                            />
                            <MD.FormControl
                                disabled={this.props.contacts.operationInProgress}
                                error={this.state.newContactErrors.general !== ''}
                                fullWidth
                            >
                                {this.props.contacts.operationInProgress ? (
                                    <MD.Button disabled={this.props.contacts.operationInProgress} fullWidth>
                                        <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                                        {this.props.i18n._('Please wait ...')}
                                    </MD.Button>
                                ) : (
                                    <MD.Button
                                        disabled={this.props.contacts.operationInProgress}
                                        fullWidth
                                        onClick={this.handleNewContactSubmit.bind(this)}
                                    >
                                        {this.props.i18n._('Create contact and add to list')}
                                    </MD.Button>
                                )}
                                {this.state.newContactErrors.general !== '' ? (
                                    <MD.FormHelperText>{this.state.newContactErrors.general}</MD.FormHelperText>
                                ) : (
                                    ''
                                )}
                            </MD.FormControl>
                        </MD.Grid>
                        <MD.Grid item xs={1}>
                            &nbsp;
                        </MD.Grid>
                        <MD.Grid item xs={6}>
                            <MD.Typography>
                                {this.props.i18n._('Added contacts')}
                                &nbsp;
                                {`(${mails.length})`}
                                &nbsp;:
                            </MD.Typography>
                            <MD.List className={classes.contactsList} dense>
                                {mails.map((id) => {
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    let res: React.ReactElement<any> | string = '';
                                    this.props.contacts.contacts.some((c) => {
                                        if (!isNullOrUndefined(c) && c.ID === id) {
                                            res = (
                                                <MD.ListItem key={`selectedContact_${c.ID}`}>
                                                    <MD.ListItemText
                                                        primary={
                                                            (c.LastName as string).toLocaleUpperCase() +
                                                            ' ' +
                                                            c.FirstName
                                                        }
                                                        secondary={c.Email}
                                                    />
                                                    <MD.ListItemSecondaryAction>
                                                        <MD.Button onClick={this.handleRemoveContact.bind(this, c.ID)}>
                                                            <FontAwesomeIcon color={'white'} icon={FA.faTrash} />
                                                        </MD.Button>
                                                    </MD.ListItemSecondaryAction>
                                                </MD.ListItem>
                                            );
                                            return true;
                                        }
                                        return false;
                                    });
                                    return res;
                                })}
                            </MD.List>
                        </MD.Grid>
                    </MD.Grid>
                </div>
            </div>
        );
    }

    private renderExtractionDialogPressReview() {
        const classes = this.props.classes;
        const classesPanel = classNames({
            [classes.formBorderError]: this.state.errors.toReview !== '',
            [classes.expansionPanel]: true,
        });
        const classesPanelDetails = classNames({
            [classes.expansionPanelDetails]: true,
            [classes.expansionPanelDetailsOpened]: this.state.enabled.indexOf(2) !== -1,
        });

        return (
            <div className={classesPanel}>
                <div className={classes.expansionPanelHeader}>
                    <MD.Checkbox
                        checked={this.state.enabled.includes(2)}
                        className={classes.expansionPanelCheckbox}
                        color='primary'
                        onChange={() => {
                            let enabled = this.state.enabled;

                            if (enabled.indexOf(2) === -1) {
                                enabled.push(2);
                            } else {
                                enabled = enabled.filter((i) => i !== 2);
                            }
                            this.setState({ enabled });
                        }}
                        value='2'
                    />
                    <MD.Typography>{this.props.i18n._('Add to press review')}</MD.Typography>
                </div>
                <div className={classesPanelDetails}>
                    <MD.InputLabel htmlFor='reviewSelect'>
                        {this.props.i18n._('Choose an existing review')}
                    </MD.InputLabel>
                    <MD.Select
                        onChange={this.handleChangeReview.bind(this)}
                        fullWidth
                        inputProps={{
                            id: 'reviewSelect',
                            name: 'toReview',
                        }}
                        native
                        value={this.state.values.ToPressReview}
                    >
                        <option value='' />
                        {this.props.reviews.reviews.map((c) => (
                            <option key={`review_${c.ID}`} value={c.ID as string}>
                                {c.Name}
                            </option>
                        ))}
                    </MD.Select>
                    <MD.Typography>{this.props.i18n._('Or create a new one :')}</MD.Typography>
                    <MD.TextField
                        disabled={this.props.reviews.operationInProgress}
                        error={this.state.newReviewErrors.name !== ''}
                        fullWidth
                        helperText={this.state.newReviewErrors.name}
                        label={this.props.i18n._('Review name')}
                        margin='normal'
                        onChange={this.handleNewReviewName.bind(this)}
                        value={this.state.newReviewName}
                    />
                    <MD.FormControl
                        disabled={this.props.reviews.operationInProgress}
                        error={this.state.newReviewErrors.general !== ''}
                        fullWidth
                    >
                        {this.props.reviews.operationInProgress ? (
                            <MD.Button disabled={this.props.reviews.operationInProgress} fullWidth>
                                <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                                {this.props.i18n._('Please wait ...')}
                            </MD.Button>
                        ) : (
                            <MD.Button
                                disabled={this.props.reviews.operationInProgress}
                                fullWidth
                                onClick={this.handleNewReviewSubmit.bind(this)}
                            >
                                {this.props.i18n._('Create review and add archive to it')}
                            </MD.Button>
                        )}
                        {this.state.newReviewErrors.general !== '' ? (
                            <MD.FormHelperText>{this.state.newReviewErrors.general}</MD.FormHelperText>
                        ) : (
                            ''
                        )}
                    </MD.FormControl>
                </div>
            </div>
        );
    }

    /*
    private renderExtractionDialogFTP() {
        const classes = this.props.classes;
        const classesPanel = classNames({
            [classes.formBorderError]: this.state.errors.toFTP !== '',
            [classes.expansionPanel]: true,
        });
        const classesPanelDetails = classNames({
            [classes.expansionPanelDetails]: true,
            [classes.expansionPanelDetailsOpened]: this.state.enabled.indexOf(3) !== -1,
        });

        return (
            <div className={classesPanel}>
                <div className={classes.expansionPanelHeader}>
                    <MD.Checkbox
                        checked={this.state.enabled.includes(3)}
                        className={classes.expansionPanelCheckbox}
                        color='primary'
                        onChange={() => {
                            let enabled = this.state.enabled;

                            if (enabled.indexOf(3) === -1) {
                                enabled.push(3);
                            } else {
                                enabled = enabled.filter((i) => i !== 3);
                            }
                            this.setState({ enabled });
                        }}
                        value='3'
                    />
                    <MD.Typography>{this.props.i18n._('Upload to FTP')}</MD.Typography>
                </div>
                <div className={classesPanelDetails}>
                    <MD.InputLabel htmlFor='ftpSelect'>{this.props.i18n._('Choose an FTP account')}</MD.InputLabel>
                    <MD.Select
                        onChange={this.handleChangeFTP.bind(this)}
                        fullWidth
                        inputProps={{
                            id: 'ftpSelect',
                            name: 'toFTP',
                        }}
                        native
                        value={this.state.values.ToFTP}
                    >
                        <option value='' />
                        {this.props.ftpAccounts.accounts.map((a) =>
                            isNull(a) ? (
                                ''
                            ) : (
                                <option key={`ftp_${ensureString(a.ID)}`} value={ensureString(a.ID)}>
                                    {a.Name}
                                </option>
                            ),
                        )}
                    </MD.Select>
                </div>
            </div>
        );
    }
    */

    private renderExtractionDialogSFTP() {
        const classes = this.props.classes;
        const classesPanel = classNames({
            [classes.formBorderError]: this.state.errors.toSFTP !== '',
            [classes.expansionPanel]: true,
        });
        const classesPanelDetails = classNames({
            [classes.expansionPanelDetails]: true,
            [classes.expansionPanelDetailsOpened]: this.state.enabled.indexOf(4) !== -1,
        });

        return (
            <div className={classesPanel}>
                <div className={classes.expansionPanelHeader}>
                    <MD.Checkbox
                        checked={this.state.enabled.includes(4)}
                        className={classes.expansionPanelCheckbox}
                        color='primary'
                        onChange={() => {
                            let enabled = this.state.enabled;

                            if (enabled.indexOf(4) === -1) {
                                enabled.push(4);
                            } else {
                                enabled = enabled.filter((i) => i !== 4);
                            }
                            this.setState({ enabled });
                        }}
                        value='4'
                    />
                    <MD.Typography>{this.props.i18n._('Upload to SFTP')}</MD.Typography>
                </div>
                <div className={classesPanelDetails}>
                    <MD.InputLabel htmlFor='sftpSelect'>{this.props.i18n._('Choose an SFTP account')}</MD.InputLabel>
                    <MD.Select
                        onChange={this.handleChangeSFTP.bind(this)}
                        fullWidth
                        inputProps={{
                            id: 'sftpSelect',
                            name: 'toSFTP',
                        }}
                        native
                        value={this.state.values.ToSFTP}
                    >
                        <option value='' />
                        {this.props.sftpAccounts.accounts.map((a) =>
                            isNull(a) ? (
                                ''
                            ) : (
                                <option key={`sftp_${ensureString(a.ID)}`} value={ensureString(a.ID)}>
                                    {a.Name}
                                </option>
                            ),
                        )}
                    </MD.Select>
                </div>
            </div>
        );
    }

    private renderExtractionDialogComment() {
        const classes = this.props.classes;
        const classesPanel = classNames({
            [classes.formBorderError]: this.state.errors.comment !== '',
            [classes.expansionPanel]: true,
        });
        const classesPanelDetails = classNames({
            [classes.expansionPanelDetails]: true,
            [classes.expansionPanelDetailsOpened]: this.state.enabled.indexOf(5) !== -1,
        });

        return (
            <div className={classesPanel}>
                <div className={classes.expansionPanelHeader}>
                    <MD.Checkbox
                        checked={this.state.enabled.includes(5)}
                        className={classes.expansionPanelCheckbox}
                        color='primary'
                        onChange={() => {
                            let enabled = this.state.enabled;

                            if (enabled.indexOf(5) === -1) {
                                enabled.push(5);
                            } else {
                                enabled = enabled.filter((i) => i !== 5);
                            }
                            this.setState({ enabled });
                        }}
                        value='5'
                    />
                    <MD.Typography>{this.props.i18n._('Add a comment')}</MD.Typography>
                </div>
                <div className={classesPanelDetails}>
                    <MD.TextField
                        label={this.props.i18n._('Archive comment')}
                        multiline
                        onChange={this.handleCommentChange.bind(this)}
                        required={true}
                        rows={5}
                        value={this.state.values.Comment}
                    />
                </div>
            </div>
        );
    }

    private handleCloseExtractionDialog() {
        this.setState({ ...this.getDefaultState() });
        this.props.hideDialog();
    }

    private handleOpenExtractionDialog() {
        this.setState({
            values: {
                ...this.state.values,
                Name: this.getDefaultName(),
            },
        });
    }

    private handleChangeThumbFormat(ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) {
        let previewFormat = mediaarchiver.ArchiveThumbFormats.ARCHIVE_THUMB_FORMAT_PNG;
        switch ((ev.target.value as string).toString()) {
            case mediaarchiver.ArchiveThumbFormats.ARCHIVE_THUMB_FORMAT_JPEG.toString():
                previewFormat = mediaarchiver.ArchiveThumbFormats.ARCHIVE_THUMB_FORMAT_JPEG;
                break;
            case mediaarchiver.ArchiveThumbFormats.ARCHIVE_THUMB_FORMAT_PNG.toString():
            default:
                previewFormat = mediaarchiver.ArchiveThumbFormats.ARCHIVE_THUMB_FORMAT_PNG;
                break;
        }
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,

                ThumbFormat: previewFormat,
            },
        });
    }

    private handleNameChange(ev: React.ChangeEvent<{ name?: string | undefined; value: string }>) {
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,

                Name: ev.target.value,
            },
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleTagChange(tags: any[]) {
        this.setState({
            ...this.state,

            values: {
                ...this.state.values,

                Tags: tags,
            },
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleTagDelete(tagToRemove: any) {
        const tags = Array.isArray(this.state.values.Tags) ? this.state.values.Tags : [];

        this.setState({
            ...this.state,
            values: {
                ...this.state.values,

                Tags: tags.filter((tag) => tag !== tagToRemove),
            },
        });
    }

    private handleAddExistingContact() {
        const contacts = Array.isArray(this.state.values.ToContacts) ? this.state.values.ToContacts : [];
        const select = document.getElementById('contactSelect') as HTMLSelectElement | null;

        if (isNullOrUndefined(select) || select.value === '') {
            return;
        }
        this.setState({
            ...this.state,

            values: {
                ...this.state.values,

                ToContacts: contacts.concat(select.value),
            },
        });
        select.selectedIndex = 0;
    }

    private handleRemoveContact(idToRemove: string) {
        const contacts = Array.isArray(this.state.values.ToContacts) ? this.state.values.ToContacts : [];

        this.setState({
            ...this.state,
            values: {
                ...this.state.values,

                ToContacts: contacts.filter((id) => id !== idToRemove),
            },
        });
    }

    private handleNewContactLastName(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            ...this.state,
            newContactForm: {
                ...this.state.newContactForm,
                lastName: ev.target.value,
            },
        });
    }
    private handleNewContactFirstName(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            ...this.state,
            newContactForm: {
                ...this.state.newContactForm,
                firstName: ev.target.value,
            },
        });
    }

    private handleNewContactEmail(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            ...this.state,
            newContactForm: {
                ...this.state.newContactForm,
                email: ev.target.value,
            },
        });
    }

    private handleNewContactSubmit() {
        let valid = true;
        const errors = this.getDefaultState().newContactErrors;
        const id = uuid();

        if (this.state.newContactForm.firstName === '') {
            errors.firstName = this.props.i18n._("Please type contact's first name");
            valid = false;
        }
        if (this.state.newContactForm.lastName === '') {
            errors.lastName = this.props.i18n._("Please type contact's last name");
            valid = false;
        }
        if (this.state.newContactForm.email === '') {
            errors.email = this.props.i18n._("Please type contact's last name");
            valid = false;
        } else if (!validateEmail(this.state.newContactForm.email)) {
            errors.email = this.props.i18n._('This email address is invalid');
            valid = false;
        }
        this.setState({
            ...this.state,

            newContactErrors: errors,
        });
        if (!valid) {
            return;
        }
        this.waitingContactID = id;
        this.props.createContact({
            Email: this.state.newContactForm.email.toLowerCase(),
            FirstName: capitalize(this.state.newContactForm.firstName),
            ID: id,
            LastName: this.state.newContactForm.lastName.toLocaleUpperCase(),
        });
    }

    private handleNewReviewName(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            ...this.state,
            newReviewName: ev.target.value,
        });
    }

    private handleNewReviewSubmit() {
        const errors = this.getDefaultState().newReviewErrors;
        let ok = true;
        let id = '';

        if (this.state.newReviewName === '') {
            errors.name = this.props.i18n._('Please type the new review name');
            ok = false;
        }
        if (ok) {
            id = uuid();
            this.waitingReviewID = id;
            this.props.createReview({
                ID: id,
                Name: this.state.newReviewName,
            });
        }
        this.setState({
            ...this.state,
            newReviewErrors: errors,
        });
    }

    private handleChangeReview(ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) {
        if (typeof ev.target.value !== 'string') {
            return;
        }
        this.setState({
            ...this.state,
            values: {
                ...this.state.values,

                ToPressReview: ev.target.value,
            },
        });
    }

    /*
    private handleChangeFTP(ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) {
        if (typeof ev.target.value !== 'string') {
            return;
        }
        this.setState({
            ...this.state,

            values: {
                ...this.state.values,

                ToFTP: ev.target.value,
            },
        });
    }
    */
    private handleChangeSFTP(ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) {
        if (typeof ev.target.value !== 'string') {
            return;
        }
        this.setState({
            ...this.state,

            values: {
                ...this.state.values,

                ToSFTP: ev.target.value,
            },
        });
    }

    private handleCommentChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            values: {
                ...this.state.values,

                Comment: ev.target.value,
            },
        });
    }

    private handleSubmitExtractionDialog() {
        if (isNull(this.props.player.startSelection) || isNull(this.props.player.endSelection)) {
            return;
        }

        const ext = { ...this.state.values };
        const start = getDateInTz(this.props.player.startSelection as Date, this.props.timeline.criterias.timezone).getTime();
        const end = getDateInTz(this.props.player.endSelection as Date, this.props.timeline.criterias.timezone).getTime();

        ext.ID = uuid();
        ext.MediaID = this.props.timeline.criterias.media;
        ext.MediaType = this.props.timeline.criterias.mediaType;
        ext.Start = start;
        ext.DurationMS = end - start;
        ext.VignetteURL = this.props.player.snapshot;

        ext.ToContacts = this.state.enabled.includes(1) ? this.state.values.ToContacts : [];
        ext.ToPressReview = this.state.enabled.includes(2) ? this.state.values.ToPressReview : '';
        // ext.ToFTP = this.state.enabled.includes(3) ? this.state.values.ToFTP : '';
        ext.ToSFTP = this.state.enabled.includes(4) ? this.state.values.ToSFTP : '';
        ext.Comment = this.state.enabled.includes(5) ? this.state.values.Comment : '';
        ext.Gif = this.state.gif;

        this.props.createArchive(ext);
        trackCreate();
    }

    private getDefaultName(): string {
        if (this.props.timeline.criterias.mediaName === '') {
            return '';
        }
        const date = moment(this.props.player.startSelection).format(this.props.localeInfos.formatLongDateTime);
        return this.props.i18n.sprintf(
            this.props.i18n._('%1$s on %2$s'),
            this.props.timeline.criterias.mediaName,
            date,
        );
    }

    private getDefaultState(): IState {
        return {
            enabled: [],
            errors: {
                comment: '',
                general: '',
                name: '',
                toContacts: '',
                // toFTP: '',
                toReview: '',
                toSFTP: '',
            },
            gif: false,
            newContactErrors: {
                email: '',
                firstName: '',
                general: '',
                lastName: '',
            },
            newContactForm: {
                email: '',
                firstName: '',
                general: '',
                lastName: '',
            },
            newReviewErrors: {
                general: '',
                name: '',
            },
            newReviewName: '',
            values: {
                Comment: '',
                ID: '',
                MediaID: 0,
                Name: '',
                Tags: [],
                ThumbFormat: mediaarchiver.ArchiveThumbFormats.ARCHIVE_THUMB_FORMAT_PNG,
                ToContacts: [],
                // ToFTP: '',
                ToPressReview: '',
                ToSFTP: '',
            },
        };
    }
}
