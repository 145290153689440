import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefined } from '../../Utils/Various';

export class ReportBug extends AMessage implements IToCommand {
    private bug?: Proto.mediaarchiver.ReportedBug;

    public constructor(bug: Proto.mediaarchiver.ReportedBug) {
        super('reportBug');
        this.bug = bug;
    }

    public Send(): Promise<void> {
        if (isNullOrUndefined(this.bug)) {
            throw new Error('bug is required');
        }
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                ReportBugArg: this.bug,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeReportBug,
            }),
        );
    }
}
