import { effects } from 'redux-saga';

import { GetChunk } from '../../Backend/Commands/GetChunk';
import { GetStream } from '../../Backend/Commands/GetStream';
import { PlayerChunkStats } from '../../Backend/Commands/PlayerChunkStats';
import { PlayerManifestStats } from '../../Backend/Commands/PlayerManifestStats';
import { Logger } from '../../Utils/Logger';

import { askChunk, askStream, sendStats } from './Actions';
import { PlayerActionTypes } from './Types';

function* handleChunkRequests(action: ReturnType<typeof askChunk>) {
    const cmd = new GetChunk(action.payload.mediaID, action.payload.time);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to ask chunk');
    }
}

function* handleStatsRequests(action: ReturnType<typeof sendStats>) {
    const cmd1 = new PlayerChunkStats(action.payload.chunks);
    const cmd2 = new PlayerManifestStats(action.payload.manifests);

    try {
        yield effects.call(cmd1.Send.bind(cmd1));
    } catch (err) {
        Logger.warn(err as string, 'Failed to send chunks stats');
    }
    try {
        yield effects.call(cmd2.Send.bind(cmd2));
    } catch (err) {
        Logger.warn(err as string, 'Failed to send manifest stats');
    }
}

function* handleStreamRequests(action: ReturnType<typeof askStream>) {
    const time = new Date(action.payload.time.getTime() - 5000);

    try {
        const cmd = new GetStream(action.payload.mediaID, time);

        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to ask stream');
    }
}

function* watchChunkRequests() {
    yield effects.takeEvery(PlayerActionTypes.ASK_CHUNK, handleChunkRequests);
}

function* watchStatsRequests() {
    yield effects.takeLatest(PlayerActionTypes.SEND_STATS, handleStatsRequests);
}

function* watchStreamRequests() {
    yield effects.takeEvery(PlayerActionTypes.ASK_STREAM, handleStreamRequests);
}

export function* PlayerSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchChunkRequests)]);
    yield effects.all([effects.fork(watchStatsRequests)]);
    yield effects.all([effects.fork(watchStreamRequests)]);
}
