import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class CreateAnnotation extends AMessage implements IToCommand {
    private annotation: Proto.mediaarchiver.ArgumentsCreateAnnotation;

    public constructor(annotation: Proto.mediaarchiver.ArgumentsCreateAnnotation) {
        super('createAnnotation');
        this.annotation = annotation;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            CreateAnnotationArg : this.annotation,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeCreateAnnotation,
        }));
    }
}
