import { AMessage, IToCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';

export class TalkEditTalk extends AMessage implements IToCommand {
    private talk: mediaarchiver.ITalk;

    public constructor(talk: mediaarchiver.ITalk) {
        super('talkEditTalk');
        this.talk = talk;
    }

    public Send(): Promise<void> {
        return this.send(
            new mediaarchiver.WSMessage({
                TalkEditArg: this.talk,
                Type: mediaarchiver.WSMessageType.MessageTypeTalkEdit,
            }),
        );
    }
}
