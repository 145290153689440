import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefined } from '../../Utils/Various';

export class TalkAllRegims extends AMessage implements IFromCommand {
    private parsedRegims: Proto.mediaarchiver.ITalkRegim[] | null = null;

    public constructor() {
        super('talkAllRegims');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.TalkAllRegimsArg) || isNullOrUndefined(msg.TalkAllRegimsArg.Regims)) {
                return reject(new Error('Not a TalkAllRegims message'));
            }
            this.parsedRegims = msg.TalkAllRegimsArg.Regims;
            resolve();
        });
    }

    public get regims(): Proto.mediaarchiver.ITalkRegim[] {
        if (isNullOrUndefined(this.parsedRegims)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedRegims;
    }
}
