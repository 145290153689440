import { action } from 'typesafe-actions';

import * as Proto from '../../Protos/protos';
import {
    IAddFileAction,
    ILoadFileStorageAction,
    IClearListAction,
    ILoadFileAction,
    ISetCurrentFileAction,
    IsetErrorAction,
    XMLActionTypes,
    ICheckListAction,
} from './Types';

export const addFile = (n: string, v: Proto.mediaarchiver.IEPGProgram[]): IAddFileAction =>
    action(XMLActionTypes.ADD_FILE, [n, v]);
export const loadFileStorage = (): ILoadFileStorageAction => action(XMLActionTypes.LOAD_FILE_STORAGE);
export const checkList = (d: Date, f: Date): ICheckListAction => action(XMLActionTypes.CHECK_LIST, [d, f]);
export const clearList = (): IClearListAction => action(XMLActionTypes.CLEAR_LIST);
export const loadFile = (f: File): ILoadFileAction => action(XMLActionTypes.LOAD_FILE, f);
export const setCurrentFile = (f: string): ISetCurrentFileAction => action(XMLActionTypes.SET_CURRENT_FILE, f);
export const setError = (err: string): IsetErrorAction => action(XMLActionTypes.SET_ERROR, err);
