import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import Layout from '../../Components/Layout';
import { MailsPrevent } from '../../Components/Mails/Prevent';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { hideAppBar, setDrawerContent, setPageContent } from '../../Store/Layout';
import { getTheme } from '../../Themes';

// interface IPropsFromState {}

interface IPropsFromDispatch {
    hideAppBar: typeof hideAppBar;
    setDrawerContent: typeof setDrawerContent;
    setPageContent: typeof setPageContent;
}

const theme = getTheme();

const styles = MD.createStyles({
    content: {
        flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
});

type AllProps =
    // IPropsFromState &
    IPropsFromDispatch & RouteComponentProps<{}> & IConnectedReduxProps;

export class MailsPreventPageComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        this.props.hideAppBar();
        this.props.setDrawerContent(null);
        this.props.setPageContent(this.renderPageContent());

        return <Route component={Layout} />;
    }

    private renderPageContent(): React.ReactNode {
        return <Route component={MailsPrevent} />;
    }
}

const mapStateToProps = ({}: IApplicationState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hideAppBar: () => dispatch(hideAppBar()),
    setDrawerContent: (content: React.ReactNode) => dispatch(setDrawerContent(content)),
    setPageContent: (content: React.ReactNode) => dispatch(setPageContent(content)),
});

export const MailsPreventPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(MailsPreventPageComponent));
