import { Reducer, Store } from 'redux';
import { action as newAction } from 'typesafe-actions';

import { store } from '../../Store/store';
import { TypedStorage } from '../../Utils/TypedStorage';
import { isNull, isNullOrUndefined } from '../../Utils/Various';
import { TimelineActionTypes } from '../Timeline/Types';
import { PlayerStatus } from './Constant';
import { IPlayerState, IPlayerStateSteps, PlayerActionTypes } from './Types';

const initialState: IPlayerState = {
    endSelection: null,
    player: null,
    positionUpdateEventElem: document.createElement('a'),
    snapshot: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    startSelection: null,
    status: PlayerStatus.NULL,
    steps: {
        backward: TypedStorage.get('stepBackward', 40),
        fastBackward: TypedStorage.get('stepFastBackward', 500),
        fastForward: TypedStorage.get('stepFastForward', 500),
        forward:  TypedStorage.get('stepForward', 40),
    },
};

const reducer: Reducer<IPlayerState> = (state = initialState, action) => {
    switch (action.type) {

        case PlayerActionTypes.END_SELECTION: {
            return { ...state, endSelection: action.payload };
        }

        case PlayerActionTypes.SET_PLAYER: {
            if (!isNullOrUndefined(state.player)) {
                state.player.destroy();
            }
            return { ...state, player: action.payload };
        }

        case PlayerActionTypes.SET_POSITION: {
            state.positionUpdateEventElem.dispatchEvent(
                new CustomEvent('timeUpdate', { detail: action.payload }),
            );
            return state;
        }

        case PlayerActionTypes.SET_SNAPSHOT: {
            const data = (isNull(action.payload)) ?
                'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' :
                action.payload;

            return { ...state, snapshot: data };
        }

        case PlayerActionTypes.SET_STATUS: {
            return { ...state, status: action.payload };
        }

        case PlayerActionTypes.SET_STEPS: {
            const steps: IPlayerStateSteps = action.payload;

            TypedStorage.set('stepBackward', steps.backward);
            TypedStorage.set('stepFastBackward', steps.fastBackward);
            TypedStorage.set('stepFastForward', steps.fastForward);
            TypedStorage.set('stepForward', steps.forward);
            return { ...state, steps };
        }

        case PlayerActionTypes.START_SELECTION: {
            return { ...state, startSelection: action.payload };
        }

        case PlayerActionTypes.REFRESH_TIMELINE: {
            window.setTimeout(
                () => {
                    (store as Store).dispatch(
                        newAction(TimelineActionTypes.UPDATE_TIMELINE),
                    );
                },
                50,
            );
            return state;
        }

        default: {
            return state;
        }
    }
};

export { reducer as PlayerReducer };
