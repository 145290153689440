/* eslint-disable react/prop-types */
import { Drawer, WithStyles } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import classNames from 'classnames';
import { IConnectedReduxProps } from '../../Store';
import { ILayoutState } from '../../Store/Layout';
import { isNullOrUndefined } from '../../Utils/Various';
import styles from './styles';

interface IPropsFromState {
    layout: ILayoutState;
    width: string;
}

type AllProps = WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

const DrawerComponent: React.FC<AllProps> = ({ classes, layout }) => {
    if (isNullOrUndefined(layout.drawerContent)) {
        return <span />;
    }
    return (
        <Drawer
            anchor='left'
            classes={{
                paper: classes.drawerPaper,
            }}
            className={classNames(classes.drawer, {
                hidden: !(!isNullOrUndefined(layout.drawerContent) && layout.showDrawer),
            })}
            open={layout.showDrawer}
            variant='persistent'
        >
            <div className={classes.toolbar} />
            {layout.drawerContent}
        </Drawer>
    );
};

export default DrawerComponent;
