import { colors, createMuiTheme, Theme } from '@material-ui/core';

import WebFont from 'webfontloader';

interface ICustomThemeDimensionsProps {
    appBarHeight: string;
    audienceBarsWidth: number;
    drawerWidth: number;
    footerHeight: number;
}

interface ICustomThemeColorsProps {
    audienceBars: string;
    backgroundPoliticalDefault: string;
    backgroundProgramBackground: string;
    backgroundProgramBackgroundSecondary: string;
    crossbucketProgramCrossBucket: string;
    crossbucketProgramCrossBucketSecondary: string;
    calendarActiveDayBackground: string;
    calendarActiveDayText: string;
    calendarCurrentDayBackground: string;
    calendarCurrentDayText: string;
    cursorCurrent: string;
    cursorSelectionEnd: string;
    cursorSelectionStart: string;
    musicProgramBackground: string;
    selectionHighlight: string;
    xmlProgramBackground: string;
}

interface ICustomThemeFontsProps {
    infosTableFontSize: number;
    global: string;
    globalSize: string;
    time: string;
}

interface ICustomThemeProps {
    colors: ICustomThemeColorsProps;
    dimensions: ICustomThemeDimensionsProps;
    fonts: ICustomThemeFontsProps;
}

interface ICustomProps {
    mediaarchiver: ICustomThemeProps;
}

export type MediaArchiverTheme = Theme & ICustomProps;

WebFont.load({
    google: {
        families: ['Anonymous Pro:300,400,700', 'Roboto:300,400,500', 'monospace'],
    },
});

export const getTheme = (): MediaArchiverTheme => {
    const theme = createMuiTheme({
        palette: {
            // Used by `getContrastText()` to maximize the contrast between the background and
            // the text.
            contrastThreshold: 3,
            error: colors.red,
            primary: colors.grey,
            secondary: colors.lightBlue,
            // Used to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
            type: 'dark',
        },
        typography: {
            // useNextVariants: true,
        },
    });

    const custom: ICustomProps = {
        mediaarchiver: {
            colors: {
                audienceBars: '#0091ea',
                backgroundPoliticalDefault: '#616161',
                backgroundProgramBackground: '#0091ea',
                backgroundProgramBackgroundSecondary: '#92cceb',
                crossbucketProgramCrossBucket: '#0091ea',
                crossbucketProgramCrossBucketSecondary: '#92cceb',
                calendarActiveDayBackground: '#5ea5fb',
                calendarActiveDayText: '#ffffff',
                calendarCurrentDayBackground: '#c8cfda',
                calendarCurrentDayText: '#000000',
                cursorCurrent: '#4774ab',
                cursorSelectionEnd: 'red',
                cursorSelectionStart: 'green',
                musicProgramBackground: '#0091ea',
                selectionHighlight: 'rgba(160, 180, 150, 0.3)',
                xmlProgramBackground: '#b7b7b7',
            },
            dimensions: {
                appBarHeight: '90px',
                audienceBarsWidth: 10,
                drawerWidth: 300,
                footerHeight: 60,
            },
            fonts: {
                global: '"Roboto", "Helvetica", "Arial", sans-serif',
                globalSize: '0.8em',
                infosTableFontSize: 11,
                time: '"Roboto", "Helvetica", "Arial", sans-serif',
            },
        },
    };

    theme.overrides = {
        MuiTab: {
            /*
            label: {
                '&:hover': {
                    opacity: 1,
                },
                color: '#fff',
                opacity: 0.7,
                transition: theme.transitions.create('opacity'),
            },
            */
        },
    };

    return { ...custom, ...theme };
};

export function getDeclarativeCategoryColor(category: string): string {
    switch (category) {
        case 'film': {
            return '#24abba';
        } // old id: 1
        case 'telefilm': {
            return '#4bb5c1';
        } // old id: 2
        case 'téléfilm': {
            return '#4bb5c1';
        } // old id: 2
        case 'serie_feuilleton': {
            return '#7fc6bc';
        } // old id: 3
        case 'série_feuilleton': {
            return '#7fc6bc';
        } // old id: 3
        case 'feuilleton': {
            return '#7fc6bc';
        } // old id: 4
        case 'documentaire': {
            return '#bdb024';
        } // old id: 5
        case 'theatre': {
            return '#c69350';
        } // old id: 6
        case 'théatre': {
            return '#c69350';
        } // old id: 6
        case 'opera': {
            return '#c69350';
        } // old id: 7
        case 'ballet': {
            return '#c69350';
        } // old id: 8
        case 'variete': {
            return '#876d9b';
        } // old id: 9
        case 'variété': {
            return '#876d9b';
        } // old id: 9
        case 'magazine': {
            return '#a3d9ff';
        } // old id: 10
        case 'jeunesse': {
            return '#d9fc49';
        } // old id: 11
        case 'jeu': {
            return '#a600d4';
        } // old id: 12
        case 'musique': {
            return '#876d9b';
        } // old id: 13
        case 'divertissement': {
            return '#d7fce1';
        } // old id: 14
        case 'court_metrage': {
            return '#24abba';
        } // old id: 15
        case 'court_métrage': {
            return '#24abba';
        } // old id: 15
        case 'dessin_anime': {
            return '#d9fc49';
        } // old id: 16
        case 'dessin_animé': {
            return '#d9fc49';
        } // old id: 16
        case 'sport': {
            return '#84c125';
        } // old id: 19
        case 'journal': {
            return '#2d80dd';
        } // old id: 20
        case 'information': {
            return '#2d80dd';
        } // old id: 21
        case 'debat': {
            return '#2d80dd';
        } // old id: 22
        case 'débat': {
            return '#2d80dd';
        } // old id: 22
        case 'danse': {
            return '#c69350';
        } // old id: 23
        case 'spectacle': {
            return '#c69350';
        } // old id: 24
        case 'gala': {
            return '#c69350';
        } // old id: 25
        case 'reportage': {
            return '#2d80dd';
        } // old id: 26
        case 'fin_emission': {
            return '#cccccc';
        } // old id: 27
        case 'fin_émission': {
            return '#cccccc';
        } // old id: 27
        case 'religieux': {
            return '#cccccc';
        } // old id: 31
        case 'concert': {
            return '#c69350';
        } // old id: 32
        case 'clips': {
            return '#876d9b';
        } // old id: 33
        case 'meteo': {
            return '#81b7f4';
        } // old id: 34
        case 'météo': {
            return '#81b7f4';
        } // old id: 34
        case 'makingof': {
            return '#24abba';
        } // old id: 35
        case 'ceremonie': {
            return '#ff0000';
        } // old id: 36
        case 'cérémonie': {
            return '#ff0000';
        } // old id: 36
        case 'talk_show': {
            return '#a0cbd9';
        } // old id: 37
        case 'telerealite': {
            return '#a8afd3';
        } // old id: 38
        case 'téléréalité': {
            return '#a8afd3';
        } // old id: 38
        case 'programme_court': {
            return '#4bb5c1';
        } // old id: 39
        case 'serie_documentaire': {
            return '#bdb024';
        } // old id: 40
        case 'série_documentaire': {
            return '#bdb024';
        } // old id: 40
        case 'indetermine': {
            return '#dddddd';
        } // old id: 0
        default:
            return '#dddddd'; // INDETERMINE
    }
}

export function getNielsenCategoryColor(category: string): string {
    switch (category.toLocaleLowerCase()) {
        case 'zeichentrickserie': {
            return '#ff3933';
        }
        case 'animationsserie': {
            return '#ff3933';
        }
        case 'krimiserie': {
            return '#ff3933';
        }
        case 'sitcom': {
            return '#ff3933';
        }
        case 'comedyserie': {
            return '#ff3933';
        }
        case 'jugendserie': {
            return '#ff3933';
        }
        case 'animeserie': {
            return '#ff3933';
        }
        case 'actionserie': {
            return '#ff3933';
        }
        case 'kinder-comedyserie': {
            return '#ff3933';
        }
        case 'sci-fi-serie': {
            return '#ff3933';
        }
        case 'telenovela': {
            return '#ff3933';
        }
        case 'mysteryserie': {
            return '#ff3933';
        }
        case 'arztserie': {
            return '#ff3933';
        }
        case 'dramaserie': {
            return '#ff3933';
        }
        case 'soap': {
            return '#ff3933';
        }
        case 'krimireihe': {
            return '#ff3933';
        }
        case 'familiensaga': {
            return '#ff3933';
        }
        case 'detektivserie': {
            return '#ff3933';
        }
        case 'familienserie': {
            return '#ff3933';
        }
        case 'fantasyserie': {
            return '#ff3933';
        }
        case 'krankenhausserie': {
            return '#ff3933';
        }
        case 'unterhaltungsserie': {
            return '#ff3933';
        }
        case 'justizserie': {
            return '#ff3933';
        }
        case 'abenteuerserie': {
            return '#ff3933';
        }
        case 'kinder-abenteuerserie': {
            return '#ff3933';
        }
        case 'dramaserie': {
            return '#ff3933';
        }
        case 'krimiserie': {
            return '#ff3933';
        }
        case 'westernserie': {
            return '#ff3933';
        }
        case 'erotikserie': {
            return '#ff3933';
        }
        case 'horrorserie': {
            return '#ff3933';
        }
        case 'historienserie': {
            return '#ff3933';
        }
        case 'kinder-fantasyserie': {
            return '#ff3933';
        }
        case 'kinderserie': {
            return '#ff3933';
        }
        case 'abenteuerserie': {
            return '#ff3933';
        }
        case 'serie': {
            return '#ff3933';
        }
        case 'animationsserie': {
            return '#ff3933';
        }
        case 'dokudrama': {
            return '#ff3933';
        }
        case 'comedyserie': {
            return '#ff3933';
        }
        case 'komödie': {
            return '#e97310';
        }
        case 'animationsfilm': {
            return '#e97310';
        }
        case 'actionfilm': {
            return '#e97310';
        }
        case 'thriller': {
            return '#e97310';
        }
        case 'actionkomödie': {
            return '#e97310';
        }
        case 'drama': {
            return '#e97310';
        }
        case 'abenteuerfilm': {
            return '#e97310';
        }
        case 'fantasyfilm': {
            return '#e97310';
        }
        case 'actionthriller': {
            return '#e97310';
        }
        case 'sci-fi-film': {
            return '#e97310';
        }
        case 'horrorfilm': {
            return '#e97310';
        }
        case 'horrorthriller': {
            return '#e97310';
        }
        case 'krimikomödie': {
            return '#e97310';
        }
        case 'tragikomödie': {
            return '#e97310';
        }
        case 'romantikkomödie': {
            return '#e97310';
        }
        case 'liebeskomödie': {
            return '#e97310';
        }
        case 'musikkomödie': {
            return '#e97310';
        }
        case 'zeichentrickfilm': {
            return '#e97310';
        }
        case 'fantasykomödie': {
            return '#e97310';
        }
        case 'mysterythriller': {
            return '#e97310';
        }
        case 'horrorkomödie': {
            return '#e97310';
        }
        case 'biografie': {
            return '#e97310';
        }
        case 'kriegsfilm': {
            return '#e97310';
        }
        case 'liebesdrama': {
            return '#e97310';
        }
        case 'familienfilm': {
            return '#e97310';
        }
        case 'kriegsdrama': {
            return '#e97310';
        }
        case 'katastrophenfilm': {
            return '#e97310';
        }
        case 'sci-fi-komödie': {
            return '#e97310';
        }
        case 'westernkomödie': {
            return '#e97310';
        }
        case 'sci-fi-horror': {
            return '#e97310';
        }
        case 'western': {
            return '#e97310';
        }
        case 'kinderfilm': {
            return '#e97310';
        }
        case 'agentenkomödie': {
            return '#e97310';
        }
        case 'italowestern': {
            return '#e97310';
        }
        case 'neowestern': {
            return '#e97310';
        }
        case 'historienfilm': {
            return '#e97310';
        }
        case 'bibelfilm': {
            return '#e97310';
        }
        case 'drama': {
            return '#e97310';
        }
        case 'westernparodie': {
            return '#e97310';
        }
        case 'jugendfilm': {
            return '#e97310';
        }
        case 'sozialdrama': {
            return '#e97310';
        }
        case 'tanzfilm': {
            return '#e97310';
        }
        case 'zirkusfilm': {
            return '#e97310';
        }
        case 'liebesmelodram': {
            return '#e97310';
        }
        case 'sonstige': {
            return '#e97310';
        }
        case 'nachrichten': {
            return '#1069e9';
        }
        case 'werbesendung': {
            return '#1069e9';
        }
        case 'wetterbericht': {
            return '#1069e9';
        }
        case 'werbesendung': {
            return '#1069e9';
        }
        case 'werbesendung': {
            return '#1069e9';
        }
        case 'wetterbericht': {
            return '#1069e9';
        }
        case 'werbesendung': {
            return '#1069e9';
        }
        case 'programm nach ansage': {
            return '#1069e9';
        }
        case 'kamerafahrt': {
            return '#1069e9';
        }
        case 'lottoziehung': {
            return '#1069e9';
        }
        case 'lottoziehung': {
            return '#1069e9';
        }
        case 'lottoziehung': {
            return '#1069e9';
        }
        case 'recht + kriminalität': {
            return '#7064aa';
        }
        case 'doku-soap': {
            return '#7064aa';
        }
        case 'recht + kriminalität': {
            return '#7064aa';
        }
        case 'wirtschaft + konsum': {
            return '#7064aa';
        }
        case 'infomagazin': {
            return '#7064aa';
        }
        case 'dokumentation': {
            return '#7064aa';
        }
        case 'regionalmagazin': {
            return '#7064aa';
        }
        case 'gesellschaft + soziales': {
            return '#7064aa';
        }
        case 'essen + trinken': {
            return '#7064aa';
        }
        case 'zeitgeschehen': {
            return '#7064aa';
        }
        case 'menschen': {
            return '#7064aa';
        }
        case 'recht + kriminalität': {
            return '#7064aa';
        }
        case 'wissen': {
            return '#7064aa';
        }
        case 'technik': {
            return '#7064aa';
        }
        case 'gesellschaft + soziales': {
            return '#7064aa';
        }
        case 'menschen': {
            return '#7064aa';
        }
        case 'zeitgeschehen': {
            return '#7064aa';
        }
        case 'wohnen': {
            return '#7064aa';
        }
        case 'geschichte': {
            return '#7064aa';
        }
        case 'wissen': {
            return '#7064aa';
        }
        case 'verkehr': {
            return '#7064aa';
        }
        case 'kirche + religion': {
            return '#7064aa';
        }
        case 'mode': {
            return '#7064aa';
        }
        case 'lifestyle': {
            return '#7064aa';
        }
        case 'gesellschaft + soziales': {
            return '#7064aa';
        }
        case 'wissenschaft': {
            return '#7064aa';
        }
        case 'wissenschaft': {
            return '#7064aa';
        }
        case 'essen + trinken': {
            return '#7064aa';
        }
        case 'wirtschaft + konsum': {
            return '#7064aa';
        }
        case 'essen + trinken': {
            return '#7064aa';
        }
        case 'verkehr': {
            return '#7064aa';
        }
        case 'zeitgeschehen': {
            return '#7064aa';
        }
        case 'abenteuer + action': {
            return '#7064aa';
        }
        case 'reportage': {
            return '#7064aa';
        }
        case 'technik': {
            return '#7064aa';
        }
        case 'ratgeber': {
            return '#7064aa';
        }
        case 'technik': {
            return '#7064aa';
        }
        case 'wissen': {
            return '#7064aa';
        }
        case 'essen + trinken': {
            return '#7064aa';
        }
        case 'luftverkehr': {
            return '#7064aa';
        }
        case 'menschen': {
            return '#7064aa';
        }
        case 'gesellschaft + soziales': {
            return '#7064aa';
        }
        case 'abenteuer + action': {
            return '#7064aa';
        }
        case 'essen + trinken': {
            return '#7064aa';
        }
        case 'recht + kriminalität': {
            return '#7064aa';
        }
        case 'infotainment': {
            return '#7064aa';
        }
        case 'recht + kriminalität': {
            return '#7064aa';
        }
        case 'technik': {
            return '#7064aa';
        }
        case 'kirche + religion': {
            return '#7064aa';
        }
        case 'architektur': {
            return '#7064aa';
        }
        case 'werbung': {
            return '#7064aa';
        }
        case 'astronomie': {
            return '#7064aa';
        }
        case 'wissen': {
            return '#7064aa';
        }
        case 'zeitgeschichte': {
            return '#7064aa';
        }
        case 'freizeit + hobby': {
            return '#7064aa';
        }
        case 'verkehr': {
            return '#7064aa';
        }
        case 'wirtschaft + konsum': {
            return '#7064aa';
        }
        case 'gesellschaft + soziales': {
            return '#7064aa';
        }
        case 'dokumentation': {
            return '#7064aa';
        }
        case 'menschen': {
            return '#7064aa';
        }
        case 'psychologie': {
            return '#7064aa';
        }
        case 'wissenschaft': {
            return '#7064aa';
        }
        case 'geschichte': {
            return '#7064aa';
        }
        case 'lifestyle': {
            return '#7064aa';
        }
        case 'familie + erziehung': {
            return '#7064aa';
        }
        case 'wirtschaft + konsum': {
            return '#7064aa';
        }
        case 'freizeit + hobby': {
            return '#7064aa';
        }
        case 'wohnen': {
            return '#7064aa';
        }
        case 'abenteuer + action': {
            return '#7064aa';
        }
        case 'gesellschaft + soziales': {
            return '#7064aa';
        }
        case 'zeitgeschehen': {
            return '#7064aa';
        }
        case 'wohnen': {
            return '#7064aa';
        }
        case 'menschen': {
            return '#7064aa';
        }
        case 'infotainment': {
            return '#7064aa';
        }
        case 'technik': {
            return '#7064aa';
        }
        case 'verkehr': {
            return '#7064aa';
        }
        case 'recht + kriminalität': {
            return '#7064aa';
        }
        case 'politik': {
            return '#7064aa';
        }
        case 'architektur': {
            return '#7064aa';
        }
        case 'wissen': {
            return '#7064aa';
        }
        case 'politik': {
            return '#7064aa';
        }
        case 'gesellschaft + soziales': {
            return '#7064aa';
        }
        case 'essen + trinken': {
            return '#7064aa';
        }
        case 'esoterik': {
            return '#7064aa';
        }
        case 'technik': {
            return '#7064aa';
        }
        case 'menschen': {
            return '#7064aa';
        }
        case 'luftverkehr': {
            return '#7064aa';
        }
        case 'dokumentation': {
            return '#7064aa';
        }
        case 'wirtschaft + konsum': {
            return '#7064aa';
        }
        case 'reportage': {
            return '#7064aa';
        }
        case 'essen + trinken': {
            return '#7064aa';
        }
        case 'lifestyle': {
            return '#7064aa';
        }
        case 'reportage': {
            return '#7064aa';
        }
        case 'archäologie': {
            return '#7064aa';
        }
        case 'militär': {
            return '#7064aa';
        }
        case 'infomagazin': {
            return '#7064aa';
        }
        case 'wissenschaft': {
            return '#7064aa';
        }
        case 'recht + kriminalität': {
            return '#7064aa';
        }
        case 'menschen': {
            return '#7064aa';
        }
        case 'freizeit + hobby': {
            return '#7064aa';
        }
        case 'wirtschaft + konsum': {
            return '#7064aa';
        }
        case 'beruf + bildung': {
            return '#7064aa';
        }
        case 'zeitgeschichte': {
            return '#7064aa';
        }
        case 'medien': {
            return '#7064aa';
        }
        case 'menschen': {
            return '#7064aa';
        }
        case 'recht + kriminalität': {
            return '#7064aa';
        }
        case 'politik': {
            return '#7064aa';
        }
        case 'freizeit + hobby': {
            return '#7064aa';
        }
        case 'zeitgeschichte': {
            return '#7064aa';
        }
        case 'europa': {
            return '#7064aa';
        }
        case 'drogen': {
            return '#7064aa';
        }
        case 'archäologie': {
            return '#7064aa';
        }
        case 'kriminalfilm': {
            return '#e3cd34';
        }
        case 'erotikfilm': {
            return '#e3cd34';
        }
        case 'komödie': {
            return '#e3cd34';
        }
        case 'märchenfilm': {
            return '#e3cd34';
        }
        case 'thriller': {
            return '#e3cd34';
        }
        case 'katastrophenfilm': {
            return '#e3cd34';
        }
        case 'abenteuerfilm': {
            return '#e3cd34';
        }
        case 'krimikomödie': {
            return '#e3cd34';
        }
        case 'romanze': {
            return '#e3cd34';
        }
        case 'melodram': {
            return '#e3cd34';
        }
        case 'drama': {
            return '#e3cd34';
        }
        case 'sci-fi-film': {
            return '#e3cd34';
        }
        case 'romantikkomödie': {
            return '#e3cd34';
        }
        case 'actionkomödie': {
            return '#e3cd34';
        }
        case 'animationsfilm': {
            return '#e3cd34';
        }
        case 'liebesdrama': {
            return '#e3cd34';
        }
        case 'familienfilm': {
            return '#e3cd34';
        }
        case 'familiensaga': {
            return '#e3cd34';
        }
        case 'liebesgeschichte': {
            return '#e3cd34';
        }
        case 'biografie': {
            return '#e3cd34';
        }
        case 'heimatfilm': {
            return '#e3cd34';
        }
        case 'liebesfilm': {
            return '#e3cd34';
        }
        case 'actionfilm': {
            return '#e3cd34';
        }
        case 'kinderfilm': {
            return '#e3cd34';
        }
        case 'dokudrama': {
            return '#e3cd34';
        }
        case 'zeichentrickfilm': {
            return '#e3cd34';
        }
        case 'horrorfilm': {
            return '#e3cd34';
        }
        case 'tragikomödie': {
            return '#e3cd34';
        }
        case 'mysterythriller': {
            return '#e3cd34';
        }
        case 'erotik': {
            return '#e334a6';
        }
        case 'quiz': {
            return '#e334a6';
        }
        case 'doku-soap': {
            return '#e334a6';
        }
        case 'reality-soap': {
            return '#e334a6';
        }
        case 'show': {
            return '#e334a6';
        }
        case 'unterhaltung': {
            return '#e334a6';
        }
        case 'show': {
            return '#e334a6';
        }
        case 'musikshow': {
            return '#e334a6';
        }
        case 'comedyshow': {
            return '#e334a6';
        }
        case 'kochshow': {
            return '#e334a6';
        }
        case 'clipshow': {
            return '#e334a6';
        }
        case 'reality-soap': {
            return '#e334a6';
        }
        case 'spielshow': {
            return '#e334a6';
        }
        case 'comedy': {
            return '#e334a6';
        }
        case 'doku-soap': {
            return '#e334a6';
        }
        case 'comedy': {
            return '#e334a6';
        }
        case 'clipshow': {
            return '#e334a6';
        }
        case 'ratespiel': {
            return '#e334a6';
        }
        case 'kabarett + satire': {
            return '#e334a6';
        }
        case 'lifestyle': {
            return '#e334a6';
        }
        case 'gewinnshow': {
            return '#e334a6';
        }
        case 'dateshow': {
            return '#e334a6';
        }
        case 'castingshow': {
            return '#e334a6';
        }
        case 'rankingshow': {
            return '#e334a6';
        }
        case 'musikshow': {
            return '#e334a6';
        }
        case 'wissen': {
            return '#e334a6';
        }
        case 'sketch': {
            return '#e334a6';
        }
        case 'dateshow': {
            return '#e334a6';
        }
        case 'unterhaltung': {
            return '#e334a6';
        }
        case 'kabarett + satire': {
            return '#e334a6';
        }
        case 'show': {
            return '#e334a6';
        }
        case 'fußball': {
            return '#41cd30';
        }
        case 'fußball': {
            return '#41cd31';
        }
        case 'fußball': {
            return '#41cd32';
        }
        case 'fußball': {
            return '#41cd33';
        }
        case 'fußball': {
            return '#41cd34';
        }
        case 'musikmagazin': {
            return '#f50faf';
        }
        case 'rock + pop': {
            return '#f50faf';
        }
        case 'rock + pop': {
            return '#f50faf';
        }
        case 'leichte musik': {
            return '#f50faf';
        }
        case 'rock + pop': {
            return '#f50faf';
        }
        case 'dokumentation': {
            return '#f50faf';
        }
        case 'klassische musik': {
            return '#f50faf';
        }
        case 'dokumentation': {
            return '#f50faf';
        }
        case 'volksmusik': {
            return '#f50faf';
        }
        case 'diskussion': {
            return '#0fe4f5';
        }
        case 'zeitgeschehen': {
            return '#0fe4f5';
        }
        case 'diskussion': {
            return '#0fe4f5';
        }
        case 'rallye': {
            return '#765a14';
        }
        case 'motorsport': {
            return '#765a14';
        }
        case 'motorsport': {
            return '#765a14';
        }
        case 'rallye': {
            return '#765a14';
        }
        case 'film + theater': {
            return '#c31832';
        }
        case 'architektur': {
            return '#c31832';
        }
        case 'brauchtum': {
            return '#c31832';
        }
        case 'literatur': {
            return '#c31832';
        }
        case 'magazin': {
            return '#c31832';
        }
        case 'film + theater': {
            return '#c31832';
        }
        case 'dokumentation': {
            return '#a7ea7c';
        }
        case 'dokumentation': {
            return '#a7ea7c';
        }
        case 'ratgeber': {
            return '#a7ea7c';
        }
        case 'dokumentation': {
            return '#a7ea7c';
        }
        case 'ratgeber': {
            return '#a7ea7c';
        }
        case 'fitness': {
            return '#a7ea7c';
        }
        case 'dokumentation': {
            return '#a7ea7c';
        }
        case 'land + leute': {
            return '#c37114';
        }
        case 'natur + umwelt': {
            return '#c37114';
        }
        case 'tiere': {
            return '#c37114';
        }
        case 'tiere': {
            return '#c37114';
        }
        case 'natur + umwelt': {
            return '#c37114';
        }
        case 'tiere': {
            return '#c37114';
        }
        case 'natur + umwelt': {
            return '#c37114';
        }
        case 'tiere': {
            return '#c37114';
        }
        case 'landschaftsbild': {
            return '#c37114';
        }
        case 'tourismus': {
            return '#c37114';
        }
        case 'stadtbild': {
            return '#c37114';
        }
        case 'land + leute': {
            return '#c37114';
        }
        case 'pflanzen': {
            return '#c37114';
        }
        case 'tiere': {
            return '#c37114';
        }
        case 'tourismus': {
            return '#c37114';
        }
        case 'land + leute': {
            return '#c37114';
        }
        case 'natur + umwelt': {
            return '#c37114';
        }
        case 'natur + umwelt': {
            return '#c37114';
        }
        case 'tiere': {
            return '#c37114';
        }
        case 'sportnews': {
            return '#180673';
        }
        case 'sportnews': {
            return '#180674';
        }
        case 'gymnastik': {
            return '#4f4585';
        }
        case 'sport': {
            return '#2a03fe';
        }
        case 'magazin': {
            return '#03d4fe';
        }
        case 'magazin': {
            return '#03d4fe';
        }
        case 'magazin': {
            return '#03d4fe';
        }
        case 'talkshow': {
            return '#aeedf9';
        }
        case 'quiz': {
            return '#3c4d50';
        }
        case 'darts': {
            return '#c5c8c8';
        }
        case 'darts': {
            return '#c5c8c8';
        }
        case 'basketball': {
            return '#b76503';
        }
        case 'e-sport': {
            return '#444152';
        }
        case 'kindersendung': {
            return '#cdf58c';
        }
        case 'kindermagazin': {
            return '#cdf58c';
        }
        case 'kindersendung': {
            return '#cdf58c';
        }
        case 'ratespiel': {
            return '#cdf58c';
        }
        case 'kindermagazin': {
            return '#cdf58c';
        }
        case 'kindersendung': {
            return '#cdf58c';
        }
        case 'kindergeschichte': {
            return '#cdf58c';
        }
        case 'reportagen': {
            return '#2e015d';
        }
        case 'lifestyle': {
            return '#85d3fa';
        }
        case 'talkshow': {
            return '#85d3fa';
        }
        case 'talkshow': {
            return '#85d3fa';
        }
        case 'talk + musik': {
            return '#85d3fa';
        }
        case 'politik': {
            return '#85d3fa';
        }
        case 'handball': {
            return '#3a29cc';
        }
        case 'handball': {
            return '#3a29cc';
        }
        case 'biathlon': {
            return '#dfdcf3';
        }
        case 'trickfilm': {
            return '#559030';
        }
        case 'animationsfilm': {
            return '#559030';
        }
        case 'informatik': {
            return '#6b9750';
        }
        case 'sonstige': {
            return '#090a09';
        }
        case 'sonstige': {
            return '#090a09';
        }
        case 'sonstige': {
            return '#090a09';
        }
        case 'sonstige': {
            return '#090a09';
        }
        case 'langlauf': {
            return '#d7fefe';
        }
        case 'skispringen': {
            return '#d7fefe';
        }
        case 'nordische kombination': {
            return '#d7fefe';
        }
        case 'olympische spiele': {
            return '#81bcff';
        }
        case 'ski alpin': {
            return '#b3c6db';
        }
        case 'rodeln': {
            return '#b5bbc2';
        }
        case 'wrestling': {
            return '#38587e';
        }
        case 'wrestling': {
            return '#38587e';
        }
        case 'american football': {
            return '#3ca040';
        }
        case 'american football': {
            return '#3ca040';
        }
        case 'tischtennis': {
            return '#037607';
        }
        case 'volleyball': {
            return '#6b950b';
        }
        case 'eishockey': {
            return '#d4fcfe';
        }
        case 'tennis': {
            return '#fab336';
        }
        case 'snooker': {
            return '#438e33';
        }
        case 'kirche + religion': {
            return '#55fbfc';
        }
        case 'politik': {
            return '#fc5583';
        }
        case 'judo': {
            return '#46497c';
        }
        default: {
            return '#dddddd';
        }
    }
}

export function getResultProcessedCategoryColor(category: string): string {
    switch (category) {
        case 'true': {
            return '#00FF00'
        }
        case 'false': {
            return '#FF0000'
        }
        default:
            return '#dddddd';
    }
}

export function getResultExtractCategoryColor(category: string): string {
    switch (category) {
        case '1': {
            return '#0000FF'
        }
        case '2': {
            return '#C4B454'
        }
        case '3': {
            return '#00FF00'
        }
        case '4' || '5':{
            return '#FF0000'
        }
        default:
            return '#dddddd';
    }
}

export function getReadjustedCategoryColor(category: string): string {
    switch (category.substr(0, 1)) {
        case 'A': {
            return '#2BA0FF';
        }
        case 'B': {
            return '#C2BE8C';
        }
        case 'C': {
            return '#F63AFC';
        }
        case 'D': {
            return '#FFDD00';
        }
        case 'E': {
            return '#3EBA00';
        }
        case 'F': {
            return '#B2F202';
        }
        case 'G': {
            return '#FAA5EA';
        }
        case 'H': {
            return '#C375FF';
        }
        case 'I': {
            return '#ABA893';
        }
        case 'J': {
            return '#B8CFDB';
        }
        default:
            return '#dddddd';
    }
}

/*
EPG.default_color = '#dddddd';
  // COULEURS DECLARATIF
  EPG.colors = [];
  EPG.colors[0]='#dddddd'; //INDETERMINE
  EPG.colors[1]='#24abba'; //FILM
  EPG.colors[2]='#4bb5c1'; //TELEFILM
  EPG.colors[3]='#7fc6bc'; //SERIE_FEUILLETON
  EPG.colors[4]='#7fc6bc'; //FEUILLETON
  EPG.colors[5]='#bdb024'; //DOCUMENTAIRE
  EPG.colors[6]='#c69350'; //THEATRE
  EPG.colors[7]='#c69350'; //OPERA
  EPG.colors[8]='#c69350'; //BALLET
  EPG.colors[9]='#876d9b'; //VARIETE
  EPG.colors[10]='#A3D9FF'; //MAGAZINE
  EPG.colors[11]='#D9FC49'; //JEUNESSE
  EPG.colors[12]='#A600D4'; //JEU
  EPG.colors[13]='#876d9b'; //MUSIQUE
  EPG.colors[14]='#D7FCE1'; //DIVERTISSEMENT
  EPG.colors[15]='#24abba'; //COURT_METRAGE
  EPG.colors[16]='#D9FC49'; //DESSIN_ANIME
  EPG.colors[19]='#84c125'; //SPORT
  EPG.colors[20]='#2d80dd'; //JOURNAL
  EPG.colors[21]='#2d80dd'; //INFORMATION
  EPG.colors[22]='#2d80dd'; //DEBAT
  EPG.colors[23]='#c69350'; //DANSE
  EPG.colors[24]='#c69350'; //SPECTACLE
  EPG.colors[25]='#c69350'; //GALA
  EPG.colors[26]='#2d80dd'; //REPORTAGE
  EPG.colors[27]='#cccccc'; //FIN_EMISSION
  EPG.colors[31]='#cccccc'; //RELIGIEUX
  EPG.colors[32]='#c69350'; //CONCERT
  EPG.colors[33]='#876d9b'; //CLIPS
  EPG.colors[34]='#81b7f4'; //METEO
  EPG.colors[35]='#24abba'; //MAKINGOF
  EPG.colors[36]='#FF0000'; //CEREMONIE
  EPG.colors[37]='#A0CBD9'; //TALK_SHOW
  EPG.colors[38]='#a8afd3'; //TELEREALITE
  EPG.colors[39]='#4bb5c1'; //PROGRAMME_COURT
  EPG.colors[40]='#bdb024'; //SERIE_DOCUMENTAIRE
  // COULEURS RECALE
  EPG.colorsMM = [];
  EPG.colorsMM['A']='#2BA0FF';
  EPG.colorsMM['B']='#C2BE8C';
  EPG.colorsMM['C']='#F63AFC';
  EPG.colorsMM['D']='#FFDD00';
  EPG.colorsMM['E']='#3EBA00';
  EPG.colorsMM['F']='#B2F202';
  EPG.colorsMM['G']='#FAA5EA';
  EPG.colorsMM['H']='#C375FF';
  EPG.colorsMM['I']='#ABA893';
  EPG.colorsMM['J']='#B8CFDB';
*/
