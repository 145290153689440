import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import { RouteComponentProps } from 'react-router-dom';
import { Color, HEXColor, RGBColor, SketchPicker } from 'react-color';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { createTalk as trackCreate, editTalk as trackEdit } from '../../Analytics/Events';
import { mediaarchiver } from '../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos } from '../../Store/I18n';
import { setSnackMessage } from '../../Store/Layout';
import { IPlayerState, setEndSelection, setStartSelection } from '../../Store/Player';
import {
    addTalk,
    clearDistricts,
    clearSuggs,
    editTalk,
    getAllValues,
    getDistricts,
    getPerson,
    getProgramTypes,
    getStudies,
    getSugg,
    hideDialog,
} from '../../Store/Talk/Actions';
import { ITalkState } from '../../Store/Talk/Types';
import { center, ITimelineState } from '../../Store/Timeline';
import { IUserState } from '../../Store/User';
import { getTheme } from '../../Themes';
import { formatDuration } from '../../Utils/Time';
import { TypedStorage } from '../../Utils/TypedStorage';
import {
    getDate,
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyArray,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../Utils/Various';

const theme = getTheme();

const styles = MD.createStyles({
    blueLabels: {
        '& .MuiInputLabel-formControl': {
            color: '#00b0ff',
        },
    },
    customLink: {
        color: theme.palette.text.secondary,
        cursor: 'pointer',
    },
    datePickers: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dialogHeaderInfos: {
        '& strong': {
            fontWeight: 'bold',
        },

        fontSize: '0.8em',
        marginBottom: theme.spacing(3),
        paddingLeft: theme.spacing(1),
    },
    dialogImage: {
        height: 'auto',
        width: '100%',
    },
    fieldDescription: {
        fontSize: '2em',
    },
    personPicture: {
        maxHeight: 75,
        position: 'absolute',
        right: 20,
    },
    sketchPicker: {
        marginTop: 10,
    },
    sketchPickerColorBlock: {
        background: '#fff',
        borderRadius: 1,
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        cursor: 'pointer',
        display: 'inline-block',
        marginRight: theme.spacing(1),
        padding: 5,
    },
    sketchPickerColorBlockInside: {
        borderRadius: '2px',
        height: '14px',
        width: '36px',
    },
    sketchPickerCover: {
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
    },
    sketchPickerPopOver: {
        position: 'absolute',
        top: -100,
        zIndex: 2,
    },
    speakersList: {
        marginBottom: theme.spacing(2),
    },
    talkDialogAddLink: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1) * 3,
        marginTop: theme.spacing(1),
        textAlign: 'right',
    },
    talkDialogContent: {
        minWidth: 600,
    },
    talkDialogFormElement: {
        marginTop: theme.spacing(1),
    },
    talkDialogLoading: {
        height: '20px !important',
        width: '20px !important',
    },
    timeInputs: {
        width: 80,
    },
});

interface IErrorsState {
    aggregatedTime: string;
    district: string;
    general: string;
    group: string;
    person: string;
    regim: string;
    programEnd: string;
    programName: string;
    programStart: string;
    programType: string;
    speakers: string;
    study: string;
    studyType: boolean;
    title: string;
}

interface IState {
    aggregatedTimeHours: number;
    aggregatedTimeMinutes: number;
    aggregatedTimeSeconds: number;
    comment: string;
    district: number;
    districtSelected: mediaarchiver.ITalkDistrict | null;
    editedSpeaker: number;
    end: Date;
    errors: IErrorsState;
    group: number;
    groupColor: string;
    groupColorShow: boolean;
    groupCustom: boolean;
    groupCustomName: string;
    personCustom: boolean;
    personFirstName: string;
    personFromEdited: boolean;
    personGender: number;
    personID: string;
    personLastName: string;
    personName: string;
    personPicture: string;
    programEnd: Date | null;
    programEndString: string;
    programName: string;
    programStart: Date | null;
    programStartString: string;
    programType: number;
    programTypeCustom: boolean;
    programTypeCustomName: string;
    regim: number;
    regimCustom: boolean;
    regimCustomName: string;
    situation: number;
    speakerMode: number;
    speakers: mediaarchiver.ITalkSpeaker[];
    start: Date;
    study: number;
    studyType: mediaarchiver.TalkStudyType;
    talkType: mediaarchiver.TalkTalkType;
    timeType: mediaarchiver.TalkTimeType;
    title: string;
    titleCustom: boolean;
}

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    player: IPlayerState;
    talk: ITalkState;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    addTalk: typeof addTalk;
    center: typeof center;
    clearDistricts: typeof clearDistricts;
    clearSuggs: typeof clearSuggs;
    editTalk: typeof editTalk;
    getAllValues: typeof getAllValues;
    getDistricts: typeof getDistricts;
    getPerson: typeof getPerson;
    getProgramTypes: typeof getProgramTypes;
    getStudies: typeof getStudies;
    getSugg: typeof getSugg;
    hideDialog: typeof hideDialog;
    setEndSelection: typeof setEndSelection;
    setSnackMessage: typeof setSnackMessage;
    setStartSelection: typeof setStartSelection;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

class TalkDialogComponent extends React.Component<AllProps, IState> {
    private parsedStart: Date | null = null;
    private parsedEnd: Date | null = null;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getInitialState();
    }

    public render(): React.ReactNode {
        return <div>{this.renderTalkDialog()}</div>;
    }

    public componentDidMount() {
        this.props.getPerson('newPerson');
        this.props.getProgramTypes();
        this.props.getStudies();
        this.props.getAllValues();
    }

    private renderTalkDialog() {
        const start = this.state.start;
        const end = this.state.end;
        const from = moment(start).format(this.props.localeInfos.formatShortDateTimeWithSeconds);
        const to = moment(end).format(this.props.localeInfos.formatShortDateTimeWithSeconds);
        const durationMS = end.getTime() - start.getTime();
        const duration = moment.duration(durationMS);
        const durationStr =
            ('0' + duration.hours().toString()).slice(-2) +
            ':' +
            ('0' + duration.minutes().toString()).slice(-2) +
            ':' +
            ('0' + duration.seconds().toString()).slice(-2);

        return (
            <MD.Dialog
                className={this.props.classes.blueLabels}
                maxWidth='sm'
                open={this.props.talk.showDialog}
                onClose={this.handleCloseTalkDialog.bind(this)}
                onEnter={this.handleOpenTalkDialog.bind(this)}
            >
                <MD.DialogTitle>
                    {isNullOrUndefined(this.props.talk.editedTalk)
                        ? this.props.i18n.pgettext('Politic', 'Add a political talk time')
                        : !isNullOrUndefined(this.props.talk.aggregatedTalk)
                        ? this.props.i18n.pgettext('Politic', 'Edit an aggregated political talk time')
                        : this.props.i18n.pgettext('Politic', 'Edit a political talk time')}
                    {this.state.talkType === mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED &&
                    this.state.personPicture !== '' ? (
                        <img className={this.props.classes.personPicture} src={this.state.personPicture} />
                    ) : (
                        ''
                    )}
                </MD.DialogTitle>
                <MD.DialogContent className={this.props.classes.talkDialogContent}>
                    <MD.Box>
                        <MD.Grid container>
                            {this.state.talkType === mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED ? (
                                <MD.Grid className={this.props.classes.dialogHeaderInfos} item xs={12}>
                                    <strong>{this.props.timeline.criterias.mediaName}</strong>
                                    {', '}
                                    {this.props.i18n.sprintf(
                                        this.props.i18n.pgettext(
                                            'Start time of selection for political talk',
                                            'From %1$s',
                                        ),
                                        from,
                                    )}{' '}
                                    {this.props.i18n.sprintf(
                                        this.props.i18n.pgettext('End time of selection for political talk', 'To %1$s'),
                                        to,
                                    )}
                                    <br />
                                    {this.props.i18n.sprintf(
                                        this.props.i18n.pgettext('Politic', 'Duration: %1$s'),
                                        durationStr,
                                    )}
                                </MD.Grid>
                            ) : (
                                <>
                                    <MD.Grid className={this.props.classes.dialogHeaderInfos} item xs={12}>
                                        <strong>{this.props.timeline.criterias.mediaName}</strong>
                                        {', '}
                                        {this.state.programName}
                                        {', '}
                                        {moment(this.state.programStart).format(
                                            this.props.localeInfos.formatShortDateTime,
                                        )}
                                    </MD.Grid>
                                    <MD.Grid className={this.props.classes.speakersList} item xs={12}>
                                        {this.props.i18n.pgettext('Politic', 'Speakers list')}
                                        <MD.Table size='small'>
                                            <MD.TableHead>
                                                <MD.TableRow>
                                                    <MD.TableCell>
                                                        {this.props.i18n.pgettext('Politic', 'Speaker')}
                                                    </MD.TableCell>
                                                    <MD.TableCell>
                                                        {this.props.i18n.pgettext('Politic', 'Time')}
                                                    </MD.TableCell>
                                                    <MD.TableCell></MD.TableCell>
                                                </MD.TableRow>
                                            </MD.TableHead>
                                            <MD.TableBody>
                                                {this.state.speakers.map(
                                                    (speaker: mediaarchiver.ITalkSpeaker, i: number) => (
                                                        <MD.TableRow key={`speaker_row_${i}`}>
                                                            <MD.TableCell>{speaker.Name || ''}</MD.TableCell>
                                                            <MD.TableCell>
                                                                {formatDuration(speaker.DurationMS || 0)}
                                                            </MD.TableCell>
                                                            <MD.TableCell>
                                                                <MD.IconButton
                                                                    onClick={() => this.editSpeaker(speaker)}
                                                                >
                                                                    <FontAwesomeIcon icon={FA.faEdit} size='xs' />
                                                                </MD.IconButton>
                                                                <MD.IconButton onClick={() => this.delSpeaker(i)}>
                                                                    <FontAwesomeIcon icon={FA.faTrash} size='xs' />
                                                                </MD.IconButton>
                                                            </MD.TableCell>
                                                        </MD.TableRow>
                                                    ),
                                                )}
                                            </MD.TableBody>
                                        </MD.Table>
                                        <MD.IconButton onClick={() => this.editSpeaker(null)}>
                                            <FontAwesomeIcon icon={FA.faPlusSquare} size='xs' />
                                        </MD.IconButton>
                                        {this.props.i18n.pgettext('Politic', 'Add a new speaker')}
                                    </MD.Grid>
                                </>
                            )}
                        </MD.Grid>
                    </MD.Box>
                    {this.generateAddTalkForm()}
                </MD.DialogContent>
                <MD.DialogActions>
                    <MD.Button onClick={this.handleCloseTalkDialog.bind(this)} color='primary'>
                        {this.props.i18n._('Cancel')}
                    </MD.Button>
                    {this.props.talk.loading ? (
                        <MD.CircularProgress className={this.props.classes.talkDialogLoading} />
                    ) : (
                        <MD.Button onClick={this.handleSubmitTalkDialog.bind(this)} color='secondary'>
                            {isNullOrUndefined(this.props.talk.editedTalk)
                                ? this.props.i18n.pgettext('Politic', 'Add talk')
                                : this.props.i18n.pgettext('Politic', 'Edit talk')}
                        </MD.Button>
                    )}
                </MD.DialogActions>
            </MD.Dialog>
        );
    }

    private generateAddTalkForm() {
        return (
            <div>
                {this.generateAddTalkProgram()}
                {this.generateAddTalkStudy()}
                {this.generateAddTalkDistrict()}
                {this.generateAddTalkTimeType()}
                {this.generateTalkComment()}
                {this.generateAggregatedTime()}
                {this.generateAddTalkPerson()}
                {this.generateAddTalkParams()}
                {this.generateAddTalkAggregatedSubmit()}
            </div>
        );
    }

    private generateAggregatedTime() {
        if (
            this.state.talkType !== mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED ||
            this.state.speakerMode === 0
        ) {
            return '';
        }
        return (
            <div className={this.props.classes.talkDialogFormElement}>
                <MD.FormLabel>{this.props.i18n.pgettext('Politic', 'Aggregated talk time')}</MD.FormLabel>
                <MD.FormGroup row>
                    <MD.FormControl error={this.state.errors.aggregatedTime !== ''}>
                        <MD.TextField
                            className={this.props.classes.timeInputs}
                            error={this.state.errors.aggregatedTime !== ''}
                            label={this.props.i18n.pgettext('Politic', 'Hours')}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                const val = parseInt(ev.target.value.replace(/\D+/g, ''), 10);

                                this.setState({
                                    ...this.state,
                                    aggregatedTimeHours: isNaN(val) ? 0 : val,
                                });
                            }}
                            value={this.state.aggregatedTimeHours}
                        />
                    </MD.FormControl>
                    <MD.FormControl error={this.state.errors.aggregatedTime !== ''}>
                        <MD.TextField
                            className={this.props.classes.timeInputs}
                            error={this.state.errors.aggregatedTime !== ''}
                            label={this.props.i18n.pgettext('Politic', 'Minutes')}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                const val = parseInt(ev.target.value.replace(/\D+/g, ''), 10);

                                this.setState({
                                    ...this.state,
                                    aggregatedTimeMinutes: isNaN(val) ? 0 : val,
                                });
                            }}
                            value={this.state.aggregatedTimeMinutes}
                        />
                    </MD.FormControl>
                    <MD.FormControl error={this.state.errors.aggregatedTime !== ''}>
                        <MD.TextField
                            className={this.props.classes.timeInputs}
                            error={this.state.errors.aggregatedTime !== ''}
                            label={this.props.i18n.pgettext('Politic', 'Seconds')}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                const val = parseInt(ev.target.value.replace(/\D+/g, ''), 10);

                                this.setState({
                                    ...this.state,
                                    aggregatedTimeSeconds: isNaN(val) ? 0 : val,
                                });
                            }}
                            value={this.state.aggregatedTimeSeconds}
                        />
                    </MD.FormControl>
                </MD.FormGroup>
                {this.state.errors.aggregatedTime !== '' ? (
                    <MD.FormHelperText error>{this.state.errors.aggregatedTime}</MD.FormHelperText>
                ) : (
                    ''
                )}
            </div>
        );
    }

    private generateAddTalkProgram() {
        const tlStart = new Date(this.props.timeline.criterias.start.getTime() - 5 * 60 * 60 * 1000);
        const tlEnd = new Date(this.props.timeline.criterias.end.getTime() + 5 * 60 * 60 * 1000);
        const startMin = tlStart;
        const startMax = !isNull(this.state.programEnd)
            ? this.state.programEnd
            : new Date(this.props.timeline.criterias.end.getTime() + 5 * 60 * 60 * 1000);
        const endMin = !isNull(this.state.programStart) ? this.state.programStart : tlStart;
        const endMax = new Date(this.props.timeline.criterias.end.getTime() + 5 * 60 * 60 * 1000);

        let views: ('year' | 'month' | 'date' | 'hours' | 'minutes')[] = ['year', 'month', 'date', 'hours', 'minutes'];
        let openTo: 'date' | 'year' | 'month' | 'hours' | 'minutes' | undefined = 'year';

        if (tlStart.getFullYear() === tlEnd.getFullYear()) {
            views = ['month', 'date', 'hours', 'minutes'];
            openTo = 'month';
            if (tlStart.getMonth() === tlEnd.getMonth()) {
                views = ['date', 'hours', 'minutes'];
                openTo = 'date';
                if (tlStart.getDate() === tlEnd.getDate()) {
                    views = ['hours', 'minutes'];
                    openTo = 'hours';
                }
            }
        }

        return (
            <div>
                {isNullOrUndefined(this.props.talk.aggregatedTalk) ? (
                    <MD.FormControl fullWidth={true}>
                        <MD.TextField
                            error={this.state.errors.programName !== ''}
                            helperText={this.state.errors.programName}
                            label={this.props.i18n.pgettext('Politic', 'Program name')}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    ...this.state,
                                    programName: ev.target.value,
                                });
                            }}
                            value={this.state.programName}
                        />
                    </MD.FormControl>
                ) : (
                    ''
                )}
                <MD.FormControl fullWidth={true}>
                    {this.state.programTypeCustom ? (
                        <MD.TextField
                            error={this.state.errors.programType !== ''}
                            fullWidth={true}
                            helperText={this.state.errors.programType}
                            label={this.props.i18n.pgettext('Politic', 'New program type name')}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    ...this.state,
                                    programTypeCustomName: ev.target.value,
                                });
                            }}
                            value={this.state.programTypeCustomName}
                        />
                    ) : (
                        <>
                            <MD.InputLabel htmlFor='chooseProgramType'>
                                {this.props.i18n.pgettext('Politic', 'Choose a program type')}
                            </MD.InputLabel>
                            <MD.Select
                                error={this.state.errors.programType !== ''}
                                inputProps={{
                                    id: 'chooseProgramType',
                                    name: 'chooseProgramType',
                                }}
                                onChange={this.handleProgramTypeChange.bind(this)}
                                value={
                                    this.props.talk.programTypes.some(
                                        (t: mediaarchiver.ITalkProgramType) => t.ID === this.state.programType,
                                    )
                                        ? this.state.programType
                                        : this.props.talk.programTypes.length > 0
                                        ? (this.props.talk.programTypes[0].ID as number)
                                        : 0
                                }
                            >
                                {this.props.talk.programTypes.length === 0 ? (
                                    <MD.MenuItem disabled={true} key={'programType_none'} value={0}>
                                        {this.props.i18n.pgettext('Politic', 'No program type, please create one')}
                                    </MD.MenuItem>
                                ) : (
                                    this.props.talk.programTypes
                                        .map((t: mediaarchiver.ITalkProgramType, i: number) => {
                                            if (
                                                isNullOrUndefinedOrZero(t.ID) ||
                                                isNullOrUndefinedOrEmptyString(t.Name)
                                            ) {
                                                return null;
                                            }
                                            return (
                                                <MD.MenuItem key={`programType_${i}`} value={t.ID}>
                                                    {t.Name}
                                                </MD.MenuItem>
                                            );
                                        })
                                        .filter((i) => !isNullOrUndefined(i))
                                )}
                            </MD.Select>
                        </>
                    )}
                </MD.FormControl>
                <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                    <MD.Link
                        className={this.props.classes.customLink}
                        onClick={() => {
                            if (this.state.programTypeCustom) {
                                this.setState({
                                    ...this.state,
                                    programType: TypedStorage.get('lastTalkProgramType', 0),
                                    programTypeCustom: false,
                                    programTypeCustomName: '',
                                });
                                return;
                            }
                            this.setState({
                                ...this.state,
                                programType: 0,
                                programTypeCustom: true,
                                programTypeCustomName: '',
                            });
                        }}
                    >
                        {this.state.programTypeCustom
                            ? this.props.i18n._('Cancel')
                            : this.props.i18n.pgettext('Politic', 'Add a new program type')}
                    </MD.Link>
                </MD.FormControl>
                <>
                    <MD.FormControl>
                        <MD.TextField
                            className={this.props.classes.datePickers}
                            error={this.state.errors.programStart !== ''}
                            helperText={this.state.errors.programStart}
                            label={this.props.i18n.pgettext('Politic', 'Program start')}
                            onBlur={() => {
                                let val = '';
                                if (!isNull(this.parsedStart)) {
                                    val = moment(this.parsedStart).format(this.props.localeInfos.formatShortDateTime);
                                }
                                this.setState({
                                    programStart: this.parsedStart,
                                    programStartString: val,
                                });
                            }}
                            InputProps={{
                                inputComponent: (props) => {
                                    const { inputRef, ...other } = props;

                                    return (
                                        <MaskedInput
                                            {...other}
                                            ref={(ref) => {
                                                inputRef(ref ? ref.inputElement : null);
                                            }}
                                            mask={[
                                                /[0-3]/,
                                                /\d/,
                                                '/',
                                                /[0-1]/,
                                                /\d/,
                                                '/',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                ' ',
                                                /[0-2]/,
                                                /[0-9]/,
                                                ':',
                                                /[0-5]/,
                                                /[0-9]/,
                                            ]}
                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                if (ev.target.value.indexOf('_') === -1) {
                                                    this.parsedStart = moment(
                                                        ev.target.value,
                                                        this.props.localeInfos.formatShortDateTime,
                                                    ).toDate();
                                                }
                                            }}
                                            value={this.state.programStartString}
                                        />
                                    );
                                },
                            }}
                        />
                        {/* 
                        <MaskedInput
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            placeholderChar={'\u2000'}
                            showMask
                        />

                        <DateTimePicker
                            ampm={false}
                            autoOk={true}
                            className={this.props.classes.datePickers}
                            disableToolbar
                            error={this.state.errors.programStart !== ''}
                            format={this.props.localeInfos.formatShortDateTime}
                            helperText={this.state.errors.programStart}
                            // keyboardIcon={<FontAwesomeIcon icon={FA.faCalendar} />}
                            InputProps={{
                                endAdornment: (
                                    <MD.InputAdornment position='end'>
                                        <MD.IconButton
                                            onClick={(ev: React.MouseEvent) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                                this.setState({ programStart: null });
                                            }}
                                        >
                                            <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                        </MD.IconButton>
                                    </MD.InputAdornment>
                                ),
                            }}
                            label={this.props.i18n.pgettext('Politic', 'Program start')}
                            leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                            maxDate={startMax}
                            minDate={startMin}
                            onChange={(val: Date | moment.Moment | null): void => {
                                const programStart = getDate(val);
                                let startError = '';
                                let endError = '';

                                if (!isNullOrUndefined(programStart)) {
                                    if (
                                        !isNullOrUndefined(this.state.programEnd) &&
                                        this.state.programEnd.getTime() < programStart.getTime()
                                    ) {
                                        endError = this.props.i18n.pgettext(
                                            'Politic',
                                            'End date cannot be before start date',
                                        );
                                    }
                                } else {
                                    startError = this.props.i18n.pgettext('Politic', 'Program start must be set');
                                }
                                this.setState({
                                    errors: {
                                        ...this.state.errors,
                                        programEnd: endError,
                                        programStart: startError,
                                    },
                                    programStart,
                                });
                            }}
                            open={false}
                            openTo={openTo}
                            rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                            value={this.state.programStart}
                            variant='inline'
                            views={views}
                        />
                        */}
                    </MD.FormControl>
                    <MD.FormControl>
                        <MD.TextField
                            className={this.props.classes.datePickers}
                            error={this.state.errors.programEnd !== ''}
                            helperText={this.state.errors.programEnd}
                            label={this.props.i18n.pgettext('Politic', 'Program end')}
                            onBlur={() => {
                                let val = '';
                                if (!isNull(this.parsedEnd)) {
                                    val = moment(this.parsedEnd).format(this.props.localeInfos.formatShortDateTime);
                                }
                                this.setState({
                                    programEnd: this.parsedEnd,
                                    programEndString: val,
                                });
                            }}
                            InputProps={{
                                inputComponent: (props) => {
                                    const { inputRef, ...other } = props;

                                    return (
                                        <MaskedInput
                                            {...other}
                                            ref={(ref) => {
                                                inputRef(ref ? ref.inputElement : null);
                                            }}
                                            mask={[
                                                /[0-3]/,
                                                /\d/,
                                                '/',
                                                /[0-1]/,
                                                /\d/,
                                                '/',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                ' ',
                                                /[0-2]/,
                                                /[0-9]/,
                                                ':',
                                                /[0-5]/,
                                                /[0-9]/,
                                            ]}
                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                if (ev.target.value.indexOf('_') === -1) {
                                                    this.parsedEnd = moment(
                                                        ev.target.value,
                                                        this.props.localeInfos.formatShortDateTime,
                                                    ).toDate();
                                                }
                                            }}
                                            value={this.state.programEndString}
                                        />
                                    );
                                },
                            }}
                        />

                        {/*
                        <DateTimePicker
                            ampm={false}
                            autoOk={true}
                            className={this.props.classes.datePickers}
                            disableToolbar
                            error={this.state.errors.programEnd !== ''}
                            format={this.props.localeInfos.formatShortDateTime}
                            helperText={this.state.errors.programEnd}
                            // keyboardIcon={<FontAwesomeIcon icon={FA.faCalendar} />}
                            InputProps={{
                                endAdornment: (
                                    <MD.InputAdornment position='end'>
                                        <MD.IconButton
                                            onClick={(ev: React.MouseEvent) => {
                                                ev.preventDefault();
                                                ev.stopPropagation();
                                                this.setState({ programEnd: null });
                                            }}
                                        >
                                            <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                        </MD.IconButton>
                                    </MD.InputAdornment>
                                ),
                            }}
                            label={this.props.i18n.pgettext('Politic', 'Program end')}
                            leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                            maxDate={endMax}
                            minDate={endMin}
                            onChange={(val: Date | moment.Moment | null): void => {
                                const programEnd = getDate(val);
                                let endError = '';

                                if (!isNullOrUndefined(programEnd)) {
                                    if (
                                        !isNullOrUndefined(this.state.programStart) &&
                                        this.state.programStart.getTime() > programEnd.getTime()
                                    ) {
                                        endError = this.props.i18n.pgettext(
                                            'Politic',
                                            'End date cannot be before start date',
                                        );
                                    }
                                } else {
                                    endError = this.props.i18n.pgettext('Politic', 'Program end must be set');
                                }
                                this.setState({
                                    errors: {
                                        ...this.state.errors,
                                        programEnd: endError,
                                    },
                                    programEnd,
                                });
                            }}
                            open={false}
                            openTo={openTo}
                            rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                            value={this.state.programEnd}
                            variant='inline'
                            views={views}
                        />
                        */}
                    </MD.FormControl>
                </>
            </div>
        );
    }

    private handleProgramTypeChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        let programType = parseInt(ev.target.value as string, 10);

        if (!this.props.talk.programTypes.some((i: mediaarchiver.ITalkProgramType) => i.ID === programType)) {
            programType =
                this.props.talk.programTypes.length === 0 ? 0 : (this.props.talk.programTypes[0].ID as number);
        }
        TypedStorage.set('lastTalkProgramType', programType), this.setState({ programType });
    }

    private generateAddTalkStudy() {
        const [elections, pluralism] = this.getStudies();
        const current = this.state.studyType === mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION ? elections : pluralism;

        return (
            <>
                <MD.FormControl fullWidth={true}>
                    <MD.InputLabel htmlFor='chooseTalkFamily'>
                        {this.props.i18n.pgettext('Politic', 'Choose a study type')}
                    </MD.InputLabel>
                    <MD.Select
                        error={this.state.errors.studyType}
                        inputProps={{
                            id: 'chooseTalkFamily',
                            name: 'chooseTalkFamily',
                        }}
                        onChange={this.handleStudyTypeChange.bind(this)}
                        value={this.getCurrentStudyType()}
                    >
                        <MD.MenuItem
                            disabled={elections.length === 0}
                            value={mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION}
                        >
                            {elections.length === 0
                                ? this.props.i18n.pgettext('Politic', 'No election study in progress')
                                : this.props.i18n.pgettext('Politic', 'Election')}
                        </MD.MenuItem>
                        <MD.MenuItem
                            disabled={pluralism.length === 0}
                            value={mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM}
                        >
                            {pluralism.length === 0
                                ? this.props.i18n.pgettext('Politic', 'No pluralism in progress')
                                : this.props.i18n.pgettext('Politic', 'Pluralism')}
                        </MD.MenuItem>
                    </MD.Select>
                </MD.FormControl>
                <MD.FormControl fullWidth={true}>
                    <MD.InputLabel htmlFor='chooseStudy'>
                        {this.props.i18n.pgettext('Politic', 'Choose a study')}
                    </MD.InputLabel>
                    <MD.Select
                        inputProps={{
                            id: 'chooseStudy',
                            name: 'chooseStudy',
                        }}
                        onChange={this.handleStudyChange.bind(this)}
                        value={this.getCurrentStudy()}
                    >
                        {current.map((study: mediaarchiver.ITalkStudy, i: number) => {
                            return (
                                <MD.MenuItem key={`study_${i}`} value={study.ID as number}>
                                    {study.Name as string}
                                </MD.MenuItem>
                            );
                        })}
                    </MD.Select>
                </MD.FormControl>
            </>
        );
    }

    private getStudies(): [mediaarchiver.ITalkStudy[], mediaarchiver.ITalkStudy[]] {
        const elections = this.props.talk.studies.filter((study: mediaarchiver.ITalkStudy) => {
            if (
                isNullOrUndefinedOrZero(study.ID) ||
                isNullOrUndefinedOrEmptyString(study.Name) ||
                study.Type !== mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION
            ) {
                return false;
            }
            if (!this.isTalkInUnixRange(study.Start, study.End)) {
                return false;
            }
            return true;
        });
        const pluralism = this.props.talk.studies.filter((study: mediaarchiver.ITalkStudy) => {
            if (
                isNullOrUndefinedOrZero(study.ID) ||
                isNullOrUndefinedOrEmptyString(study.Name) ||
                study.Type !== mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM
            ) {
                return false;
            }
            if (!this.isTalkInUnixRange(study.Start, study.End)) {
                return false;
            }
            return true;
        });
        return [elections, pluralism];
    }

    private handleStudyTypeChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        let studyType = parseInt(ev.target.value as string, 10);

        switch (studyType) {
            case mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION:
            case mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM:
                break;
            default:
                studyType = mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM;
        }
        const list = this.getCurrentStudies();
        const study = list.length === 0 ? 0 : (list[0].ID as number);

        this.props.clearDistricts();
        this.setState({ district: 0, districtSelected: null, study, studyType });
        TypedStorage.set('lastTalkStudyType', studyType);
    }

    private handleStudyChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        const list = this.getCurrentStudies();
        let study = parseInt(ev.target.value as string, 10);

        if (!list.some((i: mediaarchiver.ITalkStudy) => i.ID === study)) {
            study = list.length === 0 ? 0 : (list[0].ID as number);
        }
        this.props.clearDistricts();
        this.setState({ district: 0, districtSelected: null, study });
        TypedStorage.set('lastTalkStudy', study);
    }

    private generateAddTalkDistrict() {
        if (!this.districtIsNeeded()) {
            return '';
        }
        return (
            <MD.FormControl fullWidth={true}>
                <Autocomplete
                    filterOptions={(options: mediaarchiver.ITalkDistrict[]) => {
                        return options;
                    }}
                    getOptionLabel={(sugg: mediaarchiver.ITalkDistrict): string =>
                        isNullOrUndefined(sugg) || isNullOrUndefined(sugg.Name) ? '' : sugg.Name
                    }
                    id='districtComplete'
                    noOptionsText={this.props.i18n.pgettext('Politic', 'No district found for this study')}
                    options={this.props.talk.districts}
                    onChange={this.handleDistrictChange.bind(this)}
                    renderInput={(params) => (
                        <MD.TextField
                            {...params}
                            error={this.state.errors.district !== ''}
                            helperText={this.state.errors.district}
                            label={this.props.i18n.pgettext('Politic', 'District')}
                            onChange={this.autocompleteDistrict.bind(this)}
                        />
                    )}
                    value={this.state.districtSelected}
                />
            </MD.FormControl>
        );
    }

    private handleDistrictChange(
        event: React.ChangeEvent<{}>,
        value: mediaarchiver.ITalkDistrict | null,
        reason: AutocompleteChangeReason,
    ): void {
        let district = 0;
        let districtSelected: mediaarchiver.ITalkDistrict | null = null;
        switch (reason) {
            case 'clear':
                this.props.clearDistricts();
                break;
            case 'select-option':
                if (isNullOrUndefined(value)) {
                    break;
                }
                if (!isNullOrUndefinedOrEmptyString(value.Name) && !isNullOrUndefinedOrEmptyString(value.ID)) {
                    district = value.ID;
                    districtSelected = value;
                }
            default:
                break;
        }
        this.setState({ district, districtSelected });
    }

    private autocompleteDistrict(ev: React.ChangeEvent<HTMLInputElement>) {
        const typed = ev.target.value;
        const study = this.getCurrentStudy();

        if (isNullOrUndefinedOrZero(study)) {
            return;
        }
        if (isNullOrUndefined(typed) || typed.length < 3) {
            return;
        }
        this.props.getDistricts({
            StudyID: this.getCurrentStudy(),
            Typed: typed,
        });
    }

    private generateAddTalkTimeType() {
        if (this.state.studyType !== mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION) {
            return '';
        }
        return (
            <>
                <MD.FormControl fullWidth={true}>
                    <MD.InputLabel htmlFor='chooseTimeType'>
                        {this.props.i18n.pgettext('Politic', 'Choose a time type')}
                    </MD.InputLabel>
                    <MD.Select
                        inputProps={{
                            id: 'chooseTimeType',
                            name: 'chooseTimeType',
                        }}
                        onChange={this.handleTimeTypeChange.bind(this)}
                        value={this.state.timeType}
                    >
                        <MD.MenuItem value={mediaarchiver.TalkTimeType.TALK_TIME_TYPE_SPEAK}>
                            {this.props.i18n.pgettext('Election', 'Speak time')}
                        </MD.MenuItem>
                        <MD.MenuItem value={mediaarchiver.TalkTimeType.TALK_TIME_TYPE_BROADCAST}>
                            {this.props.i18n.pgettext('Election', 'Broadcast time')}
                        </MD.MenuItem>
                    </MD.Select>
                </MD.FormControl>
            </>
        );
    }

    private handleTimeTypeChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        let timeType = parseInt(ev.target.value as string, 10);

        switch (timeType) {
            case mediaarchiver.TalkTimeType.TALK_TIME_TYPE_BROADCAST:
            case mediaarchiver.TalkTimeType.TALK_TIME_TYPE_SPEAK:
                break;
            default:
                timeType = mediaarchiver.TalkTimeType.TALK_TIME_TYPE_SPEAK;
        }
        this.setState({ timeType });
    }

    private generateAddTalkPerson() {
        if (
            this.state.talkType === mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED &&
            this.state.speakerMode === 0
        ) {
            return '';
        }
        return (
            <div>
                <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                    {!this.state.personCustom ? (
                        this.state.personFromEdited ? (
                            <MD.TextField
                                disabled={true}
                                fullWidth={true}
                                label={this.props.i18n.pgettext('Politic', 'Personality')}
                                value={this.state.personName}
                            />
                        ) : (
                            <Autocomplete
                                filterOptions={(options: mediaarchiver.ITalkPersonSugg[]) => {
                                    return options.filter((p: mediaarchiver.ITalkPersonSugg) =>
                                        this.isTalkInUnixRange(p.Start, p.End),
                                    );
                                }}
                                getOptionLabel={(sugg: mediaarchiver.ITalkPersonSugg): string =>
                                    isNullOrUndefined(sugg.Name) ? '' : sugg.Name
                                }
                                id='personInDB'
                                noOptionsText={this.props.i18n.pgettext('Politic', 'No personality found')}
                                options={this.props.talk.personSuggestions}
                                onChange={this.onChangePerson.bind(this)}
                                renderInput={(params) => (
                                    <MD.TextField
                                        {...params}
                                        error={this.state.errors.person !== ''}
                                        helperText={this.state.errors.person}
                                        onChange={this.autocompletePerson.bind(this)}
                                        label={this.props.i18n.pgettext('Politic', 'Personality')}
                                    />
                                )}
                            />
                        )
                    ) : (
                        <div>
                            <MD.TextField
                                fullWidth={true}
                                label={this.props.i18n.pgettext('Politic', 'First name')}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    this.setState({
                                        ...this.state,
                                        personFirstName: ev.target.value,
                                    });
                                }}
                                value={this.state.personFirstName}
                            />
                            <MD.TextField
                                fullWidth={true}
                                label={this.props.i18n.pgettext('Politic', 'Last name')}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    this.setState({
                                        ...this.state,
                                        personLastName: ev.target.value,
                                    });
                                }}
                                value={this.state.personLastName}
                            />
                            <MD.FormControl className={this.props.classes.talkDialogFormElement} component='fieldset'>
                                <MD.FormLabel component='legend'>
                                    {this.props.i18n.pgettext('Politic', 'Gender')}
                                </MD.FormLabel>
                                <MD.RadioGroup
                                    name='gender-group'
                                    row
                                    value={this.state.personGender === 1 ? '1' : '0'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                                        const personGender = value === '1' ? 1 : 0;

                                        this.setState({ personGender });
                                    }}
                                >
                                    <MD.FormControlLabel
                                        value='0'
                                        control={<MD.Radio />}
                                        label={this.props.i18n.pgettext('Politic', 'Man')}
                                    />
                                    <MD.FormControlLabel
                                        value='1'
                                        control={<MD.Radio />}
                                        label={this.props.i18n.pgettext('Politic', 'Woman')}
                                    />
                                </MD.RadioGroup>
                            </MD.FormControl>
                        </div>
                    )}
                </MD.FormControl>
                <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                    <MD.Link
                        className={this.props.classes.customLink}
                        onClick={() => {
                            if (this.state.personCustom || this.state.personFromEdited) {
                                this.setState({
                                    ...this.state,
                                    personCustom: false,
                                    personFirstName: '',
                                    personFromEdited: false,
                                    personID: '',
                                    personLastName: '',
                                    personName: '',
                                    personPicture: '',
                                });
                                return;
                            }
                            this.setState({
                                ...this.state,
                                personCustom: true,
                                personFirstName: '',
                                personFromEdited: false,
                                personID: 'newPerson',
                                personLastName: '',
                                personName: '',
                                personPicture: '',
                                regim: 12,
                                regimCustom: false,
                                regimCustomName: '',
                            });
                        }}
                    >
                        {this.state.personCustom || this.state.personFromEdited
                            ? this.props.i18n._('Cancel')
                            : this.props.i18n.pgettext('Politic', 'Add a new personnality')}
                    </MD.Link>
                </MD.FormControl>
            </div>
        );
    }

    private autocompletePerson(ev: React.ChangeEvent<HTMLInputElement>) {
        const typed = ev.target.value;

        if (isNullOrUndefined(typed) || typed.length < 3) {
            return;
        }
        this.props.getSugg(typed, this.getCurrentStudy());
    }

    private onChangePerson(
        event: React.ChangeEvent<{}>,
        value: mediaarchiver.ITalkPersonSugg | null,
        reason: AutocompleteChangeReason,
    ): void {
        let personID = '';
        let personName = '';
        switch (reason) {
            case 'clear':
                this.setState({
                    group: 0,
                    groupColor: '',
                    groupColorShow: false,
                    groupCustom: false,
                    groupCustomName: '',
                    personFromEdited: false,
                    personID: '',
                    personName: '',
                    personPicture: '',
                    regim: 0,
                    regimCustom: false,
                    regimCustomName: '',
                    title: '',
                    titleCustom: false,
                });
                return;
            case 'select-option':
                if (isNullOrUndefined(value)) {
                    break;
                }
                if (!isNullOrUndefinedOrEmptyString(value.Name)) {
                    personName = value.Name;
                }
                if (!isNullOrUndefinedOrEmptyString(value.ID)) {
                    personID = value.ID;
                }
                break;
            default:
                break;
        }
        this.setState({
            ...this.state,
            group: 0,
            groupCustom: false,
            groupCustomName: '',
            personID,
            personName,
            personPicture: '',
            regim: 0,
            regimCustom: false,
            regimCustomName: '',
            title: '',
            titleCustom: false,
        });
        this.tryPicture(personName);
        if (!isNullOrUndefinedOrEmptyString(personID)) {
            this.props.getPerson(personID);
        }
    }

    private tryPicture(name: string | null | undefined) {
        if (isNullOrUndefinedOrEmptyString(name)) {
            this.setState({
                ...this.state,
                personPicture: '',
            });
            return;
        }
        const parts = name.split(' ');
        const firstName = parts.slice(-1)[0].toLocaleLowerCase();
        const lastName = parts
            .slice(0, parts.length - 1)
            .join('-')
            .toLocaleLowerCase();
        const slug1 = firstName + '-' + lastName;
        const url1 = 'https://www.nosdeputes.fr/depute/photo/' + slug1 + '/240';

        const slug2 = parts.slice(1).join('-').toLocaleLowerCase() + '-' + parts[0].toLocaleLowerCase();
        const url2 = 'https://www.nosdeputes.fr/depute/photo/' + slug2 + '/240';

        const img1 = document.createElement('img');
        const img2 = document.createElement('img');
        img1.onload = () => {
            this.setState({
                ...this.state,
                personPicture: url1,
            });
        };
        img2.onload = () => {
            this.setState({
                ...this.state,
                personPicture: url2,
            });
        };
        img1.src = url1;
        img2.src = url2;
    }

    private generateAddTalkParams() {
        if (
            this.state.talkType === mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED &&
            this.state.speakerMode === 0
        ) {
            return '';
        }
        if (!isNull(this.getCurrentPerson()) || this.state.personCustom) {
            return (
                <div>
                    {this.generateAddTalkGroup()}
                    {this.generateAddTalkTitle()}
                    {this.generateAddTalkRegim()}
                    {this.generateAddTalkSituation()}
                </div>
            );
        }
        return '';
    }

    private generateAddTalkGroup() {
        let label = this.props.i18n.pgettext('Politic', 'Group (custom)');
        if (this.state.studyType === mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION) {
            label = this.props.i18n.pgettext('Politic', 'Supported candidate (custom)');
        }

        return (
            <div>
                {!this.state.groupCustom ? this.generateAddTalkGroupPredefined() : ''}
                {this.state.groupCustom ? (
                    <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                        <MD.TextField
                            error={this.state.errors.group !== ''}
                            helperText={this.state.errors.group}
                            label={label}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    ...this.state,
                                    groupCustomName: ev.target.value,
                                });
                            }}
                            value={this.state.groupCustomName}
                        />
                        <div className={this.props.classes.sketchPicker}>
                            <div
                                className={this.props.classes.sketchPickerColorBlock}
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onClick={this.onColorClick.bind(this) as any}
                            >
                                <div
                                    className={this.props.classes.sketchPickerColorBlockInside}
                                    style={{
                                        background: this.state.groupColor,
                                    }}
                                />
                            </div>
                            {this.state.groupColorShow ? (
                                <div className={this.props.classes.sketchPickerPopOver}>
                                    <div
                                        className={this.props.classes.sketchPickerCover}
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        onClick={this.onColorClick.bind(this) as any}
                                    />
                                    <SketchPicker
                                        color={this.state.groupColor}
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        onChange={this.onColorChange.bind(this) as any}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>

                        <MD.FormHelperText>
                            <MD.Link
                                className={this.props.classes.customLink}
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        group: 0,
                                        groupCustom: false,
                                        groupCustomName: '',
                                    });
                                }}
                            >
                                {this.props.i18n._('Cancel')}
                            </MD.Link>
                        </MD.FormHelperText>
                    </MD.FormControl>
                ) : (
                    ''
                )}
            </div>
        );
    }

    private generateAddTalkGroupPredefined() {
        const groups = this.getCurrentGroups();

        if (groups.length === 1) {
            return this.generateAddTalkGroupSingle(groups[0]);
        } else {
            return this.generateAddTalkGroupMultiple(groups);
        }
    }

    private generateAddTalkGroupSingle(group: mediaarchiver.ITalkGroup) {
        let label = this.props.i18n.pgettext('Politic', 'Group');
        if (this.state.studyType === mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION) {
            label = this.props.i18n.pgettext('Politic', 'Supported candidate');
        }

        return (
            <div>
                <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                    <MD.TextField disabled={true} label={label} value={group.Name} />
                    <MD.FormHelperText>
                        <MD.Link
                            className={this.props.classes.customLink}
                            onClick={() => {
                                this.setState({
                                    ...this.state,
                                    group: 0,
                                    groupCustom: true,
                                    groupCustomName: '',
                                });
                            }}
                        >
                            {this.props.i18n.pgettext('Politic', 'Type a custom value')}
                        </MD.Link>
                    </MD.FormHelperText>
                </MD.FormControl>
            </div>
        );
    }

    private generateAddTalkGroupMultiple(groups: mediaarchiver.ITalkGroup[]) {
        let label = this.props.i18n.pgettext('Politic', 'Group');
        if (this.state.studyType === mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION) {
            label = this.props.i18n.pgettext('Politic', 'Supported candidate');
        }

        return (
            <MD.FormControl className={this.props.classes.talkDialogFormElement} fullWidth={true}>
                <MD.InputLabel id='talk-group-select-label'>{label}</MD.InputLabel>
                <MD.Select
                    id='talk-group-select'
                    labelId='talk-group-select-label'
                    onChange={(ev: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                        if (!isNullOrUndefinedOrEmptyString(ev.target.value)) {
                            this.setState({
                                ...this.state,
                                group: parseInt(ev.target.value as string, 10),
                            });
                        }
                    }}
                    value={this.getCurrentGroup()}
                >
                    {groups.map((g: mediaarchiver.ITalkGroup) => {
                        if (isNullOrUndefined(g.ID) || isNullOrUndefined(g.Name)) {
                            return '';
                        }
                        return (
                            <MD.MenuItem key={`talk-group-select-${g.ID}`} value={g.ID}>
                                {g.Name}
                            </MD.MenuItem>
                        );
                    })}
                </MD.Select>
                <MD.FormHelperText>
                    <MD.Link
                        className={this.props.classes.customLink}
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                group: 0,
                                groupCustom: true,
                                groupCustomName: '',
                            });
                        }}
                    >
                        {this.props.i18n.pgettext('Politic', 'Type a custom value')}
                    </MD.Link>
                </MD.FormHelperText>
            </MD.FormControl>
        );
    }

    private onColorClick(): void {
        this.setState({ groupColorShow: !this.state.groupColorShow });
    }

    private onColorChange(res: Color) {
        if (isNullOrUndefined(res) || isNullOrUndefinedOrEmptyString((res as HEXColor).hex)) {
            return;
        }
        const rgb = res as RGBColor;
        let groupColor = (res as HEXColor).hex;

        if (
            groupColor !== 'transparent' &&
            !isNullOrUndefined(rgb.source) &&
            !isNullOrUndefined(rgb.a) &&
            rgb.a >= 0 &&
            rgb.a < 1
        ) {
            groupColor += Math.round(rgb.a * 255).toString(16);
        }
        this.setState({ groupColor });
    }

    private generateAddTalkTitle() {
        return (
            <div>
                {!this.state.titleCustom ? this.generateAddTalkTitlePredefined() : ''}
                {this.state.titleCustom ? (
                    <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                        <MD.TextField
                            error={this.state.errors.title !== ''}
                            helperText={this.state.errors.title}
                            label={this.props.i18n.pgettext('Political personality title', 'Title (custom)')}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    ...this.state,
                                    title: ev.target.value,
                                });
                            }}
                            value={this.state.title}
                        />
                        <MD.FormHelperText>
                            <MD.Link
                                className={this.props.classes.customLink}
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        title: '',
                                        titleCustom: false,
                                    });
                                }}
                            >
                                {this.props.i18n._('Cancel')}
                            </MD.Link>
                        </MD.FormHelperText>
                    </MD.FormControl>
                ) : (
                    ''
                )}
            </div>
        );
    }

    private generateAddTalkTitlePredefined() {
        const titles = this.getCurrentTitles();

        if (titles.length === 1) {
            return this.generateAddTalkTitleSingle(titles[0]);
        } else {
            return this.generateAddTalkTitleMultiple(titles);
        }
    }

    private generateAddTalkTitleSingle(title: string) {
        return (
            <div>
                <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                    <MD.TextField
                        disabled={true}
                        label={this.props.i18n.pgettext('Political personality title', 'Title')}
                        value={title}
                    />
                    <MD.FormHelperText>
                        <MD.Link
                            className={this.props.classes.customLink}
                            onClick={() => {
                                this.setState({
                                    ...this.state,
                                    title: '',
                                    titleCustom: true,
                                });
                            }}
                        >
                            {this.props.i18n.pgettext('Politic', 'Type a custom value')}
                        </MD.Link>
                    </MD.FormHelperText>
                </MD.FormControl>
            </div>
        );
    }

    private generateAddTalkTitleMultiple(titles: string[]) {
        return (
            <MD.FormControl className={this.props.classes.talkDialogFormElement} fullWidth={true}>
                <MD.InputLabel id='talk-title-select-label'>
                    {this.props.i18n.pgettext('Politic', 'Title')}
                </MD.InputLabel>
                <MD.Select
                    id='talk-title-select'
                    labelId='talk-title-select-label'
                    onChange={(ev: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                        if (!isNullOrUndefinedOrEmptyString(ev.target.value)) {
                            this.setState({
                                ...this.state,
                                title: ev.target.value as string,
                            });
                        }
                    }}
                    value={this.getCurrentTitle()}
                >
                    {titles.map((t: string) => {
                        if (isNullOrUndefinedOrEmptyString(t)) {
                            return '';
                        }
                        return (
                            <MD.MenuItem key={`talk-title-select-${t}`} value={t}>
                                {t}
                            </MD.MenuItem>
                        );
                    })}
                </MD.Select>
                <MD.FormHelperText>
                    <MD.Link
                        className={this.props.classes.customLink}
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                title: '',
                                titleCustom: true,
                            });
                        }}
                    >
                        {this.props.i18n.pgettext('Politic', 'Type a custom value')}
                    </MD.Link>
                </MD.FormHelperText>
            </MD.FormControl>
        );
    }

    private generateAddTalkRegim() {
        return (
            <div>
                {!this.state.regimCustom ? this.generateAddTalkRegimPredefined() : ''}
                {this.state.regimCustom ? (
                    <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                        <MD.TextField
                            error={this.state.errors.regim !== ''}
                            helperText={this.state.errors.regim}
                            label={this.props.i18n.pgettext('Politic', 'Regim (custom)')}
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                this.setState({
                                    ...this.state,
                                    regimCustomName: ev.target.value,
                                });
                            }}
                            value={this.state.regimCustomName}
                        />
                        <MD.FormHelperText>
                            <MD.Link
                                className={this.props.classes.customLink}
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        regim: 0,
                                        regimCustom: false,
                                        regimCustomName: '',
                                    });
                                }}
                            >
                                {this.props.i18n._('Cancel')}
                            </MD.Link>
                        </MD.FormHelperText>
                    </MD.FormControl>
                ) : (
                    ''
                )}
            </div>
        );
    }

    private generateAddTalkRegimPredefined() {
        const regims = this.getCurrentRegims();

        if (regims.length === 1) {
            return this.generateAddTalkRegimSingle(regims[0]);
        } else {
            return this.generateAddTalRegimMultiple(regims);
        }
    }

    private generateAddTalkRegimSingle(regim: mediaarchiver.ITalkRegim) {
        return (
            <div>
                <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                    <MD.TextField
                        disabled={true}
                        label={this.props.i18n.pgettext('Politic', 'Regim')}
                        value={regim.Name}
                    />
                    <MD.FormHelperText>
                        <MD.Link
                            className={this.props.classes.customLink}
                            onClick={() => {
                                this.setState({
                                    ...this.state,
                                    regim: 0,
                                    regimCustom: true,
                                    regimCustomName: '',
                                });
                            }}
                        >
                            {this.props.i18n.pgettext('Politic', 'Type a custom value')}
                        </MD.Link>
                    </MD.FormHelperText>
                </MD.FormControl>
            </div>
        );
    }

    private generateAddTalRegimMultiple(regims: mediaarchiver.ITalkRegim[]) {
        return (
            <MD.FormControl className={this.props.classes.talkDialogFormElement} fullWidth={true}>
                <MD.InputLabel id='talk-regim-select-label'>
                    {this.props.i18n.pgettext('Politic', 'Regim')}
                </MD.InputLabel>
                <MD.Select
                    id='talk-regim-select'
                    labelId='talk-regim-select-label'
                    onChange={(ev: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                        if (!isNullOrUndefinedOrEmptyString(ev.target.value)) {
                            this.setState({
                                ...this.state,
                                regim: parseInt(ev.target.value as string, 10),
                            });
                        }
                    }}
                    value={this.getCurrentRegim()}
                >
                    {regims.map((r: mediaarchiver.ITalkRegim) => {
                        if (isNullOrUndefinedOrEmptyString(r.Name) || isNullOrUndefinedOrZero(r.ID)) {
                            return '';
                        }
                        return (
                            <MD.MenuItem key={`talk-regim-select-${r.ID}`} value={r.ID}>
                                {r.Name}
                            </MD.MenuItem>
                        );
                    })}
                </MD.Select>
                <MD.FormHelperText>
                    <MD.Link
                        className={this.props.classes.customLink}
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                regim: 0,
                                regimCustom: true,
                                regimCustomName: '',
                            });
                        }}
                    >
                        {this.props.i18n.pgettext('Politic', 'Type a custom value')}
                    </MD.Link>
                </MD.FormHelperText>
            </MD.FormControl>
        );
    }

    private generateAddTalkSituation() {
        return (
            <MD.FormControl className={this.props.classes.talkDialogFormElement} fullWidth={true}>
                <MD.InputLabel id='talk-situation-select-label'>
                    {this.props.i18n.pgettext('Politic', 'Situation')}
                </MD.InputLabel>
                <MD.Select
                    id='talk-situation-select'
                    labelId='talk-situation-select-label'
                    onChange={(ev: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                        if (!isNullOrUndefinedOrEmptyString(ev.target.value)) {
                            this.setState({
                                ...this.state,
                                situation: parseInt(ev.target.value as string, 10),
                            });
                        }
                    }}
                    value={this.state.situation}
                >
                    <MD.MenuItem value={mediaarchiver.TalkSituationType.TALK_SITUATION_TYPE_INVITED}>
                        {this.props.i18n.pgettext('Politic', 'Invited')}
                    </MD.MenuItem>
                    <MD.MenuItem value={mediaarchiver.TalkSituationType.TALK_SITUATION_TYPE_REPORTAGE}>
                        {this.props.i18n.pgettext('Politic', 'Reportage')}
                    </MD.MenuItem>
                </MD.Select>
            </MD.FormControl>
        );
    }

    private generateTalkComment() {
        return (
            <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                <MD.TextField
                    label={this.props.i18n.pgettext('Politic', 'Comment')}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({
                            ...this.state,
                            comment: ev.target.value,
                        });
                    }}
                    value={this.state.comment}
                />
            </MD.FormControl>
        );
    }

    private generateAddTalkAggregatedSubmit() {
        if (
            this.state.talkType !== mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED ||
            this.state.speakerMode === 0
        ) {
            return '';
        }
        return (
            <MD.FormControl className={this.props.classes.talkDialogFormElement} error={true} fullWidth={true}>
                <MD.Button
                    color='secondary'
                    onClick={() => {
                        const state = { ...this.state };

                        if (this.addSpeaker(state)) {
                            state.speakerMode = 0;
                        }
                        this.setState(state);
                    }}
                >
                    {this.state.speakerMode === 2
                        ? this.props.i18n.pgettext('Politic', 'Edit speaker')
                        : this.props.i18n.pgettext('Politic', 'Add speaker')}
                </MD.Button>
            </MD.FormControl>
        );
    }

    private handleCloseTalkDialog() {
        this.setState(this.getInitialState());
        this.props.hideDialog();
        this.props.clearSuggs();
        this.props.clearDistricts();
    }

    private initStateForEdit(talk: mediaarchiver.ITalk) {
        if (isNullOrUndefined(talk) || isNullOrUndefinedOrEmptyArray(talk.Speakers)) {
            return this.initStateForNewAdjusted();
        }

        const state = this.getInitialState();

        state.comment = talk.Comment || '';
        state.study = talk.Study || 0;
        this.props.talk.studies.some((s: mediaarchiver.ITalkStudy) => {
            if (
                !isNull(s) &&
                !isNullOrUndefinedOrZero(s.ID) &&
                s.ID == talk.Study &&
                !isNullOrUndefinedOrZero(s.Type)
            ) {
                state.studyType = s.Type;
                return true;
            }
        });
        state.timeType = talk.TimeType || mediaarchiver.TalkTimeType.TALK_TIME_TYPE_SPEAK;
        state.programEnd = new Date(talk.ProgramEnd as number);
        state.programEndString = moment(state.programEnd).format(this.props.localeInfos.formatShortDateTime);
        state.programName = talk.ProgramName || '';
        state.programStart = new Date(talk.ProgramStart as number);
        state.programStartString = moment(state.programStart).format(this.props.localeInfos.formatShortDateTime);
        state.programType = talk.ProgramType || 0;
        state.talkType = talk.TalkType || mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED;

        if (state.talkType === mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED) {
            this.initStateForEditAggregated(talk, state);
        } else {
            this.initStateForEditAdjusted(talk, state);
        }
        this.parsedStart = state.programStart;
        this.parsedEnd = state.programEnd;
        this.setState(state);
    }

    private initStateForEditAggregated(talk: mediaarchiver.ITalk, state: IState): void {
        if (!isNullOrUndefinedOrEmptyArray(talk.Speakers)) {
            state.speakers = talk.Speakers;
        }
        if (
            !isNullOrUndefinedOrZero(talk.ProgramStart) &&
            !isNullOrUndefinedOrZero(talk.ProgramEnd) &&
            !isNullOrUndefinedOrEmptyString(talk.ProgramName)
        ) {
            state.start = new Date(talk.ProgramStart as number);
            state.end = new Date(talk.ProgramEnd as number);
            state.programName = talk.ProgramName;
            state.programStart = new Date(talk.ProgramStart as number);
            state.programStartString = moment(state.programStart).format(this.props.localeInfos.formatShortDateTime);
            state.programEnd = new Date(talk.ProgramEnd as number);
            state.programEndString = moment(state.programEnd).format(this.props.localeInfos.formatShortDateTime);
        }
        return;
    }

    private initStateForEditAdjusted(talk: mediaarchiver.ITalk, state: IState): void {
        if (isNullOrUndefinedOrEmptyArray(talk.Speakers)) {
            return;
        }
        state.group = talk.Speakers[0].Group || 0;
        state.groupColor = talk.Speakers[0].GroupColor || '';
        state.personFromEdited = true;
        state.personID = (talk.Speakers[0].ID || 0).toString(10);
        state.personName = talk.Speakers[0].Name || '';
        state.regim = talk.Speakers[0].Regim || 0;
        state.title = talk.Speakers[0].Title || '';
        state.situation = talk.Speakers[0].Situation || 0;
        state.programStart = new Date(talk.ProgramStart as number);
        state.programStartString = moment(state.programStart).format(this.props.localeInfos.formatShortDateTime);
        state.programEnd = new Date(talk.ProgramEnd as number);
        state.programEndString = moment(state.programEnd).format(this.props.localeInfos.formatShortDateTime);
        if (!isNullOrUndefinedOrZero(talk.Speakers[0].Start)) {
            state.start = new Date(talk.Speakers[0].Start as number);
            if (!isNullOrUndefinedOrZero(talk.Speakers[0].DurationMS)) {
                state.end = new Date((talk.Speakers[0].Start as number) + talk.Speakers[0].DurationMS);
            }
        }
        this.tryPicture(state.personName);
    }

    private initStateForNewAggregated() {
        const state = this.getInitialState();

        if (
            !isNullOrUndefined(this.props.talk.aggregatedTalk) &&
            !isNullOrUndefinedOrZero(this.props.talk.aggregatedTalk.ProgramStart) &&
            !isNullOrUndefinedOrZero(this.props.talk.aggregatedTalk.ProgramEnd) &&
            !isNullOrUndefinedOrEmptyString(this.props.talk.aggregatedTalk.ProgramName)
        ) {
            state.start = new Date(this.props.talk.aggregatedTalk.ProgramStart as number);
            state.end = new Date(this.props.talk.aggregatedTalk.ProgramEnd as number);
            state.programName = this.props.talk.aggregatedTalk.ProgramName;
            state.programStart = new Date(this.props.talk.aggregatedTalk.ProgramStart as number);
            state.programStartString = moment(state.programStart).format(this.props.localeInfos.formatShortDateTime);
            state.programEnd = new Date(this.props.talk.aggregatedTalk.ProgramEnd as number);
            state.programEndString = moment(state.programEnd).format(this.props.localeInfos.formatShortDateTime);
        }
        state.talkType = mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED;
        this.parsedStart = state.programStart;
        this.parsedEnd = state.programEnd;
        this.setState(state);
    }

    private initStateForNewAdjusted() {
        const state = this.getInitialState();

        if (
            !isNullOrUndefined(this.props.player) &&
            !isNullOrUndefined(this.props.player.startSelection) &&
            !isNullOrUndefined(this.props.player.endSelection)
        ) {
            const [ok, title, start, end] = this.getEpg(
                this.props.player.startSelection.getTime(),
                this.props.player.endSelection.getTime(),
            );

            if (ok) {
                state.programStart = start;
                state.programEnd = end;
                state.programName = title;
            }
            state.start = this.props.player.startSelection;
            state.end = this.props.player.endSelection;
        }
        state.programStartString = moment(state.programStart).format(this.props.localeInfos.formatShortDateTime);
        state.programEndString = moment(state.programEnd).format(this.props.localeInfos.formatShortDateTime);
        state.talkType = mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED;
        this.parsedStart = state.programStart;
        this.parsedEnd = state.programEnd;

        if (!isNullOrUndefined(this.props.talk.lastPerson)) {
            state.group = this.props.talk.lastPerson.group;
            state.groupColor = this.props.talk.lastPerson.groupColor;
            state.personID = this.props.talk.lastPerson.personID.toString(10);
            state.personName = this.props.talk.lastPerson.personName;
            state.regim = this.props.talk.lastPerson.regim;
            state.situation = this.props.talk.lastPerson.situation;
            state.study = this.props.talk.lastPerson.study;
            state.talkType = this.props.talk.lastPerson.talkType;
            state.timeType = this.props.talk.lastPerson.timeType;
            state.title = this.props.talk.lastPerson.title;
            state.personFromEdited = true;
            this.tryPicture(state.personName);
        }

        this.setState(state);
    }

    private getEpg(start: number, end: number): [boolean, string, Date, Date] {
        let ok = false;
        let title = '';
        let prgStart = new Date();
        let prgEnd = new Date();

        if (!ok && !isNullOrUndefined(this.props.timeline.data.broadcasterEPG)) {
            Object.keys(this.props.timeline.data.broadcasterEPG).some((min: string) => {
                const program = this.props.timeline.data.broadcasterEPG[(min as unknown) as number];
                const _prgStart = program.realStart.getTime();
                const _prgEnd = _prgStart + program.realDuration;
                if (start >= _prgStart && start <= _prgEnd && end >= _prgStart && end <= _prgEnd) {
                    title = program.title;
                    prgStart = program.realStart;
                    prgEnd = new Date(_prgEnd);
                    ok = true;
                }
                return ok;
            });
        }
        if (!isNullOrUndefined(this.props.timeline.data.readjustedEPG)) {
            Object.keys(this.props.timeline.data.readjustedEPG).some((min: string) => {
                const program = this.props.timeline.data.readjustedEPG[(min as unknown) as number];
                const _prgStart = program.realStart.getTime();
                const _prgEnd = _prgStart + program.realDuration;
                if (start >= _prgStart && start <= _prgEnd && end >= _prgStart && end <= _prgEnd) {
                    title = program.title;
                    prgStart = program.realStart;
                    prgEnd = new Date(_prgEnd);
                    ok = true;
                }
                return ok;
            });
        }
        if (!ok && !isNullOrUndefined(this.props.timeline.data.declarativeEPG)) {
            Object.keys(this.props.timeline.data.declarativeEPG).some((min: string) => {
                const program = this.props.timeline.data.declarativeEPG[(min as unknown) as number];
                const _prgStart = program.realStart.getTime();
                const _prgEnd = _prgStart + program.realDuration;
                if (start >= _prgStart && start <= _prgEnd && end >= _prgStart && end <= _prgEnd) {
                    title = program.title;
                    prgStart = program.realStart;
                    prgEnd = new Date(_prgEnd);
                    ok = true;
                }
                return ok;
            });
        }
        return [ok, title, prgStart, prgEnd];
    }

    private handleOpenTalkDialog() {
        if (!isNullOrUndefined(this.props.talk.editedTalk)) {
            this.initStateForEdit(this.props.talk.editedTalk);
        } else if (!isNullOrUndefined(this.props.talk.aggregatedTalk)) {
            this.initStateForNewAggregated();
        } else {
            this.initStateForNewAdjusted();
        }
        this.props.clearSuggs();
    }

    private checkAggregatedTime(errors: IErrorsState): [boolean, number] {
        let ok = true;
        let duration = 0;

        if (isNaN(this.state.aggregatedTimeHours) || this.state.aggregatedTimeHours < 0) {
            errors.aggregatedTime = this.props.i18n.pgettext('Politic', 'Invalid value for hours');
            ok = false;
        }
        if (isNaN(this.state.aggregatedTimeMinutes) || this.state.aggregatedTimeMinutes < 0) {
            errors.aggregatedTime = this.props.i18n.pgettext('Politic', 'Invalid value for minutes');
            ok = false;
        }
        if (isNaN(this.state.aggregatedTimeSeconds) || this.state.aggregatedTimeSeconds < 0) {
            errors.aggregatedTime = this.props.i18n.pgettext('Politic', 'Invalid value for seconds');
            ok = false;
        }

        if (ok) {
            duration += this.state.aggregatedTimeHours * 60 * 60 * 1000;
            duration += this.state.aggregatedTimeMinutes * 60 * 1000;
            duration += this.state.aggregatedTimeSeconds * 1000;
            if (duration < 1000) {
                errors.aggregatedTime = this.props.i18n.pgettext(
                    'Politic',
                    'Talk duration must be greater than 1 second',
                );
                ok = false;
            }
        }
        return [ok, duration];
    }

    private addSpeaker(state: IState): boolean {
        let ok = true;
        const speaker: mediaarchiver.ITalkSpeaker = {
            Situation: state.situation,
            Start: state.start.getTime(),
        };
        state.errors = this.getInitialState().errors;

        if (state.personCustom) {
            if (state.personLastName.trim() === '' || state.personFirstName.trim() === '') {
                state.errors.person = this.props.i18n.pgettext(
                    'Politic',
                    'Please choose a political personnality first and last name',
                );
                ok = false;
            } else {
                speaker.Name =
                    state.personLastName.toLocaleUpperCase().replace(/\s+/, '-') +
                    ' ' +
                    state.personFirstName.toLocaleUpperCase().replace(/\s+/, '-');
            }
            speaker.Gender = state.personGender;
        } else {
            const person = this.getCurrentPerson();

            if (
                isNull(person) ||
                isNullOrUndefinedOrEmptyString(person.ID) ||
                person.ID === 'newPerson' ||
                isNullOrUndefinedOrEmptyString(person.Name)
            ) {
                state.errors.person = this.props.i18n.pgettext('Politic', 'Please choose a political personnality');
                ok = false;
            } else {
                speaker.ID = parseInt(person.ID, 10);
                speaker.Name = person.Name;
            }
        }

        if (state.groupCustom) {
            if (state.groupCustomName.trim() === '') {
                state.errors.group = this.props.i18n.pgettext('Politic', 'Please choose a political group');
                ok = false;
            } else {
                speaker.Group = 0;
                speaker.GroupCustom = state.groupCustomName.trim();
            }
        } else {
            const group = this.getCurrentGroup();

            if (group === 0) {
                state.errors.group = this.props.i18n.pgettext('Politic', 'Please choose a political group');
                ok = false;
            } else {
                speaker.Group = group;
            }
        }
        speaker.GroupColor = this.getCurrentGroupColor();

        if (state.titleCustom) {
            if (state.title.trim() === '') {
                state.errors.title = this.props.i18n.pgettext('Politic', 'Please choose a title');
                ok = false;
            } else {
                speaker.Title = state.title;
            }
        } else {
            const title = this.getCurrentTitle();

            if (title === '') {
                state.errors.title = this.props.i18n.pgettext('Politic', 'Please choose a title');
                ok = false;
            } else {
                speaker.Title = title;
            }
        }

        if (state.regimCustom) {
            if (state.regimCustomName.trim() === '') {
                state.errors.regim = this.props.i18n.pgettext('Politic', 'Please choose a regim');
                ok = false;
            } else {
                speaker.Regim = 0;
                speaker.RegimCustom = state.regimCustomName.trim();
            }
        } else {
            const regim = this.getCurrentRegim();

            if (regim === 0) {
                state.errors.regim = this.props.i18n.pgettext('Politic', 'Please choose a regim');
                ok = false;
            } else {
                speaker.Regim = regim;
            }
        }

        if (ok) {
            if (state.talkType === mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED) {
                const [okDuration, duration] = this.checkAggregatedTime(state.errors);

                if (!okDuration) {
                    return false;
                }
                speaker.DurationMS = duration;

                state.speakers = state.speakers.filter((s: mediaarchiver.ITalkSpeaker) => {
                    return speaker.ID == 0 || s.ID !== speaker.ID;
                });
                state.speakers.push(speaker);
            } else {
                speaker.DurationMS = state.end.getTime() - state.start.getTime();
                state.speakers = [speaker];
            }
        }
        return ok;
    }

    private editSpeaker(speaker: mediaarchiver.ITalkSpeaker | null): void {
        if (isNull(speaker)) {
            return this.setState({
                aggregatedTimeHours: 0,
                aggregatedTimeMinutes: 0,
                aggregatedTimeSeconds: 0,
                editedSpeaker: -1,
                group: 0,
                groupColor: '#ffffff',
                groupColorShow: false,
                groupCustom: false,
                groupCustomName: '',
                personCustom: false,
                personFirstName: '',
                personFromEdited: false,
                personGender: 0,
                personID: '',
                personLastName: '',
                personName: '',
                personPicture: '',
                regim: 0,
                regimCustom: false,
                regimCustomName: '',
                speakerMode: 1,
                title: '',
                titleCustom: false,
            });
        }

        let editedSpeaker = -1;

        if (
            !this.state.speakers.some((s: mediaarchiver.ITalkSpeaker, i: number) => {
                if (s.ID === speaker.ID) {
                    editedSpeaker = i;
                    return true;
                }
                return false;
            })
        ) {
            return this.editSpeaker(null);
        }

        const duration = moment.duration(speaker.DurationMS || 0);

        this.setState({
            aggregatedTimeHours: duration.hours(),
            aggregatedTimeMinutes: duration.minutes(),
            aggregatedTimeSeconds: duration.seconds(),
            editedSpeaker,
            group: speaker.Group || 0,
            groupColor: speaker.GroupColor || theme.mediaarchiver.colors.backgroundPoliticalDefault,
            groupColorShow: false,
            groupCustom: false,
            groupCustomName: '',
            personCustom: false,
            personFirstName: '',
            personFromEdited: true,
            personGender: 0,
            personID: (speaker.ID || 0).toString(),
            personLastName: '',
            personName: speaker.Name || '',
            personPicture: '',
            regim: speaker.Regim || 0,
            regimCustom: false,
            regimCustomName: '',
            situation: speaker && speaker.Situation ? speaker.Situation : 0,
            speakerMode: 2,
            title: speaker.Title || '',
            titleCustom: false,
        });
        return;
    }

    private delSpeaker(i: number) {
        if (i < 0 || i >= this.state.speakers.length) {
            return;
        }
        const speakers = this.state.speakers.filter((s: mediaarchiver.ITalkSpeaker, j: number) => i !== j);

        this.setState({
            aggregatedTimeHours: 0,
            aggregatedTimeMinutes: 0,
            aggregatedTimeSeconds: 0,
            editedSpeaker: -1,
            group: 0,
            groupColor: '',
            groupColorShow: false,
            groupCustom: false,
            groupCustomName: '',
            personCustom: false,
            personFirstName: '',
            personFromEdited: false,
            personGender: 0,
            personID: '',
            personLastName: '',
            personName: '',
            personPicture: '',
            regim: 0,
            regimCustom: false,
            regimCustomName: '',
            speakerMode: 0,
            speakers,
            title: '',
            titleCustom: false,
        });
    }

    private handleSubmitTalkDialog() {
        const state = { ...this.state };
        const talk: mediaarchiver.ITalk = {
            Company: this.props.user.user.group,
            Owner: this.props.user.user.id,
            OwnerName: this.props.user.user.getShortName(),
            TalkType: this.state.talkType,
            TimeType: this.state.timeType,
        };
        let ok = true;

        state.errors = this.getInitialState().errors;

        if (!isNullOrUndefined(this.props.talk.editedTalk)) {
            talk.ID = this.props.talk.editedTalk.ID;
            talk.Media = this.props.talk.editedTalk.Media;
        } else {
            talk.Media = this.props.timeline.criterias.media;
        }

        if (this.state.programName === '') {
            state.errors.programName = this.props.i18n.pgettext('Politic', 'Please set the program name');
            ok = false;
        } else {
            talk.ProgramName = this.state.programName;
        }

        if (this.state.programTypeCustom) {
            if (this.state.programTypeCustomName.trim() === '') {
                state.errors.programType = this.props.i18n.pgettext('Politic', 'Please set the program type name');
                ok = false;
            } else {
                talk.ProgramType = 0;
                talk.ProgramTypeCustom = this.state.programTypeCustomName.trim();
            }
        } else {
            let code = '';
            if (
                this.props.talk.programTypes.some((t: mediaarchiver.ITalkProgramType) => {
                    if (
                        !isNullOrUndefinedOrZero(this.state.programType) &&
                        !isNullOrUndefinedOrZero(t.ID) &&
                        t.ID === this.state.programType
                    ) {
                        code = t.Code || '';
                        return true;
                    }
                })
            ) {
                talk.ProgramType = this.state.programType;
                talk.ProgramTypeCode = code;
            } else if (this.props.talk.programTypes.length > 0) {
                talk.ProgramType = this.props.talk.programTypes[0].ID as number;
                talk.ProgramTypeCode = this.props.talk.programTypes[0].Code || '';
            } else {
                state.errors.programType = this.props.i18n.pgettext('Politic', 'Please set the program type');
                ok = false;
            }
        }

        if (isNull(this.parsedStart) || isNull(this.parsedEnd)) {
            if (isNull(this.parsedStart)) {
                state.errors.programStart = this.props.i18n.pgettext('Politic', 'Program start must be set');
                ok = false;
            }
            if (isNull(this.parsedEnd)) {
                state.errors.programEnd = this.props.i18n.pgettext('Politic', 'Program end must be set');
                ok = false;
            }
        } else if (this.parsedStart.getTime() >= this.parsedEnd.getTime()) {
            state.errors.programEnd = this.props.i18n.pgettext('Politic', 'End date cannot be before start date');
            ok = false;
        } else {
            talk.ProgramStart = this.parsedStart.getTime();
            talk.ProgramEnd = this.parsedEnd.getTime();
        }

        switch (this.state.studyType) {
            case mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION:
            case mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM:
                break;
            default:
                state.errors.studyType = true;
                ok = false;
        }

        if (this.getCurrentStudies().length === 0) {
            state.errors.studyType = true;
            ok = false;
        }

        if (!state.errors.studyType) {
            if (this.getCurrentStudy() !== 0) {
                talk.Study = this.getCurrentStudy();

                let code = '';
                this.props.talk.studies.some((s: mediaarchiver.ITalkStudy) => {
                    if (s.ID === talk.Study) {
                        code = s.Code || '';
                        return true;
                    }
                });
                talk.StudyCode = code;
            } else {
                state.errors.study = this.props.i18n.pgettext('Politic', 'Please choose a study');
                ok = false;
            }
        }

        if (this.districtIsNeeded()) {
            if (this.state.district === 0) {
                state.errors.district = this.props.i18n.pgettext('Politic', 'Please choose a district');
                ok = false;
            } else {
                talk.District = this.state.district;
            }
        } else {
            talk.District = 0;
        }

        if (this.state.talkType === mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED) {
            if (this.state.speakers.length < 1) {
                state.errors.district = this.props.i18n.pgettext('Politic', 'Please set at least one speaker');
                ok = false;
            }
        } else if (!this.addSpeaker(state)) {
            ok = false;
        }
        talk.Speakers = state.speakers;

        this.setState(state);
        if (!ok) {
            return;
        }
        if (isNullOrUndefined(this.props.talk.editedTalk)) {
            trackCreate();
            this.props.addTalk(talk);
        } else {
            trackEdit();
            this.props.editTalk(talk);
        }
    }

    private getTalkStart(): Date | null {
        if (!isNullOrUndefined(this.state.start)) {
            return this.state.start;
        }
        return null;
    }

    private isTalkInUnixRange(start: number | null | undefined, end: number | null | undefined): boolean {
        if (isNullOrUndefined(this.getTalkStart())) {
            return true;
        }
        const talkStart = (this.getTalkStart() as Date).getTime() / 1000;

        if (!isNullOrUndefinedOrZero(start) && talkStart < start) {
            return false;
        }
        if (!isNullOrUndefinedOrZero(end) && talkStart > end) {
            return false;
        }
        return true;
    }

    private getCurrentStudyType(): mediaarchiver.TalkStudyType {
        switch (this.state.studyType) {
            case mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION:
            case mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM:
                return this.state.studyType;
            default:
                return mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION;
        }
    }

    private getCurrentStudies(): mediaarchiver.ITalkStudy[] {
        const [elections, pluralism] = this.getStudies();
        return this.getCurrentStudyType() === mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION ? elections : pluralism;
    }

    private getCurrentStudy(): number {
        const list = this.getCurrentStudies();
        let study = this.state.study;

        if (!list.some((i: mediaarchiver.ITalkStudy) => i.ID === study)) {
            study = list.length === 0 ? 0 : (list[0].ID as number);
        }
        return study;
    }

    private districtIsNeeded(): boolean {
        const study = this.getCurrentStudy();

        return (
            !isNullOrUndefinedOrZero(study) &&
            this.props.talk.studies.some(
                (s: mediaarchiver.ITalkStudy) => !isNullOrUndefined(s.ID) && study === s.ID && s.HasDistricts,
            )
        );
    }

    private getCurrentPerson(): mediaarchiver.ITalkPerson | null {
        let person: mediaarchiver.ITalkPerson | null = null;

        if (this.state.personCustom) {
            return null;
        }
        if (this.state.personFromEdited) {
            let groupName = '';
            let regimName = '';

            this.props.talk.allGroups.some((g: mediaarchiver.ITalkGroup) => {
                if (
                    !isNullOrUndefined(g) &&
                    !isNullOrUndefinedOrZero(g.ID) &&
                    !isNullOrUndefinedOrEmptyString(g.Name) &&
                    g.ID === this.state.group
                ) {
                    groupName = g.Name;
                    return true;
                }
            });
            this.props.talk.allRegims.some((r: mediaarchiver.ITalkRegim) => {
                if (
                    !isNullOrUndefined(r) &&
                    !isNullOrUndefinedOrZero(r.ID) &&
                    !isNullOrUndefinedOrEmptyString(r.Name) &&
                    r.ID === this.state.regim
                ) {
                    regimName = r.Name;
                    return true;
                }
            });
            return {
                Gender: this.state.personGender,
                ID: this.state.personID,
                Name: this.state.personName,
                Specifications: [
                    {
                        Group: {
                            Color: this.state.groupColor,
                            ID: this.state.group,
                            Name: groupName,
                            Study: this.state.study,
                        },
                        Regim: {
                            ID: this.state.regim,
                            Name: regimName,
                            Study: this.state.study,
                        },
                        Study: this.state.study,
                        Title: {
                            Study: this.state.study,
                            Title: this.state.title,
                        },
                    },
                ],
            };
        }
        if (this.state.personID !== '') {
            this.props.talk.persons.some((p: mediaarchiver.ITalkPerson): boolean => {
                const bID = isNullOrUndefinedOrEmptyString(p.ID) ? '--' : p.ID;
                if (this.state.personID === p.ID || this.state.personID === bID) {
                    person = p;
                    return true;
                }
                return false;
            });
        }
        return person;
    }

    private getCurrentGroups(): mediaarchiver.ITalkGroup[] {
        const person = this.getCurrentPerson();
        const study = this.getCurrentStudy();
        const allGroups = this.props.talk.allGroups.filter(
            (g: mediaarchiver.ITalkGroup) =>
                !isNullOrUndefined(g) &&
                !isNullOrUndefinedOrZero(g.ID) &&
                !isNullOrUndefinedOrEmptyString(g.Name) &&
                this.isTalkInUnixRange(g.Start, g.End) &&
                (study === 0 || study === g.Study),
        );

        let res: mediaarchiver.ITalkGroup[] = [];

        if (isNull(person) || isNullOrUndefinedOrEmptyArray(person.Specifications)) {
            res = allGroups;
        } else {
            const ids: number[] = [];

            person.Specifications.filter(
                (s: mediaarchiver.ITalkPersonSpecifications) =>
                    !isNullOrUndefined(s) &&
                    this.isTalkInUnixRange(s.Start, s.End) &&
                    !isNullOrUndefined(s.Group) &&
                    (study === 0 || study === s.Study),
            )
                .map(
                    (s: mediaarchiver.ITalkPersonSpecifications): mediaarchiver.ITalkGroup =>
                        s.Group as mediaarchiver.ITalkGroup,
                )
                .forEach((s: mediaarchiver.ITalkGroup) => {
                    if (ids.indexOf(s.ID as number) === -1) {
                        res.push(s);
                        ids.push(s.ID as number);
                    }
                });
        }
        const ids: number[] = [];
        res = res
            .filter((g: mediaarchiver.ITalkGroup) => {
                if (isNullOrUndefinedOrZero(g.ID) || ids.indexOf(g.ID) !== -1) {
                    return false;
                }
                ids.push(g.ID as number);
                return true;
            })
            .sort((a, b) => {
                const aa = a.Name || '';
                const bb = b.Name || '';

                return aa < bb ? -1 : aa > bb ? 1 : 0;
            });
        return res.length === 0 ? allGroups : res;
    }

    private getCurrentGroup(): number {
        const groups = this.getCurrentGroups();

        if (groups.some((g: mediaarchiver.ITalkGroup) => g.ID === this.state.group)) {
            return this.state.group;
        }
        if (groups.length > 0) {
            return groups[0].ID as number;
        }
        return 0;
    }

    private getCurrentGroupColor(): string {
        let color = theme.mediaarchiver.colors.backgroundPoliticalDefault.substr(1);

        if (this.state.groupCustom && this.state.groupColor.length > 0) {
            color = this.state.groupColor;
        } else if (!this.state.groupCustom) {
            const group = this.getCurrentGroup();

            this.getCurrentGroups().some((g: mediaarchiver.ITalkGroup) => {
                if (g.ID === group && !isNullOrUndefined(g.Color)) {
                    color = g.Color;
                    return true;
                }
            });
        }
        if (color[0] === '#') {
            color = color.substr(1);
        }
        return color;
    }

    private getCurrentTitles(): string[] {
        const person = this.getCurrentPerson();
        const group = this.getCurrentGroup();
        const study = this.getCurrentStudy();
        const allTitles = this.props.talk.allTitles
            .filter((title: mediaarchiver.ITalkTitle) => study === 0 || study === title.Study)
            .map((title: mediaarchiver.ITalkTitle): string => title.Title || '')
            .filter((title: string) => title.length > 0);
        let res: string[] = [];

        if (
            isNull(person) ||
            this.state.groupCustom ||
            group === 0 ||
            isNullOrUndefinedOrEmptyArray(person.Specifications)
        ) {
            res = allTitles;
        } else {
            const titles: string[] = [];

            person.Specifications.filter(
                (s: mediaarchiver.ITalkPersonSpecifications) =>
                    !isNullOrUndefined(s) &&
                    this.isTalkInUnixRange(s.Start, s.End) &&
                    !isNullOrUndefined(s.Group) &&
                    s.Group.ID === group &&
                    !isNullOrUndefined(s.Title) &&
                    !isNullOrUndefinedOrEmptyString(s.Title.Title) &&
                    (study === 0 || study === s.Study),
            )
                .map(
                    (s: mediaarchiver.ITalkPersonSpecifications): string =>
                        (s.Title as mediaarchiver.ITalkTitle).Title as string,
                )
                .forEach((t: string) => {
                    if (titles.indexOf(t) === -1) {
                        titles.push(t);
                    }
                });

            res = titles.length > 0 ? titles : allTitles;
        }
        res = res
            .filter((t: string, i: number, ts: string[]) => {
                return t !== '' && ts.indexOf(t) === i;
            })
            .sort((a, b) => {
                const aa = a || '';
                const bb = b || '';

                return aa < bb ? -1 : aa > bb ? 1 : 0;
            });
        return res.length === 0 ? allTitles : res;
    }

    private getCurrentTitle(): string {
        const titles = this.getCurrentTitles();

        if (titles.some((t: string) => t === this.state.title)) {
            return this.state.title;
        }
        if (titles.length > 0) {
            return titles[0] as string;
        }
        return '';
    }

    private getCurrentRegims(): mediaarchiver.ITalkRegim[] {
        const person = this.getCurrentPerson();
        const group = this.getCurrentGroup();
        const title = this.getCurrentTitle();
        const study = this.getCurrentStudy();
        const allRegims = this.props.talk.allRegims
            .filter((regim: mediaarchiver.ITalkRegim) => study === 0 || study === regim.Study)
            .filter((regim: mediaarchiver.ITalkRegim) => !isNullOrUndefinedOrZero(regim.ID))
            .filter((regim: mediaarchiver.ITalkRegim) => !isNullOrUndefinedOrEmptyString(regim.Name));
        let res: mediaarchiver.ITalkRegim[] = [];

        if (
            isNull(person) ||
            this.state.groupCustom ||
            group === 0 ||
            this.state.titleCustom ||
            title === '' ||
            isNullOrUndefinedOrEmptyArray(person.Specifications)
        ) {
            res = allRegims;
        } else {
            const regims: mediaarchiver.ITalkRegim[] = [];
            const ids: number[] = [];

            person.Specifications.filter(
                (s: mediaarchiver.ITalkPersonSpecifications) =>
                    !isNullOrUndefined(s) &&
                    this.isTalkInUnixRange(s.Start, s.End) &&
                    !isNullOrUndefined(s.Group) &&
                    !isNullOrUndefined(s.Title) &&
                    s.Group.ID === group &&
                    s.Title.Title === title &&
                    (study === 0 || study === s.Study),
            )
                .map(
                    (s: mediaarchiver.ITalkPersonSpecifications): mediaarchiver.ITalkRegim =>
                        s.Regim as mediaarchiver.ITalkRegim,
                )
                .forEach((r: mediaarchiver.ITalkRegim) => {
                    if (ids.indexOf(r.ID as number) === -1) {
                        regims.push(r);
                        ids.push(r.ID as number);
                    }
                });

            res = regims.length > 0 ? regims : allRegims;
        }

        if (res.length === 0) {
            res = this.props.talk.allRegims;
        }

        const ids: number[] = [];
        res = res
            .filter((g: mediaarchiver.ITalkRegim) => {
                if (isNullOrUndefinedOrZero(g.ID) || ids.indexOf(g.ID) !== -1) {
                    return false;
                }
                ids.push(g.ID as number);
                return true;
            })
            .sort((a, b) => {
                const aa = a.Name || '';
                const bb = b.Name || '';

                return aa < bb ? -1 : aa > bb ? 1 : 0;
            });
        return res.length === 0 ? allRegims : res;
    }

    private getCurrentRegim(): number {
        const regims = this.getCurrentRegims();

        if (regims.some((r: mediaarchiver.ITalkRegim) => r.ID === this.state.regim)) {
            return this.state.regim;
        }
        if (regims.length > 0) {
            return regims[0].ID as number;
        }
        return 0;
    }

    private getInitialState(): IState {
        return {
            aggregatedTimeHours: 0,
            aggregatedTimeMinutes: 0,
            aggregatedTimeSeconds: 0,
            comment: '',
            district: 0,
            districtSelected: null,
            editedSpeaker: -1,
            end: new Date(),
            errors: {
                aggregatedTime: '',
                district: '',
                general: '',
                group: '',
                person: '',
                programEnd: '',
                programName: '',
                programStart: '',
                programType: '',
                regim: '',
                speakers: '',
                study: '',
                studyType: false,
                title: '',
            },
            group: 0,
            groupColor: '#ffffff',
            groupColorShow: false,
            groupCustom: false,
            groupCustomName: '',
            personCustom: false,
            personFirstName: '',
            personFromEdited: false,
            personGender: 0,
            personID: '',
            personLastName: '',
            personName: '',
            personPicture: '',
            programEnd: null,
            programEndString: '',
            programName: '',
            programStart: null,
            programStartString: '',
            programType: TypedStorage.get('lastTalkProgramType', 0),
            programTypeCustom: false,
            programTypeCustomName: '',
            regim: 0,
            regimCustom: false,
            regimCustomName: '',
            situation: 0,
            speakerMode: 0,
            speakers: [],
            start: new Date(),
            study: TypedStorage.get('lastTalkStudy', 0),
            studyType: TypedStorage.get('lastTalkStudyType', mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION),
            talkType: mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED,
            timeType: mediaarchiver.TalkTimeType.TALK_TIME_TYPE_SPEAK,
            title: '',
            titleCustom: false,
        };
    }
}

const mapStateToProps = ({ i18n, player, talk, timeline, user }: IApplicationState) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    player,
    talk,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addTalk: (talk: mediaarchiver.ITalk) => dispatch(addTalk(talk)),
    center: () => dispatch(center()),
    clearDistricts: () => dispatch(clearDistricts()),
    clearSuggs: () => dispatch(clearSuggs()),
    editTalk: (talk: mediaarchiver.ITalk) => dispatch(editTalk(talk)),
    getAllValues: () => dispatch(getAllValues()),
    getDistricts: (q: mediaarchiver.ITalkGetDistricts) => dispatch(getDistricts(q)),
    getPerson: (id: string) => dispatch(getPerson(id)),
    getProgramTypes: () => dispatch(getProgramTypes()),
    getStudies: () => dispatch(getStudies()),
    getSugg: (typed: string, study: number) => dispatch(getSugg(typed, study)),
    hideDialog: () => dispatch(hideDialog()),
    setEndSelection: (time: Date | null) => dispatch(setEndSelection(time)),
    setSnackMessage: (msg: string) => dispatch(setSnackMessage(msg)),
    setStartSelection: (time: Date | null) => dispatch(setStartSelection(time)),
});

export const TalkDialog = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(TalkDialogComponent));
