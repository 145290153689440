import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import * as Proto from '../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos } from '../../Store/I18n';
import { update as getMedias } from '../../Store/Medias/Actions';
import { IMediasState } from '../../Store/Medias/Types';
import {
    clear as clearSearch,
    search as doSearch,
    setLastSearch,
    setResultsCount,
    setTimelineProgram,
} from '../../Store/Search/Actions';
import { ISearchParams, ISearchState, NullableResult, NullableStructuredResult } from '../../Store/Search/Types';
import { getTheme } from '../../Themes';
import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyArray,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../Utils/Various';

const theme = getTheme();

export const styles = MD.createStyles({
    '@keyframes loading': {
        '0%': {
            backgroundColor: theme.palette.background.paper,
        },
        '50%': {
            backgroundColor: theme.palette.background.default,
        },
        '100%': {
            backgroundColor: theme.palette.background.paper,
        },
    },
    expandCell: {
        cursor: 'pointer',
    },
    initialPaper: {
        height: '10ch',
        padding: theme.spacing(1),
        textAlign: 'center',
    },
    resultIconView: {
        cursor: 'pointer',
    },
    rootInitial: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
    },
    rowHidden: {
        display: 'none',
    },
    rowHighlight: {
        '&>td': {
            opacity: '1 !important',
        },
        display: 'table-row !important',
    },
    rowLoading: {
        animation: '$loading linear 1s infinite',
    },
    rowPlanned: {
        backgroundColor: theme.palette.background.paper,
    },
    rowReadjusted: {
        '&>td': {
            opacity: 0.75,
        },

        backgroundColor: theme.palette.background.default,
        // display: 'none',
    },
    table: {
        minWidth: '100%',
        userSelect: 'text',
    },
    tableRoot: {
        maxHeight: 'calc(100% - 40px)',
        minHeight: 'calc(100% - 40px)',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    tableRootContainer: {
        height: '100%',
        overflow: 'hidden',
        padding: theme.spacing(2),
    },
    tableTitle: {
        height: 40,
    },
});

interface IState {
    reducedPrograms: string[];
}

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
    router: RouteComponentProps<{}>;
    search: ISearchState;
}

interface IPropsFromDispatch {
    clearSearch: typeof clearSearch;
    doSearch: typeof doSearch;
    getMedias: typeof getMedias;
    setLastSearch: typeof setLastSearch;
    setResultsCount: typeof setResultsCount;
    setTimelineProgram: typeof setTimelineProgram;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class SearchResultsComponent extends React.Component<AllProps, IState> {
    private programsList: string[] = [];
    private triggeredLastElement: HTMLTableRowElement = document.createElement('tr');

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
    }

    public componentDidMount(): void {
        this.props.getMedias();
    }

    public componentWillUnmount(): void {
        this.props.clearSearch();
    }

    public render(): React.ReactNode {
        return isNullOrUndefined(this.props.search.currentSearch) ? this.renderInitial() : this.renderResults();
    }

    private renderInitial(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid className={classes.rootInitial} container>
                <MD.Grid item lg={3} md={6} xs={10}>
                    <MD.Paper className={classes.initialPaper}>
                        <MD.Typography variant='body1'>
                            {this.props.i18n._('Please select your search parameters and launch a search')}
                        </MD.Typography>
                    </MD.Paper>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private renderResults(): React.ReactNode {
        const { classes } = this.props;
        const searchMedias = this.getSearchMedia();

        return (
            <MD.Grid className={classes.tableRootContainer} container>
                <MD.Grid className={classes.tableTitle} item xs={12}>
                    <MD.Typography variant='h5'>
                        {this.props.search.searchLoading ? (
                            <div>
                                <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                            </div>
                        ) : (
                            (searchMedias !== '' ? `${searchMedias} : ` : '') +
                            this.props.i18n.sprintf(
                                this.props.i18n.ngettext(
                                    '%1$d result',
                                    '%1$d results',
                                    this.props.search.searchResultsTotal,
                                ),
                                this.props.search.searchResultsTotal,
                            )
                        )}
                    </MD.Typography>
                </MD.Grid>
                <MD.TableContainer
                    className={classes.tableRoot}
                    component={MD.Grid}
                    item
                    onScroll={this.checkScroll.bind(this)}
                    xs={12}
                >
                    <MD.Table className={classes.table} size='small' stickyHeader>
                        {this.buildTableHead()}
                        {this.buildTableRows()}
                    </MD.Table>
                </MD.TableContainer>
            </MD.Grid>
        );
    }

    private buildTableHead(): React.ReactNode {
        const allReduced =
            this.programsList.length > 0 && this.state.reducedPrograms.length === this.programsList.length;
        const expandIcon = allReduced ? FA.faChevronDoubleDown : FA.faChevronDoubleUp;
        const expandText = allReduced
            ? this.props.i18n._('Reduce all program')
            : this.props.i18n._('Expand all program');

        return (
            <MD.TableHead>
                <MD.TableRow>
                    <MD.Tooltip title={expandText}>
                        <MD.TableCell
                            onClick={() => {
                                this.setState({ reducedPrograms: allReduced ? [] : this.programsList });
                            }}
                            padding='checkbox'
                        >
                            <FontAwesomeIcon icon={expandIcon} />
                        </MD.TableCell>
                    </MD.Tooltip>
                    <MD.TableCell>{this.props.i18n._('Date')}</MD.TableCell>
                    <MD.TableCell>{this.props.i18n._('Start')}</MD.TableCell>
                    <MD.TableCell>{this.props.i18n._('End')}</MD.TableCell>
                    <MD.TableCell>{this.props.i18n._('Duration')}</MD.TableCell>
                    <MD.TableCell>{this.props.i18n._('Level')}</MD.TableCell>
                    <MD.TableCell>{this.props.i18n._('Show')}</MD.TableCell>
                    <MD.TableCell>{this.props.i18n._('Subtitle')}</MD.TableCell>
                    <MD.TableCell>{this.props.i18n._('Kind')}</MD.TableCell>
                    <MD.TableCell>{''}</MD.TableCell>
                </MD.TableRow>
            </MD.TableHead>
        );
    }

    private buildTableRows(): React.ReactNode {
        return (
            <MD.TableBody key={'table_body'}>
                {this.props.search.structuredSearchResults.reduce<React.ReactNode[]>(
                    (list: React.ReactNode[], res: NullableStructuredResult): React.ReactNode[] => {
                        return list.concat(this.buildTableRow(res));
                    },
                    [],
                )}
            </MD.TableBody>
        );
    }

    private buildTableRow(res: NullableStructuredResult): React.ReactNode[] {
        if (isNullOrUndefined(res)) {
            return [
                <MD.TableRow className={this.props.classes.rowLoading} key={`row_loading_${Math.random()}`}>
                    <MD.TableCell colSpan={9}>{''}</MD.TableCell>
                </MD.TableRow>,
            ];
        }
        if (isNullOrUndefinedOrEmptyString(res.ID)) {
            return [];
        }

        const { classes } = this.props;

        if (this.programsList.indexOf(res.ID) === -1) {
            this.programsList.push(res.ID);
        }

        const hasReadjusted = !isNullOrUndefinedOrEmptyArray(res.Readjusted);
        let rows: React.ReactNode[] = [];
        const expanded = this.state.reducedPrograms.indexOf(res.ID) === -1;
        const expandIcon = expanded ? FA.faChevronDown : FA.faChevronUp;

        rows.push(
            <MD.TableRow className={this.props.classes.rowPlanned} key={`row_planned_${res.ID}`}>
                {hasReadjusted ? (
                    <MD.TableCell
                        className={classes.expandCell}
                        onClick={() => {
                            let reducedPrograms = this.state.reducedPrograms;

                            if (expanded) {
                                reducedPrograms.push(res.ID as string);
                            } else {
                                reducedPrograms = reducedPrograms.filter((i) => i !== res.ID);
                            }
                            this.setState({ reducedPrograms });
                        }}
                        padding='checkbox'
                    >
                        <FontAwesomeIcon icon={expandIcon} />
                    </MD.TableCell>
                ) : (
                    <MD.TableCell>
                        <MD.Tooltip title={this.props.i18n._('See content in timeline')}>
                            <MD.Typography
                                className={this.props.classes.resultIconView}
                                onClick={(): void => {
                                    if (
                                        !isNullOrUndefinedOrEmptyArray(res.Planned) &&
                                        !isNullOrUndefined(res.Planned[0])
                                    ) {
                                        this.props.setTimelineProgram(res.Planned[0]);
                                        this.props.router.history.push('/timeline');
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={FA.faVideo} />
                            </MD.Typography>
                        </MD.Tooltip>
                    </MD.TableCell>
                )}
                <MD.TableCell colSpan={9}>
                    {this.getProgramTitle(res)} {this.getProgramHours(res)}
                </MD.TableCell>
            </MD.TableRow>,
        );
        if (hasReadjusted && !isNullOrUndefined(res.Readjusted)) {
            rows = rows.concat(this.buildTableRowReadjusted(res.Readjusted, res.ID));
        }
        return rows;
    }

    private getSearchMedia(): string {
        if (
            isNullOrUndefined(this.props.search.currentSearch) ||
            isNullOrUndefinedOrEmptyArray(this.props.search.currentSearch.medias)
        ) {
            return '';
        }

        if (this.props.search.currentSearch.medias.length === 1) {
            let mediaName = '';

            this.props.medias.data.some((m) => {
                if (
                    isNullOrUndefined(this.props.search.currentSearch) ||
                    isNullOrUndefinedOrEmptyArray(this.props.search.currentSearch.medias)
                ) {
                    return false;
                }
                if (m.id === this.props.search.currentSearch.medias[0]) {
                    mediaName = m.name;
                    return true;
                }
                return false;
            });
            return mediaName;
        }
        return this.props.i18n.sprintf(
            this.props.i18n.ngettext('%1$d media', '%1$d medias', this.props.search.currentSearch.medias.length),
            this.props.search.currentSearch.medias.length,
        );
    }
    private getProgramMedia(res: Proto.mediaarchiver.IStructuredSearchResult): string {
        let mediaID = 0;
        let mediaName = '';
        if (!isNullOrUndefined(res.Readjusted) && !isNullOrUndefinedOrEmptyString(res.Readjusted.MediaName)) {
            return res.Readjusted.MediaName as string;
        } else if (!isNullOrUndefined(res.Readjusted) && !isNullOrUndefinedOrZero(res.Readjusted.MediaID)) {
            mediaID = res.Readjusted.MediaID as number;
        } else if (
            !isNullOrUndefined(res.Planned) &&
            res.Planned.length !== 0 &&
            !isNullOrUndefinedOrZero(res.Planned[0].MediaID)
        ) {
            mediaID = res.Planned[0].MediaID as number;
        } else {
            return '';
        }
        this.props.medias.data.some((media) => {
            if (media.id === mediaID) {
                mediaName = media.name;
                return true;
            }
            return false;
        });
        return mediaName;
    }

    private getProgramTitle(res: Proto.mediaarchiver.IStructuredSearchResult): string {
        if (
            !isNullOrUndefined(res.Planned) &&
            res.Planned.length !== 0 &&
            !isNullOrUndefinedOrEmptyString(res.Planned[0].Title)
        ) {
            return res.Planned[0].Title.toLocaleUpperCase();
        }
        if (
            !isNullOrUndefined(res.Planned) &&
            res.Planned.length !== 0 &&
            !isNullOrUndefinedOrEmptyString(res.Planned[0].Subtitle)
        ) {
            return res.Planned[0].Subtitle.toLocaleUpperCase();
        }
        if (!isNullOrUndefined(res.Readjusted) && !isNullOrUndefinedOrEmptyString(res.Readjusted.Title)) {
            return res.Readjusted.Title.toLocaleUpperCase();
        }
        if (!isNullOrUndefined(res.Readjusted) && !isNullOrUndefinedOrEmptyString(res.Readjusted.Subtitle)) {
            return res.Readjusted.Subtitle.toLocaleUpperCase();
        }
        return 'N/A';
    }

    private getProgramHours(res: Proto.mediaarchiver.IStructuredSearchResult): string {
        let start = 0;
        let end = 0;

        if (
            !isNullOrUndefined(res.Readjusted) &&
            !isNullOrUndefinedOrZero(res.Readjusted.Start) &&
            !isNullOrUndefinedOrZero(res.Readjusted.DurationMS)
        ) {
            start = res.Readjusted.Start as number;
            end = (start + res.Readjusted.DurationMS) as number;
        } else if (
            !isNullOrUndefined(res.Planned) &&
            res.Planned.length !== 0 &&
            !isNullOrUndefinedOrZero(res.Planned[0].Start) &&
            !isNullOrUndefinedOrZero(res.Planned[0].DurationMS)
        ) {
            start = res.Planned[0].Start as number;
            end = (start + res.Planned[0].DurationMS) as number;
        } else {
            return '';
        }
        return (
            '' +
            moment(new Date(start)).format(this.props.localeInfos.formatShortDateTime) +
            ' - ' +
            moment(new Date(end)).format(this.props.localeInfos.formatShortTime)
        );
    }

    private buildTableRowReadjusted(
        res: Proto.mediaarchiver.ISearchResult,
        parentID: string,
        level = 0,
        item = 0,
    ): React.ReactNode[] {
        if (isNullOrUndefined(res)) {
            return [];
        }
        const rowClasses = classNames({
            [this.props.classes.rowHidden]: this.state.reducedPrograms.indexOf(parentID) !== -1,
            [this.props.classes.rowReadjusted]: true,
            [this.props.classes.rowHighlight]: this.isRowHighlighted(res),
        });

        let rows: React.ReactNode[] = [];

        rows.push(
            <MD.TableRow className={rowClasses} key={`row_readjusted_${parentID}_${level}_${item}`}>
                <MD.TableCell colSpan={2}>{this.renderResultDate(res)}</MD.TableCell>
                <MD.TableCell>{this.renderResultStartHour(res)}</MD.TableCell>
                <MD.TableCell>{this.renderResultEndHour(res)}</MD.TableCell>
                <MD.TableCell>{this.renderResultDuration(res)}</MD.TableCell>
                <MD.TableCell>{res.Level || ''}</MD.TableCell>
                <MD.TableCell>{this.renderResultTitle(res)}</MD.TableCell>
                <MD.TableCell>{this.renderResultSubtitle(res)}</MD.TableCell>
                <MD.TableCell>{this.renderResultKind(res)}</MD.TableCell>
                <MD.TableCell>
                    {this.canPlayContent(res) ? (
                        <MD.Tooltip title={this.props.i18n._('See content in timeline')}>
                            <MD.Typography
                                className={this.props.classes.resultIconView}
                                onClick={(): void => {
                                    if (!isNullOrUndefined(res)) {
                                        this.props.setTimelineProgram(res);
                                        this.props.router.history.push('/timeline');
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={FA.faVideo} />
                            </MD.Typography>
                        </MD.Tooltip>
                    ) : (
                        ''
                    )}
                </MD.TableCell>
            </MD.TableRow>,
        );
        if (Array.isArray(res.Children) && res.Children.length > 0) {
            level = level + 1;
            rows = rows.concat(
                res.Children.map((child) => {
                    item = item + 1;
                    return this.buildTableRowReadjusted(child, parentID, level, item);
                }),
            );
        }
        return rows;
    }

    private isRowHighlighted(res: Proto.mediaarchiver.ISearchResult | null): boolean {
        if (
            isNullOrUndefined(res) ||
            isNullOrUndefined(this.props.search.currentSearch) ||
            (this.props.search.currentSearch.kinds.length === 0 && this.props.search.currentSearch.text === '')
        ) {
            return true;
        }

        if (!isNullOrUndefined(res.Kind)) {
            let highlighted = false;

            this.props.search.currentSearch.kinds.some((kind) => {
                if (isNullOrUndefined(res.Kind)) {
                    return false;
                }
                if (kind === res.Kind.substr(0, kind.length)) {
                    highlighted = true;
                    return true;
                }
                return false;
            });
            if (highlighted) {
                return highlighted;
            }
        }
        if (
            !isNullOrUndefinedOrEmptyString(this.props.search.currentSearch.text) &&
            !isNullOrUndefinedOrEmptyString(res.Title) &&
            res.Title.toLocaleLowerCase().indexOf(this.props.search.currentSearch.text) !== -1
        ) {
            return true;
        }
        if (
            !isNullOrUndefinedOrEmptyString(this.props.search.currentSearch.text) &&
            !isNullOrUndefinedOrEmptyString(res.Subtitle) &&
            res.Subtitle.toLocaleLowerCase().indexOf(this.props.search.currentSearch.text) !== -1
        ) {
            return true;
        }
        return false;
    }

    private renderResultTitle(res: Proto.mediaarchiver.ISearchResult | null): string {
        if (isNullOrUndefined(res) || isNullOrUndefinedOrEmptyString(res.Title)) {
            return ' ';
        }
        let s = '';

        if (!isNullOrUndefinedOrZero(res.Level) && res.Level === 2) {
            s += '└─ ';
        }
        if (!isNullOrUndefinedOrZero(res.Level) && res.Level === 3) {
            s += '└─── ';
        }
        s += res.Title.toLocaleUpperCase();
        return s;
    }

    private renderResultSubtitle(res: Proto.mediaarchiver.ISearchResult | null): string {
        if (isNullOrUndefined(res) || isNullOrUndefinedOrEmptyString(res.Subtitle)) {
            return ' ';
        }
        return res.Subtitle.toLocaleUpperCase();
    }

    private renderResultDate(res: Proto.mediaarchiver.ISearchResult | null): string {
        if (isNullOrUndefined(res) || isNullOrUndefinedOrZero(res.Start)) {
            return ' ';
        }
        return moment(new Date(res.Start as number)).format(this.props.localeInfos.formatShortDate);
    }

    private renderResultStartHour(res: Proto.mediaarchiver.ISearchResult | null): string {
        if (isNullOrUndefined(res) || isNullOrUndefinedOrZero(res.Start)) {
            return ' ';
        }
        return moment(new Date(res.Start as number)).format(this.props.localeInfos.formatShortTimeWithMilliseconds);
    }

    private renderResultEndHour(res: Proto.mediaarchiver.ISearchResult | null): string {
        if (isNullOrUndefined(res) || isNullOrUndefinedOrZero(res.Start) || isNullOrUndefinedOrZero(res.DurationMS)) {
            return ' ';
        }
        return moment(new Date((res.Start as number) + (res.DurationMS as number))).format(
            this.props.localeInfos.formatShortTimeWithMilliseconds,
        );
    }

    private renderResultDuration(res: Proto.mediaarchiver.ISearchResult | null): string {
        if (isNullOrUndefined(res) || isNullOrUndefinedOrZero(res.DurationMS)) {
            return ' ';
        }
        const duration = moment.duration(res.DurationMS as number);

        let v =
            ('0' + duration.hours().toString()).slice(-2) +
            ':' +
            ('0' + duration.minutes().toString()).slice(-2) +
            ':' +
            ('0' + duration.seconds().toString()).slice(-2);

        if (duration.milliseconds() > 0) {
            v += '.' + ('0' + duration.milliseconds().toString()).slice(-2);
        }
        return v;
    }

    private renderResultKind(res: Proto.mediaarchiver.ISearchResult | null): string {
        if (isNullOrUndefined(res) || isNullOrUndefinedOrEmptyString(res.Kind)) {
            return ' ';
        }
        return res.Kind;
    }

    private canPlayContent(res: Proto.mediaarchiver.ISearchResult | null): boolean {
        if (isNullOrUndefined(res) || isNullOrUndefinedOrZero(res.Start)) {
            return false;
        }
        return res.Start <= new Date().getTime() - 1 * 60 * 60 * 1000;
    }

    private checkScroll(ev: React.UIEvent<HTMLDivElement, UIEvent>) {
        if (isNull(this.props.search.currentSearch)) {
            return;
        }
        if (this.props.search.searchResults.length >= this.props.search.searchResultsTotal) {
            return;
        }
        const container = ev.target as HTMLDivElement;
        if (isNullOrUndefined(container)) {
            return;
        }
        if (container.scrollTop + container.clientHeight < container.scrollHeight - 100) {
            return;
        }
        const tables = container.getElementsByTagName('table');
        if (tables.length === 0) {
            return;
        }
        const trs = tables[0].getElementsByTagName('tr');
        if (trs.length === 0) {
            return;
        }
        if (this.triggeredLastElement === trs[trs.length - 1]) {
            return;
        }
        this.triggeredLastElement = trs[trs.length - 1] as HTMLTableRowElement;
        this.props.doSearch({
            end: this.props.search.currentSearch.end,
            kinds: this.props.search.currentSearch.kinds,
            medias: this.props.search.currentSearch.medias,
            page: this.props.search.currentSearch.page + 1,
            pageSize: this.props.search.currentSearch.pageSize,
            start: this.props.search.currentSearch.start,
            text: this.props.search.currentSearch.text,
        });
    }

    private getDefaultState(): IState {
        return {
            reducedPrograms: [],
        };
    }
}

const mapStateToProps = ({ i18n, medias, search }: IApplicationState, ownProps: RouteComponentProps<{}>) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    medias,
    router: ownProps,
    search,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearSearch: () => dispatch(clearSearch()),
    doSearch: (params: ISearchParams) => dispatch(doSearch(params)),
    getMedias: () => dispatch(getMedias()),
    setLastSearch: (lastSearch: ISearchParams | null) => dispatch(setLastSearch(lastSearch)),
    setResultsCount: (count: number) => dispatch(setResultsCount(count)),
    setTimelineProgram: (res: NullableResult) => dispatch(setTimelineProgram(res)),
});

export const SearchResults = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(SearchResultsComponent));
