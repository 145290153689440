import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class CreateAnnotationType extends AMessage implements IToCommand {
    private type: Proto.mediaarchiver.AnnotationType;

    public constructor(type: Proto.mediaarchiver.AnnotationType) {
        super('createAnnotationType');
        this.type = type;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            CreateAnnotationTypeArgs : this.type,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeCreateAnnotationType,
        }));
    }
}
