import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import * as React from 'react';
import FlagIconFactory from 'react-flag-icon-css';
import { RouteComponentProps } from 'react-router-dom';

import { Config } from '../../config';
import { IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos, Locales, setLocale } from '../../Store/I18n';
import { hideAnnotations, hideDrawer, ILayoutState, showAnnotations, showDrawer } from '../../Store/Layout';
import { hideReportBug, reportBug, showReportBug } from '../../Store/Timeline/Actions';
import { hideIncident, showIncident, checkIncidentContent } from '../../Store/Incident/Actions';
import { ITimelineState } from '../../Store/Timeline/Types';
import { IUser, logout } from '../../Store/User';
import styles from './styles';

import Logo from '../../Images/logo.png';
import { mediaarchiver } from '../../Protos/protos';
import { TypedStorage } from '../../Utils/TypedStorage';
import { IIncidentState } from '../../Store/Incident';
import { isNullOrUndefinedOrEmptyArray } from '../../Utils/Various';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

export interface IUIProps {
    localeMenuAnchor: HTMLElement | null;
    localeMenuOpen: boolean;
    reportBugError: string;
    reportBugValues: Record<string, string>;
    userMenuAnchor: HTMLElement | null;
    userMenuOpen: boolean;
}

interface IPropsFromState {
    i18n: I18N;
    incident: IIncidentState;
    layout: ILayoutState;
    localeInfos: ILocaleInfos;
    router: RouteComponentProps<{}>;
    timeline: ITimelineState;
    ui: IUIProps;
    user: IUser;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateUI: (name: string, value: any) => void;
    width: string;
}

interface IPropsFromDispatch {
    hideAnnotations: typeof hideAnnotations;
    hideDrawer: typeof hideDrawer;
    hideIncident: typeof hideIncident;
    hideReportBug: typeof hideReportBug;
    logout: typeof logout;
    reportBug: typeof reportBug;
    setLocale: typeof setLocale;
    showAnnotations: typeof showAnnotations;
    showDrawer: typeof showDrawer;
    showIncident: typeof showIncident;
    showReportBug: typeof showReportBug;
    checkIncidentContent: typeof checkIncidentContent;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export default class AppBarComponent extends React.Component<AllProps> {
    public state = {
        localeMenuAnchor: null,
        localeMenuOpen: false,
        reportBugErrors: '',
        reportBugValues: {
            company: this.props.user.groupName,
            email: this.props.user.email,
            name: this.props.user.getFullName(),
            phone: this.props.user.phone,
        } as Record<string, string>,
        userMenuAnchor: null,
        userMenuOpen: false,
    };

    public render(): React.ReactNode {
        const { classes } = this.props;
        let currentPath = '/timeline';
        const pathParts = this.props.router.location.pathname.split('/');

        switch (pathParts.length > 1 ? pathParts[1] : '') {
            case 'timeline':
            case 'search':
            case 'searchSTT':
            case 'archives':
            case 'account':
                currentPath = `/${pathParts[1]}`;
                break;
            default:
                if (this.props.router.location.pathname.substr(0, 8) === '/account') {
                    currentPath = '/account';
                    break;
                }
        }
        return (
            <MD.AppBar className={classes.appBar}>
                <MD.Toolbar>
                    {/* Hamburger */}
                    {this.props.layout.drawerContent !== null ? (
                        <MD.IconButton className={classes.hamburger} onClick={this.toggleDrawer.bind(this)}>
                            <MD.Icon color='primary'>
                                <FontAwesomeIcon icon={FA.faBars} />
                            </MD.Icon>
                        </MD.IconButton>
                    ) : (
                        ''
                    )}
                    {/* /Hamburger */}
                    {/* Logo */}
                    <MD.Hidden xsDown={true}>
                        <MD.Button href='/timeline' variant='text'>
                            <img className={classes.logo} src={Logo} />
                        </MD.Button>
                        <MD.Typography className={classes.version} color='secondary' variant='caption'>
                            {Config.version}
                        </MD.Typography>
                    </MD.Hidden>
                    {/* /Logo */}
                    {/* Top links */}
                    {!this.props.user.share ? (
                        <MD.Tabs
                            className={classes.tabs}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={this.onMenuTabChange.bind(this) as any}
                            value={currentPath}
                        >
                            <MD.Tab
                                className={classes.tabItem}
                                label={
                                    this.props.width === 'xs' ? (
                                        <FontAwesomeIcon icon={FA.faSlidersH} />
                                    ) : (
                                        this.props.i18n._('Timeline')
                                    )
                                }
                                value={'/timeline'}
                            />
                            <MD.Tab
                                className={classes.tabItem}
                                label={
                                    this.props.width === 'xs' ? (
                                        <FontAwesomeIcon icon={FA.faSearch} />
                                    ) : (
                                        this.props.i18n._('EPG Search')
                                    )
                                }
                                value={'/search'}
                            />
                            {this.props.user.functionalities.indexOf(
                                mediaarchiver.UserFunctionalities.FUNCTIONALITY_STT,
                            ) !== -1 ? (
                                <MD.Tab
                                    className={classes.tabItem}
                                    label={this.props.i18n._('Key Word Search')}
                                    value={'/searchSTT'}
                                />
                            ) : (
                                ''
                            )}
                            <MD.Tab
                                className={classes.tabItem}
                                label={
                                    this.props.width === 'xs' ? (
                                        <FontAwesomeIcon icon={FA.faArchive} />
                                    ) : (
                                        this.props.i18n._('Archives')
                                    )
                                }
                                value={'/archives'}
                            />
                            <MD.Tab
                                className={classes.tabItem}
                                label={
                                    this.props.width === 'xs' ? (
                                        <FontAwesomeIcon icon={FA.faUserAlt} />
                                    ) : (
                                        this.props.i18n._('My account')
                                    )
                                }
                                value={'/account'}
                            />
                        </MD.Tabs>
                    ) : (
                        ''
                    )}
                    {/* /Top links */}
                    {/* Bug icon */}
                    {/*
                    <MD.IconButton
                        color='primary'
                        onClick={() => {
                            this.props.showReportBug();
                        }}
                    >
                        <FontAwesomeIcon icon={FA.faBug} />
                    </MD.IconButton>
                    */}
                    {/* /Bug icon */}
                    {/* User icon */}
                    {!this.props.user.share ? (
                        <MD.IconButton
                            aria-owns={this.state.userMenuOpen ? 'menu-user' : undefined}
                            aria-haspopup='true'
                            color='primary'
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={this.handleUserMenuOpen.bind(this) as any}
                        >
                            <FontAwesomeIcon icon={FA.faUserCircle} />
                        </MD.IconButton>
                    ) : (
                        ''
                    )}
                    {/* /User icon */}
                    {/* Locale icon */}
                    <MD.IconButton
                        aria-owns={this.state.localeMenuOpen ? 'menu-locale' : undefined}
                        aria-haspopup='true'
                        color='primary'
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onClick={this.handleLocaleMenuOpen.bind(this) as any}
                    >
                        <FlagIcon code={this.props.localeInfos.iconName} />
                    </MD.IconButton>
                    {/* /Locale icon */}
                    {/* User menu */}
                    {!this.props.user.share ? (
                        <MD.Menu
                            anchorEl={this.state.userMenuAnchor}
                            anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'top',
                            }}
                            className={classes.menus}
                            id='menu-user'
                            transformOrigin={{
                                horizontal: 'left',
                                vertical: 'bottom',
                            }}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClose={this.handleUserMenuClose.bind(this) as any}
                            open={this.state.userMenuOpen}
                        >
                            <MD.Button
                                className={classes.menuItems}
                                component='a'
                                href='/account'
                                title={this.props.i18n._('My account')}
                                variant='text'
                            >
                                {this.props.user.getFullName()}
                            </MD.Button>

                            <MD.Button
                                className={classes.menuItems}
                                component='a'
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onClick={this.handleLogout.bind(this) as any}
                                title={this.props.i18n._('Log out my session')}
                                variant='text'
                            >
                                {this.props.i18n._('Logout')}
                            </MD.Button>
                        </MD.Menu>
                    ) : (
                        ''
                    )}
                    {/* /User menu */}
                    {/* Locale menu */}
                    <MD.Menu
                        anchorEl={this.state.localeMenuAnchor}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        className={classes.menus}
                        id='menu-locale'
                        transformOrigin={{
                            horizontal: 'left',
                            vertical: 'bottom',
                        }}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onClose={this.handleLocaleMenuClose.bind(this) as any}
                        open={this.state.localeMenuOpen}
                    >
                        <MD.Button
                            className={classes.menuItems}
                            onClick={this.onLocaleChange.bind(this, Locales.French)}
                            variant='text'
                        >
                            <FlagIcon className={classes.menuFlags} code='fr' />
                            Passer en Français
                        </MD.Button>
                        <MD.Button
                            className={classes.menuItems}
                            onClick={this.onLocaleChange.bind(this, Locales.English)}
                            variant='text'
                        >
                            <FlagIcon className={classes.menuFlags} code='gb' />
                            Switch to english
                        </MD.Button>
                        <MD.Button
                            className={classes.menuItems}
                            onClick={this.onLocaleChange.bind(this, Locales.German)}
                            variant='text'
                        >
                            <FlagIcon className={classes.menuFlags} code='de' />
                            Wechseln Sie zu Deutsch
                        </MD.Button>
                    </MD.Menu>
                    {/* /Locale menu */}
                </MD.Toolbar>
                <div className={this.props.classes.appBarLine}>
                    {this.props.layout.drawerContent !== null ? (
                        <div
                            className={
                                this.props.classes.appBarLineDrawerFolder +
                                (this.props.layout.showDrawer
                                    ? ''
                                    : ` ${this.props.classes.appBarLineDrawerFolderClosed}`)
                            }
                        >
                            <div onClick={this.toggleDrawer.bind(this)}>
                                {this.props.layout.showDrawer ? (
                                    <FontAwesomeIcon icon={FA.faArrowFromRight} />
                                ) : (
                                    <FontAwesomeIcon icon={FA.faArrowFromLeft} />
                                )}
                            </div>
                            <span>{this.props.layout.drawerTitle}</span>
                        </div>
                    ) : (
                        ''
                    )}
                    <div
                        className={
                            this.props.classes.appBarLinePageTitle +
                            (this.props.layout.showDrawer && this.props.layout.drawerContent !== null
                                ? ''
                                : ` ${this.props.classes.appBarLinePageTitleClosed}`)
                        }
                    >
                        {this.props.layout.pageTitle}
                    </div>
                    {this.props.layout.hasAnnotations ? (
                        <div
                            className={this.props.classes.appBarLineAnnotationsFolder}
                            onClick={this.toggleAnnotations.bind(this)}
                        >
                            {this.props.layout.showAnnotations ? (
                                <FontAwesomeIcon icon={FA.faArrowFromLeft} />
                            ) : (
                                <FontAwesomeIcon icon={FA.faArrowFromRight} />
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {this.getBugDialog()}
                {this.getIncidentDialog()}
            </MD.AppBar>
        );
    }

    componentDidMount(): void {
        this.props.checkIncidentContent();
    }

    componentDidUpdate(prevProps: Readonly<AllProps>): void {
        if (
            prevProps.incident.incidentContent.incidentMessages.length > 0 &&
            !this.props.incident.showIncident &&
            !TypedStorage.get('incidentFirstTimeDisplayed', false)
        ) {
            this.props.showIncident();
            TypedStorage.set('incidentFirstTimeDisplayed', true);
        }
    }

    private handleUserMenuOpen(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.setState({
            userMenuAnchor: ev.target,
            userMenuOpen: true,
        });
    }

    private handleUserMenuClose(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.setState({
            userMenuAnchor: null,
            userMenuOpen: false,
        });
    }

    private handleLocaleMenuOpen(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.setState({
            localeMenuAnchor: ev.target,
            localeMenuOpen: true,
        });
    }

    private handleLocaleMenuClose(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.setState({
            localeMenuAnchor: null,
            localeMenuOpen: false,
        });
    }

    private handleLogout(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.logout();
    }

    private onMenuTabChange(ev: MouseEvent, value: string) {
        ev.preventDefault();
        ev.stopPropagation();
        if (ev.target === null) {
            return;
        }
        //reinitialise le props du panel et media
        this.props.history.push(value);
    }

    private toggleAnnotations() {
        if (this.props.layout.showAnnotations) {
            this.props.hideAnnotations();
            return;
        }
        this.props.showAnnotations();
    }

    private toggleDrawer() {
        if (this.props.layout.showDrawer) {
            this.props.hideDrawer();
            return;
        }
        this.props.showDrawer();
    }

    private onLocaleChange(locale: Locales) {
        this.setState({
            localeMenuAnchor: null,
            localeMenuOpen: false,
            userMenuAnchor: null,
            userMenuOpen: false,
        });
        this.props.setLocale(locale);
    }

    private getIncidentDialog() {
        return (
            <MD.Dialog
                maxWidth='md'
                open={this.props.incident.showIncident}
                onClose={() => {
                    this.props.hideIncident();
                }}
            >
                <MD.DialogTitle>
                    {!isNullOrUndefinedOrEmptyArray(this.props.incident.incidentContent.incidentMessages)
                        ? 'Incident en cours'
                        : 'Aucun incident en cours'}
                </MD.DialogTitle>
                <MD.DialogContent className={this.props.classes.bugDialogContent}>
                    <MD.Box>
                        <MD.Grid container>
                            {this.props.incident.incidentContent.incidentMessages.map((message, index) => {
                                return (
                                    <MD.Grid item xs={12} key={index}>
                                        <h3>{message.title}</h3>
                                        <p>{message.message}</p>
                                    </MD.Grid>
                                );
                            })}
                        </MD.Grid>
                    </MD.Box>
                </MD.DialogContent>
            </MD.Dialog>
        );
    }

    private getBugDialog() {
        return (
            <MD.Dialog
                maxWidth='md'
                open={this.props.timeline.showReportBug}
                onClose={() => {
                    this.props.hideReportBug();
                }}
            >
                <MD.DialogTitle>{this.props.i18n._('Report a bug')}</MD.DialogTitle>
                <MD.DialogContent className={this.props.classes.bugDialogContent}>
                    <MD.Box>
                        <MD.Grid container>
                            <MD.Grid item xs={12}>
                                <MD.FormControl className={this.props.classes.bugDialogFormElement} fullWidth={true}>
                                    <MD.TextField
                                        disabled
                                        error={this.props.timeline.reportBugErrors.has('name')}
                                        fullWidth={true}
                                        helperText={this.props.timeline.reportBugErrors.get('name') || ''}
                                        label={this.props.i18n._('Your name')}
                                        onChange={(e) => {
                                            this.setState({
                                                ...this.state,
                                                reportBugValues: {
                                                    ...this.state.reportBugValues,
                                                    name: e.target.value,
                                                },
                                            });
                                        }}
                                        value={this.state.reportBugValues.name}
                                    />
                                </MD.FormControl>
                            </MD.Grid>
                            <MD.Grid item xs={12}>
                                <MD.FormControl className={this.props.classes.bugDialogFormElement} fullWidth={true}>
                                    <MD.TextField
                                        disabled
                                        error={this.props.timeline.reportBugErrors.has('mail')}
                                        fullWidth={true}
                                        helperText={this.props.timeline.reportBugErrors.get('mail') || ''}
                                        label={this.props.i18n._('Your email')}
                                        onChange={(e) => {
                                            this.setState({
                                                ...this.state,
                                                reportBugValues: {
                                                    ...this.state.reportBugValues,
                                                    email: e.target.value,
                                                },
                                            });
                                        }}
                                        value={this.state.reportBugValues.email}
                                    />
                                </MD.FormControl>
                            </MD.Grid>
                            <MD.Grid item xs={12}>
                                <MD.FormControl className={this.props.classes.bugDialogFormElement} fullWidth={true}>
                                    <MD.TextField
                                        disabled
                                        error={this.props.timeline.reportBugErrors.has('company')}
                                        fullWidth={true}
                                        helperText={this.props.timeline.reportBugErrors.get('company') || ''}
                                        label={this.props.i18n._('Company')}
                                        onChange={(e) => {
                                            this.setState({
                                                ...this.state,
                                                reportBugValues: {
                                                    ...this.state.reportBugValues,
                                                    company: e.target.value,
                                                },
                                            });
                                        }}
                                        value={this.state.reportBugValues.company}
                                    />
                                </MD.FormControl>
                            </MD.Grid>
                            <MD.Grid item xs={12}>
                                <MD.FormControl className={this.props.classes.bugDialogFormElement} fullWidth={true}>
                                    <MD.TextField
                                        fullWidth={true}
                                        label={this.props.i18n._('Phone number')}
                                        onChange={(e) => {
                                            this.setState({
                                                ...this.state,
                                                reportBugValues: {
                                                    ...this.state.reportBugValues,
                                                    phone: e.target.value,
                                                },
                                            });
                                        }}
                                        value={this.state.reportBugValues.phone}
                                    />
                                </MD.FormControl>
                            </MD.Grid>
                            <MD.Grid item xs={12}>
                                <MD.FormControl className={this.props.classes.bugDialogFormElement} fullWidth={true}>
                                    <MD.TextField
                                        error={this.props.timeline.reportBugErrors.has('message')}
                                        fullWidth={true}
                                        helperText={this.props.timeline.reportBugErrors.get('message') || ''}
                                        rows={6}
                                        multiline
                                        label={this.props.i18n._('Please describe us what happened')}
                                        onChange={(e) => {
                                            this.setState({
                                                ...this.state,
                                                reportBugValues: {
                                                    ...this.state.reportBugValues,
                                                    message: e.target.value,
                                                },
                                            });
                                        }}
                                        value={this.state.reportBugValues.message}
                                    />
                                </MD.FormControl>
                            </MD.Grid>
                        </MD.Grid>
                    </MD.Box>
                </MD.DialogContent>
                <MD.DialogActions>
                    <MD.Button
                        color='primary'
                        onClick={() => {
                            this.props.hideReportBug();
                        }}
                    >
                        {this.props.i18n._('Cancel')}
                    </MD.Button>
                    {this.props.timeline.reportingBug ? (
                        <MD.CircularProgress className={this.props.classes.bugDialogLoading} />
                    ) : (
                        <MD.Button
                            color='secondary'
                            onClick={() => {
                                if (this.state.reportBugValues.email === '') {
                                    return;
                                }
                                TypedStorage.set('lastBugReportedMail', this.state.reportBugValues.email);
                                TypedStorage.set('lastBugReportedName', this.state.reportBugValues.name);
                                TypedStorage.set('lastBugReportedPhone', this.state.reportBugValues.phone);
                                TypedStorage.set('lastBugReportedSociety', this.state.reportBugValues.company);

                                this.props.reportBug({
                                    company: this.state.reportBugValues.company,
                                    mail: this.state.reportBugValues.email,
                                    message: this.state.reportBugValues.message,
                                    name: this.state.reportBugValues.name,
                                    phone: this.state.reportBugValues.phone,
                                });
                            }}
                        >
                            {this.props.i18n._('Report bug')}
                        </MD.Button>
                    )}
                </MD.DialogActions>
            </MD.Dialog>
        );
    }
}
function isEmpty(incidentMessages: import('../../Store/Incident').IIncidentInfo[]) {
    throw new Error('Function not implemented.');
}
