import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';

import { Main } from './Components/Main';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './Store/store';

const history = createBrowserHistory();
const initialState = window.initialReduxState;
const store = configureStore(history, initialState);

const meta = document.createElement('meta');
meta.name = 'google';
meta.content = 'notranslate';
(document.head as HTMLElement).appendChild(meta);

ReactDOM.render(<Main store={store} history={history} />, document.getElementById('root'));

serviceWorker.unregister();
/* eslint-disable @typescript-eslint/no-explicit-any */
(console as any).history = [];
const oldConsole = {};
for (const i in console) {
    if (typeof (console as any)[i] == 'function') {
        (oldConsole as any)[i] = (console as any)[i];
        const strr =
            "(function(){\
            console.history.push({func:'" +
            i +
            "',args : Array.prototype.slice.call(arguments)});\
            console.history = console.history.slice(0, 100);\
            oldConsole['" +
            i +
            "'].apply(console, arguments);\
        })";
        (console as any)[i] = eval(strr);
    }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
