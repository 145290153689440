import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    annotationDialogAddLink: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1) * 3,
        marginTop: theme.spacing(1),
        textAlign: 'right',
    },
    annotationDialogContent: {
        minWidth: 600,
    },
    annotationDialogFormElement: {
        marginTop: theme.spacing(1),
    },
    annotationDialogLoading: {
        height: '20px !important',
        width: '20px !important',
    },
    fieldDescription: {
        fontSize: '2em',
    },
});
