import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    icon: {},
    menuItem: {
        '& $primary, & $icon': {
            color: theme.palette.common.white,
        },
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    primary: {},
    root: {
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        padding: '20px 10px',
    },
    updateIcon: {
        marginRight: theme.spacing(1),
    },
});
