import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';

import { isNull, isNullOrUndefined} from '../../Utils/Various';

export class SearchResults extends AMessage implements IFromCommand {
    private parsedTotal: number | null = null;
    private parsedPage: number | null = null;
    private parsedResults: Proto.mediaarchiver.ISearchResult[] | null = null;
    private parsedStructuredResults: Proto.mediaarchiver.IStructuredSearchResult[] | null = null;

    public constructor() {
        super('searchResults');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg)) {
                return reject(new Error('Empty message'));
            }
            if (isNullOrUndefined(msg.SearchResultsArgs)) {
                return reject(new Error('Not a SearchResultsArgs message'));
            }
            this.parsedTotal = (!isNullOrUndefined(msg.SearchResultsArgs.Total)) ? msg.SearchResultsArgs.Total : 0;
            this.parsedPage = (!isNullOrUndefined(msg.SearchResultsArgs.Page)) ? msg.SearchResultsArgs.Page : 0;
            this.parsedResults = (!isNullOrUndefined(msg.SearchResultsArgs.Results)) ?
                msg.SearchResultsArgs.Results : [];
            this.parsedStructuredResults = (!isNullOrUndefined(msg.SearchResultsArgs.StructuredResults)) ?
                msg.SearchResultsArgs.StructuredResults : [];
            resolve();
        });
    }

    public get total(): number {
        if (isNull(this.parsedTotal)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedTotal;
    }

    public get page(): number {
        if (isNull(this.parsedPage)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedPage;
    }

    public get results(): Proto.mediaarchiver.ISearchResult[] {
        if (isNull(this.parsedResults)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedResults;
    }

    public get structuredResults(): Proto.mediaarchiver.IStructuredSearchResult[] {
        if (isNull(this.parsedStructuredResults)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedStructuredResults;
    }
}
