import { v4 as uuid } from 'uuid';

import { Config } from '../../config';
import * as Proto from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { isNullOrUndefined } from '../../Utils/Various';
import { Backend } from '../Backend';

/**
 * Commands interface.
 */
interface ICommand {
    GetName(): string;
}

/**
 * Sent commands interface.
 */
export interface IToCommand extends ICommand {
    Send(): Promise<void>;
}

/**
 * Incoming commands interface.
 */
export interface IFromCommand extends ICommand {
    Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void>;
}

/**
 * Base class for all commands.
 */
export abstract class AMessage {
    private backend: Backend;
    private messageType: string;

    public constructor(msgType: string) {
        if (msgType === '') {
            throw new Error('Trying to create a command with an empty name');
        }
        this.messageType = msgType;
        this.backend = Backend.getInstance();
    }

    public GetName(): string {
        return this.messageType;
    }

    protected async send(msg: Proto.mediaarchiver.WSMessage, timeout?: number): Promise<void> {
        if (msg.MessageID === '') {
            msg.MessageID = uuid();
        }
        if (isNullOrUndefined(timeout)) {
            timeout = Config.commandsTTL;
        }
        Logger.trace({ id: msg.MessageID, type: this.messageType }, 'Sending command');
        Logger.trace(msg, 'message');
        try {
            await this.backend.Send(msg, this.messageType, timeout);
        } catch (err) {
            throw err;
        }
    }
}
