import * as Proto from '../../Protos/protos';

import { Store } from 'redux';
import { action } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IApplicationState } from '..';
import { IFromCommand } from '../../Backend/Commands';
import { Logger } from '../../Utils/Logger';
import { SearchSpeechesResult } from '../../Backend/Commands/SearchSpeechResult';
import { SearchWordsResult } from '../../Backend/Commands/SearchWordsResult';
import { store } from '../store';
import {
    IClearAction,
    ISearchSpeechesAction,
    ISearchSTTParams,
    ISearchSTTDateParams,
    ISetCurrentend,
    ISetCurrentmediaid,
    ISetCurrentmedianame,
    ISetCurrentsentence,
    ISetCurrentstart,
    ISetCurrenttimestampedwords,
    ISetCurrentwords,
    ISetCurrentworduid,
    ISetRequestedCurrent,
    ISetRequestedEnd,
    ISetRequestedMedia,
    ISetRequestedStart,
    ISetlastSearchSpeechesAction,
    ISetSearchSpeechesLoadingAction,
    ISetSearchSpeechesResultsAction,
    ISetTimelineProgramAction,
    NullableSpeechResult,
    SearchSTTActionTypes,
    ISearchSpeechesDateAction,
    ISetNewwords,
    ISetCurrentbegin,
} from './Types';
import { SearchSpeechesDateResult } from '../../Backend/Commands/SearchSpeechDateResult';

export const clear = (): IClearAction => action(SearchSTTActionTypes.CLEAR);

export const searchSpeeches = (params: ISearchSTTParams): ISearchSpeechesAction =>
    action(SearchSTTActionTypes.SEARCH_SPEECHES, params);
export const searchSpeechesDate = (params: ISearchSTTDateParams): ISearchSpeechesDateAction =>
    action(SearchSTTActionTypes.SEARCH_SPEECHES_DATE, params);

export const setCurrentend = (currentEnd: number): ISetCurrentend =>
    action(SearchSTTActionTypes.SET_CURRENTEND, currentEnd);
export const setCurrentbegin = (currentBegin: number): ISetCurrentbegin =>
    action(SearchSTTActionTypes.SET_CURRENTBEGIN, currentBegin);
export const setCurrentmediaid = (currentMediaID: number): ISetCurrentmediaid =>
    action(SearchSTTActionTypes.SET_CURRENTMEDIAID, currentMediaID);
export const setCurrentmedianame = (currentMediaName: string): ISetCurrentmedianame =>
    action(SearchSTTActionTypes.SET_CURRENTMEDIANAME, currentMediaName);
export const setCurrentsentence = (currentSentence: React.ReactNode[]): ISetCurrentsentence =>
    action(SearchSTTActionTypes.SET_CURRENTSENTENCE, currentSentence);
export const setCurrentstart = (currentStart: number): ISetCurrentstart =>
    action(SearchSTTActionTypes.SET_CURRENTSTART, currentStart);
export const setCurrenttimestampedwords = (currentTimestampedWords: Map<number, string>): ISetCurrenttimestampedwords =>
    action(SearchSTTActionTypes.SET_CURRENTTIMESTAMPEDWORDS, currentTimestampedWords);
export const setCurrentwords = (currentWords: Proto.mediaarchiver.ISearchSpeechesWord[]): ISetCurrentwords =>
    action(SearchSTTActionTypes.SET_CURRENTWORDS, currentWords);
export const setCurrentworduid = (currentWordUID: string): ISetCurrentworduid =>
    action(SearchSTTActionTypes.SET_CURRENTWORDUID, currentWordUID);

export const setRequestedCurrent = (requestedCurrent: Date): ISetRequestedCurrent =>
    action(SearchSTTActionTypes.SET_REQUESTED_CURRENTTIME, requestedCurrent);
export const setRequestedEnd = (requestedEnd: Date): ISetRequestedEnd =>
    action(SearchSTTActionTypes.SET_REQUESTED_END, requestedEnd);
export const setRequestedMedia = (requestedMedia: number): ISetRequestedMedia =>
    action(SearchSTTActionTypes.SET_REQUESTED_MEDIA, requestedMedia);
export const setRequestedStart = (requestedStart: Date): ISetRequestedStart =>
    action(SearchSTTActionTypes.SET_REQUESTED_START, requestedStart);

export const setLastSearchSpeeches = (lastSearch: ISearchSTTParams | null): ISetlastSearchSpeechesAction =>
    action(SearchSTTActionTypes.SET_LAST_SEARCH_SPEECHES, lastSearch);

export const setNewwords = (newWords: Proto.mediaarchiver.ISearchSpeechesWord[]): ISetNewwords =>
    action(SearchSTTActionTypes.SET_NEWWORDS, newWords);

export const setSearchSpeechesLoading = (loading: boolean): ISetSearchSpeechesLoadingAction =>
    action(SearchSTTActionTypes.SET_SEARCH_SPEECHES_LOADING, loading);
export const setSearchSpeechesResults = (
    results: NullableSpeechResult[],
    page: number,
): ISetSearchSpeechesResultsAction => action(SearchSTTActionTypes.SET_SEARCH_SPEECHES_RESULTS, [results, page]);

export const setTimelineProgram = (prg: NullableSpeechResult): ISetTimelineProgramAction =>
    action(SearchSTTActionTypes.SET_TIMELINE_PROGRAM, prg);

Backend.getInstance()
    .Bind('searchSpeechesResult', (incoming: IFromCommand) => {
        const cmd = incoming as SearchSpeechesResult;
        const str = store as Store;
        const state = str.getState() as IApplicationState;

        try {
            Logger.debug(
                {
                    results: cmd.results,
                    total: cmd.total,
                },
                'Received STT search results',
            );
            str.dispatch(setSearchSpeechesResults(cmd.results, cmd.total));
        } catch (err) {
            Logger.warn(err as string, 'Unable to parse search STT result');
        }
    })
    .Bind('searchSpeechesDateResult', (incoming: IFromCommand) => {
        const cmd = incoming as SearchSpeechesDateResult;
        const str = store as Store;
        const state = str.getState() as IApplicationState;

        try {
            Logger.debug(
                {
                    results: cmd.results,
                },
                'Received Date search results',
            );
            window.setTimeout(()=>{str.dispatch(setNewwords(cmd.results));},50);
        } catch (err) {
            Logger.warn(err as string, 'Unable to parse search words result');
        }
    });
