import { Reducer, Store } from 'redux';
import { action } from 'typesafe-actions';

import { IMediasState, MediasActionTypes } from './Types';
import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { Medias } from '../../Backend/Commands/Medias';
import { Panels } from '../../Backend/Commands/Panels';
import { SearchKindsResult } from '../../Backend/Commands/SearchKindsResult';
import { TalkMedias } from '../../Backend/Commands/TalkMedias';
import { Logger } from '../../Utils/Logger';
import { isUndefined } from '../../Utils/Various';
import { store } from '../store';
import { SttMedias } from '../../Backend/Commands/SttMedias';
import { BackgroundMedias } from '../../Backend/Commands/BackgroundMedias';
import { CrossBucketMedias } from '../../Backend/Commands/CrossBucketMedias';

const initialState: IMediasState = {
    backgroundMedias: [],
    crossbucketMedias: [],
    data: [],
    errors: undefined,
    kindsResults: [],
    loading: false,
    panels: {
        radios: [],
        tvs: [],
    },
    sttMedias: [],
    talkMedias: [],
};

const reducer: Reducer<IMediasState> = (state = initialState, action) => {
    switch (action.type) {
        case MediasActionTypes.DO_UPDATE: {
            return { ...state, data: action.payload, loading: false };
        }

        case MediasActionTypes.SEARCH_KINDS_RESET: {
            return { ...state, kindsResults: [] };
        }

        case MediasActionTypes.SET_TALK_MEDIAS: {
            return { ...state, talkMedias: action.payload };
        }

        case MediasActionTypes.SET_BACKGROUND_MEDIAS: {
            return { ...state, backgroundMedias: action.payload };
        }
        case MediasActionTypes.SET_CROSSBUCKET_MEDIAS: {
            return { ...state, crossbucketMedias: action.payload };
        }

        case MediasActionTypes.SET_STT_MEDIAS: {
            return { ...state, sttMedias: action.payload };
        }

        case MediasActionTypes.UPDATE: {
            return { ...state, loading: true };
        }

        case MediasActionTypes.UPDATE_PANELS: {
            return { ...state, panels: { radios: action.payload[0], tvs: action.payload[1] } };
        }

        case MediasActionTypes.UPDATE_SEARCH_KINDS_RESULTS: {
            return { ...state, kindsResults: action.payload };
        }

        case MediasActionTypes.UPDATE_STT: {
            return { ...state, loading: true };
        }

        default: {
            return state;
        }
    }
};

Backend.getInstance()
    .Bind('medias', (incoming: IFromCommand) => {
        const cmd = incoming as Medias;

        if (isUndefined(cmd.getMedias)) {
            return;
        }
        Logger.debug({ medias: cmd.getMedias() }, 'Received media list');
        (store as Store).dispatch(action(MediasActionTypes.DO_UPDATE, cmd.getMedias()));
    })
    .Bind('panels', (incoming: IFromCommand) => {
        const cmd = incoming as Panels;

        Logger.debug({ radios: cmd.radios, tvs: cmd.tvs }, 'Received media panels list');
        (store as Store).dispatch(action(MediasActionTypes.UPDATE_PANELS, [cmd.radios, cmd.tvs]));
    })
    .Bind('searchKindsResult', (incoming: IFromCommand) => {
        const cmd = incoming as SearchKindsResult;

        Logger.debug({ results: cmd.results }, 'Received search kind result');
        (store as Store).dispatch(action(MediasActionTypes.UPDATE_SEARCH_KINDS_RESULTS, cmd.results));
    })
    .Bind('backgroundMedias', (incoming: IFromCommand) => {
        const cmd = incoming as BackgroundMedias;

        Logger.debug({ results: cmd.medias }, 'Received background medias');
        (store as Store).dispatch(action(MediasActionTypes.SET_BACKGROUND_MEDIAS, cmd.medias));
    })
    .Bind('crossbucketMedias', (incoming: IFromCommand) => {
        const cmd = incoming as CrossBucketMedias;

        Logger.debug({ results: cmd.medias }, 'Received crossbucket medias');
        (store as Store).dispatch(action(MediasActionTypes.SET_CROSSBUCKET_MEDIAS, cmd.medias));
    })
    .Bind('talkMedias', (incoming: IFromCommand) => {
        const cmd = incoming as TalkMedias;

        Logger.debug({ results: cmd.medias }, 'Received talk medias');
        (store as Store).dispatch(action(MediasActionTypes.SET_TALK_MEDIAS, cmd.medias));
    })
    .Bind('sttMedias', (incoming: IFromCommand) => {
        const cmd = incoming as SttMedias;

        Logger.debug({ results: cmd.medias }, 'Received stt medias');
        (store as Store).dispatch(action(MediasActionTypes.SET_TALK_MEDIAS, cmd.medias));
    });

export { reducer as MediasReducer };
