import { Store } from 'redux';
import { all, AllEffect, call, fork, takeLatest } from 'redux-saga/effects';

import { IApplicationState } from '..';
import { IToCommand } from '../../Backend/Commands';
import { CreateReview } from '../../Backend/Commands/CreateReview';
import { DeleteReview } from '../../Backend/Commands/DeleteReview';
import { EditReview } from '../../Backend/Commands/EditReview';
import { GetReview } from '../../Backend/Commands/GetReview';
import { GetReviews } from '../../Backend/Commands/GetReviews';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import { create, get, getById, remove, setErrors, setOpSuccess, setProgress } from './Actions';
import { ReviewsActionTypes } from './Types';

function* handleGet(action: ReturnType<typeof get>) {
    const cmd = new GetReviews(action.payload);

    try {
        yield call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get reviews');
    }
}

function* handleOps(actionType: string, action: ReturnType<typeof create>) {
    const str = store as Store<IApplicationState>;
    let command: IToCommand;

    switch (actionType) {
        case 'create':
            command = new CreateReview(action.payload);
            break;
        case 'edit':
            command = new EditReview(action.payload);
            break;
        default:
            throw new Error('Unknown ops action type');
    }
    str.dispatch(setProgress(true));
    try {
        yield call(command.Send.bind(command));
        yield call(str.dispatch.bind(str, setOpSuccess()));
    } catch (err) {
        try {
            const data = JSON.parse((err as Error).message);

            yield call(
                str.dispatch.bind(
                    str,
                    setErrors({
                        general: data.general ? str.getState().i18n.i18n._(data.general) : '',
                        name: data.lastName ? str.getState().i18n.i18n._(data.lastName) : '',
                    }),
                ),
            );
        } catch (err2) {
            Logger.warn({ original: err, parsing: err2 }, 'Unable to parse error message');
            yield call(
                str.dispatch.bind(
                    str,
                    setErrors({
                        general: 'N/A',
                        name: '',
                    }),
                ),
            );
        }
        Logger.warn(err as Error, 'Failed to create/update review');
    }
    yield call(str.dispatch.bind(str, setProgress(false)));
}

function* handleGetById(action: ReturnType<typeof getById>) {
    const cmd = new GetReview(action.payload);

    try {
        yield call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get a review by ID');
    }
}

function* handleDelete(action: ReturnType<typeof remove>) {
    const cmd = new DeleteReview(action.payload);

    try {
        yield call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to delete a review');
    }
}

function* watchCreate() {
    yield takeLatest(ReviewsActionTypes.CREATE, handleOps.bind(null, 'create'));
}

function* watchGet() {
    yield takeLatest(ReviewsActionTypes.GET, handleGet);
}

function* watchGetById() {
    yield takeLatest(ReviewsActionTypes.GET_BY_ID, handleGetById);
}

function* watchEdit() {
    yield takeLatest(ReviewsActionTypes.EDIT, handleOps.bind(null, 'edit'));
}

function* watchDelete() {
    yield takeLatest(ReviewsActionTypes.DELETE, handleDelete);
}

export function* ReviewsSaga(): Generator<AllEffect, void, unknown> {
    yield all([fork(watchCreate), fork(watchGet), fork(watchGetById), fork(watchEdit), fork(watchDelete)]);
}
