import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Proto from '../../../Protos/protos';
import { IApplicationState } from '../../../Store';
import { setPageTitle, setSnackMessage } from '../../../Store/Layout';
import {
    create,
    deleteAccount,
    edit,
    get,
    getCount,
} from '../../../Store/SFTPAccounts';
import AccountSFTPAccountsComponent from './SFTPAccounts';
import styles from './style';

const mapStateToProps = (
    { sftpAccounts, i18n }: IApplicationState,
) => ({
    accounts: sftpAccounts,
    i18n: i18n.i18n,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    create: (account: Proto.mediaarchiver.ISFTPAccount) => dispatch(create(account)),
    delete: (id: string) => dispatch(deleteAccount(id)),
    edit: (account: Proto.mediaarchiver.ISFTPAccount) => dispatch(edit(account)),
    get: (offset = 0, limit = 100) => dispatch(get(offset, limit)),
    getCount: () => dispatch(getCount()),
    setPageTitle: (title: string) => dispatch(setPageTitle(title)),
    setSnackMessage: (msg: string) => dispatch(setSnackMessage(msg)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(AccountSFTPAccountsComponent));
