import { Store } from 'redux';
import { effects } from 'redux-saga';

import { IApplicationState } from '..';
import { Login } from '../../Backend/Commands/Login';
import { Logout } from '../../Backend/Commands/Logout';
import { SetAccountOptions } from '../../Backend/Commands/SetAccountOptions';
import { Logger } from '../../Utils/Logger';
import { TypedStorage } from '../../Utils/TypedStorage';
import { setSnackMessage } from '../Layout/Actions';
import { store } from '../store';
import { login, loginError, setUserOptions, storeUserOptions } from './Actions';
import { UserActionTypes } from './Types';

function* handleLogin(action: ReturnType<typeof login>) {
    const cmd = new Login(action.payload.login, action.payload.password, action.payload.remember);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        if (err instanceof Error) {
            yield effects.put(loginError(err));
        } else if (typeof err === 'string') {
            yield effects.put(loginError(new Error(err)));
        } else {
            yield effects.put(loginError(new Error('An unknown error occured.')));
        }
    }
}

function* handleLogout() {
    const cmd = new Logout();

    try {
        yield effects.call(cmd.Send.bind(cmd));
        TypedStorage.del('expiringToken');
        TypedStorage.del('expiringTokenExpires');
        TypedStorage.del('incidentFirstTimeDisplayed');
    } catch (err) {
        if (!(err instanceof Error)) {
            err = new Error(err as string);
        }
        Logger.warn({ error: err }, 'Cannot log out');
    }
}

function* setOptions(action: ReturnType<typeof setUserOptions>) {
    const str = store as Store<IApplicationState>;
    const before = [...str.getState().user.user.options];
    const cmd = new SetAccountOptions(action.payload);

    try {
        yield effects.put(storeUserOptions(action.payload));
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        if (!(err instanceof Error)) {
            err = new Error(err as string);
        }
        Logger.warn({ error: err }, 'Cannot set user options');
        yield effects.put(storeUserOptions(before));
        yield effects.put(setSnackMessage(str.getState().i18n.i18n._('Changing option failed')));
    }
}

function* watchLoginRequest() {
    yield effects.takeEvery(UserActionTypes.LOGIN, handleLogin);
}

function* watchLogoutRequest() {
    yield effects.takeEvery(UserActionTypes.LOGOUT, handleLogout);
}

function* watchOptionsRequest() {
    yield effects.takeEvery(UserActionTypes.SET_USER_OPTIONS, setOptions);
}

export function* UserSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchLoginRequest)]);
    yield effects.all([effects.fork(watchLogoutRequest)]);
    yield effects.all([effects.fork(watchOptionsRequest)]);
}
