import { Reducer } from 'redux';

import { TypedStorage } from '../../Utils/TypedStorage';
import { AnalyticsActionTypes, IAnalyticsState } from './Types';

const initialState: IAnalyticsState = {
    okBug20220524: TypedStorage.get('bug20220524', true),
    shouldAskConsent: false,
};

const reducer: Reducer<IAnalyticsState> = (state = initialState, action) => {
    switch (action.type) {
        case AnalyticsActionTypes.OK_BUG20220524: {
            TypedStorage.set('bug20220524', true);
            return { ...state, okBug20220524: true };
        }

        case AnalyticsActionTypes.SET_SHOULD_ASK: {
            return { ...state, shouldAskConsent: action.payload };
        }

        default: {
            return state;
        }
    }
};

export { reducer as AnalyticsReducer };
