import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState } from '../../../Store';
import {
    create as createArchive,
    get as getArchives,
    hideDialog,
} from '../../../Store/Archives';
import { create as createContact, get as getContacts } from '../../../Store/Contacts';
import { get as getFTPAccounts } from '../../../Store/FTPAccounts';
import { setSnackMessage } from '../../../Store/Layout';
import { setEndSelection, setStartSelection } from '../../../Store/Player';
import { create as createReview, get as getReviews } from '../../../Store/Reviews';
import { get as getSFTPAccounts } from '../../../Store/SFTPAccounts';
import ExtractionDialogComponent from './ExtractionDialog';

import styles from './styles';

const mapStateToProps = ({
    archives,
    contacts,
    ftpAccounts,
    i18n,
    player,
    reviews,
    sftpAccounts,
    timeline,
    user,
}: IApplicationState) => ({
    archives,
    contacts,
    ftpAccounts,
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    player,
    reviews,
    sftpAccounts,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createArchive: (s: mediaarchiver.IArchive) => dispatch(createArchive(s)),
    createContact: (c: mediaarchiver.IContact) => dispatch(createContact(c)),
    createReview: (r: mediaarchiver.IReview) => dispatch(createReview(r)),
    getArchives: (s: mediaarchiver.IArgumentsGetArchives) => dispatch(getArchives(s)),
    getContacts: () => dispatch(getContacts()),
    getFTPAccounts: () => dispatch(getFTPAccounts()),
    getReviews: (s: mediaarchiver.IArgumentsGetReviews) => dispatch(getReviews(s)),
    getSFTPAccounts: () => dispatch(getSFTPAccounts()),
    hideDialog: () => dispatch(hideDialog()),
    setEndSelection: (d: Date | null) => dispatch(setEndSelection(d)),
    setSnackMessage: (msg: string) => dispatch(setSnackMessage(msg)),
    setStartSelection: (d: Date | null) => dispatch(setStartSelection(d)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(ExtractionDialogComponent),
);
