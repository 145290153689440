import { Talks } from '../../Backend/Commands/Talks';
import { mediaarchiver } from '../../Protos/protos';

export enum TalkActionTypes {
    ADD_PERSON = '@@talk/ADD_PERSON',
    ADD_TALK = '@@talk/ADD_TALK',
    CLEAR_DISTRICTS = '@@talk/CLEAR_DISTRICTS',
    CLEAR_RESULTS = '@@talk/CLEAR_RESULTS',
    CLEAR_SUGGS = '@@talk/CLEAR_SUGGS',
    DELETE_TALK = '@@talk/DELETE_TALK',
    DELETE_MULTIPLE = '@@talk/DELETE_MULTIPLE',
    EXPORT = '@@talk/EXPORT',
    EDIT_TALK = '@@talk/EDIT_TALK',
    GET_ALL_VALUES = '@@talk/GET_ALL_VALUES',
    GET_DISTRICTS = '@@talk/GET_DISTRICTS',
    GET_PERSON = '@@talk/GET_PERSON',
    GET_PROGRAM_TYPES = '@@talk/GET_PROGRAM_TYPES',
    GET_STUDIES = '@@talk/GET_STUDIES',
    GET_SUGG = '@@talk/GET_SUGG',
    HIDE_COPY_DIALOG = '@@talk/HIDE_COPY_DIALOG',
    HIDE_DIALOG = '@@talk/HIDE_DIALOG',
    SEARCH = '@@talk/SEARCH',
    SET_ALL_GROUPS = '@@talk/SET_ALL_GROUPS',
    SET_ALL_REGIMS = '@@talk/SET_ALL_REGIMS',
    SET_ALL_TITLES = '@@talk/SET_ALL_TITLES',
    SET_COPIED_TALKS = '@@talk/SET_COPIED_TALKS',
    SET_DISTRICTS = '@@talk/SET_DISTRICTS',
    SET_LAST_PERSON = '@@talk/SET_LAST_PERSON',
    SET_LAST_SEARCH = '@@talk/SET_LAST_SEARCH',
    SET_LOADING = '@@talk/SET_TALK_LOADING',
    SET_PERSON_LOADING = '@@talk/SET_PERSON_LOADING',
    SET_PROGRAM_TYPES = '@@talk/SET_PROGRAM_TYPES',
    SET_SEARCH_LOADING = '@@talk/SET_SEARCH_LOADING',
    SET_SEARCH_RESULTS = '@@talk/SET_SEARCH_RESULTS',
    SET_STUDIES = '@@talk/SET_STUDIES',
    SET_SUGGS = '@@talk/SET_SUGGS',
    SET_SUGG_LOADING = '@@talk/SET_SUGG_LOADING',
    SET_TIMELINE_TALK = '@@talk/SET_TIMELINE_TALK',
    SHOW_AGGREGATED_DIALOG = '@@talk/SHOW_AGGREGATED_DIALOG',
    SHOW_COPY_DIALOG = '@@talk/SHOW_COPY_DIALOG',
    SHOW_DIALOG = '@@talk/SHOW_DIALOG',
    SHOW_EDIT_DIALOG = '@@talk/SHOW_EDIT_DIALOG',
}

export interface ITalkState {
    readonly aggregatedTalk?: mediaarchiver.ITalk;
    readonly allGroups: mediaarchiver.ITalkGroup[];
    readonly allRegims: mediaarchiver.ITalkRegim[];
    readonly allTitles: mediaarchiver.ITalkTitle[];
    readonly copiedTalks: mediaarchiver.ITalk[];
    readonly districts: mediaarchiver.ITalkDistrict[];
    readonly editedTalk?: mediaarchiver.Talk;
    readonly lastPerson: ITalkLastPerson | null;
    readonly lastSearch: mediaarchiver.IArgumentsGetTalks | null;
    readonly loading: boolean;
    readonly personLoading: boolean;
    readonly personSuggestions: mediaarchiver.ITalkPersonSugg[];
    readonly persons: mediaarchiver.ITalkPerson[];
    readonly programTypes: mediaarchiver.ITalkProgramType[];
    readonly searchLoading: boolean;
    readonly searchResults: mediaarchiver.ITalk[];
    readonly searchResultsCount: number;
    readonly showCopyDialog: boolean;
    readonly showDialog: boolean;
    readonly studies: mediaarchiver.ITalkStudy[];
    readonly suggLoading: boolean;
    readonly timelineTalk: mediaarchiver.ITalk | null;
}

export interface ITalkLastPerson {
    readonly group: number;
    readonly groupColor: string;
    readonly personID: number;
    readonly personName: string;
    readonly regim: number;
    readonly situation: number;
    readonly study: number;
    readonly talkType: mediaarchiver.TalkTalkType;
    readonly timeType: mediaarchiver.TalkTimeType;
    readonly title: string;
}

export interface IAddTalkAction {
    payload: mediaarchiver.ITalk;
    type: TalkActionTypes.ADD_TALK;
}

export interface IClearDistrictsAction {
    type: TalkActionTypes.CLEAR_DISTRICTS;
}

export interface IClearResultsAction {
    type: TalkActionTypes.CLEAR_RESULTS;
}

export interface IClearSuggsAction {
    type: TalkActionTypes.CLEAR_SUGGS;
}

export interface IDeleteMultipleAction {
    payload: string[];
    type: TalkActionTypes.DELETE_MULTIPLE;
}

export interface IDeleteTalkAction {
    payload: string;
    type: TalkActionTypes.DELETE_TALK;
}

export interface IEditTalkAction {
    payload: mediaarchiver.ITalk;
    type: TalkActionTypes.EDIT_TALK;
}

export interface IExportAnnotationsAction {
    payload: mediaarchiver.IArgumentsExportTalks;
    type: TalkActionTypes.EXPORT;
}

export interface IGetAllValuesAction {
    type: TalkActionTypes.GET_ALL_VALUES;
}

export interface IGetDistrictsAction {
    payload: mediaarchiver.ITalkGetDistricts;
    type: TalkActionTypes.GET_DISTRICTS;
}

export interface IGetPersonAction {
    payload: string;
    type: TalkActionTypes.GET_PERSON;
}

export interface IGetProgramTypesAction {
    type: TalkActionTypes.GET_PROGRAM_TYPES;
}

export interface IGetStudiesAction {
    type: TalkActionTypes.GET_STUDIES;
}

export interface IGetSuggAction {
    payload: [string, number];
    type: TalkActionTypes.GET_SUGG;
}

export interface IHideCopyDialogAction {
    type: TalkActionTypes.HIDE_COPY_DIALOG;
}

export interface IHideDialogAction {
    type: TalkActionTypes.HIDE_DIALOG;
}

export interface ISearchAction {
    payload: mediaarchiver.IArgumentsGetTalks;
    type: TalkActionTypes.SEARCH;
}

export interface ISetCopiedTalksAction {
    payload: mediaarchiver.ITalk[];
    type: TalkActionTypes.SET_COPIED_TALKS;
}

export interface ISetDistrictsAction {
    payload: mediaarchiver.ITalkDistrict[];
    type: TalkActionTypes.SET_DISTRICTS;
}

export interface ISetLastPersonAction {
    payload: ITalkLastPerson;
    type: TalkActionTypes.SET_LAST_PERSON;
}

export interface ISetLastSearchAction {
    payload: mediaarchiver.IArgumentsGetTalks;
    type: TalkActionTypes.SET_LAST_SEARCH;
}

export interface ISetLoadingAction {
    payload: boolean;
    type: TalkActionTypes.SET_LOADING;
}

export interface ISetPersonLoadingAction {
    payload: boolean;
    type: TalkActionTypes.SET_PERSON_LOADING;
}

export interface ISetProgramTypesAction {
    payload: mediaarchiver.ITalkProgramType[];
    type: TalkActionTypes.SET_PROGRAM_TYPES;
}

export interface ISetSearchLoadingAction {
    payload: boolean;
    type: TalkActionTypes.SET_SEARCH_LOADING;
}

export interface ISetSearchresultsAction {
    payload: Talks;
    type: TalkActionTypes.SET_SEARCH_RESULTS;
}

export interface ISetStudiesAction {
    payload: mediaarchiver.ITalkStudy[];
    type: TalkActionTypes.SET_STUDIES;
}

export interface ISetSuggLoadingAction {
    payload: boolean;
    type: TalkActionTypes.SET_SUGG_LOADING;
}

export interface ISetSuggsAction {
    payload: mediaarchiver.ITalkPersonSugg[];
    type: TalkActionTypes.SET_SUGGS;
}

export interface ISetTimelineTalkAction {
    payload: mediaarchiver.ITalk | null;
    type: TalkActionTypes.SET_TIMELINE_TALK;
}

export interface IShowAggregatedDialogAction {
    payload: mediaarchiver.ITalk;
    type: TalkActionTypes.SHOW_AGGREGATED_DIALOG;
}

export interface IShowCopyDialogAction {
    type: TalkActionTypes.SHOW_COPY_DIALOG;
}

export interface IShowDialogAction {
    type: TalkActionTypes.SHOW_DIALOG;
}

export interface IShowEditDialogAction {
    payload: mediaarchiver.ITalk;
    type: TalkActionTypes.SHOW_EDIT_DIALOG;
}
