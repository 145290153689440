import { createStyles } from '@material-ui/core';
import { getTheme } from '../../Themes';
const theme = getTheme();

export default createStyles({
    commentTooltip: {
        whiteSpace: 'pre-wrap',
    },
    footer: {
        '& button': {
            minWidth: 35,
        },

        alignItems: 'center',
        display: 'flex',
        maxHeight: 40,
        minHeight: 40,
        overflow: 'hidden',
    },
    footerCount: {},
    footerLeft: {
        marginLeft: theme.spacing(1),
        marginRight: 'auto',
    },
    footerPage: {
        display: 'inline',
        marginLeft: 15,
        marginRight: 15,
    },
    footerRight: {
        marginRight: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexFlow: 'row',
        flexWrap: 'wrap',
    },
    formContainer: {
        maxHeight: 65,
        minHeight: 65,
    },
    formControl: {
        margin: theme.spacing(1),
        maxWidth: 280,
        minWidth: 120,
    },
    formControlInput: {
        flex: 1,
        margin: theme.spacing(1),
        maxWidth: 400,
        minWidth: 150,
    },
    formPaper: {
        height: '100%',
    },
    playerCard: {
        maxWidth: 800,
    },
    playerDefinitions: {
        '&>dd': {
            '&>span': {
                display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'pre',
                width: '100%',
            },

            marginLeft: 0,
            marginRight: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        '&>dt': {
            '&::after': {
                content: '" :"',
                textDecoration: 'none',
            },
            '&>span': {
                textDecoration: 'underline',
            },

            clear: 'left',
            float: 'left',
            fontWeight: 600,
            marginRight: theme.spacing(1),
            minWidth: 90,
        },

        margin: 0,
    },
    playerMedia: {
        maxHeight: 600,
    },
    resultButton: {},
    resultChannel: {
        color: '#7cbff9',
        display: 'inline',
        fontWeight: 400,
        marginRight: 10,
    },
    resultDate: {
        color: '#e1f995',
        display: 'inline',
        fontWeight: 300,
        marginRight: 10,
    },
    resultDesc: {
        fontWeight: 300,
    },
    resultDuration: {
        color: '#ff7c72',
        fontWeight: 300,
    },
    resultGenre: {
        fontWeight: 400,
    },
    resultIcons: {
        '& svg': {
            marginRight: 5,
        },

        marginTop: 20,
    },
    resultImage: {
        maxHeight: 70,
    },
    resultItem: {
        borderColor: theme.palette.divider,
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        minHeight: '150px',
        overflow: 'hidden',
    },
    resultPersons: {
        color: '#f86462',
    },
    results: {
        maxHeight: 'calc(100% - 40px)',
        minHeight: 'calc(100% - 40px)',
        overflowY: 'auto',
    },
    resultsContainer: {
        marginTop: theme.spacing(1),
        maxHeight: `calc(100% - 65px - ${theme.spacing(1)}px)`,
        minHeight: `calc(100% - 65px - ${theme.spacing(1)}px)`,
    },
    root: {
        flexGrow: 1,
        height: '100%',
        padding: theme.spacing(1),
    },
    searchButton: {
        marginTop: 6,
    },
    shareError: {
        color: theme.palette.error.main,
    },
    shareFormControl: {
        display: 'block',
    },
});
