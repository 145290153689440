import { AMessage, IToCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';

export class GetArchive extends AMessage implements IToCommand {
    private readonly id: string;

    public constructor(id: string) {
        super('getArchive');
        this.id = id;
    }

    public Send(): Promise<void> {
        return this.send(
            new mediaarchiver.WSMessage({
                GetArchiveArg: this.id,
                Type: mediaarchiver.WSMessageType.MessageTypeGetArchive,
            }),
        );
    }
}
