import * as Proto from '../../Protos/protos';
import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../Utils/Various';
import { AMessage, IFromCommand } from '.';

export class ExpiringToken extends AMessage implements IFromCommand {
    private _token: string | null = null;
    private _expires: number | null = null;

    public constructor() {
        super('expiringToken');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        if (isNullOrUndefined(msg.ExpiringTokenArg)) {
            return Promise.reject(new Error('Not an ExpiringToken message'));
        }
        if (
            isNullOrUndefinedOrEmptyString(msg.ExpiringTokenArg.Token) ||
            isNullOrUndefinedOrZero(msg.ExpiringTokenArg.Expires)
        ) {
            return Promise.reject(new Error('Empty ExpiringToken message'));
        }
        this._token = msg.ExpiringTokenArg.Token;
        this._expires = msg.ExpiringTokenArg.Expires as number;
        return Promise.resolve();
    }

    public get token(): string {
        if (isNull(this._token)) {
            throw new Error('Command is not parsed yet');
        }
        return this._token;
    }

    public get expires(): number {
        if (isNull(this._expires)) {
            throw new Error('Command is not parsed yet');
        }
        return this._expires;
    }
}
