import { Reducer, Store } from 'redux';
import { action as newAction } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { TalkAutocompleteNameSuggs } from '../../Backend/Commands/TalkAutocompleteNameSuggs';
import { TalkPerson } from '../../Backend/Commands/TalkPerson';
import { Talks } from '../../Backend/Commands/Talks';
import { TalkAllGroups } from '../../Backend/Commands/TalkAllGroups';
import { TalkAllRegims } from '../../Backend/Commands/TalkAllRegims';
import { TalkAllTitles } from '../../Backend/Commands/TalkAllTitles';
import { TalkDistricts } from '../../Backend/Commands/TalkDistricts';
import { TalkExportedTalks } from '../../Backend/Commands/TalkExportedTalks';
import { TalkStudies } from '../../Backend/Commands/TalkStudies';
import { TalkProgramTypes } from '../../Backend/Commands/TalkProgramTypes';
import { mediaarchiver } from '../../Protos/protos';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../Utils/Various';
import { store } from '../store';
import { TalkActionTypes, ITalkState } from './Types';

const initialState: ITalkState = {
    allGroups: [],
    allRegims: [],
    allTitles: [],
    copiedTalks: [],
    districts: [],
    lastPerson: null,
    lastSearch: null,
    loading: false,
    personLoading: false,
    personSuggestions: [],
    persons: [],
    programTypes: [],
    searchLoading: false,
    searchResults: [],
    searchResultsCount: 0,
    showCopyDialog: false,
    showDialog: false,
    studies: [],
    suggLoading: false,
    timelineTalk: null,
};

const reducer: Reducer<ITalkState> = (state = initialState, action) => {
    switch (action.type) {
        case TalkActionTypes.ADD_PERSON: {
            const persons = state.persons;
            const toAdd = action.payload as mediaarchiver.ITalkPerson;

            if (
                !state.persons.some((p: mediaarchiver.ITalkPerson) => {
                    return (
                        !isNullOrUndefinedOrEmptyString(p.ID) &&
                        !isNullOrUndefinedOrEmptyString(toAdd.ID) &&
                        toAdd.ID === p.ID
                    );
                })
            ) {
                persons.push(toAdd);
            }
            return {
                ...state,
                persons,
            };
        }

        case TalkActionTypes.CLEAR_DISTRICTS: {
            return {
                ...state,
                districts: [],
            };
        }

        case TalkActionTypes.CLEAR_RESULTS: {
            return {
                ...state,
                lastSearch: null,
                searchLoading: false,
                searchResults: [],
            };
        }

        case TalkActionTypes.CLEAR_SUGGS: {
            return {
                ...state,
                personSuggestions: [],
            };
        }

        case TalkActionTypes.HIDE_COPY_DIALOG: {
            if (!isNullOrUndefined(document.activeElement) && 'blur' in document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
            return {
                ...state,
                showCopyDialog: false,
            };
        }

        case TalkActionTypes.HIDE_DIALOG: {
            if (!isNullOrUndefined(document.activeElement) && 'blur' in document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
            return {
                ...state,
                showDialog: false,
            };
        }

        case TalkActionTypes.SET_ALL_GROUPS: {
            return {
                ...state,
                allGroups: action.payload,
            };
        }

        case TalkActionTypes.SET_ALL_REGIMS: {
            return {
                ...state,
                allRegims: action.payload,
            };
        }

        case TalkActionTypes.SET_ALL_TITLES: {
            return {
                ...state,
                allTitles: action.payload,
            };
        }

        case TalkActionTypes.SET_COPIED_TALKS: {
            return {
                ...state,
                copiedTalks: action.payload,
            };
        }

        case TalkActionTypes.SET_DISTRICTS: {
            return {
                ...state,
                districts: action.payload,
            };
        }

        case TalkActionTypes.SET_LAST_PERSON: {
            return {
                ...state,
                lastPerson: action.payload,
            };
        }

        case TalkActionTypes.SET_LAST_SEARCH: {
            return {
                ...state,
                lastSearch: action.payload,
            };
        }

        case TalkActionTypes.SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }

        case TalkActionTypes.SET_PERSON_LOADING: {
            return {
                ...state,
                personLoading: action.payload,
            };
        }

        case TalkActionTypes.SET_PROGRAM_TYPES: {
            return {
                ...state,
                programTypes: action.payload,
            };
        }

        case TalkActionTypes.SET_SEARCH_LOADING: {
            return {
                ...state,
                searchLoading: action.payload,
            };
        }

        case TalkActionTypes.SET_SEARCH_RESULTS: {
            const cmd: Talks = action.payload;
            const talks = cmd.talks;
            const searchResults = state.searchResults;

            if (cmd.offset >= cmd.count) {
                return { ...state, searchLoading: false };
            }
            if (isNullOrUndefined(talks) || cmd.count === 0) {
                return { ...state, searchLoading: false, searchResults: [], searchResultsCount: 0 };
            }
            if (cmd.offset > talks.length) {
                for (let i = talks.length; i < cmd.offset; i++) {
                    searchResults[i] = {};
                }
            }
            for (let i = 0; i < talks.length; i++) {
                searchResults[i + cmd.offset] = talks[i];
            }
            return { ...state, searchLoading: false, searchResults, searchResultsCount: cmd.count };
        }

        case TalkActionTypes.SET_SUGG_LOADING: {
            return {
                ...state,
                suggLoading: action.payload,
            };
        }

        case TalkActionTypes.SET_STUDIES: {
            return {
                ...state,
                studies: action.payload,
            };
        }

        case TalkActionTypes.SET_SUGGS: {
            return {
                ...state,
                personSuggestions: action.payload,
            };
        }

        case TalkActionTypes.SET_TIMELINE_TALK: {
            return {
                ...state,
                timelineTalk: action.payload,
            };
        }

        case TalkActionTypes.SHOW_AGGREGATED_DIALOG: {
            if (!isNullOrUndefined(document.activeElement) && 'blur' in document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
            return {
                ...state,
                aggregatedTalk: action.payload,
                editedTalk: undefined,
                showDialog: true,
            };
        }

        case TalkActionTypes.SHOW_COPY_DIALOG: {
            if (!isNullOrUndefined(document.activeElement) && 'blur' in document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
            return {
                ...state,
                showCopyDialog: true,
            };
        }

        case TalkActionTypes.SHOW_DIALOG: {
            if (!isNullOrUndefined(document.activeElement) && 'blur' in document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
            return {
                ...state,
                aggregatedTalk: undefined,
                editedTalk: undefined,
                showDialog: true,
            };
        }

        case TalkActionTypes.SHOW_EDIT_DIALOG: {
            if (!isNullOrUndefined(document.activeElement) && 'blur' in document.activeElement) {
                (document.activeElement as HTMLElement).blur();
            }
            return {
                ...state,
                aggregatedTalk: undefined,
                editedTalk: action.payload,
                showDialog: true,
            };
        }

        default: {
            return state;
        }
    }
};

Backend.getInstance()
    .Bind('talkAutocompleteNameSuggs', (incoming: IFromCommand) => {
        const cmd = incoming as TalkAutocompleteNameSuggs;

        (store as Store).dispatch(newAction(TalkActionTypes.SET_SUGGS, cmd.suggs));
    })
    .Bind('talkPerson', (incoming: IFromCommand) => {
        const cmd = incoming as TalkPerson;

        (store as Store).dispatch(newAction(TalkActionTypes.ADD_PERSON, cmd.person));
    })
    .Bind('talks', (incoming: IFromCommand) => {
        const cmd = incoming as Talks;

        (store as Store).dispatch(newAction(TalkActionTypes.SET_SEARCH_RESULTS, cmd));
    })
    .Bind('talkStudies', (incoming: IFromCommand) => {
        const cmd = incoming as TalkStudies;

        (store as Store).dispatch(newAction(TalkActionTypes.SET_STUDIES, cmd.studies));
    })
    .Bind('talkDistricts', (incoming: IFromCommand) => {
        const cmd = incoming as TalkDistricts;

        (store as Store).dispatch(newAction(TalkActionTypes.SET_DISTRICTS, cmd.districts));
    })
    .Bind('talkProgramTypes', (incoming: IFromCommand) => {
        const cmd = incoming as TalkProgramTypes;

        (store as Store).dispatch(newAction(TalkActionTypes.SET_PROGRAM_TYPES, cmd.types));
    })
    .Bind('talkAllGroups', (incoming: IFromCommand) => {
        const cmd = incoming as TalkAllGroups;

        (store as Store).dispatch(newAction(TalkActionTypes.SET_ALL_GROUPS, cmd.groups));
    })
    .Bind('talkAllRegims', (incoming: IFromCommand) => {
        const cmd = incoming as TalkAllRegims;

        (store as Store).dispatch(newAction(TalkActionTypes.SET_ALL_REGIMS, cmd.regims));
    })
    .Bind('talkAllTitles', (incoming: IFromCommand) => {
        const cmd = incoming as TalkAllTitles;

        (store as Store).dispatch(newAction(TalkActionTypes.SET_ALL_TITLES, cmd.titles));
    })
    .Bind('talkExportedTalks', (incoming: IFromCommand) => {
        const cmd = incoming as TalkExportedTalks;

        if (cmd.content !== '') {
            const element = document.createElement('a');
            element.setAttribute('href', cmd.content);
            element.setAttribute('target', 'download');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        }
    });

export { reducer as TalkReducer };
