import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import { YacastPlayer } from '@yacast/react-player';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import * as Proto from '../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos } from '../../Store/I18n';
import { updateSTT as getMedias } from '../../Store/Medias/Actions';
import { IMediasState } from '../../Store/Medias/Types';
import {
    clear as clearSearch,
    searchSpeechesDate as doSearchByDate,
    searchSpeeches as doSearchSpeeches,
    setCurrentbegin,
    setCurrentend,
    setCurrentmediaid,
    setCurrentmedianame,
    setCurrentsentence,
    setCurrentstart,
    setCurrenttimestampedwords,
    setCurrentwords,
    setCurrentworduid,
    setNewwords,
    setTimelineProgram,
} from '../../Store/SearchSTT/Actions';
import {
    ISearchSTTDateParams,
    ISearchSTTParams,
    ISearchSTTState,
    NullableSpeechResult,
} from '../../Store/SearchSTT/Types';
import { getTheme } from '../../Themes';
import { TypedStorage } from '../../Utils/TypedStorage';
import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyArray,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../Utils/Various';

const theme = getTheme();

export const styles = MD.createStyles({
    '@keyframes loading': {
        '0%': {
            backgroundColor: theme.palette.background.paper,
        },
        '50%': {
            backgroundColor: theme.palette.background.default,
        },
        '100%': {
            backgroundColor: theme.palette.background.paper,
        },
    },
    expandCell: {
        cursor: 'pointer',
    },
    highlightedWord: {
        color: theme.palette.secondary.light,
        fontDecoration: 'underline',
        fontWeight: 'bold',
    },
    highlightedWordText: {
        color: theme.palette.secondary.light,
        fontDecoration: 'underline',
        fontWeight: 'bold',
    },
    initialPaper: {
        height: 'auto',
        padding: theme.spacing(1),
        textAlign: 'center',
    },
    resultIconView: {
        cursor: 'pointer',
    },
    rootInitial: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
    },
    rowHidden: {
        display: 'none',
    },
    rowHighlight: {
        '&>td': {
            opacity: '1 !important',
        },
        display: 'table-row !important',
    },
    rowLoading: {
        animation: '$loading linear 1s infinite',
    },
    rowReadjusted: {
        '&>td': {
            opacity: 0.75,
        },

        backgroundColor: theme.palette.background.default,
    },
    rowResult: {
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: '100%',
        userSelect: 'text',
    },
    tableRoot: {
        marginTop: '-40px',
        maxHeight: 'calc(100% - 80px)',
        minHeight: 'calc(100% - 80px)',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    tableRootContainer: {
        height: '100%',
        overflow: 'hidden',
        padding: theme.spacing(2),
    },
    tableTitle: {
        height: 40,
    },
    tdjustify: {
        textAlign: 'justify',
    },
});

interface IState {
    autoplay: boolean;
    openPlayer: boolean;
    page: number;
    playing: boolean;
    reducedPrograms: number[];
    resultsPerPage: number;
    currentTime: Date;
    player: HTMLMediaElement | null;
}

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
    router: RouteComponentProps<{}>;
    searchSTT: ISearchSTTState;
}

interface IPropsFromDispatch {
    clearSearch: typeof clearSearch;
    doSearchByDate: typeof doSearchByDate;
    doSearchSpeeches: typeof doSearchSpeeches;
    getMedias: typeof getMedias;
    setCurrentbegin: typeof setCurrentbegin;
    setCurrentend: typeof setCurrentend;
    setCurrentmediaid: typeof setCurrentmediaid;
    setCurrentmedianame: typeof setCurrentmedianame;
    setCurrentsentence: typeof setCurrentsentence;
    setCurrentstart: typeof setCurrentstart;
    setCurrenttimestampedwords: typeof setCurrenttimestampedwords;
    setCurrentwords: typeof setCurrentwords;
    setCurrentworduid: typeof setCurrentworduid;
    setNewwords: typeof setNewwords;
    setTimelineProgram: typeof setTimelineProgram;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class SearchSTTResultsComponent extends React.Component<AllProps, IState> {
    private programsList: number[] = [];
    private switchTimer = -1;
    private previousTime: Date = new Date();
    private player: HTMLMediaElement | null = null;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
    }

    public componentDidMount(): void {
        this.props.getMedias();
    }

    public componentWillUnmount(): void {
        this.props.clearSearch();
    }

    // prevProps: AllProps, prevState: IState
    public componentDidUpdate() {
        if (
            !isNullOrUndefinedOrEmptyArray(this.props.searchSTT.newWords) &&
            !isNullOrUndefinedOrEmptyArray(this.props.searchSTT.currentSentence)
        ) {
            this.updateText();
        }
    }

    public render(): React.ReactNode {
        return this.props.searchSTT.initialState
            ? this.renderNotTable(this.props.i18n._('Please select your search parameters and launch a search'))
            : this.props.searchSTT.searchLoading
            ? this.renderOngoing()
            : this.props.searchSTT.searchSpeechesResultsTotal === -1
            ? this.renderOngoing()
            : this.props.searchSTT.searchSpeechesResultsTotal === 0
            ? this.renderNotTable(this.props.i18n._('The research gave no result, you can perform another research'))
            : this.renderResults();
    }

    private renderOngoing(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid className={classes.rootInitial} container>
                <MD.Grid item lg={3} md={6} xs={10}>
                    <MD.Paper className={classes.initialPaper}>
                        <MD.CircularProgress />
                    </MD.Paper>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private renderNotTable(element: React.ReactNode): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid className={classes.rootInitial} container>
                <MD.Grid item lg={3} md={6} xs={10}>
                    <MD.Paper className={classes.initialPaper}>
                        <MD.Typography variant='body1'>{element}</MD.Typography>
                    </MD.Paper>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private renderResults(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid className={classes.tableRootContainer} container>
                <MD.Grid container>
                    <MD.Grid className={classes.tableTitle} item xs={6}>
                        <MD.Typography variant='h5'>
                            {this.props.i18n.sprintf(
                                this.props.i18n.ngettext(
                                    '%1$d result',
                                    '%1$d results',
                                    this.props.searchSTT.searchSpeechesResultsTotal,
                                ),
                                this.props.searchSTT.searchSpeechesResultsTotal,
                            )}
                        </MD.Typography>
                    </MD.Grid>
                    <MD.Grid className={classes.tableTitle} item xs={6}>
                        {this.buildTablePagination()}
                    </MD.Grid>
                </MD.Grid>
                <MD.TableContainer className={classes.tableRoot} component={MD.Grid} item xs={12}>
                    <MD.Table className={classes.table} size='small' stickyHeader>
                        {this.buildTableHead()}
                        {this.buildTableRows()}
                    </MD.Table>
                    {this.buildYacastPlayerDialog()}
                </MD.TableContainer>
            </MD.Grid>
        );
    }

    private buildTableHead(): React.ReactNode {
        const allReduced =
            this.programsList.length > 0 && this.state.reducedPrograms.length === this.programsList.length;
        const expandIcon = allReduced ? FA.faChevronDoubleUp : FA.faChevronDoubleDown;
        const expandText = allReduced ? this.props.i18n._('Reduce all result') : this.props.i18n._('Expand all result');

        return (
            <React.Fragment>
                <MD.TableHead>
                    <MD.TableRow>
                        <MD.TableCell padding='checkbox'>
                            <MD.IconButton
                                aria-label={expandText}
                                size={'small'}
                                onClick={() => {
                                    allReduced ? this.reduceAllResult() : this.expandAllResult();
                                }}
                            >
                                <FontAwesomeIcon icon={expandIcon} />
                            </MD.IconButton>
                        </MD.TableCell>
                        <MD.TableCell>{this.props.i18n._('Media')}</MD.TableCell>
                        <MD.TableCell>{this.props.i18n._('Date')}</MD.TableCell>
                        <MD.TableCell>{this.props.i18n._('Match')}</MD.TableCell>
                    </MD.TableRow>
                </MD.TableHead>
            </React.Fragment>
        );
    }

    private buildTablePagination(): React.ReactNode {
        return (
            <>
                <MD.TablePagination
                    component='div'
                    count={this.props.searchSTT.searchSpeechesResultsTotal}
                    rowsPerPage={this.state.resultsPerPage}
                    page={this.state.page}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, page: number): void => {
                        this.setState({
                            ...this.state,
                            page,
                        });
                        this.refresh();
                    }}
                />
            </>
        );
    }

    private buildTableRows(): React.ReactNode {
        return (
            <MD.TableBody key={'table_body'}>
                {this.props.searchSTT.searchSpeechesResults.reduce<React.ReactNode[]>(
                    (list: React.ReactNode[], res: NullableSpeechResult, currentIndex: number): React.ReactNode[] => {
                        return list.concat(this.buildTableRow(res, currentIndex));
                    },
                    [],
                )}
            </MD.TableBody>
        );
    }

    private buildTableRow(res: NullableSpeechResult, currentIndex: number): React.ReactNode[] {
        if (isNullOrUndefined(res)) {
            return [
                <MD.TableRow className={this.props.classes.rowLoading} key={`row_loading_${Math.random()}`}>
                    <MD.TableCell colSpan={6}>{''}</MD.TableCell>
                </MD.TableRow>,
            ];
        }
        if (isNullOrUndefinedOrZero(res.Start)) {
            return [];
        }
        if (isNullOrUndefinedOrZero(res.End)) {
            return [];
        }
        let matches: String[];
        if (isNullOrUndefinedOrEmptyArray(res.Matches)) {
            matches = [];
        } else {
            matches = res.Matches;
        }

        const { classes } = this.props;

        if (this.programsList.indexOf(currentIndex) === -1) {
            this.programsList.push(currentIndex);
        }

        const rows: React.ReactNode[] = [];
        const rowNumber = currentIndex;
        const rowReduced = this.state.reducedPrograms.includes(rowNumber);
        const expandIcon = rowReduced ? FA.faChevronUp : FA.faChevronDown;

        rows.push(
            <React.Fragment key={rowNumber}>
                <MD.TableRow
                    className={this.props.classes.rowResult}
                    key={`row_speech_${this.state.page}_${rowNumber}`}
                >
                    <MD.TableCell>
                        <MD.IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => {
                                rowReduced ? this.reduceResult(rowNumber) : this.expandResult(rowNumber);
                            }}
                        >
                            <FontAwesomeIcon icon={expandIcon} />
                        </MD.IconButton>
                    </MD.TableCell>
                    <MD.TableCell className={classes.highlightedWord}>{this.getProgramMedia(res)}</MD.TableCell>
                    <MD.TableCell>
                        {moment(new Date(res.Start)).format(this.props.localeInfos.formatShortDate)}
                        {': '}
                        {moment(new Date(res.Start)).format(this.props.localeInfos.formatShortTimeWithSeconds)} {' - '}
                        {moment(new Date(res.End)).format(this.props.localeInfos.formatShortTimeWithSeconds)}
                    </MD.TableCell>
                    <MD.TableCell>
                        <span className={classes.highlightedWord}>
                            {matches.map((value: String, index: number, array: String[]) => {
                                if (index < array.length - 1) {
                                    return value + ' - ';
                                } else {
                                    return value;
                                }
                            })}
                        </span>
                    </MD.TableCell>
                </MD.TableRow>
                <MD.TableRow>
                    <MD.TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                        <MD.Collapse in={this.state.reducedPrograms.includes(rowNumber)} timeout='auto' unmountOnExit>
                            <MD.Table size='small' aria-label='children'>
                                {this.buildSubTableHead()}
                                {this.buildSubTableRow(res, currentIndex)}
                            </MD.Table>
                        </MD.Collapse>
                    </MD.TableCell>
                </MD.TableRow>
            </React.Fragment>,
        );
        return rows;
    }

    private buildSubTableHead(): React.ReactNode {
        return (
            <MD.TableHead>
                <MD.TableRow>
                    <MD.TableCell>{''}</MD.TableCell>
                    <MD.TableCell>{this.props.i18n._('Text')}</MD.TableCell>
                </MD.TableRow>
            </MD.TableHead>
        );
    }
    private buildSubTableRow(res: Proto.mediaarchiver.ISearchSpeechResult, indexSentence: number): React.ReactNode {
        const { classes } = this.props;
        return (
            <MD.TableBody>
                <MD.TableRow>
                    <MD.TableCell></MD.TableCell>
                    <MD.TableCell className={classes.tdjustify}>{this.highlightQuery(res, indexSentence)}</MD.TableCell>
                </MD.TableRow>
            </MD.TableBody>
        );
    }

    private getProgramMediaID(res: Proto.mediaarchiver.ISearchSpeechResult): number {
        let mediaID = 0;
        this.props.medias.data.some((media) => {
            if (media.id === res.Media) {
                mediaID = media.id;
                return true;
            }
            return false;
        });
        return mediaID;
    }

    private getProgramMedia(res: Proto.mediaarchiver.ISearchSpeechResult): string {
        let mediaName = '';
        this.props.medias.data.some((media) => {
            if (media.id === res.Media) {
                mediaName = media.name;
                return true;
            }
            return false;
        });
        return mediaName;
    }

    private highlightQuery(res: Proto.mediaarchiver.ISearchSpeechResult, indexSentence: number): React.ReactNode[] {
        const { classes } = this.props;
        if (isNullOrUndefinedOrEmptyArray(res.Fullwords)) {
            return [''];
        }
        if (isNullOrUndefinedOrEmptyArray(res.Matches)) {
            return [''];
        }

        let previousSpeaker = '';

        return res.Fullwords.map((word: Proto.mediaarchiver.ISearchSpeechesWord, index: number): React.ReactNode => {
            if (isNullOrUndefinedOrEmptyString(word.Word)) {
                return '';
            }
            if (isNullOrUndefinedOrEmptyString(word.WordUID)) {
                return '';
            }
            if (isNullOrUndefinedOrEmptyArray(res.Matches)) {
                return word.Word;
            }

            const newSpeaker = word.WordUID.split('_')[word.WordUID.split('_').length - 3];

            if (res.Matches.indexOf(word.Word) !== -1) {
                if (previousSpeaker === newSpeaker || index === 0) {
                    previousSpeaker = newSpeaker;
                    return (
                        <a
                            key={word.WordUID}
                            className={classes.highlightedWord}
                            id={word.WordUID}
                            onClick={this.textClick.bind(this, indexSentence, index)}
                        >
                            {' '}
                            {word.Word}{' '}
                        </a>
                    );
                } else {
                    previousSpeaker = newSpeaker;
                    return (
                        <a
                            key={word.WordUID}
                            className={classes.highlightedWord}
                            id={word.WordUID}
                            onClick={this.textClick.bind(this, indexSentence, index)}
                        >
                            <br />
                            <br />
                            {word.Word}{' '}
                        </a>
                    );
                }
            }
            if (previousSpeaker === newSpeaker || index === 0) {
                previousSpeaker = newSpeaker;
                return (
                    <React.Fragment>
                        {index === 0 ? (
                            <a
                                style={
                                    previousSpeaker[0] === 'M'
                                        ? { color: '#9fc5e8', fontWeight: 'bold' }
                                        : previousSpeaker[0] === 'F'
                                        ? { color: '#d5a6bd', fontWeight: 'bold' }
                                        : { color: '#fff2cc', fontWeight: 'bold' }
                                }
                            >
                                {'(' + previousSpeaker[0] + ')'}
                            </a>
                        ) : (
                            ''
                        )}
                        <a
                            key={word.WordUID}
                            id={word.WordUID}
                            onClick={this.textClick.bind(this, indexSentence, index)}
                        >
                            {' '}
                            {index === 0 ? '(' + previousSpeaker[0] + ') ' + word.Word : word.Word}{' '}
                        </a>
                    </React.Fragment>
                );
            } else {
                previousSpeaker = newSpeaker;
                return (
                    <React.Fragment>
                        <a
                            style={
                                previousSpeaker[0] === 'M'
                                    ? { color: '#9fc5e8', fontWeight: 'bold' }
                                    : previousSpeaker[0] === 'F'
                                    ? { color: '#d5a6bd', fontWeight: 'bold' }
                                    : { color: '#fff2cc', fontWeight: 'bold' }
                            }
                        >
                            <br />
                            <br />
                            {'(' + previousSpeaker[0] + ')'}
                        </a>
                        <a
                            key={word.WordUID}
                            id={word.WordUID}
                            onClick={this.textClick.bind(this, indexSentence, index)}
                        >
                            {' '}
                            {word.Word}{' '}
                        </a>
                    </React.Fragment>
                );
            }
        });
    }

    private refresh() {
        if (this.switchTimer !== -1) {
            window.clearTimeout(this.switchTimer);
        }
        this.switchTimer = window.setTimeout(() => {
            if (!isNullOrUndefined(this.props.searchSTT.lastSearchSpeeches)) {
                this.props.doSearchSpeeches({
                    end: this.props.searchSTT.lastSearchSpeeches.end,
                    logic: this.props.searchSTT.lastSearchSpeeches.logic,
                    medias: this.props.searchSTT.lastSearchSpeeches.medias,
                    page: this.state.page,
                    pageSize: this.state.resultsPerPage,
                    start: this.props.searchSTT.lastSearchSpeeches.start,
                    text: this.props.searchSTT.lastSearchSpeeches.text,
                });
                this.setState({ reducedPrograms: [] });
            }
            this.switchTimer = -1;
        }, 300);
    }

    private reduceResult(itemToRemove: number) {
        const list = this.state.reducedPrograms;
        list.push(itemToRemove);
        const listUpdated = list.filter((item) => item !== itemToRemove);
        this.setState({ reducedPrograms: listUpdated });
    }

    private expandResult(itemToAdd: number) {
        const list = this.state.reducedPrograms;
        list.push(itemToAdd);
        this.setState({ reducedPrograms: list });
    }

    private reduceAllResult() {
        this.setState({ reducedPrograms: [] });
    }

    private expandAllResult() {
        this.setState({ reducedPrograms: this.programsList });
    }

    private textClick(indexS: number, indexW: number) {
        const currentSentence = this.props.searchSTT.searchSpeechesResults[
            indexS
        ] as Proto.mediaarchiver.ISearchSpeechResult;

        const currentWords = currentSentence.Fullwords as Proto.mediaarchiver.ISearchSpeechesWord[];

        const lastWord = currentWords[currentWords.length - 1];
        const currentWord = currentWords[indexW];
        const firstWord = currentWords[0];

        this.setState({ openPlayer: true });

        const mediaID = this.getProgramMediaID(currentSentence);
        const mediaName = this.getProgramMedia(currentSentence);

        const currentStart = currentWord.Dts as number;
        const currentBegin = firstWord.Dts as number;
        const currentEnd = lastWord.Dte as number;

        const myObj = new Map<number, string>();

        let previousSpeaker = '';

        const sentence = currentWords.map((word: Proto.mediaarchiver.ISearchSpeechesWord, index: number) => {
            if (isNullOrUndefinedOrEmptyString(word.Word)) {
                return '';
            }
            if (isNullOrUndefinedOrEmptyString(word.WordUID)) {
                return '';
            }
            if (isNullOrUndefinedOrZero(word.Dts)) {
                return '';
            }

            myObj.set(word.Dts, word.WordUID + '_player');

            const newSpeaker = word.WordUID.split('_')[word.WordUID.split('_').length - 3];

            if (newSpeaker === previousSpeaker || index === 0) {
                previousSpeaker = newSpeaker;
                return (
                    <React.Fragment>
                        {index === 0 ? (
                            <p
                                style={
                                    previousSpeaker[0] === 'M'
                                        ? { color: '#9fc5e8', fontWeight: 'bold' }
                                        : previousSpeaker[0] === 'F'
                                        ? { color: '#d5a6bd', fontWeight: 'bold' }
                                        : { color: '#fff2cc', fontWeight: 'bold' }
                                }
                            >
                                {'(' + previousSpeaker[0] + ')'}
                            </p>
                        ) : (
                            ''
                        )}
                        <a
                            key={word.WordUID + '_player'}
                            id={word.WordUID + '_player'}
                            onClick={() => {
                                if (isNullOrUndefinedOrZero(word.Dts)) {
                                    return;
                                }
                                this.props.setCurrentstart(word.Dts);
                            }}
                        >
                            {' '}
                            {index === 0 ? '(' + previousSpeaker[0] + ') ' + word.Word : word.Word}{' '}
                        </a>
                    </React.Fragment>
                );
            } else {
                previousSpeaker = newSpeaker;
                return (
                    <React.Fragment>
                        <p
                            style={
                                previousSpeaker[0] === 'M'
                                    ? { color: '#9fc5e8', fontWeight: 'bold' }
                                    : previousSpeaker[0] === 'F'
                                    ? { color: '#d5a6bd', fontWeight: 'bold' }
                                    : { color: '#fff2cc', fontWeight: 'bold' }
                            }
                        >
                            <br />
                            <br />
                            {'(' + previousSpeaker[0] + ')'}
                        </p>
                        <a
                            key={word.WordUID + '_player'}
                            id={word.WordUID + '_player'}
                            onClick={() => {
                                if (isNullOrUndefinedOrZero(word.Dts)) {
                                    return;
                                }
                                this.props.setCurrentstart(word.Dts);
                            }}
                        >
                            {' '}
                            {word.Word}{' '}
                        </a>
                    </React.Fragment>
                );
            }
        });

        this.props.setCurrentbegin(currentBegin);
        this.props.setCurrentend(currentEnd);
        this.props.setCurrentmediaid(mediaID);
        this.props.setCurrentmedianame(mediaName);
        this.props.setCurrentsentence(sentence);
        this.props.setCurrentstart(currentStart);
        this.props.setCurrenttimestampedwords(myObj);
        this.props.setCurrentwords(currentWords);
    }

    private buildYacastPlayerDialog(): React.ReactNode {
        return (
            <MD.Dialog
                fullWidth
                maxWidth='lg'
                open={this.state.openPlayer}
                onClose={() => {
                    this.setState({ openPlayer: false });
                }}
            >
                <MD.DialogTitle id='alert-dialog-title'>
                    <MD.Box display='flex' alignItems='center'>
                        <MD.Box flexGrow={1}>{this.props.searchSTT.currentMediaName}</MD.Box>
                        <MD.Box>
                            <MD.IconButton
                                onClick={() => {
                                    this.setState({ openPlayer: false });
                                }}
                            >
                                <FontAwesomeIcon icon={FA.faWindowClose} />
                            </MD.IconButton>
                        </MD.Box>
                    </MD.Box>
                </MD.DialogTitle>
                {isNullOrUndefinedOrZero(this.props.searchSTT.currentMediaID)
                    ? this.playerLoading('checking var 1')
                    : isNullOrUndefinedOrEmptyString(this.props.searchSTT.currentMediaName)
                    ? this.playerLoading('checking var 2')
                    : isNullOrUndefinedOrZero(this.props.searchSTT.currentStart)
                    ? this.playerLoading('checking var 3')
                    : isNullOrUndefinedOrEmptyArray(this.props.searchSTT.currentSentence)
                    ? this.playerLoading('checking var 4')
                    : this.playerActive()}
            </MD.Dialog>
        );
    }

    private highligthPlayerText(time: number) {
        const { classes } = this.props;

        const timestampedWords = this.props.searchSTT.currentTimestampedWords;

        timestampedWords.forEach((value: string, key: number) => {
            const current = document.getElementById(value);
            if (!isNull(current)) {
                if (key > time - 2500 && key < time + 500) {
                    current.className = classes.highlightedWordText;
                    return;
                }
                current.className = '';
            }
        });
    }

    private playerActive(): React.ReactNode {
        return (
            <MD.DialogContent style={{ overflow: 'hidden' }}>
                <div
                    id={'myplayer'}
                    style={{
                        height: '300px',
                        margin: 'auto',
                        paddingBottom: '5px',
                        width: '750px',
                    }}
                >
                    <YacastPlayer
                        aclToken={TypedStorage.get('expiringToken', '')}
                        autoplay={true}
                        key={`stt_player_${this.props.searchSTT.currentStart}`}
                        lightPlayer={true}
                        mediaID={this.props.searchSTT.currentMediaID}
                        mediaName={this.props.searchSTT.currentMediaName}
                        start={new Date(this.props.searchSTT.currentStart)}
                        timelineDuration={60 * 10}
                        exposeMedia={(media: HTMLMediaElement) => {
                            if (!isNullOrUndefined(media) && this.player !== media) {
                                this.player = media;
                                this.setState({ player: this.player });
                            }
                        }}
                        onDateUpdate={(date: Date) => {
                            this.setState({ currentTime: date });
                            const a = document.getElementsByClassName(this.props.classes.highlightedWordText);
                            const elementPlayer = document.getElementById('myplayer') as HTMLElement;
                            if (
                                date.getTime() > this.previousTime.getTime() + 250 ||
                                date.getTime() < this.previousTime.getTime()
                            ) {
                                this.highligthPlayerText(date.getTime());
                                if (!isNullOrUndefined(a[0])) {
                                    a[0].scrollIntoView();
                                }
                                if (!isNullOrUndefined(elementPlayer)) {
                                    elementPlayer.scrollIntoView();
                                }
                                this.previousTime = date;
                            }

                            if (
                                isNullOrUndefinedOrEmptyArray(this.props.searchSTT.newWords) &&
                                date.getTime() > this.props.searchSTT.currentEnd - 20000
                            ) {
                                const previousEnd = this.props.searchSTT.currentEnd;
                                const offset = 3 * 60 * 1000;

                                this.props.setCurrentbegin(previousEnd - offset);
                                this.props.setCurrentend(previousEnd + offset);
                                this.props.doSearchByDate({
                                    end: new Date(previousEnd + offset),
                                    medias: this.props.searchSTT.currentMediaID,
                                    start: new Date(previousEnd - offset),
                                });
                            }
                            if (
                                isNullOrUndefinedOrEmptyArray(this.props.searchSTT.newWords) &&
                                date.getTime() < this.props.searchSTT.currentBegin + 10000
                            ) {
                                const previousBegin = this.props.searchSTT.currentBegin;
                                const offset = 3 * 60 * 1000;

                                this.props.setCurrentbegin(previousBegin - offset);
                                this.props.setCurrentend(previousBegin + offset);
                                this.props.doSearchByDate({
                                    end: new Date(previousBegin + offset),
                                    medias: this.props.searchSTT.currentMediaID,
                                    start: new Date(previousBegin - offset),
                                });
                            }
                        }}
                    />
                </div>
                {/*<p>{this.state.currentTime.toLocaleString()}</p>*/}
                <div
                    id={'myscrolldiv'}
                    style={{
                        height: '300px',
                        margin: 'auto',
                        overflow: 'auto',
                    }}
                    onScroll={() => {
                        const element = document.getElementById('myscrolldiv') as HTMLElement;
                        if (!isNull(element)) {
                            if (element.offsetHeight + element.scrollTop >= element.scrollHeight) {
                                if (isNullOrUndefinedOrEmptyArray(this.props.searchSTT.newWords)) {
                                    const previousEnd = this.props.searchSTT.currentEnd;
                                    const offset = 3 * 60 * 1000;
                                    this.props.setCurrentbegin(previousEnd - 2 * offset);
                                    this.props.setCurrentend(previousEnd + offset);
                                    this.props.doSearchByDate({
                                        end: new Date(previousEnd + offset),
                                        medias: this.props.searchSTT.currentMediaID,
                                        start: new Date(previousEnd - 2 * offset),
                                    });
                                }
                            }
                        }
                    }}
                >
                    <p>{this.props.searchSTT.currentSentence}</p>
                </div>
            </MD.DialogContent>
        );
    }

    private playerLoading(text?: string): React.ReactNode {
        return (
            <MD.DialogContent>
                {isNullOrUndefinedOrEmptyString(text) ? null : <MD.DialogContentText>{text}</MD.DialogContentText>}
                <MD.CircularProgress />
            </MD.DialogContent>
        );
    }

    private getDefaultState(): IState {
        return {
            autoplay: true,
            openPlayer: false,
            page: 0,
            playing: true,
            reducedPrograms: this.programsList,
            resultsPerPage: 50,
            currentTime: new Date(),
            player: null,
        };
    }

    private updateText() {
        const newWords = this.props.searchSTT.newWords;

        const lastWord = newWords[newWords.length - 1];
        const newEnd = lastWord.Dte as number;

        const firstWord = newWords[0];
        const newBegin = firstWord.Dts as number;

        const myObj = new Map<number, string>();
        let previousSpeaker = '';

        const sentence = newWords.map((word: Proto.mediaarchiver.ISearchSpeechesWord, index: number) => {
            if (isNullOrUndefinedOrEmptyString(word.Word)) {
                return '';
            }
            if (isNullOrUndefinedOrEmptyString(word.WordUID)) {
                return '';
            }
            if (isNullOrUndefinedOrZero(word.Dts)) {
                return '';
            }

            myObj.set(word.Dts, word.WordUID + '_player');

            const newSpeaker = word.WordUID.split('_')[word.WordUID.split('_').length - 3];

            if (newSpeaker === previousSpeaker || index === 0) {
                previousSpeaker = newSpeaker;
                return (
                    <a
                        key={word.WordUID + '_player'}
                        id={word.WordUID + '_player'}
                        onClick={() => {
                            if (isNullOrUndefinedOrZero(word.Dts)) {
                                return;
                            }
                            this.props.setCurrentstart(word.Dts);
                        }}
                    >
                        {' '}
                        {word.Word}{' '}
                    </a>
                );
            } else {
                previousSpeaker = newSpeaker;
                return (
                    <a
                        key={word.WordUID + '_player'}
                        id={word.WordUID + '_player'}
                        onClick={() => {
                            if (isNullOrUndefinedOrZero(word.Dts)) {
                                return;
                            }
                            this.props.setCurrentstart(word.Dts);
                        }}
                    >
                        <br />
                        <br />
                        {word.Word}{' '}
                    </a>
                );
            }
        });

        this.props.setCurrentbegin(newBegin);
        this.props.setCurrentend(newEnd);
        this.props.setCurrentsentence(sentence);
        this.props.setCurrenttimestampedwords(myObj);
        this.props.setCurrentwords(newWords);
        this.props.setNewwords([]);
    }
}

const mapStateToProps = ({ i18n, medias, searchSTT }: IApplicationState, ownProps: RouteComponentProps<{}>) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    medias,
    router: ownProps,
    searchSTT,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearSearch: () => dispatch(clearSearch()),
    doSearchByDate: (params: ISearchSTTDateParams) => dispatch(doSearchByDate(params)),
    doSearchSpeeches: (params: ISearchSTTParams) => dispatch(doSearchSpeeches(params)),
    getMedias: () => dispatch(getMedias()),
    setCurrentbegin: (currentBegin: number) => dispatch(setCurrentbegin(currentBegin)),
    setCurrentend: (currentEnd: number) => dispatch(setCurrentend(currentEnd)),
    setCurrentmediaid: (currentMediaID: number) => dispatch(setCurrentmediaid(currentMediaID)),
    setCurrentmedianame: (currentMediaName: string) => dispatch(setCurrentmedianame(currentMediaName)),
    setCurrentsentence: (currentSentence: React.ReactNode[]) => dispatch(setCurrentsentence(currentSentence)),
    setCurrentstart: (currentStart: number) => dispatch(setCurrentstart(currentStart)),
    setCurrenttimestampedwords: (currentTimestampedWords: Map<number, string>) =>
        dispatch(setCurrenttimestampedwords(currentTimestampedWords)),
    setCurrentwords: (currentWords: Proto.mediaarchiver.ISearchSpeechesWord[]) =>
        dispatch(setCurrentwords(currentWords)),
    setCurrentworduid: (currentWordUID: string) => dispatch(setCurrentworduid(currentWordUID)),
    setNewwords: (currentWords: Proto.mediaarchiver.ISearchSpeechesWord[]) => dispatch(setNewwords(currentWords)),
    setTimelineProgram: (res: NullableSpeechResult) => dispatch(setTimelineProgram(res)),
});

export const SearchSTTResults = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(SearchSTTResultsComponent));
