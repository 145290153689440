import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../../Themes';

const theme = getTheme();

export default createStyles({
    audienceBar: {
        '&:hover': {
            opacity: 0.5,
        },
        backgroundColor: theme.palette.secondary.dark,
        opacity: 0.3,
        transition: theme.transitions.create('opacity', { duration: '0.2s' }),
        width: `${theme.mediaarchiver.dimensions.audienceBarsWidth}px`,
    },
    canvas: {
        left: 0,
        position: 'absolute',
        zIndex: 3,
    },
    canvasHover: {
        left: 0,
        position: 'absolute',
        zIndex: 4,
    },
    emptyContainer: {
        '&>div': {
            '&>p': {
                color: theme.palette.text.disabled,
            },
            paddingLeft: theme.spacing(1),
            paddingTop: 42,
            position: 'fixed',
        },
        height: '100%',
    },
    infosBubble: {
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.divider,
        borderRadius: theme.spacing(1),
        height: 100,
        overflow: 'hidden',
        padding: theme.spacing(1),
        position: 'fixed',
        width: 170,
        zIndex: 1000,
    },
    infosBubbleLine: {
        '& strong': {
            marginLeft: 5,
        },

        fontSize: '1.1em',
    },
    infosBubbleTitle: {

    },
    root: {
        alignItems: 'flex-end',
        display: 'inline-flex',
        height: '100%',
        paddingBottom: 1,
        width: '100%',
    },
});
