import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../Store';
import { ILoginData, login } from '../../Store/User';
import { HomePage } from './HomePage';
import styles from './styles';

const mapStateToProps = ({ i18n, user }: IApplicationState) => ({
    i18n: i18n.i18n,
    userError: user.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    login: (infos: ILoginData) => dispatch(login(infos)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(HomePage));
