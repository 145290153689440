import { createStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ShareConsult } from '../Components/Share/Consult';
import Layout from '../Components/Layout';
import { IApplicationState, IConnectedReduxProps } from '../Store';
import { I18N } from '../Store/I18n';
import { hideDrawer, setDrawerContent, setDrawerTitle, setHasAnnotations, setPageContent } from '../Store/Layout';
import { getTheme } from '../Themes';

export interface IRouterMatchParams {
    subpage: string;
}

interface IPropsFromState {
    i18n: I18N;
    router: RouteComponentProps<IRouterMatchParams>;
}

interface IPropsFromDispatch {
    hideDrawer: typeof hideDrawer;
    setDrawerContent: typeof setDrawerContent;
    setDrawerTitle: typeof setDrawerTitle;
    setHasAnnotations: typeof setHasAnnotations;
    setPageContent: typeof setPageContent;
}

type AllProps = IPropsFromState & IPropsFromDispatch & IConnectedReduxProps;

const theme = getTheme();

const styles = createStyles({
    content: {
        flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
});

export class SharePageComponent extends React.Component<AllProps, {}> {
    public render(): React.ReactNode {
        this.props.setPageContent(<Route component={ShareConsult} />);
        this.props.setDrawerTitle('');
        this.props.setHasAnnotations(false);
        this.props.setDrawerContent(null);
        this.props.hideDrawer();
        return <Route component={Layout} />;
    }
}

const mapStateToProps = (state: IApplicationState, ownProps: RouteComponentProps<IRouterMatchParams>) => ({
    i18n: state.i18n.i18n,
    router: ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hideDrawer: () => dispatch(hideDrawer()),
    setDrawerContent: (content: React.ReactNode) => dispatch(setDrawerContent(content)),
    setDrawerTitle: (title: string) => dispatch(setDrawerTitle(title)),
    setHasAnnotations: (has: boolean) => dispatch(setHasAnnotations(has)),
    setPageContent: (content: React.ReactNode) => dispatch(setPageContent(content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SharePageComponent));
