import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Proto from '../../../Protos/protos';
import { IApplicationState } from '../../../Store';
import { setTimelineAnnotation } from '../../../Store/Annotations';
import { setTimelineTalk } from '../../../Store/Talk';
import { Locales, setLocale } from '../../../Store/I18n';
import { setDrawerContent } from '../../../Store/Layout';
import { update as updateMedias } from '../../../Store/Medias';
import { setEndSelection, setSnapshot, setStartSelection } from '../../../Store/Player';
import { setTimelineProgram } from '../../../Store/Search';
import {
    ITimelineCriteriasState,
    setCalendarStart,
    setCalendarStartWarning,
    showReportBug,
    showStorageBug,
    updateCriterias,
} from '../../../Store/Timeline';
import { showIncident } from '../../../Store/Incident';
import { setCopiedTalks } from '../../../Store/Talk';
import { TimelineDrawerComponent } from './Drawer';
import { ISearchSTTDateParams } from '../../../Store/SearchSTT/Types';
import {
    clear as clearSearchSTT,
    searchSpeechesDate as doSearchSTTByDate,
    setRequestedCurrent,
    setRequestedEnd,
    setRequestedMedia,
    setRequestedStart,
} from '../../../Store/SearchSTT/Actions';
import {
    getPanels,
    getSttMedias,
    update as getMedias,
    getBackgroundMedias,
    getCrossBucketMedias,
} from '../../../Store/Medias/Actions';

import styles from './style';

const mapStateToProps = ({
    annotations,
    i18n,
    incident,
    medias,
    player,
    search,
    talk,
    timeline,
    user,
}: IApplicationState) => ({
    i18n: i18n.i18n,
    incident,
    localeInfos: i18n.localeInfos,
    medias,
    player,
    // TODO: not fixed
    searchAnnotation: annotations && annotations.timelineAnnotation,
    searchProgram: search.timelineProgram,
    searchTalk: talk && talk.timelineTalk,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearSearchSTT: () => dispatch(clearSearchSTT()),
    doSearchSTTByDate: (params: ISearchSTTDateParams) => dispatch(doSearchSTTByDate(params)),
    getBackgroundMedias: () => dispatch(getBackgroundMedias()),
    getCrossBucketMedias: () => dispatch(getCrossBucketMedias()),
    getMedias: () => dispatch(getMedias()),
    getPanels: () => dispatch(getPanels()),
    getSttMedias: () => dispatch(getSttMedias()),
    loadI18n: (locale: Locales) => dispatch(setLocale(locale)),
    setCalendarStart: (start: Date) => dispatch(setCalendarStart(start)),
    setCalendarStartWarning: (startWarning: string) => dispatch(setCalendarStartWarning(startWarning)),
    setCopiedTalks: (t: Proto.mediaarchiver.ITalk[]) => dispatch(setCopiedTalks(t)),
    setDrawerContent: (content: React.ReactNode) => dispatch(setDrawerContent(content)),
    setEndSelection: (time: Date | null) => dispatch(setEndSelection(time)),
    setRequestedCurrent: (params: Date) => dispatch(setRequestedCurrent(params)),
    setRequestedEnd: (params: Date) => dispatch(setRequestedEnd(params)),
    setRequestedMedia: (params: number) => dispatch(setRequestedMedia(params)),
    setRequestedStart: (params: Date) => dispatch(setRequestedStart(params)),
    setSnapshot: () => dispatch(setSnapshot(null)),
    setStartSelection: (time: Date | null) => dispatch(setStartSelection(time)),
    setTimelineAnnotation: (prg: Proto.mediaarchiver.IAnnotation | null) => dispatch(setTimelineAnnotation(prg)),
    setTimelineProgram: (prg: Proto.mediaarchiver.ISearchResult | null) => dispatch(setTimelineProgram(prg)),
    setTimelineTalk: (talk: Proto.mediaarchiver.ITalk | null) => dispatch(setTimelineTalk(talk)),
    showIncident: () => dispatch(showIncident()),
    showReportBug: () => dispatch(showReportBug()),
    showStorageBug: () => dispatch(showStorageBug()),
    updateCriterias: (criterias: ITimelineCriteriasState) => dispatch(updateCriterias(criterias)),
    updateMedias: () => dispatch(updateMedias()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TimelineDrawerComponent));
