import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { Reducer } from 'redux';

import { pageTitle } from '../../Utils/String';
import { ILayoutState, LayoutActionTypes } from './Types';

const initialState: ILayoutState = {
    drawerContent: null,
    drawerTitle: '',
    hasAnnotations: false,
    pageContent: null,
    pageTitle: '',
    showAnnotations: true,
    showAppBar: true,
    showDrawer: window.innerWidth >= 1280,
    size: 'md',
    snackMessage: '',
    stackedSnackMessages: [],
};

const reducer: Reducer<ILayoutState> = (state = initialState, action) => {
    switch (action.type) {
        case LayoutActionTypes.ANNOTATIONS_HIDE: {
            return { ...state, showAnnotations: false };
        }
        case LayoutActionTypes.ANNOTATIONS_SHOW: {
            return { ...state, showAnnotations: true };
        }
        case LayoutActionTypes.APPBAR_HIDE: {
            return { ...state, showAppBar: false };
        }
        case LayoutActionTypes.APPBAR_SHOW: {
            return { ...state, showAppBar: true };
        }
        case LayoutActionTypes.DRAWER_HIDE: {
            return { ...state, showDrawer: false };
        }
        case LayoutActionTypes.DRAWER_SHOW: {
            return { ...state, showDrawer: true };
        }
        case LayoutActionTypes.HIDE_SNACK: {
            return { ...state, snackMessage: '' };
        }
        case LayoutActionTypes.SET_DRAWER_CONTENT: {
            return { ...state, drawerContent: action.payload };
        }
        case LayoutActionTypes.SET_DRAWER_TITLE: {
            return { ...state, drawerTitle: action.payload };
        }
        case LayoutActionTypes.SET_HAS_ANNOTATIONS: {
            return { ...state, hasAnnotations: action.payload };
        }
        case LayoutActionTypes.SET_PAGE_CONTENT: {
            return { ...state, pageContent: action.payload };
        }
        case LayoutActionTypes.SET_PAGE_TITLE: {
            return { ...state, pageTitle: pageTitle(action.payload) };
        }
        case LayoutActionTypes.SET_SIZE: {
            let size: Breakpoint = 'md';
            let showDrawer = state.showDrawer;

            switch (action.payload) {
                case 'xl':
                    size = 'xl';
                    showDrawer = true;
                    break;
                case 'lg':
                    size = 'lg';
                    showDrawer = true;
                    break;
                case 'xs':
                case 'sm':
                case 'md':
                default:
                    size = 'md';
                    showDrawer = false;
                    break;
            }
            return { ...state, showDrawer, size };
        }
        case LayoutActionTypes.SET_SNACK_MESSAGE: {
            const s = { ...state };

            if (state.snackMessage !== '') {
                s.stackedSnackMessages = state.stackedSnackMessages.concat(action.payload);
            } else {
                s.snackMessage = action.payload;
            }
            return s;
        }
        case LayoutActionTypes.SET_SNACK_MESSAGE_NOW: {
            return { ...state, snackMessage: action.payload };
        }
        case LayoutActionTypes.UPDATE_STACK: {
            return { ...state, stackedSnackMessages: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as LayoutReducer };
