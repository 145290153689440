import * as Proto from '../../Protos/protos';

export enum SearchActionTypes {
    CLEAR = '@@search/CLEAR',
    SEARCH = '@@search/SEARCH',
    SET_LAST_SEARCH = '@@search/SET_LAST_SEARCH',
    SET_SEARCH_LOADING = '@@search/SET_SEARCH_LOADING',
    SET_SEARCH_PARAMS = '@@search/SET_SEARCH_PARAMS',
    SET_SEARCH_RESULTS = '@@search/SET_SEARCH_RESULTS',
    SET_SEARCH_RESULTS_COUNT = '@@search/SET_SEARCH_RESULTS_COUNT',
    SET_SEARCH_RESULTS_TOTAL = '@@search/SET_SEARCH_RESULTS_TOTAL',
    SET_STRUCTURED_SEARCH_RESULTS = '@@search/SET_STRUCTURED_SEARCH_RESULTS',
    SET_TIMELINE_PROGRAM = '@@search/SET_TIMELINE_PROGRAM',
}

export interface ISearchParams {
    end: Date;
    kinds: string[];
    medias: number[];
    page: number;
    pageSize: number;
    start: Date;
    text: string;
}

export type NullableResult = Proto.mediaarchiver.ISearchResult | null;
export type NullableStructuredResult = Proto.mediaarchiver.IStructuredSearchResult | null;

export interface IHashParams {
    hash: string;
}

export type hashedSearchParams = ISearchParams & IHashParams;

export interface ISearchState {
    readonly currentSearch: hashedSearchParams | null;
    readonly lastSearch: ISearchParams | null;
    readonly pageSize: number;
    readonly searchLoading: boolean;
    readonly searchResults: NullableResult[];
    readonly searchResultsTotal: number;
    readonly structuredSearchResults: NullableStructuredResult[];
    readonly timelineProgram: Proto.mediaarchiver.ISearchResult | null;
}

export interface IClearAction {
    type: SearchActionTypes.CLEAR;
}

export interface ISearchAction {
    payload: ISearchParams;
    type: SearchActionTypes.SEARCH;
}

export interface ISetCurrentSearchAction {
    payload: hashedSearchParams;
    type: SearchActionTypes.SET_SEARCH_PARAMS;
}

export interface ISetLastSearchAction {
    payload: ISearchParams | null;
    type: SearchActionTypes.SET_LAST_SEARCH;
}

export interface ISetResultsAction {
    payload: [NullableResult[], number];
    type: SearchActionTypes.SET_SEARCH_RESULTS;
}

export interface ISetResultsCountAction {
    payload: number;
    type: SearchActionTypes.SET_SEARCH_RESULTS_COUNT;
}

export interface ISetResultsTotalAction {
    payload: number;
    type: SearchActionTypes.SET_SEARCH_RESULTS_TOTAL;
}

export interface ISetSearchLoadingAction {
    payload: boolean;
    type: SearchActionTypes.SET_SEARCH_LOADING;
}

export interface ISetStructuredResultsAction {
    payload: [NullableStructuredResult[], number];
    type: SearchActionTypes.SET_STRUCTURED_SEARCH_RESULTS;
}

export interface ISetTimelineProgramAction {
    payload: NullableResult;
    type: SearchActionTypes.SET_TIMELINE_PROGRAM;
}
