import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import Layout from '../../Components/Layout';
import TimelineContent from '../../Components/Timeline/Content';
import TimelineDrawer from '../../Components/Timeline/Drawer';
import { TimelineStartContent } from '../../Components/Timeline/StartContent';
import { IConnectedReduxProps } from '../../Store';
import { I18NState } from '../../Store/I18n';
import {
    hideDrawer,
    setDrawerContent,
    setDrawerTitle,
    setHasAnnotations,
    setPageContent,
    setPageTitle,
} from '../../Store/Layout';
import { ITimelineCriteriasState, updateCriterias } from '../../Store/Timeline';
import { IUserState } from '../../Store/User';
import { isNull } from '../../Utils/Various';

interface IPropsFromState {
    criterias: ITimelineCriteriasState;
    i18n: I18NState;
    user: IUserState;
}

interface IPropsFromDispatch {
    hideDrawer: typeof hideDrawer;
    setDrawerContent: typeof setDrawerContent;
    setDrawerTitle: typeof setDrawerTitle;
    setHasAnnotations: typeof setHasAnnotations;
    setPageContent: typeof setPageContent;
    setPageTitle: typeof setPageTitle;
    updateCriterias: typeof updateCriterias;
}

type AllProps = IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}> & IConnectedReduxProps;

export class TimelinePage extends React.Component<AllProps> {
    private focusTimer = 0;

    public componentDidMount(): void {
        if (this.props.user.user.share) {
            this.props.setDrawerContent(null);
            this.props.hideDrawer();
            return;
        }
        this.props.setDrawerContent(<Route component={TimelineDrawer} />);

        this.focusTimer = window.setInterval(() => {
            const elem = document.activeElement;

            if (isNull(elem)) {
                return;
            }
            switch (elem.tagName) {
                case 'INPUT':
                case 'SELECT':
                case 'TEXTAREA':
                    return;
                default:
                    /* eslint-disable @typescript-eslint/no-explicit-any */
                    if (typeof (elem as any).blur === 'function') {
                        (elem as any).blur();
                    }
                    /* eslint-enable @typescript-eslint/no-explicit-any */
                    break;
            }
        }, 500);
    }

    public componentWillUnmount(): void {
        if (this.focusTimer) {
            window.clearTimeout(this.focusTimer);
        }
    }

    public render(): React.ReactNode {
        this.props.setDrawerTitle(this.props.i18n.i18n._('Search'));
        this.props.setPageTitle(this.props.i18n.i18n._('Timeline'));
        if (this.props.criterias.active) {
            this.props.setHasAnnotations(true);
            this.props.setPageContent(<Route component={TimelineContent} />);
        } else if (!this.props.user.user.share) {
            this.props.setHasAnnotations(false);
            this.props.setPageContent(<Route component={TimelineStartContent} />);
        }
        return <Route component={Layout} />;
    }
}
