import { AMessage, IToCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';

export class TalkDeleteTalk extends AMessage implements IToCommand {
    private id: string;

    public constructor(id: string) {
        super('talkDeleteTalk');
        this.id = id;
    }

    public Send(): Promise<void> {
        return this.send(
            new mediaarchiver.WSMessage({
                TalkDeleteArg: this.id,
                Type: mediaarchiver.WSMessageType.MessageTypeTalkDelete,
            }),
        );
    }
}
