/* eslint-disable react/prop-types */
import * as FA from '@fortawesome/pro-solid-svg-icons';
import * as Icons from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemIcon, ListItemText, MenuItem, MenuList, WithStyles } from '@material-ui/core';

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import * as Proto from '../../../Protos/protos';
import { IConnectedReduxProps } from '../../../Store';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import { IIncidentState, showIncident } from '../../../Store/Incident';
import { showReportBug } from '../../../Store/Timeline';
import { IUserState } from '../../../Store/User';
import { isNullOrUndefinedOrEmptyArray } from '../../../Utils/Various';
import styles from './AccountDrawer.styles';

export interface IRouterMatchParams {
    subpage: string;
}

interface IPropsFromState {
    localeInfos: ILocaleInfos;
    i18n: I18N;
    incident: IIncidentState;
    router: RouteComponentProps<IRouterMatchParams>;
    user: IUserState;
}

interface IPropsFromDispatch {
    showReportBug: typeof showReportBug;
    showIncident: typeof showIncident;
}

type AllProps = WithStyles<typeof styles> &
    IPropsFromState &
    RouteComponentProps<{}> &
    IConnectedReduxProps &
    IPropsFromDispatch;

const AccountDrawerComponent: React.FC<AllProps> = ({
    classes,
    i18n,
    incident,
    history,
    router,
    showReportBug,
    showIncident,
    user,
}) => {
    const political =
        user.user.functionalities.indexOf(Proto.mediaarchiver.UserFunctionalities.FUNCTIONALITY_POLITICAL) !== -1;
    const links = {
        /* eslint-disable sort-keys */
        annotations: [FA.faStickyNote, i18n._('My annotations'), true],
        annotationTypes: [FA.faPenSquare, i18n._('Configure annotation types'), true],
        talks: [FA.faComments, i18n._('My political talk times'), political],
        contacts: [FA.faMailBulk, i18n._('My contacts'), true],
        // ftp: [FA.faServer, i18n._('My FTP accounts')],
        sftp: [FA.faLock, i18n._('My SFTP accounts'), true],
        options: [FA.faSlidersV, i18n._('My settings'), true],
        report: [FA.faExclamationTriangle, 'Signaler un problème', true],
        prblm: !isNullOrUndefinedOrEmptyArray(incident.incidentContent.incidentMessages)
            ? [FA.faExclamationTriangle, 'Incident en cours', true]
            : [FA.faCheck, 'Service normal', true],
        /* eslint-enable sort-keys */
    };
    return (
        <div className={classes.root}>
            <MenuList>
                {Object.keys(links).map((id, i) => {
                    /* eslint-disable @typescript-eslint/no-explicit-any */
                    if (!(links as any)[id][2]) {
                        return '';
                    }

                    const iconName = (links as any)[id][0] as FA.IconDefinition;
                    const text = (links as any)[id][1] as string;
                    /* eslint-enable @typescript-eslint/no-explicit-any */

                    return (
                        <MenuItem
                            className={classes.menuItem}
                            component='a'
                            key={`drawerContactItem_${i}`}
                            onClick={() => {
                                if (id === 'report') {
                                    showReportBug();
                                    return;
                                }
                                if (id === 'prblm') {
                                    showIncident();
                                    return;
                                }
                                history.push(`/account/${id}`);
                            }}
                            selected={router.match.params.subpage === id}
                        >
                            <ListItemIcon key={`drawerContactItemIcon_${i}`}>
                                <FontAwesomeIcon icon={iconName} />
                            </ListItemIcon>
                            <ListItemText
                                // inset
                                key={`drawerContactItemText_${i}`}
                                primary={text}
                            />
                        </MenuItem>
                    );
                })}
            </MenuList>
        </div>
    );
};

export default AccountDrawerComponent;
