import * as MD from '@material-ui/core';
import { YacastPlayer } from '@yacast/react-player';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18NState } from '../../Store/I18n';
import { setPageTitle } from '../../Store/Layout';
import { IUserState } from '../../Store/User';
import { isNull, isNullOrUndefinedOrEmptyString } from '../../Utils/Various';

import '@yacast/react-player/dist/index.css';

// import { getTheme } from '../../Themes';

interface IRouterMatchParams {
    archiveID: string;
}

interface IPropsFromState {
    i18n: I18NState;
    router: RouteComponentProps<IRouterMatchParams>;
    user: IUserState;
}

interface IPropsFromDispatch {
    setPageTitle: typeof setPageTitle;
}

// const theme = getTheme();

const styles = MD.createStyles({
    container: {
        height: '100%',
        width: '75%',
    },
});

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class ShareConsultComponent extends React.Component<AllProps> {
    public constructor(props: AllProps) {
        super(props);
    }

    public render(): React.ReactNode {
        if (isNull(this.props.user.user.shareParams)) {
            return '';
        }
        window.setTimeout(() => {
            if (isNull(this.props.user.user.shareParams)) {
                return '';
            }
            if (!isNullOrUndefinedOrEmptyString(this.props.user.user.shareParams.archiveTitle)) {
                this.props.setPageTitle(this.props.user.user.shareParams.archiveTitle);
            } else {
                this.props.setPageTitle(this.props.i18n.i18n._('Shared archive'));
            }
        }, 5000);

        return (
            <MD.Grid alignContent={'center'} container justify={'center'}>
                <MD.Grid item lg={6} md={8} xs={12}>
                    <YacastPlayer
                        aclToken={''}
                        autoplay={false}
                        apiKey={this.props.user.user.shareParams.dataToken}
                        mediaName={this.props.user.user.shareParams.mediaName}
                        mediaID={this.props.user.user.shareParams.media}
                        selectionEnd={this.props.user.user.shareParams.end}
                        selectionStart={this.props.user.user.shareParams.start}
                        shareToken={this.props.user.user.shareParams.streamerToken}
                        start={this.props.user.user.shareParams.start}
                        streamerURL={process.env.REACT_APP_STREAMER_URL}
                        timelineDuration={
                            Math.ceil(
                                (this.props.user.user.shareParams.end.getTime() -
                                    this.props.user.user.shareParams.start.getTime()) /
                                    1000,
                            ) * 3
                        }
                        timelineHaveDeclarativeEPG={true}
                        timelineHaveMusicEPG={true}
                        timelineHaveReadjustedEPG={true}
                    />
                </MD.Grid>
            </MD.Grid>
        );
    }
}

const mapStateToProps = (
    { i18n, mails, user }: IApplicationState,
    ownProps: RouteComponentProps<IRouterMatchParams>,
) => ({
    i18n,
    mails,
    router: ownProps,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setPageTitle: (title: string) => dispatch(setPageTitle(title)),
});

export const ShareConsult = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(ShareConsultComponent));
