import { action } from 'typesafe-actions';

import {
    IGetBackgroundMediasAction,
    IGetCrossBucketMediasAction,
    IGetPanelsAction,
    IGetSttMediasAction,
    IGetTalkMediasAction,
    ISearchKindsAction,
    ISearchKindsResetAction,
    IUpdateAction,
    IUpdateSTTAction,
    MediasActionTypes,
} from './Types';

export const getBackgroundMedias = (): IGetBackgroundMediasAction => action(MediasActionTypes.GET_BACKGROUND_MEDIAS);
export const getCrossBucketMedias = (): IGetCrossBucketMediasAction => action(MediasActionTypes.GET_CROSSBUCKET_MEDIAS);
export const getPanels = (): IGetPanelsAction => action(MediasActionTypes.GET_PANELS);
export const getSttMedias = (): IGetSttMediasAction => action(MediasActionTypes.GET_STT_MEDIAS);
export const getTalkMedias = (): IGetTalkMediasAction => action(MediasActionTypes.GET_TALK_MEDIAS);
export const searchKinds = (search: string): ISearchKindsAction => action(MediasActionTypes.SEARCH_KINDS, search);
export const searchKindsReset = (): ISearchKindsResetAction => action(MediasActionTypes.SEARCH_KINDS_RESET);
export const update = (): IUpdateAction => action(MediasActionTypes.UPDATE);
export const updateSTT = (): IUpdateSTTAction => action(MediasActionTypes.UPDATE_STT);
