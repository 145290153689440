import { isNullOrUndefined } from './Various';

export function capitalize(str: string): string {
    return str
        .toLocaleLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toLocaleUpperCase() + s.substring(1))
        .join(' ');
}

export function initials(str: string, uppercase = true): string {
    return (
        slugify(str, uppercase)
            .split('-')
            .map((s) => s.charAt(0))
            .join('.') + '.'
    );
}

export function slugify(str: string, uppercase = true): string {
    str = uppercase ? str.toLocaleUpperCase() : str.toLocaleLowerCase();
    return str
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

export function nl2br(str: string): string {
    return str.replace(/\n+/g, '<br />');
}

export function limitText(str: string, limit: number): string {
    if (str.length > limit) {
        return str.substr(0, limit) + '…';
    }
    return str;
}

export function pageTitle(title: string): string {
    return `MediaArchiver ›› ${title}`;
}

export function random(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function ensureString(v: any): string {
    if (typeof v === 'string') {
        return v;
    }
    if (isNullOrUndefined(v)) {
        return '';
    }
    return v.toString();
}

export function getLastChar(s: string): string {
    if (typeof s === 'string' && s.length > 0) {
        return s[s.length - 1];
    }
    return '';
}
