import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { I18N } from '../../../Store/I18n/Types';
import { ITimelinePopoversState } from '../../../Store/TimelinePopovers/Types';
import { limitText } from '../../../Utils/String';

interface IPropsFromState {
    i18n: I18N;
    timelinePopovers: ITimelinePopoversState;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

const styles = MD.createStyles({
    popover: {
        display: 'none',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 2000,
    },
    popoverContent: {
        '& h5': {
            fontSize: '1em',
        },
        flex: '1 0 auto',
        wordWrap: 'break-word',
    },
    popoverDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 340,
    },
});

export class PopoverXMLComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        return (
            <MD.Card className={this.props.classes.popover} id='xmlPopover'>
                <div className={this.props.classes.popoverDetails}>
                    <MD.CardContent className={this.props.classes.popoverContent}>
                        {this.props.timelinePopovers.popoverXML ? (
                            <MD.Typography component='h5' variant='h5'>
                                {limitText(this.props.timelinePopovers.popoverXML.title, 2000)}
                            </MD.Typography>
                        ) : (
                            '' 
                        )}
                    </MD.CardContent>
                </div>
            </MD.Card>
        );
    }
}

const mapStateToProps = ({ i18n, timelinePopovers }: IApplicationState) => ({
    i18n: i18n.i18n,
    timelinePopovers,
});

export const PopoverXML = connect(mapStateToProps)(MD.withStyles(styles)(PopoverXMLComponent));
