import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { I18N } from '../../../Store/I18n';
import { IPlayerState } from '../../../Store/Player';
import { IEPGProgramState, IEPGState, ITimelineState } from '../../../Store/Timeline';
import { setXMLPopover } from '../../../Store/TimelinePopovers';
import { IUserState } from '../../../Store/User/Types';
import { IXMLState } from '../../../Store/XML';
import { getTheme } from '../../../Themes';
import { getDateInTz } from '../../../Utils/Time';
import { isNull, isNullOrUndefined } from '../../../Utils/Various';
import styles from './XML.styles';

interface IPropsFromState {
    i18n: I18N;
    player: IPlayerState;
    timeline: ITimelineState;
    user: IUserState;
    xml: IXMLState;
}

interface IPropsFromDispatch {
    setXMLPopover: typeof setXMLPopover;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

const theme = getTheme();

export class XMLComponent extends React.Component<AllProps> {
    private container: HTMLElement | null = null;
    private file = '';
    private popover: HTMLElement | null = null;

    public constructor(props: AllProps) {
        super(props);
        if ('file' in props) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.file = (props as any).file;
        }
    }

    public render(): React.ReactNode {
        const startTS = this.props.timeline.criterias.start.getTime();
        const endTS = this.props.timeline.criterias.end.getTime();
        const tlDuration = endTS - startTS;

        if (!this.props.xml.files.has(this.file)) {
            return '';
        }

        const data = this.props.xml.files.get(this.file) as IEPGState[];

        return (
            <div className={this.props.classes.root} id='xmlContainer'>
                <div className={this.props.classes.line1}>{this.renderLine(startTS, endTS, data[0], tlDuration)}</div>
                <div className={this.props.classes.line2}>{this.renderLine(startTS, endTS, data[1], tlDuration)}</div>
            </div>
        );
    }

    private renderLine(startTS: number, maxTS: number, data: IEPGState, tlDuration: number) {
        this.popover = null;
        return Object.keys(data)
            .map((minute: string, i: number) => {
                const program = data[minute as unknown as number];
                let start = program.start.getTime();
                const end = program.start.getTime() + program.duration;
                let duration = program.duration;

                if (start >= maxTS) {
                    return null;
                }
                if (end <= startTS) {
                    return null;
                }
                if (start + duration >= maxTS) {
                    duration = maxTS - start;
                }
                if (start < startTS) {
                    start = startTS;
                }

                const widthPercent = Math.ceil((duration / tlDuration) * 1000000) / 10000;

                return (
                    <div
                        className={this.props.classes.program}
                        data-donottrackscroller={true}
                        key={`xml_elem_${i}`}
                        onClick={this.handlePopoverClick.bind(this, program)}
                        onMouseEnter={this.handlePopoverOpen.bind(this, program)}
                        onMouseLeave={this.handlePopoverClose.bind(this)}
                        style={{
                            left: `${((start - this.props.timeline.criterias.start.getTime()) / tlDuration) * 100}%`,
                            width: `${widthPercent}%`,
                            zIndex: 10 + i,
                        }}
                    >
                        {program.title}
                    </div>
                );
            })
            .filter((i) => !isNull(i));
    }

    private handlePopoverClick(prg: IEPGProgramState) {
        if (!isNullOrUndefined(this.props.player.player)) {
            this.props.player.player.start(
                prg.start,
                getDateInTz(this.props.timeline.criterias.end, this.props.timeline.criterias.timezone),
                this.props.user.user.options.indexOf(
                    mediaarchiver.UserOptions.USER_OPTION_NO_AUTOPLAY_WHEN_PLAY_CONTENT,
                ) === -1,
            );
        }
    }

    private handlePopoverClose() {
        if (isNull(this.popover)) {
            this.popover = document.getElementById('xmlPopover');
        }
        if (isNull(this.popover)) {
            return;
        }
        this.popover.style.display = 'none';
        this.props.setXMLPopover(null);
    }

    private handlePopoverOpen(prg: IEPGProgramState, ev: React.MouseEvent) {
        if (isNull(this.popover)) {
            this.popover = document.getElementById('xmlPopover');
        }
        if (isNull(this.container)) {
            this.container = document.getElementById('xmlContainer');
        }
        if (isNullOrUndefined(ev) || isNullOrUndefined(ev.target) || isNull(this.popover) || isNull(this.container)) {
            return;
        }

        this.props.setXMLPopover(prg);

        const prgBox = (ev.target as HTMLElement).getBoundingClientRect();

        this.popover.style.display = 'flex';
        if (
            ev.clientX - theme.mediaarchiver.dimensions.drawerWidth <
            (window.innerWidth - theme.mediaarchiver.dimensions.drawerWidth) / 2
        ) {
            this.popover.style.left = `${ev.clientX}px`;
            this.popover.style.right = 'inherit';
        } else {
            this.popover.style.left = 'inherit';
            this.popover.style.right = `${window.innerWidth - ev.clientX}px`;
        }
        this.popover.style.bottom = `${window.innerHeight - prgBox.top + 3}px`;
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const mapStateToProps = ({ i18n, player, timeline, user, xml }: IApplicationState, ...rest: any[]) => ({
    i18n: i18n.i18n,
    player,
    timeline,
    user,
    xml,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setXMLPopover: (prg: IEPGProgramState | null) => dispatch(setXMLPopover(prg)),
});

export const XML = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(XMLComponent));
