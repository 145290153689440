import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    contextualMenu: {
        backgroundColor: 'transparent',
        height: '100vh',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 9999,
    },
    contextualMenuList: {
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        width: 230,
    },
    line: {
        height: '15px',
        position: 'relative',
        width: '100%',
    },
    popover: {
        display: 'none',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 2000,
    },
    popoverContent: {
        '& h5': {
            fontSize: '1.2em',
        },
        '& pre': {
            fontSize: '1.4em',
        },
        '& table td': {
            fontSize: '1em',
            padding: 2,
        },

        fontFamily: theme.typography.fontFamily,
    },
    popoverDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 340,
    },
    program: {
        alignItems: 'center',
        border: '1px solid black',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '11px',
        height: '15px',
        justifyContent: 'left',
        lineHeight: '11px',
        marginTop: '3px',
        overflow: 'hidden',
        paddingLeft: '3px',
        position: 'absolute',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
    },
    programNotOwned: {
        border: '2px solid white',
    },
    root: {
        alignItems: 'flex-end',
        display: 'inline-flex',
        flexDirection: 'column',
        height: '45px',
        width: '100%',
    },
});
