import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetSFTPAccountsCount extends AMessage implements IToCommand {
    public constructor() {
        super('getSFTPAccountsCount');
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetSFTPAccountsCount,
        }));
    }
}
