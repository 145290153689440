import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { IMedia, MediaTypes } from '../../Store/Medias/Types';
import { Logger } from '../../Utils/Logger';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString, isNullOrUndefinedOrZero } from '../../Utils/Various';

export class Medias extends AMessage implements IFromCommand {
    private medias: IMedia[] = [];

    public constructor() {
        super('medias');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.MediasArg)) {
                return reject(new Error('Not a Medias message'));
            }
            const list: IMedia[] = [];

            [msg.MediasArg.Radio, msg.MediasArg.TV].forEach((l) => {
                if (!isNullOrUndefined(l)) {
                    l.forEach((p) => {
                        try {
                            const media = this.protoMediaToStoreMedia(p);
                            if (media !== null) {
                                list.push(media);
                            }
                        } catch (err) {
                            Logger.trace(err as Error, 'Invalid media');
                        }
                    });
                }
            });
            if (list.length < 1) {
                return reject(new Error('No valid media given'));
            }
            this.medias = list.sort((a, b) => {
                const i = a.name.toLocaleLowerCase();
                const j = b.name.toLocaleLowerCase();

                return i < j ? -1 : i > j ? 1 : 0;
            });
            resolve();
        });
    }

    public getMedias(): IMedia[] {
        return this.medias;
    }

    private protoMediaToStoreMedia(proto: Proto.mediaarchiver.IMedia): IMedia | null {
        if (isNullOrUndefinedOrZero(proto.ID)) {
            throw new Error('Missing media ID');
        }
        if (isNullOrUndefinedOrEmptyString(proto.Name)) {
            throw new Error('Missing media name');
        }
        if (proto.Name.substr(0, 4) === 'xls_') {
            return null;
        }
        if (proto.Type === null || proto.Type === undefined || proto.Type.ID === null) {
            throw new Error('Missing media type');
        }
        let timezone = 'Europe/Paris';
        if (!isNullOrUndefined(proto.Country) && !isNullOrUndefinedOrEmptyString(proto.Country.Timezone)) {
            timezone = proto.Country.Timezone;
        }
        return {
            id: proto.ID,
            name: proto.Name,
            timezone,
            type: proto.Type.ID === 1 ? MediaTypes.Radio : MediaTypes.TV,
            weTVID: 1,
        };
    }
}
