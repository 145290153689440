import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetAnnotations extends AMessage implements IToCommand {
    private query: Proto.mediaarchiver.IArgumentsGetAnnotations;

    public constructor(query: Proto.mediaarchiver.IArgumentsGetAnnotations) {
        super('getAnnotations');
        this.query = query;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            GetAnnotationsArg : this.query,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetAnnotations,
        }));
    }
}
