import * as Proto from '../Protos/protos';
import { IEPGProgramState, IEPGState } from '../Store/Timeline';
import { isNullOrUndefined } from './Various';

const deserializeBoolean = (value: string) => value === 'true';
const deserializeEPGTypes = (value: string) => JSON.parse(value);
const deserializeString = (value: string) => value;
const deserializeNumber = (value: string) => Number.parseFloat(value);
const deserializeXML = (value: string) => JSON.parse(value, reviver);

const serializeBoolean = (value: boolean) => (value ? 'true' : 'false');
const serializeEPGTypes = (value: Proto.mediaarchiver.EPGType[]) => JSON.stringify(value);
const serializeString = (value: string) => value;
const serializeNumber = (value: number) => value.toString();
const serializeXML = (originalValue: Map<string, IEPGState[]>) => JSON.stringify(originalValue, replacer);

function replacer(key: string, value: Object) {
    if (value instanceof Map) {
        const newarray = Array.from(value.entries());
        const newvalue = newarray.map((element) => {
            const iepg = element[1][0];
            const iepgobj = Object.keys(iepg).map((value) => {
                return { minutes: value, program: iepg[value as unknown as number] };
            });
            return {
                epg: JSON.stringify(iepgobj),
                key: element[0],
            };
        });
        return newvalue;
    } else {
        return value;
    }
}

function reviver(
    key: string,
    value: { key: string; epg: string } | string | Array<Map<string, IEPGState[]> | Map<string, Object>>,
) {
    if (
        value !== null &&
        typeof value === 'object' &&
        'key' in value &&
        'epg' in value &&
        typeof value.epg === 'string' &&
        typeof value.key === 'string'
    ) {
        const epg = JSON.parse(value.epg);
        if (epg instanceof Array) {
            //const iepg = epg.map((x) => Object.assign({}, { [x.minutes]: x.program }));
            //const iepg = Object.assign({}, ...epg.map((x) => ({ [x.minutes]: x.program })));

            //const iepg = epg.map((v) => { return {[v.minutes]: v.program} });

            const epgs = epg.map((e): IEPGProgramState => {
                return {
                    category: e.program.category,
                    categoryText: e.program.categoryText,
                    duration: e.program.duration,
                    metas: {},
                    realDuration: e.program.realDuration,
                    realStart: new Date(e.program.realStart),
                    start: new Date(e.program.start),
                    subtitle: '',
                    synopsis: '',
                    title: e.program.title,
                    vignette: '',
                };
            });
            const res: IEPGState[] = [[], []];

            epgs.forEach((i) => {
                if (
                    !isNullOrUndefined(res[0]) &&
                    Object.keys(res[0]).some((key: string) => {
                        const m = parseInt(key, 10);
                        return (
                            !isNaN(m) &&
                            i.start.getTime() >= res[0][m].start.getTime() &&
                            i.start.getTime() < res[0][m].start.getTime() + res[0][m].duration
                        );
                    })
                ) {
                    res[1][i.start.getTime()] = i;
                } else {
                    res[0][i.start.getTime()] = i;
                }
            });

            const map = new Map();
            map.set(value.key, res);
            return map;
        }
    }
    if (value instanceof Array) {
        return value[0];
    }
    return value;
}

export interface IKeyValueStorage {
    analyticsAllowed: boolean;
    analyticsAsked: boolean;
    audienceMode: string;
    audienceWithVOSDAL: boolean;
    bug20220524: boolean;
    calendarDuration: number;
    consentGiven: boolean;
    expiringToken: string;
    expiringTokenExpires: number;
    incidentFirstTimeDisplayed: boolean;
    lastAnnotationType: string;
    lastBugReportedMail: string;
    lastBugReportedName: string;
    lastBugReportedPhone: string;
    lastBugReportedSociety: string;
    lastContentTypesRadio: Proto.mediaarchiver.EPGType[];
    lastContentTypesTV: Proto.mediaarchiver.EPGType[];
    lastMediaRadio: number;
    lastMediaRadioName: string;
    lastMediaTV: number;
    lastMediaTVName: string;
    lastMediaType: number;
    lastTalkProgramType: number;
    lastTalkStudy: number;
    lastTalkStudyType: number;
    lastTimelineDate: number;
    locale: string;
    stepBackward: number;
    stepFastBackward: number;
    stepFastForward: number;
    stepForward: number;
    storageBugSeen: boolean;
    volume: number;
    volumeBeforeMute: number;
    xmlEPG1: Map<string, IEPGState[]>;
    xmlEPG2: Map<string, IEPGState[]>;
    xmlEPG3: Map<string, IEPGState[]>;
}

const keyValueStorageDeserializers: { [key in KeyValueStorageKey]: (value: string) => IKeyValueStorage[key] } = {
    analyticsAllowed: deserializeBoolean,
    analyticsAsked: deserializeBoolean,
    audienceMode: deserializeString,
    audienceWithVOSDAL: deserializeBoolean,
    bug20220524: deserializeBoolean,
    calendarDuration: deserializeNumber,
    consentGiven: deserializeBoolean,
    expiringToken: deserializeString,
    expiringTokenExpires: deserializeNumber,
    incidentFirstTimeDisplayed: deserializeBoolean,
    lastAnnotationType: deserializeString,
    lastBugReportedMail: deserializeString,
    lastBugReportedName: deserializeString,
    lastBugReportedPhone: deserializeString,
    lastBugReportedSociety: deserializeString,
    lastContentTypesRadio: deserializeEPGTypes,
    lastContentTypesTV: deserializeEPGTypes,
    lastMediaRadio: deserializeNumber,
    lastMediaRadioName: deserializeString,
    lastMediaTV: deserializeNumber,
    lastMediaTVName: deserializeString,
    lastMediaType: deserializeNumber,
    lastTalkProgramType: deserializeNumber,
    lastTalkStudy: deserializeNumber,
    lastTalkStudyType: deserializeNumber,
    lastTimelineDate: deserializeNumber,
    locale: deserializeString,
    stepBackward: deserializeNumber,
    stepFastBackward: deserializeNumber,
    stepFastForward: deserializeNumber,
    stepForward: deserializeNumber,
    storageBugSeen: deserializeBoolean,
    volume: deserializeNumber,
    volumeBeforeMute: deserializeNumber,
    xmlEPG1: deserializeXML,
    xmlEPG2: deserializeXML,
    xmlEPG3: deserializeXML,
};

const keyValueStorageSerializers: { [key in KeyValueStorageKey]: (value: IKeyValueStorage[key]) => string } = {
    analyticsAllowed: serializeBoolean,
    analyticsAsked: serializeBoolean,
    audienceMode: serializeString,
    audienceWithVOSDAL: serializeBoolean,
    bug20220524: serializeBoolean,
    calendarDuration: serializeNumber,
    consentGiven: serializeBoolean,
    expiringToken: serializeString,
    expiringTokenExpires: serializeNumber,
    incidentFirstTimeDisplayed: serializeBoolean,
    lastAnnotationType: serializeString,
    lastBugReportedMail: serializeString,
    lastBugReportedName: serializeString,
    lastBugReportedPhone: serializeString,
    lastBugReportedSociety: serializeString,
    lastContentTypesRadio: serializeEPGTypes,
    lastContentTypesTV: serializeEPGTypes,
    lastMediaRadio: serializeNumber,
    lastMediaRadioName: serializeString,
    lastMediaTV: serializeNumber,
    lastMediaTVName: serializeString,
    lastMediaType: serializeNumber,
    lastTalkProgramType: serializeNumber,
    lastTalkStudy: serializeNumber,
    lastTalkStudyType: serializeNumber,
    lastTimelineDate: serializeNumber,
    locale: serializeString,
    stepBackward: serializeNumber,
    stepFastBackward: serializeNumber,
    stepFastForward: serializeNumber,
    stepForward: serializeNumber,
    storageBugSeen: serializeBoolean,
    volume: serializeNumber,
    volumeBeforeMute: serializeNumber,
    xmlEPG1: serializeXML,
    xmlEPG2: serializeXML,
    xmlEPG3: serializeXML,
};

export type KeyValueStorageKey = keyof IKeyValueStorage;

export interface IUserStorage {
    del<T extends KeyValueStorageKey>(key: T): void;
    get<T extends KeyValueStorageKey>(key: T, defaultValue: IKeyValueStorage[T]): IKeyValueStorage[T];
    reset(): void;
    set<T extends KeyValueStorageKey>(key: T, value: IKeyValueStorage[T]): void;
}

export const TypedStorage: IUserStorage = {
    del: (key) => {
        localStorage.removeItem(key);
    },
    get: (key, defaultValue) => {
        const serialized = window.localStorage.getItem(key);

        if (serialized !== null) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return keyValueStorageDeserializers[key](serialized) as any;
        }
        return defaultValue;
    },
    reset: () => {
        localStorage.clear();
    },
    set: (key, value) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const serializer = keyValueStorageSerializers[key] as (t: any) => string;
        localStorage.setItem(key, serializer(value));
    },
};
