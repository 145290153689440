import { withStyles } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../Store';
import {
    setDrawerContent,
    setDrawerTitle,
    setHasAnnotations,
    setPageContent,
    setPageTitle,
} from '../../Store/Layout';
import { AccountPage, IRouterMatchParams } from './Account';
import styles from './styles';

const mapStateToProps = (
    state: IApplicationState,
    ownProps: RouteComponentProps<IRouterMatchParams>,
) => ({
    i18n: state.i18n.i18n,
    router: ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setDrawerContent: (content: React.ReactNode) =>
        dispatch(setDrawerContent(content)),
    setDrawerTitle: (title: string) => dispatch(setDrawerTitle(title)),
    setHasAnnotations: (has: boolean) => dispatch(setHasAnnotations(has)),
    setPageContent: (content: React.ReactNode) =>
        dispatch(setPageContent(content)),
    setPageTitle: (title: string) => dispatch(setPageTitle(title)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(AccountPage));
