import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../Store';
import {
    hideDrawer,
    setDrawerContent,
    setDrawerTitle,
    setHasAnnotations,
    setPageContent,
    setPageTitle,
} from '../../Store/Layout';
import { setEndSelection, setStartSelection } from '../../Store/Player';
import { ITimelineCriteriasState, updateCriterias } from '../../Store/Timeline';
import { TimelinePage } from './TimelinePage';

const mapStateToProps = ({ i18n, timeline, user }: IApplicationState) => ({
    criterias: timeline.criterias,
    i18n,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    hideDrawer: () => dispatch(hideDrawer()),
    setDrawerContent: (content: React.ReactNode) =>
        dispatch(setDrawerContent(content)),
    setDrawerTitle: (title: string) => dispatch(setDrawerTitle(title)),
    setEndSelection: (time: Date | null) => dispatch(setEndSelection(time)),
    setHasAnnotations: (has: boolean) => dispatch(setHasAnnotations(has)),
    setPageContent: (content: React.ReactNode) =>
        dispatch(setPageContent(content)),
    setPageTitle: (title: string) => dispatch(setPageTitle(title)),
    setStartSelection: (time: Date | null) => dispatch(setStartSelection(time)),
    updateCriterias: (criterias: ITimelineCriteriasState) =>
        dispatch(updateCriterias(criterias)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TimelinePage);
