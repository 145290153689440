import { number } from 'prop-types';
import * as Proto from '../../Protos/protos';

export enum SearchSTTActionTypes {
    CLEAR = '@@searchSTT/CLEAR',
    CLEAR_REQUESTED_PARAMS = '@@searchSTT/CLEAR_STT_REQUESTED_PARAMS',

    SEARCH_SPEECHES = '@@searchSTT/SEARCH_SPEECHES',
    SEARCH_SPEECHES_DATE = '@@searchSTT/SEARCH_SPEECHES_DATE',

    SET_CURRENTBEGIN = '@@searchSTT/SET_CURRENTBEGIN',
    SET_CURRENTEND = '@@searchSTT/SET_CURRENTEND',
    SET_CURRENTMEDIAID = '@@searchSTT/SET_CURRENTMEDIAID',
    SET_CURRENTMEDIANAME = '@@searchSTT/SET_CURRENTMEDIANAME',
    SET_CURRENTSENTENCE = '@@searchSTT/SET_CURRENTSENTENCE',
    SET_CURRENTSTART = '@@searchSTT/SET_CURRENTSTART',
    SET_CURRENTTIMESTAMPEDWORDS = '@@searchSTT/SET_CURRENTTIMESTAMPEDWORDS',
    SET_CURRENTWORDS = '@@searchSTT/SET_CURRENTWORDS',
    SET_CURRENTWORDSUPDATED = '@@searchSTT/SET_CURRENTWORDSUPDATED',
    SET_CURRENTWORDUID = '@@searchSTT/SET_CURRENTWORDUID',

    SET_LAST_SEARCH_SPEECHES = '@@searchSTT/SET_LAST_SEARCH_SPEECHES',

    SET_NEWWORDS = '@@searchSTT/SET_NEWWORDS',

    SET_REQUESTED_CURRENTTIME = '@@searchSTT/SET_REQUESTED_CURRENTTIME',
    SET_REQUESTED_END = '@@searchSTT/SET_REQUESTED_END',
    SET_REQUESTED_MEDIA = '@@searchSTT/SET_REQUESTED_MEDIA',
    SET_REQUESTED_START = '@@searchSTT/SET_REQUESTED_START',

    SET_SEARCH_SPEECHES_LOADING = '@@searchSTT/SET_SEARCH_SPEECHES_LOADING',
    SET_SEARCH_SPEECHES_PARAMS = '@@searchSTT/SET_SEARCH_SPEECHES_PARAMS',
    SET_SEARCH_SPEECHES_RESULTS = '@@searchSTT/SET_SEARCH_SPEECHES_RESULTS',

    SET_TIMELINE_PROGRAM = '@@searchSTT/SET_TIMELINE_PROGRAM',
}

export type NullableSpeechResult = Proto.mediaarchiver.ISearchSpeechResult | null;
export type NullableWordResult = Proto.mediaarchiver.ISearchWordResult | null;

export interface IClearAction {
    type: SearchSTTActionTypes.CLEAR;
}

export interface ISearchSpeechesAction {
    payload: ISearchSTTParams;
    type: SearchSTTActionTypes.SEARCH_SPEECHES;
}
export interface ISearchSpeechesDateAction {
    payload: ISearchSTTDateParams;
    type: SearchSTTActionTypes.SEARCH_SPEECHES_DATE;
}

export interface ISearchSTTDateParams {
    end: Date;
    medias: number;
    start: Date;
}
export interface ISearchSTTParams {
    end: Date;
    logic: Proto.mediaarchiver.SearchSpeechesLogic;
    medias: number[];
    page: number;
    pageSize: number;
    start: Date;
    text: string;
}

export interface ISearchSTTState {
    readonly currentBegin: number;
    readonly currentEnd: number;
    readonly currentMediaID: number;
    readonly currentMediaName: string;
    readonly currentSentence: React.ReactNode[];
    readonly currentStart: number;
    readonly currentTimestampedWords: Map<number, string>;
    readonly currentWords: Proto.mediaarchiver.ISearchSpeechesWord[];
    readonly currentWordsUpdated: boolean;
    readonly currentWordUID: string;
    readonly initialState: boolean;
    readonly lastSearchSpeeches: ISearchSTTParams | null;
    readonly lastSearchSpeechesDate: ISearchSTTDateParams | null;
    readonly newWords: Proto.mediaarchiver.ISearchSpeechesWord[];
    readonly page: number;
    readonly pageSize: number;
    readonly playerLoading: boolean;
    readonly requestedCurrent: Date;
    readonly requestedEnd: Date;
    readonly requestedMedia: number;
    readonly requestedStart: Date;
    readonly searchLoading: boolean;
    readonly searchSpeechesResults: NullableSpeechResult[];
    readonly searchSpeechesResultsTotal: number;
    readonly searchWordsResults: NullableWordResult[];
    readonly selectedStart: number;
    readonly timelineProgram: NullableSpeechResult;
}

export interface ISetCurrentbegin {
    payload: number;
    type: SearchSTTActionTypes.SET_CURRENTBEGIN;
}
export interface ISetCurrentend {
    payload: number;
    type: SearchSTTActionTypes.SET_CURRENTEND;
}
export interface ISetCurrentmediaid {
    payload: number;
    type: SearchSTTActionTypes.SET_CURRENTMEDIAID;
}
export interface ISetCurrentmedianame {
    payload: string;
    type: SearchSTTActionTypes.SET_CURRENTMEDIANAME;
}
export interface ISetCurrentsentence {
    payload: React.ReactNode[];
    type: SearchSTTActionTypes.SET_CURRENTSENTENCE;
}
export interface ISetCurrentstart {
    payload: number;
    type: SearchSTTActionTypes.SET_CURRENTSTART;
}
export interface ISetCurrenttimestampedwords {
    payload: Map<number, string>;
    type: SearchSTTActionTypes.SET_CURRENTTIMESTAMPEDWORDS;
}
export interface ISetCurrentwords {
    payload: Proto.mediaarchiver.ISearchSpeechesWord[];
    type: SearchSTTActionTypes.SET_CURRENTWORDS;
}
export interface ISetCurrentwordsUpdated {
    payload: boolean;
    type: SearchSTTActionTypes.SET_CURRENTWORDSUPDATED;
}
export interface ISetCurrentworduid {
    payload: string;
    type: SearchSTTActionTypes.SET_CURRENTWORDUID;
}
export interface ISetRequestedCurrent {
    payload: Date;
    type: SearchSTTActionTypes.SET_REQUESTED_CURRENTTIME;
}
export interface ISetRequestedEnd {
    payload: Date;
    type: SearchSTTActionTypes.SET_REQUESTED_END;
}
export interface ISetRequestedStart {
    payload: Date;
    type: SearchSTTActionTypes.SET_REQUESTED_START;
}
export interface ISetRequestedMedia {
    payload: number;
    type: SearchSTTActionTypes.SET_REQUESTED_MEDIA;
}
export interface ISetlastSearchSpeechesAction {
    payload: ISearchSTTParams | null;
    type: SearchSTTActionTypes.SET_LAST_SEARCH_SPEECHES;
}

export interface ISetNewwords {
    type: SearchSTTActionTypes.SET_NEWWORDS;
}

export interface ISetSearchSpeechesLoadingAction {
    payload: boolean;
    type: SearchSTTActionTypes.SET_SEARCH_SPEECHES_LOADING;
}
export interface ISetSearchSpeechesResultsAction {
    payload: [NullableSpeechResult[], number];
    type: SearchSTTActionTypes.SET_SEARCH_SPEECHES_RESULTS;
}

export interface ISetTimelineProgramAction {
    payload: NullableSpeechResult;
    type: SearchSTTActionTypes.SET_TIMELINE_PROGRAM;
}
