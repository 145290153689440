import { Store } from 'redux';
import { action } from 'typesafe-actions';
import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { Review } from '../../Backend/Commands/Review';
import { Reviews } from '../../Backend/Commands/Reviews';
import { mediaarchiver } from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import {
    ICreateAction,
    IEditAction,
    IEditArchiveAction,
    IGetAction,
    IGetByIdAction,
    IRemoveAction,
    IReviewFormErrors,
    ISetErrorsAction,
    ISetOpSuccessAction,
    ISetProgressAction,
    ReviewsActionTypes,
} from './Types';

export const create = (specs: mediaarchiver.IReview): ICreateAction => action(ReviewsActionTypes.CREATE, specs);
export const edit = (specs: mediaarchiver.IReview): IEditAction => action(ReviewsActionTypes.EDIT, specs);
export const editArchive = (specs: mediaarchiver.IReview): IEditArchiveAction => action(ReviewsActionTypes.EDIT, specs);
export const get = (specs: mediaarchiver.IArgumentsGetReviews): IGetAction => action(ReviewsActionTypes.GET, specs);
export const getById = (specs: string): IGetByIdAction => action(ReviewsActionTypes.GET_BY_ID, specs);
export const remove = (specs: string): IRemoveAction => action(ReviewsActionTypes.DELETE, specs);
export const setErrors = (errors: IReviewFormErrors): ISetErrorsAction => action(ReviewsActionTypes.SET_ERRORS, errors);
export const setOpSuccess = (): ISetOpSuccessAction => action(ReviewsActionTypes.OP_SUCCESS);
export const setProgress = (toggle: boolean): ISetProgressAction => action(ReviewsActionTypes.SET_OP_PROGRESS, toggle);

Backend.getInstance()
    .Bind('review', (incoming: IFromCommand) => {
        const cmd = incoming as Review;

        Logger.trace({ count: cmd }, 'Received review');

        (store as Store).dispatch(action(ReviewsActionTypes.SET_REVIEW, cmd.review));
    })
    .Bind('reviews', (incoming: IFromCommand) => {
        const cmd = incoming as Reviews;

        Logger.trace({ count: cmd }, 'Received reviews');

        (store as Store).dispatch(action(ReviewsActionTypes.SET_SEARCH_RESULT, cmd.reviews));
    });
