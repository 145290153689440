import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Dispatch } from 'redux';

import ExtractionDialog from '../../Components/Timeline/ExtractionDialog';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N } from '../../Store/I18n';
import { ITimelineState, moveStartContentStep } from '../../Store/Timeline';
// import { StorageBugDialog } from './Content/StorageBugDialog';

import { getTheme } from '../../Themes';

interface IPropsFromState {
    i18n: I18N;
    timeline: ITimelineState;
}

interface IPropsFromDispatch {
    moveStartContentStep: typeof moveStartContentStep;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & IPropsFromDispatch & IConnectedReduxProps;

const theme = getTheme();

const styles = MD.createStyles({
    actionsContainer: {
        flex: '0 0 100%',
        marginBottom: theme.spacing(1) * 2,
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    resetContainer: {
        padding: theme.spacing(1) * 3,
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        padding: theme.spacing(1),
        paddingBottom: 0,
        paddingTop: 0,
        top: 0,
    },
    tutorial: {
        minWidth: '100%',
        width: '100%',
    },
});

export class TimelineStartContentComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        return (
            <MD.Grid container={true} className={this.props.classes.root} spacing={0}>
                {/* <Route component={StorageBugDialog} /> */}
                <MD.Grid item={true} xs={8} md={6}>
                    <MD.Stepper
                        activeStep={this.props.timeline.startContentStep}
                        className={this.props.classes.tutorial}
                        orientation='vertical'
                    >
                        {this.getSteps().map((label: string, index: number) => (
                            <MD.Step key={label}>
                                <MD.StepLabel>{label}</MD.StepLabel>
                                <MD.StepContent>
                                    <MD.Typography>{this.getStepContent(index)}</MD.Typography>
                                    <div className={this.props.classes.actionsContainer}>
                                        <div>
                                            {this.props.timeline.startContentStep !== 0 && (
                                                <MD.Button
                                                    disabled={this.props.timeline.startContentStep === 0}
                                                    onClick={this.handleBack.bind(this)}
                                                    className={this.props.classes.button}
                                                >
                                                    {this.props.i18n._('Back')}
                                                </MD.Button>
                                            )}
                                            {this.props.timeline.startContentStep !== this.getSteps().length - 1 && (
                                                <MD.Button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={this.handleNext.bind(this)}
                                                    className={this.props.classes.button}
                                                >
                                                    {this.props.i18n._('Next')}
                                                </MD.Button>
                                            )}
                                        </div>
                                    </div>
                                </MD.StepContent>
                            </MD.Step>
                        ))}
                    </MD.Stepper>
                </MD.Grid>
                <Route component={ExtractionDialog} />
            </MD.Grid>
        );
    }

    private getSteps() {
        return [
            /*0*/ this.props.i18n._('Welcome to Mediaarchiver'),
            /*1*/ this.props.i18n._('Choose a media type'),
            /*2*/ this.props.i18n._('Choose media'),
            /*3*/ this.props.i18n._('Choose timeline contents'),
            /*4*/ this.props.i18n._("Set timeline's start and duration"),
            /*5*/ this.props.i18n._('All done !'),
        ];
    }

    private getStepContent(step: number) {
        switch (step) {
            case 0:
                return this.props.i18n._(
                    'We will guide you through this simple tutorial to setup a timeline, please click on the Next button below. On each step, you will see the corresponding part of the drawer blinking.',
                );
            case 1:
                return this.props.i18n._(
                    'Choose the media type (TV or Radio) by clicking on one of the two tabs in the upper-left part of the drawer.',
                );
            case 2:
                return this.props.i18n._(
                    'Choose the media you want to generate a timeline for in the "media" select box in the drawer.',
                );
            case 3:
                return this.props.i18n._('Now, select additional informations you want to see in the timeline.');
            case 4:
                return this.props.i18n._(
                    'Finally, select the date and hour of your choice and pick a timeline duration.',
                );
            case 5:
                return this.props.i18n._(
                    "You're all done, you can now click on this button to generate your timeline.",
                );
            default:
                return 'Unknown step';
        }
    }

    private handleNext() {
        this.props.moveStartContentStep(1);
    }

    private handleBack() {
        this.props.moveStartContentStep(-1);
    }

    private handleReset() {
        this.props.moveStartContentStep(-this.props.timeline.startContentStep);
    }
}

const mapStateToProps = ({ i18n, timeline }: IApplicationState) => ({
    i18n: i18n.i18n,
    timeline,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    moveStartContentStep: (delta: number) => dispatch(moveStartContentStep(delta)),
});

export const TimelineStartContent = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(TimelineStartContentComponent));
