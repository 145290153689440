import * as Proto from '../../Protos/protos';
import { ITimelineCriteriasState } from '../Timeline/Types';

export enum UserActionTypes {
    LOGGED_OUT = '@@users/LOGGED_OUT',
    LOGIN = '@@users/LOGIN',
    LOGIN_ERROR = '@@users/LOGIN_ERROR',
    LOGIN_SUCCESS = '@@users/LOGIN_SUCCESS',
    LOGOUT = '@@users/LOG_OUT',
    SET_ALLOWED_EPG_TYPES = '@@users/SET_ALLOWED_EPG_TYPES',
    SET_USER_OPTIONS = '@@users/SET_USER_OPTIONS',
    STORE_USER_OPTIONS = '@@users/STORE_USER_OPTIONS',
}

export interface IUser {
    allowedEPGs: Proto.mediaarchiver.EPGType[];
    email: string;
    firstName: string;
    functionalities: Proto.mediaarchiver.UserFunctionalities[];
    getFullName: () => string;
    getShortName: () => string;
    group: number;
    id: number;
    lastName: string;
    loggedIn: boolean;
    login: string;
    options: Proto.mediaarchiver.UserOptions[];
    session: string;
    share: boolean;
    phone: string;
    groupName: string;

    shareCount: number;
    shareParams: ITimelineCriteriasState | null;
}

export interface ILoginData {
    login: string;
    password: string;
    remember: boolean;
}

export interface IUserState {
    readonly error?: Error;
    readonly loading: boolean;
    readonly loggedIn: boolean;
    readonly opSuccessEventElement: HTMLElement;
    readonly user: IUser;
}

export interface ILoginAction {
    payload: ILoginData;
    type: typeof UserActionTypes.LOGIN;
}

export interface ILoginSuccessAction {
    payload: IUser;
    type: typeof UserActionTypes.LOGIN_SUCCESS;
}

export interface ILoginErrorAction {
    payload: Error;
    type: typeof UserActionTypes.LOGIN_ERROR;
}

export interface ILogoutAction {
    type: typeof UserActionTypes.LOGOUT;
}

export interface ILoggedOutAction {
    type: typeof UserActionTypes.LOGGED_OUT;
}

export interface ISetUserOptionsAction {
    payload: Proto.mediaarchiver.UserOptions[];
    type: typeof UserActionTypes.SET_USER_OPTIONS;
}

export interface IStoreUserOptionsAction {
    payload: Proto.mediaarchiver.UserOptions[];
    type: typeof UserActionTypes.STORE_USER_OPTIONS;
}
