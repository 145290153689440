import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefined } from '../../Utils/Various';

export class TalkProgramTypes extends AMessage implements IFromCommand {
    private parsedTypes: Proto.mediaarchiver.ITalkProgramType[] | null = null;

    public constructor() {
        super('talkProgramTypes');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.TalkProgramTypesArgs) || !Array.isArray(msg.TalkProgramTypesArgs.Types)) {
                return reject(new Error('Not a TalkProgramTypes message'));
            }

            this.parsedTypes = msg.TalkProgramTypesArgs.Types;
            resolve();
        });
    }

    public get types(): Proto.mediaarchiver.ITalkProgramType[] {
        if (isNullOrUndefined(this.parsedTypes)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedTypes;
    }
}
