import { Store } from 'redux';
import { action } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { FTPAccount } from '../../Backend/Commands/FTPAccount';
import { FTPAccounts } from '../../Backend/Commands/FTPAccounts';
import { FTPAccountsCount } from '../../Backend/Commands/FTPAccountsCount';
import * as Proto from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import {
    ICreateAction,
    IDeleteAccountAction,
    IDeletedAccountAction,
    IEditAction,
    FTPAccountActionTypes,
    IGetAction,
    IGetCountAction,
    IFTPAccountFormErrors,
    ISetErrorsAction,
    ISetOpSuccessAction,
    ISetProgressAction,
} from './Types';

export const create = (account: Proto.mediaarchiver.IFTPAccount): ICreateAction =>
    action(FTPAccountActionTypes.CREATE, account);
export const deleteAccount = (id: string): IDeleteAccountAction => action(FTPAccountActionTypes.DELETE, id);
export const deletedAccount = (id: string): IDeletedAccountAction => action(FTPAccountActionTypes.DELETED_ACCOUNT, id);
export const edit = (account: Proto.mediaarchiver.IFTPAccount): IEditAction =>
    action(FTPAccountActionTypes.EDIT, account);
export const get = (offset = 0, limit = 100): IGetAction => action(FTPAccountActionTypes.GET, { limit, offset });
export const getCount = (): IGetCountAction => action(FTPAccountActionTypes.GET_COUNT);
export const setErrors = (errors: IFTPAccountFormErrors): ISetErrorsAction =>
    action(FTPAccountActionTypes.SET_ERRORS, errors);
export const setOpSuccess = (): ISetOpSuccessAction => action(FTPAccountActionTypes.OP_SUCCESS);
export const setProgress = (toggle: boolean): ISetProgressAction =>
    action(FTPAccountActionTypes.SET_OP_PROGRESS, toggle);

Backend.getInstance()
    .Bind('ftpAccountsCount', (incoming: IFromCommand) => {
        const cmd = incoming as FTPAccountsCount;

        Logger.trace({ count: cmd.count }, 'Received ftp accounts count');
        (store as Store).dispatch(action(FTPAccountActionTypes.SET_ACCOUNTS_COUNT, cmd.count));
    })
    .Bind('ftpAccounts', (incoming: IFromCommand) => {
        const cmd = incoming as FTPAccounts;

        Logger.trace({ cmd }, 'Received ftp accounts');
        (store as Store).dispatch(action(FTPAccountActionTypes.SET_ACCOUNTS, cmd));
    })
    .Bind('ftpAccount', (incoming: IFromCommand) => {
        const cmd = incoming as FTPAccount;

        Logger.trace({ cmd }, 'Received ftp account');
        (store as Store).dispatch(action(FTPAccountActionTypes.SET_ACCOUNT, cmd.account));
    });
