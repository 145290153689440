import { effects } from 'redux-saga';

import { IApplicationState } from '..';
import { DeleteMultipleTalks } from '../../Backend/Commands/DeleteMultipleTalks';
import { ExportTalks } from '../../Backend/Commands/ExportTalks';
import { GetTalks } from '../../Backend/Commands/GetTalks';
import { TalkAddTalk } from '../../Backend/Commands/TalkAddTalk';
import { TalkAutocompleteName } from '../../Backend/Commands/TalkAutocompleteName';
import { TalkEditTalk } from '../../Backend/Commands/TalkEditTalk';
import { TalkGetAllGroups } from '../../Backend/Commands/TalkGetAllGroups';
import { TalkGetAllRegims } from '../../Backend/Commands/TalkGetAllRegims';
import { TalkGetAllTitles } from '../../Backend/Commands/TalkGetAllTitles';
import { TalkGetDistricts } from '../../Backend/Commands/TalkGetDistricts';
import { TalkGetPerson } from '../../Backend/Commands/TalkGetPerson';
import { TalkGetProgramTypes } from '../../Backend/Commands/TalkGetProgramTypes';
import { TalkGetStudies } from '../../Backend/Commands/TalkGetStudies';
import { TalkDeleteTalk } from '../../Backend/Commands/TalkDeleteTalk';
import { mediaarchiver } from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { isNullOrUndefinedOrEmptyArray } from '../../Utils/Various';
import { I18N } from '../I18n/Types';
import { refreshTimeline, setEndSelection, setStartSelection } from '../Player/Actions';
import { setSnackMessage } from '../Layout/Actions';
import {
    addTalk,
    deleteMultipleTalks,
    deleteTalk,
    editTalk,
    exportTalks,
    getDistricts,
    getPerson,
    getSugg,
    hideCopyDialog,
    hideDialog,
    search,
    setCopiedTalks,
    setLastPerson,
    setLastSearch,
    setLoading,
    setPersonLoading,
    setSearchLoading,
    setSuggLoading,
} from './Actions';
import { ITalkLastPerson, TalkActionTypes } from './Types';

const getI18n = (state: IApplicationState): I18N => state.i18n.i18n;

function* handleAddTalk(action: ReturnType<typeof addTalk>) {
    const talk = action.payload;
    const cmd = new TalkAddTalk(action.payload);
    const i18n = (yield effects.select(getI18n)) as I18N;

    if (
        !isNullOrUndefinedOrEmptyArray(talk.Speakers) &&
        talk.TalkType === mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED
    ) {
        const speaker = talk.Speakers[0];
        const lastPerson: ITalkLastPerson = {
            group: speaker.Group || 0,
            groupColor: speaker.GroupColor || '',
            personID: speaker.ID || 0,
            personName: speaker.Name || '',
            regim: speaker.Regim || 0,
            situation: speaker.Situation || 0,
            study: talk.Study || 0,
            talkType: talk.TalkType || 0,
            timeType: talk.TimeType || 0,
            title: speaker.Title || '',
        };
        yield effects.put(setLastPerson(lastPerson));
    }

    try {
        yield effects.put(setLoading(true));
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setLoading(false));
        yield effects.put(hideDialog());
        yield effects.put(setCopiedTalks([]));
        yield effects.put(hideCopyDialog());
        yield effects.put(setStartSelection(null));
        yield effects.put(setEndSelection(null));
        yield effects.put(refreshTimeline());
        yield effects.put(setSnackMessage(i18n._('Political talk time added !')));
    } catch (err) {
        yield effects.put(setLoading(false));
        yield effects.put(setSnackMessage(i18n._('Operation failed')));
        Logger.warn(err as string, 'Failed to add talk');
    }
}

function* handleDeleteMultiple(action: ReturnType<typeof deleteMultipleTalks>) {
    const cmd = new DeleteMultipleTalks(action.payload);
    const i18n = (yield effects.select(getI18n)) as I18N;

    try {
        yield effects.put(setSearchLoading(true));
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setSearchLoading(false));
        yield effects.put(setSnackMessage(i18n._('Political talk times deleted !')));
    } catch (err) {
        yield effects.put(setSearchLoading(false));
        yield effects.put(setSnackMessage(i18n._('Operation failed')));
        Logger.warn(err as string, 'Failed to delete talks');
    }
}

function* handleDeleteTalk(action: ReturnType<typeof deleteTalk>) {
    const cmd = new TalkDeleteTalk(action.payload);
    const i18n = (yield effects.select(getI18n)) as I18N;

    try {
        yield effects.put(setLoading(true));
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setLoading(false));
        yield effects.put(refreshTimeline());
        yield effects.put(setSnackMessage(i18n._('Political talk time deleted !')));
    } catch (err) {
        yield effects.put(setLoading(false));
        yield effects.put(setSnackMessage(i18n._('Operation failed')));
        Logger.warn(err as string, 'Failed to delete talk');
    }
}

function* handleEditTalk(action: ReturnType<typeof editTalk>) {
    const cmd = new TalkEditTalk(action.payload);
    const i18n = (yield effects.select(getI18n)) as I18N;

    try {
        yield effects.put(setLoading(true));
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setLoading(false));
        yield effects.put(hideDialog());
        yield effects.put(setStartSelection(null));
        yield effects.put(setEndSelection(null));
        yield effects.put(refreshTimeline());
        yield effects.put(setSnackMessage(i18n._('Political talk time edited !')));
    } catch (err) {
        yield effects.put(setLoading(false));
        yield effects.put(setSnackMessage(i18n._('Operation failed')));
        Logger.warn(err as string, 'Failed to edit talk');
    }
}

function* handleExportTalks(action: ReturnType<typeof exportTalks>) {
    const cmd = new ExportTalks(action.payload);
    const i18n = (yield effects.select(getI18n)) as I18N;

    try {
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setSnackMessage(i18n._('Export is in progress !')));
    } catch (err) {
        yield effects.put(setLoading(false));
        yield effects.put(setSnackMessage(i18n._('Operation failed')));
        Logger.warn(err as string, 'Failed to export talks');
    }
}

function* handleGetAllValues() {
    const cmd1 = new TalkGetAllGroups();
    const cmd2 = new TalkGetAllRegims();
    const cmd3 = new TalkGetAllTitles();

    try {
        yield effects.call(cmd1.Send.bind(cmd1));
        yield effects.call(cmd2.Send.bind(cmd2));
        yield effects.call(cmd3.Send.bind(cmd3));
    } catch (err) {
        Logger.warn(err as string, 'Failed to get "all" values');
    }
}

function* handleGetDistricts(action: ReturnType<typeof getDistricts>) {
    const cmd = new TalkGetDistricts(action.payload);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to search districts');
    }
}

function* handleGetPerson(action: ReturnType<typeof getPerson>) {
    const cmd = new TalkGetPerson(action.payload);
    const i18n = (yield effects.select(getI18n)) as I18N;

    try {
        yield effects.put(setPersonLoading(true));
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setPersonLoading(false));
    } catch (err) {
        yield effects.put(setPersonLoading(false));
        yield effects.put(setSnackMessage(i18n._('Operation failed')));
        Logger.warn(err as string, 'Failed to search person');
    }
}

function* handleGetProgramTypes() {
    const cmd = new TalkGetProgramTypes();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to search program types');
    }
}

function* handleGetStudies() {
    const cmd = new TalkGetStudies();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to search studies');
    }
}

function* handleGetSugg(action: ReturnType<typeof getSugg>) {
    const cmd = new TalkAutocompleteName(action.payload[0], action.payload[1]);

    try {
        yield effects.put(setSuggLoading(true));
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setSuggLoading(false));
    } catch (err) {
        yield effects.put(setSuggLoading(false));
        Logger.warn(err as string, 'Failed to search suggestions');
    }
}

function* handleSearch(action: ReturnType<typeof search>) {
    const cmd = new GetTalks(action.payload);

    try {
        yield effects.put(setSearchLoading(true));
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setLastSearch(action.payload));
    } catch (err) {
        yield effects.put(setSearchLoading(false));
        Logger.warn(err as string, 'Failed to launch search');
    }
}

function* watchAddTalk() {
    yield effects.takeEvery(TalkActionTypes.ADD_TALK, handleAddTalk);
}

function* watchDeleteMultiple() {
    yield effects.takeEvery(TalkActionTypes.DELETE_MULTIPLE, handleDeleteMultiple);
}

function* watchDeleteTalk() {
    yield effects.takeEvery(TalkActionTypes.DELETE_TALK, handleDeleteTalk);
}

function* watchEditTalk() {
    yield effects.takeEvery(TalkActionTypes.EDIT_TALK, handleEditTalk);
}

function* watchExportTalks() {
    yield effects.takeEvery(TalkActionTypes.EXPORT, handleExportTalks);
}

function* watchGetAllValues() {
    yield effects.takeLatest(TalkActionTypes.GET_ALL_VALUES, handleGetAllValues);
}

function* watchGetDistricts() {
    yield effects.takeLatest(TalkActionTypes.GET_DISTRICTS, handleGetDistricts);
}

function* watchGetPerson() {
    yield effects.takeLatest(TalkActionTypes.GET_PERSON, handleGetPerson);
}

function* watchGetSugg() {
    yield effects.takeLatest(TalkActionTypes.GET_SUGG, handleGetSugg);
}

function* watchGetProgramTypes() {
    yield effects.takeLatest(TalkActionTypes.GET_PROGRAM_TYPES, handleGetProgramTypes);
}

function* watchGetStudies() {
    yield effects.takeLatest(TalkActionTypes.GET_STUDIES, handleGetStudies);
}

function* watchSearch() {
    yield effects.takeLatest(TalkActionTypes.SEARCH, handleSearch);
}

export function* TalkSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchAddTalk)]);
    yield effects.all([effects.fork(watchDeleteMultiple)]);
    yield effects.all([effects.fork(watchDeleteTalk)]);
    yield effects.all([effects.fork(watchEditTalk)]);
    yield effects.all([effects.fork(watchExportTalks)]);
    yield effects.all([effects.fork(watchGetAllValues)]);
    yield effects.all([effects.fork(watchGetDistricts)]);
    yield effects.all([effects.fork(watchGetPerson)]);
    yield effects.all([effects.fork(watchGetProgramTypes)]);
    yield effects.all([effects.fork(watchGetStudies)]);
    yield effects.all([effects.fork(watchGetSugg)]);
    yield effects.all([effects.fork(watchSearch)]);
}
