import MomentUtils from '@date-io/moment';
import * as MD from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, RouteComponentProps, Switch } from 'react-router-dom';

import Account from '../Pages/Account';
import Archives from '../Pages/Archives';
import Home from '../Pages/Home';
import { MailsConsultPage } from '../Pages/Mails/Consult';
import { MailsPreventPage } from '../Pages/Mails/Prevent';
import Search from '../Pages/Search';
import SearchSTT from '../Pages/SearchSTT';
import Share from '../Pages/Share';
import Timeline from '../Pages/Timeline';

import { IApplicationState, IConnectedReduxProps } from '../Store';
import { IArchiveState } from '../Store/Archives';
import { I18N, ILocaleInfos } from '../Store/I18n';
import { IUserState } from '../Store/User';

import { getTheme } from '../Themes';
import AppLoader from './AppLoader';
import styles from './Router.styles';

interface IPropsFromState {
    archives: IArchiveState;
    i18n: I18N;
    localeInfos: ILocaleInfos;
    readyView: (id: string) => void;
    user: IUserState;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

export class RouterComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        return (
            <MuiPickersUtilsProvider
                locale={this.props.localeInfos.momentLocale}
                // moment={moment}
                utils={MomentUtils}
            >
                <MD.MuiThemeProvider theme={getTheme()}>
                    <MD.Grid
                        container={true}
                        className={this.props.classes.container}
                        direction='row'
                        justify='center'
                        alignItems='center'
                        style={{ height: '100%' }}
                    >
                        <MD.CssBaseline />
                        <MD.Hidden smDown={true}>
                            {(() => {
                                if (this.props.user.loading) {
                                    return <AppLoader />;
                                }
                                if (this.props.user.loggedIn) {
                                    return (
                                        <BrowserRouter>
                                            <Switch>
                                                <Route exact path='/archives' component={Archives} />
                                                {/*
                                                <Route
                                                    exact
                                                    path='/archives/:subpage/:reviewID'
                                                    component={Archives}
                                                />
                                                */}
                                                <Route exact path='/archives/search/:searchType' component={Archives} />
                                                <Route exact path='/account/:subpage?' component={Account} />
                                                <Route exact path='/account' component={Account} />
                                                <Route exact path='/search' component={Search} />
                                                <Route exact path='/searchSTT' component={SearchSTT} />
                                                <Route exact path='/timeline' component={Timeline} />
                                                <Route exact path='/mail/:id' component={MailsConsultPage} />
                                                <Route exact path='/preventMail/:id' component={MailsPreventPage} />
                                                <Route exact path='/share/:archiveID' component={Share} />
                                                <Route component={Timeline} />
                                            </Switch>
                                        </BrowserRouter>
                                    );
                                }
                                return (
                                    <BrowserRouter>
                                        <Switch>
                                            <Route exact path='/share/:archiveID' component={Share} />
                                            <Route exact path='/mail/:id' component={MailsConsultPage} />
                                            <Route exact path='/preventMail/:id' component={MailsPreventPage} />
                                            <Route component={Home} />
                                        </Switch>
                                    </BrowserRouter>
                                );
                            })()}
                        </MD.Hidden>
                        <MD.Hidden mdUp={true}>
                            {document.location.href.indexOf('/share/') !== -1 ? (
                                <BrowserRouter>
                                    <Switch>
                                        <Route exact path='/share/:archiveID' component={Share} />
                                    </Switch>
                                </BrowserRouter>
                            ) : (
                                <MD.Card>
                                    <MD.CardContent>
                                        <MD.Typography>
                                            {this.props.i18n._(
                                                'MediaArchiver requires a device with at least 960px of width',
                                            )}
                                        </MD.Typography>
                                    </MD.CardContent>
                                </MD.Card>
                            )}
                        </MD.Hidden>
                    </MD.Grid>
                </MD.MuiThemeProvider>
            </MuiPickersUtilsProvider>
        );
    }
}

const mapStateToProps = ({ archives, user, i18n }: IApplicationState) => ({
    archives,
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    user,
});

const mapDispatchToProps = () => ({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Router = MD.withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RouterComponent) as any);
