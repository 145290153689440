import { action } from 'typesafe-actions';

import {
    AnalyticsActionTypes,
    IInitAction,
    IOKBug20220524Action,
    ISetConsentAction,
    ISetShouldAskAction,
} from './Types';

export const init = (): IInitAction => action(AnalyticsActionTypes.INIT);
export const setConsent = (consent: boolean): ISetConsentAction => action(AnalyticsActionTypes.SET_CONSENT, consent);
export const setOKBug20220524 = (): IOKBug20220524Action => action(AnalyticsActionTypes.OK_BUG20220524);
export const setShouldAsk = (shouldAsk: boolean): ISetShouldAskAction =>
    action(AnalyticsActionTypes.SET_SHOULD_ASK, shouldAsk);
