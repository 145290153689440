import { mediaarchiver } from '../../Protos/protos';
import { AMessage, IToCommand } from './';

export class GetReview extends AMessage implements IToCommand {
    private readonly id: string;

    public constructor(id: string) {
        super('getReview');
        this.id = id;
    }

    public Send(): Promise<void> {
        return this.send(new mediaarchiver.WSMessage({
            ID : this.id,
            Type: mediaarchiver.WSMessageType.MessageTypeDeleteReview,
        }));
    }
}
