import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefined } from '../../Utils/Various';

export class Panels extends AMessage implements IFromCommand {
    private parsedRadios: Proto.mediaarchiver.IPanel[] | null = null;
    private parsedTVs: Proto.mediaarchiver.IPanel[] | null = null;

    public constructor() {
        super('panels');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (
                (isNullOrUndefined(msg.PanelsArg)) ||
                (!Array.isArray(msg.PanelsArg.Radio)) ||
                (!Array.isArray(msg.PanelsArg.TV))
            ) {
                return reject(new Error('Not a Panels message'));
            }

            this.parsedRadios = msg.PanelsArg.Radio;
            this.parsedTVs = msg.PanelsArg.TV;
            resolve();
        });
    }

    public get radios(): Proto.mediaarchiver.IPanel[] {
        if (isNullOrUndefined(this.parsedRadios)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedRadios;
    }

    public get tvs(): Proto.mediaarchiver.IPanel[] {
        if (isNullOrUndefined(this.parsedTVs)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedTVs;
    }

}
