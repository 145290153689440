import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { I18N } from '../../../Store/I18n';
import { IPlayerState } from '../../../Store/Player';
import { IEPGProgramState, IEPGState, ITimelineState } from '../../../Store/Timeline';
import { setSponsoringPopover } from '../../../Store/TimelinePopovers/Actions';
import { IUserState } from '../../../Store/User/Types';
import { getTheme } from '../../../Themes';
import { getDateInTz } from '../../../Utils/Time';
import { isNull, isNullOrUndefined } from '../../../Utils/Various';

interface IPropsFromState {
    i18n: I18N;
    player: IPlayerState;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    setSponsoringPopover: typeof setSponsoringPopover;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

const theme = getTheme();

const styles = MD.createStyles({
    emptyContainer: {
        '&>div': {
            '&>p': {
                color: theme.palette.text.disabled,
            },
            paddingLeft: theme.spacing(1),
            position: 'fixed',
        },
        height: '100%',
    },
    line: {
        height: '15px',
        position: 'relative',
        width: '100%',
    },
    program: {
        alignItems: 'center',
        background: theme.mediaarchiver.colors.backgroundProgramBackground,
        border: '1px solid black',
        borderRight: '0px solid',
        borderTop: '0px solid',
        color: 'black',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '11px',
        height: '14px',
        justifyContent: 'left',
        lineHeight: '11px',
        marginTop: '3px',
        overflow: 'hidden',
        paddingLeft: '3px',
        position: 'absolute',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
    },
    root: {
        alignItems: 'flex-end',
        display: 'inline-flex',
        flexDirection: 'column',
        height: '15px',
        width: '100%',
    },
});

export class SponsoringComponent extends React.Component<AllProps> {
    private container: HTMLElement | null = null;
    private popover: HTMLElement | null = null;
    private popoverImage: HTMLImageElement | null = null;

    public render(): React.ReactNode {
        if (!this.props.timeline.ready) {
            return (
                <div className={this.props.classes.emptyContainer}>
                    <div>
                        <MD.Typography>
                            {this.props.i18n._('Data is loading')}
                            &nbsp; &nbsp;
                            <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                        </MD.Typography>
                    </div>
                </div>
            );
        }

        const startTS = this.props.timeline.criterias.start.getTime();
        const endTS = this.props.timeline.criterias.end.getTime();
        const tlDuration = endTS - startTS;

        return (
            <div className={this.props.classes.root} id='sponsoringContainer'>
                <div className={this.props.classes.line}>
                    {this.renderLine(this.props.timeline.data.sponsoringEPG, tlDuration)}
                </div>
            </div>
        );
    }

    private renderLine(data: IEPGState, tlDuration: number) {
        this.popover = null;
        return Object.keys(data).map((minute: string, i: number) => {
            const program = data[(minute as unknown) as number];
            let start = program.start.getTime();
            const tlStart = getDateInTz(this.props.timeline.criterias.start, this.props.timeline.criterias.timezone).getTime();
            const tlEnd = getDateInTz(this.props.timeline.criterias.end, this.props.timeline.criterias.timezone).getTime();
            let duration = program.duration;

            if (start < tlStart) {
                start = tlStart;
            }
            if (start + duration > tlEnd) {
                duration = tlEnd - start;
            }

            const widthPercent = Math.ceil((duration / tlDuration) * 1000000) / 10000;

            return (
                <div
                    className={this.props.classes.program}
                    data-donottrackscroller={true}
                    key={`sponsoring_${i}`}
                    onClick={this.handlePopoverClick.bind(this, program)}
                    onMouseEnter={this.handlePopoverOpen.bind(this, program)}
                    onMouseLeave={this.handlePopoverClose.bind(this)}
                    style={{
                        left: `${((start - tlStart) / tlDuration) * 100}%`,
                        width: `${widthPercent}%`,
                        zIndex: 10 + i,
                    }}
                >
                    {program.title}
                </div>
            );
        });
    }

    private handlePopoverClick(prg: IEPGProgramState, ev: React.MouseEvent<Element>) {
        ev.stopPropagation();
        if (!isNullOrUndefined(this.props.player.player)) {
            this.props.player.player.start(
                prg.start,
                getDateInTz(this.props.timeline.criterias.end, this.props.timeline.criterias.timezone),
                this.props.user.user.options.indexOf(
                    mediaarchiver.UserOptions.USER_OPTION_NO_AUTOPLAY_WHEN_PLAY_CONTENT,
                ) === -1,
            );
        }
    }

    private handlePopoverClose() {
        if (isNull(this.popover)) {
            this.popover = document.getElementById('sponsoringPopover');
        }
        if (isNull(this.popover)) {
            return;
        }
        this.popover.style.display = 'none';
        this.props.setSponsoringPopover(null);
    }

    private handlePopoverOpen(prg: IEPGProgramState, ev: React.MouseEvent) {
        if (isNull(this.popover)) {
            this.popover = document.getElementById('sponsoringPopover');
        }
        if (isNull(this.container)) {
            this.container = document.getElementById('sponsoringContainer');
        }
        if (isNullOrUndefined(ev) || isNullOrUndefined(ev.target) || isNull(this.popover) || isNull(this.container)) {
            return;
        }

        this.props.setSponsoringPopover(prg);

        const prgBox = (ev.target as HTMLElement).getBoundingClientRect();

        this.popover.style.display = 'flex';
        if (
            ev.clientX - theme.mediaarchiver.dimensions.drawerWidth <
            (window.innerWidth - theme.mediaarchiver.dimensions.drawerWidth) / 2
        ) {
            this.popover.style.left = `${ev.clientX}px`;
            this.popover.style.right = 'inherit';
        } else {
            this.popover.style.left = 'inherit';
            this.popover.style.right = `${window.innerWidth - ev.clientX}px`;
        }
        this.popover.style.bottom = `${window.innerHeight - prgBox.top + 3}px`;
    }
}

const mapStateToProps = ({ i18n, player, timeline, user }: IApplicationState) => ({
    i18n: i18n.i18n,
    player,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setSponsoringPopover: (prg: IEPGProgramState | null) => dispatch(setSponsoringPopover(prg)),
});

export const Sponsoring = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(SponsoringComponent));
