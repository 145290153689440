import { AMessage, IFromCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class Contact extends AMessage implements IFromCommand {
    private parsedContact: mediaarchiver.IContact | null = null;

    public constructor() {
        super('contact');
    }

    public Parse(msg: mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.ContactArg)) {
                return reject(new Error('Not a Contact message'));
            }
            this.parsedContact = msg.ContactArg;
            resolve();
        });
    }

    public get contact(): mediaarchiver.IContact {
        if (isNull(this.parsedContact)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedContact;
    }
}
