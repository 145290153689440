import { AMessage, IToCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';

export class ExportAnnotations extends AMessage implements IToCommand {
    private readonly specs: mediaarchiver.IArgumentsExportAnnotations;

    public constructor(specs: mediaarchiver.IArgumentsExportAnnotations) {
        super('exportAnnotations');
        this.specs = specs;
    }

    public Send(): Promise<void> {
        return this.send(
            new mediaarchiver.WSMessage({
                ExportAnnotationArg: this.specs,
                Type: mediaarchiver.WSMessageType.MessageTypeExportAnnotations,
            }),
        );
    }
}
