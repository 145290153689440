/* eslint-disable react/prop-types */
import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import ArchivesDrawer from '../../Components/Archives/Drawer';
// import Review from '../../Components/Archives/Review';
import { ArchivesSearch } from '../../Components/Archives/Search/Search';
import Layout from '../../Components/Layout';
import { IConnectedReduxProps } from '../../Store';
import { I18N, setLocale } from '../../Store/I18n';
import {
    setDrawerContent,
    setDrawerTitle,
    setHasAnnotations,
    setPageContent,
    setPageTitle,
} from '../../Store/Layout';
import { IMedia } from '../../Store/Medias';

export interface IRouterMatchParams {
    subpage: string;
}

interface IPropsFromState {
    medias: IMedia[];
    errors?: string;
    i18n: I18N;
    loading: boolean;
}

interface IPropsFromDispatch {
    loadI18n: typeof setLocale;
    setDrawerContent: typeof setDrawerContent;
    setDrawerTitle: typeof setDrawerTitle;
    setHasAnnotations: typeof setHasAnnotations;
    setPageContent: typeof setPageContent;
    setPageTitle: typeof setPageTitle;
}

type AllProps = IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<IRouterMatchParams> &
    IConnectedReduxProps;

export const ArchivesPage: React.FC<AllProps> = ({ i18n, match, ...props }) => {
    props.setPageTitle('');
    props.setDrawerContent(<Route component={ArchivesDrawer} />);

    switch (match.params.subpage) {
        case 'review': {
            // props.setDrawerTitle(i18n._('Review'));
            // props.setPageContent(<Route component={Review} />);
            break;
        }
        default: {
            props.setDrawerTitle(i18n._('My archives'));
            props.setPageContent(<Route component={ArchivesSearch} />);
            props.setPageTitle(i18n._('Archives'));

        }
    }
    return <Route component={Layout} />;
};
