import { faEye, faEyeSlash, faKey, faSignIn, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IConnectedReduxProps } from '../../Store';
import { I18N } from '../../Store/I18n';
import { login } from '../../Store/User';
import { isNullOrUndefined } from '../../Utils/Various';
import styles from './styles';

import ImageHome from '../../Images/image_home.jpg';
import LogoYacast from '../../Images/yacast.png';
import { TypedStorage } from '../../Utils/TypedStorage';

interface IOwnState {
    contactAnchorEl: Element | null;
    openDialog: boolean;
    showContact: boolean;
    showPassword: boolean;
}

interface IPropsFromState {
    i18n: I18N;
    userError?: Error;
}

interface IPropsFromDispatch {
    login: typeof login;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class HomePage extends React.Component<AllProps, IOwnState> {
    public state = {
        contactAnchorEl: null,
        openDialog: !TypedStorage.get('consentGiven', false),
        showContact: false,
        showPassword: false,
    };

    public render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Card className={classes.card}>
                <MD.Dialog
                    open={this.state.openDialog}
                    onClose={() => this.handleNoConsent()}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <MD.DialogTitle id='alert-dialog-title'>{'Information'}</MD.DialogTitle>
                    <MD.DialogContent>
                        <MD.DialogContentText id='alert-dialog-description'>
                            {this.props.i18n._(
                                'Nous vous informons que toutes les connexions, consultations, annotations, archivages et téléchargements effectués sur MediaArchiver sont loggés et archivés pour une meilleure traçabilité sur l’utilisation du service',
                            )}
                        </MD.DialogContentText>
                    </MD.DialogContent>
                    <MD.DialogActions>
                        <MD.Button onClick={() => this.handleNoConsent()} color='primary'>
                            Disagree
                        </MD.Button>
                        <MD.Button onClick={() => this.handleDialogClose()} color='primary' autoFocus>
                            Agree
                        </MD.Button>
                    </MD.DialogActions>
                </MD.Dialog>
                <MD.CardMedia className={classes.media} image={ImageHome} title='Yacast - Monitoring Media' />
                <MD.CardContent>
                    <MD.Hidden xsUp={true}>
                        <MD.Typography gutterBottom={true} variant='h5' component='h1'>
                            MediaArchiver
                        </MD.Typography>
                    </MD.Hidden>
                    <MD.Hidden xsUp={true}>
                        <MD.Typography component='h2'>
                            {this.props.i18n._('Online access to radios and TV medias archives.')}
                        </MD.Typography>
                    </MD.Hidden>
                    <form
                        className={classes.form}
                        onKeyPress={(ev: React.KeyboardEvent) => {
                            if (ev.key === 'Enter') {
                                this.doLogin();
                            }
                        }}
                        onSubmit={this.doLogin.bind(this)}
                    >
                        <MD.Grid container={true} className={classes.textFieldContainer}>
                            <MD.Grid item={true} xs={12}>
                                <MD.TextField
                                    className={classes.textField}
                                    error={this.props.userError instanceof Error}
                                    helperText={
                                        this.props.userError ? this.props.i18n._(this.props.userError.message) : ''
                                    }
                                    id='login'
                                    label={this.props.i18n._('Your login')}
                                    InputProps={{
                                        startAdornment: (
                                            <MD.InputAdornment position='start'>
                                                <FontAwesomeIcon icon={faUser} />
                                            </MD.InputAdornment>
                                        ),
                                    }}
                                />
                            </MD.Grid>
                        </MD.Grid>
                        <MD.Grid container className={classes.textFieldContainer}>
                            <MD.Grid item xs={12}>
                                <MD.TextField
                                    className={classes.textField}
                                    error={this.props.userError instanceof Error}
                                    id='password'
                                    label={this.props.i18n._('Your password')}
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <MD.InputAdornment position='end'>
                                                <MD.IconButton
                                                    aria-label={this.props.i18n._('Toggle password visibility')}
                                                    onClick={this.handleClickShowPassword.bind(this)}
                                                >
                                                    {this.state.showPassword ? (
                                                        <FontAwesomeIcon icon={faEye} />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faEyeSlash} />
                                                    )}
                                                </MD.IconButton>
                                            </MD.InputAdornment>
                                        ),
                                        startAdornment: (
                                            <MD.InputAdornment position='start'>
                                                <FontAwesomeIcon icon={faKey} />
                                            </MD.InputAdornment>
                                        ),
                                    }}
                                />
                            </MD.Grid>
                        </MD.Grid>
                        {/*
                        <MD.Grid container={true} className={classes.textFieldContainer}>
                            <MD.Grid item={true} xs={12}>
                                <MD.FormGroup row={true}>
                                    <MD.FormControlLabel
                                        control={<MD.Checkbox id='remember' />}
                                        label={this.props.i18n._('Remember me')}
                                    />
                                </MD.FormGroup>
                            </MD.Grid>
                        </MD.Grid>
                        */}
                        <MD.Grid container className={classes.textFieldContainer}>
                            <MD.Grid item xs={12}>
                                <MD.FormGroup row>
                                    <MD.Button variant='outlined' color='primary' onClick={this.doLogin.bind(this)}>
                                        <FontAwesomeIcon icon={faSignIn} />
                                        <MD.Hidden mdDown>&nbsp;{this.props.i18n._('Sign in')}</MD.Hidden>
                                    </MD.Button>
                                </MD.FormGroup>
                                <MD.Link
                                    className={classes.lostPassword}
                                    href='https://users.yacast.fr/password/reset'
                                    target='lostPasswdYacast'
                                >
                                    {this.props.i18n._('I lost my login / password')}
                                </MD.Link>
                            </MD.Grid>
                        </MD.Grid>
                    </form>
                </MD.CardContent>
                <MD.CardActions>
                    <p className={classes.contactButton}>
                        <a href='http://www.yacast.fr' target='yacast'>
                            <img className={classes.logo} src={LogoYacast} />
                        </a>
                        <MD.Button id='showContact' onClick={this.handleClickShowContact.bind(this)} size='small'>
                            {this.props.i18n._('Contact us')}
                        </MD.Button>
                        <MD.Popover
                            anchorEl={this.state.contactAnchorEl}
                            id='contactInfos'
                            onClose={this.handleCloseContact.bind(this)}
                            open={this.state.showContact}
                        >
                            <MD.Typography className={classes.contactPopover}>
                                {this.props.i18n._('Hotline')}: {this.props.i18n._('**hotline phone number')}
                                <br />
                                {this.props.i18n._('**hotline mail address')}
                            </MD.Typography>
                        </MD.Popover>
                    </p>
                </MD.CardActions>
            </MD.Card>
        );
    }

    private handleDialogClose(): void {
        TypedStorage.set('consentGiven', true);
        this.setState((prevState: IOwnState) => ({
            openDialog: !prevState.openDialog,
        }));
    }

    private handleNoConsent(): void {
        window.setTimeout(() => {
            window.location.replace('https://www.yacast.fr');
        }, 500);
    }

    private handleClickShowPassword(): void {
        this.setState((prevState: IOwnState) => ({
            showPassword: !prevState.showPassword,
        }));
    }

    private handleClickShowContact(ev: React.MouseEvent): void {
        this.setState({ contactAnchorEl: ev.target as Element, showContact: true });
    }

    private handleCloseContact(): void {
        this.setState({ contactAnchorEl: null, showContact: false });
    }

    private doLogin(e?: React.FormEvent) {
        if (!isNullOrUndefined(e)) {
            e.preventDefault();
            e.stopPropagation();
        }

        // These are the possible returns from backend
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const unused1 = this.props.i18n._('Please type your login');
        const unused2 = this.props.i18n._('Please type your password');
        const unused3 = this.props.i18n._('A server error occured, please try again later');
        const unused4 = this.props.i18n._('Bad credentials');
        const unused5 = this.props.i18n._('Deactivated account');
        const unused6 = this.props.i18n._('Account is not active');
        const unused7 = this.props.i18n._('Your account does not include the MediaArchiver solution');
        /* eslint-disable @typescript-eslint/no-unused-vars */

        this.props.login({
            login: (document.getElementById('login') as HTMLInputElement).value,
            password: (document.getElementById('password') as HTMLInputElement).value,
            // remember: (document.getElementById('remember') as HTMLInputElement).checked,
            remember: true,
        });
    }
}
