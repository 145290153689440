import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefined } from '../../Utils/Various';

export class TalkStudies extends AMessage implements IFromCommand {
    private parsedStudies: Proto.mediaarchiver.ITalkStudy[] | null = null;

    public constructor() {
        super('talkStudies');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.TalkStudiesArgs) || !Array.isArray(msg.TalkStudiesArgs.Studies)) {
                return reject(new Error('Not a TalkStudies message'));
            }

            this.parsedStudies = msg.TalkStudiesArgs.Studies;
            resolve();
        });
    }

    public get studies(): Proto.mediaarchiver.ITalkStudy[] {
        if (isNullOrUndefined(this.parsedStudies)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedStudies;
    }
}
