import { effects } from 'redux-saga';

import { Config } from '../../config';
import { Logger } from '../../Utils/Logger';
import { TypedStorage } from '../../Utils/TypedStorage';
import { init, setConsent, setShouldAsk } from './Actions';
import { AnalyticsActionTypes } from './Types';

/* eslint-disable @typescript-eslint/no-explicit-any */
(window as any).dataLayer = (window as any).dataLayer || [];
if (typeof (window as any).gtag !== 'function') {
    (window as any).gtag = function () {
        // eslint-disable-next-line prefer-rest-params
        (window as any).dataLayer.push(arguments);
    }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
gtag('js', new Date());
gtag('config', Config.gtagID);

let initialized = false;

function* handleConsent(action: ReturnType<typeof setConsent>) {
    TypedStorage.set('analyticsAsked', true);
    TypedStorage.set('analyticsAllowed', action.payload);
    yield effects.put(setShouldAsk(false));
    initialized = false;
    yield effects.put(init());
}

function* handleInit() {
    if (!TypedStorage.get('analyticsAsked', false)) {
        Logger.debug('Should ask content');
        yield effects.put(setShouldAsk(true));
        return;
    }
    if (!initialized) {
        Logger.debug('Initializing analytics');
        initialized = true;
        if (TypedStorage.get('analyticsAllowed', false)) {
            Logger.debug('Consented, loading gtag');
            const tag = document.createElement('SCRIPT');

            tag.setAttribute('async', 'true');
            tag.setAttribute(
                'src',
                `https://www.googletagmanager.com/gtag/js?id=${Config.gtagID}`,
            );
            document.body.appendChild(tag);
            return;
        }
        Logger.debug('Not consented, disabling gtag');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).gtag = () => { /* */ };
    }
}

function* watchConsent() {
    yield effects.takeLatest(AnalyticsActionTypes.SET_CONSENT, handleConsent);
}

function* watchInit() {
    yield effects.takeLatest(AnalyticsActionTypes.INIT, handleInit);
}

export function* AnalyticsSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchConsent)]);
    yield effects.all([effects.fork(watchInit)]);
}
