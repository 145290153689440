import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';

import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class SearchWordsResult extends AMessage implements IFromCommand {
    private parsedResults: Proto.mediaarchiver.ISearchWordResult[] | null = null;

    public constructor() {
        super('searchWordsResult');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg)) {
                return reject(new Error('Empty message'));
            }
            if (isNullOrUndefined(msg.SearchWordsResultArgs)) {
                return reject(new Error('Not a SearchWordsResult message'));
            }
            this.parsedResults = !isNullOrUndefined(msg.SearchWordsResultArgs.Words)
                ? msg.SearchWordsResultArgs.Words
                : [];
            resolve();
        });
    }

    public get results(): Proto.mediaarchiver.ISearchWordResult[] {
        if (isNull(this.parsedResults)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedResults;
    }
}
