import * as Proto from '../../Protos/protos';
import { AMessage, IToCommand } from './';

export class Logout extends AMessage implements IToCommand {

    public constructor() {
        super('logout');
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeLogout,
        }));
    }
}
