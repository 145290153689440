import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { IApplicationState, RootReducer, RootSaga } from './index';

let store: Store | null = null;

export function configureStore(history: History, initialState: IApplicationState): Store<IApplicationState> {
    const composeEnhancers = composeWithDevTools({});
    const sagaMiddleware = createSagaMiddleware();

    store = createStore(
        connectRouter(history)(RootReducer),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        initialState as any,
        composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
    );

    // Don't forget to run the root saga, and return the store object.
    sagaMiddleware.run(RootSaga);
    return store;
}

export { store };
