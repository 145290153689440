import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { I18N } from '../../../Store/I18n/Types';
import { ITimelinePopoversState } from '../../../Store/TimelinePopovers/Types';
import { limitText } from '../../../Utils/String';

interface IPropsFromState {
    i18n: I18N;
    timelinePopovers: ITimelinePopoversState;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

const styles = MD.createStyles({
    popover: {
        display: 'none',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 2000,
    },
    popoverContent: {
        '& h5': {
            fontSize: '1.2em',
        },
        '& p': {
            '& span': {
                fontStyle: 'italic',
            },
            fontSize: '1em',
        },
        '& p:first-of-type': {
            marginTop: 10,
        },
        flex: '1 0 auto',
        wordWrap: 'break-word',
    },
    popoverDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 340,
    },
});

export class PopoverCrossBucketComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        return (
            <MD.Card className={this.props.classes.popover} id='crossbucketPopover'>
                <div className={this.props.classes.popoverDetails}>
                    <MD.CardContent className={this.props.classes.popoverContent}>
                        {this.props.timelinePopovers.popoverCrossBucket ? (
                            <MD.Typography component='h5' variant='h5'>
                                {limitText(this.props.timelinePopovers.popoverCrossBucket.title, 150)}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverCrossBucket &&
                        this.props.timelinePopovers.popoverCrossBucket.metas &&
                        this.props.timelinePopovers.popoverCrossBucket.metas.bucket ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Bucket')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverCrossBucket.metas.bucket}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverCrossBucket &&
                        this.props.timelinePopovers.popoverCrossBucket.metas &&
                        this.props.timelinePopovers.popoverCrossBucket.metas.isrc ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('ISRC')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverCrossBucket.metas.isrc}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverCrossBucket &&
                        this.props.timelinePopovers.popoverCrossBucket.metas &&
                        this.props.timelinePopovers.popoverCrossBucket.metas.label ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Label')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverCrossBucket.metas.label}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverCrossBucket &&
                        this.props.timelinePopovers.popoverCrossBucket.metas &&
                        this.props.timelinePopovers.popoverCrossBucket.metas.albums ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Album(s)')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverCrossBucket.metas.albums}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverCrossBucket &&
                        this.props.timelinePopovers.popoverCrossBucket.metas &&
                        this.props.timelinePopovers.popoverCrossBucket.metas.authors ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Author(s)')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverCrossBucket.metas.authors}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverCrossBucket &&
                        this.props.timelinePopovers.popoverCrossBucket.metas &&
                        this.props.timelinePopovers.popoverCrossBucket.metas.composers ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Composer(s)')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverCrossBucket.metas.composers}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverCrossBucket &&
                        this.props.timelinePopovers.popoverCrossBucket.metas &&
                        this.props.timelinePopovers.popoverCrossBucket.metas.interpreters ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Interpreter(s)')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverCrossBucket.metas.interpreters}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverCrossBucket &&
                        this.props.timelinePopovers.popoverCrossBucket.metas &&
                        this.props.timelinePopovers.popoverCrossBucket.metas.publishers ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Publisher(s)')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverCrossBucket.metas.publishers}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverCrossBucket &&
                        this.props.timelinePopovers.popoverCrossBucket.metas &&
                        this.props.timelinePopovers.popoverCrossBucket.metas.release ? (
                            <MD.Typography component='p'>
                                <span>{this.props.i18n._('Release')}</span> : &nbsp;
                                {this.props.timelinePopovers.popoverCrossBucket.metas.release}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                    </MD.CardContent>
                </div>
            </MD.Card>
        );
    }
}

const mapStateToProps = ({ i18n, timelinePopovers }: IApplicationState) => ({
    i18n: i18n.i18n,
    timelinePopovers,
});

export const PopoverCrossBucket = connect(mapStateToProps)(MD.withStyles(styles)(PopoverCrossBucketComponent));
