import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { ITimelinePopoversState } from '../../../Store/TimelinePopovers/Types';
import { getReadjustedCategoryColor } from '../../../Themes';
import { limitText } from '../../../Utils/String';
import { isNullOrUndefined } from '../../../Utils/Various';

interface IPropsFromState {
    timelinePopovers: ITimelinePopoversState;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

const styles = MD.createStyles({
    popover: {
        display: 'none',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 2000,
    },
    popoverContent: {
        '& h5': {
            fontSize: '1.5em', //'1em',
        },
        '& h6': {
            fontSize: '1.2em', //'0.7em',
            fontStyle: 'italic',
            marginTop: 5,
            textTransform: 'uppercase',
        },
        '& p': {
            fontSize: '1.2em', //'0.7em',
            marginTop: 10,
        },
        flex: '1 0 auto',
        wordWrap: 'break-word',
    },
    popoverDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 340,
    },
});

export class PopoverBroadcasterComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        let showKind = '';

        if (
            !isNullOrUndefined(this.props.timelinePopovers.popoverBroadcaster) &&
            !isNullOrUndefined(this.props.timelinePopovers.popoverBroadcaster.metas) &&
            !isNullOrUndefined(this.props.timelinePopovers.popoverBroadcaster.metas.idShowKind)
        ) {
            showKind = this.props.timelinePopovers.popoverBroadcaster.metas.idShowKind;
        }

        return (
            <MD.Card className={this.props.classes.popover} id='broadcasterPopover'>
                <div className={this.props.classes.popoverDetails}>
                    <MD.CardContent className={this.props.classes.popoverContent}>
                        <MD.Typography component='h5' variant='h5'>
                            {limitText(
                                this.props.timelinePopovers.popoverBroadcaster
                                    ? this.props.timelinePopovers.popoverBroadcaster.title
                                    : '',
                                150,
                            )}
                        </MD.Typography>
                        {this.props.timelinePopovers.popoverBroadcaster &&
                        this.props.timelinePopovers.popoverBroadcaster.categoryText ? (
                            <MD.Typography
                                component='h6'
                                style={{
                                    color: getReadjustedCategoryColor(showKind),
                                }}
                                variant='h6'
                            >
                                {limitText(
                                    this.props.timelinePopovers.popoverBroadcaster
                                        ? this.props.timelinePopovers.popoverBroadcaster.categoryText
                                        : '',
                                    150,
                                )}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        <MD.Typography component='p'>
                            {limitText(
                                this.props.timelinePopovers.popoverBroadcaster &&
                                    this.props.timelinePopovers.popoverBroadcaster.subtitle
                                    ? this.props.timelinePopovers.popoverBroadcaster.subtitle
                                    : '',
                                150,
                            )}
                        </MD.Typography>
                    </MD.CardContent>
                </div>
            </MD.Card>
        );
    }
}

const mapStateToProps = ({ timelinePopovers }: IApplicationState) => ({
    timelinePopovers,
});

export const PopoverBroadcaster = connect(mapStateToProps)(MD.withStyles(styles)(PopoverBroadcasterComponent));
