import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class EditArchive extends AMessage implements IToCommand {
    private specs: Proto.mediaarchiver.IArchive;

    public constructor(specs: Proto.mediaarchiver.IArchive) {
        super('editArchive');
        this.specs = specs;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                EditArchiveArg: this.specs,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeEditArchive,
            }),
        );
    }
}
