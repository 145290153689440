import moment from 'moment';
import { Store } from 'redux';
import { delay, effects } from 'redux-saga';

import { IApplicationState } from '..';
import { CreateAnnotation } from '../../Backend/Commands/CreateAnnotation';
import { DeleteAnnotation } from '../../Backend/Commands/DeleteAnnotation';
import { DeleteMultipleAnnotations } from '../../Backend/Commands/DeleteMultipleAnnotations';
import { EditAnnotation } from '../../Backend/Commands/EditAnnotation';
import { ExportAnnotations } from '../../Backend/Commands/ExportAnnotations';
import { GetAnnotations } from '../../Backend/Commands/GetAnnotations';
import * as Proto from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { isNull, isNullOrUndefined, isNullOrUndefinedOrZero } from '../../Utils/Various';
import { I18N, ILocaleInfos } from '../I18n/Types';
import { setSnackMessage } from '../Layout/Actions';
import { IMedia } from '../Medias/Types';
import { refreshTimeline } from '../Player/Actions';
import { store } from '../store';

import {
    createAnnotation,
    deleteAnnotation,
    deleteMultipleAnnotations,
    download,
    editAnnotation,
    exportAnnotations,
    hideDialog,
    search,
    setCreateAnnotationError,
    setCreateAnnotationProgress,
    setCreateAnnotationSuccess,
    setLastSearch,
    setOpSuccess,
    setSearchLoading,
} from './Actions';
import { AnnotationActionTypes, IAnnotationsState } from './Types';

function* handleCreateAnnotation(action: ReturnType<typeof createAnnotation>) {
    const str = store as Store<IApplicationState>;
    const cmd = new CreateAnnotation(action.payload);

    yield effects.call(str.dispatch.bind(str, setCreateAnnotationProgress(true)));

    try {
        yield effects.call(cmd.Send.bind(cmd));
        yield delay(1000);
        yield effects.call(str.dispatch.bind(str, refreshTimeline()));
        yield effects.call(str.dispatch.bind(str, setCreateAnnotationSuccess()));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to create annotation');
        yield effects.call(str.dispatch.bind(str, setCreateAnnotationError((err as Error).message)));
    } finally {
        yield effects.call(str.dispatch.bind(str, setCreateAnnotationProgress(false)));
    }
}

function* handleDelete(action: ReturnType<typeof deleteAnnotation>) {
    const cmd = new DeleteAnnotation(action.payload);
    const str = store as Store<IApplicationState>;

    try {
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.call(str.dispatch.bind(str, setOpSuccess(true)));
        yield delay(1000);
        yield effects.call(str.dispatch.bind(str, refreshTimeline()));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to delete annotation');
        yield effects.call(str.dispatch.bind(str, setOpSuccess(false)));
    }
}

function* handleDeleteMultiple(action: ReturnType<typeof deleteMultipleAnnotations>) {
    const cmd = new DeleteMultipleAnnotations(action.payload);
    const str = store as Store<IApplicationState>;

    try {
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.call(str.dispatch.bind(str, setOpSuccess(true)));
        yield delay(1000);
        yield effects.call(str.dispatch.bind(str, refreshTimeline()));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to delete annotations');
        yield effects.call(str.dispatch.bind(str, setOpSuccess(false)));
    }
}

const getResults = (state: IApplicationState): Proto.mediaarchiver.IAnnotation[] => state.annotations.searchResults;

const getMedias = (state: IApplicationState): IMedia[] => state.medias.data;
const getI18n = (state: IApplicationState): I18N => state.i18n.i18n;
const getAnnotations = (state: IApplicationState): IAnnotationsState => state.annotations;
const getLocaleInfos = (state: IApplicationState): ILocaleInfos => state.i18n.localeInfos;

const renderField = (
    fieldDesc: Proto.mediaarchiver.IAnnotationTypeField,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[],
    i18n: I18N,
    localeInfos: ILocaleInfos,
): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let field: any = null;

    data.some((v) => {
        if (typeof v === 'object' && 'label' in v && typeof v.label === 'string' && v.label === fieldDesc.Name) {
            field = v;
            return true;
        }
        return false;
    });

    if (isNull(field)) {
        return '';
    }

    if (!field.label || !field.type || !field.value) {
        return '';
    }
    switch (field.type) {
        case 'shortText':
        case 'longText':
        case 'number':
            return field.value.length > 50 ? field.value.substr(0, 47) + ' ...' : field.value;
        case 'boolean':
            return field.value === '1' ? i18n._('Yes') : i18n._('No');
        case 'date':
            const date = new Date(parseInt(field.value, 10) * 1000);

            if (isNaN(date.getTime())) {
                return '⚠️';
            }
            return moment(date).format(field.special ? localeInfos.formatLongDateTime : localeInfos.formatLongDate);
        case 'list':
            try {
                const values = JSON.parse(field.value);

                if (!Array.isArray(values)) {
                    return '⚠️';
                }
                return values.join(', ');
            } catch (err) {
                if (typeof field.value === 'string') {
                    return field.value;
                } else {
                    Logger.warn(err as Error, 'error');
                    return '⚠️';
                }
            }
            break;
        default:
            return '⚠️';
    }
};

function* handleDownload(action: ReturnType<typeof download>) {
    const aType = action.payload as Proto.mediaarchiver.IAnnotationType;
    const results = (yield effects.select(getResults)) as Proto.mediaarchiver.IAnnotation[];
    const medias = (yield effects.select(getMedias)) as IMedia[];
    const localeInfos = (yield effects.select(getLocaleInfos)) as ILocaleInfos;
    const i18n = (yield effects.select(getI18n)) as I18N;

    if (results.length === 0 || isNullOrUndefined(aType.Fields)) {
        return;
    }
    const rows: string[][] = [];
    const header: string[] = [i18n._('Media'), i18n._('Date'), i18n._('Hour'), i18n._('Duration')];
    aType.Fields.forEach((field) => {
        header.push(field.Name || '');
    });
    rows.push(header);

    results.forEach((annotation) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let data: any = null;

        if (
            isNullOrUndefined(aType) ||
            isNullOrUndefined(aType.Fields) ||
            isNullOrUndefined(annotation.ID) ||
            isNullOrUndefined(annotation.Data)
        ) {
            return;
        }
        try {
            data = JSON.parse(annotation.Data);
            if (!Array.isArray(data)) {
                data = [];
            }
        } catch (err) {
            Logger.warn(err as Error, 'error');
        }

        const row: string[] = [];
        let mediaName = 'N/A';

        medias.some((media) => {
            if (media.id === annotation.MediaID) {
                mediaName = media.name;
                return true;
            }
            return false;
        });
        row.push(mediaName);

        if (isNullOrUndefinedOrZero(annotation.Start)) {
            row.push('');
            row.push('');
        }
        row.push(moment(new Date(annotation.Start as number)).format(localeInfos.formatShortDate));
        row.push(moment(new Date(annotation.Start as number)).format(localeInfos.formatShortTimeWithMilliseconds));

        const durationMS = annotation.DurationMS || 0;
        const duration = moment.duration(durationMS);

        row.push(
            ('0' + duration.hours().toString()).slice(-2) +
                ':' +
                ('0' + duration.minutes().toString()).slice(-2) +
                ':' +
                ('0' + duration.seconds().toString()).slice(-2),
        );

        aType.Fields.map((field: Proto.mediaarchiver.IAnnotationTypeField) => {
            row.push(renderField(field, data, i18n, localeInfos));
        });

        rows.push(row);
    });
    let csvContent = 'data:text/csv;charset=utf-8,';
    rows.forEach((row: string[]) => {
        let line = '"';

        line += row.map((field: string): string => field.replace('"', '”')).join('";"');
        line += '"';
        csvContent += `${line}\n`;
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'yacast_annotations.csv');
    document.body.appendChild(link);
    link.click();
}

function* handleEdit(action: ReturnType<typeof editAnnotation>) {
    const cmd = new EditAnnotation(action.payload);
    const str = store as Store<IApplicationState>;
    const i18n = (yield effects.select(getI18n)) as I18N;

    try {
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.call(str.dispatch.bind(str, setOpSuccess(true)));
        yield effects.call(str.dispatch.bind(str, setSnackMessage(i18n._('Annotation successfully updated'))));
        yield effects.call(str.dispatch.bind(str, hideDialog()));
        yield delay(1000);
        yield effects.call(str.dispatch.bind(str, refreshTimeline()));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to edit annotation');
        yield effects.call(str.dispatch.bind(str, setOpSuccess(false)));
    }
}

function* handleExport(action: ReturnType<typeof exportAnnotations>) {
    const cmd = new ExportAnnotations(action.payload);
    const str = store as Store<IApplicationState>;
    const i18n = (yield effects.select(getI18n)) as I18N;
    const annotations = (yield effects.select(getAnnotations)) as IAnnotationsState;

    try {
        yield effects.call(cmd.Send.bind(cmd));
        if (annotations.searchResultsCount < 1000) {
            yield effects.call(
                str.dispatch.bind(str, setSnackMessage(i18n._('Export in progress, download will start in seconds.'))),
            );
        } else {
            yield effects.call(
                str.dispatch.bind(
                    str,
                    setSnackMessage(i18n._("Export in progress, we will send you a mail when it's ready")),
                ),
            );
        }
    } catch (err) {
        Logger.warn(err as Error, 'Failed to export annotations');
        yield effects.call(
            str.dispatch.bind(str, setSnackMessage(str.getState().i18n.i18n._('Failed to export annotations'))),
        );
    }
}

function* handleSearch(action: ReturnType<typeof search>) {
    const cmd = new GetAnnotations(action.payload);

    try {
        yield effects.put(setSearchLoading(true));
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setSearchLoading(false));
        yield effects.put(setLastSearch(action.payload));
    } catch (err) {
        yield effects.put(setSearchLoading(false));
        Logger.warn(err as Error, 'Failed to launch search');
    }
}

function* watchCreateAnnotation() {
    yield effects.takeEvery(AnnotationActionTypes.CREATE_ANNOTATION, handleCreateAnnotation);
}

function* watchDeleteRequests() {
    yield effects.takeEvery(AnnotationActionTypes.DELETE, handleDelete);
    yield effects.takeEvery(AnnotationActionTypes.DELETE_MULTIPLE, handleDeleteMultiple);
}

function* watchDownload() {
    yield effects.takeEvery(AnnotationActionTypes.DOWNLOAD, handleDownload);
}

function* watchEditRequests() {
    yield effects.takeEvery(AnnotationActionTypes.EDIT, handleEdit);
}

function* watchExportRequests() {
    yield effects.takeEvery(AnnotationActionTypes.EXPORT, handleExport);
}

function* watchSearchRequests() {
    yield effects.takeEvery(AnnotationActionTypes.SEARCH, handleSearch);
}

export function* AnnotationsSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchCreateAnnotation)]);
    yield effects.all([effects.fork(watchDeleteRequests)]);
    yield effects.all([effects.fork(watchDownload)]);
    yield effects.all([effects.fork(watchEditRequests)]);
    yield effects.all([effects.fork(watchExportRequests)]);
    yield effects.all([effects.fork(watchSearchRequests)]);
}
