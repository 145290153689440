import * as Proto from '../../Protos/protos';

export enum AnnotationActionTypes {
    CREATE_ANNOTATION = '@@annotation/CREATE_ANNOTATION',
    CREATE_ANNOTATION_ERROR = '@@annotation/CREATE_ANNOTATION_ERROR',
    CREATE_ANNOTATION_PROGRESS = '@@annotation/CREATE_ANNOTATION_PROGRESS',
    CREATE_ANNOTATION_SUCCESS = '@@annotation/CREATE_ANNOTATION_SUCCESS',
    DELETE = '@@annotation/DELETE',
    DELETE_MULTIPLE = '@@annotation/DELETE_MULTIPLE',
    DOWNLOAD = '@@annotation/DOWNLOAD',
    EDIT = '@@annotation/EDIT',
    EXPORT = '@@annotation/EXPORT',
    HIDE_DIALOG = '@@annotation/HIDE_DIALOG',
    OPEN_EDIT_DIALOG = '@@annotation/OPEN_EDIT_DIALOG',
    OP_SUCCESS = '@@annotation/OP_SUCCESS',
    SEARCH = '@@annotation/SEARCH',
    SET_LAST_SEARCH = '@@annotation/SET_LAST_SEARCH',
    SET_SEARCH_LOADING = '@@annotation/SET_SEARCH_LOADING',
    SET_SEARCH_RESULTS = '@@annotation/SET_SEARCH_RESULTS',
    SET_TIMELINE_ANNOTATION = '@@annotation/SET_TIMELINE_ANNOTATION',
    SHOW_DIALOG = '@@annotation/SHOW_DIALOG',
}

export interface IAnnotationsState {
    readonly createAnnotationError: string;
    readonly createAnnotationLoading: boolean;
    readonly createAnnotationSuccessEventElement: HTMLElement;
    readonly editedAnnotation: Proto.mediaarchiver.IAnnotation | null;
    readonly opSuccessEventElement: HTMLElement;
    readonly lastSearch: Proto.mediaarchiver.IArgumentsGetAnnotations | null;
    readonly searchLoading: boolean;
    readonly searchResults: Proto.mediaarchiver.IAnnotation[];
    readonly searchResultsCount: number;
    readonly showDialog: boolean;
    readonly timelineAnnotation: Proto.mediaarchiver.IAnnotation | null;
}

export interface IClearResultsAction {
    type: AnnotationActionTypes.SET_SEARCH_RESULTS;
}

export interface ICreateAnnotationAction {
    payload: Proto.mediaarchiver.ArgumentsCreateAnnotation;
    type: AnnotationActionTypes.CREATE_ANNOTATION;
}

export interface IDeleteAnnotationAction {
    payload: Proto.mediaarchiver.IAnnotation;
    type: AnnotationActionTypes.DELETE;
}

export interface IDeleteMultipleAnnotationsAction {
    payload: string[];
    type: AnnotationActionTypes.DELETE_MULTIPLE;
}

export interface IDownloadAction {
    payload: Proto.mediaarchiver.IAnnotationType;
    type: AnnotationActionTypes.DOWNLOAD;
}

export interface IEditAnnotationAction {
    payload: Proto.mediaarchiver.IAnnotation;
    type: AnnotationActionTypes.EDIT;
}

export interface IExportAnnotationsAction {
    payload: Proto.mediaarchiver.IArgumentsExportAnnotations;
    type: AnnotationActionTypes.EXPORT;
}

export interface IHideDialogAction {
    type: AnnotationActionTypes.HIDE_DIALOG;
}

export interface IOpenEditDialogAction {
    payload: Proto.mediaarchiver.IAnnotation;
    type: AnnotationActionTypes.OPEN_EDIT_DIALOG;
}

export interface ISearchAction {
    payload: Proto.mediaarchiver.IArgumentsGetAnnotations;
    type: AnnotationActionTypes.SEARCH;
}

export interface ISetCreateAnnotationErrorAction {
    payload: string;
    type: AnnotationActionTypes.CREATE_ANNOTATION_ERROR;
}

export interface ISetCreateAnnotationProgressAction {
    payload: boolean;
    type: AnnotationActionTypes.CREATE_ANNOTATION_PROGRESS;
}

export interface ISetCreateAnnotationSuccessAction {
    type: AnnotationActionTypes.CREATE_ANNOTATION_SUCCESS;
}

export interface ISetLastSearchAction {
    payload: Proto.mediaarchiver.IArgumentsGetAnnotations;
    type: AnnotationActionTypes.SET_LAST_SEARCH;
}

export interface ISetOpSuccessAction {
    payload: boolean;
    type: AnnotationActionTypes.OP_SUCCESS;
}

export interface ISetSearchLoadingAction {
    payload: boolean;
    type: AnnotationActionTypes.SET_SEARCH_LOADING;
}

export interface ISetTimelineAnnotationAction {
    payload: Proto.mediaarchiver.IAnnotation | null;
    type: AnnotationActionTypes.SET_TIMELINE_ANNOTATION;
}

export interface IShowDialogAction {
    type: AnnotationActionTypes.SHOW_DIALOG;
}
