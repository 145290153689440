import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class TalkGetDistricts extends AMessage implements IToCommand {
    private query: Proto.mediaarchiver.ITalkGetDistricts;

    public constructor(query: Proto.mediaarchiver.ITalkGetDistricts) {
        super('talkGetDistricts');
        this.query = query;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                TalkGetDistrictsArg: this.query,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeTalkGetDistricts,
            }),
        );
    }
}
