import { Store } from 'redux';
import { delay, effects } from 'redux-saga';

import { IApplicationState } from '..';
import { store } from '../store';
import { hideSnack, setSnackMessageNow, updateStack } from './Actions';
import { LayoutActionTypes } from './Types';

import { Logger } from '../../Utils/Logger';

function handleDrawerChanges(visible: boolean) {
    if (visible) {
        Logger.debug('Visible');
    } else {
        Logger.debug('PAS Visible');
    }
}

function* handleCloseSnack() {
    const str = (store as Store<IApplicationState>);
    const state = str.getState().layout;

    if (state.stackedSnackMessages.length === 0) {
        yield effects.call(str.dispatch.bind(str, hideSnack()));
        return;
    }
    const message = state.stackedSnackMessages[0];
    const newStack = state.stackedSnackMessages.slice(1);

    yield effects.call(str.dispatch.bind(str, updateStack(newStack)));
    yield effects.call(str.dispatch.bind(str, hideSnack()));
    yield delay(1000);
    yield effects.call(str.dispatch.bind(str, setSnackMessageNow(message)));
}

function* watchSidebarChanges() {
    yield effects.takeEvery(LayoutActionTypes.DRAWER_SHOW, handleDrawerChanges.bind(null, true));
    yield effects.takeEvery(LayoutActionTypes.DRAWER_HIDE, handleDrawerChanges.bind(null, false));
    yield effects.takeEvery(LayoutActionTypes.ON_CLOSE_SNACK, handleCloseSnack);
}

export function* LayoutSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchSidebarChanges)]);
}
