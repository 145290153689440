import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetEPG extends AMessage implements IToCommand {
    private end: Date;
    private lang: string;
    private mediaID: number;
    private start: Date;
    private types: Proto.mediaarchiver.EPGType[];

    public constructor(start: Date, end: Date, mediaID: number, types: Proto.mediaarchiver.EPGType[], lang = 'FR') {
        super('getEPG');
        if (start.getTime() >= end.getTime()) {
            throw new Error('Invalid dates');
        }
        if (mediaID < 1) {
            throw new Error('Invalid media id');
        }
        this.start = start;
        this.end = end;
        this.mediaID = mediaID;
        this.types = types;
        this.lang = lang;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                GetEPGArgs: new Proto.mediaarchiver.ArgumentsGetEPG({
                    From: this.start.getTime(),
                    Lang: this.lang,
                    MediaID: this.mediaID,
                    To: this.end.getTime(),
                    Types: this.types,
                }),
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetEPG,
            }),
        );
    }
}
