/* eslint-disable react/prop-types */
import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { AccountAnnotations } from '../../Components/Account/Annotations';
import AccountAnnotationTypes from '../../Components/Account/AnnotationTypes';
import AccountContacts from '../../Components/Account/Contacts';
import AccountDrawer from '../../Components/Account/Drawer';
import { AccountOptions } from '../../Components/Account/Options';
import AccountSFTPAccounts from '../../Components/Account/SFTPAccounts';
import { AccountTalks } from '../../Components/Account/Talks';
import Layout from '../../Components/Layout';
import { IConnectedReduxProps } from '../../Store';
import { I18N } from '../../Store/I18n';
import {
    setDrawerContent as setDrawerContentAction,
    setDrawerTitle as setDrawerTitleAction,
    setHasAnnotations as setHasAnnotationsAction,
    setPageContent as setPageContentAction,
    setPageTitle as setPageTitleAction,
} from '../../Store/Layout';

export interface IRouterMatchParams {
    subpage: string;
}

interface IPropsFromState {
    i18n: I18N;
    router: RouteComponentProps<IRouterMatchParams>;
}

interface IPropsFromDispatch {
    setDrawerContent: typeof setDrawerContentAction;
    setDrawerTitle: typeof setDrawerTitleAction;
    setHasAnnotations: typeof setHasAnnotationsAction;
    setPageContent: typeof setPageContentAction;
    setPageTitle: typeof setPageTitleAction;
}

type AllProps = IPropsFromState & IPropsFromDispatch & IConnectedReduxProps;

const AccountPage: React.FC<AllProps> = ({
    i18n,
    setPageTitle,
    setPageContent,
    router,
    setDrawerTitle,
    setHasAnnotations,
    setDrawerContent,
}) => {
    switch (router.match.params.subpage) {
        case 'annotations':
            setPageTitle(i18n._('My annotations'));
            setPageContent(<Route component={AccountAnnotations} />);
            break;
        case 'annotationTypes':
            setPageTitle(i18n._('My annotation types'));
            setPageContent(<Route component={AccountAnnotationTypes} />);
            break;
        case 'contacts':
            setPageTitle(i18n._('My contacts'));
            setPageContent(<Route component={AccountContacts} />);
            break;
        /*
        case 'ftp':
            setPageTitle(i18n._('My FTP accounts'));
            setPageContent(<Route component={AccountFTPAccounts} />);
            break;
        */
        case 'sftp':
            setPageTitle(i18n._('My SFTP accounts'));
            setPageContent(<Route component={AccountSFTPAccounts} />);
            break;
        case 'talks':
            setPageTitle(i18n._('My political talk times'));
            setPageContent(<Route component={AccountTalks} />);
            break;
        default:
        case 'options':
            setPageTitle(i18n._('My settings'));
            setPageContent(<Route component={AccountOptions} />);
            break;
    }
    setDrawerTitle(i18n._('My account'));
    setHasAnnotations(false);
    setDrawerContent(<Route component={AccountDrawer} />);
    return <Route component={Layout} />;
};

export { AccountPage };
