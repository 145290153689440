import { Reducer } from 'redux';

import { IIncidentState, IncidentActionTypes } from './Types';

const initialState: IIncidentState = {
    incidentContent: { incidentMessages: [] },
    showIncident: false,
};

const reducer: Reducer<IIncidentState> = (state = initialState, action) => {
    switch (action.type) {
        case IncidentActionTypes.HIDE_INCIDENT: {
            return { ...state, showIncident: false };
        }

        case IncidentActionTypes.SHOW_INCIDENT: {
            return { ...state, showIncident: true };
        }

        case IncidentActionTypes.UPDATE_INCIDENT_CONTENT: {
            return {
                ...state,
                incidentContent: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as IncidentReducer };
