import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import * as Proto from '../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { IAnnotationsState } from '../../Store/Annotations';
import {
    clearResults,
    deleteMultipleAnnotations,
    download,
    exportAnnotations,
    search as doSearch,
    setTimelineAnnotation,
} from '../../Store/Annotations/Actions';
import { IAnnotationTypesState, NullableAnnotationType } from '../../Store/AnnotationTypes';
import { get as getTypes } from '../../Store/AnnotationTypes/Actions';
import { I18N, ILocaleInfos } from '../../Store/I18n';
import { setSnackMessage } from '../../Store/Layout/Actions';
import { IMediasState } from '../../Store/Medias';
import { update as updateMedias } from '../../Store/Medias/Actions';
import { IUserState } from '../../Store/User/Types';
import { getTheme } from '../../Themes';
import { Logger } from '../../Utils/Logger';
import {
    getDate,
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../Utils/Various';
import { MediaSelector } from '../Medias/Selector';

const theme = getTheme();

const styles = MD.createStyles({
    datePickers: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        flex: '1 1 0px',
        padding: theme.spacing(1),
    },
    formControlClosable: {
        flexGrow: 0.00001,
        overflow: 'hidden',
        paddingLeft: 0,
        paddingRight: 0,
        transition: 'flex-grow 150ms linear',
    },
    formControlClosableActive: {
        '& button span': {
            whiteSpace: 'nowrap',
        },

        flexGrow: 1,
        paddingRight: theme.spacing(1),
    },
    formControlLabel: {
        paddingLeft: theme.spacing(1),
    },
    formControlNoLabel: {
        marginTop: theme.spacing(2),
    },
    headerCheckbox: {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
    },
    headerCheckboxActive: {
        '& svg': {
            color: 'rgb(0, 176, 255)',
        },
    },
    resultLoading: {
        fontSize: '3em',
        textAlign: 'center',
    },
    resultTitle: {
        '& span': {
            flex: 1,
        },

        display: 'flex',
        padding: theme.spacing(1),
    },
    resultTitleRoot: {
        height: 60,
        marginTop: theme.spacing(1),
    },
    root: {
        height: '100%',
        overflow: 'hidden',
        padding: theme.spacing(1),
        width: '100%',
    },
    rowCheckbox: {
        '& td:first-child': {
            cursor: 'pointer',
        },

        backgroundColor: theme.palette.background.default,
    },
    rowCheckboxChecked: {
        '& td:first-child svg': {
            color: 'rgb(0, 176, 255)',
        },

        backgroundColor: theme.palette.background.paper,
    },
    searchBox: {
        '&>form': {
            display: 'flex',
            height: '100%',
            width: '100%',
        },

        height: '100%',
        width: '100%',
    },
    searchBoxRoot: {
        height: 80,
    },
    searchButton: {},
    table: {
        minWidth: '100%',
        userSelect: 'text',
    },
    tableRoot: {
        maxHeight: 'calc(100% - 80px - 60px - 8px)',
        minHeight: 'calc(100% - 80px - 60px - 8px)',
        overflow: 'auto',
    },
});

interface IState {
    end: Date | null;
    endError: string;
    exporting: boolean;
    filterByDate: boolean;
    filterByMedia: boolean;
    launchedSearch: boolean;
    medias: number[];
    selected: string[];
    sortDirection: 'asc' | 'desc';
    sortedRow: string;
    start: Date | null;
    type: string;
}

interface IPropsFromState {
    annotationTypes: IAnnotationTypesState;
    annotations: IAnnotationsState;
    i18n: I18N;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
    router: RouteComponentProps<{}>;
    user: IUserState;
}

interface IPropsFromDispatch {
    clearResults: typeof clearResults;
    deleteMultipleAnnotations: typeof deleteMultipleAnnotations;
    doSearch: typeof doSearch;
    download: typeof download;
    exportAnnotations: typeof exportAnnotations;
    getTypes: typeof getTypes;
    setSnackMessage: typeof setSnackMessage;
    setTimelineAnnotation: typeof setTimelineAnnotation;
    updateMedias: typeof updateMedias;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class AccountAnnotationsComponent extends React.Component<AllProps, IState> {
    private currentSearch: Proto.mediaarchiver.IArgumentsGetAnnotations = {};
    private triggeredLastElement: HTMLTableRowElement = document.createElement('tr');

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
    }

    public componentDidMount(): void {
        this.props.getTypes(0, 1000);
        this.props.updateMedias();
        this.props.clearResults();
        this.triggeredLastElement = document.createElement('tr');
    }

    public render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid container className={classes.root}>
                <MD.Grid className={classes.searchBoxRoot} item xs={12}>
                    <MD.Paper className={classes.searchBox} elevation={2}>
                        <form>
                            {this.buildTypeSelect()}
                            {this.buildMediaSelect()}
                            {this.buildDatesInput()}
                            {this.buildSearchButton()}
                        </form>
                    </MD.Paper>
                </MD.Grid>
                {this.props.annotations.searchLoading &&
                this.props.annotations.searchResults.length === 0 &&
                this.state.launchedSearch ? (
                    <MD.Grid className={classes.resultLoading} item xs={12}>
                        <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                    </MD.Grid>
                ) : (
                    ''
                )}
                {this.props.annotations.searchResults.length !== 0 && this.state.launchedSearch ? (
                    <>
                        <MD.Grid className={classes.resultTitleRoot} item xs={12}>
                            <MD.Paper className={classes.resultTitle} elevation={1}>
                                <MD.Typography component='span' variant='h5'>
                                    {this.props.i18n.sprintf(
                                        this.props.i18n.ngettext(
                                            '%1$d result',
                                            '%1$d results',
                                            this.props.annotations.searchResultsCount,
                                        ),
                                        this.props.annotations.searchResultsCount,
                                    )}
                                    {this.state.selected.length > 0
                                        ? ', ' +
                                          this.props.i18n.sprintf(
                                              this.props.i18n.ngettext(
                                                  '%1$d selected',
                                                  '%1$d selected',
                                                  this.state.selected.length,
                                              ),
                                              this.state.selected.length,
                                          )
                                        : ''}
                                </MD.Typography>
                                <MD.Button
                                    disabled={this.state.exporting}
                                    onClick={() => {
                                        if (isNullOrUndefinedOrEmptyString(this.state.type)) {
                                            return;
                                        }
                                        const args: Proto.mediaarchiver.IArgumentsExportAnnotations = {
                                            Type: this.state.type,
                                        };

                                        if (this.state.filterByDate) {
                                            if (!isNullOrUndefined(this.state.start)) {
                                                args.DateStart = this.state.start.getTime();
                                            }
                                            if (!isNullOrUndefined(this.state.end)) {
                                                args.DateEnd = this.state.end.getTime();
                                            }
                                        }
                                        if (this.state.filterByMedia) {
                                            args.Medias = this.state.medias;
                                        }

                                        this.setState({ exporting: true });
                                        this.props.exportAnnotations(args);
                                        window.setTimeout(() => {
                                            this.setState({ exporting: false });
                                        }, 2000);
                                    }}
                                    startIcon={<FontAwesomeIcon icon={FA.faFileExcel} />}
                                    variant='outlined'
                                >
                                    {this.state.exporting
                                        ? this.props.i18n._('Export is in progress ...')
                                        : this.props.i18n._('Export results')}
                                </MD.Button>
                                {this.state.selected.length > 0 ? (
                                    <MD.Button
                                        onClick={this.deleteSelected.bind(this)}
                                        startIcon={<FontAwesomeIcon icon={FA.faTrash} />}
                                        variant='outlined'
                                    >
                                        {this.props.i18n._('Delete selected')}
                                    </MD.Button>
                                ) : (
                                    ''
                                )}
                            </MD.Paper>
                        </MD.Grid>

                        <MD.TableContainer
                            className={classes.tableRoot}
                            component={MD.Grid}
                            item
                            xs={12}
                            onScroll={this.checkScroll.bind(this)}
                        >
                            <MD.Table className={classes.table} size='small' stickyHeader>
                                {this.buildTableHead()}
                                {this.buildTableRows()}
                            </MD.Table>
                        </MD.TableContainer>
                    </>
                ) : (
                    ''
                )}
                {this.buildAnnotationDialog()}
            </MD.Grid>
        );
    }

    private buildTypeSelect() {
        return (
            <MD.FormControl className={this.props.classes.formControl}>
                <MD.InputLabel className={this.props.classes.formControlLabel} id='searchAnnotationTypeLabel' required>
                    {this.props.i18n._('Annotation type')}
                </MD.InputLabel>
                <MD.Select
                    labelId='searchAnnotationTypeLabel'
                    onChange={this.onTypeChange.bind(this)}
                    value={this.state.type}
                >
                    <MD.MenuItem value='none'>{this.props.i18n._('Choose an annotation type')}</MD.MenuItem>
                    {this.props.annotationTypes.annotationTypes.map((t: NullableAnnotationType) => {
                        if (isNullOrUndefined(t)) {
                            return '';
                        }
                        return (
                            <MD.MenuItem key={`annotation_select_${t.ID}`} value={t.ID}>
                                {`${t.Name} (${t.Count})`}
                            </MD.MenuItem>
                        );
                    })}
                </MD.Select>
            </MD.FormControl>
        );
    }

    private onTypeChange(ev: React.ChangeEvent<{ name?: string | undefined }>) {
        let value = (ev.target as HTMLSelectElement).value;

        if (value === '') {
            value = 'none';
        }
        this.props.clearResults();
        this.triggeredLastElement = document.createElement('tr');
        this.setState({ launchedSearch: false, sortDirection: 'asc', sortedRow: 'date', type: value });
    }

    private buildMediaSelect() {
        const labelClassName = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlNoLabel]: true,
        });
        const inputClassName = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlNoLabel]: true,
            [this.props.classes.formControlClosable]: true,
            [this.props.classes.formControlClosableActive]: this.state.filterByMedia,
        });

        return (
            <>
                <MD.FormControl className={labelClassName} required={this.state.filterByMedia}>
                    <MD.FormLabel htmlFor='searchMedias'>
                        <MD.Checkbox
                            checked={this.state.filterByMedia}
                            onClick={() => {
                                this.setState({ filterByMedia: !this.state.filterByMedia, launchedSearch: false });
                            }}
                        />
                        {this.props.i18n._('Filter by medias')}
                    </MD.FormLabel>
                </MD.FormControl>
                <MD.FormControl className={inputClassName}>
                    <Route
                        render={(props) => (
                            <MediaSelector
                                {...props}
                                dispatch={this.props.dispatch}
                                id={'searchMedias'}
                                //initialMedias={this.state.medias}
                                onChange={(medias: number[]) => {
                                    this.setState({ launchedSearch: false, medias });
                                }}
                                variant='outlined'
                            />
                        )}
                    />
                </MD.FormControl>
            </>
        );
    }

    private buildDatesInput() {
        const className = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlNoLabel]: true,
        });

        return (
            <>
                <MD.FormControl className={className}>
                    <MD.FormLabel>
                        <MD.Checkbox
                            checked={this.state.filterByDate}
                            onClick={() => {
                                this.setState({ filterByDate: !this.state.filterByDate, launchedSearch: false });
                            }}
                        />
                        {this.props.i18n._('Filter by date')}
                    </MD.FormLabel>
                </MD.FormControl>
                {this.buildStartInput()}
                {this.buildEndInput()}
            </>
        );
    }

    private buildStartInput() {
        const { classes } = this.props;
        const className = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlClosable]: true,
            [this.props.classes.formControlClosableActive]: this.state.filterByDate,
        });
        const today = new Date();
        const startCapta = new Date(today.getFullYear() - 6, today.getMonth(), 1, 0, 0, 0, 0);
        const startMinDate = startCapta;
        const startMaxDate = !isNullOrUndefined(this.state.end) ? this.state.end : moment().endOf('day');

        return (
            <MD.FormControl className={className}>
                <DatePicker
                    autoOk={true}
                    className={classes.datePickers}
                    disableToolbar
                    format={this.props.localeInfos.formatLongDate}
                    // keyboardIcon={<FontAwesomeIcon icon={FA.faCalendar} />}
                    InputProps={{
                        endAdornment: (
                            <MD.InputAdornment position='end'>
                                <MD.IconButton
                                    onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        this.setState({ endError: '', launchedSearch: false, start: null });
                                    }}
                                >
                                    <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                </MD.IconButton>
                            </MD.InputAdornment>
                        ),
                    }}
                    label={this.props.i18n._('Start date')}
                    leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                    // locale={this.props.localeInfos.momentLocale}
                    maxDate={startMaxDate}
                    minDate={startMinDate}
                    onChange={(val: Date | moment.Moment | null): void => {
                        let start = getDate(val);
                        let endError = '';

                        if (!isNullOrUndefined(start)) {
                            start = moment(start).startOf('day').toDate();
                            if (!isNullOrUndefined(this.state.end) && this.state.end.getTime() < start.getTime()) {
                                endError = this.props.i18n._('End date cannot be before start date');
                            }
                        }
                        this.setState({ endError, launchedSearch: false, start });
                    }}
                    openTo='year'
                    rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                    value={this.state.start}
                    variant='inline'
                    views={['year', 'month', 'date']}
                />
            </MD.FormControl>
        );
    }

    private buildEndInput() {
        const { classes } = this.props;
        const className = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlClosable]: true,
            [this.props.classes.formControlClosableActive]: this.state.filterByDate,
        });
        const today = new Date();
        const startCapta = new Date(today.getFullYear() - 6, today.getMonth(), 1, 0, 0, 0, 0);
        const endMinDate = !isNullOrUndefined(this.state.start) ? this.state.start : startCapta;
        const endMaxDate = today;

        return (
            <MD.FormControl className={className}>
                <DatePicker
                    autoOk={true}
                    className={classes.datePickers}
                    disableToolbar
                    error={this.state.endError !== ''}
                    format={this.props.localeInfos.formatLongDate}
                    helperText={this.state.endError}
                    // keyboardIcon={<FontAwesomeIcon icon={FA.faCalendar} />}
                    InputProps={{
                        endAdornment: (
                            <MD.InputAdornment position='end'>
                                <MD.IconButton
                                    onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        this.setState({ end: null, endError: '', launchedSearch: false });
                                    }}
                                >
                                    <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                </MD.IconButton>
                            </MD.InputAdornment>
                        ),
                    }}
                    label={this.props.i18n._('End date')}
                    leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                    // locale={this.props.localeInfos.momentLocale}
                    maxDate={endMaxDate}
                    minDate={endMinDate}
                    onChange={(val: Date | moment.Moment | null): void => {
                        let end = getDate(val);
                        let endError = '';

                        if (!isNullOrUndefined(end)) {
                            end = moment(end).endOf('day').toDate();
                            if (!isNullOrUndefined(this.state.start) && this.state.start.getTime() > end.getTime()) {
                                endError = this.props.i18n._('End date cannot be before start date');
                            }
                        }
                        this.setState({ end, endError, launchedSearch: false });
                    }}
                    openTo='year'
                    rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                    value={this.state.end}
                    variant='inline'
                    views={['year', 'month', 'date']}
                />
            </MD.FormControl>
        );
    }

    private buildSearchButton() {
        const className = classNames({
            [this.props.classes.formControl]: true,
            [this.props.classes.formControlNoLabel]: true,
        });

        return (
            <MD.FormControl className={className}>
                <MD.Button
                    className={this.props.classes.searchButton}
                    color={this.isSearchReady() ? 'secondary' : 'primary'}
                    disabled={!this.isSearchReady()}
                    onClick={this.onSearch.bind(this, true)}
                    variant='outlined'
                >
                    {this.props.i18n._('Search')}
                </MD.Button>
            </MD.FormControl>
        );
    }

    private isSearchReady() {
        return (
            (!this.state.filterByMedia || this.state.medias.length > 0) &&
            this.state.type !== 'none' &&
            (!this.state.filterByDate || this.state.endError === '')
        );
    }

    private onSearch() {
        if (!this.isSearchReady()) {
            return;
        }

        const search: Proto.mediaarchiver.IArgumentsGetAnnotations = {
            Limit: 100,
            Offset: 0,
            Sort: 'date',
            SortDesc: false,
            Type: this.state.type,
        };

        if (this.state.filterByDate) {
            if (!isNullOrUndefined(this.state.start)) {
                search.DateStart = this.state.start.getTime();
            }
            if (!isNullOrUndefined(this.state.end)) {
                search.DateEnd = this.state.end.getTime();
            }
        }
        if (this.state.filterByMedia) {
            search.Medias = this.state.medias;
        }
        this.currentSearch = search;
        this.triggeredLastElement = document.createElement('tr');
        this.props.clearResults();
        this.props.doSearch(this.currentSearch);
        this.setState({ launchedSearch: true, sortDirection: 'asc', sortedRow: 'date' });
    }

    private getType(): Proto.mediaarchiver.AnnotationType | null {
        let res: Proto.mediaarchiver.AnnotationType | null = null;

        this.props.annotationTypes.annotationTypes.some((type: NullableAnnotationType) => {
            if (!isNullOrUndefined(type) && type.ID === this.state.type) {
                res = type;
                return true;
            }
            return false;
        });

        return res;
    }

    private buildTableHead(): React.ReactNode {
        const aType = this.getType();

        if (isNullOrUndefined(aType)) {
            return <MD.TableBody style={{ display: 'none' }} />;
        }
        return (
            <MD.TableHead>
                <MD.TableRow>
                    <MD.TableCell colSpan={2} padding='checkbox' />
                    <MD.TableCell sortDirection={this.state.sortedRow === 'media' ? this.state.sortDirection : false}>
                        <MD.TableSortLabel
                            active={this.state.sortedRow === 'media'}
                            direction={this.state.sortDirection}
                            onClick={this.changeSort.bind(this, 'media')}
                        >
                            {this.props.i18n._('Media')}
                        </MD.TableSortLabel>
                    </MD.TableCell>
                    <MD.TableCell sortDirection={this.state.sortedRow === 'date' ? this.state.sortDirection : false}>
                        <MD.TableSortLabel
                            active={this.state.sortedRow === 'date'}
                            direction={this.state.sortDirection}
                            onClick={this.changeSort.bind(this, 'date')}
                        >
                            {this.props.i18n._('Date')}
                        </MD.TableSortLabel>
                    </MD.TableCell>
                    <MD.TableCell>{this.props.i18n._('Hour')}</MD.TableCell>
                    <MD.TableCell
                        sortDirection={this.state.sortedRow === 'duration' ? this.state.sortDirection : false}
                    >
                        <MD.TableSortLabel
                            active={this.state.sortedRow === 'duration'}
                            direction={this.state.sortDirection}
                            onClick={this.changeSort.bind(this, 'duration')}
                        >
                            {this.props.i18n._('Duration')}
                        </MD.TableSortLabel>
                    </MD.TableCell>
                    {aType.Fields.map((field: Proto.mediaarchiver.IAnnotationTypeField) => (
                        <MD.TableCell key={`table_header_${field.Order}`}>{field.Name}</MD.TableCell>
                    ))}
                </MD.TableRow>
            </MD.TableHead>
        );
    }

    private changeSort(sortedRow: string) {
        let sortDirection: 'asc' | 'desc' = 'desc';

        if (this.state.sortedRow === sortedRow) {
            sortDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc';
        }
        this.setState({ sortDirection, sortedRow }, () => {
            this.props.clearResults();
            this.triggeredLastElement = document.createElement('tr');
            this.currentSearch.Offset = 0;
            this.currentSearch.Sort = sortedRow;
            this.currentSearch.SortDesc = sortDirection === 'desc';
            this.props.doSearch(this.currentSearch);
        });
    }

    private sortByMedia(a: Proto.mediaarchiver.IAnnotation, b: Proto.mediaarchiver.IAnnotation): number {
        if (isNullOrUndefined(a) || isNullOrUndefined(a.MediaID)) {
            return -1;
        }
        if (isNullOrUndefined(b) || isNullOrUndefined(b.MediaID)) {
            return -1;
        }
        return a.MediaID === b.MediaID ? 0 : a.MediaID > b.MediaID ? 1 : -1;
    }

    private sortByDate(a: Proto.mediaarchiver.IAnnotation, b: Proto.mediaarchiver.IAnnotation): number {
        if (isNullOrUndefined(a) || isNullOrUndefined(a.Start)) {
            return -1;
        }
        if (isNullOrUndefined(b) || isNullOrUndefined(b.Start)) {
            return -1;
        }
        return a.Start === b.Start ? 0 : a.Start > b.Start ? 1 : -1;
    }

    private sortByDuration(a: Proto.mediaarchiver.IAnnotation, b: Proto.mediaarchiver.IAnnotation): number {
        if (isNullOrUndefined(a) || isNullOrUndefined(a.DurationMS)) {
            return -1;
        }
        if (isNullOrUndefined(b) || isNullOrUndefined(b.DurationMS)) {
            return -1;
        }
        return a.DurationMS === b.DurationMS ? 0 : a.DurationMS > b.DurationMS ? 1 : -1;
    }

    private sortByField(a: Proto.mediaarchiver.IAnnotation, b: Proto.mediaarchiver.IAnnotation, field: string): number {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        let datasA: any = null;
        let datasB: any = null;
        let dataA: any = 0;
        let dataB: any = 0;
        /* eslint-enable @typescript-eslint/no-explicit-any */

        if (isNullOrUndefined(a) || isNullOrUndefinedOrEmptyString(a.Data)) {
            return -1;
        }
        if (isNullOrUndefined(b) || isNullOrUndefinedOrEmptyString(b.Data)) {
            return -1;
        }
        try {
            datasA = JSON.parse(a.Data);
            if (!Array.isArray(datasA)) {
                return -1;
            }
        } catch (e) {
            return -1;
        }
        try {
            datasB = JSON.parse(b.Data);
            if (!Array.isArray(datasA)) {
                return 1;
            }
        } catch (e) {
            return 1;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        datasA.some((v: any) => {
            if (typeof v === 'object' && 'label' in v && typeof v.label === 'string' && v.label === field) {
                dataA = v;
                return true;
            }
            return false;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        datasB.some((v: any) => {
            if (typeof v === 'object' && 'label' in v && typeof v.label === 'string' && v.label === field) {
                dataB = v;
                return true;
            }
            return false;
        });
        if (!dataA.label || !dataA.type || !dataA.value) {
            return -1;
        }
        if (!dataB.label || !dataB.type || !dataB.value) {
            return 1;
        }
        if (dataA.type !== dataB.type) {
            return 0;
        }
        switch (dataA.type) {
            case 'shortText':
            case 'longText':
            case 'number':
                const valueA = dataA.value.toString().toLocaleLowerCase();
                const valueB = dataB.value.toString().toLocaleLowerCase();

                return valueA === valueB ? 0 : valueA > valueB ? 1 : -1;
            case 'boolean':
                if ((dataA.value === '1' && dataB.value === '1') || (dataA.value === '0' && dataB.value === '0')) {
                    return 0;
                }
                if (dataA.value === '1') {
                    return 1;
                }
                return -1;
            case 'date':
                const timeA = dataA.value.getTime();
                const timeB = dataB.value.getTime();

                return timeA === timeB ? 0 : timeA > timeB ? 1 : -1;
            case 'list':
                try {
                    const countA = JSON.parse(dataA.value).length;
                    const countB = JSON.parse(dataB.value).length;

                    return countA === countB ? 0 : countA > countB ? 1 : -1;
                } catch (err) {
                    Logger.warn(err as string);
                    return 0;
                }
            default:
                return 0;
        }
    }

    private buildTableRows(): React.ReactNode {
        const build = this.buildTableRow.bind(this);

        return <MD.TableBody>{this.props.annotations.searchResults.map(build)}</MD.TableBody>;
    }

    private buildTableRow(annotation: Proto.mediaarchiver.IAnnotation): React.ReactNode {
        const aType = this.getType();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let data: any = null;

        if (isNullOrUndefined(aType) || isNullOrUndefined(annotation.ID) || isNullOrUndefined(annotation.Data)) {
            return <MD.TableRow key={Math.random().toString()} style={{ display: 'none' }} />;
        }
        try {
            data = JSON.parse(annotation.Data);
            if (!Array.isArray(data)) {
                data = [];
            }
        } catch (err) {
            Logger.warn(err as string);
            data = [];
        }

        const rowClasses = classNames({
            [this.props.classes.rowCheckbox]: true,
            [this.props.classes.rowCheckboxChecked]: this.state.selected.indexOf(annotation.ID) !== -1,
        });
        const icon = this.state.selected.indexOf(annotation.ID) !== -1 ? FA.faCheckSquare : FA.faSquare;
        const checkLabel =
            this.state.selected.indexOf(annotation.ID) === -1
                ? this.props.i18n._('Select annotation')
                : this.props.i18n._('Unselect annotation');

        return (
            <MD.TableRow
                className={rowClasses}
                key={`table_annotation_${annotation.ID}`}
                id={`table_annotation_${annotation.ID}`}
            >
                <MD.Tooltip title={checkLabel}>
                    <MD.TableCell
                        onClick={() => {
                            if (annotation.Owner !== this.props.user.user.id || typeof annotation.ID !== 'string') {
                                return;
                            }
                            const selected =
                                this.state.selected.indexOf(annotation.ID) === -1
                                    ? this.state.selected.concat([annotation.ID])
                                    : this.state.selected.filter((i) => i !== annotation.ID);

                            this.setState({ selected });
                        }}
                        padding='checkbox'
                    >
                        {annotation.Owner === this.props.user.user.id ? <FontAwesomeIcon icon={icon} /> : ''}
                    </MD.TableCell>
                </MD.Tooltip>
                <MD.TableCell padding='checkbox'>
                    <MD.Tooltip title={this.props.i18n._('See annotation in timeline')}>
                        <MD.Button
                            onClick={() => {
                                this.props.setTimelineAnnotation(annotation);
                                this.props.router.history.push('/timeline');
                            }}
                        >
                            <FontAwesomeIcon icon={FA.faPlay} />
                        </MD.Button>
                    </MD.Tooltip>
                </MD.TableCell>
                <MD.TableCell>{this.getMediaName(annotation)}</MD.TableCell>
                <MD.TableCell>{this.getDate(annotation)}</MD.TableCell>
                <MD.TableCell>{this.getHour(annotation)}</MD.TableCell>
                <MD.TableCell>{this.getDuration(annotation)}</MD.TableCell>
                {aType.Fields.map((field: Proto.mediaarchiver.IAnnotationTypeField) => (
                    <MD.TableCell key={`table_row_${annotation.ID}_${field.Order}`}>
                        {this.renderField(field, data)}
                    </MD.TableCell>
                ))}
            </MD.TableRow>
        );
    }

    private getMediaName(annotation: Proto.mediaarchiver.IAnnotation): string {
        const mediaID = annotation.MediaID || 0;
        let mediaName = 'N/A';

        this.props.medias.data.some((media) => {
            if (media.id === mediaID) {
                mediaName = media.name;
                return true;
            }
            return false;
        });
        return mediaName;
    }

    private getDate(annotation: Proto.mediaarchiver.IAnnotation): string {
        if (isNullOrUndefinedOrZero(annotation.Start)) {
            return '';
        }
        return moment(new Date(annotation.Start as number)).format(this.props.localeInfos.formatShortDate);
    }

    private getHour(annotation: Proto.mediaarchiver.IAnnotation): string {
        if (isNullOrUndefinedOrZero(annotation.Start)) {
            return '';
        }
        return moment(new Date(annotation.Start as number)).format(
            this.props.localeInfos.formatShortTimeWithMilliseconds,
        );
    }

    private getDuration(annotation: Proto.mediaarchiver.IAnnotation): string {
        const durationMS = annotation.DurationMS || 0;
        const duration = moment.duration(durationMS);

        return (
            ('0' + duration.hours().toString()).slice(-2) +
            ':' +
            ('0' + duration.minutes().toString()).slice(-2) +
            ':' +
            ('0' + duration.seconds().toString()).slice(-2)
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private renderField(fieldDesc: Proto.mediaarchiver.IAnnotationTypeField, data: any[]): string {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let field: any = null;

        data.some((v) => {
            if (typeof v === 'object' && 'label' in v && typeof v.label === 'string' && v.label === fieldDesc.Name) {
                field = v;
                return true;
            }
            return false;
        });

        if (isNull(field)) {
            return '';
        }

        if (!field.label || !field.type || !field.value) {
            return '';
        }
        switch (field.type) {
            case 'shortText':
            case 'longText':
            case 'number':
                return field.value.length > 50 ? field.value.substr(0, 47) + ' ...' : field.value;
            case 'boolean':
                return field.value === '1' ? this.props.i18n._('Yes') : this.props.i18n._('No');
            case 'date':
                const date = new Date(parseInt(field.value, 10) * 1000);

                if (isNaN(date.getTime())) {
                    return '⚠️';
                }
                return moment(date).format(
                    field.special ? this.props.localeInfos.formatLongDateTime : this.props.localeInfos.formatLongDate,
                );
            case 'list':
                try {
                    const values = JSON.parse(field.value);

                    if (!Array.isArray(values)) {
                        return '⚠️';
                    }
                    return values.join(', ');
                } catch (err) {
                    if (typeof field.value === 'string') {
                        return field.value;
                    } else {
                        Logger.warn(err as string);
                        return '⚠️';
                    }
                }
                break;
            default:
                return '⚠️';
        }
    }

    private buildAnnotationDialog() {
        return (
            <MD.Dialog open={false}>
                <MD.DialogTitle>Titre</MD.DialogTitle>
                <MD.DialogContent />
            </MD.Dialog>
        );
    }

    private deleteSelected(): void {
        if (this.state.selected.length === 0) {
            return;
        }
        this.props.deleteMultipleAnnotations(this.state.selected);
        window.setTimeout(() => {
            this.setState({ selected: [] });
            this.onSearch();
        }, 500);
    }

    private checkScroll(ev: React.UIEvent<HTMLDivElement, UIEvent>) {
        const container = ev.target as HTMLDivElement;
        if (isNullOrUndefined(container)) {
            return;
        }
        if (container.scrollTop + container.clientHeight < container.scrollHeight - 200) {
            return;
        }
        const tables = container.getElementsByTagName('table');
        if (tables.length === 0) {
            return;
        }
        const trs = tables[0].getElementsByTagName('tr');
        if (trs.length === 0) {
            return;
        }
        if (this.triggeredLastElement === trs[trs.length - 1]) {
            return;
        }
        this.triggeredLastElement = trs[trs.length - 1] as HTMLTableRowElement;
        if (isNullOrUndefined(this.currentSearch.Offset)) {
            this.currentSearch.Offset = 0;
        }
        if (this.currentSearch.Offset + 100 < this.props.annotations.searchResultsCount) {
            this.currentSearch.Offset = this.currentSearch.Offset + 100;
            this.props.doSearch(this.currentSearch);
        }
    }

    private getDefaultState(): IState {
        if (!isNullOrUndefined(this.props.annotations.lastSearch)) {
            return {
                end: !isNullOrUndefined(this.props.annotations.lastSearch.DateEnd)
                    ? new Date(this.props.annotations.lastSearch.DateEnd as number)
                    : null,
                endError: '',
                exporting: false,
                filterByDate:
                    !isNullOrUndefined(this.props.annotations.lastSearch.DateEnd) &&
                    !isNullOrUndefined(this.props.annotations.lastSearch.DateStart),
                filterByMedia:
                    !isNullOrUndefined(this.props.annotations.lastSearch.Medias) &&
                    this.props.annotations.lastSearch.Medias.length > 0,
                launchedSearch: false,
                medias: !isNullOrUndefined(this.props.annotations.lastSearch.Medias)
                    ? this.props.annotations.lastSearch.Medias
                    : [],
                selected: [],
                sortDirection: !isNullOrUndefined(this.props.annotations.lastSearch.SortDesc)
                    ? this.props.annotations.lastSearch.SortDesc
                        ? 'desc'
                        : 'asc'
                    : 'desc',
                sortedRow: !isNullOrUndefined(this.props.annotations.lastSearch.Sort)
                    ? this.props.annotations.lastSearch.Sort
                    : 'date',
                start: !isNullOrUndefined(this.props.annotations.lastSearch.DateStart)
                    ? new Date(this.props.annotations.lastSearch.DateStart as number)
                    : null,
                type: !isNullOrUndefined(this.props.annotations.lastSearch.Type)
                    ? this.props.annotations.lastSearch.Type
                    : 'none',
            };
        }
        return {
            end: null,
            endError: '',
            exporting: false,
            filterByDate: false,
            filterByMedia: false,
            launchedSearch: false,
            medias: [],
            selected: [],
            sortDirection: 'desc',
            sortedRow: 'date',
            start: null,
            type: 'none',
        };
    }
}

const mapStateToProps = (
    { annotationTypes, annotations, i18n, medias, user }: IApplicationState,
    ownProps: RouteComponentProps<{}>,
) => ({
    annotationTypes,
    annotations,
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    medias,
    router: ownProps,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearResults: () => dispatch(clearResults()),
    deleteMultipleAnnotations: (ids: string[]) => dispatch(deleteMultipleAnnotations(ids)),
    doSearch: (query: Proto.mediaarchiver.IArgumentsGetAnnotations) => dispatch(doSearch(query)),
    download: (aType: Proto.mediaarchiver.IAnnotationType) => dispatch(download(aType)),
    exportAnnotations: (a: Proto.mediaarchiver.IArgumentsExportAnnotations) => dispatch(exportAnnotations(a)),
    getTypes: () => dispatch(getTypes()),
    setSnackMessage: (msg: string) => dispatch(setSnackMessage(msg)),
    setTimelineAnnotation: (annotation: Proto.mediaarchiver.IAnnotation | null) =>
        dispatch(setTimelineAnnotation(annotation)),
    updateMedias: () => dispatch(updateMedias()),
});

export const AccountAnnotations = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(AccountAnnotationsComponent));
