import { createStyles } from '@material-ui/core';
import { getTheme } from '../../Themes';

const theme = getTheme();

export default createStyles({
    activeDay: {
        backgroundColor: theme.mediaarchiver.colors.calendarActiveDayBackground,
        color: theme.mediaarchiver.colors.calendarActiveDayText,
    },
    disabledDay: {
        color: theme.palette.text.disabled,
        cursor: 'forbidden',
    },
    enabledDay: {
        color: theme.palette.text.primary,
        cursor: 'pointer',
    },
    monthPicker: {
        '&>div': {
            float: 'left',
        },

        marginBottom: 30,
        width: '100%',
    },
    monthPickerMonth: {
        textAlign: 'center',
        width: '40%',
    },
    monthPickerStep: {
        '&>a': {
            color: theme.palette.text.primary,
            textDecoration: 'none',
        },

        width: '10%',
    },
    monthPickerYear: {
        textAlign: 'center',
        width: '40%',
    },
    root: {
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        borderWidth: 1,
        color: theme.palette.text.primary,
        // margin: theme.spacing(1),
        // marginTop: theme.spacing(1) * 2,
        padding: theme.spacing(1),
    },
    selectDuration: {
        '&>select': {
            margin: '0px 4px',
        },

        textAlign: 'center',
    },
    selectHour: {
        '&>select': {
            margin: '0px 4px',
        },

        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1) * 2,
        textAlign: 'center',
    },
    table: {
        '&>tbody>tr>td': {
            padding: 0,
            textAlign: 'center',
        },
        '&>thead': {
            fontWeight: 'bold',
            marginBottom: 10,
            width: '100%',
        },

        width: '100%',
    },
    today: {
        backgroundColor:
            theme.mediaarchiver.colors.calendarCurrentDayBackground,
        color: theme.mediaarchiver.colors.calendarCurrentDayText,
    },
});
