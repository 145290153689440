import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import * as React from 'react';
import ReactList from 'react-list';
import * as Proto from '../../../Protos/protos';
import { IConnectedReduxProps } from '../../../Store';
import { I18N } from '../../../Store/I18n';
import { setPageTitle, setSnackMessage } from '../../../Store/Layout';
import {
    create,
    deleteAccount,
    edit,
    get,
    getCount,
    ISFTPAccountFormErrors,
    ISFTPAccountsState,
} from '../../../Store/SFTPAccounts';
import { getTheme } from '../../../Themes';
import { Logger } from '../../../Utils/Logger';
import { capitalize, ensureString } from '../../../Utils/String';
import { isNullOrUndefined } from '../../../Utils/Various';
import styles from './style';

interface IState {
    editingAccount: Proto.mediaarchiver.ISFTPAccount;
    errors: ISFTPAccountFormErrors;
    loading: boolean;
}

interface IPropsFromState {
    accounts: ISFTPAccountsState;
    i18n: I18N;
}

interface IPropsFromDispatch {
    create: typeof create;
    delete: typeof deleteAccount;
    edit: typeof edit;
    get: typeof get;
    getCount: typeof getCount;
    setPageTitle: typeof setPageTitle;
    setSnackMessage: typeof setSnackMessage;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & IPropsFromDispatch & IConnectedReduxProps;

const theme = getTheme();

export default class AccountSFTPAccountsComponent extends React.Component<AllProps, IState> {
    private errorCallback: (ev: CustomEvent<ISFTPAccountFormErrors>) => void;
    private successCallback: (ev: CustomEvent<{}>) => void;

    private alreadyAskedOffsets = [0];

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
        this.successCallback = this.onOpSuccess.bind(this);
        this.errorCallback = this.onOpError.bind(this);
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public componentDidMount(): void {
        this.props.setPageTitle(this.props.i18n._('My SFTP accounts'));
        this.props.get(0, 1000);
        this.props.getCount();

        this.props.accounts.opSuccessEventElement.addEventListener<any>('opSuccess', this.successCallback);
        this.props.accounts.opSuccessEventElement.addEventListener<any>('opError', this.errorCallback);
    }

    public componentWillUnmount(): void {
        this.props.accounts.opSuccessEventElement.removeEventListener<any>('opSuccess', this.successCallback);
        this.props.accounts.opSuccessEventElement.removeEventListener<any>('opError', this.errorCallback);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    public render(): React.ReactNode {
        return (
            <MD.Grid container={true} className={this.props.classes.root} spacing={0}>
                <MD.Grid className={this.props.classes.listContainer} item={true} sm={12} lg={8}>
                    <MD.Paper className={this.props.classes.listContainerContent}>
                        <MD.Typography variant='h5'>
                            {this.props.i18n._('SFTP accounts list')} ({this.props.accounts.total})
                        </MD.Typography>
                        <MD.List className={this.props.classes.listListContainer}>
                            <ReactList
                                itemRenderer={this.renderAccount.bind(this)}
                                length={this.props.accounts.total}
                                type='uniform'
                            />
                        </MD.List>
                    </MD.Paper>
                </MD.Grid>
                <MD.Grid className={this.props.classes.createContainer} item={true} sm={12} lg={4}>
                    <MD.Paper className={this.props.classes.createContainerContent}>
                        <MD.Typography variant='h5'>
                            {ensureString(this.state.editingAccount.ID) === ''
                                ? this.props.i18n._('Create an account')
                                : this.props.i18n._('Edit account')}
                        </MD.Typography>
                        <form
                            autoComplete='off'
                            className={this.props.classes.form}
                            style={{
                                opacity: this.props.accounts.operationInProgress ? 0.2 : 1,
                            }}
                        >
                            <MD.TextField
                                disabled={this.props.accounts.operationInProgress}
                                error={this.state.errors.name !== ''}
                                helperText={this.state.errors.name}
                                label={this.props.i18n._('Account name')}
                                onChange={this.handleFormChange.bind(this, 'Name')}
                                value={this.state.editingAccount.Name}
                            />
                            <MD.TextField
                                disabled={this.props.accounts.operationInProgress}
                                error={this.state.errors.hostname !== ''}
                                helperText={this.state.errors.hostname}
                                label={this.props.i18n._('Hostname or IP')}
                                onChange={this.handleFormChange.bind(this, 'Hostname')}
                                value={this.state.editingAccount.Hostname}
                            />
                            <MD.TextField
                                disabled={this.props.accounts.operationInProgress}
                                error={this.state.errors.port !== ''}
                                helperText={this.state.errors.port}
                                label={this.props.i18n._('Port')}
                                onChange={this.handleFormChange.bind(this, 'Port')}
                                type='number'
                                value={this.state.editingAccount.Port}
                            />
                            <MD.TextField
                                disabled={this.props.accounts.operationInProgress}
                                error={this.state.errors.login !== ''}
                                helperText={this.state.errors.login}
                                label={this.props.i18n._('Login')}
                                onChange={this.handleFormChange.bind(this, 'Login')}
                                value={this.state.editingAccount.Login}
                            />
                            <MD.TextField
                                disabled={this.props.accounts.operationInProgress}
                                error={this.state.errors.password !== ''}
                                helperText={this.state.errors.password}
                                label={this.props.i18n._('Password')}
                                onChange={this.handleFormChange.bind(this, 'Password')}
                                type='password'
                                value={this.state.editingAccount.Password}
                            />
                            <MD.TextField
                                disabled={this.props.accounts.operationInProgress}
                                error={this.state.errors.directory !== ''}
                                helperText={this.state.errors.directory}
                                label={this.props.i18n._('Directory')}
                                onChange={this.handleFormChange.bind(this, 'Directory')}
                                value={this.state.editingAccount.Directory}
                            />
                            <MD.FormControl
                                className={this.props.classes.formControl}
                                error={this.state.errors.general !== ''}
                                disabled={this.props.accounts.operationInProgress}
                            >
                                {this.props.accounts.operationInProgress ? (
                                    <MD.Button
                                        className={this.props.classes.createButton}
                                        color='primary'
                                        variant='contained'
                                    >
                                        <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                                        {this.props.i18n._('Please wait ...')}
                                    </MD.Button>
                                ) : ensureString(this.state.editingAccount.ID) === '' ? (
                                    <MD.Button
                                        className={this.props.classes.createButton}
                                        color='primary'
                                        onClick={this.onCreate.bind(this)}
                                        variant='contained'
                                    >
                                        <FontAwesomeIcon icon={FA.faPlus} />
                                        {this.props.i18n._('Create account')}
                                    </MD.Button>
                                ) : (
                                    <div>
                                        <MD.Button
                                            className={this.props.classes.createButton}
                                            color='primary'
                                            onClick={this.onEdit.bind(this)}
                                            variant='contained'
                                        >
                                            <FontAwesomeIcon icon={FA.faEdit} />
                                            {this.props.i18n._('Edit account')}
                                        </MD.Button>
                                        <MD.Button
                                            className={this.props.classes.createButton}
                                            color='primary'
                                            onClick={this.onDelete.bind(this)}
                                            variant='contained'
                                        >
                                            <FontAwesomeIcon icon={FA.faTrash} />
                                            {this.props.i18n._('Delete account')}
                                        </MD.Button>
                                    </div>
                                )}
                                {this.state.errors.general !== '' ? (
                                    <MD.FormHelperText>{this.state.errors.general}</MD.FormHelperText>
                                ) : (
                                    ''
                                )}
                                <MD.Typography
                                    component='a'
                                    onClick={this.onCancelForm.bind(this)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {this.props.i18n._('Cancel')}
                                </MD.Typography>
                            </MD.FormControl>
                        </form>
                    </MD.Paper>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private renderAccount(index: number, key: number | string): JSX.Element {
        if (isNullOrUndefined(this.props.accounts.accounts[index])) {
            const nearest = index - (index % 1000);

            if (this.alreadyAskedOffsets.indexOf(nearest) === -1) {
                this.props.get(nearest, 1000);
                this.alreadyAskedOffsets.push(nearest);
            }
            return (
                <MD.ListItem className={this.props.classes.listListItem} key={key}>
                    <FontAwesomeIcon icon={FA.faSpinner} spin={true} color={theme.palette.text.primary} />
                </MD.ListItem>
            );
        }

        const account = this.props.accounts.accounts[index] as Proto.mediaarchiver.ISFTPAccount;

        return (
            <MD.ListItem
                className={this.props.classes.listListItem}
                key={key}
                onClick={this.onEditAccount.bind(this, account)}
            >
                <MD.ListItemText
                    primary={capitalize(account.Name || 'N/A')}
                    secondary={`${account.Login || 'N/A'}@${account.Hostname || 'N/A'}`}
                />
            </MD.ListItem>
        );
    }

    private onEditAccount(account: Proto.mediaarchiver.ISFTPAccount) {
        this.onCancelForm();
        this.setState({
            ...this.state,

            editingAccount: { ...account },
            errors: this.getDefaultState().errors,
        });
    }

    private onCancelForm() {
        this.setState({
            ...this.state,

            editingAccount: this.getDefaultState().editingAccount,
            errors: this.getDefaultState().errors,
        });
    }

    private onCreate() {
        this.props.create(this.state.editingAccount);
    }

    private onEdit() {
        if (ensureString(this.state.editingAccount.ID) === '') {
            return;
        }
        this.props.edit(this.state.editingAccount);
    }

    private onDelete() {
        if (ensureString(this.state.editingAccount.ID) === '') {
            return;
        }
        this.props.delete(ensureString(this.state.editingAccount.ID));
    }

    private handleFormChange(name: string, ev: React.ChangeEvent<HTMLInputElement>) {
        const state = { ...this.state };

        if (isNullOrUndefined(ev.target)) {
            return;
        }
        if (name === 'Port') {
            const val = parseInt(ev.target.value, 10);

            if (isNaN(val)) {
                ev.preventDefault();
                ev.stopPropagation();
                return;
            }
            state.editingAccount.Port = val;
        } else {
            if (['Name', 'Hostname', 'Login', 'Password', 'Directory'].includes(name)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (state.editingAccount as any)[name] = ev.target.value;
            } else {
                Logger.warn(state.editingAccount, `No [${name}] in editingAccount`);
            }
        }
        this.setState(state);
    }

    private getDefaultState(): IState {
        return {
            editingAccount: {
                Directory: '',
                Hostname: '',
                ID: '',
                Login: '',
                Name: '',
                Password: '',
                Port: 22,
            },
            errors: {
                directory: '',
                general: '',
                hostname: '',
                login: '',
                name: '',
                password: '',
                port: '',
            },
            loading: false,
        };
    }

    private onOpSuccess() {
        window.setTimeout(() => {
            this.props.setSnackMessage(this.props.i18n._('Operation completed successfully'));
            this.onCancelForm();
        }, 500);
    }

    private onOpError(ev: CustomEvent<ISFTPAccountFormErrors>) {
        this.setState({
            ...this.state,

            errors: ev.detail,
        });
    }
}
