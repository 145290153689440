import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { ReactNode } from 'react';

export enum LayoutActionTypes {
    ADD_STACKED_SNACK_MESSAGE = '@@layout/ADD_STACKED_SNACK_MESSAGE',
    ANNOTATIONS_HIDE = '@@layout/ANNOTATIONS_HIDE',
    ANNOTATIONS_SHOW = '@@layout/ANNOTATIONS_SHOW',
    APPBAR_HIDE = '@@layout/APPBAR_HIDE',
    APPBAR_SHOW = '@@layout/APPBAR_SHOW',
    DRAWER_HIDE = '@@layout/DRAWER_HIDE',
    DRAWER_SHOW = '@@layout/DRAWER_SHOW',
    HIDE_APPBAR = '@@layout/HIDE_SNACK',
    HIDE_SNACK = '@@layout/HIDE_SNACK',
    ON_CLOSE_SNACK = '@@layout/ON_CLOSE_SNACK',
    SET_DRAWER_CONTENT = '@@layout/SET_DRAWER_CONTENT',
    SET_DRAWER_TITLE = '@@layout/SET_DRAWER_TITLE',
    SET_HAS_ANNOTATIONS = '@@layout/SET_HAS_ANNOTATIONS',
    SET_PAGE_CONTENT = '@@layout/SET_PAGE_CONTENT',
    SET_PAGE_TITLE = '@@layout/SET_PAGE_TITLE',
    SET_SIZE = '@@layout/SET_SIZE',
    SET_SNACK_MESSAGE = '@@layout/SET_SNACK_MESSAGE',
    SET_SNACK_MESSAGE_NOW = '@@layout/SET_SNACK_MESSAGE_NOW',
    UPDATE_STACK = '@@layout/UPDATE_STACK',
}

export interface ILayoutState {
    readonly drawerContent: ReactNode;
    readonly drawerTitle: string;
    readonly hasAnnotations: boolean;
    readonly pageContent: ReactNode;
    readonly pageTitle: string;
    readonly showAnnotations: boolean;
    readonly showAppBar: boolean;
    readonly showDrawer: boolean;
    readonly size: Breakpoint;
    readonly snackMessage: string;
    readonly stackedSnackMessages: string[];
}

export interface IHideAnnotationsAction {
    type: LayoutActionTypes.ANNOTATIONS_HIDE;
}

export interface IHideAppBarAction {
    type: LayoutActionTypes.APPBAR_HIDE;
}

export interface IHideDrawerAction {
    type: LayoutActionTypes.DRAWER_HIDE;
}

export interface IHideSnackAction {
    type: LayoutActionTypes.HIDE_SNACK;
}

export interface IOnCloseSnackAction {
    type: LayoutActionTypes.ON_CLOSE_SNACK;
}

export interface ISetDrawerContentAction {
    payload: ReactNode | null;
    type: LayoutActionTypes.SET_DRAWER_CONTENT;
}

export interface ISetDrawerTitleAction {
    payload: string;
    type: LayoutActionTypes.SET_DRAWER_TITLE;
}

export interface ISetHasAnnotationsAction {
    payload: boolean;
    type: LayoutActionTypes.SET_HAS_ANNOTATIONS;
}

export interface ISetPageContentAction {
    payload: ReactNode;
    type: LayoutActionTypes.SET_PAGE_CONTENT;
}

export interface ISetPageTitleAction {
    payload: string;
    type: LayoutActionTypes.SET_PAGE_TITLE;
}

export interface ISetSizeAction {
    payload: Breakpoint;
    type: LayoutActionTypes.SET_SIZE;
}

export interface ISetSnackMessageAction {
    payload: string;
    type: LayoutActionTypes.SET_SNACK_MESSAGE;
}

export interface ISetSnackMessageNowAction {
    payload: string;
    type: LayoutActionTypes.SET_SNACK_MESSAGE_NOW;
}

export interface IShowAnnotationsAction {
    type: LayoutActionTypes.ANNOTATIONS_SHOW;
}

export interface IShowAppBarAction {
    type: LayoutActionTypes.APPBAR_SHOW;
}

export interface IShowDrawerAction {
    type: LayoutActionTypes.DRAWER_SHOW;
}

export interface IUpdateStackAction {
    payload: string[];
    type: LayoutActionTypes.UPDATE_STACK;
}
