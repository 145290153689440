import { withStyles, withWidth } from '@material-ui/core';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../Store';
import { Locales, setLocale } from '../../Store/I18n';
import { hideAnnotations, hideDrawer, showAnnotations, showDrawer } from '../../Store/Layout';
import { hideReportBug, reportBug, showReportBug } from '../../Store/Timeline/Actions';
import { checkIncidentContent, hideIncident, showIncident } from '../../Store/Incident/Actions';
import { IReportedBugInfos } from '../../Store/Timeline/Types';
import { logout } from '../../Store/User';
import AppBarComponent from './AppBar';
import styles from './styles';

const mapStateToProps = (
    { i18n, incident, layout, timeline, user }: IApplicationState,
    ownProps: RouteComponentProps<{}>,
) => ({
    i18n: i18n.i18n,
    incident,
    layout,
    localeInfos: i18n.localeInfos,
    router: ownProps,
    timeline,
    user: user.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    checkIncidentContent: () => dispatch(checkIncidentContent()),
    hideAnnotations: () => dispatch(hideAnnotations()),
    hideDrawer: () => dispatch(hideDrawer()),
    hideIncident: () => dispatch(hideIncident()),
    hideReportBug: () => dispatch(hideReportBug()),
    logout: () => dispatch(logout()),
    reportBug: (bug: IReportedBugInfos) => dispatch(reportBug(bug)),
    setLocale: (locale: Locales) => dispatch(setLocale(locale)),
    showAnnotations: () => dispatch(showAnnotations()),
    showDrawer: () => dispatch(showDrawer()),
    showIncident: () => dispatch(showIncident()),
    showReportBug: () => dispatch(showReportBug()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles)(AppBarComponent)));
