import { action } from 'typesafe-actions';

import {
    ICheckIncidentContentAction,
    IHideIncidentAction,
    IIncidentInfos,
    IncidentActionTypes,
    IShowIncidentAction,
    IUpdateIncidentContentAction,
} from './Types';

export const checkIncidentContent = (): ICheckIncidentContentAction =>
    action(IncidentActionTypes.CHECK_INCIDENT_CONTENT);
export const hideIncident = (): IHideIncidentAction => action(IncidentActionTypes.HIDE_INCIDENT);
export const showIncident = (): IShowIncidentAction => action(IncidentActionTypes.SHOW_INCIDENT);
export const updateIncidentContent = (incident: IIncidentInfos): IUpdateIncidentContentAction =>
    action(IncidentActionTypes.UPDATE_INCIDENT_CONTENT, incident);
