import * as MD from '@material-ui/core';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState } from '../../../Store';
import {
    create,
    deleteContact,
    edit,
    get,
    getCount,
} from '../../../Store/Contacts/Actions';
import { setPageTitle } from '../../../Store/Layout';
import AccountContactsComponent, { IRouterMatchParams } from './Contacts';
import styles from './styles';

const mapStateToProps = (
    { contacts, i18n }: IApplicationState,
    ownProps: RouteComponentProps<IRouterMatchParams>,
) => ({
    contacts,
    i18n: i18n.i18n,
    router: ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    create: (contact: mediaarchiver.IContact) => dispatch(create(contact)),
    delete: (id: string) => dispatch(deleteContact(id)),
    edit: (contact: mediaarchiver.IContact) => dispatch(edit(contact)),
    get: (offset = 0, limit = 100) => dispatch(get(offset, limit)),
    getCount: () => dispatch(getCount()),
    setPageTitle: (title: string) => dispatch(setPageTitle(title)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(AccountContactsComponent));
