import { Reducer } from 'redux';

import { mediaarchiver } from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';
import { ArchivesActionTypes, IArchiveState } from './Types';

const initialState: IArchiveState = {
    archives: [],
    opSuccessEventElement: document.createElement('div'),
    operationInProgress: false,
    showDialog: false,
    totalRadio: 0,
    totalTV: 0,
    watchedArchives: [],
};

const reducer: Reducer<IArchiveState> = (state = initialState, action) => {
    switch (action.type) {
        case ArchivesActionTypes.DELETED_ARCHIVE: {
            let totalRadio = state.totalRadio;
            let totalTV = state.totalTV;

            const archives = [...state.archives].filter((i) => {
                if (!isNull(i) && i.ID === action.payload) {
                    switch (i.MediaType) {
                        case 2:
                            totalTV = totalTV - 1;
                            break;
                        case 1:
                            totalRadio = totalRadio - 1;
                        default:

                    }
                    return false;
                }
                return true;
            });
            return { ...state, archives, totalRadio, totalTV };
        }

        case ArchivesActionTypes.HIDE_DIALOG: {
            if (
                !isNullOrUndefined(document.activeElement) &&
                ('blur' in document.activeElement)
            ) {
                (document.activeElement as HTMLElement).blur();
            }
            return { ...state, showDialog: false };
        }

        case ArchivesActionTypes.SET_ARCHIVE: {
            if (action.payload === null) {
                return state;
            }
            const ar = action.payload as mediaarchiver.IArchive;

            let totalRadio = state.totalRadio;
            let totalTV = state.totalTV;

            const archives = state.archives.map((archive) => {
                if (
                    !isNullOrUndefined(archive) &&
                    archive.ID &&
                    (ar.ID === archive.ID)
                ) {
                    return ar;
                }
                return archive;
            });
            if (!archives.some((archive) => !isNullOrUndefined(archive) && (archive.ID === ar.ID))) {
                if (ar.MediaType === 2) {
                    totalTV = totalTV + 1;
                } else {
                    totalRadio = totalRadio + 1;
                }
                archives.unshift(action.payload);
            }
            return { ...state, archives, totalRadio, totalTV };
        }

        case ArchivesActionTypes.SET_ARCHIVES: {
            return {
                ...state,
                archives: action.payload[0],
            };
        }

        case ArchivesActionTypes.SET_COUNT: {
            const [radioCount, tvCount] = action.payload;

            return { ...state, totalRadio: radioCount, totalTV: tvCount };
        }

        case ArchivesActionTypes.SET_OP_ERRORS: {
            window.setTimeout(() => {
                state.opSuccessEventElement.dispatchEvent(
                    new CustomEvent('opError', { detail: action.payload }),
                );
            }, 200);
            return state;
        }

        case ArchivesActionTypes.SET_OP_PROGRESS: {
            return { ...state, operationInProgress: action.payload };
        }

        case ArchivesActionTypes.SET_OP_SUCCESS: {
            window.setTimeout(() => {
                state.opSuccessEventElement.dispatchEvent(
                    new CustomEvent('opSuccess'),
                );
            }, 200);
            return state;
        }

        case ArchivesActionTypes.SHOW_DIALOG: {
            if (
                !isNullOrUndefined(document.activeElement) &&
                ('blur' in document.activeElement)
            ) {
                (document.activeElement as HTMLElement).blur();
            }
            return { ...state, showDialog: true };
        }

        case ArchivesActionTypes.UNWATCH_ARCHIVE: {
            const watchedArchives = state.watchedArchives.filter((i) => i !== action.payload);

            return { ...state, watchedArchives };
        }

        case ArchivesActionTypes.WATCH_ARCHIVE: {
            if (state.watchedArchives.indexOf(action.payload) !== -1) {
                return state;
            }

            const watchedArchives = state.watchedArchives.concat(action.payload);

            return { ...state, watchedArchives };
        }

        default: {
            return state;
        }
    }
};

export { reducer as ArchivesReducer };
