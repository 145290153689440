import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../../../Store';
import { setAudienceMode, setAudienceVOSDAL } from '../../../../Store/Timeline';
import AudiencesInfosComponent from './AudiencesInfos';
import styles from './styles';

const mapStateToProps = ({
    i18n,
    timeline,
    timelinePopovers,
}: IApplicationState) => ({
    i18n: i18n.i18n,
    timelinePopovers,
    timelinePresentation: timeline.presentation,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setAudienceMode: (mode: string) => dispatch(setAudienceMode(mode)),
    setAudienceVOSDAL: (use: boolean) => dispatch(setAudienceVOSDAL(use)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(AudiencesInfosComponent));
