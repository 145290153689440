import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { I18N } from '../../../Store/I18n';
import { getTheme } from '../../../Themes';

interface IPropsFromState {
    i18n: I18N;
}

const theme = getTheme();

const styles = MD.createStyles({
    annotationDialogAddLink: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1) * 3,
        marginTop: theme.spacing(1),
        textAlign: 'right',
    },
});

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

export class ReviewListComponent extends React.Component<AllProps> {
    public constructor(props: AllProps) {
        super(props);
    }

    public render(): React.ReactNode {
        return <div />;
    }
}

const mapStateToProps = ({ i18n }: IApplicationState) => ({
    i18n: i18n.i18n,
});

export const ReviewList = connect(mapStateToProps)(MD.withStyles(styles)(ReviewListComponent));
