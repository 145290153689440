import * as MD from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { showDialog as showExtractionDialog } from '../../../Store/Archives/Actions';
import { I18N, ILocaleInfos } from '../../../Store/I18n/Types';
import {
    setEndSelection,
    setPosition as setPlayerPosition,
    setSnapshot,
    setStartSelection,
} from '../../../Store/Player/Actions';
import { IPlayerState } from '../../../Store/Player/Types';
import { deleteTalk, ITalkState, showEditDialog } from '../../../Store/Talk';
import { setWip } from '../../../Store/Timeline/Actions';
import { IEPGProgramState, IEPGState, ITimelineState } from '../../../Store/Timeline/Types';
import { IUserState } from '../../../Store/User/Types';
import { getTheme } from '../../../Themes';
import { hexToRgb, hexToRgba } from '../../../Utils/Color';
import { Logger } from '../../../Utils/Logger';
import { formatDuration, getDateInTz } from '../../../Utils/Time';
import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../../Utils/Various';

const theme = getTheme();

const styles = MD.createStyles({
    contextualMenu: {
        backgroundColor: 'transparent',
        height: '100vh',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 9999,
    },
    contextualMenuList: {
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        width: 230,
    },
    line: {
        height: '15px',
        position: 'relative',
        width: '100%',
    },
    popover: {
        display: 'none',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 2000,
    },
    popoverContent: {
        '& h5': {
            fontSize: '1.2em',
        },
        '& pre': {
            fontSize: '1.4em',
        },
        '& table td': {
            fontSize: '1em',
            padding: 2,
        },

        fontFamily: theme.typography.fontFamily,
    },
    popoverDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 340,
    },
    popoverPerson: {
        marginBottom: 15,
    },
    program: {
        alignItems: 'center',
        border: '1px solid black',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '11px',
        height: '15px',
        justifyContent: 'left',
        lineHeight: '11px',
        marginTop: '3px',
        overflow: 'hidden',
        paddingLeft: '3px',
        position: 'absolute',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
    },
    programOwned: {
        border: '1px solid white',
    },
    root: {
        alignItems: 'flex-end',
        display: 'inline-flex',
        flexDirection: 'column',
        height: '45px',
        width: '100%',
    },
});

interface IState {
    contextualMenuProgram: IEPGProgramState | null;
    popoverProgram: IEPGProgramState | null;
}

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    player: IPlayerState;
    talk: ITalkState;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    deleteTalk: typeof deleteTalk;
    setEndSelection: typeof setEndSelection;
    setPlayerPosition: typeof setPlayerPosition;
    setSnapshot: typeof setSnapshot;
    setStartSelection: typeof setStartSelection;
    showEditDialog: typeof showEditDialog;
    showExtractionDialog: typeof showExtractionDialog;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class TalksComponent extends React.Component<AllProps, IState> {
    private contextualMenu: HTMLDivElement = document.createElement('div');
    private container: HTMLElement | null = null;
    private popover: HTMLElement | null = null;
    private popoverImage: HTMLImageElement | null = null;

    public constructor(props: AllProps) {
        super(props);
        this.state = {
            contextualMenuProgram: null,
            popoverProgram: null,
        };
    }

    public render(): React.ReactNode {
        const startTS = this.props.timeline.criterias.start.getTime();
        const endTS = this.props.timeline.criterias.end.getTime();
        const tlDuration = endTS - startTS;
        let speakers: mediaarchiver.ITalkSpeaker[] = [];
        let personValue = 'N/A';
        let seqDuration = '';
        let isAggregated = false;

        if (
            !isNullOrUndefined(this.state.popoverProgram) &&
            !isNullOrUndefinedOrEmptyString(this.state.popoverProgram.title)
        ) {
            personValue = this.state.popoverProgram.title;
        }
        if (
            !isNullOrUndefined(this.state.popoverProgram) &&
            !isNullOrUndefined(this.state.popoverProgram.metas) &&
            !isNullOrUndefined(this.state.popoverProgram.metas.talk_type) &&
            this.state.popoverProgram.metas.talk_type ===
                mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED.toString(10)
        ) {
            personValue += ' ' + this.props.i18n.pgettext('Politic', '(Aggregated)');
            isAggregated = true;
        }

        if (
            !isNullOrUndefined(this.state.popoverProgram) &&
            !isNullOrUndefined(this.state.popoverProgram.metas) &&
            !isNullOrUndefinedOrEmptyString(this.state.popoverProgram.metas.speakers)
        ) {
            try {
                speakers = JSON.parse(this.state.popoverProgram.metas.speakers);
            } catch (err) {
                Logger.warn(
                    { error: err, value: this.state.popoverProgram.metas.speakers },
                    'Failed unmarshaling speakers',
                );
            }
        }

        if (!isAggregated && speakers.length > 0 && !isNullOrUndefinedOrZero(speakers[0].DurationMS)) {
            seqDuration = formatDuration(speakers[0].DurationMS);
        }

        const owned =
            !isNullOrUndefined(this.state.contextualMenuProgram) &&
            !isNullOrUndefined(this.state.contextualMenuProgram.metas) &&
            this.state.contextualMenuProgram.metas.owned === 'true';

        const canEdit =
            owned ||
            this.props.user.user.functionalities.indexOf(
                mediaarchiver.UserFunctionalities.FUNCTIONALITY_POLITICAL_SUPERVISOR_EDIT,
            ) !== -1;

        const canDelete =
            owned ||
            this.props.user.user.functionalities.indexOf(
                mediaarchiver.UserFunctionalities.FUNCTIONALITY_POLITICAL_SUPERVISOR_DELETE,
            ) !== -1;

        return (
            <div className={this.props.classes.root} id='talksContainer'>
                <MD.Card className={this.props.classes.popover} id='talkPopover'>
                    <div className={this.props.classes.popoverDetails}>
                        <MD.CardContent className={this.props.classes.popoverContent}>
                            <MD.Typography className={this.props.classes.popoverPerson} component='h5' variant='h5'>
                                {personValue}
                            </MD.Typography>
                            <MD.Table>
                                <MD.TableBody>
                                    {!isAggregated && seqDuration !== '' ? (
                                        <MD.TableRow>
                                            <MD.TableCell>{this.props.i18n._('Duration')}</MD.TableCell>
                                            <MD.TableCell>{seqDuration}</MD.TableCell>
                                        </MD.TableRow>
                                    ) : (
                                        ''
                                    )}
                                    {!isNullOrUndefined(this.state.popoverProgram) &&
                                    !isNullOrUndefined(this.state.popoverProgram.metas) &&
                                    !isNullOrUndefinedOrEmptyString(this.state.popoverProgram.metas.studyType) ? (
                                        <MD.TableRow>
                                            <MD.TableCell>{this.props.i18n.pgettext('Politic', 'Study')}</MD.TableCell>
                                            <MD.TableCell>
                                                {this.getStudyName(this.state.popoverProgram.metas.studyType)}
                                            </MD.TableCell>
                                        </MD.TableRow>
                                    ) : (
                                        ''
                                    )}
                                    {!isAggregated &&
                                    speakers.length > 0 &&
                                    !isNullOrUndefinedOrZero(speakers[0].Group) ? (
                                        <MD.TableRow>
                                            <MD.TableCell>{this.props.i18n.pgettext('Politic', 'Group')}</MD.TableCell>
                                            <MD.TableCell>
                                                {this.getGroupName(speakers[0].Group as number)}
                                            </MD.TableCell>
                                        </MD.TableRow>
                                    ) : (
                                        ''
                                    )}
                                    {!isNullOrUndefined(this.state.popoverProgram) &&
                                    !isNullOrUndefined(this.state.popoverProgram.metas) &&
                                    !isNullOrUndefinedOrEmptyString(this.state.popoverProgram.metas.title) ? (
                                        <MD.TableRow>
                                            <MD.TableCell>{this.props.i18n.pgettext('Politic', 'Title')}</MD.TableCell>
                                            <MD.TableCell>{this.state.popoverProgram.metas.title}</MD.TableCell>
                                        </MD.TableRow>
                                    ) : (
                                        ''
                                    )}
                                    {!isAggregated &&
                                    speakers.length > 0 &&
                                    !isNullOrUndefinedOrZero(speakers[0].Regim) ? (
                                        <MD.TableRow>
                                            <MD.TableCell>{this.props.i18n.pgettext('Politic', 'Regim')}</MD.TableCell>
                                            <MD.TableCell>
                                                {this.getRegimName(speakers[0].Regim as number)}
                                            </MD.TableCell>
                                        </MD.TableRow>
                                    ) : (
                                        ''
                                    )}
                                    {isAggregated && speakers.length > 0 ? (
                                        <MD.TableRow>
                                            <MD.TableCell>
                                                {this.props.i18n.pgettext('Politic', 'Speakers')}
                                            </MD.TableCell>
                                            <MD.TableCell>
                                                {speakers
                                                    .map((speaker: mediaarchiver.ITalkSpeaker) => {
                                                        if (
                                                            !isNullOrUndefined(speaker) &&
                                                            !isNullOrUndefinedOrEmptyString(speaker.Name) &&
                                                            !isNullOrUndefinedOrZero(speaker.Group) &&
                                                            !isNullOrUndefinedOrEmptyString(speaker.Title) &&
                                                            !isNullOrUndefinedOrZero(speaker.DurationMS)
                                                        ) {
                                                            return (
                                                                <p key={`speaker_${Math.random()}`}>
                                                                    {speaker.Name}: {formatDuration(speaker.DurationMS)}
                                                                </p>
                                                            );
                                                        }
                                                        return null;
                                                    })
                                                    .filter((s) => !isNull(s))}
                                            </MD.TableCell>
                                        </MD.TableRow>
                                    ) : (
                                        ''
                                    )}
                                    {!isNullOrUndefined(this.state.popoverProgram) &&
                                    !isNullOrUndefined(this.state.popoverProgram.metas) &&
                                    !isNullOrUndefinedOrEmptyString(this.state.popoverProgram.metas.comment) ? (
                                        <MD.TableRow>
                                            <MD.TableCell>{this.props.i18n._('Comment')}</MD.TableCell>
                                            <MD.TableCell>{this.state.popoverProgram.metas.comment}</MD.TableCell>
                                        </MD.TableRow>
                                    ) : (
                                        ''
                                    )}
                                    {!isNullOrUndefined(this.state.popoverProgram) &&
                                    !isNullOrUndefined(this.state.popoverProgram.metas) &&
                                    !isNullOrUndefinedOrEmptyString(this.state.popoverProgram.metas.ownerName) ? (
                                        <MD.TableRow>
                                            <MD.TableCell>{this.props.i18n._('Added by')}</MD.TableCell>
                                            <MD.TableCell>{this.state.popoverProgram.metas.ownerName}</MD.TableCell>
                                        </MD.TableRow>
                                    ) : (
                                        ''
                                    )}
                                </MD.TableBody>
                            </MD.Table>
                        </MD.CardContent>
                    </div>
                </MD.Card>
                <div className={this.props.classes.line}>
                    {this.renderLine(this.props.timeline.data.talkEPG, tlDuration)}
                </div>
                <div
                    className={this.props.classes.contextualMenu}
                    onClick={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        this.setState({ contextualMenuProgram: null });
                        return false;
                    }}
                    onMouseDown={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                    }}
                    ref={(c: HTMLDivElement) => {
                        this.contextualMenu = c;
                    }}
                    style={{
                        display: isNull(this.state.contextualMenuProgram) ? 'none' : 'block',
                    }}
                >
                    <MD.List className={this.props.classes.contextualMenuList} dense>
                        <MD.ListItem button disabled={!canEdit} onClick={this.onEditTalk.bind(this)}>
                            {this.props.i18n._('Edit talk')}
                        </MD.ListItem>
                        <MD.ListItem button disabled={!canDelete} onClick={this.onDeleteTalk.bind(this)}>
                            {this.props.i18n._('Delete talk')}
                        </MD.ListItem>
                        <MD.ListItem button onClick={this.onArchiveTalk.bind(this)}>
                            {this.props.i18n._('Create an archive')}
                        </MD.ListItem>
                    </MD.List>
                </div>
            </div>
        );
    }

    private renderLine(data: IEPGState, tlDuration: number) {
        return Object.keys(data).map((minute: string, i: number) => {
            const program = data[(minute as unknown) as number];
            let title = '';
            let speakers: mediaarchiver.ITalkSpeaker[] = [];
            let isAggregated = false;

            if (isNullOrUndefined(program) || isNullOrUndefined(program.metas)) {
                return;
            }

            if (!isNullOrUndefinedOrEmptyString(program.title)) {
                title = program.title;
            }
            if (
                !isNullOrUndefined(program.metas.talk_type) &&
                program.metas.talk_type === mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED.toString(10)
            ) {
                title += ' ' + this.props.i18n.pgettext('Politic', '(Aggregated)');
                isAggregated = true;
            }

            if (!isNullOrUndefinedOrEmptyString(program.metas.speakers)) {
                try {
                    speakers = JSON.parse(program.metas.speakers);
                } catch (err) {
                    Logger.warn({ error: err, value: program.metas.speakers }, 'Failed unmarshaling speakers');
                }
            }

            let start = program.start.getTime();
            const tlStart = getDateInTz(this.props.timeline.criterias.start, this.props.timeline.criterias.timezone).getTime();
            const tlEnd = getDateInTz(this.props.timeline.criterias.end, this.props.timeline.criterias.timezone).getTime();
            let duration = program.duration;
            let color = theme.mediaarchiver.colors.backgroundPoliticalDefault;

            if (!isAggregated && speakers.length > 0 && !isNullOrUndefinedOrEmptyString(speakers[0].GroupColor)) {
                color = '#' + speakers[0].GroupColor;
            }

            if (program.metas.owned === 'true') {
                color = hexToRgb(color);
            } else {
                color = hexToRgba(color, '0.5');
            }

            if (start < tlStart) {
                start = tlStart;
            }
            if (start + duration > tlEnd) {
                duration = tlEnd - start;
            }

            const widthPercent = Math.ceil((duration / tlDuration) * 1000000) / 10000;
            const clsNames = classNames({
                [this.props.classes.program]: true,
                [this.props.classes.programOwned]: program.metas.owned === 'true',
            });

            return (
                <div
                    className={clsNames}
                    data-donottrackscroller={true}
                    key={`talk_${i}`}
                    onClick={this.handlePopoverClick.bind(this, program)}
                    onContextMenu={this.handleContext.bind(this, program)}
                    onMouseEnter={this.handlePopoverOpen.bind(this, program)}
                    onMouseLeave={this.handlePopoverClose.bind(this)}
                    style={{
                        background: color,
                        left: `${((start - tlStart) / tlDuration) * 100}%`,
                        width: `${widthPercent}%`,
                        zIndex: 10 + i,
                    }}
                >
                    {title}
                </div>
            );
        });
    }

    private handlePopoverClick(prg: IEPGProgramState) {
        if (!isNullOrUndefined(this.props.player.player)) {
            this.props.player.player.start(
                prg.start,
                getDateInTz(this.props.timeline.criterias.end, this.props.timeline.criterias.timezone),
                this.props.user.user.options.indexOf(
                    mediaarchiver.UserOptions.USER_OPTION_NO_AUTOPLAY_WHEN_PLAY_CONTENT,
                ) === -1,
            );
        }
    }

    private handlePopoverClose() {
        if (isNull(this.popover)) {
            this.popover = document.getElementById('talkPopover');
        }
        if (isNull(this.popover)) {
            return;
        }
        this.popover.style.display = 'none';
        this.setState({ popoverProgram: null });
    }

    private handlePopoverOpen(prg: IEPGProgramState, ev: React.MouseEvent) {
        if (isNull(this.popover)) {
            this.popover = document.getElementById('talkPopover');
        }
        if (isNull(this.container)) {
            this.container = document.getElementById('talksContainer');
        }
        if (isNullOrUndefined(ev) || isNullOrUndefined(ev.target) || isNull(this.popover) || isNull(this.container)) {
            return;
        }

        this.setState({ popoverProgram: prg });

        const prgBox = (ev.target as HTMLElement).getBoundingClientRect();

        this.popover.style.display = 'flex';
        if (
            ev.clientX - theme.mediaarchiver.dimensions.drawerWidth <
            (window.innerWidth - theme.mediaarchiver.dimensions.drawerWidth) / 2
        ) {
            this.popover.style.left = `${ev.clientX}px`;
            this.popover.style.right = 'inherit';
        } else {
            this.popover.style.left = 'inherit';
            this.popover.style.right = `${window.innerWidth - ev.clientX}px`;
        }
        this.popover.style.bottom = `${window.innerHeight - prgBox.top + 3}px`;
    }

    private handleContext(prg: IEPGProgramState, ev: React.MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();

        const elems = this.contextualMenu.getElementsByTagName('ul');

        if (elems.length === 0) {
            return;
        }

        elems[0].style.left = `${ev.clientX}px`;
        elems[0].style.bottom = `${window.innerHeight - ev.clientY}px`;
        this.setState({ contextualMenuProgram: prg });
    }

    private onArchiveTalk() {
        if (isNull(this.state.contextualMenuProgram) || isNull(this.props.player.player)) {
            return;
        }
        this.props.player.player.start(this.state.contextualMenuProgram.start, this.props.timeline.criterias.end);
        const program = this.state.contextualMenuProgram;
        window.setTimeout(() => {
            if (isNull(this.props.player.player)) {
                return;
            }
            this.props.player.player
                .getSnapshot()
                .then((snap: string) => {
                    this.props.setSnapshot(snap);
                })
                .catch((err) => Logger.warn(err));
            this.props.setPlayerPosition(program.start);
            this.props.setStartSelection(program.start);
            this.props.setEndSelection(new Date(program.start.getTime() + program.duration));
            this.props.showExtractionDialog();
        }, 2000);
    }

    private onEditTalk() {
        const program = this.state.contextualMenuProgram as IEPGProgramState;
        if (isNullOrUndefined(program)) {
            return;
        }
        const metas = program.metas;
        if (isNullOrUndefined(metas)) {
            return;
        }
        if (isNullOrUndefined(metas.id)) {
            return;
        }
        if (
            metas.owned !== 'true' &&
            this.props.user.user.functionalities.indexOf(
                mediaarchiver.UserFunctionalities.FUNCTIONALITY_POLITICAL_SUPERVISOR_EDIT,
            ) === -1
        ) {
            return;
        }

        const talk: mediaarchiver.ITalk = {
            ID: metas.id,
            Media: this.props.timeline.criterias.media,
        };

        if (!isNullOrUndefinedOrEmptyString(metas.program_type)) {
            talk.ProgramType = parseInt(metas.program_type, 10);
        }

        if (!isNullOrUndefinedOrEmptyString(metas.studyType)) {
            talk.Study = parseInt(metas.studyType, 10);
        }

        if (!isNullOrUndefinedOrEmptyString(metas.district)) {
            talk.District = parseInt(metas.district, 10);
        }

        if (!isNullOrUndefinedOrEmptyString(metas.comment)) {
            talk.Comment = metas.comment;
        }
        if (!isNullOrUndefinedOrEmptyString(metas.program_name)) {
            talk.ProgramName = metas.program_name;
        }
        if (!isNullOrUndefinedOrEmptyString(metas.program_start)) {
            const ts = parseInt(metas.program_start);

            if (!isNaN(ts) && ts > 0) {
                talk.ProgramStart = ts;
            }
        }
        if (!isNullOrUndefinedOrEmptyString(metas.program_end)) {
            const ts = parseInt(metas.program_end);

            if (!isNaN(ts) && ts > 0) {
                talk.ProgramEnd = ts;
            }
        }
        if (!isNullOrUndefinedOrEmptyString(metas.time_type)) {
            const type = parseInt(metas.time_type);

            if (type == mediaarchiver.TalkTimeType.TALK_TIME_TYPE_BROADCAST) {
                talk.TimeType = mediaarchiver.TalkTimeType.TALK_TIME_TYPE_BROADCAST;
            } else {
                talk.TimeType = mediaarchiver.TalkTimeType.TALK_TIME_TYPE_SPEAK;
            }
        }

        let t = mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED;
        if (!isNullOrUndefinedOrEmptyString(metas.talk_type)) {
            t = parseInt(metas.talk_type);
        }
        switch (t) {
            case mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED:
            case mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED:
                // ok
                break;
            default:
                t = mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED;
        }
        talk.TalkType = t;

        if (!isNullOrUndefinedOrEmptyString(metas.speakers)) {
            try {
                talk.Speakers = JSON.parse(metas.speakers);
            } catch (err) {
                Logger.warn({ error: err, value: metas.speakers }, 'Failed unmarshaling speakers');
                return false;
            }
        }
        this.props.showEditDialog(talk);
    }

    private onDeleteTalk() {
        if (
            isNull(this.state.contextualMenuProgram) ||
            isNullOrUndefined(this.state.contextualMenuProgram.metas) ||
            isNullOrUndefined(this.state.contextualMenuProgram.metas.id)
        ) {
            return;
        }
        this.props.deleteTalk(this.state.contextualMenuProgram.metas.id);
    }

    private getGroupName(group: number): string {
        let name = 'N/A';

        this.props.talk.allGroups.some((g: mediaarchiver.ITalkGroup) => {
            if (
                !isNullOrUndefined(g) &&
                !isNullOrUndefinedOrZero(g.ID) &&
                !isNullOrUndefinedOrEmptyString(g.Name) &&
                g.ID == group
            ) {
                name = g.Name;
            }
        });
        return name;
    }

    private getRegimName(regim: number): string {
        let name = 'N/A';

        this.props.talk.allRegims.some((r: mediaarchiver.ITalkRegim) => {
            if (
                !isNullOrUndefined(r) &&
                !isNullOrUndefinedOrZero(r.ID) &&
                !isNullOrUndefinedOrEmptyString(r.Name) &&
                r.ID == regim
            ) {
                name = r.Name;
            }
        });
        return name;
    }

    private getStudyName(study: string): string {
        const studyID = parseInt(study, 10);
        let name = 'N/A';

        this.props.talk.studies.some((s: mediaarchiver.ITalkStudy) => {
            if (
                !isNullOrUndefined(s) &&
                !isNullOrUndefinedOrZero(s.ID) &&
                !isNullOrUndefinedOrEmptyString(s.Name) &&
                s.ID == studyID
            ) {
                name = s.Name;
            }
        });
        return name;
    }
}

const mapStateToProps = ({ i18n, player, talk, timeline, user }: IApplicationState) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    player,
    talk,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    deleteTalk: (id: string) => dispatch(deleteTalk(id)),
    setEndSelection: (time: Date | null) => dispatch(setEndSelection(time)),
    setPlayerPosition: (time: Date) => dispatch(setPlayerPosition(time)),
    setSnapshot: (d: string | null) => dispatch(setSnapshot(d)),
    setStartSelection: (time: Date | null) => dispatch(setStartSelection(time)),
    setWip: (toggle: boolean) => dispatch(setWip(toggle)),
    showEditDialog: (t: mediaarchiver.ITalk) => dispatch(showEditDialog(t)),
    showExtractionDialog: () => dispatch(showExtractionDialog()),
});

export const Talk = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(TalksComponent));
