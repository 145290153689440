import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../../../Store';
import { IAudienceMinuteState, setWip } from '../../../../Store/Timeline';
import { setCurrentMinute } from '../../../../Store/TimelinePopovers';
import AudienceComponent from './Audience';
import styles from './style';

const mapStateToProps = ({ i18n, timeline, timelinePopovers }: IApplicationState) => ({
    i18n: i18n.i18n,
    timeline,
    timelinePopovers,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCurrentMinute: (minute: IAudienceMinuteState) =>
        dispatch(setCurrentMinute(minute)),
    setWip: (toggle: boolean) => dispatch(setWip(toggle)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(AudienceComponent));
