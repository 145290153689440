import * as Proto from '../../Protos/protos';

export enum SFTPAccountActionTypes {
    CREATE = '@@sftpAccounts/CREATE',
    DELETE = '@@sftpAccounts/DELETE',
    DELETED_ACCOUNT = '@@sftpAccounts/DELETED_ACCOUNT',
    EDIT = '@@sftpAccounts/EDIT',
    GET = '@@sftpAccounts/GET',
    GET_COUNT = '@@sftpAccounts/GET_COUNT',
    OP_SUCCESS = '@@sftpAccounts/OP_SUCCESS',
    SET_ACCOUNT = '@@sftpAccounts/SET_ACCOUNT',
    SET_ACCOUNTS = '@@sftpAccounts/SET_ACCOUNTS',
    SET_ACCOUNTS_COUNT = '@@sftpAccounts/SET_ACCOUNTS_COUNT',
    SET_ERRORS = '@@sftpAccounts/SET_ERRORS',
    SET_OP_PROGRESS = '@@sftpAccounts/SET_OP_PROGRESS',
}

export interface ISFTPAccountFormErrors {
    directory: string;
    general: string;
    hostname: string;
    login: string;
    name: string;
    password: string;
    port: string;
}

export type NullableAccount = Proto.mediaarchiver.ISFTPAccount | null;
export type NullableAccountList = NullableAccount[];

export interface ISFTPAccountsState {
    readonly accounts: NullableAccountList;
    readonly opSuccessEventElement: HTMLElement;
    readonly operationInProgress: boolean;
    readonly total: number;
}

export interface ICreateAction {
    payload: Proto.mediaarchiver.ISFTPAccount;
    type: SFTPAccountActionTypes.CREATE;
}

export interface IDeleteAccountAction {
    payload: string;
    type: SFTPAccountActionTypes.DELETE;
}

export interface IDeletedAccountAction {
    payload: string;
    type: SFTPAccountActionTypes.DELETED_ACCOUNT;
}

export interface IEditAction {
    payload: Proto.mediaarchiver.ISFTPAccount;
    type: SFTPAccountActionTypes.EDIT;
}

export interface IGetActionPayload {
    limit: number;
    offset: number;
}

export interface IGetAction {
    payload: IGetActionPayload;
    type: SFTPAccountActionTypes.GET;
}

export interface IGetCountAction {
    type: SFTPAccountActionTypes.GET_COUNT;
}

export interface ISetErrorsAction {
    payload: ISFTPAccountFormErrors;
    type: SFTPAccountActionTypes.SET_ERRORS;
}

export interface ISetOpSuccessAction {
    type: SFTPAccountActionTypes.OP_SUCCESS;
}

export interface ISetProgressAction {
    payload: boolean;
    type: SFTPAccountActionTypes.SET_OP_PROGRESS;
}
