import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';

import { isNull, isNullOrUndefined} from '../../Utils/Various';

export class SearchKindsResult extends AMessage implements IFromCommand {
    private parsedResults: Proto.mediaarchiver.ISearchKind[] | null = null;

    public constructor() {
        super('searchKindsResult');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg)) {
                return reject(new Error('Empty message'));
            }
            if (isNullOrUndefined(msg.SearchKindResultsArg)) {
                return reject(new Error('Not a SearchKindsResult message'));
            }
            if (isNullOrUndefined(msg.SearchKindResultsArg.Result)) {
                return reject(new Error('Not a SearchKindsResult message'));
            }
            this.parsedResults = msg.SearchKindResultsArg.Result;
            resolve();
        });
    }

    public get results(): Proto.mediaarchiver.ISearchKind[] {
        if (isNull(this.parsedResults)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedResults;
    }
}
