import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefined } from '../../Utils/Various';

export class TalkAllGroups extends AMessage implements IFromCommand {
    private parsedGroups: Proto.mediaarchiver.ITalkGroup[] | null = null;

    public constructor() {
        super('talkAllGroups');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.TalkAllGroupsArg) || isNullOrUndefined(msg.TalkAllGroupsArg.Groups)) {
                return reject(new Error('Not a TalkAllGroups message'));
            }
            this.parsedGroups = msg.TalkAllGroupsArg.Groups;
            resolve();
        });
    }

    public get groups(): Proto.mediaarchiver.ITalkGroup[] {
        if (isNullOrUndefined(this.parsedGroups)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedGroups;
    }
}
