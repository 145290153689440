/* eslint-disable react/prop-types */
import * as MD from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IConnectedReduxProps } from '../../../../Store';
import { I18N } from '../../../../Store/I18n';
import { IPlayerState } from '../../../../Store/Player';
import { ITimelineState } from '../../../../Store/Timeline';
// import { isNullOrUndefined } from '../../../../Utils/Various';
import styles from './style';

interface IPropsFromState {
    i18n: I18N;
    player: IPlayerState;
    timeline: ITimelineState;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

const TimeRulerComponent: React.FC<AllProps> = ({
    classes,
    timeline,
    // player,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [msByPixel, setMsByPixel] = React.useState(0);
    const startTS = timeline.criterias.start.getTime();
    const endTS = timeline.criterias.end.getTime();
    const seconds = Math.ceil((endTS - startTS) / 1000);
    let spans = Math.ceil(seconds / timeline.presentation.zoom.spanLength);

    spans = spans > 0 ? spans : 0;

    React.useEffect(() => {
        setMsByPixel(Math.round((timeline.presentation.zoom.spanLength * 1000) / timeline.presentation.zoom.spanWidth));
    });

    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    const cw = (c.width = timeline.presentation.zoom.spanWidth);
    const ch = (c.height = 30);

    if (ctx !== null) {
        ctx.clearRect(0, 0, cw, ch);
        ctx.strokeStyle = '#999';
        ctx.lineWidth = 1;
        ctx.beginPath();
        Array.from(Array(timeline.presentation.zoom.spanSubsections - 1).keys()).forEach((i) => {
            ctx.moveTo((i + 1) * (cw / timeline.presentation.zoom.spanSubsections), ch);
            ctx.lineTo((i + 1) * (cw / timeline.presentation.zoom.spanSubsections), ch - 10);
        });
        ctx.closePath();
        ctx.stroke();
    }

    const bgText = c.toDataURL();

    const renderSpan = (ts: number) => {
        return (
            <div
                className={classes.timeSpan}
                key={`spanFor_${ts}`}
                // onClick={this.onClickOnSpan.bind(this, ts)}
                style={{
                    background: `url(${bgText})`,
                    maxWidth: timeline.presentation.zoom.spanWidth,
                    minWidth: timeline.presentation.zoom.spanWidth,
                }}
            >
                {moment(ts).format('LTS')}
                {/*
                {Array.from(
                    Array(timeline.presentation.zoom.spanSubsections - 1).keys(),
                ).map((i) => (
                    <span
                        key={`timeSpan_${i}`}
                        style={{
                            left: `${Math.round(
                                (i + 1) *
                                    (timeline.presentation.zoom.spanWidth /
                                        timeline.presentation.zoom
                                            .spanSubsections),
                            )}px`,
                            zIndex: 10 + i,
                        }}
                    />
                ))}
                */}
            </div>
        );
    };
    return (
        <div className={classes.root}>
            <span className={classes.currentCursorTime} id='currentCursorTime' />

            {Array.from(Array(spans).keys()).map((i) =>
                renderSpan(startTS + i * (timeline.presentation.zoom.spanLength * 1000)),
            )}
        </div>
    );
};

export default TimeRulerComponent;
