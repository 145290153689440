import hash from 'object-hash';
import { Store } from 'redux';
import { effects } from 'redux-saga';

import { IApplicationState } from '..';
import { SearchPrograms } from '../../Backend/Commands/SearchPrograms';
import { Logger } from '../../Utils/Logger';
import { isNullOrUndefined } from '../../Utils/Various';
import { store } from '../store';
import {
    search,
    setCurrentSearch,
    setLastSearch,
    setResults,
    setResultsCount,
    setResultsTotal,
    setSearchLoading,
} from './Actions';
import { NullableResult, SearchActionTypes } from './Types';

function* handleSearch(action: ReturnType<typeof search>) {
    const str = store as Store;
    const state = str.getState() as IApplicationState;

    const hashParams = hash([
        action.payload.start,
        action.payload.end,
        action.payload.medias,
        action.payload.kinds,
        action.payload.text,
        action.payload.pageSize,
    ]);

    const cmd = new SearchPrograms(
        action.payload.start,
        action.payload.end,
        action.payload.medias,
        action.payload.kinds,
        action.payload.text,
        action.payload.pageSize,
        action.payload.page + 1,
    );

    try {
        if (isNullOrUndefined(state.search.currentSearch) || hashParams !== state.search.currentSearch.hash) {
            const res: NullableResult[] = [];
            yield effects.put(setResults(res, 1));
            yield effects.put(setResultsCount(0));
            yield effects.put(setResultsTotal(0));
            yield effects.put(setSearchLoading(true));
        }
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(
            setCurrentSearch({
                ...action.payload,
                hash: hashParams,
            }),
        );
        yield effects.put(setLastSearch(action.payload));
    } catch (err) {
        yield effects.put(setSearchLoading(false));
        Logger.warn(err as Error, 'Failed to launch search');
    }
}

function* watchSearchRequests() {
    yield effects.takeLatest(SearchActionTypes.SEARCH, handleSearch);
}

export function* SearchSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchSearchRequests)]);
}
