import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { createAnnotation as trackCreate, editAnnotation as trackEdit } from '../../../Analytics/Events';
import * as Proto from '../../../Protos/protos';
import { IConnectedReduxProps } from '../../../Store';
import { createAnnotation, editAnnotation, hideDialog } from '../../../Store/Annotations/Actions';
import { IAnnotationsState } from '../../../Store/Annotations/Types';
import { get as getAnnotations } from '../../../Store/AnnotationTypes/Actions';
import { IAnnotationTypesState } from '../../../Store/AnnotationTypes/Types';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import { setSnackMessage } from '../../../Store/Layout';
import { IPlayerState, setEndSelection, setStartSelection } from '../../../Store/Player';
import { center, ITimelineState } from '../../../Store/Timeline';
import { IUserState } from '../../../Store/User';
import { Logger } from '../../../Utils/Logger';
import { ensureString } from '../../../Utils/String';
import { getDateInTz } from '../../../Utils/Time';
import { TypedStorage } from '../../../Utils/TypedStorage';
import { isNull, isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../Utils/Various';

import styles from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Jed = require('jed');

export interface IState {
    annotationFormDates: Map<number, Date>;
    annotationFormErrors: Map<number, string>;
    annotationFormListValues: Map<number, string[]>;
    annotationType: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: Map<string, any>;
    valuesSet: boolean;
}

interface IPropsFromState {
    annotations: IAnnotationsState;
    annotationTypes: IAnnotationTypesState;
    i18n: I18N;
    localeInfos: ILocaleInfos;
    player: IPlayerState;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    center: typeof center;
    createAnnotation: typeof createAnnotation;
    editAnnotation: typeof editAnnotation;
    getAnnotations: typeof getAnnotations;
    hideDialog: typeof hideDialog;
    setEndSelection: typeof setEndSelection;
    setSnackMessage: typeof setSnackMessage;
    setStartSelection: typeof setStartSelection;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export default class AnnotationDialogComponent extends React.Component<AllProps, IState> {
    private enterCallback: (event: KeyboardEvent) => void;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
        this.enterCallback = this.handleEnterSubmit.bind(this);
    }

    public render(): React.ReactNode {
        return <div>{this.renderAnnotationDialog()}</div>;
    }

    public componentDidMount(): void {
        this.setState(this.getDefaultState());
        this.props.getAnnotations();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.props.annotations.createAnnotationSuccessEventElement.addEventListener<any>('opSuccess', () => {
            window.setTimeout(() => {
                this.props.setEndSelection(null);
                this.props.setStartSelection(null);
                this.handleCloseAnnotationDialog();
                this.props.setSnackMessage(this.props.i18n._('Annotation added !'));
                this.props.center();
            }, 200);
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.props.annotations.createAnnotationSuccessEventElement.addEventListener<any>('opError', () => {
            window.setTimeout(() => {
                this.props.setSnackMessage(this.props.i18n._('Annotation creation failed'));
            }, 200);
        });
    }

    private renderAnnotationDialog() {
        const noType = this.props.i18n._(
            "You don't have any annotation type, please create one by clicking the link below",
        );
        return (
            <MD.Dialog
                maxWidth='lg'
                open={this.props.annotations.showDialog}
                onClose={this.handleCloseAnnotationDialog.bind(this)}
                onEnter={this.handleOpenAnnotationDialog.bind(this)}
            >
                <MD.DialogTitle>
                    {isNullOrUndefined(this.props.annotations.editedAnnotation)
                        ? this.props.i18n._('Add an annotation')
                        : this.props.i18n._('Edit annotation')}
                </MD.DialogTitle>
                <MD.DialogContent className={this.props.classes.annotationDialogContent}>
                    {this.props.annotationTypes.annotationTypes.length > 0 ? (
                        <MD.FormControl fullWidth={true}>
                            <MD.InputLabel htmlFor='chooseAnotationType'>
                                {this.props.i18n._('Choose an annotation type')}
                            </MD.InputLabel>
                            <MD.Select
                                inputProps={{
                                    id: 'chooseAnotationType',
                                    name: 'chooseAnotationType',
                                }}
                                onChange={this.handleAnnotationTypeChange.bind(this)}
                                value={this.getAnnotationTypeValue()}
                            >
                                {this.props.annotationTypes.annotationTypes
                                    .map((i) => {
                                        if (!isNullOrUndefined(i)) {
                                            return (
                                                <MD.MenuItem key={`annotationTypeSelectItem_${i.ID}`} value={i.ID}>
                                                    {i.Name}
                                                </MD.MenuItem>
                                            );
                                        }
                                        return null;
                                    })
                                    .filter((i) => !isNullOrUndefined(i))}
                            </MD.Select>
                        </MD.FormControl>
                    ) : (
                        <MD.Paper>
                            <MD.Typography color='error'>&nbsp;{noType}</MD.Typography>
                        </MD.Paper>
                    )}
                    <MD.FormControl fullWidth={true}>
                        <MD.Link
                            className={this.props.classes.annotationDialogAddLink}
                            onClick={this.goToAnnotationTypes.bind(this)}
                        >
                            <FontAwesomeIcon icon={FA.faPlusCircle} size='xs' />
                            &nbsp;
                            {this.props.i18n._('Add an annotation type')}
                        </MD.Link>
                    </MD.FormControl>
                    {this.generateAddAnnotationForm()}
                </MD.DialogContent>
                <MD.DialogActions>
                    <MD.Button onClick={this.handleCloseAnnotationDialog.bind(this)} color='primary'>
                        {this.props.i18n._('Cancel')}
                    </MD.Button>
                    {this.props.annotations.createAnnotationLoading ? (
                        <MD.CircularProgress className={this.props.classes.annotationDialogLoading} />
                    ) : (
                        <MD.Button onClick={this.handleSubmitAnnotationDialog.bind(this)} color='secondary'>
                            {isNullOrUndefined(this.props.annotations.editedAnnotation)
                                ? this.props.i18n._('Add annotation')
                                : this.props.i18n._('Edit annotation')}
                        </MD.Button>
                    )}
                </MD.DialogActions>
            </MD.Dialog>
        );
    }

    private generateAddAnnotationForm() {
        if (this.state.annotationType === '') {
            return '';
        }
        const type = this.props.annotationTypes.annotationTypes.reduce((val, i) => {
            if (isNullOrUndefined(i)) {
                return val;
            }
            if (i.ID === this.state.annotationType) {
                return i;
            }
            return val;
        }, null);
        if (isNullOrUndefined(type)) {
            return '';
        }
        if (!isNullOrUndefined(this.props.annotations.editedAnnotation) && !this.state.valuesSet) {
            return '';
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let d: any = '';
        return type.Fields.filter((i) => !isNullOrUndefined(i))
            .sort((a, b) => (!isNullOrUndefined(a.Order) && !isNullOrUndefined(b.Order) ? a.Order - b.Order : 0))
            .map((f, i) => {
                switch (f.Type) {
                    case 'shortText':
                        d = '';
                        if (this.state.defaultValues.has(ensureString(f.Name))) {
                            d = this.state.defaultValues.get(ensureString(f.Name));
                        }
                        return this.generateAddAnnotationFormShortText(f, i, d);
                    case 'longText':
                        d = '';
                        if (this.state.defaultValues.has(ensureString(f.Name))) {
                            d = this.state.defaultValues.get(ensureString(f.Name));
                        }
                        return this.generateAddAnnotationFormLongText(f, i, d);
                    case 'number':
                        d = 0;
                        if (this.state.defaultValues.has(ensureString(f.Name))) {
                            d = this.state.defaultValues.get(ensureString(f.Name));
                        }
                        return this.generateAddAnnotationFormNumber(f, i, d);
                    case 'boolean':
                        d = false;
                        if (this.state.defaultValues.has(ensureString(f.Name))) {
                            d = this.state.defaultValues.get(ensureString(f.Name));
                        }
                        return this.generateAddAnnotationFormBoolean(f, i, d);
                    case 'date':
                        d = new Date();
                        if (this.state.defaultValues.has(ensureString(f.Name))) {
                            d = this.state.defaultValues.get(ensureString(f.Name));
                        }
                        return this.generateAddAnnotationFormDate(f, i, d);
                    case 'list':
                        d = [];
                        if (this.state.defaultValues.has(ensureString(f.Name))) {
                            d = this.state.defaultValues.get(ensureString(f.Name));
                        }
                        return this.generateAddAnnotationFormList(f, d);
                    default:
                        return null;
                }
            })
            .filter((i) => !isNullOrUndefined(i));
    }

    private generateAddAnnotationFormShortText(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        i: number,
        defaultValue: string,
    ) {
        if (isNullOrUndefined(f.Order)) {
            return '';
        }

        const id = `formAddAnnotationField_${f.Order}`;
        const error = this.state.annotationFormErrors.get(f.Order) || '';

        window.setTimeout(() => {
            const input = document.getElementById(id) as HTMLInputElement | null;

            if (input !== null) {
                input.value = defaultValue;
            }
        }, 500);

        return (
            <MD.FormControl
                className={this.props.classes.annotationDialogFormElement}
                error={!!error}
                id={`${id}_container`}
                key={`annotationFormControl_${id}`}
                fullWidth={true}
            >
                <MD.TextField
                    defaultValue={defaultValue}
                    error={!!error}
                    id={id}
                    label={f.Name}
                    required={!!f.Mandatory}
                    InputProps={{
                        endAdornment:
                            f.Description && f.Description.length ? (
                                <MD.InputAdornment position='end'>
                                    <MD.Tooltip
                                        title={
                                            <pre className={this.props.classes.fieldDescription}>{f.Description}</pre>
                                        }
                                    >
                                        <FontAwesomeIcon icon={FA.faQuestionCircle} />
                                    </MD.Tooltip>
                                </MD.InputAdornment>
                            ) : (
                                ''
                            ),
                    }}
                />
                <MD.FormHelperText id={`${id}_error`}>{error}</MD.FormHelperText>
            </MD.FormControl>
        );
    }

    private validateAddFormShortText(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        data: Map<string, [string, string, boolean]>,
    ) {
        const input = document.getElementById(`formAddAnnotationField_${f.Order}`) as HTMLInputElement;

        if (isNullOrUndefined(input) || isNullOrUndefined(f.Order)) {
            return 1;
        }
        if (f.Mandatory && input.value === '') {
            this.state.annotationFormErrors.set(f.Order, this.props.i18n._('This field is mandatory'));
            return 1;
        }
        if (input.value) {
            data.set(f.Name || '', ['shortText', input.value, false]);
        }
        return 0;
    }

    private generateAddAnnotationFormLongText(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        i: number,
        defaultValue: string,
    ) {
        if (isNullOrUndefined(f.Order)) {
            return '';
        }

        const id = `formAddAnnotationField_${f.Order}`;
        const error = this.state.annotationFormErrors.get(f.Order) || '';

        return (
            <MD.FormControl
                className={this.props.classes.annotationDialogFormElement}
                error={!!error}
                id={`${id}_container`}
                key={`annotationFormControl_${id}`}
                fullWidth={true}
            >
                <MD.TextField
                    defaultValue={defaultValue}
                    error={!!error}
                    id={id}
                    label={f.Name}
                    multiline={true}
                    required={!!f.Mandatory}
                    rows={4}
                    InputProps={{
                        endAdornment:
                            f.Description && f.Description.length ? (
                                <MD.InputAdornment position='end'>
                                    <MD.Tooltip
                                        title={
                                            <pre className={this.props.classes.fieldDescription}>{f.Description}</pre>
                                        }
                                    >
                                        <FontAwesomeIcon icon={FA.faQuestionCircle} />
                                    </MD.Tooltip>
                                </MD.InputAdornment>
                            ) : (
                                ''
                            ),
                    }}
                />
                <MD.FormHelperText id={`${id}_error`}>{error}</MD.FormHelperText>
            </MD.FormControl>
        );
    }

    private validateAddFormLongText(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        data: Map<string, [string, string, boolean]>,
    ) {
        const input = document.getElementById(`formAddAnnotationField_${f.Order}`) as HTMLInputElement;

        if (isNullOrUndefined(input) || isNullOrUndefined(f.Order)) {
            return 1;
        }
        if (f.Mandatory && input.value === '') {
            this.state.annotationFormErrors.set(f.Order, this.props.i18n._('This field is mandatory'));
            return 1;
        }
        if (input.value) {
            data.set(f.Name || '', ['longText', input.value, false]);
        }
        return 0;
    }

    private generateAddAnnotationFormNumber(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        i: number,
        defaultValue: string,
    ) {
        if (isNullOrUndefined(f.Order)) {
            return '';
        }

        const id = `formAddAnnotationField_${f.Order}`;
        const error = this.state.annotationFormErrors.get(f.Order) || '';
        let label = f.Name;
        let defaultValueParsed: number = parseInt(defaultValue, 10);

        if (isNaN(defaultValueParsed)) {
            defaultValueParsed = 0;
        }

        if (f.Data) {
            try {
                const specs = JSON.parse(f.Data);
                const min = parseInt(specs.min, 10);
                const max = parseInt(specs.max, 10);

                if (!isNaN(min) && !isNaN(max)) {
                    label += ' ' + Jed.sprintf(this.props.i18n._('(value between %1$d and %2$d)'), min, max);
                } else if (!isNaN(min)) {
                    label += ' ' + Jed.sprintf(this.props.i18n._('(value higher than %1$d)'), min);
                } else if (!isNaN(max)) {
                    label += ' ' + Jed.sprintf(this.props.i18n._('(value lower than %1$d)'), max);
                }
            } catch (err) {
                Logger.warn(err as string);
            }
        }

        return (
            <MD.FormControl
                className={this.props.classes.annotationDialogFormElement}
                error={!!error}
                id={`${id}_container`}
                key={`annotationFormControl_${id}`}
                fullWidth={true}
            >
                <MD.TextField
                    defaultValue={defaultValueParsed.toString(10)}
                    error={!!error}
                    id={id}
                    label={label}
                    required={!!f.Mandatory}
                    type='number'
                    InputProps={{
                        endAdornment:
                            f.Description && f.Description.length ? (
                                <MD.InputAdornment position='end'>
                                    <MD.Tooltip
                                        title={
                                            <pre className={this.props.classes.fieldDescription}>{f.Description}</pre>
                                        }
                                    >
                                        <FontAwesomeIcon icon={FA.faQuestionCircle} />
                                    </MD.Tooltip>
                                </MD.InputAdornment>
                            ) : (
                                ''
                            ),
                    }}
                />
                <MD.FormHelperText id={`${id}_error`}>{error}</MD.FormHelperText>
            </MD.FormControl>
        );
    }

    private validateAddFormNumber(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        data: Map<string, [string, string, boolean]>,
    ) {
        const input = document.getElementById(`formAddAnnotationField_${f.Order}`) as HTMLInputElement;

        if (isNullOrUndefined(input) || isNullOrUndefined(f.Order)) {
            return 1;
        }

        if (f.Mandatory && input.value === '') {
            this.state.annotationFormErrors.set(f.Order, this.props.i18n._('This field is mandatory'));
            return 1;
        }
        if (input.value) {
            const value = parseInt(input.value, 10);

            if (isNaN(value)) {
                this.state.annotationFormErrors.set(f.Order, this.props.i18n._('Invalid number'));
                return 1;
            }
            if (f.Data) {
                try {
                    const specs = JSON.parse(f.Data);
                    const min = parseInt(specs.min, 10);
                    const max = parseInt(specs.max, 10);
                    if (!isNaN(min) && value < min) {
                        this.state.annotationFormErrors.set(f.Order, this.props.i18n._('Value is lower than minimum'));
                        return 1;
                    }
                    if (!isNaN(max) && value > max) {
                        this.state.annotationFormErrors.set(f.Order, this.props.i18n._('Value is higher than maximum'));
                        return 1;
                    }
                } catch (err) {
                    Logger.warn(err as string);
                    return 1;
                }
                data.set(f.Name || '', ['number', input.value, false]);
            }
        }
        return 0;
    }

    private generateAddAnnotationFormBoolean(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        i: number,
        defaultValue: string,
    ) {
        if (isNullOrUndefined(f.Order)) {
            return '';
        }

        const id = `formAddAnnotationField_${f.Order}`;
        const defaultValueParsed: boolean = defaultValue === '1';

        return (
            <MD.FormControl
                className={this.props.classes.annotationDialogFormElement}
                id={`${id}_container`}
                key={`annotationFormControl_${id}`}
                fullWidth={true}
            >
                <MD.FormControlLabel
                    control={<MD.Checkbox defaultChecked={defaultValueParsed} id={id} value='1' />}
                    label={
                        <span>
                            {f.Name}
                            {f.Description && f.Description.length ? (
                                <MD.Tooltip
                                    style={{ marginLeft: 10 }}
                                    title={<pre className={this.props.classes.fieldDescription}>{f.Description}</pre>}
                                >
                                    <FontAwesomeIcon icon={FA.faQuestionCircle} />
                                </MD.Tooltip>
                            ) : (
                                ''
                            )}
                        </span>
                    }
                />
            </MD.FormControl>
        );
    }

    private validateAddFormBoolean(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        data: Map<string, [string, string, boolean]>,
    ) {
        const input = document.getElementById(`formAddAnnotationField_${f.Order}`) as HTMLInputElement;

        if (isNullOrUndefined(input) || isNullOrUndefined(f.Order)) {
            return 1;
        }
        data.set(f.Name || '', ['boolean', input.checked ? '1' : '0', false]);
        return 0;
    }

    private generateAddAnnotationFormDate(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        i: number,
        defaultValue: string,
    ) {
        if (isNullOrUndefined(f.Order)) {
            return '';
        }
        let defaultValueParsed = parseInt(defaultValue, 10);

        if (isNaN(defaultValueParsed)) {
            defaultValueParsed = new Date().getTime();
        }
        const defaultDate = new Date(defaultValueParsed);

        const id = `formAddAnnotationField_${f.Order}`;
        const error = this.state.annotationFormErrors.get(f.Order) || '';
        let withTime = false;

        if (f.Data && f.Data.length) {
            try {
                withTime = JSON.parse(f.Data).withTime === '1';
            } catch (err) {
                /* */
            }
        }

        return (
            <MD.FormControl
                className={this.props.classes.annotationDialogFormElement}
                error={!!error}
                id={`${id}_container`}
                key={`annotationFormControl_${id}`}
                fullWidth={true}
            >
                <input type='hidden' id={id} value={defaultDate.getTime().toString(10)} />
                {withTime ? (
                    <DateTimePicker
                        ampm={false}
                        dateRangeIcon={<FontAwesomeIcon icon={FA.faCalendarAlt} />}
                        defaultValue={defaultDate}
                        emptyLabel={f.Name + (f.Mandatory ? '  ⃰' : '')}
                        format={this.props.localeInfos.formatLongDateTime}
                        // keyboardIcon={<FontAwesomeIcon icon={FA.faKeyboard} />}
                        leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                        onChange={this.onAnnotationDatePick.bind(this, f.Order)}
                        rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                        timeIcon={<FontAwesomeIcon icon={FA.faClock} />}
                        value={this.state.annotationFormDates.get(f.Order) || null}
                    />
                ) : (
                    <DatePicker
                        defaultValue={defaultDate}
                        emptyLabel={f.Name + (f.Mandatory ? '  ⃰' : '')}
                        format={this.props.localeInfos.formatLongDate}
                        // keyboardIcon={<FontAwesomeIcon icon={FA.faKeyboard} />}
                        leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                        onChange={this.onAnnotationDatePick.bind(this, f.Order)}
                        rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                        value={this.state.annotationFormDates.get(f.Order) || null}
                    />
                )}
                <MD.FormHelperText id={`${id}_error`}>{error}</MD.FormHelperText>
            </MD.FormControl>
        );
    }

    private validateAddFormDate(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        data: Map<string, [string, string, boolean]>,
    ) {
        if (isNullOrUndefined(f.Order)) {
            return 1;
        }
        if (f.Mandatory && !this.state.annotationFormDates.has(f.Order)) {
            this.state.annotationFormErrors.set(f.Order, this.props.i18n._('This field is mandatory'));
            return 1;
        }

        let special = false;

        try {
            special = JSON.parse(f.Data || '').withTime === '1';
        } catch (err) {
            Logger.warn(err as string);
        }

        if (this.state.annotationFormDates.has(f.Order)) {
            data.set(f.Name || '', [
                'date',
                Math.round((this.state.annotationFormDates.get(f.Order) as Date).getTime() / 1000).toString(10),
                special,
            ]);
        }
        return 0;
    }

    private generateAddAnnotationFormList(f: Proto.mediaarchiver.IAnnotationTypeField, defaultValue: string) {
        if (isNullOrUndefined(f.Order)) {
            return '';
        }
        let multiple = false;
        let list = [];
        if (f.Data) {
            try {
                const datas = JSON.parse(f.Data);

                list = JSON.parse(datas.values);
                multiple = datas.multiple === '1';
            } catch (err) {
                /* */
            }
        }
        let defaultValueParsed: string[] = [];
        if (!isNullOrUndefinedOrEmptyString(defaultValue)) {
            try {
                defaultValueParsed = JSON.parse(defaultValue);
            } catch (err) {
                defaultValueParsed = [];
            }
        }
        if (!this.state.annotationFormListValues.has(f.Order)) {
            this.state.annotationFormListValues.set(f.Order, []);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let value: any = this.state.annotationFormListValues.get(f.Order);
        if (multiple) {
            if (Array.isArray(value)) {
                // ok
            } else if (Array.isArray(defaultValueParsed) && defaultValueParsed.length !== 0) {
                value = defaultValueParsed;
            } else if (!Array.isArray(value)) {
                value = [];
            }
        } else {
            if (Array.isArray(value) && value.length > 0 && typeof value[0] === 'string') {
                value = value[0];
            } else if (Array.isArray(defaultValueParsed) && defaultValueParsed.length !== 0) {
                value = defaultValueParsed[0];
            } else {
                value = list[0];
                this.state.annotationFormListValues.set(f.Order, [list[0]]);
            }
        }

        const id = `formAddAnnotationField_${f.Order}`;
        const error = this.state.annotationFormErrors.get(f.Order) || '';

        return (
            <MD.FormControl
                className={this.props.classes.annotationDialogFormElement}
                error={!!error}
                id={`${id}_container`}
                key={`annotationFormControl_${id}`}
                fullWidth={true}
            >
                <MD.InputLabel htmlFor={id}>
                    {f.Name} {f.Mandatory ? <sup>*</sup> : ''}
                </MD.InputLabel>

                <MD.Select
                    multiple={multiple}
                    onChange={this.onAnnotationListValueChange.bind(this, f.Order, multiple)}
                    inputProps={{
                        id,
                        name: id,
                    }}
                    value={value}
                >
                    {list.map((v: string, k: number) => {
                        if (!isNullOrUndefined(v)) {
                            return (
                                <MD.MenuItem key={`${id}_List_${f.Order}_${k}`} value={v}>
                                    {v}
                                </MD.MenuItem>
                            );
                        }
                        return null;
                    })}
                </MD.Select>
                <MD.FormHelperText id={`${id}_error`}>{error}</MD.FormHelperText>
            </MD.FormControl>
        );
    }

    private validateAddFormList(
        f: Proto.mediaarchiver.IAnnotationTypeField,
        data: Map<string, [string, string, boolean]>,
    ) {
        if (isNullOrUndefined(f.Order)) {
            return 1;
        }

        if (
            f.Mandatory &&
            (!this.state.annotationFormListValues.has(f.Order) ||
                (this.state.annotationFormListValues.get(f.Order) as string[]).length === 0)
        ) {
            this.state.annotationFormErrors.set(f.Order, this.props.i18n._('This field is mandatory'));
            return 1;
        }

        if (
            this.state.annotationFormListValues.has(f.Order) &&
            (this.state.annotationFormListValues.get(f.Order) as string[]).length > 0
        ) {
            data.set(f.Name || '', ['list', JSON.stringify(this.state.annotationFormListValues.get(f.Order)), false]);
        }
        return 0;
    }

    private handleCloseAnnotationDialog() {
        this.setState(this.getDefaultState());
        this.props.hideDialog();
        document.removeEventListener('keydown', this.enterCallback);
    }

    private handleOpenAnnotationDialog() {
        let annotationType = '';
        const defaultValues = new Map();

        if (!isNullOrUndefined(this.props.annotations.editedAnnotation)) {
            const annData = JSON.parse(ensureString(this.props.annotations.editedAnnotation.Data));

            if (!isNull(annData.data)) {
                const list = JSON.parse(annData.data);

                if (Array.isArray(list)) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    list.forEach((i: any) => {
                        defaultValues.set(i.label, i.value);
                    });
                }
            }
            annotationType = ensureString(this.props.annotations.editedAnnotation.Type);
        }
        this.setState({
            ...this.state,
            annotationType,
            defaultValues,
            valuesSet: true,
        });
        document.addEventListener('keydown', this.enterCallback);
        this.forceUpdate();
    }

    private handleEnterSubmit(event: KeyboardEvent): void {
        if (
            document.activeElement === null ||
            document.activeElement.tagName === 'TEXTAREA' ||
            isNullOrUndefined(event) ||
            typeof event.key !== 'string'
        ) {
            return;
        }
        if (event.key === 'Enter') {
            this.handleSubmitAnnotationDialog();
        }
    }

    private handleSubmitAnnotationDialog() {
        if (isNull(this.props.annotations.editedAnnotation)) {
            if (
                this.props.timeline.criterias.media === 0 ||
                isNullOrUndefined(this.props.player.startSelection) ||
                isNullOrUndefined(this.props.player.endSelection)
            ) {
                Logger.info('Missing media or selection, skipping');
                this.props.setSnackMessage(this.props.i18n._('No media or selection to create annotation for'));
                return;
            }
        }
        if (this.state.annotationType === '') {
            Logger.info('Missing annotation type, skipping');
            this.props.setSnackMessage(this.props.i18n._('No annotation type selected'));
            return;
        }
        const type = this.props.annotationTypes.annotationTypes.reduce((val, i) => {
            if (isNullOrUndefined(i)) {
                return val;
            }
            if (i.ID === this.state.annotationType) {
                return i;
            }
            return val;
        }, null);
        if (isNullOrUndefined(type)) {
            Logger.info('Invalid annotation type, skipping');
            this.props.setSnackMessage(this.props.i18n._('Invalid annotation type'));
            return;
        }
        const fieldsData = new Map<string, [string, string, boolean]>();
        let errors = 0;
        this.setState({ annotationFormErrors: new Map() });
        type.Fields.filter((i) => !isNullOrUndefined(i))
            .sort((a, b) => (!isNullOrUndefined(a.Order) && !isNullOrUndefined(b.Order) ? a.Order - b.Order : 0))
            .map((f) => {
                switch (f.Type) {
                    case 'shortText':
                        errors += this.validateAddFormShortText(f, fieldsData);
                        break;
                    case 'longText':
                        errors += this.validateAddFormLongText(f, fieldsData);
                        break;
                    case 'number':
                        errors += this.validateAddFormNumber(f, fieldsData);
                        break;
                    case 'boolean':
                        errors += this.validateAddFormBoolean(f, fieldsData);
                        break;
                    case 'date':
                        errors += this.validateAddFormDate(f, fieldsData);
                        break;
                    case 'list':
                        errors += this.validateAddFormList(f, fieldsData);
                        break;
                    default:
                        Logger.info('Unknown field type ?');
                        return;
                }
            });
        if (errors > 0) {
            this.setState({
                annotationFormErrors: new Map(this.state.annotationFormErrors),
            });
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fields: any = [];
        fieldsData.forEach((spec, label) => {
            fields.push({
                label,
                special: spec[2],
                type: spec[0],
                value: spec[1],
            });
        });
        if (isNull(this.props.annotations.editedAnnotation)) {
            trackCreate();
            this.props.createAnnotation(
                new Proto.mediaarchiver.ArgumentsCreateAnnotation({
                    Data: JSON.stringify(fields),
                    End: getDateInTz(this.props.player.endSelection as Date, this.props.timeline.criterias.timezone).getTime(),
                    MediaID: this.props.timeline.criterias.media,
                    Start: getDateInTz(this.props.player.startSelection as Date, this.props.timeline.criterias.timezone).getTime(),
                    Type: this.state.annotationType,
                }),
            );
        } else {
            const annotation = this.props.annotations.editedAnnotation;

            if (isNull(annotation)) {
                return;
            }
            trackEdit();
            annotation.Data = JSON.stringify(fields);
            annotation.Type = this.state.annotationType;
            this.props.editAnnotation(annotation);
        }
    }

    private getAnnotationTypeValue(): string {
        if (this.state.annotationType !== '') {
            return this.state.annotationType;
        }

        const remember = TypedStorage.get('lastAnnotationType', '');
        let annotationType = '';

        this.props.annotationTypes.annotationTypes.some((t) => {
            if (!isNull(t) && t.ID === remember) {
                annotationType = t.ID;
                return true;
            }
            return false;
        });
        if (annotationType !== '') {
            this.setState({ annotationType });
        }
        return annotationType;
    }

    private handleAnnotationTypeChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        const annotationType = ev.target.value as string;

        TypedStorage.set('lastAnnotationType', annotationType);
        this.setState({ annotationType });
    }

    private onAnnotationDatePick(order: number, date: MaterialUiPickersDate) {
        if (isNull(date)) {
            return;
        }
        this.state.annotationFormDates.set(order, new Date(date.unix() * 1000));
        this.setState({
            annotationFormDates: new Map(this.state.annotationFormDates),
        });
    }

    private onAnnotationListValueChange(
        order: number,
        multiple: boolean,
        ev: React.ChangeEvent<{ name?: string; value: unknown }>,
    ) {
        if (multiple) {
            this.state.annotationFormListValues.set(order, (ev.target.value as unknown) as string[]);
        } else {
            this.state.annotationFormListValues.set(order, [ev.target.value as string]);
        }
        this.setState({
            annotationFormListValues: new Map(this.state.annotationFormListValues),
        });
    }

    private goToAnnotationTypes() {
        this.props.history.push('/account/annotationTypes');
    }

    private getDefaultState(): IState {
        return {
            annotationFormDates: new Map(),
            annotationFormErrors: new Map(),
            annotationFormListValues: new Map(),
            annotationType: '',
            defaultValues: new Map(),
            valuesSet: false,
        };
    }
}
