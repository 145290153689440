import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class AnnotationTypesOwned extends AMessage implements IFromCommand {
    private parsedCount: number | null = null;
    private parsedOffset: number | null = null;
    private parsedAnnotationTypes: Proto.mediaarchiver.AnnotationType[] | null = null;

    public constructor() {
        super('annotationTypesOwned');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.AnnotationTypesOwnedArgs)) {
                return reject(new Error('Not an AnnotationTypesOwned message'));
            }
            if (isNullOrUndefined(msg.AnnotationTypesOwnedArgs.Count)) {
                return reject(new Error('Invalid count'));
            }
            if (isNullOrUndefined(msg.AnnotationTypesOwnedArgs.Offset)) {
                return reject(new Error('Invalid offset'));
            }
            if (isNullOrUndefined(msg.AnnotationTypesOwnedArgs.AnnotationTypes)) {
                return reject(new Error('No annotation types list'));
            }
            this.parsedCount = msg.AnnotationTypesOwnedArgs.Count;
            this.parsedOffset = msg.AnnotationTypesOwnedArgs.Offset;
            this.parsedAnnotationTypes = msg.AnnotationTypesOwnedArgs.AnnotationTypes
                .map((proto) => {
                    if (isNullOrUndefined(proto.ID)) {
                        Logger.debug({proto}, 'Skipping annotation type, missing id');
                        return null;
                    }
                    if (isNullOrUndefined(proto.Name)) {
                        Logger.debug({proto}, 'Skipping annotation type, missing name');
                        return null;
                    }
                    return proto;
                })
                .filter((i) => i !== null) as Proto.mediaarchiver.AnnotationType[];
            resolve();
        });
    }

    public get count(): number {
        if (isNull(this.parsedCount)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedCount;
    }

    public get offset(): number {
        if (isNull(this.parsedOffset)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedOffset;
    }

    public get annotationTypes(): Proto.mediaarchiver.AnnotationType[] {
        if (isNull(this.parsedAnnotationTypes)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedAnnotationTypes;
    }
}
