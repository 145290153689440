export enum AnalyticsActionTypes {
    INIT = '@@analytics/INIT',
    OK_BUG20220524 = '@@analytics/OK_BUG20220524',
    SET_CONSENT = '@@analytics/SET_CONSENT',
    SET_SHOULD_ASK = '@@analytics/SET_SHOULD_ASK',
}

export interface IAnalyticsState {
    okBug20220524: boolean;
    shouldAskConsent: boolean;
}

export interface IInitAction {
    type: AnalyticsActionTypes.INIT;
}

export interface ISetConsentAction {
    payload: boolean;
    type: AnalyticsActionTypes.SET_CONSENT;
}

export interface IOKBug20220524Action {
    type: AnalyticsActionTypes.OK_BUG20220524;
}

export interface ISetShouldAskAction {
    payload: boolean;
    type: AnalyticsActionTypes.SET_SHOULD_ASK;
}
