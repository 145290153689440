import { action } from 'typesafe-actions';

import { IPlayer } from '../../Player/Player';
import { PlayerStatus } from './Constant';
import {
    IAskChunkAction,
    IAskStreamAction,
    IPlayerStateSteps,
    IPlayerStats,
    IRefreshTimelineAction,
    ISendStatsAction,
    ISetEndSelectionAction,
    ISetPlayerAction,
    ISetPositionAction,
    ISetSnapshotAction,
    ISetStartSelectionAction,
    ISetStepsAction,
    ISetStatusAction,
    PlayerActionTypes,
} from './Types';

export const askChunk = (mediaID: number, time: Date): IAskChunkAction =>
    action(PlayerActionTypes.ASK_CHUNK, { mediaID, time });
export const askStream = (mediaID: number, time: Date): IAskStreamAction =>
    action(PlayerActionTypes.ASK_STREAM, { mediaID, time });
export const refreshTimeline = (): IRefreshTimelineAction => action(PlayerActionTypes.REFRESH_TIMELINE);
export const setEndSelection = (time: Date | null): ISetEndSelectionAction =>
    action(PlayerActionTypes.END_SELECTION, time);
export const sendStats = (stats: IPlayerStats): ISendStatsAction => action(PlayerActionTypes.SEND_STATS, stats);
export const setPlayer = (elem: IPlayer): ISetPlayerAction => action(PlayerActionTypes.SET_PLAYER, elem);
export const setPosition = (time: Date): ISetPositionAction => action(PlayerActionTypes.SET_POSITION, time);
export const setSnapshot = (data: string | null): ISetSnapshotAction => action(PlayerActionTypes.SET_SNAPSHOT, data);
export const setStartSelection = (time: Date | null): ISetStartSelectionAction =>
    action(PlayerActionTypes.START_SELECTION, time);
export const setSteps = (steps: IPlayerStateSteps): ISetStepsAction => action(PlayerActionTypes.SET_STEPS, steps);
export const setStatus = (status: PlayerStatus): ISetStatusAction => action(PlayerActionTypes.SET_STATUS, status);
