import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import moment from 'moment';
import numeral from 'numeral';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IConnectedReduxProps } from '../../../../Store';
import { I18N } from '../../../../Store/I18n';
import { ITimelineState, setWip } from '../../../../Store/Timeline';
import { ITimelinePopoversState, setCurrentMinute } from '../../../../Store/TimelinePopovers';
import { isNull, isNullOrUndefined } from '../../../../Utils/Various';
import styles from './style';

export interface IUIProps {
    hoverAnchor: HTMLElement | null;
    hoverShow: boolean;
    loading: boolean;
}

interface IPropsFromState {
    i18n: I18N;
    timeline: ITimelineState;
    timelinePopovers: ITimelinePopoversState;
}

interface IPropsFromDispatch {
    setCurrentMinute: typeof setCurrentMinute;
    setWip: typeof setWip;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

const maxCanvasWidth = 5000;

export default class AudienceComponent extends React.Component<AllProps> {
    // TODO: persist
    public state: IUIProps = {
        hoverAnchor: null,
        hoverShow: false,
        loading: false,
    };
    private canvas: HTMLCanvasElement[] | null = null;
    private canvasHover: HTMLCanvasElement[] | null = null;
    private infosBubble: HTMLDivElement = document.createElement('DIV') as HTMLDivElement;
    private lastMinute = 0;
    private minutesCoords: Array<Map<number, number[]>> | null = null;
    private updateTimer = -1;
    private pixelsPerMinute: number | null = null;

    public render(): React.ReactNode {
        this.canvas = null;
        this.canvasHover = null;
        this.minutesCoords = null;
        this.pixelsPerMinute = null;
        if (!this.props.timeline.ready) {
            return (
                <div className={this.props.classes.emptyContainer}>
                    <div>
                        <MD.Typography>
                            {this.props.i18n._('Data is loading')}
                            &nbsp; &nbsp;
                            <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                        </MD.Typography>
                    </div>
                </div>
            );
        }
        if (Object.keys(this.props.timeline.data.audienceEPG).length === 0) {
            return (
                <div className={this.props.classes.emptyContainer}>
                    <div>
                        <MD.Typography>
                            {this.props.i18n._('We dont have any data for this time range, please try again later')}
                        </MD.Typography>
                    </div>
                </div>
            );
        }
        const startTS = this.props.timeline.criterias.start.getTime();
        const endTS = this.props.timeline.criterias.end.getTime();
        const seconds = Math.ceil((endTS - startTS) / 1000);
        const spans = Math.ceil(seconds / this.props.timeline.presentation.zoom.spanLength);
        const totalWidth = spans * this.props.timeline.presentation.zoom.spanWidth;

        this.pixelsPerMinute = totalWidth / (seconds / 60);

        let canvasWidth = 0;
        while (canvasWidth + this.pixelsPerMinute < maxCanvasWidth) {
            canvasWidth += this.pixelsPerMinute;
        }
        if (canvasWidth === 0) {
            canvasWidth = this.pixelsPerMinute;
        }

        let canvasCount = Math.ceil(totalWidth / canvasWidth);
        canvasCount = canvasCount < 1 ? 1 : Math.ceil(canvasCount);
        this.canvas = [];
        this.canvasHover = [];
        this.minutesCoords = [];
        Array.from(Array(canvasCount).keys()).map(() => {
            (this.minutesCoords as Array<Map<number, number[]>>).push(new Map());
        });

        return (
            <div className={this.props.classes.root} onMouseLeave={this.onCanvasOut.bind(this)}>
                <div
                    className={this.props.classes.infosBubble}
                    ref={(c: HTMLDivElement) => {
                        this.infosBubble = c;
                    }}
                    style={{
                        display: isNull(this.props.timelinePopovers.currentAudienceMinute.minute) ? 'none' : 'block',
                    }}
                >
                    <MD.Typography className={this.props.classes.infosBubbleTitle}>
                        {this.props.timelinePopovers.currentAudienceMinute.minute !== null
                            ? moment(this.props.timelinePopovers.currentAudienceMinute.minute).format('LT')
                            : '\u00A0'}
                    </MD.Typography>
                    <MD.Typography className={this.props.classes.infosBubbleLine}>
                        {this.props.i18n._('M.S.')}:
                        <strong>
                            {this.props.timelinePopovers.currentAudienceMinute.marketShare !== -1
                                ? numeral(this.props.timelinePopovers.currentAudienceMinute.marketShare / 100).format(
                                      '0.00%',
                                  )
                                : '\u00A0'}
                        </strong>
                    </MD.Typography>
                    <MD.Typography className={this.props.classes.infosBubbleLine}>
                        {this.props.i18n._('A.R.')}:
                        <strong>
                            {this.props.timelinePopovers.currentAudienceMinute.averageRate !== -1
                                ? numeral(this.props.timelinePopovers.currentAudienceMinute.averageRate / 100).format(
                                      '0.00%',
                                  )
                                : '\u00A0'}
                        </strong>
                    </MD.Typography>
                    <MD.Typography className={this.props.classes.infosBubbleLine}>
                        {this.props.i18n._('W.B.')}:
                        <strong>
                            {this.props.timelinePopovers.currentAudienceMinute.weightedBasis !== -1
                                ? numeral(this.props.timelinePopovers.currentAudienceMinute.weightedBasis).format('0,0')
                                : '\u00A0'}
                        </strong>
                    </MD.Typography>
                </div>
                {Array.from(Array(canvasCount).keys()).map((i) => (
                    <canvas
                        className={this.props.classes.canvas}
                        key={`audiencesCanvas_${i}`}
                        ref={(c) => {
                            if (!isNull(c) && !isNull(this.canvas)) {
                                this.canvas[i] = c;
                            }
                        }}
                    />
                ))}
                {Array.from(Array(canvasCount).keys()).map((i) => (
                    <canvas
                        className={this.props.classes.canvasHover}
                        key={`audiencesCanvasHover_${i}`}
                        onMouseMove={this.onCanvasMove.bind(this, i)}
                        onMouseOut={this.onCanvasOut.bind(this, i)}
                        ref={(c) => {
                            if (!isNull(c) && !isNull(this.canvasHover)) {
                                this.canvasHover[i] = c;
                            }
                        }}
                    />
                ))}
            </div>
        );
    }

    public componentDidMount(): void {
        this.draw();
    }

    public componentDidUpdate(): void {
        this.draw();
    }

    private draw() {
        if (isNull(this.canvas) && isNull(this.canvasHover)) {
            return;
        }

        const startTS = this.props.timeline.criterias.start.getTime();
        const endTS = this.props.timeline.criterias.end.getTime();
        const seconds = Math.ceil((endTS - startTS) / 1000);
        const spans = Math.ceil(seconds / this.props.timeline.presentation.zoom.spanLength);
        const totalWidth = spans * this.props.timeline.presentation.zoom.spanWidth;

        this.pixelsPerMinute = totalWidth / (seconds / 60);

        let canvasWidth = 0;
        let minutesCount = 0;
        while (canvasWidth + this.pixelsPerMinute < maxCanvasWidth) {
            canvasWidth += this.pixelsPerMinute;
            minutesCount++;
        }
        if (canvasWidth === 0) {
            canvasWidth = this.pixelsPerMinute;
            minutesCount = 1;
        }

        let max = 0;

        Object.keys(this.props.timeline.data.audienceEPG).forEach((minuteStr: string) => {
            const minute = parseInt(minuteStr, 10);

            if (minute >= startTS && minute <= endTS) {
                const metas = this.props.timeline.data.audienceEPG[minute].metas;

                if (metas === undefined) {
                    return;
                }
                const viewers = parseInt(metas.viewers, 10);
                const viewersVOSDAL = parseInt(metas.viewersVOSDAL, 10);
                let value = 0;
                if (
                    this.props.timeline.presentation.audienceWithVOSDAL &&
                    !isNaN(viewers) &&
                    !isNaN(viewersVOSDAL) &&
                    viewers !== viewersVOSDAL
                ) {
                    value = viewersVOSDAL;
                } else if (!isNaN(viewers)) {
                    value = viewers;
                }
                if (value > max) {
                    max = value;
                }
            }
        });

        (this.canvas as HTMLCanvasElement[]).forEach((canvas, i) =>
            this.drawCanvas(i, canvas, canvasWidth, minutesCount, max, totalWidth),
        );
    }

    private drawCanvas(
        canvasCount: number,
        canvas: HTMLCanvasElement,
        canvasWidth: number,
        minutesCount: number,
        max: number,
        totalWidth: number,
    ) {
        if (
            isNull(canvas) ||
            isNull(this.pixelsPerMinute) ||
            isNull(this.canvasHover) ||
            isNull(this.canvasHover[canvasCount])
        ) {
            return;
        }

        const canvasHover = this.canvasHover[canvasCount];
        const context = canvas.getContext('2d');

        if (isNull(context)) {
            return;
        }
        const container = canvas.parentElement as HTMLDivElement;
        const end = (canvasCount + 1) * canvasWidth;
        let destCanvasWidth = canvasWidth;

        if (end > totalWidth) {
            destCanvasWidth = totalWidth - canvasCount * canvasWidth;
        }

        canvas.style.height = `${container.offsetHeight}px`;
        canvas.style.width = `${destCanvasWidth}px`;
        canvas.style.left = `${canvasWidth * canvasCount}px`;
        canvasHover.style.height = `${container.offsetHeight}px`;
        canvasHover.style.width = `${destCanvasWidth}px`;
        canvasHover.style.left = `${canvasWidth * canvasCount}px`;
        canvas.height = container.offsetHeight;
        canvas.width = destCanvasWidth;
        canvasHover.height = container.offsetHeight;
        canvasHover.width = destCanvasWidth;
        context.clearRect(0, 0, canvas.width, canvas.height);

        const startTS = this.props.timeline.criterias.start.getTime() + canvasCount * (minutesCount * 60000);
        const endTS = this.props.timeline.criterias.start.getTime() + (canvasCount + 1) * (minutesCount * 60000);

        if (this.props.timeline.presentation.audienceMode === 'dots') {
            this.drawDots(canvas, context, startTS, endTS, max, canvasCount);
        } else {
            this.drawBars(canvas, context, startTS, endTS, max, canvasCount);
        }
    }

    private drawBars(
        canvas: HTMLCanvasElement,
        context: CanvasRenderingContext2D,
        startTS: number,
        endTS: number,
        max: number,
        canvasCount: number,
    ) {
        if (
            isNull(this.pixelsPerMinute) ||
            isNull(this.minutesCoords) ||
            isNullOrUndefined(this.minutesCoords[canvasCount])
        ) {
            return;
        }

        const pixelsPerMinute = this.pixelsPerMinute;
        let x = 0;
        context.lineWidth = 1;
        Object.keys(this.props.timeline.data.audienceEPG).forEach((minuteStr: string) => {
            const minute = parseInt(minuteStr, 10);
            if (isNull(this.minutesCoords) || isNullOrUndefined(this.minutesCoords[canvasCount])) {
                return;
            }
            if (minute >= startTS && minute <= endTS) {
                const metas = this.props.timeline.data.audienceEPG[minute].metas;

                if (metas === undefined) {
                    return;
                }

                const viewers = parseInt(metas.viewers, 10);
                const viewersVOSDAL = parseInt(metas.viewersVOSDAL, 10);

                let value = 0;
                let value2 = 0;

                if (
                    this.props.timeline.presentation.audienceWithVOSDAL &&
                    !isNaN(viewers) &&
                    !isNaN(viewersVOSDAL) &&
                    viewers !== viewersVOSDAL
                ) {
                    value2 = viewersVOSDAL / max;
                }

                if (!isNaN(viewers)) {
                    value = viewers / max;
                }

                const height = Math.round(value * (canvas.height - 5) * 0.9) + 5;
                const height2 = value2 !== 0 ? Math.round(value2 * (canvas.height - 5) * 0.9) + 5 : 0;

                if (
                    !isNull(this.props.timelinePopovers.currentAudienceMinute) &&
                    !isNull(this.props.timelinePopovers.currentAudienceMinute.minute) &&
                    this.props.timelinePopovers.currentAudienceMinute.minute.getTime() === minute
                ) {
                    context.strokeStyle = '#e40f24';
                    context.fillStyle = '#ae3c71';
                } else {
                    context.strokeStyle = '#EC0F16';
                    context.fillStyle = '#8D1525';
                }
                context.fillRect(x, canvas.height - height, pixelsPerMinute, height);
                if (height2) {
                    context.fillStyle = 'green';
                    context.fillRect(x, canvas.height - height2, pixelsPerMinute, height2 - height);
                    context.strokeRect(x, canvas.height - height2, pixelsPerMinute, height2);
                    this.minutesCoords[canvasCount].set(minute, [x, height2]);
                } else {
                    context.strokeRect(x, canvas.height - height, pixelsPerMinute, height);
                    this.minutesCoords[canvasCount].set(minute, [x, height]);
                }
                x += pixelsPerMinute;
            }
        });
    }

    private drawDots(
        canvas: HTMLCanvasElement,
        context: CanvasRenderingContext2D,
        startTS: number,
        endTS: number,
        max: number,
        canvasCount: number,
    ) {
        if (
            isNull(this.pixelsPerMinute) ||
            isNull(this.minutesCoords) ||
            isNullOrUndefined(this.minutesCoords[canvasCount])
        ) {
            return;
        }

        const pixelsPerMinute = this.pixelsPerMinute;
        let x = pixelsPerMinute / 2;
        let previousCoord: number[] | null = null;

        context.lineWidth = 1;
        context.strokeStyle = '#8D1525';
        context.fillStyle = '#8D1525';
        Object.keys(this.props.timeline.data.audienceEPG).forEach((minuteStr: string) => {
            const minute = parseInt(minuteStr, 10);

            if (isNull(this.minutesCoords) || isNullOrUndefined(this.minutesCoords[canvasCount])) {
                return;
            }
            if (minute >= startTS && minute <= endTS) {
                const metas = this.props.timeline.data.audienceEPG[minute].metas;

                if (metas === undefined) {
                    return;
                }

                const viewers = parseInt(metas.viewers, 10);
                const viewersVOSDAL = parseInt(metas.viewersVOSDAL, 10);
                let value = 0;

                if (
                    this.props.timeline.presentation.audienceWithVOSDAL &&
                    !isNaN(viewers) &&
                    !isNaN(viewersVOSDAL) &&
                    viewers !== viewersVOSDAL
                ) {
                    value = viewersVOSDAL / max;
                } else if (!isNaN(viewers)) {
                    value = viewers / max;
                }

                const height = Math.round(value * (canvas.height - 5) * 0.9) + 5;

                context.beginPath();
                context.arc(x, canvas.height - height, 3, 0, 2 * Math.PI, false);
                if (!isNull(previousCoord)) {
                    context.moveTo(previousCoord[0], previousCoord[1]);
                    context.lineTo(x, canvas.height - height);
                }
                context.stroke();
                context.fill();
                previousCoord = [x, canvas.height - height];
                this.minutesCoords[canvasCount].set(minute, [x, height]);
            }
            x += pixelsPerMinute;
        });
    }

    private onCanvasMove(i: number, ev: React.MouseEvent<HTMLCanvasElement>) {
        ev.stopPropagation();

        const currentCursorTime = document.getElementById('currentCursorTime');

        if (isNull(currentCursorTime)) {
            return;
        }
        currentCursorTime.style.opacity = '0';
        if (
            isNull(this.canvas) ||
            isNull(this.canvasHover) ||
            isNullOrUndefined(this.canvas[i]) ||
            isNullOrUndefined(this.canvasHover[i]) ||
            isNullOrUndefined(this.minutesCoords) ||
            isNullOrUndefined(this.minutesCoords[i]) ||
            isNull(this.pixelsPerMinute)
        ) {
            return;
        }
        const pixelsPerMinute = this.pixelsPerMinute;
        const canvasHover = this.canvasHover[i];
        const box = canvasHover.getBoundingClientRect();
        const mouseX = ev.clientX - box.left;
        this.minutesCoords[i].forEach((coords: number[], minute: number) => {
            if (mouseX >= coords[0] && mouseX <= coords[0] + pixelsPerMinute) {
                const epg = this.props.timeline.data.audienceEPG[minute];
                if (minute === this.lastMinute) {
                    return;
                }
                this.lastMinute = minute;

                if (isNull(this.canvas) || isNullOrUndefined(this.canvas[i])) {
                    return;
                }
                const rect = this.canvas[i].getBoundingClientRect();

                this.infosBubble.style.bottom = `${window.innerHeight - rect.bottom + coords[1] + 5}px`;
                if (ev.clientX >= window.innerWidth / 2) {
                    this.infosBubble.style.right = `${window.innerWidth - ev.clientX}px`;
                    this.infosBubble.style.left = 'inherit';
                } else {
                    this.infosBubble.style.left = `${ev.clientX}px`;
                    this.infosBubble.style.right = 'inherit';
                }
                if (!isNull(epg)) {
                    const metas = epg.metas;

                    if (metas === undefined) {
                        return;
                    }
                    const viewersNormal = parseInt(metas.viewers, 10);
                    const arNormal = parseFloat(metas.averageRate);
                    const msNormal = parseFloat(metas.marketShare);
                    const viewersVOSDAL = parseInt(metas.viewersVOSDAL, 10);
                    const arVOSDAL = parseFloat(metas.averageRateVOSDAL);
                    const msVOSDAL = parseFloat(metas.marketShareVOSDAL);

                    let viewers = 0;
                    let ar = 0;
                    let ms = 0;

                    if (
                        this.props.timeline.presentation.audienceWithVOSDAL &&
                        !isNaN(viewersNormal) &&
                        !isNaN(viewersVOSDAL) &&
                        viewersNormal !== viewersVOSDAL
                    ) {
                        viewers = viewersVOSDAL;
                    } else if (!isNaN(viewersNormal)) {
                        viewers = viewersNormal;
                    }
                    if (
                        this.props.timeline.presentation.audienceWithVOSDAL &&
                        !isNaN(arNormal) &&
                        !isNaN(arVOSDAL) &&
                        arNormal !== arVOSDAL
                    ) {
                        ar = arVOSDAL;
                    } else if (!isNaN(arNormal)) {
                        ar = arNormal;
                    }
                    if (
                        this.props.timeline.presentation.audienceWithVOSDAL &&
                        !isNaN(msNormal) &&
                        !isNaN(msVOSDAL) &&
                        msNormal !== msVOSDAL
                    ) {
                        ms = msVOSDAL;
                    } else if (!isNaN(msNormal)) {
                        ms = msNormal;
                    }
                    if (this.updateTimer !== -1) {
                        window.clearTimeout(this.updateTimer);
                        this.updateTimer = -1;
                    }
                    this.updateTimer = window.setTimeout(() => {
                        this.updateTimer = -1;
                        this.props.setCurrentMinute({
                            averageRate: ar,
                            marketShare: ms,
                            minute: new Date(minute),
                            weightedBasis: viewers,
                        });
                    }, 500);

                    const context = canvasHover.getContext('2d');

                    if (isNull(context)) {
                        return;
                    }
                    context.clearRect(0, 0, canvasHover.width, canvasHover.height);
                    context.strokeStyle = '#e40f24';
                    context.fillStyle = '#ae3c71';
                    context.fillRect(coords[0], canvasHover.height - coords[1], pixelsPerMinute, coords[1]);
                    context.strokeRect(coords[0], canvasHover.height - coords[1], pixelsPerMinute, coords[1]);
                }
            }
        });
        const timeTextElement = document.getElementById('rulerText');
        const zoomElement = document.getElementById('rulerZoom');

        if (zoomElement !== null && timeTextElement !== null) {
            zoomElement.style.opacity = '1';
            timeTextElement.style.opacity = '0';
        }
    }

    private onCanvasOut() {
        this.lastMinute = 0;
        if (this.updateTimer !== -1) {
            window.clearTimeout(this.updateTimer);
            this.updateTimer = -1;
        }
        this.props.setCurrentMinute({
            averageRate: -1,
            marketShare: -1,
            minute: null,
            weightedBasis: -1,
        });
        if (isNull(this.canvasHover)) {
            return;
        }
        this.canvasHover.forEach((canvas) => {
            const context = canvas.getContext('2d');
            if (isNull(context)) {
                return;
            }
            context.clearRect(0, 0, canvas.width, canvas.height);
        });
    }
}
