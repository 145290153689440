import { Store } from 'redux';
import { effects } from 'redux-saga';

import { IApplicationState } from '..';
import { BlockMail } from '../../Backend/Commands/BlockMail';
import { GetMail } from '../../Backend/Commands/GetMail';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import { block, get, setError, setOpSuccess, setProgress } from './Actions';
import { MailsActionTypes } from './Types';

function* handleBlock(action: ReturnType<typeof block>) {
    const str = store as Store<IApplicationState>;
    const cmd = new BlockMail(action.payload[0], action.payload[1]);

    yield effects.call(str.dispatch.bind(str, setProgress(true)));

    try {
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.call(str.dispatch.bind(str, setOpSuccess()));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to block mail');
        try {
            const data = JSON.parse((err as Error).message);

            yield effects.call(str.dispatch.bind(str, setError(data)));
        } catch (err2) {
            Logger.warn({ original: err, parsing: err2 }, 'Unable to parse error message');
            yield effects.call(
                str.dispatch.bind(
                    str,
                    setError({
                        general: str.getState().i18n.i18n._('An unknown error occurred'),
                        mail: '',
                    }),
                ),
            );
        } finally {
            yield effects.call(str.dispatch.bind(str, setProgress(false)));
        }
    }
}

function* handleGet(action: ReturnType<typeof get>) {
    const str = store as Store<IApplicationState>;
    const cmd = new GetMail(action.payload);

    yield effects.call(str.dispatch.bind(str, setProgress(true)));
    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get mail');
        try {
            const data = JSON.parse((err as Error).message);

            yield effects.call(str.dispatch.bind(str, setError(data)));
        } catch (err2) {
            Logger.warn({ original: err, parsing: err2 }, 'Unable to parse error message');
            yield effects.call(
                str.dispatch.bind(
                    str,
                    setError({
                        general: str.getState().i18n.i18n._('An unknown error occurred'),
                        mail: '',
                    }),
                ),
            );
        }
    }
}

function* watchBlock() {
    yield effects.takeLatest(MailsActionTypes.BLOCK, handleBlock);
}

function* watchGet() {
    yield effects.takeLatest(MailsActionTypes.GET, handleGet);
}

export function* MailsSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchBlock)]);
    yield effects.all([effects.fork(watchGet)]);
}
