import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefinedOrEmptyString } from '../../Utils/Various';

export class SearchSpeechDate extends AMessage implements IToCommand {
    private medias: number;
    private start: number;
    private end: number;

    public constructor(medias: number, start: Date, end: Date) {
        super('searchSpeechDate');

        if (medias < 1) {
            throw new Error('no media provided');
        }
        if (end.getTime() <= start.getTime()) {
            throw new Error('start is equal of after end');
        }

        this.medias = medias;
        this.start = Math.round(start.getTime() / 1000);
        this.end = Math.round(end.getTime() / 1000);
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                SearchSpeechesDateArgs: {
                    Medias: this.medias,
                    Start: this.start,
                    End: this.end,
                },
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeSearchSpeechesDate,
            }),
        );
    }
}
