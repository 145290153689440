import { AMessage, IFromCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class SFTPAccount extends AMessage implements IFromCommand {
    private parsedAccount: mediaarchiver.ISFTPAccount | null = null;

    public constructor() {
        super('sftpAccount');
    }

    public Parse(msg: mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.SFTPAccountArg)) {
                return reject(new Error('Not a SFTPAccount message'));
            }
            this.parsedAccount = msg.SFTPAccountArg;
            resolve();
        });
    }

    public get account(): mediaarchiver.ISFTPAccount {
        if (isNull(this.parsedAccount)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedAccount;
    }
}
