import { createStyles } from '@material-ui/core';
import { getTheme } from '../../Themes';

const theme = getTheme();

export default createStyles({
    appBar: {
        backgroundColor: theme.palette.background.default,
        maxHeight: theme.mediaarchiver.dimensions.appBarHeight,
        minHeight: theme.mediaarchiver.dimensions.appBarHeight,
        position: 'relative',
        zIndex: theme.zIndex.drawer + 1,
    },
    appBarLine: {
        color: theme.palette.text.primary,
        height: 23,
        marginTop: 5,
        position: 'relative',
    },
    appBarLineAnnotationsFolder: {
        height: '100%',
        position: 'absolute',
        right: 5,
        top: 0,
    },
    appBarLineDrawerFolder: {
        '&>div': {
            cursor: 'pointer',
            position: 'absolute',
            right: 5,
            transition: 'right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            zIndex: 2,
        },

        '&>span': {
            opacity: 1,
            transition: 'opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            zIndex: 1,
        },

        fontSize: '1.1em',
        height: '100%',
        opacity: 1,
        paddingLeft: theme.spacing(1),
        position: 'absolute',
        top: 0,
        width: theme.mediaarchiver.dimensions.drawerWidth,
    },
    appBarLineDrawerFolderClosed: {
        '&>div': {
            color: theme.palette.text.primary,
            right: theme.mediaarchiver.dimensions.drawerWidth - 20,
        },
        '&>span': {
            opacity: 0,
        },
    },
    appBarLinePageTitle: {
        fontSize: '1.1em',
        height: '100%',
        left: theme.mediaarchiver.dimensions.drawerWidth + 10,
        position: 'absolute',
        top: 0,
        transition: 'left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
    appBarLinePageTitleClosed: {
        left: 40,
    },
    barTitle: {
        color: 'inherit',
    },
    bugDialogContent: {
        minWidth: 600,
    },
    bugDialogFormElement: {
        marginTop: theme.spacing(1),
    },
    bugDialogLoading: {
        height: '20px !important',
        width: '20px !important',
    },
    hamburger: {
        marginLeft: -12,
        marginRight: 20,
    },
    logo: {
        width: '100%',
    },
    menuFlags: {
        marginRight: theme.spacing(1),
    },
    menuItems: {
        display: 'block',
        flexGrow: 1,
    },
    menus: {
        display: 'flex',
        flexFlow: 'column',
        flexWrap: 'wrap',
    },
    tabItem: {
        '&:hover': {
            color: theme.palette.text.secondary,
        },
        color: theme.palette.text.primary,
        opacity: 0.7,
        transition: theme.transitions.create('color'),
    },
    tabs: {
        marginLeft: '30px',
        width: '100%',
    },
    version: {
        fontSize: '0.8em',
        opacity: 0.6,
        whiteSpace: 'nowrap',
    },
});
