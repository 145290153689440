import { AMessage, IFromCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class Contacts extends AMessage implements IFromCommand {
    private parsedCount: number | null = null;
    private parsedOffset: number | null = null;
    private parsedContacts: mediaarchiver.IContact[] | null = null;

    public constructor() {
        super('contacts');
    }

    public Parse(msg: mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.ContactsArgs)) {
                return reject(new Error('Not a Contacts message'));
            }
            if (isNullOrUndefined(msg.ContactsArgs.Count)) {
                return reject(new Error('Invalid count'));
            }
            if (isNullOrUndefined(msg.ContactsArgs.Offset)) {
                return reject(new Error('Invalid offset'));
            }
            if (isNullOrUndefined(msg.ContactsArgs.Contacts)) {
                return reject(new Error('No contacts list'));
            }
            this.parsedCount = msg.ContactsArgs.Count;
            this.parsedOffset = msg.ContactsArgs.Offset;
            this.parsedContacts = msg.ContactsArgs.Contacts
                .map((proto) => {
                    if (isNullOrUndefined(proto.ID)) {
                        Logger.debug({proto}, 'Skipping contacts, missing id');
                        return null;
                    }
                    if (isNullOrUndefined(proto.Email)) {
                        Logger.debug({proto}, 'Skipping contacts, missing email');
                        return null;
                    }
                    return {
                        Email: proto.Email,
                        FirstName: proto.FirstName || '',
                        ID: proto.ID,
                        LastName: proto.LastName || '',
                    };
                })
                .filter((i) => i !== null) as mediaarchiver.IContact[];
            resolve();
        });
    }

    public get count(): number {
        if (isNull(this.parsedCount)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedCount;
    }

    public get offset(): number {
        if (isNull(this.parsedOffset)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedOffset;
    }

    public get contacts(): mediaarchiver.IContact[] {
        if (isNull(this.parsedContacts)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedContacts;
    }
}
