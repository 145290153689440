import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IConnectedReduxProps } from '../../../../Store';
import { I18N } from '../../../../Store/I18n';
import { ITimelinePresentationState, setAudienceMode, setAudienceVOSDAL } from '../../../../Store/Timeline';
import { ITimelinePopoversState } from '../../../../Store/TimelinePopovers';
import styles from './styles';

export interface IUIProps {
    arPopupAnchor: HTMLElement | null;
    arPopupShow: boolean;
    msPopupAnchor: HTMLElement | null;
    msPopupShow: boolean;
    showOptions: boolean;
    wbPopupAnchor: HTMLElement | null;
    wbPopupShow: boolean;
}

interface IPropsFromState {
    i18n: I18N;
    timelinePopovers: ITimelinePopoversState;
    timelinePresentation: ITimelinePresentationState;
}

interface IPropsFromDispatch {
    setAudienceMode: typeof setAudienceMode;
    setAudienceVOSDAL: typeof setAudienceVOSDAL;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export default class AudiencesInfosComponent extends React.Component<AllProps> {
    public state: IUIProps = {
        arPopupAnchor: null,
        arPopupShow: false,
        msPopupAnchor: null,
        msPopupShow: false,
        showOptions: false,
        wbPopupAnchor: null,
        wbPopupShow: false,
    };
    public render(): React.ReactNode {
        return (
            <div className={classNames(this.props.classes.legendItem, this.props.classes.legendAudience)}>
                <MD.Typography
                    className={this.props.classes.audienceOptions}
                    onClick={this.showAudienceOptions.bind(this)}
                >
                    <FontAwesomeIcon icon={FA.faCog} />
                </MD.Typography>
                {this.props.i18n._('Audience')}
                <dl className={this.props.classes.legendAudienceData}>
                    <dt>{this.props.i18n._('Hour')}</dt>
                    <dd>
                        {this.props.timelinePopovers.currentAudienceMinute.minute !== null
                            ? moment(this.props.timelinePopovers.currentAudienceMinute.minute).format('LT')
                            : '\u00A0'}
                    </dd>
                    <MD.Popover
                        open={this.state.msPopupShow}
                        anchorEl={this.state.msPopupAnchor}
                        anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'top',
                        }}
                        onClose={this.hidePoppers.bind(this)}
                        transformOrigin={{
                            horizontal: 'center',
                            vertical: 'bottom',
                        }}
                    >
                        <MD.Typography>{this.props.i18n._('Market share')}</MD.Typography>
                    </MD.Popover>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    <dt onClick={this.showMSPopper.bind(this) as any}>{this.props.i18n._('M.S.')}</dt>
                    <dd>
                        {this.props.timelinePopovers.currentAudienceMinute.marketShare !== -1
                            ? numeral(this.props.timelinePopovers.currentAudienceMinute.marketShare / 100).format(
                                  '0.00%',
                              )
                            : '\u00A0'}
                    </dd>
                    <MD.Popover
                        open={this.state.arPopupShow}
                        anchorEl={this.state.arPopupAnchor}
                        anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'top',
                        }}
                        onClose={this.hidePoppers.bind(this)}
                        transformOrigin={{
                            horizontal: 'center',
                            vertical: 'bottom',
                        }}
                    >
                        <MD.Typography>{this.props.i18n._('Average rate')}</MD.Typography>
                    </MD.Popover>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    <dt onClick={this.showARPopper.bind(this) as any}>{this.props.i18n._('A.R.')}</dt>
                    <dd>
                        {this.props.timelinePopovers.currentAudienceMinute.averageRate !== -1
                            ? numeral(this.props.timelinePopovers.currentAudienceMinute.averageRate / 100).format(
                                  '0.00%',
                              )
                            : '\u00A0'}
                    </dd>
                    <MD.Popover
                        open={this.state.wbPopupShow}
                        anchorEl={this.state.wbPopupAnchor}
                        anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'top',
                        }}
                        onClose={this.hidePoppers.bind(this)}
                        onClick={this.hidePoppers.bind(this)}
                        transformOrigin={{
                            horizontal: 'center',
                            vertical: 'bottom',
                        }}
                    >
                        <MD.Typography>{this.props.i18n._('Weighted basis')}</MD.Typography>
                    </MD.Popover>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    <dt onClick={this.showWBPopper.bind(this) as any}>{this.props.i18n._('W.B.')}</dt>
                    <dd>
                        {this.props.timelinePopovers.currentAudienceMinute.weightedBasis !== -1
                            ? numeral(this.props.timelinePopovers.currentAudienceMinute.weightedBasis).format('0,0')
                            : '\u00A0'}
                    </dd>
                </dl>
                <MD.Dialog open={this.state.showOptions} onClose={this.hideAudienceOptions.bind(this)}>
                    <MD.DialogTitle id='audienceOptions'>{this.props.i18n._('Audience options')}</MD.DialogTitle>
                    <MD.DialogContent>
                        <MD.FormGroup row={false}>
                            <MD.FormControl>
                                <MD.FormLabel component='legend'>{this.props.i18n._('Graphic mode')}</MD.FormLabel>
                                <MD.RadioGroup
                                    name='mode'
                                    className={this.props.classes.radios}
                                    value={this.props.timelinePresentation.audienceMode}
                                    onChange={this.onGraphicModeChange.bind(this)}
                                >
                                    <MD.FormControlLabel
                                        value='bars'
                                        control={<MD.Radio />}
                                        label={this.props.i18n._('Bars')}
                                    />
                                    <MD.FormControlLabel
                                        value='dots'
                                        control={<MD.Radio />}
                                        label={this.props.i18n._('Dots')}
                                    />
                                </MD.RadioGroup>
                            </MD.FormControl>
                            <MD.FormControlLabel
                                control={
                                    <MD.Switch
                                        checked={this.props.timelinePresentation.audienceWithVOSDAL}
                                        onChange={this.onShowVOSDALChange.bind(this)}
                                        value='1'
                                    />
                                }
                                label={this.props.i18n._('Add VOSDAL (Viewers On the Same Day As Live)')}
                            />
                        </MD.FormGroup>
                    </MD.DialogContent>
                    <MD.DialogActions>
                        <MD.Button onClick={this.hideAudienceOptions.bind(this)} color='primary'>
                            {this.props.i18n._('Close')}
                        </MD.Button>
                    </MD.DialogActions>
                </MD.Dialog>
            </div>
        );
    }

    private showAudienceOptions() {
        this.setState({ showOptions: true });
    }

    private hideAudienceOptions() {
        this.setState({ showOptions: false });
    }

    private onGraphicModeChange(ev: React.ChangeEvent<{}>, value: string) {
        this.props.setAudienceMode(value);
    }

    private onShowVOSDALChange(ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        this.props.setAudienceVOSDAL(checked);
    }

    private showARPopper(ev: MouseEvent) {
        if (ev.currentTarget === null) {
            return;
        }
        this.setState({
            arPopupAnchor: ev.currentTarget as HTMLElement,
            arPopupShow: true,
        });
    }

    private showMSPopper(ev: MouseEvent) {
        if (ev.currentTarget === null) {
            return;
        }
        this.setState({
            msPopupAnchor: ev.currentTarget as HTMLElement,
            msPopupShow: true,
        });
    }

    private showWBPopper(ev: MouseEvent) {
        if (ev.currentTarget === null) {
            return;
        }
        this.setState({
            wbPopupAnchor: ev.currentTarget as HTMLElement,
            wbPopupShow: true,
        });
    }

    private hidePoppers() {
        this.setState({
            arPopupShow: false,
            msPopupShow: false,
            wbPopupShow: false,
        });
    }
}
