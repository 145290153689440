import { Store } from 'redux';
import { action } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { AnnotationTypes } from '../../Backend/Commands/AnnotationTypes';
import { AnnotationTypesCount } from '../../Backend/Commands/AnnotationTypesCount';
import { AnnotationTypesOwned } from '../../Backend/Commands/AnnotationTypesOwned';
import { AnnotationTypesOwnedCount } from '../../Backend/Commands/AnnotationTypesOwnedCount';
import * as Proto from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import {
    AnnotationTypesActionTypes,
    IAnnotationTypesErrors,
    ICreateAction,
    IDeleteTypeAction,
    IEditAction,
    IGetAction,
    IGetCountAction,
    IGetOwnedAction,
    IGetOwnedCountAction,
    ISetErrorsAction,
    ISetOpSuccessAction,
    ISetProgressAction,
    ITypesUpdateAction,
} from './Types';

export const create = (type: Proto.mediaarchiver.AnnotationType): ICreateAction =>
    action(AnnotationTypesActionTypes.CREATE, type);
export const deleteType = (type: Proto.mediaarchiver.AnnotationType): IDeleteTypeAction =>
    action(AnnotationTypesActionTypes.DELETE, type);
export const edit = (type: Proto.mediaarchiver.AnnotationType): IEditAction =>
    action(AnnotationTypesActionTypes.EDIT, type);
export const get = (offset = 0, limit = 100): IGetAction => action(AnnotationTypesActionTypes.GET, { limit, offset });
export const getCount = (): IGetCountAction => action(AnnotationTypesActionTypes.GET_COUNT);
export const getOwned = (offset = 0, limit = 100): IGetOwnedAction =>
    action(AnnotationTypesActionTypes.GET_OWNED, { limit, offset });
export const getOwnedCount = (): IGetOwnedCountAction => action(AnnotationTypesActionTypes.GET_OWNED_COUNT);
export const setErrors = (errors: IAnnotationTypesErrors): ISetErrorsAction =>
    action(AnnotationTypesActionTypes.SET_ERRORS, errors);
export const setOpSuccess = (type: Proto.mediaarchiver.AnnotationType): ISetOpSuccessAction =>
    action(AnnotationTypesActionTypes.OP_SUCCESS, type);
export const setProgress = (toggle: boolean): ISetProgressAction =>
    action(AnnotationTypesActionTypes.SET_OP_PROGRESS, toggle);
export const typesUpdate = (type: Proto.mediaarchiver.AnnotationType, actionType: string): ITypesUpdateAction =>
    action(AnnotationTypesActionTypes.UPDATE, [type, actionType]);

Backend.getInstance()
    .Bind('annotationTypes', (incoming: IFromCommand) => {
        const cmd = incoming as AnnotationTypes;

        Logger.trace({ cmd }, 'Received annotation types');
        (store as Store).dispatch(action(AnnotationTypesActionTypes.UPDATE_LIST, cmd));
    })
    .Bind('annotationTypesCount', (incoming: IFromCommand) => {
        const cmd = incoming as AnnotationTypesCount;

        Logger.trace({ count: cmd.count }, 'Received annotation types count');
        (store as Store).dispatch(action(AnnotationTypesActionTypes.SET_COUNT, cmd.count));
    })
    .Bind('annotationTypesOwned', (incoming: IFromCommand) => {
        const cmd = incoming as AnnotationTypesOwned;

        Logger.trace({ cmd }, 'Received annotation types (owned)');
        (store as Store).dispatch(action(AnnotationTypesActionTypes.UPDATE_OWNED_LIST, cmd));
    })
    .Bind('annotationTypesOwnedCount', (incoming: IFromCommand) => {
        const cmd = incoming as AnnotationTypesOwnedCount;

        Logger.trace({ count: cmd.count }, 'Received annotation types count (owned)');
        (store as Store).dispatch(action(AnnotationTypesActionTypes.SET_OWNED_COUNT, cmd.count));
    });
