import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { IApplicationState } from '../../../../Store';
import styles from './style';
import TimeRulerComponent from './TimeRuler';

const mapStateToProps = ({ i18n, player, timeline }: IApplicationState) => ({
    i18n: i18n.i18n,
    player,
    timeline,
});

export default connect(mapStateToProps)(withStyles(styles)(TimeRulerComponent));
