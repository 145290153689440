import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { I18N } from '../../../Store/I18n/Types';
import { ITimelinePopoversState } from '../../../Store/TimelinePopovers/Types';
import { getDeclarativeCategoryColor, getTheme } from '../../../Themes';
import { limitText } from '../../../Utils/String';

interface IPropsFromState {
    i18n: I18N;
    timelinePopovers: ITimelinePopoversState;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

const theme = getTheme();

const styles = MD.createStyles({
    popover: {
        display: 'none',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 2000,
    },
    popoverContent: {
        '& h5': {
            fontSize: '1.5em', //'1em',
        },
        '& h6': {
            fontSize: '1.2em', //'0.7em',
            fontStyle: 'italic',
            marginTop: 5,
            textTransform: 'uppercase',
        },
        '& p': {
            fontSize: '1.2em', //'0.7em',
            marginTop: 10,
        },
        flex: '1 0 auto',
        wordWrap: 'break-word',
    },
    popoverCover: {
        '& img': {
            width: '100%',
        },
        width: 100,
    },
    popoverDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 340,
    },
    prgChip: {
        fontSize: '0.7em',
        height: '1.5em',
        margin: theme.spacing(1),
        marginLeft: 0,
        textTransform: 'uppercase',
    },
});

export class PopoverDeclarativeComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        return (
            <MD.Card className={this.props.classes.popover} id='declarativePopover'>
                <div className={this.props.classes.popoverDetails}>
                    <MD.CardContent className={this.props.classes.popoverContent}>
                        <MD.Typography component='h5' variant='h5'>
                            {limitText(
                                this.props.timelinePopovers.popoverDeclarative
                                    ? this.props.timelinePopovers.popoverDeclarative.title
                                    : '',
                                150,
                            )}
                        </MD.Typography>
                        {this.props.timelinePopovers.popoverDeclarative &&
                        this.props.timelinePopovers.popoverDeclarative.categoryText ? (
                            <MD.Typography
                                component='h6'
                                style={{
                                    color: getDeclarativeCategoryColor(
                                        this.props.timelinePopovers.popoverDeclarative.category,
                                    ),
                                }}
                                variant='h6'
                            >
                                {this.props.timelinePopovers.popoverDeclarative
                                    ? this.props.timelinePopovers.popoverDeclarative.categoryText
                                    : ''}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                        {this.props.timelinePopovers.popoverDeclarative &&
                        this.props.timelinePopovers.popoverDeclarative.metas &&
                        this.props.timelinePopovers.popoverDeclarative.metas.firstBroadcast === '1' ? (
                            <MD.Chip
                                label={this.props.i18n._('First broadcast')}
                                className={this.props.classes.prgChip}
                                color='primary'
                            />
                        ) : this.props.timelinePopovers.popoverDeclarative &&
                          this.props.timelinePopovers.popoverDeclarative.metas &&
                          this.props.timelinePopovers.popoverDeclarative.metas.reBroadcast === '1' ? (
                            <MD.Chip label={this.props.i18n._('rebroadcast')} className={this.props.classes.prgChip} />
                        ) : (
                            ''
                        )}

                        <MD.Typography component='p'>
                            {limitText(
                                this.props.timelinePopovers.popoverDeclarative &&
                                    this.props.timelinePopovers.popoverDeclarative.synopsis
                                    ? this.props.timelinePopovers.popoverDeclarative.synopsis
                                    : '',
                                400,
                            )}
                        </MD.Typography>

                        {this.props.timelinePopovers.popoverDeclarative &&
                        this.props.timelinePopovers.popoverDeclarative.metas &&
                        this.props.timelinePopovers.popoverDeclarative.metas.persons ? (
                            <MD.Typography>
                                <em>
                                    {limitText(
                                        this.props.timelinePopovers.popoverDeclarative &&
                                            this.props.timelinePopovers.popoverDeclarative.metas.persons
                                            ? this.props.timelinePopovers.popoverDeclarative.metas.persons
                                            : '',
                                        500,
                                    )}
                                </em>
                            </MD.Typography>
                        ) : (
                            ''
                        )}

                        {this.props.timelinePopovers.popoverDeclarative &&
                        this.props.timelinePopovers.popoverDeclarative.metas &&
                        this.props.timelinePopovers.popoverDeclarative.metas['nielsen type form'] ? (
                            <MD.Typography>
                                <em>
                                    {limitText(
                                        this.props.timelinePopovers.popoverDeclarative &&
                                            this.props.timelinePopovers.popoverDeclarative.metas['nielsen type form']
                                            ? this.props.timelinePopovers.popoverDeclarative.metas['nielsen type form']
                                            : '',
                                        500,
                                    )}
                                </em>
                            </MD.Typography>
                        ) : (
                            ''
                        )}

                        {this.props.timelinePopovers.popoverDeclarative &&
                        this.props.timelinePopovers.popoverDeclarative.metas &&
                        this.props.timelinePopovers.popoverDeclarative.metas['nielsen type stype'] ? (
                            <MD.Typography>
                                <em>
                                    {limitText(
                                        this.props.timelinePopovers.popoverDeclarative &&
                                            this.props.timelinePopovers.popoverDeclarative.metas['nielsen type stype']
                                            ? this.props.timelinePopovers.popoverDeclarative.metas['nielsen type stype']
                                            : '',
                                        500,
                                    )}
                                </em>
                            </MD.Typography>
                        ) : (
                            ''
                        )}

                        {this.props.timelinePopovers.popoverDeclarative &&
                        this.props.timelinePopovers.popoverDeclarative.metas &&
                        this.props.timelinePopovers.popoverDeclarative.metas['nielsen type kind'] ? (
                            <MD.Typography>
                                <em>
                                    {limitText(
                                        this.props.timelinePopovers.popoverDeclarative &&
                                            this.props.timelinePopovers.popoverDeclarative.metas['nielsen type kind']
                                            ? this.props.timelinePopovers.popoverDeclarative.metas['nielsen type kind']
                                            : '',
                                        500,
                                    )}
                                </em>
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                    </MD.CardContent>
                </div>
            </MD.Card>
        );
    }
}

const mapStateToProps = ({ i18n, timelinePopovers }: IApplicationState) => ({
    i18n: i18n.i18n,
    timelinePopovers,
});

export const PopoverDeclarative = connect(mapStateToProps)(MD.withStyles(styles)(PopoverDeclarativeComponent));
