import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class EditSFTPAccount extends AMessage implements IToCommand {
    private account: Proto.mediaarchiver.ISFTPAccount;

    public constructor(account: Proto.mediaarchiver.ISFTPAccount) {
        super('editSFTPAccount');
        this.account = account;
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                EditSFTPAccountArgs : this.account,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeEditSFTPAccount,
            }),
            10000,
        );
    }
}
