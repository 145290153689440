import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../../Themes';

const theme = getTheme();

export default createStyles({
    currentCursorTime: {
        borderLeft: 'solid 1px red',
        bottom: 0,
        display: 'inline-block',
        height: 29,
        opacity: 0,
        position: 'relative',
        verticalAlign: 'bottom',
        width: '1px',
        zoom: '1',
    },
    root: {
        cursor: 'pointer',
        display: 'inline-flex',
        flexDirection: 'row',
        height: '30px !important',
        position: 'absolute',
    },
    timeSpan: {
        '&>span': {
            borderLeft: 'solid 1px #fff',
            bottom: 0,
            display: 'inline-block',
            height: '9px',
            position: 'absolute',
            verticalAlign: 'bottom',
            width: '1px',
            zoom: '1',
        },
        borderColor: '#fff',
        borderStyle: 'solid',
        borderWidth: '0 1px 1px 1px',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        fontFamily: theme.mediaarchiver.fonts.time,
        fontSize: '12px',
        fontWeight: 700,
        paddingLeft: '7px',
        paddingTop: '4px',
        position: 'relative',
        userSelect: 'none',
    },
});
