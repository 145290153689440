import { Locales } from './Constants';

export interface ILocaleInfos {
    // days initials
    daysInitials: string[];
    // first day of week (0 = sunday, 1= monday)
    dow: number;
    // Long date format
    formatLongDate: string;
    // Long date & time format
    formatLongDateTime: string;
    // Long date & time format with seconds and milliseconds
    formatLongDateTimeWithMilliSeconds: string;
    // Long date & time format with seconds
    formatLongDateTimeWithSeconds: string;
    // Short date format
    formatShortDate: string;
    // Short date & time format
    formatShortDateTime: string;
    // Short date & time format with seconds and milliseconds
    formatShortDateTimeWithMilliseconds: string;
    // Short date & time format with seconds
    formatShortDateTimeWithSeconds: string;
    // Short time format
    formatShortTime: string;
    // Short time format with seconds
    formatShortTimeWithMilliseconds: string;
    // Short time format with seconds
    formatShortTimeWithSeconds: string;
    // react-flag-icon-css icon to use
    iconName: string;
    // Moment.js locale to use
    momentLocale: string;
    // Month names
    months: string[];
    // Locale name
    name: string;
}

export enum I18nActionTypes {
    SET_LOCALE = '@@i18n/SET_LOCALE',
    SET_LOCALE_ERROR = '@@i18n/SET_LOCALE_ERROR',
    SET_LOCALE_SUCCESS = '@@i18n/SET_LOCALE_SUCCESS',
}

export interface I18N {
    _: (msgID: string) => string;
    gettext: (msgID: string) => string;
    ngettext: (msgID: string, msgIDPlural: string, n: number) => string;
    npgettext: (ctx: string, msgID: string, msgIDPlural: string, n: number) => string;
    pgettext: (ctx: string, msgID: string) => string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sprintf: (format: string, ...theArgs: any[]) => string;
}

export interface I18NState {
    readonly i18n: I18N;
    readonly localeInfos: ILocaleInfos;
}

export interface ILoadErrorAction {
    payload: string;
    type: I18nActionTypes.SET_LOCALE_ERROR;
}

export interface ILoadSuccessActionPayload {
    i18n: I18N;
    localeInfos: ILocaleInfos;
}

export interface ILoadSuccessAction {
    payload: ILoadSuccessActionPayload;
    type: I18nActionTypes.SET_LOCALE_SUCCESS;
}

export interface ISetLocaleAction {
    payload: Locales;
    type: I18nActionTypes.SET_LOCALE;
}
