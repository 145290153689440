import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetSFTPAccounts extends AMessage implements IToCommand {
    private offset: number;
    private limit: number;

    public constructor(offset = 0, limit = 100) {
        super('getSFTPAccounts');
        this.limit = limit;
        this.offset = offset;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            GetSFTPAccountsArgs : new Proto.mediaarchiver.ArgumentsGetSFTPAccounts({
                Limit: this.limit,
                Offset: this.offset,
            }),
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetSFTPAccounts,
        }));
    }
}
