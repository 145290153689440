import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { validate as validateEmail } from 'email-validator';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Constants } from '../../../constants';
import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { IArchiveState } from '../../../Store/Archives';
import {
    deleteArchive,
    get as getArchives,
    getById as getArchiveById,
    incCounters,
    share as shareArchive,
} from '../../../Store/Archives/Actions';
import { get as getContacts, IContactsState } from '../../../Store/Contacts';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import { IMediasState } from '../../../Store/Medias';
import { update as updateMedias } from '../../../Store/Medias/Actions';
import { IUserState } from '../../../Store/User';
import { Logger } from '../../../Utils/Logger';
import { ensureString } from '../../../Utils/String';
import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../../Utils/Various';
import { ArchiveItem } from './ArchiveItem';
import { EditItem } from './EditItem';

import styles from '../styles';

export interface IRouterMatchParams {
    searchType: string;
}

interface IState {
    editArchive: mediaarchiver.IArchive | null;
    page: number;
    shareArchive: mediaarchiver.IArchive | null;
    shareError: string;
    shareMessage: string;
    shareToContact: string;
    shareToContactCustom: string;
    shareWithDownload: boolean;
    shareWithMessage: boolean;
    showArchive: mediaarchiver.IArchive | null;
    values: mediaarchiver.IArgumentsGetArchives;
}

interface IPropsFromState {
    archives: IArchiveState;
    contacts: IContactsState;
    i18n: I18N;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
    router: RouteComponentProps<IRouterMatchParams>;
    user: IUserState;
}

interface IPropsFromDispatch {
    deleteArchive: typeof deleteArchive;
    getArchiveById: typeof getArchiveById;
    getArchives: typeof getArchives;
    getContacts: typeof getContacts;
    incCounters: typeof incCounters;
    shareArchive: typeof shareArchive;
    updateMedias: typeof updateMedias;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class ArchivesSearchComponent extends React.Component<AllProps, IState> {
    private lastMediaType = 1;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
    }

    public componentDidMount(): void {
        const { medias } = this.props;

        let mediaType = 0;
        switch (this.props.router.match.params.searchType) {
            case 'tv':
                mediaType = 2;
                break;
            case 'radio':
                mediaType = 1;
                break;
            case 'all':
            default:
                mediaType = 0;
        }
        this.lastMediaType = mediaType;
        this.onSearch(true);
        if (!medias.data || medias.data.length === 0) {
            this.props.updateMedias();
        }
        this.props.getContacts(0, 1000);
    }

    public render(): React.ReactNode {
        const { classes } = this.props;
        let mediaType = 0;

        switch (this.props.router.match.params.searchType) {
            case 'tv':
                mediaType = 2;
                break;
            case 'radio':
                mediaType = 1;
                break;
            case 'all':
            default:
                mediaType = 0;
        }

        if (this.lastMediaType !== mediaType) {
            this.lastMediaType = mediaType;
            this.onSearch(true);
        }

        return (
            <>
                {this.buildPlayer()}
                {this.buildShare()}
                <MD.Grid container={true} className={this.props.classes.root} alignItems='stretch' spacing={0}>
                    <MD.Grid className={classes.formContainer} item={true} xs={12}>
                        <MD.Paper className={classes.formPaper} elevation={1}>
                            <form autoComplete={'off'} className={classes.form} noValidate={true}>
                                <MD.Hidden mdDown={true}>{this.buildNameInput()}</MD.Hidden>
                                {this.buildMediaSelect()}
                                {this.buildStartSelect()}
                                {this.buildEndSelect()}
                                {this.buildSearchButton()}
                            </form>
                        </MD.Paper>
                    </MD.Grid>
                    <MD.Grid className={classes.resultsContainer} item={true} xs={12}>
                        {Array.isArray(this.props.archives.archives) ? (
                            <div>
                                {this.buildResultList()}
                                {this.buildResultFooter()}
                            </div>
                        ) : (
                            ''
                        )}
                    </MD.Grid>
                </MD.Grid>
                {((archive: mediaarchiver.IArchive | null) => {
                    if (!isNull(archive)) {
                        return (
                            <Route
                                render={(props) => (
                                    <EditItem
                                        {...props}
                                        archive={archive}
                                        dispatch={this.props.dispatch}
                                        onClose={() => {
                                            this.setState({ editArchive: null });
                                        }}
                                        open={true}
                                    />
                                )}
                            />
                        );
                    }
                    return '';
                })(this.state.editArchive)}
            </>
        );
    }

    private buildPlayer() {
        if (isNull(this.state.showArchive)) {
            return '';
        }

        const { classes } = this.props;
        const tag = this.state.showArchive.MediaType === 2 ? 'video' : 'audio';

        return (
            <MD.Dialog
                onClose={() => this.setState({ showArchive: null })}
                onEntered={this.onPlayerEntered.bind(this)}
                open={!isNull(this.state.showArchive)}
            >
                <MD.Card className={classes.playerCard}>
                    <MD.CardActionArea onClick={this.onCardClick.bind(this)}>
                        <MD.CardMedia
                            autoPlay={this.props.user.user.options.includes(
                                mediaarchiver.UserOptions.USER_OPTION_AUTOPLAY_ARCHIVES,
                            )}
                            className={classes.playerMedia}
                            component={tag}
                            controls
                            preload='auto'
                            src={ensureString(this.state.showArchive.Url)}
                            title={ensureString(this.state.showArchive.Name)}
                        />
                        <MD.CardContent>
                            <MD.Typography gutterBottom variant='h6' component='div'>
                                {this.state.showArchive.Name}
                            </MD.Typography>
                            <MD.Typography variant='body2' color='textSecondary' component='p'>
                                <dl className={classes.playerDefinitions}>
                                    <dt>
                                        <span>{this.props.i18n._('Media')}</span>
                                    </dt>
                                    <dd>{this.getMediaName(this.state.showArchive.MediaID || 0)}</dd>
                                    <dt>
                                        <span>{this.props.i18n._('Start')}</span>
                                    </dt>
                                    <dd>{this.getStart(this.state.showArchive)}</dd>
                                    <dt>
                                        <span>{this.props.i18n._('End')}</span>
                                    </dt>
                                    <dd>{this.getEnd(this.state.showArchive)}</dd>
                                    <dt>
                                        <span>{this.props.i18n._('Comment')}</span>
                                    </dt>
                                    <dd>{this.getComment(this.state.showArchive)}</dd>
                                    <dt>
                                        <span>{this.props.i18n._('Tags')}</span>
                                    </dt>
                                    <dd>{this.getTags(this.state.showArchive)}</dd>
                                </dl>
                            </MD.Typography>
                        </MD.CardContent>
                    </MD.CardActionArea>
                    <MD.CardActions>
                        <MD.Button
                            color='primary'
                            onClick={() => {
                                this.setState({
                                    editArchive: this.state.showArchive,
                                    showArchive: null,
                                });
                            }}
                            size='small'
                        >
                            {this.props.i18n._('Edit')}
                        </MD.Button>
                        <MD.Button
                            color='primary'
                            onClick={() => {
                                if (!isNull(this.state.showArchive)) {
                                    this.onDelete(ensureString(this.state.showArchive.ID));
                                }
                                this.setState({
                                    editArchive: null,
                                    showArchive: null,
                                });
                            }}
                            size='small'
                        >
                            {this.props.i18n._('Delete')}
                        </MD.Button>
                    </MD.CardActions>
                </MD.Card>
            </MD.Dialog>
        );
    }

    private buildShare() {
        if (isNull(this.state.shareArchive)) {
            return '';
        }

        return (
            <MD.Dialog
                fullWidth={true}
                maxWidth='sm'
                onClose={() => this.onShareClose()}
                open={!isNull(this.state.shareArchive)}
            >
                <MD.DialogTitle>{this.props.i18n._('Share archive')}</MD.DialogTitle>
                <MD.DialogContent>
                    <MD.FormControl className={this.props.classes.shareFormControl} fullWidth>
                        <MD.InputLabel id='sendToContactLabel' required>
                            {this.props.i18n._('Choose a contact')}
                        </MD.InputLabel>
                        <MD.Select
                            fullWidth
                            labelId='sendToContactLabel'
                            onChange={(ev: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                                let shareToContact = ev.target.value as string;
                                let shareToContactCustom = this.state.shareToContactCustom;

                                if (isNullOrUndefinedOrEmptyString(shareToContact)) {
                                    shareToContact = '';
                                } else {
                                    shareToContactCustom = '';
                                }
                                this.setState({ shareToContact, shareToContactCustom });
                            }}
                            value={this.state.shareToContact}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('Choose a contact')}</MD.MenuItem>
                            {this.props.contacts.contacts.map((c) => {
                                if (!isNullOrUndefined(c) && !isNullOrUndefinedOrEmptyString(c.Email)) {
                                    return (
                                        <MD.MenuItem key={`contact_${c.ID}`} value={c.Email}>
                                            {`${c.FirstName} ${c.LastName}`}
                                        </MD.MenuItem>
                                    );
                                }
                                return '';
                            })}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl className={this.props.classes.shareFormControl} required>
                        <MD.TextField
                            fullWidth
                            label={this.props.i18n._('Or simply type recipient email address :')}
                            margin='normal'
                            name='recipient'
                            onChange={this.handleRecipientChange.bind(this)}
                            value={ensureString(this.state.shareToContactCustom)}
                        />
                    </MD.FormControl>
                    <MD.FormControl className={this.props.classes.shareFormControl}>
                        <MD.FormLabel htmlFor='allowDownload'>
                            <MD.Checkbox
                                checked={this.state.shareWithDownload}
                                id='allowDownload'
                                onClick={() => {
                                    this.setState({ shareWithDownload: !this.state.shareWithDownload });
                                }}
                            />
                            {this.props.i18n._('Allow archive file download')}
                        </MD.FormLabel>
                    </MD.FormControl>
                    <MD.FormControl className={this.props.classes.shareFormControl}>
                        <MD.FormLabel htmlFor='allowMessage'>
                            <MD.Checkbox
                                checked={this.state.shareWithMessage}
                                id='allowMessage'
                                onClick={() => {
                                    this.setState({ shareWithMessage: !this.state.shareWithMessage });
                                }}
                            />
                            {this.props.i18n._('Add a personal message')}
                        </MD.FormLabel>
                    </MD.FormControl>
                    {this.state.shareWithMessage ? (
                        <MD.FormControl className={this.props.classes.shareFormControl}>
                            <MD.TextField
                                fullWidth
                                label={this.props.i18n._('Message :')}
                                margin='normal'
                                multiline
                                rows={4}
                                name='message'
                                onChange={this.handleMessageChange.bind(this)}
                                value={ensureString(this.state.shareMessage)}
                            />
                        </MD.FormControl>
                    ) : (
                        ''
                    )}
                </MD.DialogContent>
                <MD.DialogActions>
                    <MD.Typography className={this.props.classes.shareError} variant='subtitle1'>
                        {this.state.shareError}
                    </MD.Typography>
                    <MD.Button color='primary' onClick={() => this.onShareClose()}>
                        {this.props.i18n._('Cancel')}
                    </MD.Button>
                    <MD.Button
                        color='secondary'
                        disabled={this.state.shareToContact === '' && this.state.shareToContactCustom === ''}
                        onClick={() => this.onShareSubmit()}
                    >
                        {this.props.i18n._('Share')}
                    </MD.Button>
                </MD.DialogActions>
            </MD.Dialog>
        );
    }

    private handleRecipientChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ shareToContact: '', shareToContactCustom: ev.target.value });
    }

    private handleMessageChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ shareMessage: ev.target.value });
    }

    private onShareClose() {
        this.setState({
            shareArchive: null,
            shareError: '',
            shareMessage: '',
            shareToContact: '',
            shareToContactCustom: '',
            shareWithDownload: true,
            shareWithMessage: false,
        });
    }

    private onShareSubmit() {
        if (isNullOrUndefined(this.state.shareArchive)) {
            return;
        }

        const params: mediaarchiver.IArgumentsShareArchive = {
            AllowDownload: this.state.shareWithDownload,
            ArchiveID: this.state.shareArchive.ID,
        };

        if (this.state.shareToContactCustom !== '') {
            if (!validateEmail(this.state.shareToContactCustom)) {
                this.setState({
                    shareError: this.props.i18n._('This is not a valid email address'),
                });
                return;
            }
            params.ToMail = this.state.shareToContactCustom;
        } else {
            if (this.state.shareToContact === '') {
                this.setState({
                    shareError: this.props.i18n._("Please set the contact's email address"),
                });
                return;
            }
            params.ToMail = this.state.shareToContact;
        }

        if (this.state.shareWithMessage && this.state.shareMessage !== '') {
            params.Message = this.state.shareMessage;
        }
        this.props.shareArchive(params);
        this.onShareClose();
    }

    private buildMediaSelect() {
        const medias = Array.isArray(this.state.values.Medias) ? this.state.values.Medias : [];
        return (
            <MD.FormControl className={this.props.classes.formControl}>
                <MD.InputLabel htmlFor='medias' filled={true} shrink={true}>
                    {this.props.i18n._('Media')}
                </MD.InputLabel>
                <MD.Select
                    key='medias'
                    inputProps={{
                        id: 'media',
                        name: 'media',
                    }}
                    onChange={this.onMediaChange.bind(this)}
                    native={true}
                    value={medias.length ? medias[0] : 'all'}
                >
                    <option value='all' label={this.props.i18n._('All medias')} />
                    {this.props.medias.data.map((media) => {
                        return (
                            <option key={media.id} label={media.name} value={media.id}>
                                {media.name}
                            </option>
                        );
                    })}
                </MD.Select>
            </MD.FormControl>
        );
    }

    private buildStartSelect() {
        const minDate = Constants.minDateWeTV;

        return (
            <MD.FormControl className={this.props.classes.formControl}>
                <MD.InputLabel filled={true} shrink={true}>
                    {this.props.i18n._('Start date')}
                    &nbsp;&nbsp;&nbsp;
                    {!isNullOrUndefinedOrZero(this.state.values.BroadcastStart) ? (
                        <MD.Link onClick={this.onResetStart.bind(this)}>{this.props.i18n._('Reset')}</MD.Link>
                    ) : (
                        ''
                    )}
                </MD.InputLabel>
                <DatePicker
                    format={this.props.localeInfos.formatLongDate}
                    id={'startDate'}
                    leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                    // keyboardIcon={<FontAwesomeIcon icon={FA.faKeyboard} />}
                    // locale={this.props.localeInfos.momentLocale}
                    maxDate={moment().startOf('day')}
                    minDate={minDate}
                    onChange={this.onStartChange.bind(this)}
                    rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                    style={{
                        marginTop: 16,
                    }}
                    value={this.state.values.BroadcastStart}
                />
            </MD.FormControl>
        );
    }

    private buildEndSelect() {
        const minDate = Constants.minDateWeTV;

        return (
            <MD.FormControl className={this.props.classes.formControl}>
                <MD.InputLabel filled shrink>
                    {this.props.i18n._('End date')}
                    &nbsp;&nbsp;&nbsp;
                    {!isNullOrUndefinedOrZero(this.state.values.BroadcastEnd) ? (
                        <MD.Link onClick={this.onResetEnd.bind(this)}>{this.props.i18n._('Reset')}</MD.Link>
                    ) : (
                        ''
                    )}
                </MD.InputLabel>
                <DatePicker
                    format={this.props.localeInfos.formatLongDate}
                    id={'endDate'}
                    // keyboardIcon={<FontAwesomeIcon icon={FA.faKeyboard} />}
                    leftArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleLeft} />}
                    // locale={this.props.localeInfos.momentLocale}
                    maxDate={moment().startOf('day')}
                    minDate={minDate}
                    onChange={this.onEndChange.bind(this)}
                    rightArrowIcon={<FontAwesomeIcon icon={FA.faCaretCircleRight} />}
                    style={{
                        marginTop: 16,
                    }}
                    value={this.state.values.BroadcastEnd}
                />
            </MD.FormControl>
        );
    }

    private buildNameInput() {
        return (
            <MD.FormControl className={this.props.classes.formControlInput}>
                <MD.InputLabel htmlFor='searchName'>{this.props.i18n._('Archive name')}</MD.InputLabel>
                <MD.Input id='searchName' onChange={this.onNameChange.bind(this)} value={this.state.values.Name} />
            </MD.FormControl>
        );
    }

    private buildSearchButton() {
        return (
            <MD.FormControl className={this.props.classes.formControl}>
                <MD.Button
                    className={this.props.classes.searchButton}
                    color='primary'
                    onClick={this.onSearch.bind(this, true)}
                    variant='contained'
                >
                    {this.props.i18n._('Search')}
                </MD.Button>
            </MD.FormControl>
        );
    }

    private buildResultList() {
        if (!Array.isArray(this.props.archives.archives)) {
            return <div />;
        }
        return (
            <MD.Grid className={this.props.classes.results} container>
                {this.props.archives.archives
                    .slice(this.state.page * 20, (this.state.page + 1) * 20)
                    .map((archive, i) => (
                        <Route
                            key={`__result_${i}`}
                            render={(props) => (
                                <ArchiveItem
                                    archive={archive}
                                    dispatch={this.props.dispatch}
                                    onEditClick={(ar: mediaarchiver.IArchive) => {
                                        this.setState({ editArchive: ar });
                                    }}
                                    onPlayClick={(ar: mediaarchiver.IArchive) => {
                                        this.setState({ showArchive: ar });
                                    }}
                                    onShareClick={(ar: mediaarchiver.IArchive) => {
                                        this.setState({
                                            shareArchive: ar,
                                            shareError: '',
                                            shareToContact: '',
                                            shareToContactCustom: '',
                                        });
                                    }}
                                    {...props}
                                />
                            )}
                        />
                    ))}
            </MD.Grid>
        );
    }

    private buildResultFooter() {
        const currentPage = this.state.page + 1;
        let totalPages = Math.ceil(this.props.archives.archives.length / 20);

        totalPages = totalPages === 0 ? 1 : totalPages;
        return (
            <MD.Paper className={this.props.classes.footer}>
                <div className={this.props.classes.footerLeft}>
                    <MD.Typography className={this.props.classes.footerCount}>
                        {this.props.archives.operationInProgress ? (
                            <span>
                                {this.props.i18n._('Searching archives')}
                                &nbsp;
                                <FontAwesomeIcon spin={true} icon={FA.faSpinner} />
                            </span>
                        ) : (
                            this.props.i18n.ngettext('%1$d result', '%1$d results', this.props.archives.archives.length)
                        )}
                    </MD.Typography>
                </div>
                <div className={this.props.classes.footerRight}>
                    <MD.Button
                        disabled={this.props.archives.operationInProgress || currentPage < 6}
                        onClick={this.switchPage.bind(this, -5)}
                        size='small'
                    >
                        <FontAwesomeIcon icon={FA.faChevronDoubleLeft} />
                    </MD.Button>
                    <MD.Button
                        disabled={this.props.archives.operationInProgress || currentPage < 2}
                        onClick={this.switchPage.bind(this, -1)}
                        size='small'
                    >
                        <FontAwesomeIcon icon={FA.faChevronLeft} />
                    </MD.Button>
                    <MD.Typography className={this.props.classes.footerPage}>
                        {this.props.i18n._('Page')}
                        &nbsp;
                        {currentPage} / {totalPages}
                    </MD.Typography>
                    <MD.Button
                        disabled={this.props.archives.operationInProgress || currentPage >= totalPages}
                        onClick={this.switchPage.bind(this, 1)}
                        size='small'
                    >
                        <FontAwesomeIcon icon={FA.faChevronRight} />
                    </MD.Button>
                    <MD.Button
                        disabled={this.props.archives.operationInProgress || currentPage + 5 > totalPages}
                        onClick={this.switchPage.bind(this, +5)}
                        size='small'
                    >
                        <FontAwesomeIcon icon={FA.faChevronDoubleRight} />
                    </MD.Button>
                </div>
            </MD.Paper>
        );
    }

    private onMediaChange(ev: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) {
        if (ev.target === null) {
            return;
        }
        const mediaID = parseInt(ev.target.value as string, 10);
        const mediaIDs = isNaN(mediaID) ? [] : [mediaID];

        this.setState({
            values: {
                ...this.state.values,

                Medias: mediaIDs,
            },
        });
    }

    private onStartChange(d: MaterialUiPickersDate) {
        if (d === null || typeof d === 'undefined') {
            return;
        }
        this.setState({
            values: {
                ...this.state.values,

                BroadcastStart: d.startOf('day').toDate().getTime(),
            },
        });
    }

    private onResetStart(e: React.MouseEvent) {
        e.stopPropagation();
        this.setState({
            values: {
                ...this.state.values,

                BroadcastStart: null,
            },
        });
        return false;
    }

    private onEndChange(d: MaterialUiPickersDate) {
        if (d === null || typeof d === 'undefined') {
            return;
        }
        this.setState({
            values: {
                ...this.state.values,

                BroadcastEnd: d.startOf('day').toDate().getTime(),
            },
        });
    }

    private onResetEnd(ev: React.MouseEvent) {
        ev.stopPropagation();
        this.setState({
            values: {
                ...this.state.values,

                BroadcastEnd: null,
            },
        });
        return false;
    }

    private onNameChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            values: {
                ...this.state.values,

                Name: ev.target.value,
            },
        });
        return false;
    }

    private onSearch(resetPage: boolean) {
        if (resetPage) {
            this.setState({ page: 0 });
        }
        this.props.getArchives({
            ...this.state.values,

            MediaType: this.lastMediaType,
        });
    }

    private switchPage(step: number) {
        if (this.props.archives.archives.length === 0) {
            return;
        }
        const totalPages = Math.floor(this.props.archives.archives.length / 20);

        let dest = this.state.page + step;

        if (dest < 0) {
            dest = 0;
        }
        if (dest > totalPages) {
            dest = totalPages;
        }
        this.setState({ page: dest });
    }

    private getMediaName(mediaID: number): string {
        let mediaName = 'N/A';

        this.props.medias.data.some((media) => {
            if (media.id === mediaID) {
                mediaName = media.name;
                return true;
            }
            return false;
        });
        return mediaName;
    }

    private getStart(archive: mediaarchiver.IArchive): string {
        if (isNullOrUndefinedOrZero(archive.Start)) {
            return '';
        }
        return moment(new Date(archive.Start as number)).format(
            this.props.localeInfos.formatLongDateTimeWithMilliSeconds,
        );
    }

    private getEnd(archive: mediaarchiver.IArchive): string {
        if (isNullOrUndefinedOrZero(archive.Start)) {
            return '';
        }
        if (isNullOrUndefinedOrZero(archive.DurationMS)) {
            return '';
        }
        const date = new Date(((archive.Start as number) + archive.DurationMS) as number);
        return moment(new Date(date)).format(this.props.localeInfos.formatLongDateTimeWithMilliSeconds);
    }

    private getComment(archive: mediaarchiver.IArchive): React.ReactNode {
        if (isNullOrUndefinedOrEmptyString(archive.Comment)) {
            return '--';
        }
        const lines = archive.Comment.split('\n').length;

        if (lines < 3) {
            return archive.Comment;
        }
        return (
            <MD.Tooltip title={<p className={this.props.classes.commentTooltip}>{archive.Comment}</p>}>
                <span>{archive.Comment.split('\n').slice(0, 3).join('\n') + ' ...'}</span>
            </MD.Tooltip>
        );
    }

    private getTags(archive: mediaarchiver.IArchive): React.ReactNode {
        if (!Array.isArray(archive.Tags) || archive.Tags.length === 0) {
            return '--';
        }
        return archive.Tags.join(', ');
    }

    private onCardClick(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        if (isNull(ev.target) || isNull(ev.currentTarget) || (ev.target as HTMLElement).tagName === 'VIDEO') {
            return;
        }
        const els = (ev.currentTarget as HTMLElement).querySelectorAll('VIDEO');

        if (els.length === 0) {
            return;
        }
        const player = els[0] as HTMLVideoElement;

        if (player.paused) {
            player.play().catch(Logger.warn);
        } else {
            player.pause();
        }
    }

    private onPlayerEntered(node: HTMLElement, isAppearing: boolean): void {
        if (
            !isAppearing ||
            isNull(this.state.showArchive) ||
            isNullOrUndefinedOrEmptyString(this.state.showArchive.ID)
        ) {
            return;
        }
        this.props.incCounters(this.state.showArchive.ID, 1, 0);
    }

    private onDelete(id: string): void {
        if (id === '') {
            return;
        }
        if (confirm(this.props.i18n._('Are you sure ? This action is permanent'))) {
            this.props.deleteArchive(id);
        }
    }

    private getDefaultState(): IState {
        return {
            editArchive: null,
            page: 0,
            shareArchive: null,
            shareError: '',
            shareMessage: '',
            shareToContact: '',
            shareToContactCustom: '',
            shareWithDownload: true,
            shareWithMessage: false,
            showArchive: null,
            values: {
                BroadcastEnd: null,
                BroadcastStart: null,
            },
        };
    }
}

const mapStateToProps = (
    { archives, contacts, i18n, medias, user }: IApplicationState,
    ownProps: RouteComponentProps<IRouterMatchParams>,
) => ({
    archives,
    contacts,
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    medias,
    router: ownProps,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    deleteArchive: (id: string) => dispatch(deleteArchive(id)),
    getArchiveById: (id: string) => dispatch(getArchiveById(id)),
    getArchives: (specs: mediaarchiver.IArgumentsGetArchives) => dispatch(getArchives(specs)),
    getContacts: (o?: number, l?: number) => dispatch(getContacts(o, l)),
    incCounters: (archiveID: string, views: number, downloads: number) =>
        dispatch(incCounters(archiveID, views, downloads)),
    shareArchive: (p: mediaarchiver.IArgumentsShareArchive) => dispatch(shareArchive(p)),
    updateMedias: () => dispatch(updateMedias()),
});

export const ArchivesSearch = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(ArchivesSearchComponent));
