import { IPlayer } from '../../Player/Player';
import { mediaarchiver } from '../../Protos/protos';
import { PlayerStatus } from './Constant';

export enum PlayerActionTypes {
    ASK_CHUNK = '@@player/ASK_CHUNK',
    ASK_STREAM = '@@player/ASK_STREAM',
    END_SELECTION = '@@player/END_SELECTION',
    REFRESH_TIMELINE = '@@player/REFRESH_TIMELINE',
    SEND_STATS = '@@player/SEND_STATS',
    SET_PLAYER = '@@player/SET_PLAYER',
    SET_POSITION = '@@player/SET_POSITION',
    SET_SNAPSHOT = '@@player/SET_SNAPSHOT',
    SET_STATUS = '@@player/SET_STATUS',
    SET_STEPS = '@@player/SET_STEPS',
    START_SELECTION = '@@player/START_SELECTION',
}

export interface IPlayerStateSteps {
    readonly backward: number;
    readonly fastBackward: number;
    readonly fastForward: number;
    readonly forward: number;
}

export interface IPlayerState {
    readonly endSelection: Date | null;
    readonly player: IPlayer | null;
    readonly positionUpdateEventElem: HTMLElement;
    readonly snapshot: string;
    readonly startSelection: Date | null;
    readonly status: PlayerStatus;
    readonly steps: IPlayerStateSteps;
}

export interface IPlayerStats {
    chunks: mediaarchiver.IPlayerStatsChunks;
    manifests: mediaarchiver.IPlayerStatsManifest;
}

export interface IAskChunkActionPayload {
    mediaID: number;
    time: Date;
}

export interface IAskChunkAction {
    payload: IAskChunkActionPayload;
    type: PlayerActionTypes.ASK_CHUNK;
}

export interface IAskStreamActionPayload {
    mediaID: number;
    time: Date;
}

export interface IAskStreamAction {
    payload: IAskStreamActionPayload;
    type: PlayerActionTypes.ASK_STREAM;
}

export interface IRefreshTimelineAction {
    type: PlayerActionTypes.REFRESH_TIMELINE;
}

export interface ISendStatsAction {
    payload: IPlayerStats;
    type: PlayerActionTypes.SEND_STATS;
}

export interface ISetEndSelectionAction {
    payload: Date | null;
    type: PlayerActionTypes.END_SELECTION;
}

export interface ISetPlayerAction {
    payload: IPlayer;
    type: PlayerActionTypes.SET_PLAYER;
}

export interface ISetPositionAction {
    payload: Date;
    type: PlayerActionTypes.SET_POSITION;
}

export interface ISetSnapshotAction {
    payload: string | null;
    type: PlayerActionTypes.SET_SNAPSHOT;
}

export interface ISetStartSelectionAction {
    payload: Date | null;
    type: PlayerActionTypes.START_SELECTION;
}

export interface ISetStepsAction {
    payload: IPlayerStateSteps;
    type: PlayerActionTypes.SET_STEPS;
}

export interface ISetStatusAction {
    payload: PlayerStatus;
    type: PlayerActionTypes.SET_STATUS;
}
