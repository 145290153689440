import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import { validate as validateEmail } from 'email-validator';
import React from 'react';
import ReactList from 'react-list';
import { RouteComponentProps } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { mediaarchiver } from '../../../Protos/protos';
import { IConnectedReduxProps } from '../../../Store';
import {
    create,
    deleteContact,
    edit,
    get,
    getCount,
    IContactFormErrors,
    IContactsState,
} from '../../../Store/Contacts';
import { I18N } from '../../../Store/I18n';
import { setPageTitle } from '../../../Store/Layout';
import { getTheme } from '../../../Themes';
import { capitalize, ensureString } from '../../../Utils/String';
import { isNull, isNullOrUndefined } from '../../../Utils/Various';

import styles from './styles';

export interface IStateFormValues {
    email: string;
    firstName: string;
    lastName: string;
}

export interface IState {
    editingContact: mediaarchiver.IContact;
    errors: IContactFormErrors;
}

export interface IRouterMatchParams {
    subpage: string;
}

interface IPropsFromState {
    contacts: IContactsState;
    i18n: I18N;
    router: RouteComponentProps<IRouterMatchParams>;
}

interface IPropsFromDispatch {
    create: typeof create;
    delete: typeof deleteContact;
    edit: typeof edit;
    get: typeof get;
    getCount: typeof getCount;
    setPageTitle: typeof setPageTitle;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

const theme = getTheme();

export default class AccountContactsComponent extends React.Component<AllProps, IState> {
    private alreadyAskedOffsets = [0];
    private errorCallback: (ev: CustomEvent<IContactFormErrors>) => void;
    private lastCreatedID = '';
    private list: ReactList | null = null;
    private successCallback: (ev: CustomEvent<{}>) => void;

    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
        this.successCallback = this.onOpSuccess.bind(this);
        this.errorCallback = this.onOpError.bind(this);
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public componentDidMount(): void {
        this.props.setPageTitle(this.props.i18n._('My contacts'));
        this.props.get(0, 1000);
        this.props.getCount();
        this.props.contacts.opSuccessEventElement.addEventListener<any>('opSuccess', this.successCallback);
        this.props.contacts.opSuccessEventElement.addEventListener<any>('opError', this.errorCallback);
    }

    public componentWillUnmount(): void {
        this.props.contacts.opSuccessEventElement.removeEventListener<any>('opSuccess', this.successCallback);
        this.props.contacts.opSuccessEventElement.removeEventListener<any>('opError', this.errorCallback);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    public render(): React.ReactNode {
        return (
            <MD.Grid container={true} className={this.props.classes.root} spacing={0}>
                <MD.Grid className={this.props.classes.listContainer} item={true} sm={12} lg={8}>
                    <MD.Paper className={this.props.classes.listContainerContent}>
                        <MD.Typography variant='h5'>
                            {this.props.i18n._('Mail contacts list')} ({this.props.contacts.total})
                        </MD.Typography>
                        <MD.List className={this.props.classes.listListContainer}>
                            <ReactList
                                itemRenderer={this.renderContact.bind(this)}
                                length={this.props.contacts.total}
                                ref={(c) => {
                                    this.list = c;
                                }}
                                type='uniform'
                            />
                        </MD.List>
                    </MD.Paper>
                </MD.Grid>
                <MD.Grid className={this.props.classes.createContainer} item={true} sm={12} lg={4}>
                    <MD.Paper className={this.props.classes.createContainerContent}>
                        <MD.Typography variant='h5'>
                            {this.state.editingContact.ID === ''
                                ? this.props.i18n._('Create a contact')
                                : this.props.i18n._('Edit contact')}
                        </MD.Typography>
                        <form
                            autoComplete='off'
                            className={this.props.classes.form}
                            style={{
                                opacity: this.props.contacts.operationInProgress ? 0.2 : 1,
                            }}
                        >
                            <MD.FormControl
                                className={this.props.classes.formControl}
                                disabled={this.props.contacts.operationInProgress}
                                error={this.state.errors.lastName.length > 0}
                            >
                                <MD.TextField
                                    name='lastName'
                                    error={this.state.errors.lastName !== ''}
                                    fullWidth
                                    helperText={this.state.errors.lastName}
                                    label={this.props.i18n._('Last name')}
                                    margin='normal'
                                    onChange={this.handleFormChange.bind(this, 'lastName')}
                                    value={ensureString(this.state.editingContact.LastName)}
                                />
                            </MD.FormControl>
                            <MD.FormControl
                                className={this.props.classes.formControl}
                                disabled={this.props.contacts.operationInProgress}
                                error={this.state.errors.firstName.length > 0}
                            >
                                <MD.TextField
                                    name='firstName'
                                    error={this.state.errors.firstName !== ''}
                                    fullWidth
                                    helperText={this.state.errors.firstName}
                                    label={this.props.i18n._('First name')}
                                    margin='normal'
                                    onChange={this.handleFormChange.bind(this, 'firstName')}
                                    value={ensureString(this.state.editingContact.FirstName)}
                                />
                            </MD.FormControl>
                            <MD.FormControl
                                className={this.props.classes.formControl}
                                disabled={this.props.contacts.operationInProgress}
                                error={this.state.errors.email.length > 0}
                            >
                                <MD.TextField
                                    name='email'
                                    error={this.state.errors.email !== ''}
                                    fullWidth
                                    helperText={this.state.errors.email}
                                    label={this.props.i18n._('Email address')}
                                    margin='normal'
                                    onChange={this.handleFormChange.bind(this, 'email')}
                                    value={ensureString(this.state.editingContact.Email)}
                                />
                            </MD.FormControl>
                            <MD.FormControl
                                className={this.props.classes.formControl}
                                error={this.state.errors.general !== ''}
                                disabled={this.props.contacts.operationInProgress}
                            >
                                {this.props.contacts.operationInProgress ? (
                                    <MD.Button
                                        className={this.props.classes.createButton}
                                        color='primary'
                                        variant='contained'
                                    >
                                        <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                                        {this.props.i18n._('Please wait ...')}
                                    </MD.Button>
                                ) : this.state.editingContact.ID === '' ? (
                                    <MD.Button
                                        className={this.props.classes.createButton}
                                        color='primary'
                                        onClick={this.onCreate.bind(this)}
                                        variant='contained'
                                    >
                                        <FontAwesomeIcon icon={FA.faPlus} />
                                        {this.props.i18n._('Create contact')}
                                    </MD.Button>
                                ) : (
                                    <div>
                                        <MD.Button
                                            className={this.props.classes.createButton}
                                            color='primary'
                                            onClick={this.onEdit.bind(this)}
                                            variant='contained'
                                        >
                                            <FontAwesomeIcon icon={FA.faEdit} />
                                            {this.props.i18n._('Edit contact')}
                                        </MD.Button>
                                        <MD.Button
                                            className={this.props.classes.createButton}
                                            color='primary'
                                            onClick={this.onDelete.bind(this)}
                                            variant='contained'
                                        >
                                            <FontAwesomeIcon icon={FA.faTrash} />
                                            {this.props.i18n._('Delete contact')}
                                        </MD.Button>
                                    </div>
                                )}
                                {this.state.errors.general.length > 0 ? (
                                    <MD.FormHelperText>{this.state.errors.general}</MD.FormHelperText>
                                ) : (
                                    ''
                                )}
                                <MD.Typography
                                    component='a'
                                    onClick={this.onCancelForm.bind(this)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {this.props.i18n._('Cancel')}
                                </MD.Typography>
                            </MD.FormControl>
                        </form>
                    </MD.Paper>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private renderContact(index: number, key: number | string): JSX.Element {
        if (isNullOrUndefined(this.props.contacts.contacts[index])) {
            const nearest = index - (index % 1000);

            if (this.alreadyAskedOffsets.indexOf(nearest) === -1) {
                this.props.get(nearest, 1000);
                this.alreadyAskedOffsets.push(nearest);
            }
            return (
                <MD.ListItem className={this.props.classes.listListItem} key={key}>
                    <FontAwesomeIcon icon={FA.faSpinner} spin={true} color={theme.palette.text.primary} />
                </MD.ListItem>
            );
        }

        const contact = this.props.contacts.contacts[index] as mediaarchiver.IContact;

        return (
            <MD.ListItem
                className={this.props.classes.listListItem}
                key={key}
                onClick={this.onEditContact.bind(this, contact)}
            >
                <MD.ListItemText
                    primary={`${capitalize(ensureString(contact.FirstName))} ${ensureString(
                        contact.LastName,
                    ).toLocaleUpperCase()}`}
                    secondary={ensureString(contact.Email)}
                />
            </MD.ListItem>
        );
    }
    private onEditContact(contact: mediaarchiver.IContact) {
        this.setState({
            ...this.state,

            editingContact: { ...contact },
        });
    }

    private onCancelForm() {
        this.setState({
            ...this.state,

            editingContact: this.getDefaultState().editingContact,
            errors: this.getDefaultState().errors,
        });
    }

    private onCreate() {
        if (!this.checkForm()) {
            return;
        }
        this.lastCreatedID = uuid();
        this.props.create({
            ...this.state.editingContact,
            ID: this.lastCreatedID,
        });
    }

    private onEdit() {
        if (this.state.editingContact.ID === '' || !this.checkForm()) {
            return;
        }
        this.props.edit(this.state.editingContact);
    }

    private onDelete() {
        if (this.state.editingContact.ID === '') {
            return;
        }
        this.props.delete(this.state.editingContact.ID as string);
    }

    private checkForm(): boolean {
        let ok = true;
        const state = {
            ...this.state,
            errors: this.getDefaultState().errors,
        };

        if (ensureString(state.editingContact.FirstName).length === 0) {
            ok = false;
            state.errors.firstName = this.props.i18n._("Please set the contact's first name");
        } else if (ensureString(state.editingContact.FirstName).length > 255) {
            ok = false;
            state.errors.firstName = this.props.i18n._("Contact's first name should be less than 256 characters");
        } else {
            state.editingContact.FirstName = capitalize(ensureString(state.editingContact.FirstName));
        }
        if (ensureString(state.editingContact.LastName).length === 0) {
            ok = false;
            state.errors.lastName = this.props.i18n._("Please set the contact's last name");
        } else if (ensureString(state.editingContact.LastName).length > 255) {
            ok = false;
            state.errors.lastName = this.props.i18n._("Contact's last name should be less than 256 characters");
        } else {
            state.editingContact.LastName = ensureString(state.editingContact.LastName).toLocaleUpperCase();
        }
        if (ensureString(state.editingContact.Email).length === 0) {
            ok = false;
            state.errors.email = this.props.i18n._("Please set the contact's email address");
        } else if (!validateEmail(ensureString(state.editingContact.Email))) {
            ok = false;
            state.errors.email = this.props.i18n._('This is not a valid email address');
        }
        this.setState(state);
        return ok;
    }

    private getDefaultState(): IState {
        return {
            editingContact: {
                Email: '',
                FirstName: '',
                ID: '',
                LastName: '',
            },
            errors: {
                email: '',
                firstName: '',
                general: '',
                lastName: '',
            },
        };
    }

    private handleFormChange(name: string, ev: React.ChangeEvent<HTMLInputElement>) {
        const state = this.state;

        switch (name) {
            case 'lastName':
                state.editingContact.LastName = ev.target.value;
                break;
            case 'firstName':
                state.editingContact.FirstName = ev.target.value;
                break;
            case 'email':
                state.editingContact.Email = ev.target.value;
                break;
            default:
                break;
        }
        this.setState(state);
    }

    private onOpSuccess() {
        const idToSearch = this.lastCreatedID === '' ? this.state.editingContact.ID : this.lastCreatedID;

        this.lastCreatedID = '';
        if (isNull(this.list) || idToSearch === '') {
            return;
        }
        let i = -1;
        this.props.contacts.contacts.some((contact, j) => {
            if (!isNull(contact) && idToSearch === contact.ID) {
                i = j;
                return true;
            }
            return false;
        });
        if (i !== -1) {
            this.list.scrollAround(i);
        }
        this.onCancelForm();
    }

    private onOpError(ev: CustomEvent<IContactFormErrors>) {
        this.lastCreatedID = '';
        this.setState({
            ...this.state,

            errors: ev.detail,
        });
    }
}
