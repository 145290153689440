import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class TalkGetProgramTypes extends AMessage implements IToCommand {
    public constructor() {
        super('talkGetProgramTypes');
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeTalkGetProgramTypes,
            }),
        );
    }
}
