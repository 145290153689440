import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    line1: {
        minHeight: '15px',
        position: 'relative',
        width: '100%',
    },
    line2: {
        minHeight: '15px',
        position: 'relative',
        width: '100%',
    },
    program: {
        alignItems: 'center',
        background: theme.mediaarchiver.colors.xmlProgramBackground,
        border: '1px solid black',
        borderRight: '0px solid',
        borderTop: '0px solid',
        color: 'black',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '11px',
        height: '14px',
        justifyContent: 'left',
        lineHeight: '11px',
        marginTop: '3px',
        overflow: 'hidden',
        paddingLeft: '3px',
        position: 'absolute',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
    },
    root: {
        alignItems: 'flex-end',
        display: 'inline-flex',
        flexDirection: 'column',
        height: '15px',
        width: '100%',
    },
});
