import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { connect, Provider } from 'react-redux';
import { Route } from 'react-router';
import { Dispatch, Store } from 'redux';

import { Config } from '../config';
import { IApplicationState } from '../Store';
import { watchTick } from '../Store/Archives/Actions';
import { Locales, setLocale } from '../Store/I18n';
import { TypedStorage } from '../Utils/TypedStorage';
import { Router } from './Router';

interface IPropsFromDispatch {
    setLocale: typeof setLocale;
    watchTick: typeof watchTick;
}

interface IOwnProps {
    store: Store<IApplicationState>;
    history: History;
}

type AllProps = IPropsFromDispatch & IOwnProps;

class MainComponent extends React.Component<AllProps> {
    private archiveWatchTimer = 0;

    public constructor(props: AllProps) {
        super(props);
        this.props.setLocale(TypedStorage.get('locale', TypedStorage.get('locale', 'fr_FR')) as Locales);
    }

    public componentDidMount(): void {
        if (this.archiveWatchTimer !== 0) {
            window.clearInterval(this.archiveWatchTimer);
        }
        this.archiveWatchTimer = window.setInterval(
            () => this.archiveWatchTick(),
            Config.archivesPollingDelay,
        );
    }

    public render(): React.ReactNode {
        return (
            <DndProvider backend={Backend}>
                <Provider store={this.props.store}>
                    <ConnectedRouter history={this.props.history}>
                        <Route render={() => <Router />} />
                    </ConnectedRouter>
                </Provider>
            </DndProvider>
        );
    }

    private archiveWatchTick() {
        this.props.watchTick();
    }
}

const mapStateToProps = ({ archives }: IApplicationState) => ({ archives });

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setLocale: (locale: Locales) => dispatch(setLocale(locale)),
    watchTick: () => dispatch(watchTick()),
});

const Main = connect<{}, IPropsFromDispatch, IOwnProps, IApplicationState>(
    mapStateToProps,
    mapDispatchToProps,
)(MainComponent);

export { Main };
