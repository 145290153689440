import { AMessage, IToCommand } from '.';
import {mediaarchiver } from '../../Protos/protos';

export class CreateContact extends AMessage implements IToCommand {
    private contact: mediaarchiver.IContact;

    public constructor(contact: mediaarchiver.IContact) {
        super('createContact');
        this.contact = contact;
    }

    public Send(): Promise<void> {
        return this.send(new mediaarchiver.WSMessage({
            CreateContactArgs : this.contact,
            Type: mediaarchiver.WSMessageType.MessageTypeCreateContact,
        }));
    }
}
