// Whole component needs redesign
/* eslint-disable */
import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import * as React from 'react';
import { Color, HEXColor, RGBColor, SketchPicker } from 'react-color';
import ReactList from 'react-list';
import { RouteComponentProps } from 'react-router-dom';

import * as Proto from '../../../Protos/protos';
import { IConnectedReduxProps } from '../../../Store';
import { IAnnotationTypesErrors, IAnnotationTypesState } from '../../../Store/AnnotationTypes';
import { create, deleteType, edit, getOwned, getOwnedCount, setErrors } from '../../../Store/AnnotationTypes/Actions';
import { I18N } from '../../../Store/I18n';
import { IUserState } from '../../../Store/User/Types';
import { setPageTitle } from '../../../Store/Layout';
import { getTheme } from '../../../Themes';
import { isNull, isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../Utils/Various';
import styles from './styles';

const theme = getTheme();

interface IUIProps {
    annotationTypes: Proto.mediaarchiver.AnnotationType[];
    color: string;
    colorShow: boolean;
    editingAnnotationType: Proto.mediaarchiver.AnnotationType;
    editingField: Proto.mediaarchiver.AnnotationTypeField;
    editingFieldData: Map<string, string>;
    editingFieldErrors: IFieldFormError;
    formtab: number;
    loading: boolean;
    showFieldForm: boolean;
}

interface IFieldFormError {
    type: string;
    label: string;
    option: string;
}

export interface IRouterMatchParams {
    subpage: string;
}

interface IPropsFromState {
    annotationTypes: IAnnotationTypesState;
    i18n: I18N;
    router: RouteComponentProps<IRouterMatchParams>;
    user: IUserState;
}

interface IPropsFromDispatch {
    create: typeof create;
    delete: typeof deleteType;
    edit: typeof edit;
    getOwned: typeof getOwned;
    getOwnedCount: typeof getOwnedCount;
    setErrors: typeof setErrors;
    setPageTitle: typeof setPageTitle;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export default class AccountAnnotationTypesComponent extends React.Component<AllProps> {
    public state = {
        annotationTypes: [],
        color:
            '#' +
            '0123456789abcdef'
                .split('')
                .map((v, i, a) => {
                    return i > 5 ? null : a[Math.floor(Math.random() * 16)];
                })
                .join(''),
        colorShow: false,
        editingAnnotationType: new Proto.mediaarchiver.AnnotationType(),
        editingField: new Proto.mediaarchiver.AnnotationTypeField(),
        editingFieldData: new Map(),
        editingFieldErrors: {
            label: '',
            option: '',
            type: '',
        },
        formtab: 1,
        loading: false,
        showFieldForm: false,
    };

    private alreadyAskedOffsets = [0];
    private form: HTMLFormElement | null = null;
    private list: ReactList | null = null;

    public componentDidMount(): void {
        this.props.setPageTitle(this.props.i18n._('My annotation types'));
        this.props.getOwned(0, 1000);
        this.props.getOwnedCount();
        this.props.annotationTypes.opSuccessEventElement.addEventListener<any>('opSuccess', (ev: CustomEvent) => {
            window.setTimeout(() => {
                this.forceUpdate();
                this.onCancelForm();
                if (isNull(this.list) || isNull(ev.detail)) {
                    return;
                }
                let i = -1;
                this.props.annotationTypes.annotationTypesOwned.some((annotationTypes, j) => {
                    if (
                        !isNull(annotationTypes) &&
                        (ev.detail as Proto.mediaarchiver.AnnotationType).ID === annotationTypes.ID
                    ) {
                        i = j;
                        return true;
                    }
                    return false;
                });
                if (i !== -1) {
                    this.list.scrollAround(i);
                }
            }, 200);
        });
    }

    public render(): React.ReactNode {
        let formAddListValues: string[] = [];

        try {
            formAddListValues = JSON.parse(this.state.editingFieldData.get('values') || '');
        } catch (err) {
            formAddListValues = [];
        }

        return (
            <MD.Grid container={true} className={this.props.classes.root} spacing={0}>
                <MD.Grid className={this.props.classes.listContainer} item={true} sm={12} lg={4}>
                    <MD.Paper className={this.props.classes.listContainerContent}>
                        <MD.Typography variant='h5'>
                            {this.props.i18n._('My annotation types')} ({this.props.annotationTypes.totalOwned})
                        </MD.Typography>
                        <MD.List className={this.props.classes.listListContainer}>
                            <ReactList
                                itemRenderer={this.renderAnnotationType.bind(this)}
                                length={this.props.annotationTypes.totalOwned}
                                ref={(c) => {
                                    this.list = c;
                                }}
                                type='uniform'
                            />
                        </MD.List>
                    </MD.Paper>
                </MD.Grid>
                <MD.Grid className={this.props.classes.createContainer} item={true} sm={12} lg={8}>
                    <MD.Paper className={this.props.classes.createContainerContent}>
                        <MD.Typography variant='h5'>
                            {!this.state.editingAnnotationType || !this.state.editingAnnotationType.ID
                                ? this.props.i18n._('Create an annotation type')
                                : this.props.i18n._('Edit annotation type')}
                        </MD.Typography>
                        <MD.Tabs
                            onChange={this.onTabChange.bind(this)}
                            indicatorColor='primary'
                            textColor='primary'
                            value={this.state.formtab}
                            variant='fullWidth'
                        >
                            <MD.Tab value={1} label={this.props.i18n._('Informations')} />
                            <MD.Tab value={2} label={this.props.i18n._('Fields')} />
                        </MD.Tabs>
                        <form
                            autoComplete='off'
                            className={this.props.classes.form}
                            ref={(c) => {
                                this.form = c;
                            }}
                            style={{
                                opacity: this.props.annotationTypes.operationInProgress ? 0.2 : 1,
                            }}
                        >
                            {this.state.formtab === 1 ? (
                                <MD.FormControl>
                                    <MD.FormControl
                                        className={this.props.classes.formControl}
                                        disabled={this.props.annotationTypes.operationInProgress}
                                        error={this.props.annotationTypes.formErrors.name.length > 0}
                                    >
                                        <MD.InputLabel htmlFor='newAnnotationTypesName'>
                                            {this.props.i18n._('Name')}
                                        </MD.InputLabel>
                                        <MD.Input
                                            className={this.props.classes.createContainerInput}
                                            defaultValue={this.state.editingAnnotationType.Name}
                                            id='newAnnotationTypesName'
                                        />
                                        {this.props.annotationTypes.formErrors.name.length > 0 ? (
                                            <MD.FormHelperText>
                                                {this.props.annotationTypes.formErrors.name}
                                            </MD.FormHelperText>
                                        ) : (
                                            ''
                                        )}
                                    </MD.FormControl>
                                    <MD.FormControl
                                        className={this.props.classes.formControl}
                                        disabled={this.props.annotationTypes.operationInProgress}
                                    >
                                        <MD.InputLabel htmlFor='newAnnotationTypesDescription'>
                                            {this.props.i18n._('Description')}
                                        </MD.InputLabel>
                                        <MD.Input
                                            className={this.props.classes.createContainerInput}
                                            defaultValue={this.state.editingAnnotationType.Description}
                                            id='newAnnotationTypesDescription'
                                            multiline={true}
                                            rows={5}
                                        />
                                    </MD.FormControl>
                                    <MD.FormControl
                                        className={this.props.classes.formControlColor}
                                        disabled={this.props.annotationTypes.operationInProgress}
                                    >
                                        <MD.FormLabel htmlFor='newAnnotationTypesColor'>
                                            {this.props.i18n._('Color')}
                                        </MD.FormLabel>
                                        <div className={this.props.classes.sketchPicker}>
                                            <div
                                                className={this.props.classes.sketchPickerColorBlock}
                                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                onClick={this.OnColorClick.bind(this) as any}
                                            >
                                                <div
                                                    className={this.props.classes.sketchPickerColorBlockInside}
                                                    style={{
                                                        background: this.state.editingAnnotationType.Color
                                                            ? this.state.editingAnnotationType.Color
                                                            : this.state.color,
                                                    }}
                                                />
                                            </div>
                                            {this.state.colorShow ? (
                                                <div className={this.props.classes.sketchPickerPopOver}>
                                                    <div
                                                        className={this.props.classes.sketchPickerCover}
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        onClick={this.OnColorClick.bind(this) as any}
                                                    />
                                                    <SketchPicker
                                                        color={
                                                            this.state.editingAnnotationType.Color
                                                                ? this.state.editingAnnotationType.Color
                                                                : this.state.color
                                                        }
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        onChange={this.OnColorChange.bind(this) as any}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </MD.FormControl>
                                    <MD.FormControl
                                        className={this.props.classes.formControlColor}
                                        disabled={this.props.annotationTypes.operationInProgress}
                                    >
                                        <MD.FormControlLabel
                                            control={
                                                <MD.Checkbox
                                                    checked={
                                                        this.state.editingAnnotationType &&
                                                        this.state.editingAnnotationType.Group === 0
                                                    }
                                                    id='isPrivate'
                                                    onChange={(
                                                        ev: React.ChangeEvent<HTMLInputElement>,
                                                        checked: boolean,
                                                    ) => {
                                                        const editingAnnotationType = {
                                                            ...this.state.editingAnnotationType,
                                                        };

                                                        (editingAnnotationType.Group = checked
                                                            ? 0
                                                            : this.props.user.user.group),
                                                            this.setState({ editingAnnotationType });
                                                    }}
                                                    value='1'
                                                />
                                            }
                                            label={this.props.i18n._('Do not share type with the members of my group')}
                                        />
                                    </MD.FormControl>
                                </MD.FormControl>
                            ) : (
                                <div className={this.props.classes.fieldsContainer}>
                                    <MD.Typography variant='h6'>
                                        {this.props.i18n._('List of annotation fields')}
                                    </MD.Typography>
                                    <div className={this.props.classes.fieldsAddButtonContainer}>
                                        <MD.Button
                                            className={this.props.classes.fieldsAddButton}
                                            color='secondary'
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                            onClick={this.onAddFieldClick.bind(this) as any}
                                        >
                                            <FontAwesomeIcon icon={FA.faPlus} />
                                            {this.props.i18n._('Add field')}
                                        </MD.Button>
                                    </div>
                                    <div className={this.props.classes.fieldsList}>{this.generateFieldList()}</div>
                                    <MD.Dialog
                                        aria-labelledby='form-dialog-add-field'
                                        className={this.props.classes.fieldsForm}
                                        open={this.state.showFieldForm}
                                        onClose={this.onFieldFormClose.bind(this)}
                                    >
                                        <MD.DialogTitle id='form-dialog-add-field-title'>
                                            {this.state.editingField.Order === 0
                                                ? this.props.i18n._('Add a field')
                                                : this.props.i18n._('Edit field')}
                                        </MD.DialogTitle>
                                        <MD.DialogContent>
                                            <MD.FormControl
                                                className={this.props.classes.fieldsFormControl}
                                                error={this.state.editingFieldErrors.type.length > 0}
                                            >
                                                <MD.InputLabel htmlFor='fieldType'>Type</MD.InputLabel>
                                                <MD.Select
                                                    onChange={this.onFieldTypeChange.bind(this)}
                                                    inputProps={{
                                                        id: 'fieldType',
                                                        name: 'fieldType',
                                                    }}
                                                    value={this.state.editingField.Type}
                                                >
                                                    <MD.MenuItem value=''>
                                                        <em>{this.props.i18n._('Choose a type')}</em>
                                                    </MD.MenuItem>
                                                    <MD.MenuItem value='shortText'>
                                                        {this.props.i18n._('Short text')}
                                                    </MD.MenuItem>
                                                    <MD.MenuItem value='longText'>
                                                        {this.props.i18n._('Long text')}
                                                    </MD.MenuItem>
                                                    <MD.MenuItem value='number'>
                                                        {this.props.i18n._('Number')}
                                                    </MD.MenuItem>
                                                    <MD.MenuItem value='boolean'>
                                                        {this.props.i18n._('Boolean')}
                                                    </MD.MenuItem>
                                                    <MD.MenuItem value='date'>{this.props.i18n._('Date')}</MD.MenuItem>
                                                    <MD.MenuItem value='list'>{this.props.i18n._('List')}</MD.MenuItem>
                                                </MD.Select>
                                                {this.state.editingFieldErrors.type.length > 0 ? (
                                                    <MD.FormHelperText>
                                                        {this.state.editingFieldErrors.type}
                                                    </MD.FormHelperText>
                                                ) : (
                                                    ''
                                                )}
                                            </MD.FormControl>
                                            <MD.FormControl
                                                className={this.props.classes.fieldsFormControl}
                                                error={this.state.editingFieldErrors.label.length > 0}
                                                style={{
                                                    display: this.state.editingField.Type === '' ? 'none' : 'block',
                                                }}
                                            >
                                                <MD.InputLabel htmlFor='fieldLabel'>
                                                    {this.props.i18n._('Label')}
                                                </MD.InputLabel>
                                                <MD.Input
                                                    fullWidth={true}
                                                    id='fieldLabel'
                                                    defaultValue={this.state.editingField.Name}
                                                />
                                                {this.state.editingFieldErrors.label.length > 0 ? (
                                                    <MD.FormHelperText>
                                                        {this.state.editingFieldErrors.label}
                                                    </MD.FormHelperText>
                                                ) : (
                                                    ''
                                                )}
                                            </MD.FormControl>
                                            <MD.FormControl
                                                className={this.props.classes.fieldsFormControl}
                                                style={{
                                                    display: this.state.editingField.Type === '' ? 'none' : 'block',
                                                }}
                                            >
                                                <MD.InputLabel htmlFor='fieldDescription'>
                                                    {this.props.i18n._('Description')}
                                                </MD.InputLabel>
                                                <MD.Input
                                                    defaultValue={this.state.editingField.Description}
                                                    fullWidth={true}
                                                    id='fieldDescription'
                                                    multiline={true}
                                                    rows={4}
                                                />
                                            </MD.FormControl>
                                            <MD.FormControl
                                                className={this.props.classes.fieldsFormControl}
                                                style={{
                                                    display: this.state.editingField.Type === '' ? 'none' : 'block',
                                                }}
                                            >
                                                <MD.FormControlLabel
                                                    control={
                                                        <MD.Checkbox
                                                            defaultChecked={this.state.editingField.Mandatory}
                                                            id='fieldMandatory'
                                                            value='1'
                                                        />
                                                    }
                                                    label={this.props.i18n._('Mandatory')}
                                                />
                                            </MD.FormControl>
                                            <MD.FormControl
                                                className={this.props.classes.fieldsFormControl}
                                                style={{
                                                    display: this.state.editingField.Type === 'date' ? 'block' : 'none',
                                                }}
                                            >
                                                <MD.FormControlLabel
                                                    control={
                                                        <MD.Checkbox
                                                            defaultChecked={
                                                                this.state.editingFieldData.get('withTime') === '1'
                                                            }
                                                            id='fieldDateWithTime'
                                                            value='1'
                                                        />
                                                    }
                                                    label={this.props.i18n._('With time')}
                                                />
                                            </MD.FormControl>
                                            <MD.FormControl
                                                className={this.props.classes.fieldsFormControl}
                                                error={this.state.editingFieldErrors.option.length > 0}
                                                style={{
                                                    display:
                                                        this.state.editingField.Type === 'number' ? 'block' : 'none',
                                                }}
                                            >
                                                <MD.InputLabel htmlFor='fieldNumberMin'>
                                                    {this.props.i18n._('Minimum value')}
                                                </MD.InputLabel>
                                                <MD.Input
                                                    defaultValue={this.state.editingFieldData.get('min')}
                                                    id='fieldNumberMin'
                                                />
                                                {this.state.editingFieldErrors.option.length > 0 ? (
                                                    <MD.FormHelperText>
                                                        {this.state.editingFieldErrors.option}
                                                    </MD.FormHelperText>
                                                ) : (
                                                    ''
                                                )}
                                            </MD.FormControl>
                                            <MD.FormControl
                                                className={this.props.classes.fieldsFormControl}
                                                error={this.state.editingFieldErrors.option.length > 0}
                                                style={{
                                                    display:
                                                        this.state.editingField.Type === 'number' ? 'block' : 'none',
                                                }}
                                            >
                                                <MD.InputLabel htmlFor='fieldNumberMax'>
                                                    {this.props.i18n._('Maximum value')}
                                                </MD.InputLabel>
                                                <MD.Input
                                                    defaultValue={this.state.editingFieldData.get('max')}
                                                    id='fieldNumberMax'
                                                />
                                                {this.state.editingFieldErrors.option.length > 0 ? (
                                                    <MD.FormHelperText>
                                                        {this.state.editingFieldErrors.option}
                                                    </MD.FormHelperText>
                                                ) : (
                                                    ''
                                                )}
                                            </MD.FormControl>
                                            <MD.FormControl
                                                className={this.props.classes.fieldsFormControl}
                                                style={{
                                                    display: this.state.editingField.Type === 'list' ? 'block' : 'none',
                                                }}
                                            >
                                                <MD.FormControlLabel
                                                    control={
                                                        <MD.Checkbox
                                                            defaultChecked={
                                                                this.state.editingFieldData.get('multiple') === '1'
                                                            }
                                                            id='fieldListMultiple'
                                                            value='1'
                                                        />
                                                    }
                                                    label={this.props.i18n._('Allow multiple values')}
                                                />
                                            </MD.FormControl>
                                            <MD.FormControl
                                                className={this.props.classes.fieldsFormControl}
                                                error={this.state.editingFieldErrors.option.length > 0}
                                                style={{
                                                    display: this.state.editingField.Type === 'list' ? 'block' : 'none',
                                                }}
                                            >
                                                <MD.InputLabel htmlFor='fieldListAdd'>
                                                    {this.props.i18n._('Add a list value')}
                                                </MD.InputLabel>
                                                <MD.Input defaultValue='' id='fieldListAdd' />
                                                <MD.Button
                                                    onClick={this.onListAddElem.bind(this)}
                                                    title={this.props.i18n._('Add value')}
                                                >
                                                    <FontAwesomeIcon icon={FA.faPlusCircle} size='1x' />
                                                </MD.Button>
                                                {this.state.editingFieldErrors.option.length > 0 ? (
                                                    <MD.FormHelperText>
                                                        {this.state.editingFieldErrors.option}
                                                    </MD.FormHelperText>
                                                ) : (
                                                    ''
                                                )}
                                                <MD.List className={this.props.classes.fieldsFormListContainer}>
                                                    {formAddListValues.map((v, k) => {
                                                        return (
                                                            <MD.ListItem
                                                                className={this.props.classes.fieldsFormListItem}
                                                                key={`fieldListAddItem_${k}`}
                                                            >
                                                                <MD.ListItemIcon
                                                                    className={
                                                                        this.props.classes.fieldsFormListItemIcon
                                                                    }
                                                                    onClick={this.onDeleteListItem.bind(this, k)}
                                                                >
                                                                    <FontAwesomeIcon icon={FA.faTrash} />
                                                                </MD.ListItemIcon>
                                                                <MD.ListItemText primary={v} />
                                                                {k > 0 ? (
                                                                    <MD.ListItemIcon
                                                                        className={
                                                                            this.props.classes.fieldsFormListItemIcon
                                                                        }
                                                                        onClick={this.changeListItemOrder.bind(
                                                                            this,
                                                                            k,
                                                                            -1,
                                                                        )}
                                                                    >
                                                                        <FontAwesomeIcon icon={FA.faArrowUp} />
                                                                    </MD.ListItemIcon>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {k < formAddListValues.length - 1 ? (
                                                                    <MD.ListItemIcon
                                                                        className={
                                                                            this.props.classes.fieldsFormListItemIcon
                                                                        }
                                                                        onClick={this.changeListItemOrder.bind(
                                                                            this,
                                                                            k,
                                                                            1,
                                                                        )}
                                                                    >
                                                                        <FontAwesomeIcon icon={FA.faArrowDown} />
                                                                    </MD.ListItemIcon>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </MD.ListItem>
                                                        );
                                                    })}
                                                </MD.List>
                                            </MD.FormControl>
                                        </MD.DialogContent>
                                        <MD.DialogActions>
                                            <MD.Button color='primary' onClick={this.onFieldCancel.bind(this)}>
                                                {this.props.i18n._('Cancel')}
                                            </MD.Button>
                                            <MD.Button color='secondary' onClick={this.onFieldSubmit.bind(this)}>
                                                {this.state.editingField.Order === 0
                                                    ? this.props.i18n._('Add')
                                                    : this.props.i18n._('Edit')}
                                            </MD.Button>
                                        </MD.DialogActions>
                                    </MD.Dialog>
                                </div>
                            )}
                            {this.state.formtab === 1 ? (
                                <MD.FormControl
                                    className={this.props.classes.formControl}
                                    error={this.props.annotationTypes.formErrors.general.length > 0}
                                    disabled={this.props.annotationTypes.operationInProgress}
                                >
                                    {this.props.annotationTypes.operationInProgress ? (
                                        <MD.Button
                                            className={this.props.classes.createButton}
                                            color='primary'
                                            variant='contained'
                                        >
                                            <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                                            {this.props.i18n._('Please wait ...')}
                                        </MD.Button>
                                    ) : !this.state.editingAnnotationType.ID ? (
                                        <MD.Button
                                            className={this.props.classes.createButton}
                                            color='primary'
                                            onClick={this.onCreate.bind(this)}
                                            variant='contained'
                                        >
                                            <FontAwesomeIcon icon={FA.faPlus} />
                                            {this.props.i18n._('Create type')}
                                        </MD.Button>
                                    ) : (
                                        <div>
                                            <MD.Button
                                                className={this.props.classes.createButton}
                                                color='primary'
                                                onClick={this.onEdit.bind(this)}
                                                variant='contained'
                                            >
                                                <FontAwesomeIcon icon={FA.faEdit} />
                                                {this.props.i18n._('Edit type')}
                                            </MD.Button>
                                            <MD.Button
                                                className={this.props.classes.createButton}
                                                color='primary'
                                                onClick={this.onDelete.bind(this)}
                                                variant='contained'
                                            >
                                                <FontAwesomeIcon icon={FA.faTrash} />
                                                {this.props.i18n._('Delete type')}
                                            </MD.Button>
                                        </div>
                                    )}
                                    {this.props.annotationTypes.formErrors.general.length > 0 ? (
                                        <MD.FormHelperText error={true}>
                                            {this.props.annotationTypes.formErrors.general}
                                        </MD.FormHelperText>
                                    ) : (
                                        ''
                                    )}
                                    <MD.Typography
                                        component='a'
                                        onClick={this.onCancelForm.bind(this)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {this.props.i18n._('Cancel')}
                                    </MD.Typography>
                                </MD.FormControl>
                            ) : (
                                ''
                            )}
                        </form>
                    </MD.Paper>
                </MD.Grid>
            </MD.Grid>
        );
    }

    private renderAnnotationType(index: number, key: number | string): JSX.Element {
        if (isNullOrUndefined(this.props.annotationTypes.annotationTypesOwned[index])) {
            const nearest = index - (index % 1000);

            if (this.alreadyAskedOffsets.indexOf(nearest) === -1) {
                this.props.getOwned(nearest, 1000);
                this.alreadyAskedOffsets.push(nearest);
            }
            return (
                <MD.ListItem className={this.props.classes.listListItem} key={key}>
                    <FontAwesomeIcon icon={FA.faSpinner} spin={true} color={theme.palette.text.primary} />
                </MD.ListItem>
            );
        }

        const type = this.props.annotationTypes.annotationTypesOwned[index] as Proto.mediaarchiver.AnnotationType;

        return (
            <MD.ListItem
                className={this.props.classes.listListItem}
                key={key}
                onClick={this.onEditType.bind(this, type)}
            >
                <div className={this.props.classes.sketchPickerColorBlock}>
                    <div
                        className={this.props.classes.sketchPickerColorBlockInside}
                        style={{ background: type.Color }}
                    />
                </div>
                <MD.ListItemText primary={type.Name} secondary={type.Description} />
            </MD.ListItem>
        );
    }

    private OnColorClick(ev: MouseEvent): void {
        this.setState({ colorShow: !this.state.colorShow });
    }

    private OnColorChange(res: Color) {
        if (isNullOrUndefined(res) || isNullOrUndefinedOrEmptyString((res as HEXColor).hex)) {
            return;
        }
        const rgb = res as RGBColor;
        let color = (res as HEXColor).hex;

        if (
            color !== 'transparent' &&
            !isNullOrUndefined(rgb.source) &&
            !isNullOrUndefined(rgb.a) &&
            rgb.a >= 0 &&
            rgb.a < 1
        ) {
            color += Math.round(rgb.a * 255).toString(16);
        }
        this.setState({ color });
        this.state.editingAnnotationType.Color = color;
    }

    private onEditType(type: Proto.mediaarchiver.AnnotationType) {
        this.setState({ editingAnnotationType: type });
        if (this.form === null) {
            return;
        }
        this.state.editingAnnotationType = type;
        this.setState({ editingAnnotationType: type });
        if (this.state.formtab === 1) {
            (document.getElementById('newAnnotationTypesName') as HTMLInputElement).value = type.Name;
            (document.getElementById('newAnnotationTypesDescription') as HTMLInputElement).value = type.Description;
            const event = new FocusEvent('focus');
            (document.getElementById('newAnnotationTypesName') as HTMLInputElement).dispatchEvent(event);
            (document.getElementById('newAnnotationTypesDescription') as HTMLInputElement).dispatchEvent(event);
        }
        this.setState({ formtab: 1 });
    }

    private onCancelForm() {
        if (this.form !== null) {
            this.form.reset();
        }
        const newColor =
            '#' +
            '0123456789abcdef'
                .split('')
                .map((v, i, a) => {
                    return i > 5 ? null : a[Math.floor(Math.random() * 16)];
                })
                .join('');

        this.props.setErrors({
            color: '',
            general: '',
            name: '',
        });
        this.state.editingFieldErrors = {
            label: '',
            option: '',
            type: '',
        };
        this.state.editingAnnotationType = new Proto.mediaarchiver.AnnotationType();
        this.state.editingAnnotationType.Group = this.props.user.user.group;
        this.state.editingAnnotationType.Fields = [];
        this.state.editingAnnotationType.Color = newColor;
        this.setState({
            editingAnnotationType: this.state.editingAnnotationType,
        });
        this.state.editingField = new Proto.mediaarchiver.AnnotationTypeField();
        this.state.editingFieldData = new Map();
        if (this.state.formtab === 1) {
            (document.getElementById('newAnnotationTypesName') as HTMLInputElement).value = '';
            (document.getElementById('newAnnotationTypesDescription') as HTMLInputElement).value = '';
        }
        this.setState({ color: newColor });
        this.setState({ color: newColor });
        this.forceUpdate();
    }

    private onCreate() {
        if (!this.checkForm()) {
            return;
        }
        this.props.create(this.state.editingAnnotationType);
    }

    private onEdit() {
        if (!this.checkForm()) {
            return;
        }
        this.props.edit(this.state.editingAnnotationType);
    }

    private onDelete() {
        if (this.state.editingAnnotationType.ID === '') {
            return;
        }
        if (window.confirm(this.props.i18n._('Do you want to delete this annotation type ?'))) {
            this.props.delete(this.state.editingAnnotationType);
        }
    }

    private checkForm(): boolean {
        // Following messages may come from backend.
        const useless1 = this.props.i18n._('You already have an annotation type with this name');
        const useless2 = this.props.i18n._('An error occured while creating type, please try again later');
        const useless3 = this.props.i18n._("Annotation's name first name should be less than 256 characters");
        const useless4 = this.props.i18n._('Color is invalid');

        if (this.state.formtab === 1) {
            this.state.editingAnnotationType.Name = (
                document.getElementById('newAnnotationTypesName') as HTMLInputElement
            ).value;
            this.state.editingAnnotationType.Description = (
                document.getElementById('newAnnotationTypesDescription') as HTMLInputElement
            ).value;
            this.state.editingAnnotationType.Color = this.state.color;
        }
        let ok = true;
        const errors: IAnnotationTypesErrors = {
            color: '',
            general: '',
            name: '',
        };
        if (this.state.editingAnnotationType.Name.length === 0) {
            ok = false;
            errors.name = this.props.i18n._("Please set the annotation's name");
        }
        if (this.state.editingAnnotationType.Fields.length === 0) {
            ok = false;
            errors.general = this.props.i18n._('You must set at least one annotion type field');
        }
        this.props.setErrors(errors);
        return ok;
    }

    private generateFieldList(): React.ReactNode {
        const items = this.state.editingAnnotationType.Fields.map((field, i, list) => {
            let title = '';

            if (field.Mandatory) {
                title += `[${this.props.i18n._('Mandatory')}] `;
            }
            switch (field.Type) {
                case 'shortText':
                    title += `[${this.props.i18n._('Short text')}] `;
                    break;
                case 'longText':
                    title += `[${this.props.i18n._('Long text')}] `;
                    break;
                case 'number':
                    title += `[${this.props.i18n._('Number')}] `;
                    break;
                case 'boolean':
                    title += `[${this.props.i18n._('Boolean')}] `;
                    break;
                case 'date':
                    title += `[${this.props.i18n._('Date')}] `;
                    break;
                case 'list':
                    title += `[${this.props.i18n._('List')}] `;
                    break;
                default:
                    title += `[${this.props.i18n._('Unknown')}] `;
                    break;
            }
            title += field.Name;
            return (
                <MD.ListItem className={this.props.classes.fieldsListItem} key={`fieldNumber_${i}`}>
                    <MD.ListItemIcon
                        className={this.props.classes.fieldsListItemIcon}
                        onClick={this.onEditField.bind(this, i)}
                    >
                        <FontAwesomeIcon icon={FA.faEdit} />
                    </MD.ListItemIcon>
                    <MD.ListItemIcon
                        className={this.props.classes.fieldsListItemIcon}
                        onClick={this.onDeleteField.bind(this, i)}
                    >
                        <FontAwesomeIcon icon={FA.faTrash} />
                    </MD.ListItemIcon>
                    <MD.ListItemText primary={title} secondary={field.Description} />
                    {i > 0 ? (
                        <MD.ListItemIcon
                            className={this.props.classes.fieldsListItemIcon}
                            onClick={this.changeFieldOrder.bind(this, i, -1)}
                        >
                            <FontAwesomeIcon icon={FA.faArrowUp} />
                        </MD.ListItemIcon>
                    ) : (
                        ''
                    )}
                    {i < list.length - 1 ? (
                        <MD.ListItemIcon
                            className={this.props.classes.fieldsListItemIcon}
                            onClick={this.changeFieldOrder.bind(this, i, 1)}
                        >
                            <FontAwesomeIcon icon={FA.faArrowDown} />
                        </MD.ListItemIcon>
                    ) : (
                        ''
                    )}
                </MD.ListItem>
            );
        });

        return <MD.List component='nav'>{items}</MD.List>;
    }

    private changeFieldOrder(i: number, op: -1 | 1) {
        if (i < 0 || i > this.state.editingAnnotationType.Fields.length) {
            return;
        }
        if (i === this.state.editingAnnotationType.Fields.length && op === 1) {
            return;
        }
        if (i === 0 && op === -1) {
            return;
        }
        if (isNullOrUndefined(this.state.editingAnnotationType.Fields[i])) {
            return;
        }
        if (isNullOrUndefined(this.state.editingAnnotationType.Fields[i + op])) {
            return;
        }
        const swapFrom = this.state.editingAnnotationType.Fields[i];
        const swapTo = this.state.editingAnnotationType.Fields[i + op];
        swapTo.Order = swapFrom.Order;
        swapFrom.Order = (swapFrom.Order as number) + op;
        this.state.editingAnnotationType.Fields[i + op] = swapFrom;
        this.state.editingAnnotationType.Fields[i] = swapTo;
        this.forceUpdate();
    }

    private onDeleteField(i: number) {
        const newList: Proto.mediaarchiver.IAnnotationTypeField[] = [];

        if (window.confirm(this.props.i18n._('Do you want to delete this field ?'))) {
            for (let j = 0; j < this.state.editingAnnotationType.Fields.length; j++) {
                if (j === i) {
                    continue;
                }
                const item = this.state.editingAnnotationType.Fields[j];
                item.Order = newList.length + 1;
                newList.push(item);
            }
            this.state.editingAnnotationType.Fields = newList;
            this.forceUpdate();
        }
    }

    private onEditField(i: number) {
        const item = this.state.editingAnnotationType.Fields[i];
        const data = new Map<string, string>();

        if (isNullOrUndefined(item)) {
            return;
        }
        if (!isNullOrUndefinedOrEmptyString(item.Data)) {
            const datas = JSON.parse(item.Data);

            Object.keys(datas).map((key) => {
                data.set(key, datas[key].toString());
            });
        }
        this.setState({
            editingField: item as Proto.mediaarchiver.AnnotationTypeField,
            editingFieldData: data,
            editingFieldErrors: {
                label: '',
                option: '',
                type: '',
            },
            showFieldForm: true,
        });
    }

    private onAddFieldClick(ev: React.MouseEvent) {
        this.setState({ showFieldForm: true });
    }

    private onFieldTypeChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        const field = this.state.editingField;

        field.Type = ev.target.value as string;
        this.setState({ editingField: field });
        this.forceUpdate();
    }

    private onFieldCancel(ev: React.MouseEvent) {
        this.setState({
            editingField: new Proto.mediaarchiver.AnnotationTypeField(),
            editingFieldData: new Map(),
            editingFieldErrors: {
                label: '',
                option: '',
                type: '',
            },
            showFieldForm: false,
        });
    }

    private onFieldSubmit(ev: React.MouseEvent) {
        const label = (document.getElementById('fieldLabel') as HTMLInputElement).value;
        const description = (document.getElementById('fieldDescription') as HTMLInputElement).value;
        const mandatory = (document.getElementById('fieldMandatory') as HTMLInputElement).checked;

        let list = [];
        if (this.state.editingFieldData.has('values')) {
            try {
                list = JSON.parse(this.state.editingFieldData.get('values') || '');
            } catch (err) {
                /* */
            }
        }

        if (this.state.editingField.Type === '') {
            this.setState({
                editingFieldErrors: {
                    label: '',
                    option: '',
                    type: this.props.i18n._('Please choose a field type'),
                },
            });
            return;
        }
        if (label === '') {
            this.setState({
                editingFieldErrors: {
                    label: this.props.i18n._('Please set the field label'),
                    option: '',
                    type: '',
                },
            });
            return;
        }

        if (this.state.editingField.Type === 'number') {
            const min = parseInt((document.getElementById('fieldNumberMin') as HTMLInputElement).value, 10);
            const max = parseInt((document.getElementById('fieldNumberMax') as HTMLInputElement).value, 10);

            if (isNaN(min) || isNaN(max)) {
                this.setState({
                    editingFieldErrors: {
                        label: '',
                        option: this.props.i18n._('Please type numeric values for minimum and maximum'),
                        type: '',
                    },
                });
                return;
            }
            if (min >= max) {
                this.setState({
                    editingFieldErrors: {
                        label: '',
                        option: this.props.i18n._('Maximum must be higher than minimum'),
                        type: '',
                    },
                });
                return;
            }
            this.state.editingField.Data = JSON.stringify({ min, max });
        }
        if (this.state.editingField.Type === 'date') {
            const withTime = (document.getElementById('fieldDateWithTime') as HTMLInputElement).checked;

            this.state.editingField.Data = JSON.stringify({
                withTime: withTime ? '1' : '0',
            });
        }
        if (this.state.editingField.Type === 'list') {
            if (list.length < 1) {
                this.setState({
                    editingFieldErrors: {
                        label: '',
                        option: this.props.i18n._('You must set at least on element in list'),
                        type: '',
                    },
                });
                return;
            }
            const multiple = (document.getElementById('fieldListMultiple') as HTMLInputElement).checked;

            this.state.editingField.Data = JSON.stringify({
                multiple: multiple ? '1' : '0',
                values: JSON.stringify(list),
            });
        }

        this.setState({
            editingFieldErrors: {
                label: '',
                option: '',
                type: '',
            },
        });
        this.state.editingField.Name = label;
        this.state.editingField.Description = description;
        this.state.editingField.Mandatory = mandatory;
        if (this.state.editingField.Order === 0) {
            this.state.editingField.Order = this.state.editingAnnotationType.Fields.length + 1;
            this.state.editingAnnotationType.Fields.push(this.state.editingField);
        } else {
            this.forceUpdate();
        }
        this.setState({
            editingField: new Proto.mediaarchiver.AnnotationTypeField(),
            showFieldForm: false,
        });
    }

    private onTabChange(ev?: React.ChangeEvent<{}>) {
        if (this.state.formtab === 1) {
            this.state.editingAnnotationType.Name = (
                document.getElementById('newAnnotationTypesName') as HTMLInputElement
            ).value;
            this.state.editingAnnotationType.Description = (
                document.getElementById('newAnnotationTypesDescription') as HTMLInputElement
            ).value;
            this.state.editingAnnotationType.Color = this.state.color;
        }
        this.setState({ formtab: this.state.formtab === 1 ? 2 : 1 });
    }

    private onListAddElem() {
        const value = (document.getElementById('fieldListAdd') as HTMLInputElement).value;

        if (!value) {
            return;
        }
        let list = [];
        if (this.state.editingFieldData.has('values')) {
            try {
                list = JSON.parse(this.state.editingFieldData.get('values') || '');
            } catch (err) {
                /* */
            }
        }
        // @ts-ignore
        list.push(value);
        this.state.editingFieldData.set('values', JSON.stringify(list));
        this.setState({
            editingFieldData: new Map(this.state.editingFieldData),
        });
        (document.getElementById('fieldListAdd') as HTMLInputElement).value = '';
        (document.getElementById('fieldListAdd') as HTMLInputElement).dispatchEvent(new FocusEvent('blur'));
    }

    private onDeleteListItem(i: number) {
        let list = [];
        if (this.state.editingFieldData.has('values')) {
            try {
                list = JSON.parse(this.state.editingFieldData.get('values') || '');
            } catch (err) {
                /* */
            }
        }
        if (i < list.length) {
            // @ts-ignore
            list = (list as string[]).filter((_, k) => i !== k);
            this.state.editingFieldData.set('values', JSON.stringify(list));
            this.setState({
                editingFieldData: new Map(this.state.editingFieldData),
            });
        }
    }

    private changeListItemOrder(i: number, op: number) {
        let list = [];
        if (this.state.editingFieldData.has('values')) {
            try {
                list = JSON.parse(this.state.editingFieldData.get('values') || '');
            } catch (err) {
                /* */
            }
        }

        if (i < 0 || i > list.length - 1) {
            return;
        }
        if (i === list.length - 1 && op === 1) {
            return;
        }
        if (i === 0 && op === -1) {
            return;
        }
        if (isNullOrUndefined(list[i])) {
            return;
        }
        if (isNullOrUndefined(list[i + op])) {
            return;
        }
        const swapFrom = list[i];
        const swapTo = list[i + op];
        list[i + op] = swapFrom;
        list[i] = swapTo;
        this.state.editingFieldData.set('values', JSON.stringify(list));
        this.setState({
            editingFieldData: new Map(this.state.editingFieldData),
        });
    }

    private onFieldFormClose() {
        this.setState({
            editingField: new Proto.mediaarchiver.AnnotationTypeField(),
            editingFieldData: new Map(),
            editingFieldErrors: {
                label: '',
                option: '',
                type: '',
            },
            showFieldForm: false,
        });
    }
}
/* eslint-enable */
