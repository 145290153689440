import { mediaarchiver } from '../../Protos/protos';

export enum ContactsActionTypes {
    CREATE = '@@contacts/CREATE',
    DELETE = '@@contacts/DELETE',
    DELETED_CONTACT = '@@contacts/DELETED_CONTACT',
    EDIT = '@@contacts/EDIT',
    GET = '@@contacts/GET',
    GET_COUNT = '@@contacts/GET_COUNT',
    OP_SUCCESS = '@@contacts/OP_SUCCESS',
    SET_CONTACT = '@@contacts/SET_CONTACT',
    SET_CONTACTS = '@@contacts/SET_CONTACTS',
    SET_CONTACTS_COUNT = '@@contacts/SET_CONTACTS_COUNT',
    SET_ERRORS = '@@contacts/SET_ERRORS',
    SET_OP_PROGRESS = '@@contacts/SET_OP_PROGRESS',
}

export interface IContactFormErrors {
    email: string;
    firstName: string;
    general: string;
    lastName: string;
}

export type NullableContact = mediaarchiver.IContact | null;
export type NullableContactList = NullableContact[];

export interface IContactsState {
    readonly contacts: NullableContactList;
    readonly opSuccessEventElement: HTMLElement;
    readonly operationInProgress: boolean;
    readonly total: number;
}

export interface ICreateAction {
    payload: mediaarchiver.IContact;
    type: ContactsActionTypes.CREATE;
}

export interface IDeleteContactAction {
    payload: string;
    type: ContactsActionTypes.DELETE;
}

export interface IDeletedContactAction {
    payload: string;
    type: ContactsActionTypes.DELETED_CONTACT;
}

export interface IEditAction {
    payload: mediaarchiver.IContact;
    type: ContactsActionTypes.EDIT;
}

export interface IGetActionPayload {
    limit: number;
    offset: number;
}

export interface IGetAction {
    payload: IGetActionPayload;
    type: ContactsActionTypes.GET;
}

export interface IGetCountAction {
    type: ContactsActionTypes.GET_COUNT;
}

export interface ISetErrorsAction {
    payload: IContactFormErrors;
    type: ContactsActionTypes.SET_ERRORS;
}

export interface ISetOpSuccessAction {
    type: ContactsActionTypes.OP_SUCCESS;
}

export interface ISetProgressAction {
    payload: boolean;
    type: ContactsActionTypes.SET_OP_PROGRESS;
}
