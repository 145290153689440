import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefined } from '../../Utils/Various';

export class TalkPerson extends AMessage implements IFromCommand {
    private parsedPerson: Proto.mediaarchiver.ITalkPerson | null = null;

    public constructor() {
        super('talkPerson');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.TalkPersonArg)) {
                return reject(new Error('Not a TalkPerson message'));
            }
            this.parsedPerson = msg.TalkPersonArg;
            resolve();
        });
    }

    public get person(): Proto.mediaarchiver.ITalkPerson {
        if (isNullOrUndefined(this.parsedPerson)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedPerson;
    }
}
