import { mediaarchiver } from '../../Protos/protos';
import { AMessage, IToCommand } from './';

export class CreateReview extends AMessage implements IToCommand {
    private readonly specs: mediaarchiver.IReview;

    public constructor(specs: mediaarchiver.IReview) {
        super('createReview');
        this.specs = specs;
    }

    public Send(): Promise<void> {
        return this.send(new mediaarchiver.WSMessage({
            ReviewArg : this.specs,
            Type: mediaarchiver.WSMessageType.MessageTypeCreateReview,
        }));
    }
}
