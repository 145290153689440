import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { I18N } from '../../../Store/I18n/Types';
import { IPlayerState } from '../../../Store/Player/Types';
import { showAggregatedDialog } from '../../../Store/Talk/Actions';
import { IEPGProgramState, IEPGState, ITimelineState } from '../../../Store/Timeline/Types';
import { setBroadcasterPopover } from '../../../Store/TimelinePopovers/Actions';
import { IUserState } from '../../../Store/User/Types';
import { getReadjustedCategoryColor, getTheme } from '../../../Themes';
import { invertColor } from '../../../Utils/Color';
import { getDateInTz } from '../../../Utils/Time';
import { isNull, isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../Utils/Various';

interface IState {
    contextualMenuProgram: IEPGProgramState | null;
}

interface IPropsFromState {
    i18n: I18N;
    player: IPlayerState;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    setBroadcasterPopover: typeof setBroadcasterPopover;
    showAggregatedDialog: typeof showAggregatedDialog;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

const theme = getTheme();

const styles = MD.createStyles({
    contextualMenu: {
        backgroundColor: 'transparent',
        height: '100vh',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100vw',
        zIndex: 9999,
    },
    contextualMenuList: {
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        width: 230,
    },
    emptyContainer: {
        '&>div': {
            '&>p': {
                color: theme.palette.text.disabled,
            },
            paddingLeft: theme.spacing(1),
            position: 'fixed',
        },
        height: '100%',
    },
    line1: {
        height: '15px',
        position: 'relative',
        width: '100%',
    },
    line2: {
        height: '15px',
        position: 'relative',
        top: -1,
        width: '100%',
    },
    program: {
        alignItems: 'center',
        background: 'red',
        border: '1px solid black',
        borderRight: '0px solid',
        borderTop: '0px solid',
        color: 'black',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '11px',
        height: '14px',
        justifyContent: 'left',
        lineHeight: '11px',
        marginTop: '3px',
        overflow: 'hidden',
        paddingLeft: '3px',
        position: 'absolute',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        wordBreak: 'break-all',
    },
    programSecondLine: {},
    root: {
        alignItems: 'flex-end',
        display: 'inline-flex',
        flexDirection: 'column',
        height: '34px',
        
        width: '100%',
    },
});

export class BroadcasterComponent extends React.Component<AllProps, IState> {
    private contextualMenu: HTMLDivElement = document.createElement('div');
    private container: HTMLElement | null = null;
    private popover: HTMLElement | null = null;

    public constructor(props: AllProps) {
        super(props);
        this.state = {
            contextualMenuProgram: null,
        };
    }

    public render(): React.ReactNode {
        if (!this.props.timeline.ready) {
            return (
                <div className={this.props.classes.emptyContainer}>
                    <div>
                        <MD.Typography>
                            {this.props.i18n._('Data is loading')}
                            &nbsp; &nbsp;
                            <FontAwesomeIcon icon={FA.faSpinner} spin={true} />
                        </MD.Typography>
                    </div>
                </div>
            );
        }
        if (
            Object.keys(this.props.timeline.data.broadcasterEPG).length === 0 &&
            Object.keys(this.props.timeline.data.broadcasterEPGDetails).length === 0
        ) {
            return (
                <div className={this.props.classes.emptyContainer}>
                    <div>
                        <MD.Typography>
                            {this.props.i18n._('We dont have any data for this time range, please try again later')}
                        </MD.Typography>
                    </div>
                </div>
            );
        }

        const startTS = this.props.timeline.criterias.start.getTime();
        const endTS = this.props.timeline.criterias.end.getTime();
        const tlDuration = endTS - startTS;

        this.popover = null;

        return (
            <div className={this.props.classes.root} id='broadcasterContainer'>
                <div className={this.props.classes.line1}>
                    {this.renderLine(this.props.timeline.data.broadcasterEPG, 1, tlDuration, false)}
                </div>
                <div className={this.props.classes.line2}>
                    {this.renderLine(this.props.timeline.data.broadcasterEPGDetails, 2, tlDuration, true)}
                </div>
                <div
                    className={this.props.classes.contextualMenu}
                    onClick={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        this.setState({ contextualMenuProgram: null });
                        return false;
                    }}
                    onMouseDown={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                    }}
                    ref={(c: HTMLDivElement) => {
                        this.contextualMenu = c;
                    }}
                    style={{
                        display: isNull(this.state.contextualMenuProgram) ? 'none' : 'block',
                    }}
                >
                    <MD.List className={this.props.classes.contextualMenuList} dense>
                        <MD.ListItem button onClick={this.onAddTalk.bind(this)}>
                            {this.props.i18n.pgettext('Politic', 'Add an aggregated talk time')}
                        </MD.ListItem>
                    </MD.List>
                </div>
            </div>
        );
    }

    private renderLine(data: IEPGState, line: number, tlDuration: number, secondline: boolean) {
        return Object.keys(data)
            .map((minute: string, i: number) => {
                const program = data[(minute as unknown) as number];
                let start = program.start.getTime();
                let end = start + program.duration;
                const tlStart = getDateInTz(this.props.timeline.criterias.start, this.props.timeline.criterias.timezone).getTime();
                const tlEnd = getDateInTz(this.props.timeline.criterias.end, this.props.timeline.criterias.timezone).getTime();
                let showKind = '';

                if (!isNullOrUndefined(program.metas) && !isNullOrUndefined(program.metas.idShowKind)) {
                    showKind = program.metas.idShowKind;
                }

                if (start >= tlEnd) {
                    return null;
                }

                if (start < tlStart) {
                    start = tlStart;
                }

                if (end >= tlEnd) {
                    end = tlEnd;
                }

                const duration = end - start;
                const widthPercent = Math.ceil((duration / tlDuration) * 1000000) / 10000;
                let title = program.title;

                if (!isNullOrUndefinedOrEmptyString(program.subtitle)) {
                    title = `${title}: ${program.subtitle}`;
                }

                const bgColor = getReadjustedCategoryColor(showKind);
                const textColor = invertColor(bgColor, true);
                const shadowColor = invertColor(textColor, true);

                return (
                    <div
                        className={
                            this.props.classes.program + ' ' + (secondline ? this.props.classes.programSecondLine : '')
                        }
                        data-donottrackscroller={true}
                        key={`broadcaster_${line}_${i}`}
                        onClick={this.handleProgramClick.bind(this, program)}
                        onContextMenu={this.handleContext.bind(this, program)}
                        onMouseEnter={this.handlePopoverOpen.bind(this, program)}
                        onMouseLeave={this.handlePopoverClose.bind(this)}
                        style={{
                            backgroundColor: bgColor,
                            color: textColor,
                            left: `${((start - tlStart) / tlDuration) * 100}%`,
                            textShadow: `${shadowColor} 0.1em 0.1em 0.2em`,
                            width: `${widthPercent}%`,
                            zIndex: 10 + i,
                        }}
                    >
                        {title}
                    </div>
                );
            })
            .filter((program) => program !== null);
    }

    private handleContext(prg: IEPGProgramState, ev: React.MouseEvent) {
        if (
            this.props.user.user.functionalities.indexOf(mediaarchiver.UserFunctionalities.FUNCTIONALITY_POLITICAL) ===
            -1
        ) {
            return true;
        }
        ev.preventDefault();
        ev.stopPropagation();

        const elems = this.contextualMenu.getElementsByTagName('ul');

        if (elems.length === 0) {
            return;
        }

        elems[0].style.left = `${ev.clientX}px`;
        elems[0].style.bottom = `${window.innerHeight - ev.clientY}px`;
        this.setState({ contextualMenuProgram: prg });
    }

    private onAddTalk() {
        const program = this.state.contextualMenuProgram as IEPGProgramState;
        if (isNullOrUndefined(program)) {
            return;
        }
        const talk: mediaarchiver.ITalk = {
            ProgramEnd: program.realStart.getTime() + program.realDuration,
            ProgramName: program.title,
            ProgramStart: program.realStart.getTime(),
        };
        this.props.showAggregatedDialog(talk);
    }

    private handlePopoverClose() {
        if (isNull(this.popover)) {
            this.popover = document.getElementById('broadcasterPopover');
        }
        if (isNull(this.popover)) {
            return;
        }
        this.popover.style.display = 'none';
        this.props.setBroadcasterPopover(null);
    }

    private handlePopoverOpen(prg: IEPGProgramState, ev: React.MouseEvent) {
        if (isNull(this.popover)) {
            this.popover = document.getElementById('broadcasterPopover');
        }
        if (isNull(this.container)) {
            this.container = document.getElementById('broadcasterContainer');
        }
        if (isNullOrUndefined(ev) || isNullOrUndefined(ev.target) || isNull(this.popover) || isNull(this.container)) {
            return;
        }

        this.props.setBroadcasterPopover(prg);

        const prgBox = (ev.target as HTMLElement).getBoundingClientRect();

        this.popover.style.display = 'flex';
        if (
            ev.clientX - theme.mediaarchiver.dimensions.drawerWidth <
            (window.innerWidth - theme.mediaarchiver.dimensions.drawerWidth) / 2
        ) {
            this.popover.style.left = `${ev.clientX}px`;
            this.popover.style.right = 'inherit';
        } else {
            this.popover.style.left = 'inherit';
            this.popover.style.right = `${window.innerWidth - ev.clientX}px`;
        }
        this.popover.style.bottom = `${window.innerHeight - prgBox.top + 3}px`;
    }

    private handleProgramClick(prg: IEPGProgramState, ev: React.MouseEvent<Element>) {
        ev.stopPropagation();
        if (!isNullOrUndefined(this.props.player.player)) {
            this.props.player.player.start(
                prg.start,
                getDateInTz(this.props.timeline.criterias.end, this.props.timeline.criterias.timezone),
                this.props.user.user.options.indexOf(
                    mediaarchiver.UserOptions.USER_OPTION_NO_AUTOPLAY_WHEN_PLAY_CONTENT,
                ) === -1,
            );
        }
    }
}

const mapStateToProps = ({ i18n, player, timeline, user }: IApplicationState) => ({
    i18n: i18n.i18n,
    player,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setBroadcasterPopover: (prg: IEPGProgramState | null) => dispatch(setBroadcasterPopover(prg)),
    showAggregatedDialog: (talk: mediaarchiver.ITalk) => dispatch(showAggregatedDialog(talk)),
});

export const Broadcaster = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(BroadcasterComponent));
