import * as Icons from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { deleteArchive, incCounters, retry } from '../../../Store/Archives/Actions';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import { IMediasState } from '../../../Store/Medias';
import { setTimelineProgram } from '../../../Store/Search/Actions';
import { NullableResult } from '../../../Store/Search/Types';
import { getTheme } from '../../../Themes';
import { Logger } from '../../../Utils/Logger';
import { ensureString } from '../../../Utils/String';
import {
    getGIFFromVideo,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../../Utils/Various';

import missingVignette from '../../../Images/missingVignette.jpg';

interface IOwnProps {
    archive: mediaarchiver.IArchive;
    onEditClick?: (ar: mediaarchiver.IArchive) => void;
    onPlayClick?: (ar: mediaarchiver.IArchive) => void;
    onShareClick?: (ar: mediaarchiver.IArchive) => void;
}

interface IState {
    edit: boolean;
    hover: boolean;
    toolsOpened: boolean;
}

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    medias: IMediasState;
    router: RouteComponentProps<{}>;
}

interface IPropsFromDispatch {
    deleteArchive: typeof deleteArchive;
    incCounters: typeof incCounters;
    retry: typeof retry;
    setTimelineProgram: typeof setTimelineProgram;
}

const theme = getTheme();

const styles = MD.createStyles({
    '@keyframes running': {
        '0%': {
            opacity: 0.75,
        },
        '50%': {
            opacity: 0.3,
        },
        '100%': {
            opacity: 0.75,
        },
    },
    button: {},
    counts: {
        '&>span': {
            '&>svg': {
                marginRight: theme.spacing(1),
            },

            display: 'inline-block',
            marginRight: 20,
        },
    },
    date: {
        color: '#e1f995',
        fontWeight: 300,
    },
    duration: {
        backgroundColor: '#000',
        bottom: 0,
        color: '#ff7c72',
        fontWeight: 300,
        paddingRight: 3,
        position: 'absolute',
        right: 0,
        zIndex: 2,
    },
    icons: {
        '& svg': {
            marginRight: 5,
        },

        marginTop: 20,
    },
    image: {
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 1,
    },
    imageContainer: {
        [theme.breakpoints.only('lg')]: {
            maxWidth: 90,
            minWidth: 90,
        },

        [theme.breakpoints.only('xl')]: {
            maxWidth: 130,
            minWidth: 130,
        },

        height: 70,
        position: 'relative',
    },
    item: {
        border: 'solid',
        borderColor: theme.palette.info.dark,
        borderWidth: 1,
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
        transition: theme.transitions.create('border-color', { duration: 100 }),
    },
    itemContainer: {
        padding: theme.spacing(1),
    },
    itemError: {
        borderColor: theme.palette.error.light,
    },
    itemExpired: {
        borderColor: theme.palette.divider,
        opacity: 0.75,
    },
    itemHover: {
        borderColor: theme.palette.info.light,
    },
    itemRunning: {
        animation: '$running linear 2s infinite',
    },
    media: {
        color: '#7cbff9',
        fontWeight: 300,
    },
    name: {
        fontWeight: 400,
    },
    notReadyButton: {
        cursor: 'not-allowed',
        opacity: 0.6,
    },
    readyButton: {
        cursor: 'pointer',
        opacity: 1,
    },
    removeButton: {
        '&:hover': {
            opacity: 0.9,
        },

        backgroundColor: '#000',
        border: '2px solid #fff',
        borderRadius: 2,
        color: '#fff',
        cursor: 'pointer',
        height: 20,
        opacity: 0.7,
        paddingLeft: 4,
        paddingTop: 1,
        position: 'absolute',
        right: 5,
        top: 5,
        transition: theme.transitions.create('opacity', { duration: 500 }),
        width: 20,
        zIndex: 2,
    },
    text: {
        '&>span': {
            display: 'block',
            fontSize: '1.1em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
        },

        [theme.breakpoints.only('lg')]: {
            maxWidth: 'calc(100% - 90px)',
        },

        [theme.breakpoints.only('xl')]: {
            maxWidth: 'calc(100% - 130px)',
        },

        paddingLeft: theme.spacing(1),
    },
    tools: {
        backgroundColor: theme.palette.background.paper,
        bottom: 22,
        color: theme.palette.text.primary,
        height: 22,
        left: 2,
        position: 'absolute',
        width: 105,
        zIndex: 3,
    },
    toolsButton: {
        '&:hover': {
            opacity: 0.9,
        },

        backgroundColor: '#000',
        border: '2px solid #fff',
        borderRadius: 2,
        bottom: 2,
        color: '#fff',
        cursor: 'pointer',
        height: 20,
        left: 2,
        opacity: 0.7,
        paddingLeft: 4,
        paddingTop: 1,
        position: 'absolute',
        transition: theme.transitions.create('opacity', { duration: 500 }),
        width: 20,
        zIndex: 2,
    },
    toolsItem: {
        cursor: 'pointer',
        display: 'inline-block',
        marginLeft: 5,
        marginTop: 3,
        width: 15,
    },
    toolsItemDisabled: {
        cursor: 'not-allowed',
        display: 'inline-block',
        marginLeft: 5,
        marginTop: 3,
        opacity: 0.4,
        width: 15,
    },
});

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    IOwnProps &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class ArchiveItemComponent extends React.Component<AllProps, IState> {
    public constructor(props: AllProps) {
        super(props);
        this.state = this.getDefaultState();
    }

    public render(): React.ReactNode {
        const classes = this.props.classes;
        const isRunning =
            !isNullOrUndefined(this.props.archive.Status) &&
            [
                mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_RUNNING,
                mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_SCHEDULED,
            ].indexOf(this.props.archive.Status) !== -1;
        const isOk =
            !isNullOrUndefined(this.props.archive.Status) &&
            this.props.archive.Status === mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_OK;
        const itemClassName = classNames({
            [classes.item]: true,
            [classes.itemError]:
                !isNullOrUndefined(this.props.archive.Status) &&
                this.props.archive.Status === mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_ERROR,
            [classes.itemExpired]:
                !isNullOrUndefined(this.props.archive.Status) &&
                this.props.archive.Status === mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_EXPIRED,
            [classes.itemHover]: isOk && this.state.hover,
            [classes.itemRunning]: isRunning,
        });

        return (
            <MD.Grid className={classes.itemContainer} item xs={3}>
                <MD.Tooltip title={this.getArchiveTooltip()}>
                    <MD.Box
                        className={itemClassName}
                        onMouseEnter={this.onMouseEnter.bind(this)}
                        onMouseLeave={this.onMouseLeave.bind(this)}
                    >
                        <MD.Box className={classes.imageContainer}>
                            <img
                                className={classes.image}
                                onError={this.onVignetteError.bind(this)}
                                onMouseEnter={this.onVignetteOver.bind(this, true)}
                                onMouseLeave={this.onVignetteOver.bind(this, false)}
                                src={this.getVignetteURL()}
                            />
                            {this.state.hover ? (
                                <MD.Tooltip title={this.props.i18n._('Delete archive')}>
                                    <div className={classes.removeButton} onClick={this.onDelete.bind(this)}>
                                        <FontAwesomeIcon icon={Icons.faTimes} />
                                    </div>
                                </MD.Tooltip>
                            ) : (
                                ''
                            )}
                            <MD.Hidden mdDown={true}>
                                <div className={classes.duration}>{this.getDuration()}</div>
                                {isOk ? (
                                    <div className={classes.toolsButton} onClick={this.toggleTools.bind(this)}>
                                        <FontAwesomeIcon
                                            icon={this.state.toolsOpened ? Icons.faCaretDown : Icons.faCaretUp}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </MD.Hidden>
                            {this.state.toolsOpened ? (
                                <div className={classes.tools}>
                                    <MD.Tooltip title={this.props.i18n._('Play archive')}>
                                        <span className={classes.toolsItem} onClick={this.launchPlay.bind(this)}>
                                            <FontAwesomeIcon icon={Icons.faPlay} />
                                        </span>
                                    </MD.Tooltip>
                                    <MD.Tooltip title={this.props.i18n._('See archive in timeline')}>
                                        <span
                                            className={classes.toolsItem}
                                            onClick={() => {
                                                this.props.setTimelineProgram({
                                                    DurationMS: this.props.archive.DurationMS,
                                                    MediaID: this.props.archive.MediaID as number,
                                                    MediaName: this.getMediaName(),
                                                    Start: this.props.archive.Start as number,
                                                });
                                                this.props.router.history.push('/timeline');
                                            }}
                                        >
                                            <FontAwesomeIcon icon={Icons.faStream} />
                                        </span>
                                    </MD.Tooltip>
                                    <MD.Tooltip title={this.props.i18n._('Edit archive')}>
                                        <span
                                            className={classes.toolsItem}
                                            onClick={() => {
                                                if (this.props.onEditClick) {
                                                    this.props.onEditClick(this.props.archive);
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon icon={Icons.faPen} />
                                        </span>
                                    </MD.Tooltip>
                                    <MD.Tooltip title={this.props.i18n._('Not yet available')}>
                                        <span className={classes.toolsItemDisabled} onClick={Logger.debug}>
                                            <FontAwesomeIcon icon={Icons.faICursor} />
                                        </span>
                                    </MD.Tooltip>
                                    <MD.Tooltip title={this.props.i18n._('Share archive')}>
                                        <span
                                            className={classes.toolsItem}
                                            onClick={() => {
                                                if (this.props.onShareClick) {
                                                    this.props.onShareClick(this.props.archive);
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon icon={Icons.faShareAlt} />
                                        </span>
                                    </MD.Tooltip>
                                </div>
                            ) : (
                                ''
                            )}
                        </MD.Box>
                        <MD.Box className={classes.text}>
                            <MD.Typography className={classes.name} variant='inherit'>
                                {ensureString(this.props.archive.Name)}
                            </MD.Typography>
                            <MD.Typography className={classes.media} variant='inherit'>
                                {ensureString(this.getMediaName())}
                            </MD.Typography>
                            <MD.Typography className={classes.date} variant='inherit'>
                                {ensureString(this.getStart())}
                            </MD.Typography>
                            <MD.Typography className={classes.counts} variant='inherit'>
                                {this.getLinks()}
                            </MD.Typography>
                        </MD.Box>
                    </MD.Box>
                </MD.Tooltip>
            </MD.Grid>
        );
    }

    private onMouseEnter() {
        this.setState({ hover: true });
    }

    private onMouseLeave() {
        this.setState({ hover: false });
    }

    private getVignetteURL(): string {
        const url = ensureString(this.props.archive.VignetteURL);

        return url === '' ? missingVignette : url;
    }

    private onVignetteError(ev: React.SyntheticEvent<HTMLImageElement>): void {
        if (isNullOrUndefined(ev.target)) {
            return;
        }
        ev.currentTarget.src = missingVignette;
    }

    private onVignetteOver(enter: boolean, ev: React.SyntheticEvent<HTMLImageElement>): void {
        if (isNullOrUndefined(ev.target)) {
            return;
        }
        if (!this.props.archive.Gif) {
            return;
        }
        const video = ensureString(this.props.archive.Url);
        const gif = getGIFFromVideo(video);

        if (video === '' || gif === '') {
            return;
        }

        const img = ev.target as HTMLImageElement;
        img.src = enter ? gif : ensureString(this.props.archive.VignetteURL);
    }

    private getDuration(): string {
        const durationMS = this.props.archive.DurationMS || 0;
        const duration = moment.duration(durationMS);

        return (
            ('0' + duration.hours().toString()).slice(-2) +
            ':' +
            ('0' + duration.minutes().toString()).slice(-2) +
            ':' +
            ('0' + duration.seconds().toString()).slice(-2)
        );
    }

    private getMediaName(): string {
        const mediaID = this.props.archive.MediaID || 0;
        let mediaName = 'N/A';

        this.props.medias.data.some((media) => {
            if (media.id === mediaID) {
                mediaName = media.name;
                return true;
            }
            return false;
        });
        return mediaName;
    }

    private getStart(): string {
        if (isNullOrUndefinedOrZero(this.props.archive.Start)) {
            return '';
        }
        return moment(new Date(this.props.archive.Start as number)).format(
            this.props.localeInfos.formatShortDateTimeWithSeconds,
        );
    }

    private isReady(): boolean {
        return (
            this.props.archive.Status === mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_OK && this.props.archive.Url !== ''
        );
    }

    private viewTooltip(): string {
        switch (this.props.archive.Status) {
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_ERROR: {
                return this.props.i18n._('Archive extraction failed');
            }
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_EXPIRED: {
                return this.props.i18n.ngettext(
                    'Archive has been viewed %1$d time, but is now expired',
                    'Archive has been viewed %1$d times, but is now expired',
                    this.props.archive.Views || 0,
                );
            }
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_OK: {
                return this.props.i18n.ngettext(
                    'Archive has been viewed %1$d time, click to view it',
                    'Archive has been viewed %1$d times, click to view it',
                    this.props.archive.Views || 0,
                );
            }
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_RUNNING:
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_SCHEDULED: {
                return this.props.i18n._('Archive extraction is in progress');
            }
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_UNKNOWN:
            default: {
                return this.props.i18n._('Archive status is unknown');
            }
        }
    }

    private downloadTooltip(): string {
        switch (this.props.archive.Status) {
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_ERROR: {
                return this.props.i18n._('Archive extraction failed');
            }
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_EXPIRED: {
                return this.props.i18n.ngettext(
                    'Archive has been downloaded %1$d time, but is now expired',
                    'Archive has been downloaded %1$d times, but is now expired',
                    this.props.archive.Downloads || 0,
                );
            }
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_OK: {
                return this.props.i18n.ngettext(
                    'Archive has been downloaded %1$d time, click to download it',
                    'Archive has been downloaded %1$d times, click to download it',
                    this.props.archive.Downloads || 0,
                );
            }
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_RUNNING:
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_SCHEDULED: {
                return this.props.i18n._('Archive extraction is in progress');
            }
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_UNKNOWN:
            default: {
                return this.props.i18n._('Archive status is unknown');
            }
        }
    }

    private launchPlay() {
        if (!isNullOrUndefined(this.props.onPlayClick) && this.isReady()) {
            this.props.onPlayClick(this.props.archive);
        }
    }

    private launchDownload() {
        if (
            this.isReady() &&
            !isNullOrUndefined(window) &&
            !isNullOrUndefined(window.location) &&
            !isNullOrUndefined(window.location.href) &&
            !isNullOrUndefined(this.props.archive) &&
            !isNullOrUndefinedOrEmptyString(this.props.archive.ID)
        ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).document.location.href = ensureString(this.props.archive.Url);
            this.props.incCounters(this.props.archive.ID, 0, 1);
        }
    }

    private launchDownloadGif() {
        const gif = this.props.archive.Gif ? getGIFFromVideo(ensureString(this.props.archive.Url)) : '';
        if (
            gif === '' ||
            (this.isReady() &&
                !isNullOrUndefined(window) &&
                !isNullOrUndefined(window.location) &&
                !isNullOrUndefined(window.location.href) &&
                !isNullOrUndefined(this.props.archive) &&
                !isNullOrUndefinedOrEmptyString(this.props.archive.ID))
        ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).document.location.href = gif;
        }
    }

    private onDelete(): void {
        if (isNullOrUndefined(this.props.archive) || isNullOrUndefinedOrEmptyString(this.props.archive.ID)) {
            return;
        }
        if (confirm(this.props.i18n._('Are you sure ? This action is permanent'))) {
            this.props.deleteArchive(this.props.archive.ID);
        }
    }

    private toggleTools() {
        this.setState({ toolsOpened: !this.state.toolsOpened });
    }

    private getArchiveTooltip(): string {
        if (isNullOrUndefined(this.props.archive.Status)) {
            return '';
        }
        switch (this.props.archive.Status) {
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_ERROR:
                return '';
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_EXPIRED:
                return this.props.i18n._('Extraction expired, you can not download or view it');
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_OK:
                return '';
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_RUNNING:
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_SCHEDULED:
                return this.props.i18n._('Extraction is in progress');
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_UNKNOWN:
            default:
                return '';
        }
    }

    private getLinks(): React.ReactNode {
        if (isNullOrUndefined(this.props.archive.Status)) {
            return '';
        }
        switch (this.props.archive.Status) {
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_ERROR:
                return this.getLinksError();
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_EXPIRED:
                return '';
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_OK:
                return this.getLinksOk();
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_RUNNING:
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_SCHEDULED:
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_UNKNOWN:
            default:
                return '';
        }
    }

    private getLinksOk(): React.ReactNode {
        const classes = this.props.classes;
        const gif = this.props.archive.Gif ? getGIFFromVideo(ensureString(this.props.archive.Url)) : '';

        return (
            <>
                <MD.Tooltip title={this.viewTooltip()}>
                    <span
                        className={this.isReady() ? classes.readyButton : classes.notReadyButton}
                        onClick={this.launchPlay.bind(this)}
                    >
                        <FontAwesomeIcon icon={Icons.faEye} />
                        {this.props.archive.Views || 0}
                    </span>
                </MD.Tooltip>
                <MD.Tooltip title={this.downloadTooltip()}>
                    <span
                        className={this.isReady() ? classes.readyButton : classes.notReadyButton}
                        onClick={this.launchDownload.bind(this)}
                    >
                        <FontAwesomeIcon icon={Icons.faDownload} />
                        {this.props.archive.Downloads || 0}
                    </span>
                </MD.Tooltip>
                {gif ? (
                    <MD.Tooltip title={this.props.i18n._('Download GIF')}>
                        <span
                            className={this.isReady() ? classes.readyButton : classes.notReadyButton}
                            onClick={this.launchDownloadGif.bind(this)}
                        >
                            <FontAwesomeIcon icon={Icons.faFilm} />
                        </span>
                    </MD.Tooltip>
                ) : (
                    ''
                )}
            </>
        );
    }

    private getLinksError(): React.ReactNode {
        const classes = this.props.classes;

        if (!isNullOrUndefined(this.props.archive.Retries) && this.props.archive.Retries >= 10) {
            return (
                <MD.Tooltip title={this.props.i18n._('Extraction failed, and too many attempts were done')}>
                    <span className={classes.notReadyButton}>
                        <FontAwesomeIcon icon={Icons.faEngineWarning} />
                    </span>
                </MD.Tooltip>
            );
        }

        return (
            <MD.Tooltip title={this.props.i18n._('Extraction failed, click to try again')}>
                <span
                    className={classes.readyButton}
                    onClick={() => {
                        if (!isNullOrUndefined(this.props.archive.ID)) {
                            this.props.retry(this.props.archive.ID);
                        }
                    }}
                >
                    <FontAwesomeIcon icon={Icons.faRedo} />
                </span>
            </MD.Tooltip>
        );
    }

    private getDefaultState(): IState {
        return {
            edit: false,
            hover: false,
            toolsOpened: false,
        };
    }
}

const mapStateToProps = ({ i18n, medias }: IApplicationState, ownProps: RouteComponentProps<{}>) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    medias,
    router: ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    deleteArchive: (id: string) => dispatch(deleteArchive(id)),
    incCounters: (id: string, views: number, downloads: number) => dispatch(incCounters(id, views, downloads)),
    retry: (id: string) => dispatch(retry(id)),
    setTimelineProgram: (prg: NullableResult) => dispatch(setTimelineProgram(prg)),
});

export const ArchiveItem = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(ArchiveItemComponent));
