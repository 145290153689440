import * as Icons from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N, ILocaleInfos } from '../../Store/I18n';
import {
    ITimelineState,
    dateWarning,
    groupsForWarningDisplay,
    setCalendarDuration,
    setCalendarStart,
    setCalendarStartWarning,
    warningMsg,
} from '../../Store/Timeline';
import { IUserState } from '../../Store/User/Types';
import styles from './TimelineDrawerCalendar.style';
import { isNullOrUndefined } from '../../Utils/Various';

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    setCalendarDuration: typeof setCalendarDuration;
    setCalendarStart: typeof setCalendarStart;
    setCalendarStartWarning: typeof setCalendarStartWarning;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class TimelineDrawerCalendarComponent extends React.Component<AllProps> {
    private currentMonth: number;
    private currentYear: number;

    public constructor(props: AllProps) {
        super(props);

        const today = this.props.timeline.criterias.calendar.start;
        this.currentMonth = today.getMonth();
        this.currentYear = today.getFullYear();
    }

    public render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.monthPicker}>
                    <div className={classes.monthPickerStep}>
                        <a href='#' onClick={this.stepMonth.bind(this, -1)}>
                            <FontAwesomeIcon icon={Icons.faStepBackward} />
                        </a>
                    </div>
                    <div className={classes.monthPickerMonth}>
                        {/*{this.getMonth(this.currentMonth)}*/}
                        <select
                            onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                                const month = parseInt(ev.target.value, 10);

                                if (isNaN(month)) {
                                    return;
                                }
                                this.currentMonth = month;
                                this.setStartWarning();
                                this.forceUpdate();
                            }}
                            value={this.currentMonth}
                        >
                            {Array.from(Array(12).keys()).map((i) => (
                                <option key={`select_month_${i}`} label={this.getMonth(i)} value={i.toString(10)} />
                            ))}
                        </select>
                    </div>
                    <div className={classes.monthPickerYear}>
                        {/*{this.currentYear}*/}
                        <select
                            onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                                const year = parseInt(ev.target.value, 10);

                                if (isNaN(year)) {
                                    return;
                                }
                                this.currentYear = year;
                                this.setStartWarning();
                                this.forceUpdate();
                            }}
                            value={this.currentYear}
                        >
                            {[2018, 2019, 2020, 2021, 2022, 2023, 2024].map((i) => (
                                <option key={`select_year_${i}`} label={i.toString(10)} value={i.toString(10)} />
                            ))}
                        </select>
                    </div>
                    <div className={classes.monthPickerStep}>
                        <a href='#' onClick={this.stepMonth.bind(this, 1)}>
                            <FontAwesomeIcon icon={Icons.faStepForward} />
                        </a>
                    </div>
                </div>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            {Array.from(Array(7).keys()).map((i) => {
                                let day = this.props.localeInfos.dow + i;
                                if (day > 6) {
                                    day = day - 7;
                                }
                                return <td key={`calendarHead_${i}`}>{this.getDayLetter(day)}</td>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.computeDays().map((line, i) => (
                            <tr key={`calendarLine_${i}`}>{line.map((day) => this.renderDate(day))}</tr>
                        ))}
                    </tbody>
                </table>
                <div className={this.props.classes.selectHour}>
                    <span>{this.props.i18n._('Start')} :</span>
                    <select
                        onChange={this.setStartHours.bind(this)}
                        value={`${this.props.timeline.criterias.calendar.start.getHours()}`}
                    >
                        {Array.from(Array(24).keys()).map((i) => (
                            <option key={`hours_${i}`} value={`${i}`}>
                                {i < 10 ? `0${i}` : `${i}`}
                            </option>
                        ))}
                    </select>
                    :
                    <select
                        onChange={this.setStartMinutes.bind(this)}
                        value={this.props.timeline.criterias.calendar.start.getMinutes()}
                    >
                        {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55].map((i) => (
                            <option key={`minutes_${i}`} value={i}>
                                {i < 10 ? `0${i}` : `${i}`}
                            </option>
                        ))}
                    </select>
                </div>
                <div className={this.props.classes.selectDuration}>
                    <span>{this.props.i18n._('Duration')} :</span>
                    <select
                        onChange={this.setDuration.bind(this)}
                        value={this.props.timeline.criterias.calendar.duration}
                    >
                        {[24, 22, 20, 18, 16, 14, 12, 10, 8, 6, 4, 2, 1].map((i) => (
                            <option key={`minutes_${i}`} value={i}>
                                {i}
                            </option>
                        ))}
                    </select>
                    {this.props.i18n._('Hours')}
                </div>
            </div>
        );
    }

    private getDayLetter(day: number): string {
        return this.props.localeInfos.daysInitials[day];
    }

    private getMonth(month: number): string {
        return this.props.localeInfos.months[month];
    }

    private renderDate(day: number): React.ReactNode {
        if (day === 0) {
            return <td key={`calendarDay_${Math.random()}`}>&nbsp;</td>;
        }
        const today = new Date();
        const date = new Date(this.currentYear, this.currentMonth, day);
        let className = this.isSameDay(date, this.props.timeline.criterias.calendar.start)
            ? this.props.classes.activeDay
            : this.isSameDay(date, new Date())
            ? this.props.classes.today
            : '';

        if (date.getTime() > today.getTime()) {
            className += ` ${this.props.classes.disabledDay}`;
            return (
                <td className={className} key={`calendarDay_${date.getTime()}`}>
                    {day}
                </td>
            );
        }
        className += ` ${this.props.classes.enabledDay}`;
        return (
            <td className={className} key={`calendarDay_${date.getTime()}`} onClick={this.setDay.bind(this, date)}>
                {day}
            </td>
        );
    }

    private setDay(day: Date) {
        const date = new Date(
            day.getFullYear(),
            day.getMonth(),
            day.getDate(),
            this.props.timeline.criterias.calendar.start.getHours(),
            this.props.timeline.criterias.calendar.start.getMinutes(),
            0,
            0,
        );
        this.props.setCalendarStart(date);
        this.setStartWarning(date);
    }

    private computeDays(): number[][] {
        const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1);
        const lastDayOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);
        const days = lastDayOfMonth.getDate();
        let date = 1;
        let loop = true;
        const result: number[][] = [];

        result.push(
            Array.from(Array(7).keys()).map((i) => {
                let day = this.props.localeInfos.dow + i;

                if (day > 6) {
                    day = day - 7;
                }
                if (date > 1 || day === firstDayOfMonth.getDay()) {
                    return date++;
                }
                return 0;
            }),
        );
        while (loop) {
            result.push(
                Array.from(Array(7).keys()).map(() => {
                    if (date > days) {
                        return 0;
                    }
                    if (date === days) {
                        loop = false;
                    }
                    return date++;
                }),
            );
        }
        while (result.length < 6) {
            result.push([0]);
        }
        return result;
    }

    private isSameDay(d1: Date, d2: Date): boolean {
        const dd1 = new Date(d1).setHours(0, 0, 0, 0);
        const dd2 = new Date(d2).setHours(0, 0, 0, 0);

        return dd1 === dd2;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private stepMonth(delta: number, ev: any) {
        ev.preventDefault();
        const date = new Date(this.currentYear, this.currentMonth + delta);
        const today = new Date();
        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1, 0, 0, 0, 0);
        const minDate = new Date(today.getFullYear() - 6, today.getMonth(), 1, 0, 0, 0, 0);

        if (date.getTime() >= nextMonth.getTime() || date.getTime() < minDate.getTime()) {
            return;
        }

        this.currentYear = date.getFullYear();
        this.currentMonth = date.getMonth();

        this.setStartWarning(date);

        this.forceUpdate();
    }

    private setDuration(ev: React.ChangeEvent<HTMLSelectElement>) {
        const duration = parseInt(ev.target.value, 10);

        if (isNaN(duration) || duration < 1 || duration > 24) {
            return;
        }
        this.props.setCalendarDuration(duration);
    }

    private setStartMinutes(ev: React.ChangeEvent<HTMLSelectElement>) {
        const val = parseInt(ev.target.value, 10);

        if (isNaN(val) || val < 0 || val > 55) {
            return;
        }
        const newStart = new Date(this.props.timeline.criterias.calendar.start.setMinutes(val));
        this.setStartWarning(newStart);
        this.props.setCalendarStart(newStart);
    }

    private setStartHours(ev: React.ChangeEvent<HTMLSelectElement>) {
        const val = parseInt(ev.target.value, 10);

        if (isNaN(val) || val < 0 || val > 23) {
            return;
        }
        const newStart = new Date(this.props.timeline.criterias.calendar.start.setHours(val));
        this.setStartWarning(newStart);
        this.props.setCalendarStart(newStart);
    }

    private setStartWarning(start?: Date) {
        if (!isNullOrUndefined(start)) {
            this.props.setCalendarStartWarning(
                this.shouldShowWarningForDate() && groupsForWarningDisplay.includes(this.props.user.user.group)
                    ? start.getTime() < dateWarning
                        ? warningMsg
                        : ''
                    : '',
            );
        } else {
            this.props.setCalendarStartWarning(
                this.shouldShowWarningForDate() && groupsForWarningDisplay.includes(this.props.user.user.group)
                    ? new Date(this.currentYear, this.currentMonth).getTime() < dateWarning
                        ? warningMsg
                        : ''
                    : '',
            );
        }
    }

    // only show message on weekends and week days from 22.30 to 8.00
    private shouldShowWarningForDate(): boolean {
        const now = new Date();

        if (now.getDay() === 6 || now.getDay() === 0) {
            return true;
        }
        if (now.getHours() >= 23 || now.getHours() < 8) {
            return true;
        }
        if (now.getHours() === 22 && now.getHours() >= 30) {
            return true;
        }
        return false;
    }
}

const mapStateToProps = ({ i18n, timeline, user }: IApplicationState) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCalendarDuration: (duration: number) => dispatch(setCalendarDuration(duration)),
    setCalendarStart: (start: Date) => dispatch(setCalendarStart(start)),
    setCalendarStartWarning: (startWarning: string) => dispatch(setCalendarStartWarning(startWarning)),
});

export const TimelineDrawerCalendar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(TimelineDrawerCalendarComponent));
