import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetTalkMedias extends AMessage implements IToCommand {
    public constructor() {
        super('getTalkMedias');
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetTalkMedias,
            }),
        );
    }
}
