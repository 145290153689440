import { Reducer } from 'redux';

import { AnnotationTypes } from '../../Backend/Commands/AnnotationTypes';
import * as Proto from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { isNull } from '../../Utils/Various';
import {
    AnnotationTypesActionTypes,
    IAnnotationTypesState,
    NullableAnnotationTypeList,
} from './Types';

const initialState: IAnnotationTypesState = {
    annotationTypes: [],
    annotationTypesOwned: [],
    formErrors: {
        color: '',
        general: '',
        name: '',
    },
    opSuccessEventElement: document.createElement('div'),
    operationInProgress: false,
    total: 0,
    totalOwned: 0,
};

const reducer: Reducer<IAnnotationTypesState> = (state = initialState, action) => {
    switch (action.type) {
        case AnnotationTypesActionTypes.UPDATE_LIST: {
            const cmd: AnnotationTypes = action.payload;
            const annotationTypes = Array.from(state.annotationTypes);

            if (cmd.count === 0) {
                return state;
            }
            if (cmd.offset > annotationTypes.length) {
                for (let i = annotationTypes.length; i < cmd.offset; i++) {
                    annotationTypes[i] = null;
                }
            }
            for (let i = 0; i < cmd.count; i++) {
                annotationTypes[i + cmd.offset] = cmd.annotationTypes[i];
            }
            return { ...state, annotationTypes };
        }

        case AnnotationTypesActionTypes.UPDATE_OWNED_LIST: {
            const cmd: AnnotationTypes = action.payload;
            const annotationTypesOwned = Array.from(state.annotationTypesOwned);

            if (cmd.count === 0) {
                return state;
            }
            if (cmd.offset > annotationTypesOwned.length) {
                for (let i = annotationTypesOwned.length; i < cmd.offset; i++) {
                    annotationTypesOwned[i] = null;
                }
            }
            for (let i = 0; i < cmd.count; i++) {
                annotationTypesOwned[i + cmd.offset] = cmd.annotationTypes[i];
            }
            return { ...state, annotationTypesOwned };
        }

        case AnnotationTypesActionTypes.UPDATE: {
            const type: Proto.mediaarchiver.AnnotationType = action.payload[0];
            const actionType: string = action.payload[1];
            const types = Array.from(state.annotationTypesOwned);
            let newTypes: NullableAnnotationTypeList = [];
            let delta = 0;

            if (actionType === 'create') {
                let i = 0;
                for (i = 0; i < types.length; i++) {
                    if (
                        (isNull(types[i])) ||
                        (type.Name < (types[i] as Proto.mediaarchiver.AnnotationType).Name)
                    ) {
                        break;
                    }
                }
                newTypes = (i > 0) ? types.slice(0, i) : [];
                newTypes.push(type);
                if (i < types.length) {
                    newTypes.push(...types.slice(i));
                }
                delta = 1;
            } else if (actionType === 'edit') {
                newTypes = types.map((t) => {
                    if (!isNull(t) && t.ID === type.ID) {
                        return type;
                    }
                    return t;
                });
            } else if (actionType === 'delete') {
                newTypes = types.filter((t) => {
                    if (!isNull(t) && t.ID === type.ID) {
                        return false;
                    }
                    return true;
                });
                delta = -1;
            } else {
                Logger.warn('Unkown action type');
                return state;
            }
            if (newTypes.length < state.total) {
                for (let i = newTypes.length; i < state.total + delta; i++) {
                    newTypes[i] = null;
                }
            }
            return { ...state, annotationTypesOwned: newTypes, totalOwned: newTypes.length };
        }

        case AnnotationTypesActionTypes.SET_COUNT: {
            return { ...state, total: action.payload };
        }

        case AnnotationTypesActionTypes.SET_OWNED_COUNT: {
            return { ...state, totalOwned: action.payload };
        }

        case AnnotationTypesActionTypes.SET_OP_PROGRESS: {
            return { ...state, operationInProgress: action.payload };
        }

        case AnnotationTypesActionTypes.SET_ERRORS: {
            return { ...state, formErrors: action.payload };
        }

        case AnnotationTypesActionTypes.OP_SUCCESS: {
            state.opSuccessEventElement.dispatchEvent(
                new CustomEvent('opSuccess', { detail: action.payload }),
            );
            return state;
        }

        default: {
            return state;
        }
    }
};

export { reducer as AnnotationTypesReducer };
