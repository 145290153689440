import { Store } from 'redux';
import { action } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { Archive } from '../../Backend/Commands/Archive';
import { Archives } from '../../Backend/Commands/Archives';
import { ArchivesCount } from '../../Backend/Commands/ArchivesCount';
import * as Proto from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import {
    ArchivesActionTypes,
    IArchiveFormErrors,
    ICreateAction,
    IDeleteArchiveAction,
    IDeletedArchiveAction,
    IEditAction,
    IGetAction,
    IGetByIdAction,
    IGetCountAction,
    IHideDialogAction,
    IIncCountersAction,
    IRetryAction,
    ISetArchiveAction,
    ISetOpErrorsAction,
    ISetOpSuccessAction,
    ISetProgressAction,
    IShareAction,
    IShowDialogAction,
    IUnwatchArchiveAction,
    IWatchArchiveAction,
    IWatchTickAction,
} from './Types';

export const create = (specs: Proto.mediaarchiver.IArchive): ICreateAction => action(ArchivesActionTypes.CREATE, specs);
export const deleteArchive = (id: string): IDeleteArchiveAction => action(ArchivesActionTypes.DELETE, id);
export const deletedArchive = (id: string): IDeletedArchiveAction => action(ArchivesActionTypes.DELETED_ARCHIVE, id);
export const edit = (specs: Proto.mediaarchiver.IArchive): IEditAction => action(ArchivesActionTypes.EDIT, specs);
export const get = (specs: Proto.mediaarchiver.IArgumentsGetArchives): IGetAction =>
    action(ArchivesActionTypes.GET, specs);
export const getById = (id: string): IGetByIdAction => action(ArchivesActionTypes.GET_BY_ID, id);
export const getCount = (): IGetCountAction => action(ArchivesActionTypes.GET_COUNT);
export const hideDialog = (): IHideDialogAction => action(ArchivesActionTypes.HIDE_DIALOG);
export const incCounters = (archiveID: string, views: number, downloads: number): IIncCountersAction =>
    action(ArchivesActionTypes.INC_COUNTERS, [archiveID, views, downloads]);
export const retry = (id: string): IRetryAction => action(ArchivesActionTypes.RETRY, id);
export const setArchive = (archive: Proto.mediaarchiver.IArchive): ISetArchiveAction =>
    action(ArchivesActionTypes.SET_ARCHIVE, archive);
export const setOpErrors = (errors: IArchiveFormErrors): ISetOpErrorsAction =>
    action(ArchivesActionTypes.SET_OP_ERRORS, errors);
export const setOpSuccess = (): ISetOpSuccessAction => action(ArchivesActionTypes.SET_OP_SUCCESS);
export const setProgress = (toggle: boolean): ISetProgressAction => action(ArchivesActionTypes.SET_OP_PROGRESS, toggle);
export const share = (params: Proto.mediaarchiver.IArgumentsShareArchive): IShareAction =>
    action(ArchivesActionTypes.SHARE, params);
export const showDialog = (): IShowDialogAction => action(ArchivesActionTypes.SHOW_DIALOG);
export const unwatchArchive = (id: string): IUnwatchArchiveAction => action(ArchivesActionTypes.UNWATCH_ARCHIVE, id);
export const watchArchive = (id: string): IWatchArchiveAction => action(ArchivesActionTypes.WATCH_ARCHIVE, id);
export const watchTick = (): IWatchTickAction => action(ArchivesActionTypes.WATCH_TICK);

Backend.getInstance()
    .Bind('archive', (incoming: IFromCommand) => {
        const cmd = incoming as Archive;

        if (cmd.archive === undefined) {
            return;
        }

        Logger.trace({ count: cmd }, 'Received archive');
        (store as Store).dispatch(setArchive(cmd.archive));
    })
    .Bind('archivesCount', (incoming: IFromCommand) => {
        const cmd = incoming as ArchivesCount;

        Logger.trace({ count: cmd }, 'Received archives count');
        (store as Store).dispatch(action(ArchivesActionTypes.SET_COUNT, [cmd.radioCount, cmd.tvCount]));
    })
    .Bind('archives', (incoming: IFromCommand) => {
        const cmd = incoming as Archives;

        Logger.trace({ cmd }, 'Received archives search result');
        (store as Store).dispatch(action(ArchivesActionTypes.SET_ARCHIVES, [cmd.archives, cmd.count]));
    });
