import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IApplicationState } from '../../../Store';
import { showReportBug } from '../../../Store/Timeline';
import { showIncident } from '../../../Store/Incident';
import styles from './AccountDrawer.styles';
import AccountDrawerComponent, { IRouterMatchParams } from './Drawer';

const mapStateToProps = (state: IApplicationState, ownProps: RouteComponentProps<IRouterMatchParams>) => ({
    i18n: state.i18n.i18n,
    incident: state.incident,
    router: ownProps,
    user: state.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    showIncident: () => dispatch(showIncident()),
    showReportBug: () => dispatch(showReportBug()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountDrawerComponent));
