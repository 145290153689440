import { mediaarchiver } from '../../Protos/protos';
import { AMessage, IToCommand } from './';

export class GetReviews extends AMessage implements IToCommand {
    private readonly specs: mediaarchiver.IArgumentsGetReviews;

    public constructor(specs: mediaarchiver.IArgumentsGetReviews) {
        super('getReviews');
        this.specs = specs;
    }

    public Send(): Promise<void> {
        return this.send(new mediaarchiver.WSMessage({
            GetReviewsArg: this.specs,
            Type: mediaarchiver.WSMessageType.MessageTypeGetReviews,
        }));
    }
}
