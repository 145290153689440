import * as Proto from '../../Protos/protos';

export enum FTPAccountActionTypes {
    CREATE = '@@ftpAccounts/CREATE',
    DELETE = '@@ftpAccounts/DELETE',
    DELETED_ACCOUNT = '@@ftpAccounts/DELETED_ACCOUNT',
    EDIT = '@@ftpAccounts/EDIT',
    GET = '@@ftpAccounts/GET',
    GET_COUNT = '@@ftpAccounts/GET_COUNT',
    OP_SUCCESS = '@@ftpAccounts/OP_SUCCESS',
    SET_ACCOUNT = '@@ftpAccounts/SET_ACCOUNT',
    SET_ACCOUNTS = '@@ftpAccounts/SET_ACCOUNTS',
    SET_ACCOUNTS_COUNT = '@@ftpAccounts/SET_ACCOUNTS_COUNT',
    SET_ERRORS = '@@ftpAccounts/SET_ERRORS',
    SET_OP_PROGRESS = '@@ftpAccounts/SET_OP_PROGRESS',
}

export interface IFTPAccountFormErrors {
    general: string;
    hostname: string;
    name: string;
    port: string;
}

export type NullableAccount = Proto.mediaarchiver.IFTPAccount | null;
export type NullableAccountList = NullableAccount[];

export interface IFTPAccountsState {
    readonly accounts: NullableAccountList;
    readonly opSuccessEventElement: HTMLElement;
    readonly operationInProgress: boolean;
    readonly total: number;
}

export interface ICreateAction {
    payload: Proto.mediaarchiver.IFTPAccount;
    type: FTPAccountActionTypes.CREATE;
}

export interface IDeleteAccountAction {
    payload: string;
    type: FTPAccountActionTypes.DELETE;
}

export interface IDeletedAccountAction {
    payload: string;
    type: FTPAccountActionTypes.DELETED_ACCOUNT;
}

export interface IEditAction {
    payload: Proto.mediaarchiver.IFTPAccount;
    type: FTPAccountActionTypes.EDIT;
}

export interface IGetActionPayload {
    limit: number;
    offset: number;
}

export interface IGetAction {
    payload: IGetActionPayload;
    type: FTPAccountActionTypes.GET;
}

export interface IGetCountAction {
    type: FTPAccountActionTypes.GET_COUNT;
}

export interface ISetErrorsAction {
    payload: IFTPAccountFormErrors;
    type: FTPAccountActionTypes.SET_ERRORS;
}

export interface ISetOpSuccessAction {
    type: FTPAccountActionTypes.OP_SUCCESS;
}

export interface ISetProgressAction {
    payload: boolean;
    type: FTPAccountActionTypes.SET_OP_PROGRESS;
}
