import { createStyles } from '@material-ui/core';
import { getTheme } from '../../Themes';

const theme = getTheme();

export default createStyles({
    content: {
        flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
});
