import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetMail extends AMessage implements IToCommand {
    private mailID: string;

    public constructor(mailID: string) {
        super('getMail');
        if (mailID === '') {
            throw new Error('Missing mail id');
        }
        this.mailID = mailID;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            GetMailArg : this.mailID,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetMail,
        }));
    }
}
