import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class DeleteFTPAccount extends AMessage implements IToCommand {
    private id: string;

    public constructor(id: string) {
        super('deleteFTPAccount');
        this.id = id;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            DeleteFTPAccountArg : this.id,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeDeleteFTPAccount,
        }));
    }
}
