import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    annotations: {
        height: '100%',
        paddingLeft: 5,
    },
    middle: {
        flex: 3,
        overflow: 'hidden',
    },
    middleContainer: {
        height: '100%',
        overflow: 'hidden',
        paddingBottom: 10,
    },
    playerContainer: {
        height: '100%',
        paddingRight: 5,
    },
    quota: {
        margin: 'auto',
        marginTop: 50,
        padding: theme.spacing(1),
        width: '40vw',
    },
    root: {
        '&>div': {
            flex: 1,
        },
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(1),
        paddingBottom: 0,
        paddingTop: 0,
        top: 0,
    },
    timeline: {},
});
