import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    annotationDialogAddLink: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1) * 3,
        marginTop: theme.spacing(1),
        textAlign: 'right',
    },
    annotationDialogContent: {
        minWidth: 600,
    },
    annotationDialogFormElement: {
        marginTop: theme.spacing(1),
    },
    annotationDialogLoading: {
        height: '20px !important',
        width: '20px !important',
    },
    contactsList: {
        '& svg': {
            marginRight: theme.spacing(1),
        },
        '&>:first-child': {
            borderTopWidth: 0,
        },
        '&>:last-child': {
            borderBottomWidth: 0,
        },
        '&>li': {
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderTop: `1px solid ${theme.palette.divider}`,
        },

        border: `1px solid ${theme.palette.divider}`,
        height: 315,
        maxHeight: 315,
        overflowY: 'auto',
    },
    controls: {
        background: 'black',
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'row',
        height: '40px',
        width: '100%',
    },
    controlsLeft: {
        '& a': {
            maxWidth: 30,
            minWidth: 30,
            padding: '8px 15px',
        },
        '& a:first-child': {
            maxWidth: 50,
            minWidth: 50,
            padding: '8px 25px',
        },
        alignSelf: 'center',
    },
    controlsRight: {
        '& a': {
            maxWidth: 30,
            minWidth: 30,
            padding: '8px 15px',
        },
        '& p': {
            color: theme.palette.text.primary,
            display: 'inline',
            fontFamily: theme.mediaarchiver.fonts.time,
            marginRight: 10,
        },
        alignSelf: 'center',
        marginLeft: 'auto',
    },
    extractionDialogContent: {
        minWidth: 600,
    },
    extractionDialogExpansionPanelCheckbox: {
        padding: 0,
        paddingRight: theme.spacing(1),
    },
    extractionDialogExpansionPanelContainer: {
        '&>div:first-child': {
            marginTop: theme.spacing(1) * 4,
        },
    },
    extractionDialogExpansionPanelDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    extractionDialogExpansionPanelDisabled: {
        cursor: 'not-allowed !important',
        opacity: 0.7,
    },
    extractionDialogExpansionPanelHeader: {
        '&>div': {
            alignItems: 'center',
        },
    },
    extractionDialogFormBorderError: {
        border: '1px solid red',
    },
    extractionDialogFormControls: {
        marginTop: theme.spacing(1),
    },
    extractionDialogFormElement: {
        marginTop: theme.spacing(1),
    },
    extractionDialogFormQualities: {
        padding: theme.spacing(1),
    },
    extractionDialogHeader: {
        display: 'flex',
        margin: theme.spacing(1),
        minHeight: 90,
    },
    extractionDialogHeaderText: {
        '&>p': {
            margin: 0,
        },
        '&>p>span': {
            fontWeight: 'bold',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: theme.spacing(1),
        minHeight: 90,
    },
    extractionDialogLoading: {
        height: '20px !important',
        width: '20px !important',
    },
    extractionDialogVignette: {
        border: `1px solid ${theme.palette.divider}`,
        margin: theme.spacing(1),
        marginRight: 0,
        maxHeight: 90,
        minWidth: 90,
    },
    fieldDescription: {
        fontSize: '2em',
    },
    player: {
        '&>canvas': {
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
        },
        '&>video': {
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
        },
        height: '100%',
        position: 'relative',
        'video::-webkit-media-controls': {
            display: 'none !important',
        },
        'video::-webkit-media-controls-enclosure': {
            display: 'none !important',
        },
        'video::-webkit-media-controls-overlay-enclosure': {
            display: 'none !important',
        },
        width: '100%',
    },
    playerContainer: {
        alignItems: 'center',
        background: 'black',
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        height: 'calc(100% - 42px)',
        justifyItems: 'center',
        marginBottom: 2,
        position: 'relative',
        width: '100%',
    },
    root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
    },
    selectionInfos: {
        '&:hover': {
            background: 'rgba(0, 0, 0, 1)',
        },
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.9)',
        borderRadius: theme.shape.borderRadius,
        bottom: 5,
        display: 'flex',
        flexDirection: 'row',
        height: 75,
        left: 20,
        position: 'absolute',
        transition: [
            theme.transitions.create('background', { duration: 500 }),
        ].join(','),
        width: 'calc(100% - 40px)',
        zIndex: 10,
    },
    selectionInfosActions: {
        '& button': {
            minWidth: '100%',
        },
    },
    selectionInfosDetailsDefinitions: {
        '&>p': {
            fontFamily: theme.mediaarchiver.fonts.time,
            fontSize: '1.1em',
        },
        marginLeft: 15,
    },
    selectionInfosDetailsIcons: {
        '&>a': {
            fontSize: '0.8em',
            maxHeight: 10,
            maxWidth: 10,
            minHeight: 10,
            minWidth: 10,
            padding: '0 10px',
        },

        alignSelf: 'start',
        lineHeight: '18px',
        marginLeft: 10,
        marginTop: 10,
        minWidth: 55,
    },
    selectionInfosDetailsValules: {
        '&>p': {
            fontFamily: theme.mediaarchiver.fonts.time,
            fontSize: '1.1em',
        },
        marginLeft: 10,
    },
    selectionSnapshotContainer: {
        '&>figure': {
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            height: 75,
            margin: 0,
            width: '100%',
        },
        maxHeight: 75,
        maxWidth: 150,
        minHeight: 75,
        minWidth: 150,
        overflow: 'hidden',
    },
    sharePopup: {
        '& h6': {
            marginBottom: theme.spacing(2),
        },
        minHeight: '30%',
        minWidth: '40%',
        padding: theme.spacing(1),
    },
    titleText: {
        '&>span': {
            color: theme.palette.text.primary,
            marginRight: 4,
        },
        color: theme.palette.text.primary,
        fontSize: 12,
        margin: '3px 0',
        textAlign: 'left',
        width: '100%',
    },
    volume: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: theme.shape.borderRadius,
        bottom: 5,
        display: 'none',
        height: 40,
        opacity: 0,
        position: 'absolute',
        right: 5,
        transition: theme.transitions.create('opacity', { duration: 500 }),
        width: 200,
        zIndex: 11,
    },
    volumeBar: {
        background: 'white',
        cursor: 'pointer',
        height: 4,
        left: 10,
        opacity: 0.5,
        position: 'absolute',
        top: 18,
        width: 180,
        zIndex: 3,
    },
    volumeBarActive: {
        background: 'white',
        cursor: 'pointer',
        height: 4,
        left: 10,
        position: 'absolute',
        top: 18,
        width: 90,
        zIndex: 3,
    },
    volumeBarClickZone: {
        cursor: 'pointer',
        height: 20,
        left: 5,
        opacity: 0.5,
        position: 'absolute',
        top: 10,
        width: 190,
        zIndex: 4,
    },
    volumeCursor: {
        background: 'white',
        borderRadius: '50%',
        cursor: 'pointer',
        height: 10,
        left: 95,
        position: 'absolute',
        top: 15,
        width: 10,
        zIndex: 3,
    },
});
