import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNullOrUndefined } from '../../Utils/Various';

export class SttMedias extends AMessage implements IFromCommand {
    private parsedMedias: number[] | null = null;

    public constructor() {
        super('sttMedias');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.STTMediasArg) || !Array.isArray(msg.STTMediasArg.Medias)) {
                return reject(new Error('Not a SttMedias message'));
            }
            this.parsedMedias = msg.STTMediasArg.Medias;
            resolve();
        });
    }

    public get medias(): number[] {
        if (isNullOrUndefined(this.parsedMedias)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedMedias;
    }
}
