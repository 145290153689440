import * as Proto from '../../Protos/protos';

export enum MediaTypes {
    Radio = 1,
    TV,
}

export enum MediasActionTypes {
    DO_UPDATE = '@@medias/DO_UPDATE',
    GET_PANELS = '@@medias/GET_PANELS',
    GET_CROSSBUCKET_MEDIAS = '@@medias/GET_CROSSBUCKET_MEDIAS',
    GET_BACKGROUND_MEDIAS = '@@medias/GET_BACKGROUND_MEDIAS',
    GET_TALK_MEDIAS = '@@medias/GET_TALK_MEDIAS',
    GET_STT_MEDIAS = '@@medias/GET_STT_MEDIAS',
    SEARCH_KINDS = '@@medias/SEARCH_KINDS',
    SEARCH_KINDS_RESET = '@@medias/SEARCH_KINDS_RESET',
    SELECT = '@@medias/SELECT',
    SELECTED = '@@medias/SELECTED',
    SET_CROSSBUCKET_MEDIAS = '@@medias/SET_CROSSBUCKET_MEDIAS',
    SET_BACKGROUND_MEDIAS = '@@medias/SET_BACKGROUND_MEDIAS',
    SET_TALK_MEDIAS = '@@medias/SET_TALK_MEDIAS',
    SET_STT_MEDIAS = '@@medias/SET_STT_MEDIAS',
    UPDATE = '@@medias/UPDATE',
    UPDATE_PANELS = '@@medias/UPDATE_PANELS',
    UPDATE_SEARCH_KINDS_RESULTS = '@@medias/UPDATE_SEARCH_KINDS_RESULTS',
    UPDATE_STT = '@@medias/UPDATE_STT',
}

export interface IMedia {
    id: number;
    name: string;
    timezone: string;
    type: MediaTypes;
    weTVID: number;
}

export interface IMediasPanelsState {
    readonly radios: Proto.mediaarchiver.IPanel[];
    readonly tvs: Proto.mediaarchiver.IPanel[];
}

export interface IMediasState {
    readonly data: IMedia[];
    readonly errors?: string;
    readonly kindsResults: Proto.mediaarchiver.ISearchKind[];
    readonly loading: boolean;
    readonly panels: IMediasPanelsState;
    readonly talkMedias: number[];
    readonly sttMedias: number[];
    readonly backgroundMedias: number[];
    readonly crossbucketMedias: number[];
}

export interface IGetPanelsAction {
    type: MediasActionTypes.GET_PANELS;
}

export interface IGetBackgroundMediasAction {
    type: MediasActionTypes.GET_BACKGROUND_MEDIAS;
}
export interface IGetCrossBucketMediasAction {
    type: MediasActionTypes.GET_CROSSBUCKET_MEDIAS;
}
export interface IGetTalkMediasAction {
    type: MediasActionTypes.GET_TALK_MEDIAS;
}

export interface IGetSttMediasAction {
    type: MediasActionTypes.GET_STT_MEDIAS;
}

export interface ISearchKindsAction {
    payload: string;
    type: MediasActionTypes.SEARCH_KINDS;
}

export interface ISearchKindsResetAction {
    type: MediasActionTypes.SEARCH_KINDS_RESET;
}

export interface IUpdateAction {
    type: MediasActionTypes.UPDATE;
}

export interface IUpdateSTTAction {
    type: MediasActionTypes.UPDATE_STT;
}
