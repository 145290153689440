import { Store } from 'redux';
import { effects } from 'redux-saga';
import { v4 as uuid } from 'uuid';

import { IApplicationState } from '..';
import { IToCommand } from '../../Backend/Commands';
import { CreateArchive } from '../../Backend/Commands/CreateArchive';
import { DeleteArchive } from '../../Backend/Commands/DeleteArchive';
import { EditArchive } from '../../Backend/Commands/EditArchive';
import { GetArchive } from '../../Backend/Commands/GetArchive';
import { GetArchives } from '../../Backend/Commands/GetArchives';
import { GetArchivesCount } from '../../Backend/Commands/GetArchivesCount';
import { IncArchiveCounter } from '../../Backend/Commands/IncArchiveCounter';
import { RetryArchive } from '../../Backend/Commands/RetryArchive';
import { ShareArchive } from '../../Backend/Commands/ShareArchive';
import { mediaarchiver } from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { ensureString } from '../../Utils/String';
import { I18N } from '../I18n/Types';
import { setSnackMessage } from '../Layout/Actions';
import { store } from '../store';
import {
    create,
    deleteArchive,
    deletedArchive,
    get,
    getById,
    incCounters,
    retry,
    setArchive,
    setOpErrors,
    setOpSuccess,
    setProgress,
    share,
    unwatchArchive,
    watchArchive,
} from './Actions';
import { ArchivesActionTypes } from './Types';

import MALogo from '../../Images/logo.png';

const getL18N = (state: IApplicationState): I18N => state.i18n.i18n;

function* handleGetById(action: ReturnType<typeof getById>) {
    const cmd = new GetArchive(action.payload);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get archive by ID');
    }
}

function* handleGet(action: ReturnType<typeof get>) {
    const cmd = new GetArchives(action.payload);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get archives');
    }
}

function* handleGetCount() {
    const cmd = new GetArchivesCount();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get archives count');
    }
}

function* handleDelete(action: ReturnType<typeof deleteArchive>) {
    const str = store as Store<IApplicationState>;
    const cmd = new DeleteArchive(action.payload);
    const i18n: I18N = yield effects.select(getL18N);

    try {
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.call(str.dispatch.bind(str, deletedArchive(action.payload)));
        yield effects.call(str.dispatch.bind(str, setSnackMessage(i18n._('Archive successfully deleted'))));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to delete archive');
        yield effects.call(str.dispatch.bind(str, setSnackMessage(i18n._('Archive deletion failed'))));
    }
}

function* handleIncCounters(action: ReturnType<typeof incCounters>) {
    const [archiveID, views, downloads] = action.payload;
    const cmd = new IncArchiveCounter(archiveID as string, views as number, downloads as number);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to update archive counters');
    }
}

function* handleRetry(action: ReturnType<typeof retry>) {
    const str = store as Store<IApplicationState>;
    const cmd = new RetryArchive(action.payload);

    try {
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.call(str.dispatch.bind(str, watchArchive(ensureString(action.payload))));
    } catch (err) {
        yield effects.call(str.dispatch.bind(str, unwatchArchive(ensureString(action.payload))));
        Logger.warn(err as Error, 'Failed to retry archive');
    }
}

function* handleOps(actionType: string, action: ReturnType<typeof create>) {
    const str = store as Store<IApplicationState>;
    let command: IToCommand;

    if (ensureString(action.payload.ID) === '') {
        action.payload.ID = uuid();
    }

    yield effects.call(str.dispatch.bind(str, setProgress(true)));

    switch (actionType) {
        case 'create':
            command = new CreateArchive(action.payload);
            yield effects.call(str.dispatch.bind(str, watchArchive(ensureString(action.payload.ID))));
            break;
        case 'edit':
            command = new EditArchive(action.payload);
            break;
        default:
            yield effects.call(str.dispatch.bind(str, setProgress(false)));
            throw new Error('Unknown ops action type');
    }

    try {
        yield effects.call(command.Send.bind(command));
        yield effects.call(str.dispatch.bind(str, setOpSuccess()));
    } catch (err) {
        yield effects.call(str.dispatch.bind(str, unwatchArchive(ensureString(action.payload.ID))));
        try {
            const data = JSON.parse((err as Error).message);

            yield effects.call(str.dispatch.bind(str, setOpErrors(data)));
        } catch (err2) {
            Logger.warn({ original: err, parsing: err2 }, 'Unable to parse error message');
            yield effects.call(
                str.dispatch.bind(
                    str,
                    setOpErrors({
                        comment: '',
                        general: 'N/A',
                        name: '',
                        toContacts: '',
                        // toFTP: '',
                        toReview: '',
                        toSFTP: '',
                    }),
                ),
            );
        }
        Logger.warn(err as Error, 'Failed to create archive');
    } finally {
        yield effects.call(str.dispatch.bind(str, setProgress(false)));
    }
}

function* handleSetArchive(action: ReturnType<typeof setArchive>) {
    const str = store as Store<IApplicationState>;
    const ids = str.getState().archives.watchedArchives;

    if (ids.length === 0 || ensureString(action.payload.ID) === '') {
        return;
    }
    for (const id of ids) {
        if (id !== action.payload.ID) {
            continue;
        }
        switch (action.payload.Status) {
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_ERROR:
                yield effects.call(str.dispatch.bind(str, unwatchArchive(id)));
                yield notifyArchiveError(action.payload);
                break;
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_OK:
                yield effects.call(str.dispatch.bind(str, unwatchArchive(id)));
                yield notifyArchiveSuccess(action.payload);
                break;
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_RUNNING:
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_SCHEDULED:
                break;
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_EXPIRED:
                yield effects.call(str.dispatch.bind(str, unwatchArchive(id)));
                break;
            case mediaarchiver.ArchiveStatus.ARCHIVE_STATUS_UNKNOWN:
            default:
                break;
        }
    }
}

function* handleShareArchive(action: ReturnType<typeof share>) {
    const str = store as Store<IApplicationState>;
    const i18n: I18N = yield effects.select(getL18N);

    try {
        const cmd = new ShareArchive(action.payload);

        yield effects.call(cmd.Send.bind(cmd));
        yield effects.call(str.dispatch.bind(str, setSnackMessage(i18n._('Archive shared to contact'))));
    } catch (err) {
        Logger.warn(err as string);
    }
}

function* handleWatchTick() {
    const str = store as Store<IApplicationState>;
    const ids = str.getState().archives.watchedArchives;

    for (const id of ids) {
        const cmd = new GetArchive(id);

        try {
            yield effects.call(cmd.Send.bind(cmd));
        } catch (err) {
            Logger.warn(err as Error, 'Failed to get archive by ID');
        }
    }
}

function* watchCreate() {
    yield effects.takeEvery(ArchivesActionTypes.CREATE, handleOps.bind(null, 'create'));
}

function* watchDelete() {
    yield effects.takeEvery(ArchivesActionTypes.DELETE, handleDelete);
}

function* watchEdit() {
    yield effects.takeEvery(ArchivesActionTypes.EDIT, handleOps.bind(null, 'edit'));
}

function* watchGet() {
    yield effects.takeEvery(ArchivesActionTypes.GET, handleGet);
}

function* watchGetById() {
    yield effects.takeEvery(ArchivesActionTypes.GET_BY_ID, handleGetById);
}

function* watchGetCount() {
    yield effects.takeEvery(ArchivesActionTypes.GET_COUNT, handleGetCount);
}

function* watchIncCounters() {
    yield effects.takeEvery(ArchivesActionTypes.INC_COUNTERS, handleIncCounters);
}

function* watchRetryArchive() {
    yield effects.takeEvery(ArchivesActionTypes.RETRY, handleRetry);
}

function* watchSetArchive() {
    yield effects.takeLatest(ArchivesActionTypes.SET_ARCHIVE, handleSetArchive);
}
function* watchShareArchive() {
    yield effects.takeEvery(ArchivesActionTypes.SHARE, handleShareArchive);
}

function* watchWatchTick() {
    yield effects.takeLatest(ArchivesActionTypes.WATCH_TICK, handleWatchTick);
}

function* notifyArchiveSuccess(archive: mediaarchiver.IArchive) {
    const i18n: I18N = yield effects.select(getL18N);
    const message = i18n.sprintf(i18n._('Your archive "%1$s" is now ready to be viewed'), ensureString(archive.Name));

    yield notifyArchiveSnack(message);
    yield notifyArchiveBrowserNotification(message, archive);
}

function* notifyArchiveError(archive: mediaarchiver.IArchive) {
    const i18n: I18N = yield effects.select(getL18N);
    const message = i18n.sprintf(i18n._('Archive "%1$s" extraction failed'), ensureString(archive.Name));

    yield notifyArchiveSnack(message);
    yield notifyArchiveBrowserNotification(message, archive);
}

function* notifyArchiveSnack(message: string) {
    const str = store as Store<IApplicationState>;

    yield effects.call(str.dispatch.bind(str, setSnackMessage(message)));
}

function* notifyArchiveBrowserNotification(message: string, archive: mediaarchiver.IArchive) {
    const str = store as Store<IApplicationState>;

    if (str.getState().user.user.options.indexOf(mediaarchiver.UserOptions.USER_OPTION_BROWSER_NOTIFICATIONS) !== -1) {
        if ('Notification' in window && Notification.permission === 'granted') {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const notification = new Notification(message, {
                    badge: MALogo,
                    image: ensureString(archive.VignetteURL),
                    vibrate: 1,
                });
            } catch (err) {
                Logger.warn(err as Error, 'Failed to create notification');
            }
        }
    }
    yield;
}

export function* ArchivesSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchCreate)]);
    yield effects.all([effects.fork(watchDelete)]);
    yield effects.all([effects.fork(watchEdit)]);
    yield effects.all([effects.fork(watchGet)]);
    yield effects.all([effects.fork(watchGetById)]);
    yield effects.all([effects.fork(watchGetCount)]);
    yield effects.all([effects.fork(watchIncCounters)]);
    yield effects.all([effects.fork(watchRetryArchive)]);
    yield effects.all([effects.fork(watchSetArchive)]);
    yield effects.all([effects.fork(watchShareArchive)]);
    yield effects.all([effects.fork(watchWatchTick)]);
}
