import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class CreateFTPAccount extends AMessage implements IToCommand {
    private account: Proto.mediaarchiver.IFTPAccount;

    public constructor(account: Proto.mediaarchiver.IFTPAccount) {
        super('createFTPAccount');
        this.account = account;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            CreateFTPAccountArgs : this.account,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeCreateFTPAccount,
        }));
    }
}
