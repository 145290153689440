import { Store } from 'redux';
import { effects } from 'redux-saga';

import { IApplicationState } from '..';
import { GetEPG } from '../../Backend/Commands/GetEPG';
import { ReportBug } from '../../Backend/Commands/ReportBug';
import protos from '../../Protos/protos';
import { Logger } from '../../Utils/Logger';
import { getDateInTz } from '../../Utils/Time';
import { store } from '../store';
import { checkList } from '../XML/Actions';
import { TimelineActionTypes } from './Types';
import { hideReportBug, reportBug, setReportBugErrors, setReporting } from './Actions';
import { isNull, isNullOrUndefinedOrEmptyArray, isNullOrUndefinedOrEmptyString } from '../../Utils/Various';
import { setSnackMessage } from '../Layout/Actions';
import { I18N } from '../I18n/Types';

const getI18n = (state: IApplicationState): I18N => state.i18n.i18n;

function* handleReportBug(action: ReturnType<typeof reportBug>) {
    const i18n = (yield effects.select(getI18n)) as I18N;
    const state = (store as Store).getState() as IApplicationState;
    const errors: Map<string, string> = new Map();
    let ok = true;

    if (isNullOrUndefinedOrEmptyString(action.payload.company)) {
        errors.set('company', i18n._('Company is mandatory'));
        ok = false;
    }
    if (isNullOrUndefinedOrEmptyString(action.payload.mail)) {
        errors.set('mail', i18n._('Email address is mandatory'));
        ok = false;
    }
    if (isNullOrUndefinedOrEmptyString(action.payload.name)) {
        errors.set('name', i18n._('Name is mandatory'));
        ok = false;
    }
    if (isNullOrUndefinedOrEmptyString(action.payload.message)) {
        errors.set('message', i18n._('Message is mandatory'));
        ok = false;
    }

    yield effects.put(setReportBugErrors(errors));
    if (!ok) {
        return;
    }

    const bug = new protos.mediaarchiver.ReportedBug({
        FromCompany: action.payload.company,
        FromMail: action.payload.mail,
        FromName: action.payload.name,
        FromPhone: action.payload.phone,
        Message: action.payload.message,
    });

    if (state.timeline && state.timeline.criterias && state.timeline.criterias.media) {
        bug.Media = state.timeline.criterias.media;
    }
    if (state.timeline && state.timeline.criterias && state.timeline.criterias.start) {
        let ts = state.timeline.criterias.start.getTime() / 1000;
        if (state.player && state.player.player) {
            const time = state.player.player.getCurrentTime();

            if (time) {
                ts = time.getTime() / 1000;
            }
        }
        bug.TS = Math.round(ts);
    }
    bug.DebugInfos = {};
    if (!isNullOrUndefinedOrEmptyArray((console as any).history)) {
        try {
            bug.DebugInfos['console'] = JSON.stringify((console as any).history);
        } catch (err) {
            /* */
        }
    }
    if (window.navigator && window.navigator.userAgent) {
        bug.DebugInfos['user-agent'] = window.navigator.userAgent;
    }
    const cmd = new ReportBug(bug);

    try {
        yield effects.put(setReporting(true));
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setSnackMessage(i18n._('Bug reported, we will contact you shortly')));
        yield effects.put(hideReportBug());
    } catch (err) {
        Logger.warn(err as Error, 'Failed to report bug');
    } finally {
        yield effects.put(setReporting(false));
    }
}

function* handleTimelineUpdate() {
    const state = (store as Store).getState() as IApplicationState;

    const cmd = new GetEPG(
        getDateInTz(state.timeline.criterias.start, state.timeline.criterias.timezone),
        getDateInTz(state.timeline.criterias.end, state.timeline.criterias.timezone),
        state.timeline.criterias.media,
        state.timeline.criterias.contentTypes,
        state.i18n.localeInfos.name.slice(-2),
    );

    try {
        yield effects.put(
            checkList(
                getDateInTz(state.timeline.criterias.start, state.timeline.criterias.timezone),
                getDateInTz(state.timeline.criterias.end, state.timeline.criterias.timezone),
            ),
        );
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to fetch timeline');
    }
}

function* watchReportBug() {
    yield effects.takeEvery(TimelineActionTypes.REPORT_BUG, handleReportBug);
}

function* watchTimelineChanges() {
    yield effects.takeEvery(TimelineActionTypes.UPDATE_TIMELINE, handleTimelineUpdate);
}

export function* TimelineSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchReportBug)]);
    yield effects.all([effects.fork(watchTimelineChanges)]);
}
