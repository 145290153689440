import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class EditAnnotationType extends AMessage implements IToCommand {
    private type: Proto.mediaarchiver.AnnotationType;

    public constructor(type: Proto.mediaarchiver.AnnotationType) {
        super('editAnnotationType');
        this.type = type;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            EditAnnotationTypeArgs : this.type,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeEditAnnotationType,
        }));
    }
}
