import { action } from 'typesafe-actions';

import {
    ICenterAction,
    IHideAction,
    IHideReportBugAction,
    IMoveStartContentStepAction,
    IReportBugAction,
    IReportedBugInfos,
    ISetAudienceModeAction,
    ISetAudienceVOSDALAction,
    ISetCalendarDurationAction,
    ISetCalendarStartAction,
    ISetCalendarStartWarningAction,
    ISetHeightAction,
    ISetPlaybackSpeedAction,
    ISetReportBugErrorsAction,
    ISetReportingAction,
    ISetStorageBugSeenAction,
    ISetWipAction,
    IShowAction,
    IShowReportBugAction,
    IShowStorageBugAction,
    ITimelineCriteriasState,
    IUpdateAction,
    IUpdateCriteriasAction,
    IZoomInAction,
    IZoomOutAction,
    TimelineActionTypes,
} from './Types';

export const center = (): ICenterAction => action(TimelineActionTypes.CENTER);

export const hide = (): IHideAction => action(TimelineActionTypes.HIDE);
export const hideReportBug = (): IHideReportBugAction => action(TimelineActionTypes.HIDE_REPORT_BUG);
export const moveStartContentStep = (delta: number): IMoveStartContentStepAction =>
    action(TimelineActionTypes.MOVE_STARTCONTENT_STEP, delta);
export const reportBug = (infos: IReportedBugInfos): IReportBugAction => action(TimelineActionTypes.REPORT_BUG, infos);
export const setAudienceMode = (mode: string): ISetAudienceModeAction =>
    action(TimelineActionTypes.SET_AUDIENCE_MODE, mode);
export const setAudienceVOSDAL = (use: boolean): ISetAudienceVOSDALAction =>
    action(TimelineActionTypes.SET_AUDIENCE_VOSDAL, use);
export const setCalendarDuration = (duration: number): ISetCalendarDurationAction =>
    action(TimelineActionTypes.SET_CALENDAR_DURATION, duration);
export const setCalendarStart = (start: Date): ISetCalendarStartAction =>
    action(TimelineActionTypes.SET_CALENDAR_START, start);
export const setCalendarStartWarning = (startWarning: string): ISetCalendarStartWarningAction =>
    action(TimelineActionTypes.SET_CALENDAR_START_WARNING, startWarning);
export const setHeight = (height: number): ISetHeightAction => action(TimelineActionTypes.SET_HEIGHT, height);
export const setPlaybackSpeed = (value: number): ISetPlaybackSpeedAction =>
    action(TimelineActionTypes.SET_PLAYBACK_SPEED, value);
export const setReportBugErrors = (errors: Map<string, string>): ISetReportBugErrorsAction =>
    action(TimelineActionTypes.SET_REPORT_BUG_ERRORS, errors);
export const setReporting = (reporting: boolean): ISetReportingAction =>
    action(TimelineActionTypes.SET_REPORTING, reporting);
export const setStorageBugSeen = (): ISetStorageBugSeenAction => action(TimelineActionTypes.SET_STORAGE_BUG_SEEN);
export const showReportBug = (): IShowReportBugAction => action(TimelineActionTypes.SHOW_REPORT_BUG);
export const showStorageBug = (): IShowStorageBugAction => action(TimelineActionTypes.SHOW_STORAGE_BUG);
export const setWip = (toggle: boolean): ISetWipAction => action(TimelineActionTypes.WIP, toggle);
export const show = (): IShowAction => action(TimelineActionTypes.SHOW);
export const update = (): IUpdateAction => action(TimelineActionTypes.UPDATE);
export const updateCriterias = (criterias: ITimelineCriteriasState): IUpdateCriteriasAction =>
    action(TimelineActionTypes.UPDATE_CRITERIAS, criterias);

export const zoomIn = (): IZoomInAction => action(TimelineActionTypes.ZOOM_IN);
export const zoomOut = (): IZoomOutAction => action(TimelineActionTypes.ZOOM_OUT);
