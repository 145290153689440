import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';

import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../Utils/Various';

export class Stream extends AMessage implements IFromCommand {
    private parsedStart: Date | null = null;
    private parsedMediaID: number | null = null;
    private parsedURL: string | null = null;
    private parsedToken: string | null = null;

    public constructor() {
        super('stream');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.StreamArg)) {
                return reject(new Error('Not a Stream message'));
            }
            if (isNullOrUndefinedOrZero(msg.StreamArg.Start)) {
                return reject(new Error('Invalid from timestamp'));
            }
            if (isNullOrUndefinedOrZero(msg.StreamArg.MediaID)) {
                return reject(new Error('Invalid media ID'));
            }
            if (isNullOrUndefinedOrEmptyString(msg.StreamArg.URL)) {
                return reject(new Error('Invalid url'));
            }
            if (isNullOrUndefinedOrEmptyString(msg.StreamArg.Token)) {
                return reject(new Error('Invalid token'));
            }
            this.parsedStart = new Date(msg.StreamArg.Start as number * 1000);
            this.parsedMediaID = msg.StreamArg.MediaID;
            this.parsedURL = msg.StreamArg.URL;
            this.parsedToken = msg.StreamArg.Token;
            resolve();
        });
    }

    public get start(): Date {
        if (isNull(this.parsedStart)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedStart;
    }

    public get mediaID(): number {
        if (isNull(this.parsedMediaID)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedMediaID;
    }

    public get url(): string {
        if (isNull(this.parsedURL)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedURL;
    }

    public get token(): string {
        if (isNull(this.parsedToken)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedToken;
    }
}
