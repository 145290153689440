import hash from 'object-hash';
import { Store } from 'redux';
import { effects } from 'redux-saga';
import * as Proto from '../../Protos/protos';

import { IApplicationState } from '..';
import { Logger } from '../../Utils/Logger';
import { SearchSpeech } from '../../Backend/Commands/SearchSpeech';
import { SearchSpeechDate } from '../../Backend/Commands/SearchSpeechDate';
import { store } from '../store';
import {
    searchSpeeches,
    searchSpeechesDate,
    setLastSearchSpeeches,
    setSearchSpeechesResults,
    setSearchSpeechesLoading,
    clear,
} from './Actions';
import { NullableSpeechResult, SearchSTTActionTypes } from './Types';

function* handleSearch(action: ReturnType<typeof searchSpeeches>) {
    yield effects.put(clear());
    yield effects.put(setSearchSpeechesLoading(true));
    const str = store as Store;
    const state = str.getState() as IApplicationState;
    const cmd = new SearchSpeech(
        action.payload.text,
        action.payload.logic,
        action.payload.medias,
        action.payload.start,
        action.payload.end,
        action.payload.pageSize,
        action.payload.page,
    );
    try {
        const res: NullableSpeechResult[] = [];
        yield effects.put(setSearchSpeechesResults(res, 0));
        yield effects.put(setSearchSpeechesLoading(true));
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.put(setLastSearchSpeeches(action.payload));
    } catch (err) {
        yield effects.put(setSearchSpeechesLoading(false));
        yield effects.put(setLastSearchSpeeches(null));
        Logger.warn(err as string, 'Failed to launch search');
    }
}

function* handleSearchDate(action: ReturnType<typeof searchSpeechesDate>) {
    const str = store as Store;
    const state = str.getState() as IApplicationState;
    const cmd = new SearchSpeechDate(action.payload.medias, action.payload.start, action.payload.end);
    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as string, 'Failed to launch search');
    }
}

function* watchSearchRequests() {
    yield effects.takeLatest(SearchSTTActionTypes.SEARCH_SPEECHES, handleSearch);
}

function* watchSearchDateRequests() {
    yield effects.takeLatest(SearchSTTActionTypes.SEARCH_SPEECHES_DATE, handleSearchDate);
}

export function* SearchSTTSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchSearchRequests)]);
    yield effects.all([effects.fork(watchSearchDateRequests)]);
}
