import { AMessage, IToCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';

export class DeleteContact extends AMessage implements IToCommand {
    private id: string;

    public constructor(id: string) {
        super('deleteContact');
        this.id = id;
    }

    public Send(): Promise<void> {
        return this.send(new mediaarchiver.WSMessage({
            DeleteContactArg : this.id,
            Type: mediaarchiver.WSMessageType.MessageTypeDeleteContact,
        }));
    }
}
