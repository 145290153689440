import moment from 'moment-timezone';

export function floorMinute(d: Date): Date {
    if (d.getSeconds() === 0 && d.getMilliseconds() === 0) {
        return d;
    }
    return new Date(new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0));
}

export function ceilMinute(d: Date): Date {
    if (d.getSeconds() === 0 && d.getMilliseconds() === 0) {
        return d;
    }
    return new Date(
        new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0).getTime() + 60000,
    );
}

export function dateFromProto(val: number | Long): Date {
    if (!(typeof val === 'number')) {
        if (val.toNumber() === 0 || val.compare(-8640000000000) === -1 || val.compare(8640000000000) === 1) {
            throw new Error('Invalid date');
        }
        return new Date(val.toNumber());
    }
    return new Date(val);
}

export function startOfDay(val: Date): Date {
    return new Date(val.setHours(0, 0, 0, 0));
}

export function endOfDay(val: Date): Date {
    return new Date(val.setHours(23, 59, 59, 999));
}

export function formatDuration(ms: number, showMS = false): string {
    const duration = moment.duration(ms);
    let dur =
        ('0' + duration.hours().toString()).slice(-2) +
        ':' +
        ('0' + duration.minutes().toString()).slice(-2) +
        ':' +
        ('0' + duration.seconds().toString()).slice(-2);

    if (showMS) {
        dur += '.' + ('00' + duration.milliseconds().toString()).slice(-3);
    }
    return dur;
}

export function getDateInTz(date: Date, tz: string): Date {
    let dateStr = date.getFullYear().toString();
    dateStr += '-' + (date.getMonth() + 1).toString().padStart(2, '0');
    dateStr += '-' + (date.getDate()).toString().padStart(2, '0');
    dateStr += ' ' + (date.getHours()).toString().padStart(2, '0');
    dateStr += ':' + (date.getMinutes()).toString().padStart(2, '0');
    dateStr += ':' + (date.getSeconds()).toString().padStart(2, '0');
    return moment.tz(dateStr, tz).toDate();
}
