import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetStream extends AMessage implements IToCommand {
    private mediaID: number;
    private timestamp: Date;

    public constructor(mediaID: number, timestamp: Date) {
        super('getStream');
        if (mediaID < 1) {
            throw new Error('Invalid media ID');
        }
        this.mediaID = mediaID;
        this.timestamp = timestamp;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            GetStreamArg : new Proto.mediaarchiver.ArgumentsGetStream({
                MediaID: this.mediaID,
                Start: Math.round(this.timestamp.getTime() / 1000),
            }),
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetStream,
        }));
    }
}
