import { Reducer } from 'redux';
import { IReviewsState, ReviewsActionTypes } from './Types';

const initialState: IReviewsState = {
    opSuccessEventElement: document.createElement('div'),
    operationInProgress: false,
    reviews: [],
    selected: {},
    total: 0,
};

const reducer: Reducer<IReviewsState> = (state = initialState, action) => {
    switch (action.type) {
        case ReviewsActionTypes.OP_SUCCESS: {
            state.opSuccessEventElement.dispatchEvent(
                new CustomEvent('opSuccess', { detail: action.payload }),
            );
            return state;
        }

        case ReviewsActionTypes.SET_ERRORS: {
            state.opSuccessEventElement.dispatchEvent(
                new CustomEvent('opError', { detail: action.payload }),
            );
            return state;
        }

        case ReviewsActionTypes.SET_OP_PROGRESS: {
            return { ...state, operationInProgress: action.payload };
        }

        case ReviewsActionTypes.SET_REVIEW: {
            const reviews = state.reviews.map((review) => {
                if (action.payload.ID === review.ID) {
                    return action.payload;
                }
                return review;
            });
            if (!reviews.some((review) => review.ID === action.payload.ID)) {
                reviews.push(action.payload);
            }
            return { ...state, reviews };
        }

        case ReviewsActionTypes.SET_SEARCH_RESULT: {
            return { ...state, reviews: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as ReviewsReducer };
