import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import * as Proto from '../../../Protos/protos';
import { IApplicationState } from '../../../Store';
import { createAnnotation, editAnnotation, hideDialog } from '../../../Store/Annotations';
import { get as getAnnotations } from '../../../Store/AnnotationTypes/Actions';
import { setSnackMessage } from '../../../Store/Layout';
import { setEndSelection, setStartSelection } from '../../../Store/Player';
import { center } from '../../../Store/Timeline';
import AnnotationDialogComponent from './AnnotationDIalog';

import styles from './styles';

const mapStateToProps = ({ annotationTypes, annotations, i18n, player, timeline, user }: IApplicationState) => ({
    annotationTypes,
    annotations,
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    player,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    center: () => dispatch(center()),
    createAnnotation: (a: Proto.mediaarchiver.ArgumentsCreateAnnotation) => dispatch(createAnnotation(a)),
    editAnnotation: (a: Proto.mediaarchiver.IAnnotation) => dispatch(editAnnotation(a)),
    getAnnotations: () => dispatch(getAnnotations()),
    hideDialog: () => dispatch(hideDialog()),
    setEndSelection: (time: Date | null) => dispatch(setEndSelection(time)),
    setSnackMessage: (msg: string) => dispatch(setSnackMessage(msg)),
    setStartSelection: (time: Date | null) => dispatch(setStartSelection(time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AnnotationDialogComponent));
