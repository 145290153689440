import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class GetAnnotationTypes extends AMessage implements IToCommand {
    private offset: number;
    private limit: number;

    public constructor(offset = 0, limit = 100) {
        super('getAnnotationTypes');
        this.limit = limit;
        this.offset = offset;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            GetAnnotationTypesArgs : new Proto.mediaarchiver.ArgumentsGetAnnotationTypes({
                Limit: this.limit,
                Offset: this.offset,
            }),
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeGetAnnotationTypes,
        }));
    }
}
