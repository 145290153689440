import { Store } from 'redux';
import { effects } from 'redux-saga';

import { IToCommand } from '../../Backend/Commands';
import { CreateSFTPAccount } from '../../Backend/Commands/CreateSFTPAccount';
import { DeleteSFTPAccount } from '../../Backend/Commands/DeleteSFTPAccount';
import { EditSFTPAccount } from '../../Backend/Commands/EditSFTPAccount';
import { GetSFTPAccounts } from '../../Backend/Commands/GetSFTPAccounts';
import { GetSFTPAccountsCount } from '../../Backend/Commands/GetSFTPAccountsCount';

import { IApplicationState } from '..';
import { Logger } from '../../Utils/Logger';
import { store } from '../store';
import { create, deleteAccount, deletedAccount, get, setErrors, setOpSuccess, setProgress } from './Actions';
import { SFTPAccountActionTypes } from './Types';

function* handleGet(action: ReturnType<typeof get>) {
    const cmd = new GetSFTPAccounts(action.payload.offset, action.payload.limit);

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get sftp accounts');
    }
}

function* handleGetCount() {
    const cmd = new GetSFTPAccountsCount();

    try {
        yield effects.call(cmd.Send.bind(cmd));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to get sftp accounts count');
    }
}

function* handleDelete(action: ReturnType<typeof deleteAccount>) {
    const str = store as Store<IApplicationState>;
    const cmd = new DeleteSFTPAccount(action.payload);

    yield effects.call(str.dispatch.bind(str, setProgress(true)));
    try {
        yield effects.call(cmd.Send.bind(cmd));
        yield effects.call(str.dispatch.bind(str, deletedAccount(action.payload)));
        yield effects.call(str.dispatch.bind(str, setOpSuccess()));
    } catch (err) {
        Logger.warn(err as Error, 'Failed to delete account');
    } finally {
        yield effects.call(str.dispatch.bind(str, setProgress(false)));
    }
}

function* handleOps(actionType: string, action: ReturnType<typeof create>) {
    const str = store as Store<IApplicationState>;
    let command: IToCommand;

    switch (actionType) {
        case 'create':
            command = new CreateSFTPAccount(action.payload);
            break;
        case 'edit':
            command = new EditSFTPAccount(action.payload);
            break;
        default:
            throw new Error('Unknown ops action type');
    }

    yield effects.call(str.dispatch.bind(str, setProgress(true)));
    try {
        yield effects.call(command.Send.bind(command));
        yield effects.call(str.dispatch.bind(str, setOpSuccess()));
    } catch (err) {
        try {
            const data = JSON.parse((err as Error).message);

            yield effects.call(
                str.dispatch.bind(
                    str,
                    setErrors({
                        directory: data.directory ? str.getState().i18n.i18n._(data.directory) : '',
                        general: data.general ? str.getState().i18n.i18n._(data.general) : '',
                        hostname: data.hostname ? str.getState().i18n.i18n._(data.hostname) : '',
                        login: data.login ? str.getState().i18n.i18n._(data.login) : '',
                        name: data.name ? str.getState().i18n.i18n._(data.name) : '',
                        password: data.password ? str.getState().i18n.i18n._(data.password) : '',
                        port: data.port ? str.getState().i18n.i18n._(data.port) : '',
                    }),
                ),
            );
        } catch (err2) {
            Logger.warn({ original: err, parsing: err2 }, 'Unable to parse error message');
            yield effects.call(
                str.dispatch.bind(
                    str,
                    setErrors({
                        directory: '',
                        general: 'N/A',
                        hostname: '',
                        login: '',
                        name: '',
                        password: '',
                        port: '',
                    }),
                ),
            );
        }
        Logger.warn(err as Error, 'Failed to create/edit/delete sftp account');
    }
    yield effects.call(str.dispatch.bind(str, setProgress(false)));
}

function* watchGet() {
    yield effects.takeEvery(SFTPAccountActionTypes.GET, handleGet);
}

function* watchGetCount() {
    yield effects.takeEvery(SFTPAccountActionTypes.GET_COUNT, handleGetCount);
}

function* watchCreate() {
    yield effects.takeEvery(SFTPAccountActionTypes.CREATE, handleOps.bind(null, 'create'));
}

function* watchEdit() {
    yield effects.takeEvery(SFTPAccountActionTypes.EDIT, handleOps.bind(null, 'edit'));
}

function* watchDelete() {
    yield effects.takeEvery(SFTPAccountActionTypes.DELETE, handleDelete);
}

export function* SFTPAccountsSaga(): Generator<effects.AllEffect, void, unknown> {
    yield effects.all([effects.fork(watchGet)]);
    yield effects.all([effects.fork(watchGetCount)]);
    yield effects.all([effects.fork(watchCreate)]);
    yield effects.all([effects.fork(watchEdit)]);
    yield effects.all([effects.fork(watchDelete)]);
}
