import { IEPGState } from '../Timeline/Types';
import * as Proto from '../../Protos/protos';

export enum XMLActionTypes {
    ADD_FILE = '@@xml/ADD_FILE',
    CHECK_LIST = '@@xml/CHECK_LIST',
    CLEAR_LIST = '@@xml/CLEAR_LIST',
    LOAD_FILE = '@@xml/LOAD_FILE',
    SET_CURRENT_FILE = '@@xml/SET_CURRENT_FILE',
    SET_ERROR = '@@xml/SET_ERROR',
    LOAD_FILE_STORAGE = '@@xml/LOAD_FILE_STORAGE',
}

export interface IXMLState {
    readonly currentFile: string;
    readonly files: Map<string, IEPGState[]>;
    readonly loadError: string;
}

export interface IAddFileAction {
    payload: [string, Proto.mediaarchiver.IEPGProgram[]];
    type: XMLActionTypes.ADD_FILE;
}
export interface ILoadFileStorageAction {
    type: XMLActionTypes.LOAD_FILE_STORAGE;
}

export interface ICheckListAction {
    payload: [Date, Date];
    type: XMLActionTypes.CHECK_LIST;
}
export interface IClearListAction {
    type: XMLActionTypes.CLEAR_LIST;
}

export interface ILoadFileAction {
    payload: File;
    type: XMLActionTypes.LOAD_FILE;
}

export interface ISetCurrentFileAction {
    payload: string;
    type: XMLActionTypes.SET_CURRENT_FILE;
}

export interface IsetErrorAction {
    payload: string;
    type: XMLActionTypes.SET_ERROR;
}
