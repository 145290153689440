import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import * as Proto from '../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { showDialog as showAnnotationDialog } from '../../Store/Annotations/Actions';
import { showDialog as showExtractionDialog } from '../../Store/Archives/Actions';
import { I18N } from '../../Store/I18n';
import { getTalkMedias, IMediasState } from '../../Store/Medias';
import { setSteps } from '../../Store/Player/Actions';
import { PlayerStatus } from '../../Store/Player/Constant';
import { IPlayerState, IPlayerStateSteps } from '../../Store/Player/Types';
import { showDialog as showTalkDialog } from '../../Store/Talk/Actions';
import { center, ITimelineState, setPlaybackSpeed, update, zoomIn, zoomOut } from '../../Store/Timeline';

import { setUserOptions, IUserState } from '../../Store/User';
import { IXMLState } from '../../Store/XML';
import { getTheme } from '../../Themes';
import { isNull } from '../../Utils/Various';

const theme = getTheme();

interface IStateStepsErrors {
    backward: string;
    fastBackward: string;
    fastForward: string;
    forward: string;
}

interface IState {
    playbackSpeed: number;
    timelineSettings: boolean;
    timelineSettingsSteps: IPlayerStateSteps;
    timelineSettingsStepsErrors: IStateStepsErrors;
}

interface IPropsFromState {
    i18n: I18N;
    medias: IMediasState;
    player: IPlayerState;
    timeline: ITimelineState;
    user: IUserState;
    xml: IXMLState;
}

interface IPropsFromDispatch {
    center: typeof center;
    getTalkMedias: typeof getTalkMedias;
    setPlaybackSpeed: typeof setPlaybackSpeed;
    setSteps: typeof setSteps;
    setUserOptions: typeof setUserOptions;
    showAnnotationDialog: typeof showAnnotationDialog;
    showExtractionDialog: typeof showExtractionDialog;
    showTalkDialog: typeof showTalkDialog;
    update: typeof update;
    zoomIn: typeof zoomIn;
    zoomOut: typeof zoomOut;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

const styles = MD.createStyles({
    bigButtons: {
        '& svg': {
            marginRight: 10,
        },

        display: 'block',
        marginRight: theme.spacing(1),
    },
    body: {
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'row',
        height: '85%',
    },
    formControlSettings: {
        marginTop: theme.spacing(1),
    },
    left: {
        flex: 1,
    },
    right: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'row-reverse',
        textAlign: 'right',
    },
    root: {
        height: theme.mediaarchiver.dimensions.footerHeight,
        width: '100%',
    },
    slider: {
        top: 10,
        width: 100,
    },
    sliderTitle: {
        display: 'inline',
        fontSize: '1.15em',
        margin: `0 ${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    },
    smallButtons: {
        minWidth: 35,
    },
});

export class TimelineFooterComponent extends React.Component<AllProps, IState> {
    private playbackSpeed = 1;
    private keyboardHandler: (ev: KeyboardEvent) => void;

    public constructor(props: AllProps) {
        super(props);
        this.state = {
            playbackSpeed: 2,
            timelineSettings: false,
            timelineSettingsSteps: props.player.steps,
            timelineSettingsStepsErrors: {
                backward: '',
                fastBackward: '',
                fastForward: '',
                forward: '',
            },
        };
        this.keyboardHandler = this.keyboardHandlerFunc.bind(this);
    }

    public componentDidMount(): void {
        this.props.getTalkMedias();
        document.addEventListener('keydown', this.keyboardHandler);
    }

    public componentWillUnmount(): void {
        document.removeEventListener('keydown', this.keyboardHandler);
    }

    public render(): React.ReactNode {
        const canAnnotate =
            this.props.user.user.functionalities.indexOf(
                Proto.mediaarchiver.UserFunctionalities.FUNCTIONALITY_ANNOTATIONS,
            ) !== -1;
        const canExtract =
            this.props.user.user.functionalities.indexOf(
                Proto.mediaarchiver.UserFunctionalities.FUNCTIONALITY_EXTRACTIONS,
            ) !== -1;
        const canPolitical =
            this.props.user.user.functionalities.indexOf(
                Proto.mediaarchiver.UserFunctionalities.FUNCTIONALITY_POLITICAL,
            ) !== -1 && this.props.medias.talkMedias.indexOf(this.props.timeline.criterias.media) !== -1;
        const buttonsReady = !isNull(this.props.player.startSelection) && !isNull(this.props.player.endSelection);

        return (
            <div className={this.props.classes.root}>
                <div className={this.props.classes.body}>
                    <div className={this.props.classes.left}>
                        <MD.Button
                            className={this.props.classes.smallButtons}
                            component='a'
                            disabled={this.props.player.status === PlayerStatus.NULL}
                            id='centerTimelineButton'
                            onClick={this.centerOnCursor.bind(this)}
                            title={this.props.i18n._('Center timeline on cursor')}
                            size='small'
                        >
                            <FontAwesomeIcon icon={FA.faICursor} />
                        </MD.Button>
                        <MD.Button
                            className={this.props.classes.smallButtons}
                            component='a'
                            disabled={this.props.timeline.presentation.zoom.isMin || this.props.timeline.workInProgress}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={this.zoomOut.bind(this) as any}
                            title={this.props.i18n._('Zoom out')}
                            size='small'
                        >
                            <FontAwesomeIcon icon={FA.faSearchMinus} />
                        </MD.Button>
                        <MD.Button
                            className={this.props.classes.smallButtons}
                            component='a'
                            disabled={this.props.timeline.presentation.zoom.isMax || this.props.timeline.workInProgress}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={this.zoomIn.bind(this) as any}
                            title={this.props.i18n._('Zoom in')}
                            size='small'
                        >
                            <FontAwesomeIcon icon={FA.faSearchPlus} />
                        </MD.Button>
                        <MD.Button
                            className={this.props.classes.smallButtons}
                            component='a'
                            onClick={this.onTimelineSettingsOpen.bind(this)}
                            title={this.props.i18n._('Timeline settings')}
                            size='small'
                        >
                            <FontAwesomeIcon icon={FA.faCogs} />
                        </MD.Button>
                        <MD.Typography className={this.props.classes.sliderTitle}>
                            {this.props.i18n._('Speed')}: ({this.getSpeedText()}):
                        </MD.Typography>
                        <Slider
                            className={this.props.classes.slider}
                            max={4}
                            min={0}
                            step={1}
                            onChange={this.setPlaybackSpeed.bind(this)}
                            onChangeCommitted={this.setPlaybackSpeedCommited.bind(this)}
                            value={this.state.playbackSpeed}
                        />
                    </div>
                    <div className={this.props.classes.right}>
                        {!this.props.user.user.share ? (
                            <MD.Button
                                className={classNames({
                                    [this.props.classes.bigButtons]: true,
                                })}
                                color='primary'
                                component='a'
                                disabled={!canPolitical || !buttonsReady}
                                onClick={this.props.showTalkDialog}
                                title={
                                    !canPolitical
                                        ? this.props.i18n._('Your account does not have access to political talk time')
                                        : buttonsReady
                                        ? this.props.i18n._('Political talk time')
                                        : this.props.i18n._('Set your selection to create an political talk time')
                                }
                                variant='contained'
                            >
                                <FontAwesomeIcon icon={FA.faUserTie} />
                                {this.props.i18n._('Talk')}
                            </MD.Button>
                        ) : (
                            ''
                        )}
                        {!this.props.user.user.share ? (
                            <MD.Button
                                className={classNames({
                                    [this.props.classes.bigButtons]: true,
                                })}
                                color='primary'
                                component='a'
                                disabled={!canAnnotate || !buttonsReady}
                                onClick={this.props.showAnnotationDialog}
                                title={
                                    !canAnnotate
                                        ? this.props.i18n._('Your account does not have access to annotations')
                                        : buttonsReady
                                        ? this.props.i18n._('Annotate selection')
                                        : this.props.i18n._('Set your selection to create an annotation')
                                }
                                variant='contained'
                            >
                                <FontAwesomeIcon icon={FA.faCommentAlt} />
                                {this.props.i18n._('Annotate')}
                            </MD.Button>
                        ) : (
                            ''
                        )}
                        {!this.props.user.user.share ? (
                            <MD.Button
                                className={classNames({
                                    [this.props.classes.bigButtons]: true,
                                })}
                                color='primary'
                                component='a'
                                disabled={!canExtract || !buttonsReady}
                                onClick={this.props.showExtractionDialog}
                                title={
                                    !canExtract
                                        ? this.props.i18n._('Your account does not have access to extractions')
                                        : buttonsReady
                                        ? this.props.i18n._('Extract selection')
                                        : this.props.i18n._('Set your selection to create an archive')
                                }
                                variant='contained'
                            >
                                <FontAwesomeIcon icon={FA.faFileArchive} />
                                {this.props.i18n._('Archive')}
                            </MD.Button>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                {this.renderTimelineSettings()}
            </div>
        );
    }

    private renderTimelineSettings(): React.ReactNode {
        const classes = this.props.classes;
        return (
            <MD.Dialog open={this.state.timelineSettings}>
                <MD.DialogTitle>{this.props.i18n._('Timeline settings')}</MD.DialogTitle>
                <MD.DialogContent>
                    <form autoComplete='off'>
                        <MD.FormGroup>
                            <MD.Typography variant={'body2'}>
                                {this.props.i18n._('Forward / Backward step with keyboard')}
                            </MD.Typography>
                            <MD.FormControl className={classes.formControlSettings} fullWidth>
                                <MD.TextField
                                    error={this.state.timelineSettingsStepsErrors.fastBackward.length !== 0}
                                    helperText={this.state.timelineSettingsStepsErrors.fastBackward}
                                    InputProps={{ endAdornment: 'ms' }}
                                    label={this.props.i18n._('Shift left')}
                                    onChange={this.onTimelineSettingsStepChange.bind(this, 'fastBackward')}
                                    type='number'
                                    value={this.state.timelineSettingsSteps.fastBackward}
                                />
                            </MD.FormControl>
                            <MD.FormControl className={classes.formControlSettings} fullWidth>
                                <MD.TextField
                                    error={this.state.timelineSettingsStepsErrors.backward.length !== 0}
                                    helperText={this.state.timelineSettingsStepsErrors.backward}
                                    InputProps={{ endAdornment: 'ms' }}
                                    label={this.props.i18n._('Left')}
                                    onChange={this.onTimelineSettingsStepChange.bind(this, 'backward')}
                                    type='number'
                                    value={this.state.timelineSettingsSteps.backward}
                                />
                            </MD.FormControl>
                            <MD.FormControl className={classes.formControlSettings} fullWidth>
                                <MD.TextField
                                    error={this.state.timelineSettingsStepsErrors.forward.length !== 0}
                                    helperText={this.state.timelineSettingsStepsErrors.forward}
                                    InputProps={{ endAdornment: 'ms' }}
                                    label={this.props.i18n._('Right')}
                                    onChange={this.onTimelineSettingsStepChange.bind(this, 'forward')}
                                    type='number'
                                    value={this.state.timelineSettingsSteps.forward}
                                />
                            </MD.FormControl>
                            <MD.FormControl className={classes.formControlSettings} fullWidth>
                                <MD.TextField
                                    error={this.state.timelineSettingsStepsErrors.fastForward.length !== 0}
                                    helperText={this.state.timelineSettingsStepsErrors.fastForward}
                                    InputProps={{ endAdornment: 'ms' }}
                                    label={this.props.i18n._('Shift right')}
                                    onChange={this.onTimelineSettingsStepChange.bind(this, 'fastForward')}
                                    type='number'
                                    value={this.state.timelineSettingsSteps.fastForward}
                                />
                            </MD.FormControl>
                            <MD.FormControlLabel
                                control={
                                    <MD.Checkbox
                                        checked={
                                            this.props.user.user.options.indexOf(
                                                Proto.mediaarchiver.UserOptions.USER_OPTION_TIMELINE_FOLLOW_CURSOR,
                                            ) !== -1
                                        }
                                        onChange={this.changeFollowCursor.bind(this)}
                                        name='followCursorCheckbox'
                                        color='primary'
                                    />
                                }
                                label={this.props.i18n._('Timeline follows the cursor')}
                            />
                        </MD.FormGroup>
                    </form>
                </MD.DialogContent>
                <MD.DialogActions>
                    <MD.Button
                        onClick={this.onTimelineSettingsClose.bind(this)}
                        color='primary'
                        title={this.props.i18n._('Cancel')}
                    >
                        {this.props.i18n._('Cancel')}
                    </MD.Button>
                    <MD.Button
                        disabled={!this.isTimelineSettingsSubmitReady()}
                        onClick={this.onTimelineSettingsSubmit.bind(this)}
                        color='secondary'
                        title={this.props.i18n._('Save')}
                    >
                        {this.props.i18n._('Save')}
                    </MD.Button>
                </MD.DialogActions>
            </MD.Dialog>
        );
    }

    private onTimelineSettingsOpen() {
        this.setState({
            timelineSettings: true,
            timelineSettingsSteps: this.props.player.steps,
            timelineSettingsStepsErrors: {
                backward: '',
                fastBackward: '',
                fastForward: '',
                forward: '',
            },
        });
    }

    private onTimelineSettingsClose() {
        this.setState({ timelineSettings: false });
    }

    private onTimelineSettingsStepChange(
        name: 'backward' | 'fastForward' | 'fastBackward' | 'forward',
        ev: React.ChangeEvent<HTMLInputElement>,
    ) {
        const timelineSettingsSteps = { ...this.state.timelineSettingsSteps };
        const timelineSettingsStepsErrors = { ...this.state.timelineSettingsStepsErrors };
        const val = parseInt(ev.target.value, 10);
        let max = 2000;

        if (name.substr(0, 4) === 'fast') {
            max = 20000;
        }
        if (isNaN(val)) {
            timelineSettingsStepsErrors[name] = this.props.i18n._('Not a valid number');
        } else if (val < 40 || val > max) {
            timelineSettingsStepsErrors[name] = this.props.i18n.sprintf(
                this.props.i18n._('Value must be between 40 and %1$d ms'),
                max,
            );
        } else {
            timelineSettingsStepsErrors[name] = '';
        }
        timelineSettingsSteps[name] = val;
        this.setState({ timelineSettingsSteps, timelineSettingsStepsErrors });
    }

    private isTimelineSettingsSubmitReady() {
        return (
            this.state.timelineSettingsStepsErrors.backward.length === 0 &&
            this.state.timelineSettingsStepsErrors.fastBackward.length === 0 &&
            this.state.timelineSettingsStepsErrors.fastForward.length === 0 &&
            this.state.timelineSettingsStepsErrors.forward.length === 0
        );
    }

    private onTimelineSettingsSubmit() {
        if (!this.isTimelineSettingsSubmitReady()) {
            return;
        }
        this.props.setSteps(this.state.timelineSettingsSteps);
        this.setState({ timelineSettings: false });
    }

    private setPlaybackSpeed(event: React.ChangeEvent<{}>, value: number | number[]) {
        let playbackSpeed = 1;
        if (!Array.isArray(value)) {
            value = [value];
        }
        switch (value[0]) {
            case 0:
            case 1:
            case 3:
            case 4:
                playbackSpeed = value[0];
                break;
            case 2:
            default:
                playbackSpeed = 2;
        }
        this.setState({
            ...this.state,
            playbackSpeed,
        });
    }
    private setPlaybackSpeedCommited(event: React.ChangeEvent<{}>, value: number | number[]) {
        let playbackSpeed = 1;
        if (!Array.isArray(value)) {
            value = [value];
        }
        switch (value[0]) {
            case 0:
            case 1:
            case 3:
            case 4:
                playbackSpeed = value[0];
                break;
            case 2:
            default:
                playbackSpeed = 2;
        }
        this.setState({
            ...this.state,
            playbackSpeed,
        });
        let val = 1;
        switch (playbackSpeed) {
            case 0:
                val = 0.2;
                break;
            case 1:
                val = 0.5;
                break;
            case 3:
                val = 2;
                break;
            case 4:
                val = 5;
                break;
            case 2:
            default:
                val = 1;
                break;
        }
        this.props.setPlaybackSpeed(val);
    }

    private getSpeedText() {
        switch (this.state.playbackSpeed) {
            case 0:
                return '0.2x';
            case 1:
                return '0.5x';
            case 3:
                return '2x';
            case 4:
                return '5x';
            case 2:
            default:
                return '1x';
        }
    }

    private zoomOut() {
        this.props.zoomOut();
        window.setTimeout(() => {
            this.props.center();
            this.props.update();
        }, 400);
        window.setTimeout(() => {
            this.props.center();
            this.props.update();
        }, 1500);
    }

    private zoomIn() {
        this.props.zoomIn();
        window.setTimeout(() => {
            this.props.center();
            this.props.update();
        }, 400);
        window.setTimeout(() => {
            this.props.center();
            this.props.update();
        }, 1500);
    }

    private centerOnCursor() {
        this.props.center();
    }

    private keyboardHandlerFunc(ev: KeyboardEvent): void {
        if (
            document.activeElement === null ||
            document.activeElement.tagName === 'INPUT' ||
            document.activeElement.tagName === 'TEXTAREA'
        ) {
            return;
        }

        const canAnnotate =
            this.props.user.user.functionalities.indexOf(
                Proto.mediaarchiver.UserFunctionalities.FUNCTIONALITY_ANNOTATIONS,
            ) !== -1;
        const buttonsReady = !isNull(this.props.player.startSelection) && !isNull(this.props.player.endSelection);

        let stop = false;

        switch (ev.key) {
            // Shift A
            case 'A':
                if (!ev.shiftKey || !canAnnotate || !buttonsReady) {
                    break;
                }
                this.props.showAnnotationDialog();
                stop = true;
                break;
            // Shift C
            case 'C':
                if (!ev.shiftKey) {
                    break;
                }
                this.props.center();
                break;
            // Shift T
            case 'T':
                if (!ev.shiftKey || !canAnnotate || !buttonsReady) {
                    break;
                }
                this.props.showTalkDialog();
                stop = true;
                break;
            // Alt left
            case 'ArrowLeft':
                if (!ev.altKey || isNull(this.props.player.startSelection) || isNull(this.props.player.player)) {
                    break;
                }
                this.props.player.player.start(
                    this.props.player.startSelection,
                    this.props.timeline.criterias.end,
                    false,
                );
                stop = true;
                break;
            // Alt right
            case 'ArrowRight':
                if (!ev.altKey || isNull(this.props.player.endSelection) || isNull(this.props.player.player)) {
                    break;
                }
                this.props.player.player.start(
                    this.props.player.endSelection,
                    this.props.timeline.criterias.end,
                    false,
                );
                stop = true;
                break;
            default:
                stop = false;
        }
        if (stop) {
            ev.stopPropagation();
            ev.preventDefault();
        }
    }

    private changeFollowCursor(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
        let options = this.props.user.user.options.filter(
            (o) => o !== Proto.mediaarchiver.UserOptions.USER_OPTION_TIMELINE_FOLLOW_CURSOR,
        );

        if (checked) {
            options = [...options, Proto.mediaarchiver.UserOptions.USER_OPTION_TIMELINE_FOLLOW_CURSOR];
        }
        this.props.setUserOptions(options);
    }
}

const mapStateToProps = ({ i18n, medias, player, timeline, user, xml }: IApplicationState) => ({
    i18n: i18n.i18n,
    medias,
    player,
    timeline,
    user,
    xml,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    center: () => dispatch(center()),
    getTalkMedias: () => dispatch(getTalkMedias()),
    setPlaybackSpeed: (value: number) => dispatch(setPlaybackSpeed(value)),
    setSteps: (steps: IPlayerStateSteps) => dispatch(setSteps(steps)),
    setUserOptions: (o: Proto.mediaarchiver.UserOptions[]) => dispatch(setUserOptions(o)),
    showAnnotationDialog: () => dispatch(showAnnotationDialog()),
    showExtractionDialog: () => dispatch(showExtractionDialog()),
    showTalkDialog: () => dispatch(showTalkDialog()),
    update: () => dispatch(update()),
    zoomIn: () => dispatch(zoomIn()),
    zoomOut: () => dispatch(zoomOut()),
});

export const TimelineFooter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(TimelineFooterComponent));
