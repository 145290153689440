import { action } from 'typesafe-actions';
import * as Proto from '../../Protos/protos';
import {
    AnnotationActionTypes,
    IClearResultsAction,
    ICreateAnnotationAction,
    IDeleteAnnotationAction,
    IDeleteMultipleAnnotationsAction,
    IDownloadAction,
    IEditAnnotationAction,
    IExportAnnotationsAction,
    IHideDialogAction,
    IOpenEditDialogAction,
    ISearchAction,
    ISetCreateAnnotationErrorAction,
    ISetCreateAnnotationProgressAction,
    ISetCreateAnnotationSuccessAction,
    ISetLastSearchAction,
    ISetOpSuccessAction,
    ISetSearchLoadingAction,
    ISetTimelineAnnotationAction,
    IShowDialogAction,
} from './Types';

export const clearResults = (): IClearResultsAction => action(AnnotationActionTypes.SET_SEARCH_RESULTS, []);
export const createAnnotation = (annotation: Proto.mediaarchiver.ArgumentsCreateAnnotation): ICreateAnnotationAction =>
    action(AnnotationActionTypes.CREATE_ANNOTATION, annotation);
export const deleteAnnotation = (annotation: Proto.mediaarchiver.IAnnotation): IDeleteAnnotationAction =>
    action(AnnotationActionTypes.DELETE, annotation);
export const deleteMultipleAnnotations = (ids: string[]): IDeleteMultipleAnnotationsAction =>
    action(AnnotationActionTypes.DELETE_MULTIPLE, ids);
export const download = (aType: Proto.mediaarchiver.IAnnotationType): IDownloadAction =>
    action(AnnotationActionTypes.DOWNLOAD, aType);
export const editAnnotation = (a: Proto.mediaarchiver.IAnnotation): IEditAnnotationAction =>
    action(AnnotationActionTypes.EDIT, a);
export const exportAnnotations = (a: Proto.mediaarchiver.IArgumentsExportAnnotations): IExportAnnotationsAction =>
    action(AnnotationActionTypes.EXPORT, a);
export const hideDialog = (): IHideDialogAction => action(AnnotationActionTypes.HIDE_DIALOG);
export const openEditDialog = (a: Proto.mediaarchiver.IAnnotation): IOpenEditDialogAction =>
    action(AnnotationActionTypes.OPEN_EDIT_DIALOG, a);
export const search = (query: Proto.mediaarchiver.IArgumentsGetAnnotations): ISearchAction =>
    action(AnnotationActionTypes.SEARCH, query);
export const setCreateAnnotationError = (error: string): ISetCreateAnnotationErrorAction =>
    action(AnnotationActionTypes.CREATE_ANNOTATION_ERROR, error);
export const setCreateAnnotationProgress = (progress: boolean): ISetCreateAnnotationProgressAction =>
    action(AnnotationActionTypes.CREATE_ANNOTATION_PROGRESS, progress);
export const setCreateAnnotationSuccess = (): ISetCreateAnnotationSuccessAction =>
    action(AnnotationActionTypes.CREATE_ANNOTATION_SUCCESS);
export const setOpSuccess = (success: boolean): ISetOpSuccessAction =>
    action(AnnotationActionTypes.OP_SUCCESS, success);
export const setLastSearch = (query: Proto.mediaarchiver.IArgumentsGetAnnotations): ISetLastSearchAction =>
    action(AnnotationActionTypes.SET_LAST_SEARCH, query);
export const setSearchLoading = (loading: boolean): ISetSearchLoadingAction =>
    action(AnnotationActionTypes.SET_SEARCH_LOADING, loading);
export const setTimelineAnnotation = (
    annotation: Proto.mediaarchiver.IAnnotation | null,
): ISetTimelineAnnotationAction => action(AnnotationActionTypes.SET_TIMELINE_ANNOTATION, annotation);
export const showDialog = (): IShowDialogAction => action(AnnotationActionTypes.SHOW_DIALOG);
