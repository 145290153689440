import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    contactsList: {
        '& svg': {
            marginRight: theme.spacing(1),
        },
        '&>:first-child': {
            borderTopWidth: 0,
        },
        '&>:last-child': {
            borderBottomWidth: 0,
        },
        '&>li': {
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderTop: `1px solid ${theme.palette.divider}`,
        },

        border: `1px solid ${theme.palette.divider}`,
        height: 315,
        maxHeight: 315,
        overflowY: 'auto',
    },
    dialogAddLink: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1) * 3,
        marginTop: theme.spacing(1),
        textAlign: 'right',
    },
    dialogContent: {
        minWidth: 600,
    },
    dialogFormElement: {
        marginTop: theme.spacing(1),
    },
    dialogHeaderFormat: {
        display: 'block',
    },
    dialogHeaderInfos: {
        '& strong': {
            display: 'block',
            fontWeight: 'bold',
        },

        fontSize: '0.8em',
        paddingLeft: theme.spacing(1),
    },
    dialogImage: {
        height: 'auto',
        width: '100%',
    },
    dialogLoading: {
        height: '20px !important',
        width: '20px !important',
    },
    dialogTags: {
        marginTop: 5,
    },
    expansionPanel: {
        '&:first-of-type': {
            borderTopWidth: 1,
        },

        border: `0px solid ${theme.palette.divider}`,
        borderBottomWidth: 1,
        overflow: 'hidden',
    },
    expansionPanelCheckbox: {
        padding: 0,
        paddingRight: theme.spacing(1),
    },
    expansionPanelContainer: {
        '&>div:first-child': {
            marginTop: theme.spacing(1) * 4,
        },
    },
    expansionPanelDetails: {
        display: 'flex',
        flexDirection: 'column',
        height: 0,
        padding: 0,
        transition: theme.transitions.create('height', { duration: 500 }),
    },
    expansionPanelDetailsOpened: {
        height: 'inherit',
        padding: theme.spacing(1),
    },
    expansionPanelDisabled: {
        cursor: 'not-allowed !important',
        opacity: 0.7,
    },
    expansionPanelHeader: {
        display: 'flex',
        padding: theme.spacing(1),
    },
    fieldDescription: {
        fontSize: '2em',
    },
    formBorderError: {
        border: '1px solid red',
    },
    formControls: {
        marginTop: theme.spacing(1),
    },
    formElement: {
        marginTop: theme.spacing(1),
    },
    panelsContainer: {
        marginTop: 20,
    },
});
