import { Reducer } from 'redux';

import { isNullOrUndefined } from '../../Utils/Various';
import { ISearchState, SearchActionTypes } from './Types';

const initialState: ISearchState = {
    currentSearch: null,
    lastSearch: null,
    pageSize: 50,
    searchLoading: false,
    searchResults: [],
    searchResultsTotal: 0,
    structuredSearchResults: [],
    timelineProgram: null,
};

const reducer: Reducer<ISearchState> = (state = initialState, action) => {
    switch (action.type) {
        case SearchActionTypes.CLEAR: {
            return {
                ...state,
                currentSearch: null,
                searchLoading: false,
                searchResults: [],
                searchResultsTotal: 0,
                structuredSearchResults: [],
            };
        }

        case SearchActionTypes.SET_SEARCH_LOADING: {
            return {
                ...state,
                searchLoading: action.payload,
            };
        }

        case SearchActionTypes.SET_LAST_SEARCH: {
            return {
                ...state,
                lastSearch: action.payload,
            };
        }

        case SearchActionTypes.SET_SEARCH_PARAMS: {
            return {
                ...state,
                currentSearch: action.payload,
            };
        }

        case SearchActionTypes.SET_SEARCH_RESULTS: {
            if (isNullOrUndefined(state.currentSearch)) {
                return { ...state, searchLoading: false, searchResults: [] };
            }
            const start = (action.payload[1] - 1) * state.currentSearch.pageSize;
            const searchResults = state.searchResults.map((_, i) => {
                if (!isNullOrUndefined(state.currentSearch) && i >= start && i < start + state.currentSearch.pageSize) {
                    return action.payload[0][i - start];
                }
                return state.searchResults[i];
            });

            return { ...state, searchLoading: false, searchResults };
        }

        case SearchActionTypes.SET_STRUCTURED_SEARCH_RESULTS: {
            if (isNullOrUndefined(state.currentSearch)) {
                return { ...state, searchLoading: false, structuredSearchResults: [] };
            }
            const start = (action.payload[1] - 1) * state.currentSearch.pageSize;
            const structuredSearchResults = state.structuredSearchResults.map((_, i) => {
                if (!isNullOrUndefined(state.currentSearch) && i >= start && i < start + state.currentSearch.pageSize) {
                    return action.payload[0][i - start];
                }
                return state.structuredSearchResults[i];
            });

            return { ...state, searchLoading: false, structuredSearchResults };
        }

        case SearchActionTypes.SET_SEARCH_RESULTS_COUNT: {
            if (action.payload <= state.searchResults.length) {
                return state;
            }
            const searchResults = Array.from(new Array(action.payload)).map((_, i) => {
                if (!isNullOrUndefined(state.searchResults[i])) {
                    return state.searchResults[i];
                }
                return null;
            });
            const structuredSearchResults = Array.from(new Array(action.payload)).map((_, i) => {
                if (!isNullOrUndefined(state.structuredSearchResults[i])) {
                    return state.structuredSearchResults[i];
                }
                return null;
            });

            return { ...state, searchResults, structuredSearchResults };
        }

        case SearchActionTypes.SET_SEARCH_RESULTS_TOTAL: {
            return { ...state, searchResultsTotal: action.payload };
        }

        case SearchActionTypes.SET_TIMELINE_PROGRAM: {
            return {
                ...state,
                timelineProgram: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as SearchReducer };
