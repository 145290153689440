import { AMessage, IToCommand } from '.';
import { mediaarchiver } from '../../Protos/protos';

export class EditContact extends AMessage implements IToCommand {
    private contact: mediaarchiver.IContact;

    public constructor(contact: mediaarchiver.IContact) {
        super('editContact');
        this.contact = contact;
    }

    public Send(): Promise<void> {
        return this.send(new mediaarchiver.WSMessage({
            EditContactArgs : new mediaarchiver.Contact({
                Email: this.contact.Email,
                FirstName: this.contact.FirstName,
                ID: this.contact.ID,
                LastName: this.contact.LastName,
            }),
            Type: mediaarchiver.WSMessageType.MessageTypeEditContact,
        }));
    }
}
