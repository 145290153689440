import * as Icons from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18NState } from '../../Store/I18n';
import { get as getMail, IMailFormErrors, IMailState } from '../../Store/Mails';
import { getTheme } from '../../Themes';

interface IRouterMatchParams {
    id: string;
}

interface IState {
    error: IMailFormErrors;
}

interface IPropsFromState {
    i18n: I18NState;
    mails: IMailState;
    router: RouteComponentProps<IRouterMatchParams>;
}

interface IPropsFromDispatch {
    getMail: typeof getMail;
}
const theme = getTheme();

const styles = MD.createStyles({
    container: {
        height: '100%',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
    },
    error: {
        '&>div': {
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            height: 100,
            justifyContent: 'center',
            width: 400,
        },
        '&>div>h4': {
            color: theme.palette.error.main,
        },

        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
    },
    frame: {
        border: '0px solid',
        height: '100%',
        left: 'calc(50% - 400px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'absolute',
        width: 800,
    },
    loading: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
    },
    mail: {},
});

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class MailsConsultComponent extends React.Component<AllProps, IState> {
    public constructor(props: AllProps) {
        super(props);
        this.state = { error: { general: '', mail: '' } };
    }

    public componentDidMount(): void {
        this.props.getMail(this.props.router.match.params.id);
    }

    public render(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid className={classes.container} container>
                {this.props.mails.operationInProgress
                    ? this.renderLoading()
                    : this.state.error.general !== ''
                    ? this.renderError()
                    : this.renderMail()}
            </MD.Grid>
        );
    }

    public renderLoading(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid className={classes.loading} item xs={12}>
                <MD.Typography variant='h2'>
                    <FontAwesomeIcon icon={Icons.faSpinner} spin={true} />
                </MD.Typography>
            </MD.Grid>
        );
    }

    public renderError(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid className={classes.error} item xs={12}>
                <MD.Paper>
                    <MD.Typography variant='h4'>
                        {this.props.i18n.i18n._('We could not find this email or an error ocurred')}
                    </MD.Typography>
                </MD.Paper>
            </MD.Grid>
        );
    }

    public renderMail(): React.ReactNode {
        const { classes } = this.props;

        return (
            <MD.Grid className={classes.mail} item xs={12}>
                <iframe className={classes.frame} srcDoc={this.props.mails.mail} />
            </MD.Grid>
        );
    }
}

const mapStateToProps = ({ i18n, mails }: IApplicationState, ownProps: RouteComponentProps<IRouterMatchParams>) => ({
    i18n,
    mails,
    router: ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getMail: (id: string) => dispatch(getMail(id)),
});

export const MailsConsult = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(MailsConsultComponent));
