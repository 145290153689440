import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    '@keyframes flash': {
        '0%': {
            backgroundColor: 'inherit',
            opacity: 1,
        },
        '50%': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.contrastText,
            opacity: 0.5,
        },
        '100%': {
            backgroundColor: 'inherit',
            opacity: 1,
        },
    },
    chip: {
        margin: theme.spacing(1) / 4,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    flashit: {
        animation: '$flash linear 1s infinite',
    },
    form: {
        display: 'flex',
        flexFlow: 'column',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        maxWidth: 280,
        minWidth: 120,
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(1) * 2,
    },
    startWarning: {
        margin: theme.spacing(1),
    },
    tabItem: {
        '& svg': {
            color: 'white',
            opacity: 0.7,
            transition: theme.transitions.create('opacity'),
        },
        '&:hover svg': {
            opacity: 0.9,
        },
        maxWidth: theme.mediaarchiver.dimensions.drawerWidth / 2,
        minWidth: theme.mediaarchiver.dimensions.drawerWidth / 2,
        width: theme.mediaarchiver.dimensions.drawerWidth / 2,
    },
    toolbar: theme.mixins.toolbar,
    updateButton: {
        marginTop: theme.spacing(1) * 3,
    },
    updateButtonContainer: {
        textAlign: 'center',
    },
    updateButtonDisabled: {
        opacity: 0.5,
    },
    updateIcon: {
        marginRight: theme.spacing(1),
    },
});
