import { createStyles } from '@material-ui/core';
import { getTheme } from '../../Themes';

const theme = getTheme();

export default createStyles({
    card: {
        maxWidth: '50%',
        minWidth: '50%',
        [theme.breakpoints.down('md')]: {
            maxWidth: '80%',
            minWidth: '80%',
        },
    },
    contactButton: {
        '& button': {
            marginLeft: '5px',
        },
        alignItems: 'center',
        color: theme.palette.secondary.main,
        display: 'flex',
        fontSize: '0.8em',
        justifyContent: 'space-between',
        marginLeft: 'auto',
        order: 2,
        textAlign: 'right',
    },
    contactPopover: {
        padding: '8px',
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '15px',
    },
    logo: {
        height: '25px',
    },
    lostPassword: {
        display: 'block',
        fontSize: '1.2em',
        marginTop: theme.spacing(1),
    },
    media: {
        height: '300px',
        [theme.breakpoints.down('lg')]: {
            height: '250px',
        },
        [theme.breakpoints.down('md')]: {
            height: '200px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '100px',
        },
    },
    textField: {
        width: 200,
    },
    textFieldContainer: {
        marginBottom: '15px',
    },
});
