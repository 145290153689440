import * as Proto from '../../Protos/protos';
import { AMessage, IToCommand } from './';

export class Login extends AMessage implements IToCommand {
    private login: string;
    private password: string;
    private remember: boolean;

    public constructor(login: string, password: string, remember: boolean) {
        super('login');
        this.login = login;
        this.password = password;
        this.remember = remember;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            LoginArgs : new Proto.mediaarchiver.ArgumentsLogin({
                Login: this.login,
                Password: this.password,
                Remember: this.remember,
            }),
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeLogin,
        }));
    }
}
