import { createStyles } from '@material-ui/core';
import { getTheme } from '../../../Themes';

const theme = getTheme();

export default createStyles({
    createButton: {
        '& svg': {
            marginRight: 5,
        },
        margin: theme.spacing(1),
    },
    createContainer: {
        padding: theme.spacing(1),
    },
    createContainerContent: {
        padding: theme.spacing(1),
    },
    createContainerInput: {
        margin: '0.8em',
    },
    form: {
        display: 'flex',
        flexFlow: 'column',
        flexWrap: 'wrap',
    },
    formControl: {
        textAlign: 'center',
    },
    listContainer: {
        height: '100%',
        overflow: 'hidden',
        padding: `0 ${theme.spacing(1)}`,
    },
    listContainerContent: {
        '&>h5': {
            marginBottom: 20,
        },
        height: '100%',
        padding: theme.spacing(1),
    },
    listListContainer: {
        maxHeight: '90%',
        overflow: 'auto',
    },
    listListItem: {
        '& span, & div, & p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderWidth: 0,
        cursor: 'pointer',
        flex: 1,
        maxHeight: 75,
        minHeight: 75,
        minWidth: 0,
        padding: '10px 8px',
    },
    root: {
        flexGrow: 1,
        height: '100%',
        padding: '20px 10px',
    },
});
