import * as MD from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import { mediaarchiver } from '../../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { deleteAnnotation, openEditDialog } from '../../../Store/Annotations/Actions';
import { showDialog as showExtractionDialog } from '../../../Store/Archives/Actions';
import { I18N, ILocaleInfos } from '../../../Store/I18n/Types';
import {
    setEndSelection,
    setPosition as setPlayerPosition,
    setSnapshot,
    setStartSelection,
} from '../../../Store/Player/Actions';
import { IPlayerState } from '../../../Store/Player/Types';
import { setWip } from '../../../Store/Timeline/Actions';
import { IEPGProgramState, IEPGState, ITimelineState } from '../../../Store/Timeline/Types';
import { IUserState } from '../../../Store/User/Types';
import { getTheme } from '../../../Themes';
import { Logger } from '../../../Utils/Logger';
import { getDateInTz } from '../../../Utils/Time';
import { isNull, isNullOrUndefined } from '../../../Utils/Various';
import styles from './Annotations.style';

const theme = getTheme();

interface IState {
    contextualMenuProgram: IEPGProgramState | null;
    popoverProgram: IEPGProgramState | null;
}

interface IPropsFromState {
    i18n: I18N;
    localeInfos: ILocaleInfos;
    player: IPlayerState;
    timeline: ITimelineState;
    user: IUserState;
}

interface IPropsFromDispatch {
    deleteAnnotation: typeof deleteAnnotation;
    openEditDialog: typeof openEditDialog;
    setEndSelection: typeof setEndSelection;
    setPlayerPosition: typeof setPlayerPosition;
    setSnapshot: typeof setSnapshot;
    setStartSelection: typeof setStartSelection;
    showExtractionDialog: typeof showExtractionDialog;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class AnnotationsComponent extends React.Component<AllProps, IState> {
    private contextualMenu: HTMLDivElement = document.createElement('div');
    private container: HTMLElement | null = null;
    private popover: HTMLElement | null = null;
    private popoverImage: HTMLImageElement | null = null;

    public constructor(props: AllProps) {
        super(props);
        this.state = {
            contextualMenuProgram: null,
            popoverProgram: null,
        };
    }

    public render(): React.ReactNode {
        const startTS = this.props.timeline.criterias.start.getTime();
        const endTS = this.props.timeline.criterias.end.getTime();
        const tlDuration = endTS - startTS;

        let typeValue = 'N/A';

        if (
            !isNullOrUndefined(this.state.popoverProgram) &&
            !isNullOrUndefined(this.state.popoverProgram.metas) &&
            !isNullOrUndefined(this.state.popoverProgram.metas.typeName)
        ) {
            typeValue =
                this.state.popoverProgram.metas.typeName === ''
                    ? this.props.i18n._('Deleted type')
                    : this.state.popoverProgram.metas.typeName;
        }
        const owned =
            !isNullOrUndefined(this.state.contextualMenuProgram) &&
            !isNullOrUndefined(this.state.contextualMenuProgram.metas) &&
            this.state.contextualMenuProgram.metas.owned === 'true';

        return (
            <div className={this.props.classes.root} id='annotationsContainer'>
                <MD.Card className={this.props.classes.popover} id='annotationPopover'>
                    <div className={this.props.classes.popoverDetails}>
                        <MD.CardContent className={this.props.classes.popoverContent}>
                            <MD.Typography component='h5' variant='h5'>
                                {typeValue}
                            </MD.Typography>
                            <MD.Table>
                                <MD.TableBody>
                                    {Array.from(this.getAnnotationDatas().entries()).map(([key, value]) => {
                                        const key2 = key.length > 50 ? key.substr(0, 47) + ' ...' : key;

                                        return (
                                            <MD.TableRow key={`annotation field ${Math.random()}`}>
                                                <MD.TableCell>{key2}</MD.TableCell>
                                                <MD.TableCell>{value}</MD.TableCell>
                                            </MD.TableRow>
                                        );
                                    })}
                                </MD.TableBody>
                            </MD.Table>
                        </MD.CardContent>
                    </div>
                </MD.Card>
                <div className={this.props.classes.line}>
                    {this.renderLine(this.props.timeline.data.annotationsEPG, tlDuration)}
                </div>
                <div
                    className={this.props.classes.contextualMenu}
                    onClick={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        this.setState({ contextualMenuProgram: null });
                        return false;
                    }}
                    onMouseDown={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                    }}
                    ref={(c: HTMLDivElement) => {
                        this.contextualMenu = c;
                    }}
                    style={{
                        display: isNull(this.state.contextualMenuProgram) ? 'none' : 'block',
                    }}
                >
                    <MD.List className={this.props.classes.contextualMenuList} dense>
                        <MD.ListItem button disabled={!owned} onClick={this.onEditAnnotation.bind(this)}>
                            {this.props.i18n._('Edit annotation')}
                        </MD.ListItem>
                        <MD.ListItem button disabled={!owned} onClick={this.onDeleteAnnotation.bind(this)}>
                            {this.props.i18n._('Delete annotation')}
                        </MD.ListItem>
                        <MD.ListItem button onClick={this.onArchiveAnnotation.bind(this)}>
                            {this.props.i18n._('Create an archive')}
                        </MD.ListItem>
                    </MD.List>
                </div>
            </div>
        );
    }

    private renderLine(data: IEPGState, tlDuration: number) {
        return Object.keys(data).map((minute: string, i: number) => {
            const program = data[(minute as unknown) as number];
            let type = 'N/A';

            if (isNullOrUndefined(program.metas)) {
                return;
            }

            let start = program.start.getTime();
            const tlStart = getDateInTz(this.props.timeline.criterias.start, this.props.timeline.criterias.timezone).getTime();
            const tlEnd = getDateInTz(this.props.timeline.criterias.end, this.props.timeline.criterias.timezone).getTime();
            let duration = program.duration;
            let color = theme.palette.divider;

            if (start < tlStart) {
                start = tlStart;
            }
            if (start + duration > tlEnd) {
                duration = tlEnd - start;
            }
            if (program.metas.color) {
                color = program.metas.color;
            }
            if (program.metas.typeName) {
                type = program.metas.typeName;
            }

            const widthPercent = Math.ceil((duration / tlDuration) * 1000000) / 10000;

            const classes = classNames({
                [this.props.classes.program]: true,
                [this.props.classes.programNotOwned]: program.metas && program.metas.owned !== 'true',
            });

            return (
                <div
                    className={classes}
                    data-donottrackscroller={true}
                    key={`annotation_${i}`}
                    onClick={this.handlePopoverClick.bind(this, program)}
                    onContextMenu={this.handleContext.bind(this, program)}
                    onMouseEnter={this.handlePopoverOpen.bind(this, program)}
                    onMouseLeave={this.handlePopoverClose.bind(this)}
                    style={{
                        background: color,
                        left: `${((start - tlStart) / tlDuration) * 100}%`,
                        width: `${widthPercent}%`,
                        zIndex: 10 + i,
                    }}
                >
                    {type};
                </div>
            );
        });
    }

    private getAnnotationDatas(): Map<string, React.ReactNode> {
        if (
            isNullOrUndefined(this.state.popoverProgram) ||
            isNullOrUndefined(this.state.popoverProgram.metas) ||
            isNullOrUndefined(this.state.popoverProgram.metas.data)
        ) {
            return new Map();
        }
        const res: Map<string, React.ReactNode> = new Map();
        res.set(
            this.props.i18n._('Owner'),
            !isNullOrUndefined(this.state.popoverProgram) &&
                !isNullOrUndefined(this.state.popoverProgram.metas) &&
                this.state.popoverProgram.metas.owned === 'true' ? (
                <MD.Chip color='secondary' label={this.props.i18n._('Yes')} />
            ) : (
                <MD.Chip color='primary' label={this.props.i18n._('No')} />
            ),
        );

        try {
            const fields = JSON.parse(this.state.popoverProgram.metas.data);

            if (!Array.isArray(fields)) {
                throw new Error('Invalid datas');
            }
            fields.forEach((field) => {
                if (!field.label || !field.type || !field.value) {
                    return;
                }
                switch (field.type) {
                    case 'shortText':
                    case 'longText':
                    case 'number':
                        const value = field.value.length > 50 ? field.value.substr(0, 47) + ' ...' : field.value;

                        res.set(field.label, <pre>{value}</pre>);
                        break;
                    case 'boolean':
                        res.set(
                            field.label,
                            field.value === '1' ? (
                                <MD.Chip color='secondary' label={this.props.i18n._('Yes')} />
                            ) : (
                                <MD.Chip color='primary' label={this.props.i18n._('No')} />
                            ),
                        );
                        break;
                    case 'date':
                        const date = new Date(parseInt(field.value, 10) * 1000);

                        if (isNaN(date.getTime())) {
                            return;
                        }
                        res.set(
                            field.label,
                            moment(date).format(
                                field.special
                                    ? this.props.localeInfos.formatLongDateTime
                                    : this.props.localeInfos.formatLongDate,
                            ),
                        );
                        break;
                    case 'list':
                        try {
                            const values = JSON.parse(field.value);

                            if (!Array.isArray(values)) {
                                throw new Error('Value is not an array');
                            }
                            res.set(field.label, <pre>{values.join(', \n')}</pre>);
                        } catch (err) {
                            if (typeof field.value === 'string') {
                                res.set(field.label, field.value);
                            } else {
                                Logger.warn(err as string);
                            }
                        }
                        break;
                    default:
                        return;
                }
            });
        } catch (err) {
            Logger.warn(err as string);
        }
        return res;
    }

    private handlePopoverClick(prg: IEPGProgramState) {
        if (!isNullOrUndefined(this.props.player.player)) {
            this.props.player.player.start(
                prg.start,
                getDateInTz(this.props.timeline.criterias.end, this.props.timeline.criterias.timezone),
                this.props.user.user.options.indexOf(
                    mediaarchiver.UserOptions.USER_OPTION_NO_AUTOPLAY_WHEN_PLAY_CONTENT,
                ) === -1,
            );
        }
    }

    private handlePopoverClose() {
        if (isNull(this.popover)) {
            this.popover = document.getElementById('annotationPopover');
        }
        if (isNull(this.popover)) {
            return;
        }
        this.popover.style.display = 'none';
        this.setState({ popoverProgram: null });
    }

    private handlePopoverOpen(prg: IEPGProgramState, ev: React.MouseEvent) {
        if (isNull(this.popover)) {
            this.popover = document.getElementById('annotationPopover');
        }
        if (isNull(this.container)) {
            this.container = document.getElementById('annotationsContainer');
        }
        if (isNullOrUndefined(ev) || isNullOrUndefined(ev.target) || isNull(this.popover) || isNull(this.container)) {
            return;
        }

        this.setState({ popoverProgram: prg });

        const prgBox = (ev.target as HTMLElement).getBoundingClientRect();

        this.popover.style.display = 'flex';
        if (
            ev.clientX - theme.mediaarchiver.dimensions.drawerWidth <
            (window.innerWidth - theme.mediaarchiver.dimensions.drawerWidth) / 2
        ) {
            this.popover.style.left = `${ev.clientX}px`;
            this.popover.style.right = 'inherit';
        } else {
            this.popover.style.left = 'inherit';
            this.popover.style.right = `${window.innerWidth - ev.clientX}px`;
        }
        this.popover.style.bottom = `${window.innerHeight - prgBox.top + 3}px`;
    }

    private handleContext(prg: IEPGProgramState, ev: React.MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();

        const elems = this.contextualMenu.getElementsByTagName('ul');

        if (elems.length === 0) {
            return;
        }

        elems[0].style.left = `${ev.clientX}px`;
        elems[0].style.bottom = `${window.innerHeight - ev.clientY}px`;
        this.setState({ contextualMenuProgram: prg });
    }

    private onArchiveAnnotation() {
        if (isNull(this.state.contextualMenuProgram) || isNull(this.props.player.player)) {
            return;
        }
        this.props.player.player.start(this.state.contextualMenuProgram.start, this.props.timeline.criterias.end);
        const program = this.state.contextualMenuProgram;
        window.setTimeout(() => {
            if (isNull(this.props.player.player)) {
                return;
            }
            this.props.player.player.getSnapshot().then((snap: string) => {
                this.props.setSnapshot(snap);
            });
            this.props.setPlayerPosition(program.start);
            this.props.setStartSelection(program.start);
            this.props.setEndSelection(new Date(program.start.getTime() + program.duration));
            this.props.showExtractionDialog();
        }, 500);
    }

    private onEditAnnotation() {
        if (
            isNull(this.state.contextualMenuProgram) ||
            isNullOrUndefined(this.state.contextualMenuProgram.metas) ||
            isNullOrUndefined(this.state.contextualMenuProgram.metas.id)
        ) {
            return;
        }
        // Edit annotation
        this.props.openEditDialog({
            Data: JSON.stringify(this.state.contextualMenuProgram.metas),
            DurationMS: this.state.contextualMenuProgram.duration,
            ID: this.state.contextualMenuProgram.metas.id,
            MediaID: this.props.timeline.criterias.media,
            Start: this.state.contextualMenuProgram.start.getTime(),
            Type: this.state.contextualMenuProgram.metas.type,
        });
    }

    private onDeleteAnnotation() {
        if (
            isNull(this.state.contextualMenuProgram) ||
            isNullOrUndefined(this.state.contextualMenuProgram.metas) ||
            isNullOrUndefined(this.state.contextualMenuProgram.metas.id)
        ) {
            return;
        }
        this.props.deleteAnnotation({
            ID: this.state.contextualMenuProgram.metas.id,
        });
    }
}

const mapStateToProps = ({ i18n, player, timeline, user }: IApplicationState) => ({
    i18n: i18n.i18n,
    localeInfos: i18n.localeInfos,
    player,
    timeline,
    user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    deleteAnnotation: (a: mediaarchiver.IAnnotation) => dispatch(deleteAnnotation(a)),
    openEditDialog: (a: mediaarchiver.IAnnotation) => dispatch(openEditDialog(a)),
    setEndSelection: (time: Date | null) => dispatch(setEndSelection(time)),
    setPlayerPosition: (time: Date) => dispatch(setPlayerPosition(time)),
    setSnapshot: (d: string | null) => dispatch(setSnapshot(d)),
    setStartSelection: (time: Date | null) => dispatch(setStartSelection(time)),
    setWip: (toggle: boolean) => dispatch(setWip(toggle)),
    showExtractionDialog: () => dispatch(showExtractionDialog()),
});

export const Annotations = connect(mapStateToProps, mapDispatchToProps)(MD.withStyles(styles)(AnnotationsComponent));
