import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { isNull, isNullOrUndefined } from '../../Utils/Various';

export class ArchivesCount extends AMessage implements IFromCommand {
    private parsedTVCount: number | null = null;
    private parsedRadioCount: number | null = null;

    public constructor() {
        super('archivesCount');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.ArchivesCountArg)) {
                return reject(new Error('Not a ArchivesCount message'));
            }
            if (isNullOrUndefined(msg.ArchivesCountArg.RadioCount)) {
                return reject(new Error('Empty RadiioCount in ArchivesCount message'));
            }
            if (isNullOrUndefined(msg.ArchivesCountArg.TVCount)) {
                return reject(new Error('Empty TVCount in ArchivesCount message'));
            }
            this.parsedRadioCount = msg.ArchivesCountArg.RadioCount;
            this.parsedTVCount = msg.ArchivesCountArg.TVCount;
            resolve();
        });
    }

    public get radioCount(): number {
        if (isNull(this.parsedRadioCount)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedRadioCount;
    }

    public get tvCount(): number {
        if (isNull(this.parsedTVCount)) {
            throw new Error('Command is not parsed yet');
        }
        return this.parsedTVCount;
    }
}
