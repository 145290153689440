import { AMessage, IFromCommand } from '.';
import * as Proto from '../../Protos/protos';
import { dateFromProto } from '../../Utils/Time';
import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../Utils/Various';

export class Chunk extends AMessage implements IFromCommand {
    private parsedMediaID: number | null = null;
    private parsedURL: string | null = null;
    private parsedStart: Date | null = null;

    public constructor() {
        super('chunk');
    }

    public Parse(msg: Proto.mediaarchiver.WSMessage): Promise<void> {
        return new Promise((resolve: () => void, reject: (err: Error) => void): void => {
            if (isNullOrUndefined(msg.ChunkArgs)) {
                return reject(new Error('Not a Chunk message'));
            }
            if (isNullOrUndefinedOrZero(msg.ChunkArgs.Start)) {
                return reject(new Error('Invalid chunk start'));
            }
            if (isNullOrUndefinedOrZero(msg.ChunkArgs.MediaID)) {
                return reject(new Error('Invalid media id'));
            }
            if (isNullOrUndefinedOrEmptyString(msg.ChunkArgs.URL)) {
                return reject(new Error('Invalid chunk url'));
            }
            this.parsedMediaID = msg.ChunkArgs.MediaID;
            this.parsedURL = msg.ChunkArgs.URL;
            this.parsedStart = dateFromProto(msg.ChunkArgs.Start);
            resolve();
        });
    }

    public get start(): Date {
        if (isNull(this.parsedStart)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedStart;
    }

    public get mediaID(): number {
        if (isNull(this.parsedMediaID)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedMediaID;
    }

    public get url(): string {
        if (isNull(this.parsedURL)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedURL;
    }

    public get urlFragmented(): string {
        if (isNull(this.parsedURL)) {
            throw new Error('Message is not parsed yet');
        }
        return this.parsedURL.replace(/\.mp4$/, '_key.mp4');
    }
}
