import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class RetryArchive extends AMessage implements IToCommand {
    private archive: string;

    public constructor(archive: string) {
        super('retryArchive');
        if (archive === '') {
            throw new Error('Missing archive id');
        }
        this.archive = archive;
    }

    public Send(): Promise<void> {
        return this.send(new Proto.mediaarchiver.WSMessage({
            RetryArchiveArg: this.archive,
            Type: Proto.mediaarchiver.WSMessageType.MessageTypeRetryArchive,
        }));
    }
}
