import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemIcon, ListItemText, MenuItem, MenuList, Typography, WithStyles } from '@material-ui/core';
import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import { IConnectedReduxProps } from '../../../Store';
import { getCount, IArchiveState } from '../../../Store/Archives';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import { showReportBug } from '../../../Store/Timeline';
import { IIncidentState, showIncident } from '../../../Store/Incident';
import { ReviewList } from '../Reviews/ReviewsList';
import styles from './styles';
import { isNullOrUndefinedOrEmptyArray } from '../../../Utils/Various';

export interface IRouterMatchParams {
    subpage: string;
}

interface IPropsFromState {
    archives: IArchiveState;
    localeInfos: ILocaleInfos;
    i18n: I18N;
    incident: IIncidentState;
    router: RouteComponentProps<IRouterMatchParams>;
}

interface IPropsFromDispatch {
    getCount: typeof getCount;
    showIncident: typeof showIncident;
    showReportBug: typeof showReportBug;
}

interface IOWnState {
    isCreateDialogOpen: boolean;
}

type AllProps = WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

interface ILinks {
    [id: string]: [FA.IconDefinition, string];
}

export default class ArchivesDrawerComponent extends React.Component<AllProps, IOWnState> {
    public state = {
        isCreateDialogOpen: false,
    };

    public componentDidMount(): void {
        this.props.getCount();
    }

    public handleCreateDialogToggle = (): void => this.setState({ isCreateDialogOpen: !this.state.isCreateDialogOpen });

    public render(): React.ReactNode {
        const searchLinks: ILinks = {
            /* eslint-disable sort-keys */
            all: [
                FA.faStarOfLife,
                this.props.i18n._('All my archives') +
                    ` (${this.props.archives.totalTV + this.props.archives.totalRadio})`,
            ],
            radio: [FA.faMusic, this.props.i18n._('Radio') + ` (${this.props.archives.totalRadio})`],
            tv: [FA.faTvRetro, this.props.i18n._('TV') + ` (${this.props.archives.totalTV})`],
            report: [FA.faExclamationTriangle, 'Signaler un problème'],
            prblm: !isNullOrUndefinedOrEmptyArray(this.props.incident.incidentContent.incidentMessages)
                ? [FA.faExclamationTriangle, 'Incident en cours']
                : [FA.faCheck, 'Service normal'],
            /* eslint-enable sort-keys */
        };
        return (
            <div className={this.props.classes.root}>
                <Typography variant='subtitle1'>{this.props.i18n._('My archives')}</Typography>
                <MenuList>
                    {Object.keys(searchLinks).map((id, i) => (
                        <MenuItem
                            className={this.props.classes.menuItem}
                            component='a'
                            key={`drawerArchiveItem_${i}`}
                            onClick={() => {
                                if (id === 'report') {
                                    this.props.showReportBug();
                                    return;
                                }
                                if (id === 'prblm') {
                                    this.props.showIncident();
                                    return;
                                }
                                this.props.history.push(`/archives/search/${id}`);
                            }}
                            selected={this.props.router.match.params.subpage === id}
                        >
                            <ListItemIcon key={`drawerArchiveItemIcon_${i}`}>
                                <FontAwesomeIcon icon={searchLinks[id][0]} />
                            </ListItemIcon>
                            <ListItemText
                                // inset={true}
                                key={`drawerArchiveItemText_${i}`}
                                primary={searchLinks[id][1]}
                            />
                        </MenuItem>
                    ))}
                </MenuList>
                <Route component={ReviewList} />
            </div>
        );
    }
}
