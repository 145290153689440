import { AMessage, IToCommand } from '.';
import * as Proto from '../../Protos/protos';

export class SetAccountOptions extends AMessage implements IToCommand {
    private options: Proto.mediaarchiver.IUserOptionsList;

    public constructor(options: Proto.mediaarchiver.UserOptions[]) {
        super('setAccountOptions');
        this.options = { Options: options };
    }

    public Send(): Promise<void> {
        return this.send(
            new Proto.mediaarchiver.WSMessage({
                SetAccountOptionsArg: this.options,
                Type: Proto.mediaarchiver.WSMessageType.MessageTypeSetAccountOptions,
            }),
        );
    }
}
